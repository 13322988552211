import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Select from "react-select"
import moment from "moment";
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_M_Department, Get_DDL_M_Designation } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Employee_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import DesignationNameDDL from "../../../CommonDDL/DesignationDDL/DesignationNameDDL";
import GenderDDL from "../../../CommonDDL/GenderDDL/GenderDDL";
import { useDispatch, useSelector } from "react-redux";
import { DepartmentNameDDLAPI, DesignationNameDDLAPI, GenderDDLAPI } from "../../../Redux/DDLSlice";
import EmployeeNameDDL from "../../../CommonDDL/EmployeeNameDDL/EmployeeNameDDL";
import { DesignationPostAPI } from "../../../Redux/MasterSlice/DesignationSlice";
import { EmployeePostAPI } from "../../../Redux/MasterSlice/EmployeeSlice";



export default function EmployeePopUp({ flag, open, handleOnCloseClick, DepartmentID, EmployeeID, editData, PopUpFlag, IsPost, setIsPost, handleOnClearClick }) {

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false)
    const [empolyeeTextField, setempolyeeTextField] = useState(
        {
            employeeName: flag === "Update" ? editData.employeeName : "",
            // joiningDate: flag === "Update" ? moment(editData.joiningDate).format("YYYY-MM-DD") : "",
            emailAddress: flag === "Update" ? editData.emailID : "",
            contactNumber: flag === "Update" ? editData.mobileNumber : "",
            dateOfBirth: flag === "Update" ? (editData.dateOfBirth ? moment(editData.dateOfBirth).format("YYYY-MM-DD") : '') : "",
        }
    )
    // console.log(editData)
    const [designationNameDDL, setDesignationNameDDL] = useState({
        DDL: [],
        ID: flag === "Update" ? editData.m_DesignationID : 0,
        Label: flag === "Update" ? editData.designationName : "---Select---"
    })
    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: flag === "Update" ? editData.m_DepartmentID : 0,
        Label: flag === "Update" ? editData.departmentName : "---Select---"
    })
    const [genderDDL, setGenderDDL] = useState({
        DDL: [],
        ID: flag === "Update" ? editData.genderIndicatorID : 0,
        Label: flag === "Update" ? editData.genderIndicatorName : "---Select---"
    })

    useEffect(() => {
        const data = { UserID, token }
        dispatch(DepartmentNameDDLAPI({ data }))
    }, [])

    const { DepartmentDDLData } = useSelector(state => state.DepartmentNameData)

    useEffect(() => {
        const data = { UserID: 0, token }
        dispatch(GenderDDLAPI({ data }))
    }, [])

    const { GenderDDLData } = useSelector(state => state.GenderDDLData)

    const handleInputChange = (e) => {
        setempolyeeTextField({ ...empolyeeTextField, [e.target.name]: e.target.value })
        // setempolyeeTextField({...empolyeeTextField, dateOfBirth:e.target.value})
    }

    const [formErrors, setformErrors] = useState({
        // empNameError: "",

    })

    const checkTextOnlyFields = (text, errorField, errorValue) => {
        let regex = new RegExp(/^[a-zA-Z ]*$/)
        // let regex = new RegExp(/^[A-Za-z\s\U+O9OO-U+O97F]+$/)
        // let regex = new RegExp(/^[A-Za-z\s]*$/)
        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, [errorField]: "" })
        } else {
            setformErrors({ ...formErrors, [errorField]: errorValue })

        }
    }
    const checkEmailvalidation = (text) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, emailError: "" })
        } else {
            setformErrors({ ...formErrors, emailError: "Enter valid email address" })

        }
    }
    const checkMobileValidation = (text) => {
        // let regex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
        let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, mobileError: "" })
        } else {
            setformErrors({ ...formErrors, mobileError: "Enter valid mobile number" })

        }
    }

    const handleClearField = () => {
        setempolyeeTextField(
            {
                employeeName: "",
                joiningDate: "",
                emailAddress: "",
                contactNumber: "",
                dateOfBirth: ''
            }
        )
        setDesignationNameDDL({
            ...designationNameDDL,
            ID: 0,
            Label: ""
        })
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: ""
        })
        setGenderDDL({
            ...genderDDL,
            ID: 0,
            Label: ""
        })

    }
    const handleClose = () => {
        if (flag === "Update") {
            handleOnCloseClick(false)
        }
        handleOnCloseClick(false)
        handleClearField()
    }



    const handleSubmitPost = () => {

        const data = {
            M_EmployeeID: flag === 'Update' ? editData.m_EmployeeID : 0,
            EmployeeName: empolyeeTextField.employeeName,
            M_DepartmentID: departmentDDL.ID,
            M_DesignationID: designationNameDDL.ID,
            JoiningDate: '',
            EmailID: empolyeeTextField.emailAddress,
            MobileNumber: empolyeeTextField.contactNumber,
            GenderIndicatorID: genderDDL.ID,
            DateOfBirth: empolyeeTextField.dateOfBirth,
            Login_UserID: UserID,
            Flag: flag,
            setIsPost: setIsPost,
            IsPost: IsPost,
            M_UsersID: UserID,
            token: token,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(EmployeePostAPI({ data }))
    }

    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Employee Master </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="employeeName">Employee Name <Star /></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="employeeName"
                                    id="employeeName"
                                    value={empolyeeTextField.employeeName}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        checkTextOnlyFields(e.target.value, "empNameError", "Invalid employee name")
                                    }}
                                />
                                <span className="text-danger error-danger">{formErrors.empNameError}</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <DepartmentDDL
                                departmentDDL={departmentDDL}
                                setdepartmentDDL={setdepartmentDDL}
                                DDLData={DepartmentDDLData}
                                editData={editData}
                                apiFlag={flag}
                                FlagName='Update'
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <DesignationNameDDL
                                designationNameDDL={designationNameDDL}
                                setDesignationNameDDL={setDesignationNameDDL}
                                IsPost=''
                                editData={editData}
                                FlagName='Update'
                                mendetary='yes'
                                departmentDDL={departmentDDL}
                            />
                        </div>

                        <div className="col-12 col-lg-6">
                            <GenderDDL
                                genderDDL={genderDDL}
                                setGenderDDL={setGenderDDL}
                                DDLData={GenderDDLData}
                                editData={editData}
                                // apiFlag={flag}
                                FlagName='Update'
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="joiningDate">Date Of Birth </label>
                                <input
                                    className="form-control"
                                    id="dateOfBirth"
                                    type="Date"
                                    name="dateOfBirth"
                                    value={empolyeeTextField.dateOfBirth}
                                    max={moment().format('YYYY-MM-DD')}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="emailAddress">Email Address</label>
                                <input
                                    className="form-control"
                                    id="emailAddress"
                                    type="email"
                                    name="emailAddress"
                                    value={empolyeeTextField.emailAddress}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        checkEmailvalidation(e.target.value)
                                    }}
                                />
                                <span className="text-danger error-danger">{formErrors.emailError}</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="contactNumber">Contact Number</label>
                                <input
                                    className="form-control"
                                    id="contactNumber"
                                    type="text"
                                    name="contactNumber"
                                    value={empolyeeTextField.contactNumber}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            handleInputChange(e)
                                            checkMobileValidation(e.target.value)
                                        }

                                    }}
                                />
                                <span className="text-danger error-danger">{formErrors.mobileError}</span>
                            </div>
                        </div>

                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={flag === 'Update' ? handleOnCloseClick : handleClearField}>
                                    <X size={18} style={{ marginRight: "3px" }} />{flag === 'Update' ? 'Cancel' : 'Clear'}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    // onClick={() => console.log(empolyeeTextField)}
                                    onClick={handleSubmitPost}
                                    disabled={
                                        empolyeeTextField.employeeName === "" ||
                                        departmentDDL.ID === 0 ||
                                        designationNameDDL.ID === 0 ||
                                        genderDDL.ID === 0

                                    }
                                >{flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{PopUpFlag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}