import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Select from 'react-select'

export default function BudgetTypePopUp({ flag, open, handleOnCloseClick }) {

    const options = [
        { value: 'Capital', label: 'Capital' },
        { value: 'Revenue', label: 'Revenue' },
        // { value: 'Repairs & Maintenance', label: 'Repairs & Maintenance' }
    ]

    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Budget Type </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="Budgettype">Budget Type </label>
                                <Select
                                    isClearable
                                    // isRtl={isRtl}
                                    isSearchable
                                    // value={{ value: designationDDL.ID, label: designationDDL.Label }}
                                    // onChange={(e) => {
                                    //   e ?
                                    //     setdesignationDDL({ ...designationDDL, ID: e.value, Label: e.label })
                                    //     :
                                    //     setdesignationDDL({ ...designationDDL, ID: 0, Label: language == "Marathi" ? Marathi.placeHolder.select : English.placeHolder.select })

                                    // }}
                                    // options={designationDDL.DDL}
                                    options={options}

                                />
                            </div>

                        </div>

                        {/* <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="NameofDepartment">Budget Code</label>
                                <input type="text" className="form-control" name="NameofDepartment" id="NameofDepartment" />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="NameofDepartment">Budget Type</label>
                                <input type="text" className="form-control" name="NameofDepartment" id="NameofDepartment" />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="NameofDepartment">Budget Provision(Lacs)</label>
                                <input type="text" className="form-control" name="NameofDepartment" id="NameofDepartment" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-primary mx-2 float-right">Cancel</button>
                                <button type="button" className="btn btn-md btn-primary mx-2 float-right">{flag}</button>
                            </div>
                        </div> */}
                    </div>
                </div>


            </Popup>
        </div>

    )
}