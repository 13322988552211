import React, { useState, useEffect } from "react";

import Select from "react-select"
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import CreateBudgetPopUp from "./CreateBudgetPopUp";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import { Get_DDL_M_ProjectType, Get_DDL_M_WorkType, Get_DDL_T_ProjectDefine_ProjectType, Get_DDL_T_WorkDefine_WorkType } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Get_T_ProjectWorkWiseBudgetCreation_Select } from "../CreateProjectAPI/GETAPI";
import { Pegination } from "../../../Components/Pegination/Pegination";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Post_T_ProjectWorkWiseBudgetCreation_Delete } from "../CreateProjectAPI/POSTAPI";
import Footer from "../../../Components/Footer/Footer";

export default function CreateBudget() {
  const userDetails = useAuthState();
  const { DepartmentID, UserID } = userDetails

  const today = new Date();
  const year = today.getFullYear();

  const [isopen, setIsOpen] = useState(true);

  const [projectWiseBudgetTable, setprojectWiseBudgetTable] = useState([])
  const [IsPost, setIsPost] = useState(false)
  const [loading, setloading] = useState()
  const [CurrentPage, setCurrentPage] = useState(0)

  // react pagination states 
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)

  const [allstate, setallstate] = useState({
    showCreatePopup: false,
    showDeletePopup: false,
    apiFlag: "",
    popUpFlag: "",
    editData: {},
    deleteData: ""
  })

  const [projectTypeDDL, setprojectTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })

  const [workTypeDDL, setworkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })

  const handleOnCloseClick = () => {
    setallstate({apiFlag: "",popUpFlag: "",deleteData:"" ,editData: {}, showCreatePopup: false,showDeletePopup: false })
  }

  const handleAddNewClick = () => {
    setallstate({ ...allstate, apiFlag: "Insert", popUpFlag: "Add", showCreatePopup: true })
  }

  const handleOnEditClick = (item) => {
    setallstate({...allstate, apiFlag : "Update" , editData: item, popUpFlag : "Update" , showCreatePopup : true})
  }

  const handleOnDeleteClick = (item) => {
    setallstate({...allstate , deleteData : item ,showDeletePopup : true})
  }

  const handleOnSearchClick = () => {
    setIsPost(!IsPost)
    setCurrentPage(0)
  }

  const handleOnClearClick = () => {
    setCurrentPage(0)
    setprojectTypeDDL({
      ...projectTypeDDL,
      ID: 0,
      Label: "---Select---",
    })
    setworkTypeDDL({
      ...workTypeDDL,
      ID: 0,
      Label: "---Select---"
    })
    setIsPost(!IsPost)
  }



  useEffect(() => {
    Get_DDL_T_ProjectDefine_ProjectType({ UserID, projectTypeDDL: projectTypeDDL, setprojectTypeDDL: setprojectTypeDDL })
  }, [])

  useEffect(() => {
    Get_DDL_T_WorkDefine_WorkType({ UserID, setworkTypeDDL, workTypeDDL })
  }, [])

  useEffect(() => {
    Get_T_ProjectWorkWiseBudgetCreation_Select({ setprojectWiseBudgetTable, UserID, projectTypeDDL, workTypeDDL, setloading, setTotalCount: setTotalCount, From: From, To: To, rowNo: rowNo, PerPageCount: PerPageCount })
  }, [To, IsPost])

  const handleDeleteData = () => {
    Post_T_ProjectWorkWiseBudgetCreation_Delete({
      UserID,
      deleteData : allstate.deleteData,
      handleOnCloseClick,
      IsPost,
      setIsPost
    })
  }

  return (
    <>
      {loading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="createbudget" subMenu="subProCreation" isopen={isopen} />
          <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">Project Work wise Budget Creation</h4>
                    </div>
                    <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                      <button type="button" className="btn btn-primary btn-sm"
                        title="Add" onClick={() => handleAddNewClick()}>
                        <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                        Add
                      </button>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    {/* <div className="card filter mb-4"> */}
                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group">
                            <label className="d-block" htmlFor="Budgettype">Project Type </label>
                            <Select
                              isClearable
                              // isRtl={isRtl}
                              isSearchable
                              maxMenuHeight={190}
                              value={{ value: projectTypeDDL.ID, label: projectTypeDDL.Label }}
                              onChange={(e) => {
                                e ?
                                  setprojectTypeDDL({ ...projectTypeDDL, ID: e.value, Label: e.label })
                                  :
                                  setprojectTypeDDL({ ...projectTypeDDL, ID: 0, Label: "---Select---" })

                              }}
                              options={projectTypeDDL.DDL}


                            />
                          </div>
                        </div>
                        <div className=" col-12 col-md-6 col-lg-4">
                          <div className="form-group">
                            <label className="d-block" htmlFor="Budgettype">Work Type </label>
                            <Select
                              isClearable
                              isSearchable
                              maxMenuHeight={190}
                              value={{ value: workTypeDDL.ID, label: workTypeDDL.Label }}
                              onChange={(e) => {
                                e ?
                                  setworkTypeDDL({ ...workTypeDDL, ID: e.value, Label: e.label })
                                  :
                                  setworkTypeDDL({ ...workTypeDDL, ID: 0, Label: "---Select---" })

                              }}
                              options={workTypeDDL.DDL}

                            />
                          </div>
                        </div>

                        <div className="col-12 col-lg-4 text-center">
                          <button type="button" className="btn btn-primary text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnSearchClick}>
                            <Search size={18} style={{ marginRight: "3px" }} />  Search
                          </button>
                          <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick}>
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th>Project Type</th>
                          <th>Project Name</th>
                          <th>Work Type</th>
                          <th>Work Name</th>
                          <th>Work Area</th>
                          <th>Work Amount(Lacs)</th>
                          {/* <th>Financial Year</th> */}
                          <th style={{ width: "10%", textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {

                          projectWiseBudgetTable && projectWiseBudgetTable.length > 0 ? projectWiseBudgetTable.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">{item.rowNum}</td>
                              <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                              <td>{item.projectName ? item.projectName : "-"}</td>
                              <td>{item.workTypeName ? item.workTypeName : "-"}</td>
                              <td>{item.workName ? item.workName : "-"}</td>
                              <td>{item.workArea ? item.workArea : "-"}</td>
                              <td>{item.workAmount ? item.workAmount.toFixed(2) : "-"}</td>
                              {/* <td>{item.year}</td> */}
                              <td style={{ textAlign: "center" }}>
                                <span className="mx-2 cursor-pointer"
                                  title="Edit" onClick={() => handleOnEditClick(item)}>
                                  <Edit size={15} color="#007bff" />
                                </span>

                                <span className="mx-2 cursor-pointer"
                                  title="Delete" onClick={() => handleOnDeleteClick(item.t_ProjectWorkWiseBudgetCreationID)}>
                                  <Trash2 size={15} color="red" />
                                </span>

                              </td>
                            </tr>
                          )) : ""
                        }


                      </tbody>
                    </table>
                  </div>
                  {projectWiseBudgetTable && projectWiseBudgetTable.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={TotalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {allstate.showCreatePopup ?
        <CreateBudgetPopUp
          open={allstate.showCreatePopup}
          handleOnCloseClick={handleOnCloseClick}
          allstate={allstate}
          IsPost={IsPost}
          setIsPost={setIsPost}
          handleOnClearClick={handleOnClearClick}
        /> : <></>}

      {allstate.showDeletePopup ? <DeletePopUp
        open={allstate.showDeletePopup}
        handleOnCloseClick={handleOnCloseClick}
        handleDeleteData={handleDeleteData}
      /> : <></>}

    </>
  )
}