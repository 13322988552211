
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useDispatch, useSelector } from "react-redux";
import { NewProjectDetailsProjectNameDDLAPI, ProjectDDLAPI } from "../../Redux/DDLSlice";
import { useEffect } from "react";
import { useAuthState } from "../../Helper/Context/context";

export default function ProjectDetailProjectNameDDL(props) {
    const { ProjectDetailScreenProjectNameDDL, setProjectDetailScreenProjectNameDDL, DDLFlag, ProjectTypeDDL, apiFlag,
        editData, IsPost, mendetary, wardNameDDL, subDepartmentDDL, departmentDDL, } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            M_DepartmentID: departmentDDL.ID,
            M_SubDepartmentID: subDepartmentDDL.ID,
            M_WardID: wardNameDDL.ID,
            M_WorkTypeID: 0,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_UsersID: UserID,
            token: token,
            Flag: DDLFlag
        }
        {
            departmentDDL.ID != 0 && subDepartmentDDL.ID != 0 && wardNameDDL.ID != 0 && ProjectTypeDDL.ID != 0 &&
                dispatch(NewProjectDetailsProjectNameDDLAPI({ data }))
        }
    }, [IsPost, YearID, MonthID, ProjectTypeDDL.ID, wardNameDDL.ID, subDepartmentDDL.ID, departmentDDL.ID])

    const { NewProjectDetailsProjectNameData } = useSelector(state => state.NewProjectDetailsProjectNameDDLData)

    useEffect(() => {
        handleProjectDetailScreenProjectNameDDL();
    }, [NewProjectDetailsProjectNameData])

    const handleProjectDetailScreenProjectNameDDL = () => {
        if (NewProjectDetailsProjectNameData && NewProjectDetailsProjectNameData.table && NewProjectDetailsProjectNameData.table.length > 0) {
            let list = NewProjectDetailsProjectNameData.table.map((item, index) => ({
                value: item.m_ProjectID,
                label: item.projectName,
            }))
            setProjectDetailScreenProjectNameDDL({
                DDL: list,
                ID: apiFlag === 'Update' ? editData.m_ProjectID : 0,
                Label: apiFlag === 'Update' ? editData.projectName : "---Select---",
            })
        }
        else {
            setProjectDetailScreenProjectNameDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
        }
    }


    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Project Name {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: ProjectDetailScreenProjectNameDDL.ID, label: ProjectDetailScreenProjectNameDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setProjectDetailScreenProjectNameDDL({ ...ProjectDetailScreenProjectNameDDL, ID: e.value, Label: e.label })
                            :
                            setProjectDetailScreenProjectNameDDL({ ...ProjectDetailScreenProjectNameDDL, ID: 0, Label: "---Select---", })
                    }}
                    options={ProjectDetailScreenProjectNameDDL.DDL}

                />
            </div>
        </>
    )
}