import React, { useEffect } from "react";
import { useState } from "react";
import Popup from 'reactjs-popup';
import moment from 'moment';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../Helper/Context/context";
import { WardMasterPostAPI } from "../../Redux/MasterSlice/WardMasterSlice";
import ZoneDDL from "../../CommonDDL/ZoneDDL/ZoneDDL";
import WorkType from "../Master/WorkType/WorkType";
import { WorkTypeDDLData } from "../../CommonDDL/WorkTypeDDLData";
import ProjectNameDDL from "../../CommonDDL/ProjectNameDDL/ProjectNameDDL";
import ContractorNameDDL from "../../CommonDDL/ContractorNameDDL/ContractorNameDDL";
import { ContractorNameDDLAPI } from "../../Redux/DDLSlice";
import { ConcateWorkAndWorkTypeDDL } from "../../CommonDDL/ConcateWorkAndWorkTypeDDL";
import { UpdatePostAPI } from "../../Redux/UpdateSlice/UpdateSlice";

export default function UpdateMasterPopUp({ open, handleAddCloseClick, PopUpField, handlePost }) {
    // console.log(editData)
    const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField
    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch();

    // const [wardName, setWardName] = useState(apiFlag === 'Update' ? editData.wardName : '')
    const [passwordField, setpasswordField] = useState("password")
    const [UpdateTextField, setUpdateTextField] = useState(
        {
            work_Start_Date: apiFlag === "Insert" ? '' : moment(rowData?.work_Start_Date).format("YYYY-MM-DD"),
            work_Completion_Date: apiFlag === "Insert" ? '' : moment(rowData?.work_Completion_Date).format("YYYY-MM-DD"),
            extenstionDate1: apiFlag === "Insert" ? '' : rowData.extenstionDate1?.split("/").reverse().join("-"),
            extenstionDate2: apiFlag === "Insert" ? '' : rowData.extenstionDate2?.split("/").reverse().join("-"),
            extenstionDate3: apiFlag === "Insert" ? '' : rowData.extenstionDate3?.split("/").reverse().join("-"),

        }
    )

    const [ProjectDDL, setProjectDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? '0' : rowData?.m_ProjectID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.projectName,
    });

    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [contractorNameDDL, setContractorNameDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? '0' : rowData?.m_ContractorID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.contractor_Name,
    });
    const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? '0' : rowData?.m_WorkTypeID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.workTypeName,
    })

    useEffect(() => {
        const data = { UserID, token }
        dispatch(ContractorNameDDLAPI({ data }))
    }, [])

    const { ContractorNameDDLData } = useSelector(state => state.ContractorNameDDLData)

    const handleInputChange = (e) => {
        setUpdateTextField({ ...UpdateTextField, [e.target.name]: e.target.value })
    }


    const handleClearField = () => {
        setUpdateTextField(
            {
                departmentName: "",
                // contactNumber: "",
                // emailID: "",
                // userName: "",
                // password: ''
            }
        )
    }
    const showPassword = () => {
        if (passwordField === "password") {
            setpasswordField("text")
        } else {
            setpasswordField("password")
        }
    }
    const [formErrors, setformErrors] = useState({
        empNameError: "",
        emailError: "",
        mobileError: "",

    })

    const checkEmailvalidation = (text) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, emailError: "" })
        } else {
            setformErrors({ ...formErrors, emailError: "Enter valid email address" })

        }
    }

    const checkMobileValidation = (text) => {
        // let regex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
        let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, mobileError: "" })
        } else {
            setformErrors({ ...formErrors, mobileError: "Enter valid contact number" })

        }
    }
    // console.log(editData)
    // const { departmentName, contactNumber, emailID, userName, password } = UpdateTextField

    const Add = () => {
        const data = {
            M_ProjectID: apiFlag === 'Insert' ? '0' : rowData?.m_ProjectID,
            ExtenstionDate1: UpdateTextField?.extenstionDate1,
            ExtenstionDate2: UpdateTextField?.extenstionDate2,
            ExtenstionDate3: UpdateTextField?.extenstionDate3,           
            // Flag: 'Update',
            M_UsersID: UserID,
            token: token,
            Flag: apiFlag,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick,
        }
        dispatch(UpdatePostAPI({ data }))
    }

    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>

                <span className="close" onClick={handleAddCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Update </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-md-12 col-lg-6">
                            <ConcateWorkAndWorkTypeDDL
                                WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                                setWorkAndWorkTypeDDL={setWorkAndWorkTypeDDL}
                                PopUpField={PopUpField}
                                FlagName=''
                            />
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <ProjectNameDDL
                                ProjectDDL={ProjectDDL}
                                setProjectDDL={setProjectDDL}
                                ProjectTypeDDL={ProjectTypeDDL}
                                PopUpField={PopUpField}
                            />
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <ContractorNameDDL
                                contractorNameDDL={contractorNameDDL}
                                setContractorNameDDL={setContractorNameDDL}
                                DDLData={ContractorNameDDLData}
                                PopUpField={PopUpField}
                                FlagName=''
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="startDate"> Start Date </label>
                                <input
                                    className="form-control"
                                    id="work_Start_Date"
                                    type="date"
                                    name="work_Start_Date"
                                    value={UpdateTextField.work_Start_Date}
                                    onChange={(e) => handleInputChange(e)}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="completedDate"> Completed Date </label>
                                <input
                                    className="form-control"
                                    id="work_Completion_Date"
                                    type="date"
                                    name="work_Completion_Date"
                                    value={UpdateTextField.work_Completion_Date}
                                    onChange={(e) => handleInputChange(e)}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="ext1Date"> Extenstion 1 Date </label>
                                <input
                                    className="form-control"
                                    id="extenstionDate1"
                                    type="date"
                                    name="extenstionDate1"
                                    value={UpdateTextField.extenstionDate1}
                                    onChange={(e) => handleInputChange(e)}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="ext2Date"> Extenstion 2 Date </label>
                                <input
                                    className="form-control"
                                    id="extenstionDate2"
                                    type="date"
                                    name="extenstionDate2"
                                    value={UpdateTextField.extenstionDate2}
                                    onChange={(e) => handleInputChange(e)}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="ext3Date"> Extenstion 3 Date </label>
                                <input
                                    className="form-control"
                                    id="extenstionDate3"
                                    type="date"
                                    name="extenstionDate3"
                                    value={UpdateTextField.extenstionDate3}
                                    onChange={(e) => handleInputChange(e)}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>



                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleAddCloseClick}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                // disabled={
                                //     // wardName === '' ||
                                //     // zoneDDL.ID === 0

                                // }
                                >{apiFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popupFlag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}