


import React, { useState } from 'react'
import MobileHeader from '../../../../Components/Header/MobileHeader';
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import DepartmentDDL from '../../../../CommonDDL/DepartmentDDL/DepartmentDDL';
import { useSelector } from 'react-redux';
import SubDepartmentDDL from '../../../../CommonDDL/SubDepartmentDDL/SubDepartmentDDL';
import ProjectTypeDDLData from '../../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData';
import WardNameDDL from '../../../../CommonDDL/WardNameDDL/WardNameDDL';
import ProjectPriorityDDL from '../../../../CommonDDL/ProjectPriorityDDL/ProjectPriorityDDL';
import KeyProjectDDL from '../../../../CommonDDL/KeyProjectDDL/KeyProjectDDL';
import WorkTypeDDL from '../../../../CommonDDL/WorkTypeDDL/WorkTypeDDL/WorkTypeDDL';
import { useSearchParams } from 'react-router-dom';


export default function WardWiseProjectDetails({ props }) {
    const today = new Date();
    const year = today.getFullYear();
    const [searchParams] = useSearchParams()
    const title = searchParams.get('title')

    const [isopen, setIsOpen] = useState(true);

    const dateOptions = [
        { value: '1', label: '2021-2022' },
        { value: '2', label: '2020-2021' },
        { value: '3', label: '2019-2020' }
    ]

    const [FinancialDDL, setFinancialDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    // ---------
    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [subDepartmentDDL, setSubDepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [workTypeDDL, setworkTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [projectPriorityDDL, setProjectPriorityDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [keyProjectDDL, setKeyProjectDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const { DepartmentDDLData } = useSelector(state => state.DepartmentNameData)


    return (
        <div className="wrapper">
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="dashboard" isopen={isopen} />
                    <div
                        className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            {/* <DashboardHeading /> */}

                            <div className="row page-heading justify-content-between">
                                <div className="col-6 col-lg-6 mb-xl-0 align-self-center align-items-center">
                                    <h4 className="font-weight-bold py-3"><span className='text-danger'> {title} &nbsp;</span>  Ward Wise Project Details</h4>

                                </div>
                            </div>

                            <div className="col-12 shadow table-card">
                             
                                <div className="row px-3 pt-3">
                                    <div className="col-md-12 grid-margin">


                                        <div className="row">
                                            <div className="table-responsive">

                                                <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                    <thead>
                                                        <tr style={{ textAlign: "center" }}>
                                                            <th width="5%">Sr.No.</th>
                                                            <th>Departments</th>
                                                            <th>Ward Name</th>
                                                            <th>Project Tracker Code</th>
                                                            <th>Project Type</th>
                                                            <th>Work Type</th>
                                                            <th>Project Name</th>
                                                            <th>Tender Amt (lacs.)</th>
                                                            <th>Work Start Date</th>
                                                            <th>Work Completion Date</th>
                                                            <th>PhyProgress %</th>
                                                            <th>Fin Progress %</th>
                                                            <th>Project Priority</th>
                                                            <th>Key Project</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td className="text_center">1</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>

                                                            <td></td>

                                                        </tr>

                                                        <tr>
                                                            <td className="text_center">1</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>

                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="copyright">
                                Copyright {year}. all rights are reserved.<b>Version 1.0</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
