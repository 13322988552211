import React from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function ProjectPriorityPopUp({ flag, open, handleOnCloseClick }) {
    const options = [
        { value: 'High', label: 'High' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Low', label: 'Low' }
    ]
    return (

            <div>
                <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                    <span className="close" onClick={handleOnCloseClick}>
                        &times;
                    </span>
                    <div className="call"> Add Project</div>
                    <div className="modal-body">
                        <div className="row details-row">
                            <div className="col-6 col-lg-6">
                                <div className="form-group">
                                    <label className="d-block" for="Budgettype">Project Priority </label>
                                    <Select
                                        isClearable
                                        // isRtl={isRtl}
                                        isSearchable
                                        // value={{ value: designationDDL.ID, label: designationDDL.Label }}
                                        // onChange={(e) => {
                                        //   e ?
                                        //     setdesignationDDL({ ...designationDDL, ID: e.value, Label: e.label })
                                        //     :
                                        //     setdesignationDDL({ ...designationDDL, ID: 0, Label: language == "Marathi" ? Marathi.placeHolder.select : English.placeHolder.select })

                                        // }}
                                        // options={designationDDL.DDL}
                                        options={options}

                                    />
                                </div>

                            </div>
                            
                            <div className="col-12 col-lg-12">
                                <div className="btn-action">
                                    <button type="button" className="btn btn-md btn-primary mx-2 float-right">Cancel</button>
                                    <button type="button" className="btn btn-md btn-primary mx-2 float-right">{flag}</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </Popup>
            </div>
    )
}