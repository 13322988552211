
import React from 'react'
import { useNavigate } from 'react-router-dom';

function ProjectWiseWorkStatusCard({title,count,RouteName,OnClick}){
	// const {title,count,RouteName,handleOnClick} = props
// console.log(RouteName);

const navigate = useNavigate()

	const handleOnClick = (val) =>{
		// navigate("/physicalspillovertable")
		console.log(val);
	}
	return (
		<div className="col-12 col-lg-3 col-sm-6 mb-2" >
			{/* onClick={()=>handleOnClick(RouteName)} */}
			<span className="con-wrap" onClick={handleOnClick} >
				<p className='spacing_text'>{title}</p>
				<span>
					<h2 className='font-weight-bold mb-lg-2'>{count}</h2>
				</span>
			</span>
		</div>
	)
}

export default ProjectWiseWorkStatusCard
