import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import MobileHeader from "../../Components/Header/MobileHeader";
import Sidebar from "../../Components/Sidebar/Sidebar";

export default function UserRoles() {

    const [isopen, setIsOpen] = useState(true);

    const tableData = [
        {
            id: 1,
            OfficeHead: "Office Head",
            OfficeAdmin: "OfficeAdmin",
            DepartmentHOD: "DepartmentHOD",
            DepartmentUsers: "DepartmentUsers",
            Contractor: "Contractor",
        },
        {
            id: 2,
            OfficeHead: "Office Head",
            OfficeAdmin: "OfficeAdmin",
            DepartmentHOD: "DepartmentHOD",
            DepartmentUsers: "DepartmentUsers",
            Contractor: "Contractor",
        },
        {
            id: 3,
            OfficeHead: "Office Head",
            OfficeAdmin: "OfficeAdmin",
            DepartmentHOD: "DepartmentHOD",
            DepartmentUsers: "DepartmentUsers",
            Contractor: "Contractor",
        },
        {
            id: 4,
            OfficeHead: "Office Head",
            OfficeAdmin: "OfficeAdmin",
            DepartmentHOD: "DepartmentHOD",
            DepartmentUsers: "DepartmentUsers",
            Contractor: "Contractor",
        },
        {
            id: 5,
            OfficeHead: "Office Head",
            OfficeAdmin: "OfficeAdmin",
            DepartmentHOD: "DepartmentHOD",
            DepartmentUsers: "DepartmentUsers",
            Contractor: "Contractor",
        },
    ]

    return (
        <>
            <div className="container-scroller">
            <MobileHeader setIsOpen={setIsOpen} isopen={isopen}/>
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="userroles" isopen={isopen} />

                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">User Roles</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">

                                    <div className="table-responsive">
                                        <table cellpadding="0" cellspacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="5%">Sr.No.</th>
                                                    <th>Office Head</th>
                                                    <th>Office Admin</th>
                                                    <th>Department HOD</th>
                                                    <th>Department Users</th>
                                                    <th>Contractor</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    tableData && tableData.length > 0 ? tableData.map((item, i) => (
                                                        <tr>
                                                            <td >{i + 1}</td>
                                                            <td >{item.OfficeHead ? item.OfficeHead : "-"}</td>
                                                            <td >{item.OfficeAdmin ? item.OfficeAdmin : "-"}</td>
                                                            <td >{item.DepartmentHOD ? item.DepartmentHOD : "-"}</td>
                                                            <td > {item.DepartmentUsers ? item.DepartmentUsers : "-"}</td>
                                                            <td >{item.Contractor ? item.Contractor : "-"}</td>

                                                        </tr>
                                                    )

                                                    ) : ""
                                                }



                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row"><div className="col-sm-12 col-md-5"><div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 1 to 10 of 25 entries</div></div><div className="col-sm-12 col-md-7"><div className="dataTables_paginate paging_simple_numbers" id="example_paginate"><ul className="pagination"><li className="paginate_button page-item previous disabled" id="example_previous"><a href="#" aria-controls="example" data-dt-idx="0" tabindex="0" className="page-link">Previous</a></li><li className="paginate_button page-item active"><a href="#" aria-controls="example" data-dt-idx="1" tabindex="0" className="page-link">1</a></li><li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="2" tabindex="0" className="page-link">2</a></li><li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="3" tabindex="0" className="page-link">3</a></li><li className="paginate_button page-item next" id="example_next"><a href="#" aria-controls="example" data-dt-idx="4" tabindex="0" className="page-link">Next</a></li></ul></div></div></div>
                                </div>
                            </div>

                            <div className="copyright">
                                Copyright 2023. all rights are reserved.<b>Version 1.0</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}