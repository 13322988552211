import React, { useState } from 'react'
import FinancialSpillOver from './FinancialSpillOver/FinancialSpillOver'
import FinancialSpillOverCard from './FinancialSpillOver/FinancialSpillOverCard'
import PendingUtilizationStatement from './PendingUtilization/PendingUtilizationStatement'
import PendingUtilizationStatementCard from './PendingUtilization/PendingUtilizationStatementCard'

export default function FinancialPending(props) {
    const { Countdata } = props
    const [activeClass, setActiveClass] = useState("capitalFso")
    const [capitalToggleBtns, setCapitalToggleBtn] = useState("All")
    const [revenueToggleBtns, setRevenueToggleBtn] = useState("All")
    return (
        <>
            <div class="row mt-4">
                <div class="col-12 col-lg-6 pb-4">
                    {/* <FinancialSpillOver /> */}
                    <div className="card">

                        <div className="card-header font-weight-bold">
                            Delayed Work

                        </div>

                        <div className="card-body">
                            <div className="tab-content w-100 gray-bg" role="tab-container">

                                <div className="tab-pane fade show active" id="capitalFso" role="tabpanel" aria-labelledby="Capital-tab">

                                    <div className="tab-content w-100 p-0" role="tab-container">

                                        <div className="tab-pane fade active show p-0" id="comCapitalFso">
                                            <div className="row five-blocks">

                                                <div className="col-12 col-lg-4 col-md-4 mb-2">
                                                    <FinancialSpillOverCard
                                                        title="By Department"
                                                        count={Countdata.ByDepartment ? Countdata.ByDepartment : '0'}
                                                        path='/DelayedWork'
                                                        cardName='Department'
                                                        Flag='DelayByDepartment'
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-4 col-md-4 mb-2">
                                                    <FinancialSpillOverCard
                                                        title="By Ward"
                                                        count={Countdata.ByWard ? Countdata.ByWard : '0'}
                                                        path='/DelayedWork'
                                                        cardName='Ward'
                                                        Flag='DelayByWard'
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-4 col-md-4 mb-2">
                                                    <FinancialSpillOverCard
                                                        title="By Project Priority"
                                                        count={Countdata.ByProjectPriority ? Countdata.ByProjectPriority : '0'}
                                                        path='/DelayedWork'
                                                        cardName='ProjectPriority'
                                                        Flag='DelayByProjectPriority'
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-lg-6 pb-4">
                    <div class="card">

                        <div class="card-header font-weight-bold">
                            Budget Details
                        </div>

                        <div class="card-body">
                            <div class="tab-content w-100 gray-bg" role="tab-container">
                                <div class="tab-pane fade in active show" id="capitalPus">

                                    <div class="tab-content w-100 p-0" role="tab-container">
                                        <div class="tab-pane fade in active show p-0" id="allCapitalPus">
                                            <div class="row five-blocks">
                                                <div class="col-12 col-lg-4 col-md-4 mb-2">
                                                    <PendingUtilizationStatementCard
                                                        title="Approved Cost In (cr)"
                                                        count={Countdata.ApprovedCost ? Countdata?.ApprovedCost.toFixed(4) : '0'}
                                                        path='/BudgetDetails'
                                                        cardName='ApprovedCost'
                                                        Flag='ApprovedCost'
                                                    />
                                                </div>


                                                <div class="col-12 col-lg-4 col-md-4 mb-2">
                                                    <PendingUtilizationStatementCard
                                                        title="Tender Cost In (cr)"
                                                        count={Countdata.TenderCost ? Countdata?.TenderCost.toFixed(4) : '0'}
                                                        path='/BudgetDetails'
                                                        cardName='TenderCost'
                                                        Flag='TenderCost'
                                                    />
                                                </div>


                                                <div class="col-12 col-lg-4 col-md-4 mb-2">
                                                    <PendingUtilizationStatementCard
                                                        title="Expenditure Cost In (lacs)"
                                                        count={Countdata.ExpenditureCost ? Countdata?.ExpenditureCost.toFixed(2) : '0'}
                                                        // count='0'
                                                        path='/BudgetDetails'
                                                        cardName='ExpenditureCost'
                                                        Flag='ExpenditureCost'
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
