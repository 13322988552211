

// import Select from "react-select";
// import { useEffect } from "react";
// import { useAuthState } from "../../Helper/Context/context";
// import { useDispatch, useSelector } from "react-redux";
// import { FinancialDDLAPI } from "../../Redux/DDLSlice";

// export const FinancialYearDDL = (props) => {
//     const { FinancialDDL, setFinancialDDL, setYear, Year, DDLData, FlagName, editData, mendetary } = props;
//     const userDetails = useAuthState();
//     const { UserID, token } = userDetails;

//     const dispatch = useDispatch();

//     useEffect(() => {
//         const data = {
//             UserID: UserID,
//             token: token
//         };
//         dispatch(FinancialDDLAPI({ data }));
//     }, []);

//     const { FinancialData } = useSelector((state) => state.FinancialDDLData);

//     useEffect(() => {
//         handleFinancialDDL();
//     }, [FinancialData]);

//     const handleFinancialDDL = () => {
//         if (FinancialData && FinancialData.table && FinancialData.table.length > 0) {
//             let list = FinancialData.table.map((item) => ({
//                 value: item.financialYearID,
//                 label: item.financialYear
//             }));
//             const defaultID = localStorage.setItem("YearID") || list[0].value;
//             const defaultLabel = localStorage.setItem("YearLabel") || list[0].label;

//             setFinancialDDL({
//                 DDL: list,
//                 ID: defaultID,
//                 Label: defaultLabel
//             });

//             setYear({
//                 ...setYear,
//                 DDL: list,
//                 ID: defaultID,
//                 Label: defaultLabel
//             })

//             if (!localStorage.getItem("YearID") || !localStorage.getItem("YearLabel")) {
//                 localStorage.setItem("YearID", defaultID);
//                 localStorage.setItem("YearLabel", defaultLabel);
//             }
//         } else {
//             setFinancialDDL({
//                 DDL: [],
//                 ID: 0,
//                 Label: "Select..."
//             });
//             setYear({
//                 ...setYear,
//                 DDL: [],
//                 ID: 0,
//                 Label: "Select..."
//             })
//         }
//     };

//     const handleFinancialYearChange = (e) => {
//         setFinancialDDL({ ...FinancialDDL, ID: e.value, Label: e.label });
//         localStorage.setItem("YearID", e.value);
//         localStorage.setItem("YearLabel", e.label);
//     };

//     const handleFinancialYearClear = () => {
//         setFinancialDDL({ ...FinancialDDL, ID: 0, Label: "Select..." });
//         localStorage.removeItem("YearID");
//         localStorage.removeItem("YearLabel");
//     };

//     return (
//         <div className="form-group d-flex justify-content-end">
//             <label
//                 className="d-block mt-2 ml-4 d-flex"
//                 style={{ whiteSpace: "nowrap" }}
//                 htmlFor="Budgettype"
//             >
//                 Financial Year :
//             </label>
//             <Select
//                 isClearable
//                 isSearchable
//                 className="ml-2"
//                 maxMenuHeight={140}
//                 value={{ value: FinancialDDL.ID, label: FinancialDDL.Label }}
//                 onChange={(e) => {
//                     e ? handleFinancialYearChange(e) : handleFinancialYearClear();
//                 }}
//                 options={FinancialDDL.DDL}
//             />
//         </div>
//     );
// };




import Select from "react-select";
import { useEffect } from "react";
import { useAuthState } from "../../Helper/Context/context";
import { useDispatch, useSelector } from "react-redux";
import { FinancialDDLAPI } from "../../Redux/DDLSlice";

export const FinancialYearDDL = (props) => {
    const { FinancialDDL, setFinancialDDL, setYear, Year, DDLData, FlagName, editData, mendetary } = props;
    const userDetails = useAuthState();
    const { UserID, token } = userDetails;

    const dispatch = useDispatch();

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token
        };
        dispatch(FinancialDDLAPI({ data }));
    }, []);

    const { FinancialData } = useSelector((state) => state.FinancialDDLData);

    useEffect(() => {
        handleFinancialDDL();
    }, [FinancialData]);

    const handleFinancialDDL = () => {
        if (FinancialData && FinancialData.table && FinancialData.table.length > 0) {
            let list = FinancialData.table.map((item) => ({
                value: item.financialYearID,
                label: item.financialYear
            }));
            // setFinancialDDL({
            //     DDL: list,
            //     ID: localStorage.getItem('YearID') ? localStorage.getItem('YearID') : list[0].value ? list[0].value : 0,
            //     Label: localStorage.getItem('YearLabel') ? localStorage.getItem('YearLabel') : list[0].label ? list[0].label : 'Select...',
            // })

            const defaultID = localStorage.getItem('YearID') ? localStorage.getItem('YearID') : list[0]?.value;
            const defaultLabel = localStorage.getItem('YearLabel') ? localStorage.getItem('YearLabel') : list[0]?.label;

            if (!localStorage.getItem('YearID') || !localStorage.getItem('YearLabel')) {
                localStorage.setItem('YearID', JSON.stringify(list[0]?.value));
                localStorage.setItem('YearLabel', list[0]?.label);
            }

            setFinancialDDL({
                DDL: list,
                ID: defaultID ? defaultID : 0,
                Label: defaultLabel ? defaultLabel : 'Select...',
            });
        } else {
            setFinancialDDL({
                DDL: [],
                ID: 0,
                Label: "Select..."
            });
        }
    };

    const handleFinancialYearChange = (e) => {
        setFinancialDDL({ ...FinancialDDL, ID: e.value, Label: e.label });
        localStorage.setItem("YearID", JSON.stringify(e.value));
        localStorage.setItem("YearLabel", e.label);
    };

    const handleFinancialYearClear = () => {
        setFinancialDDL({ ...FinancialDDL, ID: 0, Label: "Select..." });
        localStorage.setItem("YearID", '0');
        localStorage.setItem("YearLabel", 'Select...');
    };

    return (
        <div className="form-group d-md-flex d-lg-flex justify-content-lg-end">
            <label className="d-block mt-2 ml-2"> Financial Year :</label>
            <Select
                isClearable
                isSearchable
                className="ml-2"
                maxMenuHeight={140}
                value={{ value: FinancialDDL.ID, label: FinancialDDL.Label }}
                onChange={(e) => {
                    e ? handleFinancialYearChange(e) : handleFinancialYearClear();
                }}
                options={FinancialDDL.DDL}
            />
        </div>
    );
};

