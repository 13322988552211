import React, { useState, useEffect } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Select from "react-select";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import WorkWiseMiliStonePopUp from "./WorkWiseMiliStonePopUp";
import { useAuthState } from "../../../Helper/Context/context";
import { Pegination } from "../../../Components/Pegination/Pegination";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import Footer from "../../../Components/Footer/Footer";
import ProjectTypeDDL from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import { ProjectWiseMilestoneDeleteAPI, ProjectWiseMilestoneDeleteAPIm, ProjectWiseMilestoneExportTableDataAPI, ProjectWiseMilestoneTableDataAPI } from "../../../Redux/ProjectCreationAllocationSlice/ProjectWiseMilestoneSlice";
import { useDispatch, useSelector } from "react-redux";
import { ConcateWorkAndWorkTypeDDL } from "../../../CommonDDL/ConcateWorkAndWorkTypeDDL";
import ProjectNameMilestoneDDL from "../../../CommonDDL/ProjectNameMilestoneDDL/ProjectNameMilestoneDDL";
import moment from 'moment';
import { WorkWiseMileStoneSidebarExport } from "./WorkWiseMileStoneExport";

export default function WorkWiseMiliStone() {

  const userDetails = useAuthState();
  const { UserID, token } = userDetails

  const dispatch = useDispatch();


  const [isopen, setIsOpen] = useState(true);

  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)

  const [workMileStoneTable, setworkMileStoneTable] = useState([])
  const [IsPost, setIsPost] = useState(false)
  const [loading, setloading] = useState()
  const [CurrentPage, setCurrentPage] = useState(0)
  let [RowNum, setRowNum] = useState(0)
  const YearID = localStorage.getItem("YearID")

  const [allState, setallState] = useState({
    showPopUp: false,
    showDeletePopup: false,
    popUpFlag: "",
    apiFlag: "",
    editData: {},
    deleteData: "",
  })

  const [WorkTypeDDL, setWorkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "--Select--",
  })

  const [projectTypeDDL, setProjectTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [ProjectDDL, setProjectDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })
  const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })

  const [projectNameMilestoneDDL, setProjectNameMilestoneDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
    // projectCode: '',
    // numberOfMileStone: ''
  })

  const handleOnCloseClick = () => {
    setallState({ showPopUp: false, showDeletePopup: false, popUpFlag: "", apiFlag: "", editData: {}, deleteData: "", })
  }

  const handleAddNewClick = () => {
    setallState({ ...allState, apiFlag: "Insert", popUpFlag: "Add", showPopUp: true })
  }

  const handleOnEditClick = (item) => {
    setallState({ ...allState, editData: item, apiFlag: "Update", popUpFlag: "Update", showPopUp: true })
  }

  const handleOnDeleteClick = (item) => {
    setallState({ ...allState, showDeletePopup: true, deleteData: item })
  }

  const handleOnSearchClick = () => {
    setIsPost(!IsPost)
    setCurrentPage(0)
  }

  const handleOnClearClick = () => {
    setCurrentPage(0)
    setWorkAndWorkTypeDDL({
      ...WorkAndWorkTypeDDL,
      ID: 0,
      Label: "---Select---"
    })
    setProjectTypeDDL({
      ...projectTypeDDL,
      ID: 0,
      Label: "---Select---"
    })
    setProjectDDL({
      ...ProjectDDL,
      ID: 0,
      Label: "---Select---"
    })
    setProjectNameMilestoneDDL({
      ...projectNameMilestoneDDL,
      ID: 0,
      Label: 'Select...'
    })

    setIsPost(!IsPost)
  }


  useEffect(() => {
    // Get_T_ProjectWorkWiseMilestone_Select({ setworkMileStoneTable, UserID, setloading, setTotalCount: setTotalCount, From: From, To: To, rowNo: rowNo, PerPageCount: PerPageCount, workTypeDDL, workNameDDL })
  }, [To, IsPost])

  const handlePost = () => {
    setIsPost(!IsPost)
  }

  const handleDeleteData = () => {
    const data = {
      T_ProjectWorkWiseMilestoneID: allState.deleteData,
      handlePost: handlePost,
      token: token,
      UserID: UserID,
      handleDeleteCloseClick: handleOnCloseClick
    }
    dispatch(ProjectWiseMilestoneDeleteAPI({ data }))
  }

  useEffect(() => {
    setRowNum(From)
  }, [From])

  useEffect(() => {
    setCurrentPage(0)
    setFrom(1)
    setTo(10)
  }, [projectTypeDDL.ID, ProjectDDL.ID, WorkAndWorkTypeDDL.ID])

  useEffect(() => {
    const data = {
      M_FinancialYearID: YearID ? YearID : 0,
      M_MonthID: 0,
      T_ProjectWorkWiseMilestoneID: 0,
      WorkTypeNameID: 0,
      M_ProjectID: projectNameMilestoneDDL.ID,
      M_WorkID: WorkAndWorkTypeDDL.ID,
      M_ProjectTypeID: projectTypeDDL.ID,
      UserID: UserID,
      From: From,
      token: token,
      To: To,
      // handleWorkWiseMileStoneGetExport: handleWorkWiseMileStoneGetExport
    }
    dispatch(ProjectWiseMilestoneTableDataAPI({ data }))

  }, [From, IsPost, projectTypeDDL.ID, ProjectDDL.ID, WorkAndWorkTypeDDL.ID, projectNameMilestoneDDL.ID])

  const { tableData, isLoading } = useSelector(state => state.ProjectWiseMilestoneTableData)

  useEffect(() => {
    const data = {
      M_FinancialYearID: YearID ? YearID : 0,
      M_MonthID: 0,
      T_ProjectWorkWiseMilestoneID: 0,
      WorkTypeNameID: 0,
      M_ProjectID: projectNameMilestoneDDL.ID,
      M_WorkID: WorkAndWorkTypeDDL.ID,
      M_ProjectTypeID: projectTypeDDL.ID,
      UserID: UserID,
      From: From,
      token: token,
      To: '99999',
    }
    dispatch(ProjectWiseMilestoneExportTableDataAPI({ data }))
  }, [From, IsPost, projectTypeDDL.ID, ProjectDDL.ID, WorkAndWorkTypeDDL.ID, projectNameMilestoneDDL.ID])

  const { ExporttableData, isExportLoading } = useSelector(state => state.ProjectWiseMilestoneExportTableData)
  return (
    <>
      {isLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="workwisemilistone" subMenu="subProCreation" isopen={isopen} />
          {/* <!-- Main Container --> */}
          <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-6 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold"> Project Wise Milestones</h4>
                    </div>
                    <div className="col-6 col-lg-3 mb-xl-0 py-2">
                      <h4 className="font-weight-bold float-right px-2 py-1 mr-3 btn-outline-count rounded">
                        Total Count :- <span className='text-danger px-2'>{tableData && tableData[0]?.totalCount ? tableData[0]?.totalCount : 0}</span>
                      </h4>
                    </div>
                    <div className="col-12 col-lg-3 mb-xl-0 align-self-center align-items-end text-right">
                      <button type="button" className="btn btn-primary btn-sm"
                        title="Add" onClick={() => handleAddNewClick()}>
                        <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                        Add
                      </button>
                      {
                        isExportLoading ?
                          <button type="button" className="btn btn-success btn-sm" disabled>
                            <i className="fa fa-refresh fa-spin" style={{ fontSize: "15px", marginRight: "5px" }}></i>
                            Loading..
                          </button>
                          :
                          tableData && tableData && tableData.length > 0 &&
                          <WorkWiseMileStoneSidebarExport
                            ExportData={ExporttableData}
                            name='Project Wise Milestones'
                          // title={title}
                          // projectType={projectType}
                          />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    {/* <div className="card filter mb-4"> */}
                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-lg-3 col-md-3">
                          <ProjectTypeDDLData
                            ProjectTypeDDL={projectTypeDDL}
                            setProjectTypeDDL={setProjectTypeDDL}

                          // PopUpField={PopUpField}

                          />
                        </div>

                        <div className="col-lg-3 col-md-3">
                          <ConcateWorkAndWorkTypeDDL
                            WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                            setWorkAndWorkTypeDDL={setWorkAndWorkTypeDDL}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <ProjectNameMilestoneDDL
                            projectNameMilestoneDDL={projectNameMilestoneDDL}
                            setProjectNameMilestoneDDL={setProjectNameMilestoneDDL}
                            projectTypeDDL={projectTypeDDL}
                            WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                            mendetary='no'
                            FlagName='ProjectMilestones'
                          />
                        </div>

                        <div className="col-12 col-lg-2 pl-lg-3">
                          <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick}
                          >
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th>Project Type</th>
                          <th style={{ whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em' }}>Project Name</th>
                          <th>Main Work Type</th>
                          <th>No of Milestone</th>
                          <th style={{ width: "250px", textAlign: "center" }}>Milestone Name</th>
                          <th style={{ width: "50px", textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tableData && tableData.length > 0 ? tableData.map((item, i) => (
                            <tr key={i + 1}>
                              <td className="text_center">{RowNum ? RowNum++ : '-'}</td>
                              <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                              <td>{item.projectName ? item.projectName : "-"}</td>
                              <td>{item.workTypeName ? item.workTypeName : "-"}</td>
                              <td className='text-right' width="3%">{item.numberOfMileStone ? item.numberOfMileStone : "-"}</td>
                              <td>
                                <span className="milestoneName">
                                  {
                                    // item.dataList && item.dataList.length > 0 ?
                                    item.dataList.map((each, index) => (
                                      <span className="mr-2">{index + 1 + ". "}{each.milestone_Name ? each.milestone_Name : "-"}</span>
                                    ))
                                    // : "-"
                                  }
                                </span>
                              </td>

                              <td style={{ textAlign: "center" }} >
                                {
                                  item.isProjectSanction === 'Yes' ?
                                    '-' :
                                    <div className="d-flex">
                                      <span className="mx-2 cursor-pointer"
                                        title="Edit" onClick={() => handleOnEditClick(item)}>
                                        <Edit size={15} color="#007bff" />
                                      </span>

                                      <span className="mx-2 cursor-pointer"
                                        title="Delete" onClick={() => handleOnDeleteClick(item.t_ProjectWorkWiseMilestoneID)}>
                                        <Trash2 size={15} color="red" />
                                      </span>
                                    </div>
                                }
                              </td>
                            </tr>
                          )) : <tr style={{ whiteSpace: 'nowrap' }}>No data</tr>
                        }

                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>


      {allState.showPopUp ? <WorkWiseMiliStonePopUp
        open={allState.showPopUp}
        handleOnCloseClick={handleOnCloseClick}
        allState={allState}
        IsPost={IsPost}
        setIsPost={setIsPost}
        handleOnClearClick={handleOnClearClick}
      /> : <></>}

      {allState.showDeletePopup ? <DeletePopUp
        open={allState.showDeletePopup}
        handleOnCloseClick={handleOnCloseClick}
        handleDeleteData={handleDeleteData}
      /> : <></>}

    </>
  )
}