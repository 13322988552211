import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import PhysicalSpillOverCard from './PhysicalSpillOverCard'
import { GroupBTN } from './WardWiseProjectDetails/GroupBTn/GroupBTN'
import { WardWiseProjectCountDataAPI } from '../../../Redux/DashboardSlice/DashBoardCountSlice/DashBoardCountSlice'
import { useAuthState } from '../../../Helper/Context/context'
import { useDispatch, useSelector } from 'react-redux'

const PhysicalSpillOver = (props) => {
	const { DashBoardMonthDDL, FinancialDDL } = props

	const userDetails = useAuthState();
	const { UserID, token } = userDetails
	const dispatch = useDispatch();

	const [activeClass, setActiveClass] = useState(sessionStorage.getItem("ActiveButton") ? sessionStorage.getItem("ActiveButton") : "Proposed")

	useEffect(() => {
		sessionStorage.setItem("ActiveButton", activeClass)
	}, [activeClass])

	const YearID = localStorage.getItem("YearID")
	const MonthID = localStorage.getItem("MonthID")

	const { FinancialData } = useSelector((state) => state.FinancialDDLData);
    const { DashboardMonthData } = useSelector(state => state.DashboardMonthDDLData)

	useEffect(() => {
		const data = {
			M_FinancialYearID: YearID ? YearID : 0,
			M_MonthID: DashBoardMonthDDL.ID,
			M_UsersID: UserID,
			Flag: 'WEB_Ward',
			ShowBy: activeClass == 'Proposed' ? 'Proposed' :
				activeClass == 'OnGoing' ? 'OnGoing' : 'Completed',
			token: token,
			// handleGetCount: handleGetCount
		}
		{
			YearID &&
				dispatch(WardWiseProjectCountDataAPI({ data }))
		}
	}, [YearID,DashBoardMonthDDL.ID,activeClass])

	const { WardWiseProjectCountData, WardWiseProjectisLoading } = useSelector(state => state.WardWiseProjectCountData)

	return (
		<div className="card mt-4">

			<div className="card-header font-weight-bold">
				Ward Wise Project Details
				<GroupBTN setActiveClass={setActiveClass} activeClass={activeClass} />
			</div>
			<div className="card-body">
				<div className="tab-content w-100 gray-bg" role="tab-container">
					<div className="tab-pane fade show active" id="Capital" role="tabpanel" aria-labelledby="Capital-tab">

						<div className="tab-content w-100 p-0" role="tab-container">
							<div className="tab-pane fade active show p-0" id="allCapitalPso">
								<div className="row five-blocks">

									{
										WardWiseProjectCountData && WardWiseProjectCountData.table && WardWiseProjectCountData.table.length > 0 ?
											WardWiseProjectCountData.table.map((i) => (
												<PhysicalSpillOverCard
													CardID={i.m_WardID ? i.m_WardID : 0}
													Cardtitle={i.wardName ? i.wardName : ''}
													count={i.totalCount ? i.totalCount : 0}
													path="/CommonWardWiseProjectDetailsScreen"
													setActiveClass={setActiveClass}
													activeClass={activeClass}
													cardName={activeClass}
													title={`${activeClass} Project`}
												/>

											))
											:
											<div>
												<h3 className='text-center text-danger'>No data Found</h3>
											</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PhysicalSpillOver
