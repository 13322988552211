import { useEffect } from 'react'
import Select from 'react-select'
import { RoleDDLAPI } from '../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../Helper/Context/context'
import { Star } from '../Components/CommoComponent/Star'


export const RoleDDLData = (props) => {
    const { RoleDDL, setRoleDDL, Flag,PopUpField,IsPost, mendetary } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = { UserID: UserID, token: token }
        dispatch(RoleDDLAPI({ data }))
    }, [IsPost])

    const { RoleData } = useSelector(state => state.RoleDDLData)

    useEffect(() => {
        handleSubWorkTypeDDL();
    }, [RoleData])

    const handleSubWorkTypeDDL = () => {
        if (RoleData && RoleData.table && RoleData.table.length > 0) {
            let list = RoleData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.roleName,
            }))

            setRoleDDL({
                DDL: list,
                ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_RoleID : 0 : '0',
                Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.roleName : "---Select---" : "---Select---",
            })
        }
        else {
            setRoleDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"> Role {mendetary=== 'yes' ? <Star/> : ''} </label>
            <Select
                isClearable
                isSearchable
                maxMenuHeight={150}
                value={{ value: RoleDDL.ID, label: RoleDDL.Label }}
                onChange={(e) => {
                    e ?
                        setRoleDDL({ ...RoleDDL, ID: e.value, Label: e.label })
                        :
                        setRoleDDL({ ...RoleDDL, ID: 0, Label: "---Select---" })

                }}
                options={RoleDDL.DDL}
            // isDisabled
            />
        </div>
    )
}