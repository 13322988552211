import React, { useState } from 'react'
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import Select from "react-select"
import MobileHeader from '../../../../Components/Header/MobileHeader';
import { useNavigate } from 'react-router-dom';
import { Search, X } from 'react-feather';



export default function CompletedTotalProjectTable() {
    const [isopen, setIsOpen] = useState(true);

    const navigate = useNavigate()

    const handleOnClick = () => {
        navigate("/completedpublicworkdepartment")
    }
    const options = [
        { value: 'Public Works Department', label: 'Public Works Department' },
        { value: 'Garden Department', label: 'Garden Department' },
        { value: 'Department 3', label: 'Department 3' }
    ]
    return (
        <div>
            <div className="wrapper">
                <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen}/>
                    <Header setIsOpen={setIsOpen} isopen={isopen} />
                    <div className="container-fluid justify-content-center page-body-wrapper">
                        <Sidebar isopen={isopen} />
                        <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin">
                                        <div className="row page-heading">
                                            <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                                <h4 className="font-weight-bold">Completed Projects</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 table-card">
                                        <div className=" filter mb-0">
                                            <div className="card-body">
                                                <div className="form-row">
                                                    <div className="col-12 col-md-6 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="d-block font-weight-bold" for="WardNo">Name of Department</label>
                                                            <Select
                                                                isClearable
                                                                isSearchable
                                                                options={options}

                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-4 text-center">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 waves-effect waves-light">
                                                            {/* <Search/> */}
                                                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                        </button>
                                                        <button type="button" className="btn btn-primary text-white mr-2 mt-4 waves-effect waves-light">
                                                            {/* <Search/> */}
                                                            <Search size={18} style={{ marginRight: "3px" }} />  Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="tbl-sticky">
                                                <div className="table-responsive">
                                                    <table cellpadding="0" cellspacing="0" border="0" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr.No.</th>
                                                                <th>Name of Department</th>
                                                                <th>Total Projects</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Public Works Department</td>
                                                                <td><a onClick={handleOnClick} className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>Garden Department</td>
                                                                <td><a to="/totalproject" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>Public Works Department</td>
                                                                <td><a to="/totalproject" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>Garden Department</td>
                                                                <td><a to="/totalproject" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>Public Works Department</td>
                                                                <td><a to="/totalproject" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>Garden Department</td>
                                                                <td><a to="/totalproject" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>Public Works Department</td>
                                                                <td><a to="/totalproject" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>Garden Department</td>
                                                                <td><a to="/totalproject" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>9</td>
                                                                <td>Public Works Department</td>
                                                                <td><a to="/totalproject" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>10</td>
                                                                <td>Garden Department</td>
                                                                <td><a to="/totalproject" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row"><div className="col-sm-12 col-md-5"><div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 1 to 10 of 25 entries</div></div><div className="col-sm-12 col-md-7"><div className="dataTables_paginate paging_simple_numbers" id="example_paginate"><ul className="pagination"><li className="paginate_button page-item previous disabled" id="example_previous"><a href="#" aria-controls="example" data-dt-idx="0" tabindex="0" className="page-link">Previous</a></li><li className="paginate_button page-item active"><a href="#" aria-controls="example" data-dt-idx="1" tabindex="0" className="page-link">1</a></li><li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="2" tabindex="0" className="page-link">2</a></li><li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="3" tabindex="0" className="page-link">3</a></li><li className="paginate_button page-item next" id="example_next"><a href="#" aria-controls="example" data-dt-idx="4" tabindex="0" className="page-link">Next</a></li></ul></div></div></div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
