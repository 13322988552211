import React from 'react'

const Footer = () => {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <div className="copyright">
            Copyright {year}. all rights are reserved.<b>Version 1.0</b>
        </div>
    )
}

export default Footer
