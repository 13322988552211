import React, { useState } from 'react'
import { Search, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Select from "react-select"
import Header from '../../../../Components/Header/Header';
import MobileHeader from '../../../../Components/Header/MobileHeader';
import Sidebar from '../../../../Components/Sidebar/Sidebar';



const PendingUtilizationTablePage = () => {

    const [isopen, setIsOpen] = useState(true);

    const navigate = useNavigate()
    const handleOnClick = (val) => {
        navigate("/pendingutilizationelectricaldepartment")
        console.log(val);
    }
    const options = [
        { value: 'New', label: 'New' },
        { value: 'Ongoing', label: 'Ongoing' },
        { value: 'Completed', label: 'Completed' }
    ]

    const options1 = [
        { value: 'Public Works Department', label: 'Public Works Department' },
        { value: 'WATER Work Department', label: 'WATER Work Department' },
        { value: 'Health Department', label: 'Health Department' },
        { value: 'Solid Waste Management', label: 'Solid Waste Management' },
        { value: 'Electrical Department', label: 'Electrical Department' },
        { value: 'Sewerage Department', label: 'Sewerage Department' }
    ]

    return (
        <div className="wrapper">
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar isopen={isopen} />
                    <div
                        className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Pending Utilization - Since 15 Days</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="Budgettype">Status</label>

                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options}

                                                        />



                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="WardNo">Department</label>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options1}

                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-4 text-center">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>
                                                    <button type="button" className="btn btn-primary text-white mr-2 mt-4 waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <Search size={18} style={{ marginRight: "3px" }} />  Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered ">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name of Department</th>
                                                    <th>Pending Utilization</th>
                                                    <th>Total Projects</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Public Works Department</td>
                                                    <td>3</td>
                                                    <td><a onClick={() => handleOnClick()} className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>WATER Work Department</td>
                                                    <td>3</td>
                                                    <td><a href="total_utilization_projects.html" className="btn btn-primary btn-sm px-3 py-2">20</a></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Health Department</td>
                                                    <td>3</td>
                                                    <td><a href="total_utilization_projects.html" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Garden Department</td>
                                                    <td>3</td>
                                                    <td><a href="total_utilization_projects.html" className="btn btn-primary btn-sm px-3 py-2">20</a></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Solid Waste Management</td>
                                                    <td>3</td>
                                                    <td><a href="total_utilization_projects.html" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Electrical Department</td>
                                                    <td>3</td>
                                                    <td><a href="total_utilization_projects.html" className="btn btn-primary btn-sm px-3 py-2">20</a></td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>Sewerage Department</td>
                                                    <td>3</td>
                                                    <td><a href="total_utilization_projects.html" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>Garden Department</td>
                                                    <td>3</td>
                                                    <td><a href="total_utilization_projects.html" className="btn btn-primary btn-sm px-3 py-2">20</a></td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>Public Works Department</td>
                                                    <td>3</td>
                                                    <td><a href="total_utilization_projects.html" className="btn btn-primary btn-sm px-3 py-2">10</a></td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>Garden Department</td>
                                                    <td>3</td>
                                                    <td><a href="total_utilization_projects.html" className="btn btn-primary btn-sm px-3 py-2">20</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PendingUtilizationTablePage
