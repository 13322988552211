import React, { useState } from "react";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import Select from "react-select"
import { useNavigate } from "react-router-dom";
import MobileHeader from "../../../Components/Header/MobileHeader";
import BudgetTypePopUp from "./BudgetTypePopUp";
import DeletePopUp from "../../../Helper/DeletePopUp";
import { Edit, Edit2, Search, Trash, X } from "react-feather";

export default function BudgetType() {

    const navigate = useNavigate()

    const onNavigate = () => {
        navigate("/totalbudget")
    }

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);


    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
    }
    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        setflag("Add")
    }
    const handleOnEditClick = () => {
        setshowCreatePopup(true)
        setflag("Edit")
    }
    const handleOnDeleteClick = () => {
        setshowDeletePopup(true)
        setflag("Delete")
    }

    const tableData = [
        {
            id: 1,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Capital",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
        {
            id: 2,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Revenue",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
        {
            id: 3,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Revenue",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
        {
            id: 4,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Capital",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
        {
            id: 5,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Revenue",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
    ]

    const options = [
        { value: 'Capital', label: 'Capital' },
        { value: 'Revenue', label: 'Revenue' },
    ]


    return (
        <>
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="budgettype" isopen={isopen} />
                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Budget Type</h4>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                title="Add" onClick={() => handleAddNewClick()}>
                                                <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="filter mb-4">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-4 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="Budgettype">Budget Type </label>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options}

                                                        />
                                                    </div>

                                                </div>

                                                <div className="col-6 col-lg-6">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 float-right waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>
                                                    <button type="button" className="btn btn-primary text-white mr-2 mt-4 mt-md-0 mt-lg-4 float-right waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <Search size={18} style={{ marginRight: "3px" }} />  Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table cellpadding="0" cellspacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "5%" }}>Sr.No.</th>
                                                    <th>Budget Type </th>
                                                    <th style={{ width: "150px", textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableData && tableData.length > 0 ? tableData.map((item, i) => (
                                                        <tr>
                                                            <td className="text_center">{i + 1}</td>
                                                            <td>{item.BudgetHead}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <span className="btn btn-sm btn-outline-primary out-btn margin_tb"
                                                                    title="Edit" onClick={() => handleOnEditClick()}>
                                                                    <Edit size={15} />
                                                                </span>

                                                                <span className="btn btn-sm btn-outline-danger out-btn margin_tb"
                                                                    title="Delete" onClick={() => handleOnDeleteClick()}>
                                                                    <Trash size={15} />
                                                                </span>

                                                            </td>
                                                        </tr>
                                                    )) : ""
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 1 to 10 of 25
                                                entries</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                                <ul className="pagination">
                                                    <li className="paginate_button page-item previous disabled" id="example_previous"><a href="#"
                                                        aria-controls="example" data-dt-idx="0" tabindex="0" className="page-link">Previous</a></li>
                                                    <li className="paginate_button page-item active"><a href="#" aria-controls="example" data-dt-idx="1"
                                                        tabindex="0" className="page-link">1</a></li>
                                                    <li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="2"
                                                        tabindex="0" className="page-link">2</a></li>
                                                    <li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="3"
                                                        tabindex="0" className="page-link">3</a></li>
                                                    <li className="paginate_button page-item next" id="example_next"><a href="#" aria-controls="example"
                                                        data-dt-idx="4" tabindex="0" className="page-link">Next</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="copyright">
                                Copyright 2023. all rights are reserved.<b>Version 1.0</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showCreatePopup ? <BudgetTypePopUp open={showCreatePopup} handleOnCloseClick={handleOnCloseClick} flag={flag} /> : <></>}
            {showDeletePopup ? <DeletePopUp open={showDeletePopup} handleOnCloseClick={handleOnCloseClick} /> : <></>}

        </>
    )
}