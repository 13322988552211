import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const ProjectWiseMilestoneTableDataAPI = createAsyncThunk("ProjectWiseMilestoneTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_ProjectTypeID,
        T_ProjectWorkWiseMilestoneID,
        WorkTypeNameID,
        M_ProjectID,
        M_WorkID,
        UserID,
        From,
        token,
        To,
        // handleGetExport
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_T_ProjectWorkWiseMilestone_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : 0}&T_ProjectWorkWiseMilestoneID=${T_ProjectWorkWiseMilestoneID ? T_ProjectWorkWiseMilestoneID : '0'}&WorkTypeNameID=${WorkTypeNameID ? WorkTypeNameID : '0'}&M_WorkID=${M_WorkID ? M_WorkID : '0'}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data_Details) {
                // handleGetExport()
                return result.data_Details
            } else {
                return result
            }
        })
})

const ProjectWiseMilestoneDataSlice = createSlice({
    name: "ProjectWiseMilestoneTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseMilestoneTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectWiseMilestoneTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectWiseMilestoneTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseMilestoneTableDataReducer = ProjectWiseMilestoneDataSlice.reducer

// Export Api

export const ProjectWiseMilestoneExportTableDataAPI = createAsyncThunk("ProjectWiseMilestoneExportTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_ProjectTypeID,
        T_ProjectWorkWiseMilestoneID,
        WorkTypeNameID,
        M_ProjectID,
        M_WorkID,
        UserID,
        From,
        token,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_T_ProjectWorkWiseMilestone_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : 0}&T_ProjectWorkWiseMilestoneID=${T_ProjectWorkWiseMilestoneID ? T_ProjectWorkWiseMilestoneID : '0'}&WorkTypeNameID=${WorkTypeNameID ? WorkTypeNameID : '0'}&M_WorkID=${M_WorkID ? M_WorkID : '0'}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data_Details) {
                return result.data_Details
            } else {
                return result
            }
        })
})

const ProjectWiseMilestoneExportTableDataSlice = createSlice({
    name: "ProjectWiseMilestoneExportTableData",
    initialState: {
        isExportLoading: false,
        ExporttableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseMilestoneExportTableDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(ProjectWiseMilestoneExportTableDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExporttableData = action.payload;
        });
        builder.addCase(ProjectWiseMilestoneExportTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExporttableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseMilestoneExportTableDataReducer = ProjectWiseMilestoneExportTableDataSlice.reducer

// InsertUpdate Table Data

export const ProjectWiseMilestonePostAPI = createAsyncThunk("ProjectWiseMilestonePostData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        T_ProjectWorkWiseMilestoneID,
        RawData,
        NumberOfMileStone,
        M_ProjectID,
        M_WorkID,
        UserID,
        token,
        Flag,
        T_ProjectDetailsID,
        handlePost,
        handleAddCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_FinancialYearID", M_FinancialYearID);
    formdata.append("M_MonthID", M_MonthID);
    formdata.append("T_ProjectWorkWiseMilestoneID", T_ProjectWorkWiseMilestoneID);
    formdata.append("M_ProjectID", M_ProjectID);
    formdata.append("T_ProjectDetailsID", T_ProjectDetailsID);
    formdata.append("RawData", RawData);
    formdata.append("M_WorkID", M_WorkID);
    formdata.append("NumberOfMileStone", NumberOfMileStone);
    formdata.append("UserID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectWorkWiseMilestone_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const ProjectWiseMilestonePostSlice = createSlice({
    name: "ProjectWiseMilestonePostData",
    initialState: {
        isLoading: false,
        ProjectSanctionData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseMilestonePostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectWiseMilestonePostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectSanctionData = action.payload;
        });
        builder.addCase(ProjectWiseMilestonePostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectSanctionData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseMilestonePostDataReducer = ProjectWiseMilestonePostSlice.reducer



// Delete Table Data

export const ProjectWiseMilestoneDeleteAPI = createAsyncThunk("ProjectWiseMilestoneDelete", async ({ data }) => {

    const {
        T_ProjectWorkWiseMilestoneID,
        handlePost,
        token,
        UserID,
        handleDeleteCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_ProjectWorkWiseMilestoneID", T_ProjectWorkWiseMilestoneID);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectWorkWiseMilestone_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                toastSuccesss(result.message)
                handleDeleteCloseClick()
                handlePost()
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const ProjectWiseMilestoneDeleteSlice = createSlice({
    name: "ProjectWiseMilestoneDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseMilestoneDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectWiseMilestoneDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;

        });
        builder.addCase(ProjectWiseMilestoneDeleteAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseMilestoneDeleteReducer = ProjectWiseMilestoneDeleteSlice.reducer