
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"
import { BudgetHeadDDLAPI } from "../../Redux/DDLSlice"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../Helper/Context/context"



export default function BudgetHeadDDL(props) {
    const { budgetHeadDDL, setBudgetHeadDDL, ProjectNameDDLData, FlagName, apiFlag, editData, mendetary } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch();


    useEffect(() => {
        const data = {
            UserID: UserID,
            M_BudgetTypeID: 0,
            token: token,
        }
        dispatch(BudgetHeadDDLAPI({ data }))
    }, [])

    const { BudgetHeadData } = useSelector(state => state.BudgetHeadDDLData)

    useEffect(() => {
        handleBudgetHeadDDL()
    }, [BudgetHeadData])
    const handleBudgetHeadDDL = () => {
        if (BudgetHeadData && BudgetHeadData.table && BudgetHeadData.table.length > 0) {

            let list = BudgetHeadData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.budgetName,
                budgetCode: item.budgetCode
            }))
            setBudgetHeadDDL({
                DDL: list,
                ID: apiFlag === "Update" ? editData.m_BudgetID : 0,
                Label: apiFlag === "Update" ? editData.budgetName : "Select...",
                budgetCode: apiFlag === "Update" ? editData.budgetCode : "Select...",
                // ID: PopUpField ? PopUpField?.apiFlag === "Insert" ? 0 : PopUpField?.rowData?.m_Product_CategoryID : "0",
                // Label: PopUpField ? PopUpField?.apiFlag === "Insert" ? "Select..." : PopUpField?.rowData?.categoryName : "Select...",
            })
        }
        else {
            setBudgetHeadDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
                budgetCode: '',
            })
        }

    }

    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Budget Head {mendetary === 'yes' ? <Star/> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: budgetHeadDDL.ID, label: budgetHeadDDL.Label, budgetCode: budgetHeadDDL.budgetCode }}
                    onChange={(e) => {
                        e ?
                            setBudgetHeadDDL({ ...budgetHeadDDL, ID: e.value, Label: e.label, budgetCode:e.budgetCode })
                            :
                            setBudgetHeadDDL({ ...budgetHeadDDL, ID: 0, Label: "---Select---", budgetCode:'' })
                    }}
                    options={budgetHeadDDL.DDL}

                />
            </div>
        </>
    )
}