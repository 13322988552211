import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";





export const BudgetMasterTableDataAPI = createAsyncThunk("BudgetMasterTableData", async ({ data }) => {
    const { BudgetTypeID,
        UserID,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Budget_Select?BudgetTypeID=${BudgetTypeID ? BudgetTypeID : '0'}&UserID=${UserID ? UserID : '0'}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const BudgetMasterTableDataSlice = createSlice({
    name: "ManPowerTypeTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(BudgetMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(BudgetMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(BudgetMasterTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const BudgetMasterTableDataReducer = BudgetMasterTableDataSlice.reducer


// InsertUpdate Table Data

export const BudgetMasterPostAPI = createAsyncThunk("BudgetMasterPostData", async ({ data }) => {
    const {
        handleOnCloseClick,
        setIsPost,
        IsPost,
        handleOnClearClick,
        M_BudgetID,
        M_BudgetTypeID,
        BudgetName,
        BudgetCode,
        UserID,
        token,
        Flag, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_BudgetID", M_BudgetID);
    formdata.append("M_BudgetTypeID", M_BudgetTypeID);
    formdata.append("BudgetName", BudgetName);
    formdata.append("BudgetCode", BudgetCode);
    formdata.append("UserID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_Budget_InserUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
})

const BudgetMasterPostSlice = createSlice({
    name: "BudgetMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(BudgetMasterPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(BudgetMasterPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(BudgetMasterPostAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const BudgetMasterPostDataReducer = BudgetMasterPostSlice.reducer



// Delete Table Data

export const BudgetMasterDeleteAPI = createAsyncThunk("DepartmentDelete", async ({ data }) => {
    // const { rowData, apiFlag } = PopUpField
    const {
        handlePost, 
        token, 
        UserID, 
        handleDeleteCloseClick, 
        M_BudgetID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_BudgetID", M_BudgetID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_Budget_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                // toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handlePost()
            handleDeleteCloseClick()
            return result

        })
})

const BudgetMasterDeleteSlice = createSlice({
    name: "BudgetMasterDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(BudgetMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(BudgetMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(BudgetMasterDeleteAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const  BudgetMasterDeleteReducer = BudgetMasterDeleteSlice.reducer