import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const DefineRoleTableDataAPI = createAsyncThunk("DefineRoleTableData", async ({ data }) => {
    const {
        M_RoleID,
        M_DeptOfficeTypeIndicatorID,
        RoleName,
        UserID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Role_Select?M_RoleID=${M_RoleID ? M_RoleID : '0'}&M_DeptOfficeTypeIndicatorID=${M_DeptOfficeTypeIndicatorID}&RoleName=${RoleName ? RoleName : ''}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DefineRoleTableDataSlice = createSlice({
    name: "DefineRoleTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DefineRoleTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DefineRoleTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DefineRoleTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DefineRoleTableDataReducer = DefineRoleTableDataSlice.reducer

// InsertUpdate Table Data

export const DefineRolePostAPI = createAsyncThunk("DefineRolePostData", async ({ addData }) => {
    const {
        M_RoleID,
        M_DeptOfficeTypeIndicatorID,
        RoleName,
        DisplayOrder,
        UserID,
        Flag,      
        token,        
        handlePost,
        handleAddCloseClick
    } = addData

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_RoleID", M_RoleID);
    formdata.append("M_DeptOfficeTypeIndicatorID", M_DeptOfficeTypeIndicatorID);
    formdata.append("RoleName", RoleName);
    formdata.append("DisplayOrder", DisplayOrder);
    formdata.append("UserID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Role_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const DefineRoleNameSlice = createSlice({
    name: "DefineRolePostData",
    initialState: {
        isLoading: false,
        DefineRoleData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DefineRolePostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DefineRolePostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DefineRoleData = action.payload;
        });
        builder.addCase(DefineRolePostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DefineRoleData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DefineRolePostDataReducer = DefineRoleNameSlice.reducer



// Delete Table Data

export const DefineRoleDeleteAPI = createAsyncThunk("DefineRoleDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_RoleID", rowData?.m_RoleID);
    formdata.append("M_UserID", UserID);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/PTMS/Master/Post_M_Role_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const DefineRoleDeleteSlice = createSlice({
    name: "DefineRoleDelete",
    initialState: {
        isDeleteLoading: false,
        DefineRoletableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DefineRoleDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(DefineRoleDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.DefineRoletableData = action.payload;

        });
        builder.addCase(DefineRoleDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.DefineRoletableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DefineRoleDeleteReducer = DefineRoleDeleteSlice.reducer