import React, { useState } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Select from "react-select";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import { Get_T_WorkDefine_Select } from "../CreateProjectAPI/GETAPI";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { useEffect } from "react";
import { Pegination } from "../../../Components/Pegination/Pegination";
import { useAuthState } from "../../../Helper/Context/context";
import { Get_DDL_M_ProjectType, Get_DDL_M_WorkType, Get_DDL_T_ProjectDefine_ProjectType } from "../../../APIs/DDLAPI/DDLAPI";
import { Post_T_WorkDefine_Delete } from "../CreateProjectAPI/POSTAPI";
import ProjectWiseWorkPopUp from "./ProjectWiseWorkPopUp";
import Footer from "../../../Components/Footer/Footer";


export default function ProjectWiseWork() {

  const userDetails = useAuthState();
  const { UserID } = userDetails

  const [isopen, setIsOpen] = useState(true);

  // react pagination states 
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)

  // department Api states 
  const [workdefineTable, setworkdefineTable] = useState([])
  const [IsPost, setIsPost] = useState(false)
  const [loading, setloading] = useState()
  const [CurrentPage, setCurrentPage] = useState(0)

  const [allState, setallState] = useState({
    showCreatePopup: false,
    showDeletePopup: false,
    apiFlag: "",
    popUpFlag: "",
    editData: {},
    deleteData: "",
  })

  const [projectTypeDDL, setprojectTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })
  // console.log(projectTypeDDL.ID);
  const [workTypeDDL, setworkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })

  const handleOnCloseClick = () => {
    setallState({apiFlag: "",popUpFlag: "",deleteData:"" ,editData: {}, showCreatePopup: false,showDeletePopup: false })
  }

  const handleAddNewClick = () => {
    setallState({ ...allState, popUpFlag: "Add", apiFlag: "Insert", showCreatePopup: true })
  }

  const handleOnEditClick = (item) => {
    setallState({ ...allState, popUpFlag: "Update", apiFlag: "Update", editData: item, showCreatePopup: true })
  }
  const handleOnDeleteClick = (item) => {
    setallState({ ...allState, deleteData: item, showDeletePopup: true })
  }

  const handleOnSearchClick = () => {
    setIsPost(!IsPost)
    setCurrentPage(0)
  }

  const handleOnClearClick = () => {
    setCurrentPage(0)
    setprojectTypeDDL({
      ...projectTypeDDL,
      ID: 0,
      Label: "---Select---"
    })
    setworkTypeDDL({
      ...workTypeDDL,
      ID: 0,
      Label: "---Select---"
    })
    setIsPost(!IsPost)
  }

  useEffect(() => {
    Get_DDL_T_ProjectDefine_ProjectType({ UserID, projectTypeDDL: projectTypeDDL, setprojectTypeDDL: setprojectTypeDDL })
  }, [])

  useEffect(() => {
    Get_DDL_M_WorkType({ setworkDDL: setworkTypeDDL, workDDL: workTypeDDL, UserID })
  }, [workTypeDDL.ID])

  useEffect(() => {
    Get_T_WorkDefine_Select({ setworkdefineTable, UserID, setloading, projectTypeDDL, workTypeDDL, setTotalCount: setTotalCount, From: From, To: To, rowNo: rowNo, PerPageCount: PerPageCount })
  }, [To, IsPost])

  const handleDeleteData = () => {
    Post_T_WorkDefine_Delete({
      UserID,
      deleteData: allState.deleteData,
      handleOnCloseClick,
      setIsPost,
      IsPost
    })
  }


  return (
    <>
      {loading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="workdefine" subMenu="subProCreation" isopen={isopen} />
          {/* <!-- Main Container --> */}
          <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">Project Wise Work</h4>
                    </div>
                    <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">

                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        title="Add"
                        onClick={() => handleAddNewClick()}
                      >
                        <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                        Add
                      </button>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    {/* <div className="card filter mb-4"> */}
                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group">
                            <label className="d-block" htmlFor="Budgettype">Project Type </label>
                            <Select
                              isClearable
                              isSearchable
                              value={{ value: projectTypeDDL.ID, label: projectTypeDDL.Label }}
                              onChange={(e) => {
                                e ?
                                  setprojectTypeDDL({ ...projectTypeDDL, ID: e.value, Label: e.label })
                                  :
                                  setprojectTypeDDL({ ...projectTypeDDL, ID: 0, Label: "---Select---" })

                              }}
                              options={projectTypeDDL.DDL}

                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6  col-lg-4">
                          <div className="form-group">
                            <label className="d-block" htmlFor="Budgettype">Work Type </label>
                            <Select
                              isClearable
                              isSearchable
                              value={{ value: workTypeDDL.ID, label: workTypeDDL.Label }}
                              onChange={(e) => {
                                e ?
                                  setworkTypeDDL({ ...workTypeDDL, ID: e.value, Label: e.label })
                                  :
                                  setworkTypeDDL({ ...workTypeDDL, ID: 0, Label: "---Select---" })

                              }}
                              options={workTypeDDL.DDL}

                            />
                          </div>
                        </div>

                        <div className="col-12 col-lg-4 text-center">
                          <button type="button" className="btn btn-primary text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnSearchClick}>
                            <Search size={18} style={{ marginRight: "3px" }} />  Search
                          </button>
                          <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick}>
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th>Project Type</th>
                          <th>Project Name</th>
                          <th>Work Type</th>
                          <th>Work Name</th>
                          <th>Work Area</th>
                          <th style={{ width: "150px", textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          workdefineTable && workdefineTable.length > 0 ? workdefineTable.map((item, i) => (
                            <tr key={item.id}>
                              <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                              <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                              <td>{item.projectName ? item.projectName : "-"}</td>
                              <td>{item.workTypeName ? item.workTypeName : "-"}</td>
                              <td>{item.workName ? item.workName : "-"}</td>
                              <td>{item.workArea ? item.workArea : "-"}</td>
                              <td style={{ textAlign: "center" }}>
                                <span className="mx-2 cursor-pointer"
                                  title="Edit" onClick={() => handleOnEditClick(item)}>
                                  <Edit size={15} color="#007bff" />
                                </span>

                                <span className="mx-2 cursor-pointer"
                                  title="Delete" onClick={() => handleOnDeleteClick(item.id)}>
                                  <Trash2 size={15} color="red" />
                                </span>

                              </td>
                            </tr>
                          )) : <tr>No data</tr>
                        }


                      </tbody>
                    </table>
                  </div>
                  {workdefineTable && workdefineTable.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={TotalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>


      {allState.showCreatePopup ?
        <ProjectWiseWorkPopUp
          open={allState.showCreatePopup}
          handleOnCloseClick={handleOnCloseClick}
          allState={allState}
          IsPost={IsPost}
          setIsPost={setIsPost}
          handleOnClearClick={handleOnClearClick}
        /> : <></>}

      {allState.showDeletePopup ?
        <DeletePopUp
          open={allState.showDeletePopup}
          handleOnCloseClick={handleOnCloseClick}
          handleDeleteData={handleDeleteData}
        /> : <></>}

    </>
  )
}