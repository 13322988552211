import React, { useState } from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_T_ProjectDefine_ProjectName, Get_DDL_T_ProjectDefine_ProjectType, Get_DDL_T_WorkDefine_WorkArea, Get_DDL_T_WorkDefine_WorkName, Get_DDL_T_WorkDefine_WorkType } from "../../../APIs/DDLAPI/DDLAPI";
import { useEffect } from "react";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_T_ProjectWorkWiseBudgetCreation_InsertUpdate } from "../CreateProjectAPI/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";

export default function CreateBudgetPopUp(props) {

    const userDetails = useAuthState();
    const { UserID } = userDetails

    const { allstate, open, handleOnCloseClick, IsPost, setIsPost, handleOnClearClick } = props
    const {apiFlag,editData,popUpFlag} = allstate

    const [createBudgetTextField, setcreateBudgetTextField] = useState(
        {
            workAmount: apiFlag === "Update" ? editData.workAmount : "",
        }
    )

    const [projectTypeDDL, setprojectTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_ProjectTypeID : 0,
        Label: apiFlag === "Update" ? editData.projectTypeName : "---Select---",
    })

    const [projectNameDDL, setprojectNameDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_ProjectID : 0,
        Label: apiFlag === "Update" ? editData.projectName : "---Select---",
    })

    const [workTypeDDL, setworkTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_WorkTypeID : 0,
        Label: apiFlag === "Update" ? editData.workTypeName : "---Select---",
    })
    const [workNameDDL, setworkNameDDL] = useState({
        DDL: [],
        ID: apiFlag==="Update" ? editData.m_WorkID: 0,
        Label: apiFlag==="Update" ? editData.workName: "---Select---",
    })

    const [workAreaDDL, setworkAreaDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.workAreaID: 0,
        Label: apiFlag === "Update" ? editData.workArea:"---Select---",
    })

    const projectNameClear = () => {
        setprojectNameDDL({
            ...projectNameDDL,
            ID:  0,
            Label: "---Select---",
        })
    }
    const workNameClear = () => {
        setworkNameDDL({
            ...workNameDDL,
            ID: 0,
            Label: "---Select---",
        })
    }

    const handleInputChange = (e) => {
        setcreateBudgetTextField({ ...createBudgetTextField, [e.target.name]: e.target.value })
    }

    const [formErrors, setformErrors] = useState({
        workAmountError: "",


    })
    const checkcodeValidation = (text) => {

        let regex = new RegExp(/^[0-9]*$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, workAmountError: "" })
        } else {
            setformErrors({ ...formErrors, workAmountError: "Enter valid number" })

        }
    }


    useEffect(() => {
        Get_DDL_T_ProjectDefine_ProjectType({ UserID, projectTypeDDL: projectTypeDDL, setprojectTypeDDL: setprojectTypeDDL })
    }, [])

    useEffect(() => {
        Get_DDL_T_ProjectDefine_ProjectName({ UserID, projectNameDDL: projectNameDDL, setprojectNameDDL: setprojectNameDDL, projectTypeDDL })
    }, [projectTypeDDL.ID])


    useEffect(() => {
        Get_DDL_T_WorkDefine_WorkType({ UserID, setworkTypeDDL, workTypeDDL })
    }, [])

    useEffect(() => {
        Get_DDL_T_WorkDefine_WorkName({ UserID, setworkNameDDL, workNameDDL, workTypeDDL })
    }, [workTypeDDL.ID])

    useEffect(() => {
        Get_DDL_T_WorkDefine_WorkArea({ UserID, setworkAreaDDL, workAreaDDL, workNameDDL })
    }, [workNameDDL.ID])

    const Add = () => {
        Post_T_ProjectWorkWiseBudgetCreation_InsertUpdate({
            ProjectWorkWiseBudgetCreationID: apiFlag === "Insert" ? "0" : editData.t_ProjectWorkWiseBudgetCreationID,
            UserID,
            apiFlag,
            IsPost,
            setIsPost,
            handleOnCloseClick,
            projectNameDDL: projectNameDDL,
            workNameDDL: workNameDDL,
            workAreaDDL:workAreaDDL,
            createBudgetTextField,
            handleOnClearClick
        })
    }

    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Project Work wise Budget Creation</div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="Budgettype">Project Type <Star /> </label>
                                <Select
                                    isClearable
                                    isSearchable
                                    value={{ value: projectTypeDDL.ID, label: projectTypeDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setprojectTypeDDL({ ...projectTypeDDL, ID: e.value, Label: e.label })
                                            :
                                            setprojectTypeDDL({ ...projectTypeDDL, ID: 0, Label: "---Select---" })
                                            projectNameClear()
                                    }}
                                    options={projectTypeDDL.DDL}

                                />
                            </div>

                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="Budgettype">Project Name <Star /> </label>
                                <Select
                                    isClearable
                                    isSearchable
                                    value={{ value: projectNameDDL.ID, label: projectNameDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setprojectNameDDL({ ...projectNameDDL, ID: e.value, Label: e.label })
                                            :
                                            setprojectNameDDL({ ...projectNameDDL, ID: 0, Label: "---Select---" })
                                    }}
                                    options={projectNameDDL.DDL}
                                    maxMenuHeight={140}
                                />
                            </div>

                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="Budgettype">Work Type <Star /> </label>
                                <Select
                                    isClearable
                                    isSearchable
                                    value={{ value: workTypeDDL.ID, label: workTypeDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setworkTypeDDL({ ...workTypeDDL, ID: e.value, Label: e.label })
                                            :
                                            setworkTypeDDL({ ...workTypeDDL, ID: 0, Label: "---Select---" })
                                            workNameClear()
                                    }}
                                    options={workTypeDDL.DDL}


                                />
                            </div>

                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="Budgettype">Work Name <Star /> </label>
                                <Select
                                    isClearable
                                    isSearchable
                                    value={{ value: workNameDDL.ID, label: workNameDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setworkNameDDL({ ...workNameDDL, ID: e.value, Label: e.label })
                                            :
                                            setworkNameDDL({ ...workNameDDL, ID: 0, Label: "---Select---" })
                                    }}
                                    options={workNameDDL.DDL}
                                    maxMenuHeight={140}
                                />
                            </div>

                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="Budgettype">Work Area <Star /></label>
                                <Select
                                    isClearable
                                    isSearchable
                                    value={{ value: workAreaDDL.ID, label: workAreaDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setworkAreaDDL({ ...workAreaDDL, ID: e.value, Label: e.label, area: e.area })
                                            :
                                            setworkAreaDDL({ ...workAreaDDL, ID: 0, Label: "---Select---" })
                                    }}
                                    options={workAreaDDL.DDL}
                                    maxMenuHeight={140}
                                />
                                {/* <input
                                    className="form-control"
                                    id="workAmount"
                                    type="text"
                                    name="workAmount"
                                    value={workNameDDL.area}
                                    disabled
                                /> */}
                            </div>

                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="workAmount">Work Amount(Lacs) <Star /></label>
                                <input
                                    className="form-control"
                                    id="workAmount"
                                    type="text"
                                    name="workAmount"
                                    value={createBudgetTextField.workAmount}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        checkcodeValidation(e.target.value)
                                    }}
                                />
                                <span className="text-danger error-danger">{formErrors.workAmountError}</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}
                                ><X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        projectTypeDDL.ID === 0 ||
                                        projectNameDDL.ID === 0 ||
                                        workTypeDDL.ID === 0 ||
                                        workNameDDL.ID === 0 ||
                                        createBudgetTextField.workAmount === ""
                                    }
                                >{popUpFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popUpFlag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>
    )
}