
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../Helper/Context/context";
import { KeyProjectDDLAPI } from "../../Redux/DDLSlice";
import { useEffect } from "react";



export default function KeyProjectDDL(props) {
    const { keyProjectDDL, setKeyProjectDDL, keyProjectDDLData, FlagName,apiFlag, editData,} = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()

    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = { UserID: UserID, token: token }
        dispatch(KeyProjectDDLAPI({ data }))
    }, [])

    const { KeyProjectData } = useSelector(state => state.KeyProjectDDLData)

    useEffect(() => {
        handleKeyProjectDDL()
    }, [KeyProjectData])

    const handleKeyProjectDDL = () => {
        if (KeyProjectData && KeyProjectData.table && KeyProjectData.table.length > 0) {

            let list = KeyProjectData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.indicatorID,
                label: item.indicatorName,
            }))
            setKeyProjectDDL({
                DDL: list,
                ID: apiFlag === 'Update' ? editData.key_ProjectM_IndicatorID : 0,
                    Label: apiFlag === 'Update' ? editData.key_ProjectIndicatorName : "---Select---",
            })
        }
        else {
            setKeyProjectDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Key Project</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: keyProjectDDL.ID, label: keyProjectDDL.Label }}
                    onChange={(e) => {
                        e ?
                        setKeyProjectDDL({ ...keyProjectDDL, ID: e.value, Label: e.label })
                            :
                            setKeyProjectDDL({ ...keyProjectDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={keyProjectDDL.DDL}

                />
            </div>
        </>
    )
}