import React, { useEffect, useState } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import WorkPopUp from "./WorkPopUp";
import Select from "react-select";
import MobileHeader from "../../../Components/Header/MobileHeader";
import { useAuthState } from "../../../Helper/Context/context";
import { Get_M_Work_Select } from "../MasterApi/GETAPI";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Pegination } from "../../../Components/Pegination/Pegination";
import { Get_DDL_M_WorkType } from "../../../APIs/DDLAPI/DDLAPI";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseUrl";
import Footer from "../../../Components/Footer/Footer";
import { WorkTypeDDLData } from "../../../CommonDDL/WorkTypeDDLData";
import { useDispatch, useSelector } from "react-redux";
import { WorkDDLData } from "../../../CommonDDL/WorkDDLData";
import { SubWorkTypeDeleteAPI, SubWorkTypeTableDataAPI } from "../../../Redux/MasterSlice/SubWorkTypeSlice";

export default function Work() {

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [isopen, setIsOpen] = useState(true);

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })
    const [WorkTypeDDL, setWorkTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "--Select--",
    })

    const [SubWorkTypeDDL, setSubWorkTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "--Select--",
    })

    const handleAddCloseClick = () => {
        setPopUpField({ addPopUp: false })
    }

    const handleDeleteCloseClick = () => {
        setPopUpField({ deletePopUp: false })
    }
    const addButtonClick = () => {
        setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Add", popupBtn: "Clear", apiFlag: 'Insert', rowData: '' })
    }

    const editButtonClick = (item) => {
        setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Update", popupBtn: "Cancel", apiFlag: 'Update', rowData: item })

    }

    const deleteButtonClick = (item) => {
        setPopUpField({ ...PopUpField, addPopUp: false, deletePopUp: true, popupBtn: "", apiFlag: 'Delete', rowData: item })
    }

    const handleDeleteData = () => {
        dispatch(SubWorkTypeDeleteAPI({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }))
    }

    const { isDeleteLoading } = useSelector(state => state.SubWorkTypeDeleteData)

    const handlePost = () => {
        setIsPost(!IsPost)
    }

    const handleOnClearClick = () => {
        setCurrentPage(0)
        setWorkTypeDDL({
            ...WorkTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setSubWorkTypeDDL({
            ...SubWorkTypeDDL,
            ID: 0,
            Label: "---Select---"
        })

        setIsPost(!IsPost)
    }

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)

    }, [WorkTypeDDL.ID, SubWorkTypeDDL.ID])

    useEffect(() => {
        const data = {
            M_WorkID: SubWorkTypeDDL.ID,
            M_WorkTypeID: WorkTypeDDL.ID,
            WorkTypeName: '',
            UserID: UserID,
            token: token,
            From: From,
            To: To,
        }
        dispatch(SubWorkTypeTableDataAPI({ data }))
    }, [From, To, IsPost, IsClear, WorkTypeDDL.ID, SubWorkTypeDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.SubWorkTypeTableData)

    return (
        <>
            {isLoading && <LoaderFile />}
            {isDeleteLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="work" subMenu="subMaster" isopen={isopen} />
                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Sub Work Type</h4>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                title="Add" onClick={() => addButtonClick()}>
                                                <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WorkTypeDDLData
                                                        WorkTypeDDL={WorkTypeDDL}
                                                        setWorkTypeDDL={setWorkTypeDDL}
                                                        Flag='Master'
                                                    // WorkTypeData={WorkTypeData}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WorkDDLData
                                                        SubWorkTypeDDL={SubWorkTypeDDL}
                                                        setSubWorkTypeDDL={setSubWorkTypeDDL}
                                                        WorkTypeDDL={WorkTypeDDL}
                                                        IsPost={IsPost}
                                                        Flag='Master'

                                                    // WorkTypeData={WorkTypeData}
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-2 pl-lg-3">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                    >
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "5em", textAlign: "center" }}>Sr.No.</th>
                                                    <th>Main Work Type</th>
                                                    <th>Sub Work Type Name</th>
                                                    <th style={{ width: "8%",textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                                            <td>{item.workTypeName ? item.workTypeName : "-"}</td>
                                                            <td>{item.workName ? item.workName : "-"}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Edit" onClick={() => editButtonClick(item)}>
                                                                    <Edit size={15} color="#007bff" />
                                                                </span>

                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Delete" onClick={() => deleteButtonClick(item)}>
                                                                    <Trash2 size={15} color="red" />
                                                                </span>

                                                            </td>
                                                        </tr>
                                                    )) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                                                }



                                            </tbody>
                                        </table>
                                    </div>
                                    {tableData && tableData.table && tableData.table.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData.table[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

            {PopUpField.addPopUp ? <WorkPopUp
                open={PopUpField.addPopUp}
                PopUpField={PopUpField}
                handleAddCloseClick={handleAddCloseClick}
                IsPost={IsPost}
                setIsPost={setIsPost}
                handlePost={handlePost}
            /> : <></>}

            {PopUpField.deletePopUp ?
                <DeletePopUp
                    open={PopUpField.deletePopUp}
                    handleDeleteData={handleDeleteData}
                    handleOnCloseClick={handleDeleteCloseClick}
                /> : <></>}


        </>
    )
}