
import { useEffect } from 'react'
import Select from 'react-select'
import { RoleDDLAPI, UserProgressStatusDDLAPI, UserProgressIDAPI } from '../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../Helper/Context/context'
import { Star } from '../Components/CommoComponent/Star'


export const UserProgressStatusDDL = (props) => {
    const { UserProgressID, setUserProgressID, Flag,PopUpField, mendetary } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = { GroupID: '5', token: token }
        dispatch(UserProgressStatusDDLAPI({ data }))
    }, [])

    const { UserStatusData } = useSelector(state => state.UserProgressStatusDDLData)

    useEffect(() => {
        handleSubWorkTypeDDL();
    }, [UserStatusData])

    const handleSubWorkTypeDDL = () => {
        if (UserStatusData && UserStatusData.table && UserStatusData.table.length > 0) {
            let list = UserStatusData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.indicatorID,
                label: item.indicatorName,
            }))

            setUserProgressID({
                DDL: list,
                ID: 0,
                Label: "---Select---",
                // ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.userStatusIndicatorID : 0 : '0',
                // Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.userStatusIndicator : "---Select---" : "---Select---",
            })
        }
        else {
            setUserProgressID({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"> User Status {mendetary==='yes' ? <Star/> : ''} </label>
            <Select
                isClearable
                isSearchable
                maxMenuHeight={150}
                value={{ value: UserProgressID.ID, label: UserProgressID.Label }}
                onChange={(e) => {
                    e ?
                        setUserProgressID({ ...UserProgressID, ID: e.value, Label: e.label })
                        :
                        setUserProgressID({ ...UserProgressID, ID: 0, Label: "---Select---" })

                }}
                options={UserProgressID.DDL}
            // isDisabled
            />
        </div>
    )
}