import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";





export const MuncipleCouncilorTableDataAPI = createAsyncThunk("MuncipleCouncilorTableData", async ({ data }) => {
    const { M_MunicipalCouncilorID,
        M_WardNumberID,
        UserID,
        MunicipalCouncilor,
        From,
        token,
        To,
        M_WardID,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };


    return fetch(`${BaseUrl}/PTMS/Master/M_MunicipalCouncilor_Select?M_MunicipalCouncilorID=${M_MunicipalCouncilorID ? M_MunicipalCouncilorID : '0'}&M_WardNumberID=${M_WardNumberID ? M_WardNumberID : '0'}&MunicipalCouncilor=${MunicipalCouncilor ? MunicipalCouncilor : ''}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}&M_WardID=${M_WardID ? M_WardID : '0'}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const MunicipalCouncilorTableDataSlice = createSlice({
    name: "MunicipalCouncilorTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MuncipleCouncilorTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MuncipleCouncilorTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(MuncipleCouncilorTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const MunicipalCouncilorTableDataReducer = MunicipalCouncilorTableDataSlice.reducer


// InsertUpdate Table Data

export const MunicipalCouncilorPostAPI = createAsyncThunk("MunicipalCouncilorPostData", async ({ data }) => {

    const {
        handleOnCloseClick,
        setIsPost,
        IsPost,
        handleOnClearClick,
        token,
        Flag,
        M_MunicipalCouncilorID,
        M_WardNumberID,
        MunicipalCouncilor,
        UserID,
        M_WardID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_MunicipalCouncilorID", M_MunicipalCouncilorID);
    formdata.append("M_WardNumberID", M_WardNumberID);
    formdata.append("MunicipalCouncilor", MunicipalCouncilor);
    formdata.append("M_UsersID", UserID);
    formdata.append("M_WardID", M_WardID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_MunicipalCouncilor_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
})

const MunicipalCouncilorPostSlice = createSlice({
    name: "MunicipalCouncilorPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MunicipalCouncilorPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MunicipalCouncilorPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(MunicipalCouncilorPostAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const MunicipalCouncilorPostDataReducer = MunicipalCouncilorPostSlice.reducer



// Delete Table Data

export const MunicipalCouncilorDeleteAPI = createAsyncThunk("DepartmentDelete", async ({ data }) => {
    // const { rowData, apiFlag } = PopUpField

    const {
        apiFlag,
        handlePost,
        M_MunicipalCouncilorID,
        token,
        UserID,
        handleOnCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_MunicipalCouncilorID", M_MunicipalCouncilorID);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_MunicipalCouncilor_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleOnCloseClick()
            } else {
                toastErrorr(result.message)
                handleOnCloseClick()
            }
            return result

        })
})

const MunicipalCouncilorDeleteSlice = createSlice({
    name: "MunicipalCouncilorDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MunicipalCouncilorDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(MunicipalCouncilorDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            toastSuccesss(action.payload.message)
        });
        builder.addCase(MunicipalCouncilorDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const MunicipalCouncilorDeleteReducer = MunicipalCouncilorDeleteSlice.reducer