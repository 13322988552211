import React, { useEffect, useState } from "react";
import { Eye, X } from "react-feather";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import PhysicalFinancialWorkProgressPopUp from "./PhysicalFinancialWorkProgressPopUp";
import PhysicalWorkProgressViewPopUp from "./PhysicalWorkProgressViewPopUp";
import Footer from "../../../Components/Footer/Footer";
import WardNameDDL from "../../../CommonDDL/WardNameDDL/WardNameDDL";
import { ConcateWorkAndWorkTypeDDL } from "../../../CommonDDL/ConcateWorkAndWorkTypeDDL";
import { WardNameDDLAPI } from "../../../Redux/DDLSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../Helper/Context/context";
import { PhysicalWorkProgressTableDataAPI } from "../../../Redux/ProjectCreationAllocationSlice/PhysicalWorkProgressSlice";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import ProjectNameMilestoneDDL from "../../../CommonDDL/ProjectNameMilestoneDDL/ProjectNameMilestoneDDL";
import { UserProgressStatusDDL } from "../../../CommonDDL/UserProgressStatusDDL";
import { Pegination } from "../../../Components/Pegination/Pegination";

export default function PhysicalFinancialWorkProgress() {

  const userDetails = useAuthState();
  const { UserID, token } = userDetails
  const dispatch = useDispatch();

  const today = new Date();
  const year = today.getFullYear();

  // ----------pagination-------------
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)
  const [CurrentPage, setCurrentPage] = useState(0)
  const [IsSearch, setIsSearch] = useState(false)
  const [IsClear, setIsClear] = useState(false)
  const [IsPost, setIsPost] = useState(false)
  const [isopen, setIsOpen] = useState(true);

  const YearID = localStorage.getItem("YearID")

  const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "--Select--",
  })

  const [projectNameMilestoneDDL, setProjectNameMilestoneDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
    projectCode: '',
    numberOfMileStone: ''
  })

  const [ProjectDDL, setProjectDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })
  const [UserProgressID, setUserProgressID] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });

  const [wardNameDDL, setWardNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })

  const [PopUpField, setPopUpField] = React.useState({
    addPopUp: false,
    deletePopUp: false,
    showViewPopup: false,
    showPopup: false,
    popupFlag: '',
    popupBtn: "",
    apiFlag: "",
    rowData: ''
  })

  useEffect(() => {
    const data = { UserID, token }
    dispatch(WardNameDDLAPI({ data }))
  }, [IsPost])

  const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

  const handleAddCloseClick = () => {
    setPopUpField({ addPopUp: false, showViewPopup: false, showPopup: false })
  }
  // const handleOnViewClick = () => {
  //   setPopUpField({ showViewPopup: true })
  // }

  const handleDeleteCloseClick = () => {
    setPopUpField({ deletePopUp: false })
  }
  const addButtonClick = () => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Add", popupBtn: "Clear", apiFlag: 'Insert', rowData: '' })
  }

  const editButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Update", popupBtn: "Cancel", apiFlag: 'Update', rowData: item })

  }

  const ViewButtonClick = (data) => {
    setPopUpField({
      ...PopUpField,
      showPopup: true,
      deletePopUp: false,
      popupFlag: "Add",
      popupBtn: "Cancel",
      apiFlag: 'Update',
      rowData: data
    })
  }

  const deleteButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: false, deletePopUp: true, popupBtn: "", apiFlag: 'Delete', rowData: item })
  }

  const handleDeleteData = () => {
    // dispatch(ProjectSanctionDetailsDeleteAPI({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }))
  }

  const { isDeleteLoading } = useSelector(state => state.ProjectSanctionDetailsDeleteData)

  const handlePost = () => {
    setIsPost(!IsPost)
  }
  const handleOnClearClick = () => {
    setCurrentPage(0)
    setWorkAndWorkTypeDDL({
      ...WorkAndWorkTypeDDL,
      ID: 0,
      Label: "---Select---",
    })
    setProjectDDL({
      ...ProjectDDL,
      ID: 0,
      Label: "---Select---"
    })
    setUserProgressID({
      ...UserProgressID,
      ID: 0,
      Label: "---Select---"
    })
    setWardNameDDL({
      ...wardNameDDL,
      ID: 0,
      Label: "---Select---"
    })
    setProjectNameMilestoneDDL({
      ...projectNameMilestoneDDL,
      ID: 0,
      Label: 'Select...'
    })
    setIsPost(!IsPost)
  }

  useEffect(() => {
    const data = {
      M_FinancialYearID: YearID,
      M_MonthID: 0,
      M_WorkTypeID: WorkAndWorkTypeDDL.ID,
      M_ProjectID: projectNameMilestoneDDL.ID,
      M_WardID: wardNameDDL.ID,
      M_WardWiseNumberID: 0,
      M_IndicatorStatusID: UserProgressID.ID,
      Flag: 'CompleteWork',
      M_UsersID: UserID,
      token: token,
      From: From,
      To: To,
    }
    dispatch(PhysicalWorkProgressTableDataAPI({ data }))
  }, [To, IsPost, IsClear, YearID, WorkAndWorkTypeDDL.ID, projectNameMilestoneDDL.ID, wardNameDDL.ID, UserProgressID.ID])

  const { tableData, isLoading } = useSelector(state => state.PhysicalWorkProgressTableData)

  return (
    <>
      {isLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="physicalfinancialworkprogress" subMenu="subProCreation" isopen={isopen} />
          {/* <!-- Main Container --> */}
          <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">Physical Work Progress</h4>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">

                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-12 col-md-6 col-lg-3">
                          <ConcateWorkAndWorkTypeDDL
                            WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                            setWorkAndWorkTypeDDL={setWorkAndWorkTypeDDL}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          {/* <ProjectNameDDL
                            ProjectDDL={ProjectDDL}
                            setProjectDDL={setProjectDDL}
                            ProjectTypeDDL='0'
                            FlagName=''
                          /> */}
                          <ProjectNameMilestoneDDL
                            projectNameMilestoneDDL={projectNameMilestoneDDL}
                            setProjectNameMilestoneDDL={setProjectNameMilestoneDDL}
                            WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                            // PopUpField={PopUpField}
                            projectTypeDDL="0"
                            mendetary='no'
                            FlagName='PhysicalWorkProgress '
                          />
                        </div>

                        {/* <div className="col-12 col-md-6 col-lg-2">
                          <UserStatusDDLData
                            UserStatusDDL={UserStatusDDL}
                            setUserStatusDDL={setUserStatusDDL}
                          />
                        </div> */}

                        <div className="col-12 col-md-6 col-lg-2">
                          <UserProgressStatusDDL
                            UserProgressID={UserProgressID}
                            setUserProgressID={setUserProgressID}
                          />
                        </div>

                        <div className="col-12 col-md-6 col-lg-2">
                          <WardNameDDL
                            wardNameDDL={wardNameDDL}
                            setWardNameDDL={setWardNameDDL}
                            DDLData={WardNameDDLData}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-lg-2 pl-lg-3 ">
                          <button type="button"
                            className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 mx-2 waves-effect waves-light"
                            onClick={handleOnClearClick}>
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th style={{whiteSpace:'nowrap'}}>Work Type</th>
                          <th style={{ whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em' }}>Project Name</th>
                          <th>Work Start Date</th>
                          <th>Work Completion Date</th>
                          <th>Number of Milestone</th>
                          <th>Completed Milestone</th>
                          <th>1st Extension Date</th>
                          <th>2nd Extension Date</th>
                          <th>3rd Extension Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {

                          tableData && tableData.length > 0 ? tableData.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">{item?.rowNum}</td>
                              <td>{item.workTypeName ? item.workTypeName : "-"}</td>
                              <td>{item.projectName ? item.projectName : "-"}</td>
                              <td>{item.work_Start_Date ? item.work_Start_Date : "-"}</td>
                              <td>{item.work_Completion_Date ? item.work_Completion_Date : "-"}</td>
                              <td className='text-right'>{item.numberOfMileStone ? item.numberOfMileStone : "-"}</td>
                              <td align="center"><button
                                href="#details"
                                className="mx-2 cursor-pointer"
                                data-toggle="modal"
                                data-target="#details"
                                data-tooltip="tooltip"
                                title="View Details"
                                onClick={() => ViewButtonClick(item)}><i><Eye size={18} color='#4788f4' /></i></button>
                              </td>
                              <td>{item.extenstionDate1 ? item.extenstionDate1 : "-"}</td>
                              <td>{item.extenstionDate2 ? item.extenstionDate2 : "-"}</td>
                              <td>{item.extenstionDate3 ? item.extenstionDate3 : "-"}</td>
                              <td>{item.statusName ? item.statusName : "-"}</td>
                            </tr>
                          )) : <tr style={{ whiteSpace: 'nowrap' }}>No data</tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>


      {PopUpField.addPopUp ? <PhysicalFinancialWorkProgressPopUp
        open={PopUpField.addPopUp}
        PopUpField={PopUpField}
        handleAddCloseClick={handleAddCloseClick}
        IsPost={IsPost}
        setIsPost={setIsPost}
        handlePost={handlePost}
      /> : <></>}

      {PopUpField.deletePopUp ? <DeletePopUp
        open={PopUpField.deletePopUp}
        handleDeleteData={handleDeleteData}
        // deleteData={deleteData}
        handleOnCloseClick={handleDeleteCloseClick}
      />
        : <></>}
      {PopUpField.showPopup ? <PhysicalWorkProgressViewPopUp
        open={PopUpField.showPopup}
        handleAddCloseClick={handleAddCloseClick}
        dataList={PopUpField.rowData}

      /> : <></>}

    </>
  )
}