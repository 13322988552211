import React, { useState, useEffect } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Select from "react-select";
import { Get_DDL_M_Department, Get_DDL_M_Project } from "../../../APIs/DDLAPI/DDLAPI";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import { useAuthState } from "../../../Helper/Context/context";
import { Pegination } from "../../../Components/Pegination/Pegination";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
// import DepartmentWiseProjectPopUp from "./DepartmentWiseProjectPopUp";
import Footer from "../../../Components/Footer/Footer";
import { Get_T_ProjectDefine_Select } from "../../CreateProject/CreateProjectAPI/GETAPI";
import { Post_T_ProjectDefine_Delete } from "../../CreateProject/CreateProjectAPI/POSTAPI";
import ProjectDetailsPopUp from "./ProjectDetailsPopUp";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import SubDepartmentDDL from "../../../CommonDDL/SubDepartmentDDL/SubDepartmentDDL";
// import ProjectTypeDDL from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import WardNameDDL from "../../../CommonDDL/WardNameDDL/WardNameDDL";
import ProjectPriority from "../../Master/ProjectPriority/ProjectPriority";
import KeyProjectDDL from "../../../CommonDDL/KeyProjectDDL/KeyProjectDDL";
import ProjectPriorityDDL from "../../../CommonDDL/ProjectPriorityDDL/ProjectPriorityDDL";
import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import { useDispatch, useSelector } from "react-redux";
import { AssignJEDDLAPI, DepartmentNameDDLAPI, WardNameDDLAPI } from "../../../Redux/DDLSlice";
import { ProjectDetailsDeleteAPI, ProjectDetailsExportTableDataAPI, ProjectDetailsTableDataAPI } from "../../../Redux/ProjectCreationAllocationSlice/ProjectDetailsSlice";
import ProjectNameDDL from "../../../CommonDDL/ProjectNameDDL/ProjectNameDDL";
import moment from 'moment';
import { ProjectDetailsSidebarExport } from "./ProjectDetailsSidebarExport";
import ProjectDetailProjectNameDDL from "../../../CommonDDL/ProjectDetailsProjectNameDDL/ProjectDetailsProjectNameDDL";

export default function ProjectDetails() {
  const userDetails = useAuthState();
  const { DepartmentID, UserID, token } = userDetails

  const dispatch = useDispatch();

  const [isopen, setIsOpen] = useState(true);
  const [IsPost, setIsPost] = useState(false)
  const [ProjectDefineTable, setProjectDefineTable] = useState([])

  // react pagination states 
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)
  const [CurrentPage, setCurrentPage] = useState(0)
  const [loading, setloading] = useState()
  const [DeleteData, setDeleteData] = useState()
  const YearID = localStorage.getItem("YearID")
  const MonthID = localStorage.getItem("MonthID")

  const [allState, setallState] = useState({
    showCreatePopup: false,
    showDeletePopup: false,
    popUpFlag: "",
    apiFlag: "",
    editData: {},
    deleteData: "",
  })


  const [departmentDDL, setdepartmentDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })

  const [subDepartmentDDL, setSubDepartmentDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })
  const [ProjectTypeDDL, setProjectTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })
  const [workTypeDDL, setworkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })
  const [wardNameDDL, setWardNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })
  const [projectPriorityDDL, setProjectPriorityDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })

  const [keyProjectDDL, setKeyProjectDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })

  const [ProjectDDL, setProjectDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })

  const [ProjectDetailScreenProjectNameDDL, setProjectDetailScreenProjectNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })

  useEffect(() => {
    const data = { UserID, token }
    dispatch(DepartmentNameDDLAPI({ data }))
  }, [IsPost])

  const { DepartmentDDLData } = useSelector(state => state.DepartmentNameData)

  useEffect(() => {
    const data = { UserID, token }
    dispatch(WardNameDDLAPI({ data }))
  }, [IsPost])

  const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)


  const check = DepartmentID === "1" ? "0" : departmentDDL.ID ? departmentDDL.ID : DepartmentID
  // console.log(check);

  const [PopUpField, setPopUpField] = React.useState({
    addPopUp: false,
    deletePopUp: false,
    popupFlag: '',
    popupBtn: "",
    apiFlag: "",
    rowData: ''
  })

  const handleOnCloseClick = () => {
    setallState({ showCreatePopup: false, showDeletePopup: false, popUpFlag: "", apiFlag: "", editData: {}, deleteData: "" })
  }
  const handleAddNewClick = () => {
    setallState({ ...allState, popUpFlag: "Add", apiFlag: "Insert", showCreatePopup: true })
  }
  const handleOnEditClick = (item) => {
    setallState({ ...allState, popUpFlag: "Update", apiFlag: "Update", showCreatePopup: true, editData: item })
  }
  const handleOnDeleteClick = (item) => {
    setallState({ ...allState, popUpFlag: "Update", apiFlag: "Update", showDeletePopup: true, deleteData: item })
  }

  const handleOnSearchClick = () => {
    setIsPost(!IsPost)
    setCurrentPage(0)
  }

  const handleOnClearClick = () => {
    setCurrentPage(0)
    setdepartmentDDL({
      ...departmentDDL,
      ID: 0,
      Label: 'Select...'
    })
    setProjectTypeDDL({
      ...ProjectTypeDDL,
      ID: 0,
      Label: 'Select...'
    })
    setWardNameDDL({
      ...wardNameDDL,
      ID: 0,
      Label: 'Select...'
    })
    setProjectPriorityDDL({
      ...projectPriorityDDL,
      ID: 0,
      Label: 'Select...'
    })
    setKeyProjectDDL({
      ...keyProjectDDL,
      ID: 0,
      Label: 'Select...'
    })
    setSubDepartmentDDL({
      ...subDepartmentDDL,
      ID: 0,
      Label: 'Select...'
    })
    setProjectDetailScreenProjectNameDDL({
      ...ProjectDetailScreenProjectNameDDL,
      ID: 0,
      Label: 'Select...'
    })
  }

  const handlePost = () => {
    setIsPost(!IsPost)
  }

  const handleDeleteData = () => {

    const data = {
      handlePost: handlePost,
      token: token,
      UserID: UserID,
      handleDeleteCloseClick: handleOnCloseClick,
      T_ProjectDetailsID: allState.deleteData
    }
    dispatch(ProjectDetailsDeleteAPI({ data }))
  }

  useEffect(() => {
    setCurrentPage(0)
    setFrom(1)
    setTo(10)
  }, [ProjectTypeDDL.ID, departmentDDL.ID, subDepartmentDDL.ID, wardNameDDL.ID])

  useEffect(() => {
    const data = {
      M_FinancialYearID: YearID ? YearID : 0,
      M_MonthID: MonthID ? MonthID : 0,
      T_ProjectDetailsID: 0,
      M_DepartmentID: departmentDDL.ID,
      M_SubDepartmentID: subDepartmentDDL.ID,
      M_ProjectTypeID: ProjectTypeDDL.ID,
      M_ProjectID: ProjectDetailScreenProjectNameDDL.ID,
      M_WorkTypeID: 0,
      M_ProjectPriorityID: projectPriorityDDL.ID,
      M_WardID: wardNameDDL.ID,
      M_WardWiseNumberID: 0,
      UserID: UserID,
      From: From,
      token: token,
      To: To,
      handleGetExport: handleGetExport
    }
    dispatch(ProjectDetailsTableDataAPI({ data }))

  }, [From, IsPost, YearID, MonthID, departmentDDL.ID, subDepartmentDDL.ID, ProjectTypeDDL.ID, wardNameDDL.ID, projectPriorityDDL.ID, keyProjectDDL.ID, ProjectDetailScreenProjectNameDDL.ID])

  const { tableData, isLoading } = useSelector(state => state.ProjectDetailsTableData)

  const handleGetExport = () => {
    const data = {
      M_FinancialYearID: YearID ? YearID : 0,
      M_MonthID: MonthID ? MonthID : 0,
      T_ProjectDetailsID: 0,
      M_DepartmentID: departmentDDL.ID,
      M_SubDepartmentID: subDepartmentDDL.ID,
      M_ProjectTypeID: ProjectTypeDDL.ID,
      M_ProjectID: ProjectDetailScreenProjectNameDDL.ID,
      M_WorkTypeID: 0,
      M_ProjectPriorityID: projectPriorityDDL.ID,
      M_WardID: wardNameDDL.ID,
      M_WardWiseNumberID: 0,
      UserID: UserID,
      From: From,
      token: token,
      To: '99999',
    }
    dispatch(ProjectDetailsExportTableDataAPI({ data }))
  }
  // , [To, IsPost, IsClear, departmentDDL.ID, subDepartmentDDL.ID, ProjectTypeDDL.ID, workTypeDDL.ID, projectPriorityDDL.ID, keyProjectDDL.ID,])

  const { ExporttableData, isExportLoading } = useSelector(state => state.ProjectDetailsExportTableData)

  return (
    <>
      {isLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="projectDetails" subMenu="subProCreation" isopen={isopen} />
          <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-6 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">Project Details</h4>
                    </div>
                    <div className="col-6 col-lg-3 mb-xl-0 py-2">
                      <h4 className="font-weight-bold float-right px-2 py-1 mr-3 btn-outline-count rounded">
                        Total Count :- <span className='text-danger px-2'>{tableData && tableData[0]?.totalCount ? tableData[0]?.totalCount : 0}</span>
                      </h4>
                    </div>
                    <div className="col-12 col-lg-3 mb-xl-0 align-self-center align-items-end text-right">
                      <button type="button" className="btn btn-primary btn-sm"
                        title="Add" onClick={() => handleAddNewClick()}>
                        <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                        Add
                      </button>
                      {
                        isExportLoading ?
                          <button type="button" className="btn btn-success btn-sm" disabled>
                            <i className="fa fa-refresh fa-spin" style={{ fontSize: "15px", marginRight: "5px" }}></i>
                            Loading..
                          </button>
                          :
                          tableData && tableData.length > 0 &&
                          <ProjectDetailsSidebarExport
                            ExportData={ExporttableData}
                            name='Project Details'
                          // title={title}
                          // projectType={projectType}
                          />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    {/* <div className="card filter mb-4"> */}
                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-12 col-md-4 col-lg-3">
                          <DepartmentDDL
                            departmentDDL={departmentDDL}
                            setdepartmentDDL={setdepartmentDDL}
                            DDLData={DepartmentDDLData}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <SubDepartmentDDL
                            subDepartmentDDL={subDepartmentDDL}
                            setSubDepartmentDDL={setSubDepartmentDDL}
                            departmentDDLID={departmentDDL.ID}
                            FlagName=''
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                          <WardNameDDL
                            wardNameDDL={wardNameDDL}
                            setWardNameDDL={setWardNameDDL}
                            DDLData={WardNameDDLData}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <ProjectTypeDDLData
                            ProjectTypeDDL={ProjectTypeDDL}
                            setProjectTypeDDL={setProjectTypeDDL}
                            PopUpField={PopUpField}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          {/* <ProjectNameDDL
                            ProjectDDL={ProjectDDL}
                            setProjectDDL={setProjectDDL}
                            ProjectTypeDDL={ProjectTypeDDL}
                            IsPost={IsPost}
                            DDLFlag='Master'
                          /> */}
                          <ProjectDetailProjectNameDDL
                            ProjectDetailScreenProjectNameDDL={ProjectDetailScreenProjectNameDDL}
                            setProjectDetailScreenProjectNameDDL={setProjectDetailScreenProjectNameDDL}
                            departmentDDL={departmentDDL}
                            subDepartmentDDL={subDepartmentDDL}
                            wardNameDDL={wardNameDDL}
                            ProjectTypeDDL={ProjectTypeDDL}
                            IsPost={IsPost}
                            DDLFlag='ProjectDetails'
                          />
                        </div>

                        <div className="col-12 col-md-12 col-lg-2 pl-lg-3">
                          <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick}>
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th style={{ whiteSpace: 'nowrap' }}>Project Tracker Code <hr /> LFMS Number</th>
                          <th>Project Type</th>
                          <th style={{ whiteSpace: 'nowrap' }}>Work Type</th>
                          <th style={{ whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em' }}>Project Name</th>
                          <th>Project ERP Code</th>
                          <th>Ward Name</th>
                          <th>Electoral Ward Number</th>
                          <th>Project Priority</th>
                          <th>Key Project</th>
                          <th>Assign JE</th>
                          <th >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tableData && tableData.length > 0 ? tableData.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">{item.rowNum}</td>
                              <td>
                                {item.project_Tracker_Code ? item.project_Tracker_Code : "-"}
                                <hr className="colorBlack" />
                                {item.lfmS_File_No ? item.lfmS_File_No : "-"}
                              </td>
                              <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                              <td>{item.workAndWorkType ? item.workAndWorkType : "-"}</td>
                              <td style={{ width: '40em' }}>{item.projectName ? item.projectName : "-"}</td>
                              <td>{item.project_ERP_Code ? item.project_ERP_Code : "-"}</td>
                              <td>{item.wardName ? item.wardName : "-"}</td>
                              <td className='text-right'>{item.wardNumber ? item.wardNumber : "-"}</td>
                              <td>{item.projectPriorityName ? item.projectPriorityName : "-"}</td>
                              <td>{item.key_ProjectIndicatorName ? item.key_ProjectIndicatorName : "-"}</td>
                              <td>{item.employeeName ? item.employeeName : "-"}</td>
                              <td className="" style={{ textAlign: "center" }}>
                                <span className="m-2 cursor-pointer"
                                  title="Edit" onClick={() => handleOnEditClick(item)}>
                                  <Edit size={15} color="#007bff" />
                                </span>

                                <span className="mt-4 cursor-pointer"
                                  title="Delete" onClick={() => handleOnDeleteClick(item.t_ProjectDetailsID)}>
                                  <Trash2 size={15} color="red" />
                                </span>

                              </td>
                            </tr>
                          )) : <tr>No data</tr>
                        }


                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>


      {allState.showCreatePopup ? <ProjectDetailsPopUp
        open={allState.showCreatePopup}
        handleOnCloseClick={handleOnCloseClick}
        allState={allState}
        PopUpField={PopUpField}
        IsPost={IsPost}
        setIsPost={setIsPost}
        handleOnClearClick={handleOnClearClick}
      /> : <></>}

      {allState.showDeletePopup ? <DeletePopUp
        open={allState.showDeletePopup}
        handleOnCloseClick={handleOnCloseClick}
        handleDeleteData={handleDeleteData}
      /> : <></>}

    </>
  )
}