import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";


export const CommonProjectOverViewTableDataAPI = createAsyncThunk("CommonProjectOverViewTableData", async ({ data }) => {
    const {
        M_UsersID,
        M_FinancialYearID,
        M_DepartmentID,
        M_MonthID,
        M_ProjectTypeID,
        M_WardID,
        M_WardWiseNumberID,
        M_ProjectPriorityID,
        Project_Tracker_Code,
        M_ProjectID,
        Flag,
        token,
        FromTop,
        ToTop,
        setGridData
        // handleExportData
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_Web_DB_T_PhysicalMilestoneWorkProgress_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : 0}&M_WardID=${M_WardID ? M_WardID : 0}&M_WardWiseNumberID=${M_WardWiseNumberID ? M_WardWiseNumberID : 0}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : 0}&M_ProjectID=${M_ProjectID ? M_ProjectID : 0}&M_ProjectPriorityID=${M_ProjectPriorityID ? M_ProjectPriorityID : 0}&Project_Tracker_Code=${Project_Tracker_Code ? Project_Tracker_Code : 0}&Flag=${Flag}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                // handleExportData()
                setGridData && setGridData(result.data.table)
                return result.data.table
            } else {
                return result
            }
        })
})

const CommonProjectOverViewTableDataSlice = createSlice({
    name: "CommonProjectOverViewTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommonProjectOverViewTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommonProjectOverViewTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommonProjectOverViewTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommonProjectOverViewTableDataReducer = CommonProjectOverViewTableDataSlice.reducer

// MileStone Popup Get API

export const CommonProjectOverViewMileStonePopupTableDataAPI = createAsyncThunk("CommonProjectOverViewMileStonePopupTableData", async ({ data }) => {
    const {
        M_UsersID,
        M_FinancialYearID,
        M_MonthID,
        T_PhysicalMilestoneWorkProgressID,
        token,
        FromTop,
        ToTop,
        // handleExportData
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_Web_DB_T_PhysicalMilestoneWorkProgress_Popup_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&T_PhysicalMilestoneWorkProgressID=${T_PhysicalMilestoneWorkProgressID ? T_PhysicalMilestoneWorkProgressID : 0}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                // handleExportData()
                return result.data.table
            } else {
                return result
            }
        })
})

const CommonProjectOverViewMileStonePopupTableDataSlice = createSlice({
    name: "CommonProjectOverViewMileStonePopupTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommonProjectOverViewMileStonePopupTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommonProjectOverViewMileStonePopupTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommonProjectOverViewMileStonePopupTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommonProjectOverViewMileStonePopupTableDataReducer = CommonProjectOverViewMileStonePopupTableDataSlice.reducer


