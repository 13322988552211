
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"



export default function RoleDDL(props) {
    const { roleDDL, setRoleDDL, RoleDDLData , FlagName} = props
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Role</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: roleDDL.ID, label: roleDDL.Label }}
                    onChange={(e) => {
                        e ?
                        setRoleDDL({ ...roleDDL, ID: e.value, Label: e.label })
                            :
                            setRoleDDL({ ...roleDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={roleDDL.DDL}

                />
            </div>
        </>
    )
}