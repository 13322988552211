import React from "react";
import { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Department_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import { useDispatch } from "react-redux";
import { DepartmentPostAPI } from "../../../Redux/MasterSlice/DepartmentSlice";

export default function DepartmentPopUp({ flag, open, handleOnCloseClick, deptOfficeTypeIndicatorID, apiFlag, IsPost, setIsPost, editData, handleOnClearClick }) {
    // console.log(editData)

    const userDetails = useAuthState();
    const { DepartmentID, UserID, token } = userDetails
    const dispatch = useDispatch();

    const [passwordField, setpasswordField] = useState("password")
    const [departmentTextField, setdepartmentTextField] = useState(
        {
            departmentName: apiFlag === "Update" ? editData.departmentName : "",
            departmentCode: apiFlag === "Update" ? editData.dept_Code : "",
            address: apiFlag === "Update" ? editData.departmentAddress : "",
            contactNumber: apiFlag === "Update" ? editData.mobileNo : "",
            emailID: apiFlag === "Update" ? editData.emailId : "",
        }
    )

    const handleInputChange = (e) => {
        setdepartmentTextField({ ...departmentTextField, [e.target.name]: e.target.value })
    }


    const handleClearField = () => {
        setdepartmentTextField({
            departmentName: '',
            departmentCode: '',
            address: '',
            contactNumber: '',
            emailID: '',
        })
        setformErrors({
            empNameError: "",
            emailError: "",
            mobileError: "",    
        })
    }
    const showPassword = () => {
        if (passwordField === "password") {
            setpasswordField("text")
        } else {
            setpasswordField("password")
        }
    }
    const [formErrors, setformErrors] = useState({
        empNameError: "",
        emailError: "",
        mobileError: "",

    })

    const checkEmailvalidation = (text) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, emailError: "" })
        } else {
            setformErrors({ ...formErrors, emailError: "Enter valid email address" })

        }
    }

    const checkMobileValidation = (text) => {
        // let regex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
        let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, mobileError: "" })
        } else {
            setformErrors({ ...formErrors, mobileError: "Enter valid contact number" })

        }
    }

    const { departmentName, contactNumber, emailID, userName, password } = departmentTextField

    const Add = () => {
        const data = {
            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost,
            handleOnClearClick: handleOnClearClick,
            M_DepartmentID: apiFlag === 'Update' ? editData.m_DepartmentID : 0,
            token: token,
            Flag: flag,
            M_OfficeID: 1,
            M_DeptOfficeTypeIndicatorID: 2,
            DepartmentName: departmentTextField.departmentName,
            ContactPerson: '',
            MobileNo: departmentTextField.contactNumber,
            EmailId: departmentTextField.emailID,
            DepartmentAddress: departmentTextField.address,
            UserName: '',
            Password: '',
            // DeptFlag: '',
            DeptCode: departmentTextField.departmentCode,
            UserID: UserID,
        }
        dispatch(DepartmentPostAPI({ data }))
    }


    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>

                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Department </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="departmentName">Name of Department <Star /> </label>
                                <input
                                    className="form-control"
                                    id="NameofDepartment"
                                    type="text"
                                    name="departmentName"
                                    value={departmentTextField.departmentName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="departmentName">Department Code <Star /> </label>
                                <input
                                    className="form-control"
                                    id="DepartmentCode"
                                    type="text"
                                    name="departmentCode"
                                    value={departmentTextField.departmentCode}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="NameofDepartment">Address <Star/></label>
                                
                                <input
                                    className="form-control"
                                    id="NameofDepartment"
                                    type="text"
                                    name="address"
                                    value={departmentTextField.address}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        // checkEmailvalidation(e.target.value)
                                    }}
                                />
                                {/* <span className="text-danger error-danger">{formErrors.emailError}</span> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="contactNumber"> Contact Number <Star/></label>
                                <input
                                    className="form-control"
                                    id="contactNumber"
                                    type="text"
                                    name="contactNumber"
                                    value={departmentTextField.contactNumber}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            handleInputChange(e)
                                            checkMobileValidation(e.target.value)
                                        }
                                    }}

                                />
                                <span className="text-danger error-danger">{formErrors.mobileError}</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="NameofDepartment">Email Id </label>
                                <input
                                    className="form-control"
                                    id="NameofDepartment"
                                    type="email"
                                    name="emailID"
                                    value={departmentTextField.emailID}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        checkEmailvalidation(e.target.value)
                                    }}
                                />
                                <span className="text-danger error-danger">{formErrors.emailError}</span>
                            </div>
                        </div>


                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={flag === 'Update' ? handleOnCloseClick : handleClearField}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />{flag === 'Update' ? 'Cancel' : 'Clear'}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        departmentTextField.departmentName === "" ||
                                        departmentTextField.departmentCode === "" ||
                                        departmentTextField.address === "" ||
                                        departmentTextField.contactNumber === ""


                                    }
                                >{flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag === 'Update' ? 'Update' : 'Add'}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}