import React from "react";
import { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Department_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import Select from "react-select";
import WardNameDDL from "../../../CommonDDL/WardNameDDL/WardNameDDL";
import ElectoralWardNumDDL from "../../../CommonDDL/ElectoralWardNumDDL/ElectoralWardNumDDL";
import { ElectoralWardNumberDDLAPI, WardNameDDLAPI } from "../../../Redux/DDLSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import MuncipleCouncilor from "./MuncipleCouncilor";
import { MunicipalCouncilorPostAPI } from "../../../Redux/MasterSlice/MunicipleCouncilorSlice";

export default function MuncipleCouncilorPopUp({ flag, open, handleOnCloseClick, deptOfficeTypeIndicatorID, apiFlag, IsPost, setIsPost, editData, handleOnClearClick }) {

    const userDetails = useAuthState();
    const { UserID, token } = userDetails;
    const dispatch = useDispatch();

    // console.log(editData)

    const [muncipleCouncilorName, setMuncipleCouncilorName] = useState(apiFlag === 'Update' ? editData.municipalCouncilor : '')

    const [designationDDL, setdesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });
    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });
    const [electoralWardNumDDL, setElectoralWardNumDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.m_WardNumberID : 0,
        Label: apiFlag === 'Update' ? editData.wardNumber : "---Select---",
    });

    useEffect(() => {
        const data = { UserID, token }
        dispatch(WardNameDDLAPI({ data }))
    }, [])

    const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

    useEffect(() => {
        const data = { UserID, token, M_WardID: wardNameDDL.ID }
        dispatch(ElectoralWardNumberDDLAPI({ data }))
    }, [wardNameDDL.ID])

    const { ElectoralWardNumberData } = useSelector(state => state.ElectoralWardNumberDDLData)


    // const showPassword = () => {
    //     if (passwordField === "password") {
    //         setpasswordField("text")
    //     } else {
    //         setpasswordField("password")
    //     }
    // }
    const [formErrors, setformErrors] = useState({
        empNameError: "",
        emailError: "",
        mobileError: "",

    })

    const checkEmailvalidation = (text) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, emailError: "" })
        } else {
            setformErrors({ ...formErrors, emailError: "Enter valid email address" })

        }
    }

    const checkMobileValidation = (text) => {
        // let regex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
        let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, mobileError: "" })
        } else {
            setformErrors({ ...formErrors, mobileError: "Enter valid contact number" })

        }
    }


    // console.log(editData)
    const Add = () => {
        const data = {
            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost,
            handleOnClearClick: handleOnClearClick,
            token: token,
            Flag: apiFlag,
            M_MunicipalCouncilorID: apiFlag === 'Update' ? editData.m_MunicipalCouncilorID : 0,
            M_WardNumberID: wardNameDDL.ID,
            MunicipalCouncilor: muncipleCouncilorName,
            UserID: UserID,
            M_WardID: apiFlag === 'Update' ? editData.m_WardID : wardNameDDL.ID

        }
        dispatch(MunicipalCouncilorPostAPI({ data }))
    }


    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>

                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Municipal Councilor </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <WardNameDDL
                                wardNameDDL={wardNameDDL}
                                setWardNameDDL={setWardNameDDL}
                                DDLData={WardNameDDLData}
                                apiFlag={apiFlag}
                                editData={editData}
                                FlagName='Update'
                                mendetary = 'yes'
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <ElectoralWardNumDDL
                                electoralWardNumDDL={electoralWardNumDDL}
                                setElectoralWardNumDDL={setElectoralWardNumDDL}
                                DDLData={ElectoralWardNumberData}
                                editData={editData}
                                apiFlag={apiFlag}
                                wardNameDDL={wardNameDDL}
                                mendetary='yes'
                                FlagName=''
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="contactNumber"> Municipal Councilor Name <Star/> </label>
                                <input
                                    className="form-control"
                                    id="contactNumber"
                                    type="text"
                                    name="contactNumber"
                                    value={muncipleCouncilorName}
                                    onChange={(e) => { setMuncipleCouncilorName(e.target.value) }}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>



                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        muncipleCouncilorName === '' ||
                                        wardNameDDL.ID === 0 ||
                                        electoralWardNumDDL.ID === 0
                                    }
                                >{flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}