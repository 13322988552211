
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"



export default function ManPowerTypeDDL(props) {
    const { manPowerTypeDDL, setManPowerTypeDDL, DDLData, FlagName,PopUpField } = props

    useEffect(() => {
        handleManPowerTypeDDL()
    }, [DDLData])

    const handleManPowerTypeDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {

            let list = DDLData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.manpowerType,
            }))
            setManPowerTypeDDL({
                DDL: list,
                ID: PopUpField ? PopUpField?.apiFlag === "Insert" ? 0 : PopUpField?.rowData?.m_ManpowerTypeID : 0,
                Label: PopUpField ? PopUpField?.apiFlag === "Insert" ? "Select..." : PopUpField?.rowData?.manpowerType : "Select...",
            })
        }
        else {
            setManPowerTypeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Man Power Type</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: manPowerTypeDDL.ID, label: manPowerTypeDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setManPowerTypeDDL({ ...manPowerTypeDDL, ID: e.value, Label: e.label })
                            :
                            setManPowerTypeDDL({ ...manPowerTypeDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={manPowerTypeDDL.DDL}

                />
            </div>
        </>
    )
}