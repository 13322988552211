

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import MobileHeader from '../../../../Components/Header/MobileHeader';
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import AddPopUpNumberOfMilestone from './AddPopUpNumberOfMilestone';
import DepartmentDDL from '../../../../CommonDDL/DepartmentDDL/DepartmentDDL';
import WardNameDDL from '../../../../CommonDDL/WardNameDDL/WardNameDDL';
import ProjectNameDDL from '../../../../CommonDDL/ProjectNameDDL/ProjectNameDDL';
import ProjectTypeDDLData from '../../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData';
import ProjectTrackerCodeDDL from '../../../../CommonDDL/ProjectTrackerCodeDDL/ProjectTrackerCodeDDL';
import ElectoralWardNumDDL from '../../../../CommonDDL/ElectoralWardNumDDL/ElectoralWardNumDDL';
import { CommonProjectOverViewTableDataAPI } from '../../../../Redux/DashboardSlice/ProjectOverViewSlice/ProjectOverViewSlice';
import LoaderFile from '../../../../Helper/Loader/LoaderFile';
import { useAuthState } from '../../../../Helper/Context/context';
import moment from 'moment';
import { Pegination } from '../../../../Components/Pegination/Pegination';
import { X } from 'react-feather';
import { WardNameDDLAPI } from '../../../../Redux/DDLSlice';

export default function ProjectOverviewAll({ props }) {
    const today = new Date();
    const year = today.getFullYear();


    const [searchParams] = useSearchParams()
    const projectType = searchParams.get('projectType')
    const title = searchParams.get('title')
    const Flag = searchParams.get('Flag')

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [ExcelData, setExcelData] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [isopen, setIsOpen] = useState(true);

    const [GridData, setGridData] = useState([]);

    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });
    const [subDepartmentDDL, setSubDepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [workTypeDDL, setworkTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [projectPriorityDDL, setProjectPriorityDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [keyProjectDDL, setKeyProjectDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [ProjectDDL, setProjectDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [projectTrackerCodeDDL, setProjectTrackerCodeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });
    const [electoralWardNumDDL, setElectoralWardNumDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    });

    const [addPopUp, setaddPopUp] = useState(false)
    const handleaddPopUpMilestone = (data) => {
        setGridData(data)
        setaddPopUp(!addPopUp)
    }

    const handleOnClearClick = () => {
        setCurrentPage(0)
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: 'Select...'
        })
        setProjectTypeDDL({
            ...ProjectTypeDDL,
            ID: 0,
            Label: 'Select...'
        })
        setWardNameDDL({
            ...wardNameDDL,
            ID: 0,
            Label: 'Select...'
        })
        setProjectPriorityDDL({
            ...projectPriorityDDL,
            ID: 0,
            Label: 'Select...'
        })
        setKeyProjectDDL({
            ...keyProjectDDL,
            ID: 0,
            Label: 'Select...'
        })
        setElectoralWardNumDDL({
            ...electoralWardNumDDL,
            ID: 0,
            Label: 'Select...'
        })
        setProjectDDL({
            ...ProjectDDL,
            ID: 0,
            Label: 'Select...'
        })
        setProjectTrackerCodeDDL({
            ...projectTrackerCodeDDL,
            ID: 0,
            Label: 'Select'
        })
    }

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_WardID: wardNameDDL.ID,
            zoneDDL: 0
        }
        dispatch(WardNameDDLAPI({ data }))
    }, [])

    const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            M_DepartmentID: departmentDDL.ID,
            M_WardID: wardNameDDL.ID,
            M_WardWiseNumberID: electoralWardNumDDL.ID,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_ProjectID: ProjectDDL.ID,
            M_ProjectPriorityID: projectPriorityDDL.ID,
            Project_Tracker_Code: projectTrackerCodeDDL.ID,
            Flag: Flag,
            M_UsersID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
            setGridData: setGridData
        }
        dispatch(CommonProjectOverViewTableDataAPI({ data }))
    }, [To, From, IsPost, YearID, MonthID, IsClear, departmentDDL.ID, wardNameDDL.ID, ProjectTypeDDL.ID, ProjectDDL.ID, electoralWardNumDDL.ID, projectPriorityDDL.ID, projectTrackerCodeDDL.ID,])

    const { tableData, isLoading } = useSelector(state => state.CommonProjectOverViewTableData)

    return (
        <div className="wrapper">
            {isLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="dashboard" isopen={isopen} />
                    <div
                        className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            {/* <DashboardHeading /> */}

                            <div className="row page-heading justify-content-between">
                                <div className="col-6 col-lg-6 mb-xl-0 align-self-center align-items-center">
                                    <h4 className="font-weight-bold"> {title}</h4>

                                </div>
                                <div className="col-6 col-lg-6 mb-xl-0 py-2">
                                    <h4 className="font-weight-bold float-right px-2 py-1 mr-3 btn-outline-count rounded">
                                        Total Count :- <span className='text-danger px-2'>{tableData && tableData[0]?.totalCount ? tableData[0]?.totalCount : 0}</span>
                                    </h4>
                                </div>
                            </div>

                            <div className="col-12 shadow table-card">

                                <div className="row p-3">
                                    <div className="col-md-12 grid-margin">
                                        <div className=" filter mb-0">
                                            {/* <div className="card filter mb-4"> */}
                                            <div className="card-body">
                                                <div className="form-row">
                                                    <div className="col-12 col-md-6 col-lg-3">
                                                        <DepartmentDDL
                                                            departmentDDL={departmentDDL}
                                                            setdepartmentDDL={setdepartmentDDL}
                                                            FlagName=''
                                                            IsPost={IsPost}
                                                        />
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-3">
                                                        <WardNameDDL
                                                            wardNameDDL={wardNameDDL}
                                                            setWardNameDDL={setWardNameDDL}
                                                            DDLData={WardNameDDLData}
                                                            FlagName=''
                                                        />
                                                    </div>
                                                    {/* <div className="col-12 col-md-6 col-lg-3">
                                                        <ElectoralWardNumDDL
                                                            electoralWardNumDDL={electoralWardNumDDL}
                                                            setElectoralWardNumDDL={setElectoralWardNumDDL}
                                                            DDLData=''
                                                            FlagName=''
                                                        />
                                                    </div> */}
                                                    <div className="col-12 col-md-6 col-lg-3">
                                                        <ProjectTypeDDLData
                                                            ProjectTypeDDL={ProjectTypeDDL}
                                                            setProjectTypeDDL={setProjectTypeDDL}
                                                            // PopUpField={PopUpField}
                                                            FlagName=''
                                                            mendetary='yes'
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-3">
                                                        <ProjectNameDDL
                                                            ProjectDDL={ProjectDDL}
                                                            setProjectDDL={setProjectDDL}
                                                            ProjectTypeDDL={ProjectTypeDDL}
                                                            PopUpField=''
                                                             mendetary='yes'
                                                             DDLFlag='Master'
                                                        />
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-4">
                                                        <div className="form-group">
                                                            <ProjectTrackerCodeDDL
                                                                projectTrackerCodeDDL={projectTrackerCodeDDL}
                                                                setProjectTrackerCodeDDL={setProjectTrackerCodeDDL}
                                                                ProjectDDL={ProjectDDL}
                                                                // DDLData={WardNameDDLData}
                                                                apiFlag='ProjectTrackerCode'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-2 pl-lg-3">
                                                        <button type="button"
                                                            className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                            onClick={handleOnClearClick}
                                                        >
                                                            {/* <Search/> */}
                                                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 grid-margin">
                                                <div className="table-responsive">
                                                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                        <thead>
                                                            <tr style={{ textAlign: "center" }}>
                                                                <th width="5%">Sr.No.</th>
                                                                <th>Department</th>
                                                                <th>Ward Name</th>
                                                                <th>Electroal Ward Number</th>
                                                                <th style={{ whiteSpace: 'nowrap' }}>Project Tracker Code</th>
                                                                <th>Project Type</th>
                                                                <th>Work Type</th>
                                                                <th style={{ whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em' }}>Project Name</th>
                                                                <th>Budge Head <hr />Budge Code</th>

                                                                {
                                                                    projectType == 'FinancialSpillOver' &&
                                                                    <>
                                                                        <th>Tender Cost (In Lacs)</th>
                                                                        <th>Approval cost (In Lacs)</th>
                                                                        <th>Actual cost (In Lacs)</th>
                                                                        <th>Amount (In Lacs)</th>

                                                                    </>
                                                                }

                                                                <th>Work Start Date</th>
                                                                <th>Work Completion Date</th>

                                                                {
                                                                    projectType == "FinancialWorkProgress" &&
                                                                    <>
                                                                        <th>Tender Cost (In Lacs)</th>
                                                                        <th>Approval cost (In Lacs)</th>
                                                                        <th>Actual cost (In Lacs)</th>
                                                                        {/* <th>Amount (In Lacs)</th> */}
                                                                        <th>Financial Progress in %</th>
                                                                    </>
                                                                }

                                                                {
                                                                    projectType == "PhysicalWorkProgress" &&
                                                                    <>
                                                                        <th style={{ width: '10%' }}>Number of Milestone</th>
                                                                        <th>Completed Milestone</th>
                                                                        <th>Extension Date</th>
                                                                        <th>Physical Progress in %</th>
                                                                    </>
                                                                }
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                tableData && tableData.length > 0 ? tableData.map((item, i) => (
                                                                    <tr>
                                                                        <td className="text_center">{item?.rowNum}</td>
                                                                        <td>{item.departmentName ? item.departmentName : '-'}</td>
                                                                        <td>{item.wardName ? item.wardName : '-'}</td>
                                                                        <td className='text-right'>{item.wardWiseNumber ? item.wardWiseNumber : '-'}</td>
                                                                        <td>{item.project_Tracker_Code ? item.project_Tracker_Code : '-'}</td>
                                                                        <td>{item.projectTypeName ? item.projectTypeName : '-'}</td>
                                                                        <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                                                        <td>{item.projectName ? item.projectName : '-'}</td>
                                                                        <td>
                                                                            {item.budgetName ? item.budgetName : '-'}
                                                                            <hr className="colorBlack" />
                                                                            {item.budgetCode ? item.budgetCode : '-'}
                                                                        </td>
                                                                        {
                                                                            projectType == 'FinancialSpillOver' &&
                                                                            <>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                {/* <td></td> */}
                                                                            </>
                                                                        }
                                                                        <td style={{ whiteSpace: 'nowrap' }}>{item.work_Start_Date ? item.work_Start_Date : '-'}</td>
                                                                        <td style={{ whiteSpace: 'nowrap' }}>{item.work_Completion_Date ? item.work_Completion_Date : '-'}</td>
                                                                        {
                                                                            projectType == "FinancialWorkProgress" &&
                                                                            <>
                                                                                <td className='text-right'>{item.tendarCost ? item.tendarCost.toFixed(2) : '0'}</td>
                                                                                <td className='text-right'>{item.administrativeApproveCost ? item.administrativeApproveCost.toFixed(2) : '0'}</td>
                                                                                <td className='text-right'>{item.expenditureAmount ? item.expenditureAmount.toFixed(2) : 0}</td>
                                                                                <td className='text-right'>{item.financialWorkProgress ? `${item.financialWorkProgress.toFixed(2)} %` : '0 %'}</td>
                                                                                {/* <td></td> */}
                                                                            </>
                                                                        }
                                                                        {
                                                                            projectType == "PhysicalWorkProgress" &&
                                                                            <>
                                                                                <td className='text-center'>
                                                                                    <button className='countView' onClick={() => handleaddPopUpMilestone(item)}>{item.numberOfMileStone ? item.numberOfMileStone : '0'}</button>
                                                                                </td>
                                                                                <td className='text-right'>{item.completedMilestone ? item.completedMilestone : 0}</td>
                                                                                <td>
                                                                                    {item.extenstionDate1 ? moment(item.extenstionDate1).format('DD-MM-YYYY') : '-'}
                                                                                    <hr className="colorBlack" />
                                                                                    {item.extenstionDate2 ? moment(item.extenstionDate2).format('DD-MM-YYYY') : '-'}
                                                                                    <hr className="colorBlack" />
                                                                                    {item.extenstionDate3 ? moment(item.extenstionDate3).format('DD-MM-YYYY') : '-'}
                                                                                </td>
                                                                                <td className='text-right'>{item.physicalWorkProgress ? `${item.physicalWorkProgress.toFixed(2)} %` : '0 %'}</td>
                                                                            </>
                                                                        }
                                                                        <td>{item.workStatus ? item.workStatus : '-'}</td>
                                                                    </tr>
                                                                )) : <tr style={{ textAlign: "center", whiteSpace: 'nowrap' }}>No data found...</tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {tableData && tableData.length > 0 &&
                                                    <Pegination
                                                        PerPageCount={PerPageCount}
                                                        TotalCount={tableData[0].totalCount}
                                                        setFrom={setFrom}
                                                        setTo={setTo}
                                                        setrowNo={setrowNo}
                                                        CurrentPage={CurrentPage}
                                                        setCurrentPage={setCurrentPage}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="copyright">
                                Copyright {year}. all rights are reserved.<b>Version 1.0</b>
                            </div>


                            {addPopUp ?
                                <AddPopUpNumberOfMilestone
                                    handleaddPopUpMilestone={handleaddPopUpMilestone}
                                    GridData={GridData}
                                    cancelbtn={handleaddPopUpMilestone}
                                    heading="Number of Milestone"
                                /> : <></>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
