
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useDispatch, useSelector } from "react-redux";
import { ProjectDDLAPI } from "../../Redux/DDLSlice";
import { useEffect } from "react";
import { useAuthState } from "../../Helper/Context/context";

export default function ProjectNameDDL(props) {
    const { ProjectDDL, setProjectDDL, FlagName, PopUpField, isdisabled, ProjectTypeDDL, apiFlag, editData, IsPost, mendetary, DDLFlag } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            UserID: UserID,
            token: token,
            Flag: DDLFlag
        }
        { ProjectTypeDDL.ID && dispatch(ProjectDDLAPI({ data })) }
    }, [IsPost, ProjectTypeDDL.ID, YearID, MonthID])

    const { ProjectData } = useSelector(state => state.ProjectDDLData)

    useEffect(() => {
        handleProjectDDL();
    }, [ProjectData])

    // console.log(PopUpField)
    const handleProjectDDL = () => {
        if (ProjectData && ProjectData.table && ProjectData.table.length > 0) {
            let list = ProjectData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.projectName,
                numberOfMileStone: item.numberOfMileStone,
                projectCode: item.projectCode
            }))

            PopUpField && PopUpField?.popupFlag === "Update" ?
                setProjectDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_ProjectID : 0 : '0',
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.projectName : "---Select---" : "---Select---",
                    numberOfMileStone: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.numberOfMileStone : 0 : '0',
                }) :
                setProjectDDL({
                    DDL: list,
                    ID: apiFlag === 'Update' ? editData.m_ProjectID : 0,
                    Label: apiFlag === 'Update' ? editData.projectName : "---Select---",
                    numberOfMileStone: apiFlag === 'Update' ? editData.numberOfMileStone : '',
                    projectCode: apiFlag === 'Update' ? editData.projectCode : '',
                })
        }
        else {
            setProjectDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
                projectCode: 0,
                numberOfMileStone: 0,
            })
        }
    }


    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Project Name {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    isDisabled={isdisabled === 'yes' && apiFlag === 'Update'}
                    maxMenuHeight={140}
                    value={{ value: ProjectDDL.ID, label: ProjectDDL.Label, numberOfMileStone: ProjectDDL.numberOfMileStone }}
                    onChange={(e) => {
                        e ?
                            setProjectDDL({ ...ProjectDDL, ID: e.value, Label: e.label, numberOfMileStone: e.numberOfMileStone, projectCode: e.projectCode })
                            :
                            setProjectDDL({ ...ProjectDDL, ID: 0, Label: "---Select---", numberOfMileStone: '', projectCode: '' })
                    }}
                    options={ProjectDDL.DDL}

                />
            </div>
        </>
    )
}