import React, { useState } from 'react'

import { ArrowDown, ArrowUp } from "react-feather";
import DashboardDetailsCard from '../DashboardDetailsCard/DashboardDetailsCard';

const DashboardDetails = (props) => {
    const { Countdata } = props
    const [link, setLink] = useState();

    return (
        <div className="row main-blocks">
            <DashboardDetailsCard
                path="/TotalDepartments"
                iconClass="icon departments"
                title="Total Departments"
                count={Countdata.TotalDepartments ? Countdata.TotalDepartments : '0'}
                icon={<ArrowUp />}
                iconColor="text-success"
                perentage="5.27"
                status="Since Last Month"
                bgColor="green"
                Flag=''
            />


            <DashboardDetailsCard
                path="/TotalProjects"
                iconClass="icon project"
                title="Total Projects"
                count={Countdata.TotalProjects ? Countdata.TotalProjects : '0'}
                icon={<ArrowDown />}
                iconColor="text-danger"
                perentage="10.27"
                status="Since Last Month"
                bgColor="red"
                Flag=''
            />
            <DashboardDetailsCard
                path="/BudgetDetails"
                iconClass="icon budget"
                title="Budget Provision In (cr)"
                count={Countdata.BudgetProvison ? Countdata?.BudgetProvison.toFixed(4) : '0'}
                icon={<ArrowDown />}
                cardName='ApprovedCost'
                iconColor="text-danger"
                perentage="20.27"
                status="Since Last Month"
                bgColor="red"
                Flag='ApprovedCost'
            />
            <DashboardDetailsCard
                path="/BudgetDetails"
                iconClass="icon consumedbudget"
                title="Consumed Amount In (lacs)"
                count={Countdata.ConsumedAmount ? Countdata?.ConsumedAmount.toFixed(2) : '0'}
                // count='0'
                icon={<ArrowDown />}
                cardName='ExpenditureCost'
                iconColor="text-danger"
                perentage="50.27"
                status="Since Last Month"
                bgColor="red"
                Flag='ExpenditureCost'
            />
        </div>
    )
}

export default DashboardDetails
