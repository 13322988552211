
import Select from "react-select"
import { useEffect } from "react"
import { Star } from "../../Components/CommoComponent/Star"
import { DepartmentNameDDLAPI } from "../../Redux/DDLSlice"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../Helper/Context/context"

export default function DepartmentDDL(props) {
    const { departmentDDL, setdepartmentDDL, FlagName, editData, apiFlag, PopUpField, mendetary,RoleDDL,IsPost } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    useEffect(() => {
        const data = { UserID, token }
        dispatch(DepartmentNameDDLAPI({ data }))
    }, [IsPost])

    const { DepartDDLData } = useSelector(state => state.DepartmentNameData)

    useEffect(() => {
        handleDepartmentNameDDL()
    }, [DepartDDLData])

    const handleDepartmentNameDDL = () => {
        if (DepartDDLData && DepartDDLData.table && DepartDDLData.table.length > 0) {

            let list = DepartDDLData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.departmentName,
            }))

            if (FlagName == 'Update') {
                setdepartmentDDL({
                    DDL: list,
                    ID: FlagName === 'Update' ? editData.m_DepartmentID : 0,
                    Label: FlagName === 'Update' ? editData.departmentName : "---Select---",
                })
            } else {
                setdepartmentDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_DepartmentID : 0 : 0,
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.departmentName : "---Select---" : "---Select---",
                })
            }

        }
        else {
            setdepartmentDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Department Name {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    isDisabled={RoleDDL && RoleDDL.ID == 0}
                    maxMenuHeight={140}
                    value={{ value: departmentDDL.ID, label: departmentDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setdepartmentDDL({ ...departmentDDL, ID: e.value, Label: e.label })
                            :
                            setdepartmentDDL({ ...departmentDDL, ID: 0, Label: "Select..." })
                    }}
                    options={departmentDDL.DDL}

                />
            </div>
        </>
    )
}