
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const ProjectOverviewCard = (props) => {
	const { title, icon, count, cardName, path, iconClass, Flag } = props

	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate(`${path}?projectType=${cardName}&title=${title}&Flag=${Flag}`);

	};
	return (
		<div>
			<span onClick={handleOnClick} className="con-wrap">
				{/* <p>{title}</p> */}
				<span>
					<i className={iconClass}>{icon}</i> <br />
					<h2 className='font-weight-bold'>{count}</h2>
					<p className='pro_card'>{title}</p>
					{/* <span className={textClass}>{status} <br />
						<b className='pl-2'>{percentage}</b></span> */}
				</span>
			</span>
		</div>

	)
}

export default ProjectOverviewCard
