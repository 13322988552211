import React from "react";
import { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Department_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { MainWorkTypePostAPI } from "../../../Redux/MasterSlice/MainWorkTypeSlice";

export default function WorkTypePopUp({ open, handleAddCloseClick, PopUpField, handlePost }) {

    const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()

    const [MainWorkTypeTextField, setMainWorkTypeTextField] = useState({

        WorkTypeName: apiFlag === "Insert" ? '' : rowData?.workTypeName,

    })

    const handleInputChange = (e) => {
        setMainWorkTypeTextField({ ...MainWorkTypeTextField, [e.target.name]: e.target.value })
    }

    const handleAddUpdate = () => {
        const addData = {
            M_WorkTypeID:apiFlag === 'Insert' ? '0' : rowData?.m_WorkTypeID,
            WorkTypeName:MainWorkTypeTextField?.WorkTypeName,  
            M_UserID: UserID,
            token: token,
            Flag: apiFlag,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick
        }
        dispatch(MainWorkTypePostAPI({ addData }))
    }

    const handleClear = () => {
        setMainWorkTypeTextField({

            WorkTypeName: ''
        })
    }
    // const {} = useSelector(state=>state.)

    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>

                <span className="close" onClick={handleAddCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Main Work Type </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="Budgettype">
                                    Main Work Type <Star/>
                                </label>
                                <input
                                    className="form-control"
                                    id="WorkTypeName"
                                    type="text"
                                    name="WorkTypeName"
                                    value={MainWorkTypeTextField.WorkTypeName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>




                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={() => { popupBtn === 'Clear' ? handleClear() : handleAddCloseClick() }}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />{popupBtn}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => handleAddUpdate()}
                                    disabled={
                                       
                                        MainWorkTypeTextField.WorkTypeName === "" 
                                      
                                        // formErrors.mobileError === "Enter valid contact number" ||
                                        // formErrors.emailError === "Enter valid email address"
                                    }
                                >{popupFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />} {popupFlag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}