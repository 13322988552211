

import React, { useState } from 'react'
import { TrendingDown, TrendingUp } from 'react-feather'
import ProjectOverviewCard from './Card/ProjectOverviewCard'
import ProjectOverviewPie from './PieChart/ProjectOverviewPie'
import { Pie } from 'react-chartjs-2'


const ProjectOverview = (props) => {
    const { Countdata } = props

    const [activeClass, setActiveClass] = useState("Physical")

    const data = {
        labels: activeClass && activeClass === 'Physical' ?
            ['Physical Work Progress', 'Physical Spill Over']
            :
            ['Financial Work Progress', 'Financial Spill Over'],
        datasets: [
            {
                data: activeClass && activeClass === 'Physical' ?
                    [Countdata.PhysicalWorkProgress ? Countdata.PhysicalWorkProgress : '0',
                    Countdata.FinancialWorkProgress ? Countdata.FinancialWorkProgress : '0',]
                    :
                    [0, 0],
                backgroundColor: [
                    '#17a2b8',
                    '#024976',
                    // '#f79327',
                    // '#ff3547',
                ],
                borderColor: [
                    '#fff',
                    '#fff',
                    // '#fff',
                    // '#fff'
                ],
                borderWidth: 2,
            },
        ],
    };

    return (
        <div className="row mt-4">

            <div className="col-12 col-lg-6  ">
                <div className="card h-100 mb-0">
                    {/* <ProjectLeftCard/> */}

                    <div className="card-header font-weight-bold">
                        Project Overview
                        {/* <ul className="nav nav-tabs">
                            <li onClick={() => setActiveClass("Capital")}>
                                <a data-toggle="tab"
                                    className={activeClass === "Capital" ? "active" : ""}>Capital</a>
                            </li>
                            <li onClick={() => setActiveClass("Revenue")}>
                                <a data-toggle="tab"
                                    className={activeClass === "Revenue" ? "active" : ""}>Revenue ( O & M )</a>
                            </li>
                        </ul> */}
                    </div>

                    <div className="card-body icon-col">
                        <div className="tab-content w-100 gray-bg" role="tab-container" id="myTabContent">

                            <div className="tab-pane fade show active" id="Capital" role="tabpanel" aria-labelledby="Capital-tab">
                                <div className="form-row">

                                    <div className="col-12 col-lg-6 col-md-6">
                                        <ProjectOverviewCard
                                            // path="/totalprojecttable"
                                            title="Physical Work Progress"
                                            icon={<TrendingUp />}
                                            iconClass="text-success"
                                            textClass="situation text-success pl-2"
                                            count={Countdata.PhysicalWorkProgress ? Countdata.PhysicalWorkProgress : '0'}
                                            status="Over Last Month"
                                            percentage="+5.2%"
                                            path='/ProjectOverviewAll'
                                            cardName='PhysicalWorkProgress'
                                            Flag='PhysicalWorkProgress'
                                        />
                                    </div>

                                    <div className="col-12 col-lg-6 col-md-6">
                                        <ProjectOverviewCard

                                            // path="/ongoingtotalproject"
                                            title="Financial Work Progress"
                                            icon={<TrendingDown />}
                                            iconClass="text-danger"
                                            textClass="situation text-danger pl-2"
                                            count={Countdata.FinancialWorkProgress ? Countdata.FinancialWorkProgress : '0'}
                                            status="Over Last Month"
                                            percentage="+5.2%"
                                            path='/ProjectOverviewAll'
                                            cardName='FinancialWorkProgress'
                                            Flag='FinancialWorkProgress'
                                        />
                                    </div>

                                    <div className="col-12 col-lg-6 col-md-6">
                                        <ProjectOverviewCard
                                            // path="/completedtotalprojecttable"
                                            title="Physical Spill Over"
                                            icon={<TrendingUp />}
                                            iconClass="text-success"
                                            textClass="situation text-success pl-2"
                                            count="0"
                                            status="Over Last Month"
                                            percentage="+5.2%"
                                            path='/ProjectOverviewAll'
                                            cardName='PhysicalSpillOver'
                                            Flag=''
                                        />
                                    </div>

                                    <div className="col-12 col-lg-6 col-md-6">
                                        <ProjectOverviewCard
                                            // path="/completedtotalprojecttable"
                                            title="Financial Spill Over"
                                            icon={<TrendingUp />}
                                            iconClass="text-success"
                                            textClass="situation text-success pl-2"
                                            count="0"
                                            status="Over Last Month"
                                            percentage="+5.2%"
                                            path='/ProjectOverviewAll'
                                            cardName='FinancialSpillOver'
                                            Flag=''
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-12 col-lg-6 ">
                <div >
                    <div className="card mb-0">
                        <div className="card-header font-weight-bold">
                            Project Overview
                            <ul className="nav nav-tabs">
                                <li onClick={() => setActiveClass("Physical")}>
                                    <a data-toggle="tab"
                                        className={activeClass === "Physical" ? "active" : ""}>Physical</a>
                                </li>
                                <li onClick={() => setActiveClass("Financial")}>
                                    <a data-toggle="tab"
                                        className={activeClass === "Financial" ? "active" : ""}>Financial</a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content w-100 gray-bg p-5" role="tab-container" id="myTabContent">
                                {/* <Pie style={{maxHeight:"250px"}} data={data} /> */}
                                <Pie data={data} 
                                height= "200px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProjectOverview
