import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const UserWiseWardMappingTableDataAPI = createAsyncThunk("UserWiseWardMappingTableData", async ({ data }) => {
    const {
        LoginM_UsersID,
        token,
        M_UsersID
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_UserWiseWardMapping_Select?LoginM_UsersID=${LoginM_UsersID}&M_UsersID=${M_UsersID ? M_UsersID : '0'}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const UserWiseWardMappingTableDataSlice = createSlice({
    name: "UserWiseWardMappingTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserWiseWardMappingTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserWiseWardMappingTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(UserWiseWardMappingTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const UserWiseWardMappingTableDataReducer = UserWiseWardMappingTableDataSlice.reducer

// InsertUpdate Table Data

export const UserWiseWardMappingPostAPI = createAsyncThunk("UserWiseWardMappingPostData", async ({ data }) => {
    const {
        M_WardID_Data,
        M_UsersID,
        LoginM_UsersID,
        token,
        Flag,
        handlePost,
        handleAddCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WardID_Data", M_WardID_Data);
    formdata.append("M_UsersID", M_UsersID);
    formdata.append("Flag", Flag);
    formdata.append("LoginM_UsersID", LoginM_UsersID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_UserWiseWardMapping_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const UserWiseWardMappingPostSlice = createSlice({
    name: "UserWiseWardMappingPostData",
    initialState: {
        isLoading: false,
        UserWiseWardMappingData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserWiseWardMappingPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserWiseWardMappingPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.UserWiseWardMappingData = action.payload;
        });
        builder.addCase(UserWiseWardMappingPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.UserWiseWardMappingData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const UserWiseWardMappingPostDataReducer = UserWiseWardMappingPostSlice.reducer



// Delete Table Data

export const UserCreationDeleteAPI = createAsyncThunk("UserCreationDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_UsersID", rowData?.id);
    formdata.append("Login_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Users_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const UserCreationDeleteSlice = createSlice({
    name: "UserCreationDelete",
    initialState: {
        isDeleteLoading: false,
        UserCreationableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(UserCreationDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.UserCreationableData = action.payload;

        });
        builder.addCase(UserCreationDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.UserCreationableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const UserCreationDeleteReducer = UserCreationDeleteSlice.reducer