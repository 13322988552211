
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const ProjectDetailsSidebarExport = (props) => {
    const { ExportData, name, title, projectType } = props

    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportUserCreation"
                className="btn btn-success float-end download-table-xls-button float-end btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-ExportUserCreation" style={{ display: 'none', width: '50%' }}>

                <h5><span>&emsp;</span></h5>

                <tr>
                    <th colSpan={10} style={{ textAlign: 'center', fontSize: 16 }}>{name}</th>
                </tr>
                {/* <tr><td></td></tr> */}

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#254462', color: "#fff" }}>
                        <th style={{ width: "5%", borderRight: '1px solid white' }} >Sr.No.</th>
                        <th>
                            <span style={{ borderBottom: '2px solid red' }}>Project Tracker Code</span>
                            <br className="colorBlack" />
                            <span>LFMS Number</span>
                        </th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Work Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project ERP Code</th>
                        <th style={{ borderLeft: "1px solid white" }}>Ward Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Electoral Ward <br />Number</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project <br />Priority</th>
                        <th style={{ borderLeft: "1px solid white" }}>Key Project</th>
                        <th style={{ borderLeft: "1px solid white" }}>Assign JE</th>
                    </tr>
                </thead>
                <tbody>
                    {

                        ExportData && ExportData.table && ExportData.table.length > 0 ? ExportData.table.map((item, i) => (
                            <tr style={{ textAlign: "center", border: '1px solid black' }}>
                                <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                <td>
                                    {item.project_Tracker_Code ? item.project_Tracker_Code : "-"}
                                    <br className="colorBlack" />
                                    {item.lfmS_File_No ? item.lfmS_File_No : "-"}
                                </td>
                                <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                                <td>{item.workAndWorkType ? item.workAndWorkType : "-"}</td>
                                <td>{item.projectName ? item.projectName : "-"}</td>
                                <td>{item.project_ERP_Code ? item.project_ERP_Code : "-"}</td>
                                <td>{item.wardName ? item.wardName : "-"}</td>
                                <td style={{ textAlign:'right' }}>{item.wardNumber ? item.wardNumber : "-"}</td>
                                <td>{item.projectPriorityName ? item.projectPriorityName : "-"}</td>
                                <td>{item.key_ProjectIndicatorName ? item.key_ProjectIndicatorName : "-"}</td>
                                <td>{item.employeeName ? item.employeeName : "-"}</td>
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}