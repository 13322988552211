import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";


export const TotalProjectTableDataAPI = createAsyncThunk("TotalProjectTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_DepartmentID,
        M_SubDepartmentID,
        M_WorkTypeID,
        M_WardID,
        M_ProjectPriorityID,
        Key_ProjectM_IndicatorID,
        M_UsersID,  
        M_ProjectTypeID,
        token,
        FromTop,
        ToTop,
       
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_Web_M_Project_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_DepartmentID=${M_DepartmentID}&M_SubDepartmentID=${M_SubDepartmentID}&M_ProjectTypeID=${M_ProjectTypeID}&M_WorkTypeID=${M_WorkTypeID}&M_WardID=${M_WardID}&M_ProjectPriorityID=${M_ProjectPriorityID}&Key_ProjectM_IndicatorID=${Key_ProjectM_IndicatorID}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                // handleExportData()
                return result.data.table
            } else {
                return result
            }
        })
})

const TotalProjectTableDataSlice = createSlice({
    name: "TotalProjectTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(TotalProjectTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(TotalProjectTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(TotalProjectTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const TotalProjectTableDataReducer = TotalProjectTableDataSlice.reducer


