import React, { useState, useEffect } from "react";
import Select from "react-select";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_M_Department, Get_DDL_M_Project, Get_DDL_M_ProjectPriority, Get_DDL_M_ProjectType } from "../../APIs/DDLAPI/DDLAPI";
import { Post_T_ProjectDefine_InsertUpdate } from "../CreateProject/CreateProjectAPI/POSTAPI";
import { useAuthState } from "../../Helper/Context/context";

export default function RoleWiseFormPopUp(props) {

    const userDetails = useAuthState();
    const { UserID } = userDetails

    const { open, allState, handleOnCloseClick, IsPost, setIsPost, handleOnClearClick } = props
    const { popUpFlag, apiFlag, editData } = allState

    // console.log(popUpFlag, apiFlag);

    const [projectDefineTextField, setprojectDefineTextField] = useState(
        { projectArea: apiFlag === "Update" ? editData.projectArea : "" }
    )

    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_DepartementID : 0,
        Label: apiFlag === "Update" ? editData.departmentName : "---Select---"
    })
    const [projectPriorityDDL, setprojectPriorityDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_ProjectPriorityID : 0,
        Label: apiFlag === "Update" ? editData.projectPriorityName : "---Select---"
    })
    const [projectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_ProjectTypeID : 0,
        Label: apiFlag === "Update" ? editData.projectTypeName : "---Select---"
    })

    const [projectNameDDL, setprojectNameDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_ProjectID : 0,
        Label: apiFlag === "Update" ? editData.projectName : "---Select---"
    })

    const handleClearDDL = () => {
        setprojectNameDDL({
            ...projectNameDDL,
            ID: 0,
            Label: "---Select---"
        })
    }


    const handleInputChange = (e) => {
        setprojectDefineTextField({ ...projectDefineTextField, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        Get_DDL_M_Department({ UserID, departmentDDL: departmentDDL, setdepartmentDDL: setdepartmentDDL })
    }, [])

    useEffect(() => {
        Get_DDL_M_ProjectType({ UserID, ProjectTypeDDL: projectTypeDDL, setProjectTypeDDL: setProjectTypeDDL })
    }, [])

    useEffect(() => {
        Get_DDL_M_Project({ UserID, projectNameDDL: projectNameDDL, setprojectNameDDL: setprojectNameDDL, projectTypeDDL })
    }, [projectTypeDDL.ID])

    useEffect(() => {
        Get_DDL_M_ProjectPriority({ UserID, projectPriorityDDL: projectPriorityDDL, setprojectPriorityDDL: setprojectPriorityDDL })
    }, [])

    const { projectArea } = projectDefineTextField

    const Add = () => {
        Post_T_ProjectDefine_InsertUpdate({
            ProjectDefineID: apiFlag === "Insert" ? "0" : editData.t_ProjectDefineID,
            departmentDDL: departmentDDL,
            ProjectTypeDDL: projectTypeDDL,
            projectNameDDL: projectNameDDL,
            projectPriorityDDL: projectPriorityDDL,
            projectDefineTextField: projectDefineTextField,
            UserID,
            apiFlag,
            handleOnCloseClick,
            IsPost,
            setIsPost,
            handleOnClearClick: handleOnClearClick
        })
    }

    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Role Wise Form</div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="role">Role Name </label>
                                <input
                                    className="form-control"
                                    id="role"
                                    type="text"
                                    name="role"
                                // value={empolyeeTextField.joiningDate}
                                // max={empolyeeTextField.joiningDate}
                                // onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="role">Form Name </label>
                                <input
                                    className="form-control"
                                    id="role"
                                    type="text"
                                    name="role"
                                // value={empolyeeTextField.joiningDate}
                                // max={empolyeeTextField.joiningDate}
                                // onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        departmentDDL.ID === 0 ||
                                        projectTypeDDL.ID === 0 ||
                                        projectNameDDL.ID === 0 ||
                                        projectPriorityDDL.ID === 0 ||
                                        projectDefineTextField.projectArea === ""
                                    }
                                >{popUpFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popUpFlag}</button>
                            </div>
                        </div>

                    </div>
                </div>


            </Popup>
        </div>
    )
}