
import React, { useEffect, useState } from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_T_WorkDefine_WorkArea, Get_DDL_T_WorkDefine_WorkName, Get_DDL_T_WorkDefine_WorkType } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_T_ProjectWorkWiseMilestone_InsertUpdate } from "../CreateProjectAPI/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import ProjectTypeDDL from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import ProjectNameDDL from "../../../CommonDDL/ProjectNameDDL/ProjectNameDDL";
import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import { WorkTypeDDLData } from "../../../CommonDDL/WorkTypeDDLData";
import { useDispatch } from "react-redux";
import { toBeEnabled } from "@testing-library/jest-dom/matchers";
import { ProjectWiseMilestonePostAPI } from "../../../Redux/ProjectCreationAllocationSlice/ProjectWiseMilestoneSlice";
import { ConcateWorkAndWorkTypeDDL } from "../../../CommonDDL/ConcateWorkAndWorkTypeDDL";
import ProjectNameMilestoneDDL from "../../../CommonDDL/ProjectNameMilestoneDDL/ProjectNameMilestoneDDL";
import moment from 'moment';

export default function WorkWiseMiliStonePopUp(props) {

  const userDetails = useAuthState();
  const { UserID, token } = userDetails

  const dispatch = useDispatch()

  const { open, allState, handleOnCloseClick, IsPost, setIsPost, handleOnClearClick } = props
  const { apiFlag, editData, popUpFlag } = allState

  const [projectTypeDDL, setProjectTypeDDL] = useState({
    DDL: [],
    ID: apiFlag === 'Update' ? editData.m_ProjectTypeID : 0,
    Label: apiFlag === 'Update' ? editData.projectTypeName : "---Select---",

  })
  const [WorkTypeDDL, setWorkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "--Select--",
  })

  // const [ProjectDDL, setProjectDDL] = useState({
  //   DDL: [],
  //   ID: apiFlag === 'Update' ? editData.m_ProjectID : 0,
  //   Label: apiFlag === 'Update' ? editData.projectName : "---Select---",
  // })

  const [projectNameMilestoneDDL, setProjectNameMilestoneDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
    projectCode: '',
    numberOfMileStone: ''
  })


  const [workMilestoneTextField, setworkMilestoneTextField] = useState(
    {
      NoOfMilestone: apiFlag === "Update" ? editData.numberOfMileStone : "",
      MilestoneName: apiFlag === "Update" ? editData.milestone : "",
    }
  )

  const [workNameDDL, setworkNameDDL] = useState({
    DDL: [],
    ID: apiFlag === "Update" ? editData.m_WorkID : "0",
    Label: apiFlag === "Update" ? editData.workName : "---Select---",
  })

  const [workAreaDDL, setworkAreaDDL] = useState({
    DDL: [],
    ID: apiFlag === "Update" ? editData.workAreaID : "0",
    Label: apiFlag === "Update" ? editData.workArea : "---Select---",
  })

  const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
    DDL: [],
    ID: apiFlag === 'Update' ? editData.workTypeNameID : 0,
    Label: apiFlag === 'Update' ? editData.workTypeName : "---Select---",
  })

  // console.log(editData)

  let [MilestoneNames, setMilestoneNames] = useState(apiFlag === 'Update' ? editData.dataList : [])
  const [rawData, setRawData] = useState([])

  const workNameClear = () => {
    setworkNameDDL({
      ...workNameDDL,
      ID: "0",
      Label: "---Select---",
    })
  }

  const handleInputChange = (e) => {
    setworkMilestoneTextField({ ...workMilestoneTextField, [e.target.name]: e.target.value })
  }


  const handlePost = () => {
    setIsPost(!IsPost)
  }

  const handleMilestoneName = () => {
    setMilestoneNames([
      ...MilestoneNames,
      {
        milestone_Name: workMilestoneTextField.MilestoneName,
      }
    ])
    workMilestoneTextField.MilestoneName = ''
    setRawData([])
    // console.log(MilestoneNames)
  }

  const handleMilestoneNameDelete = (item) => {
    MilestoneNames = MilestoneNames.filter((MilestoneName) => {
      // console.log(MilestoneName, item)
      return MilestoneName != item
    })
    setMilestoneNames(MilestoneNames)
    setRawData([])
    // console.log(MilestoneNames)
  }

  const handleRawData = () => {
    MilestoneNames.map((item) => {
      rawData.push(item.milestone_Name)
    })
  }

  const handleclearDDL = () => {
    setProjectTypeDDL({
      ...ProjectTypeDDL,
      ID: 0,
      Label: 'Select...'
    })
    setProjectNameMilestoneDDL({
      ...projectNameMilestoneDDL,
      ID: 0,
      Label: 'Select...'
    })
    setWorkAndWorkTypeDDL({
      ...WorkAndWorkTypeDDL,
      ID: 0,
      Label: 'Select...'
    })
    workMilestoneTextField.NoOfMilestone = ''
    setMilestoneNames([])
  }

  const Add = () => {
    handleRawData();
    const data = {
      M_FinancialYearID: moment().format('YYYY'),
      M_MonthID: moment().format('MM'),
      T_ProjectWorkWiseMilestoneID: apiFlag === 'Update' ? editData.t_ProjectWorkWiseMilestoneID : 0,
      RawData: rawData,
      NumberOfMileStone: workMilestoneTextField.NoOfMilestone,
      M_ProjectID: projectNameMilestoneDDL.ID,
      T_ProjectDetailsID: '0',
      M_WorkID: WorkAndWorkTypeDDL.ID ? WorkAndWorkTypeDDL.ID : 0,
      UserID: UserID,
      token: token,
      Flag: apiFlag,
      handlePost: handlePost,
      handleAddCloseClick: handleOnCloseClick
    }
    dispatch(ProjectWiseMilestonePostAPI({ data }))
  }
  // console.log(WorkAndWorkTypeDDL.ID)
  return (

    <div>
      <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
        <span className="close" onClick={handleOnCloseClick}>
          &times;
        </span>
        <div className="call"> Add Project Wise Milestones</div>
        <div className="modal-body">
          <div className="row details-row">
            <div className="col-12 col-lg-6">
              <ProjectTypeDDLData
                ProjectTypeDDL={projectTypeDDL}
                setProjectTypeDDL={setProjectTypeDDL}
                // PopUpField={PopUpField}
                editData={editData}
                apiFlag={apiFlag}
                mendetary='yes'
                isdisabled='yes'
              />
            </div>

            <div className="col-12 col-lg-6">
              <ConcateWorkAndWorkTypeDDL
                WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                setWorkAndWorkTypeDDL={setWorkAndWorkTypeDDL}
                editData={editData}
                apiFlag={apiFlag}
                mendetary='yes'
                isdisabled='yes'
                // PopUpField={PopUpField}
                FlagName='ProjectMilestones'
              />
            </div>
            <div className="col-12 col-lg-6">
              <ProjectNameMilestoneDDL
                projectNameMilestoneDDL={projectNameMilestoneDDL}
                setProjectNameMilestoneDDL={setProjectNameMilestoneDDL}
                projectTypeDDL={projectTypeDDL}
                WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                editData={editData}
                apiFlag={apiFlag}
                mendetary='yes'
                FlagName='Master'
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="NoOfMilestone">No of Milestone  <Star /></label>
                <input
                  className="form-control"
                  id="NoOfMilestone"
                  type="text"
                  name="NoOfMilestone"
                  disabled={
                    MilestoneNames.length >= workMilestoneTextField.NoOfMilestone && MilestoneNames.length !== 0 && workMilestoneTextField.NoOfMilestone
                  }
                  value={workMilestoneTextField.NoOfMilestone}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>

            <div className="col-12 col-lg-7">
              <div className="form-group">
                <label className="d-block" htmlFor="MilestoneName"> Milestone Name  <Star /></label>
                <div className="d-flex">
                  <div>
                    <input
                      className="form-control"
                      id="MilestoneName"
                      type="text"
                      name="MilestoneName"
                      disabled={
                        MilestoneNames.length >= workMilestoneTextField.NoOfMilestone
                      }
                      value={workMilestoneTextField.MilestoneName}
                      onChange={(e) => handleInputChange(e)}
                    />

                  </div>
                  <div>
                    <button className=" milestoneNameAddButton btn-primary p-2"
                      disabled={
                        MilestoneNames.length >= workMilestoneTextField.NoOfMilestone ||
                        workMilestoneTextField.MilestoneName === ''
                      }
                      onClick={handleMilestoneName}
                    ><Plus size={18} /></button>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column table-responsive">
                <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Milestone Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      MilestoneNames.map((item, index) => (
                        <tr>
                          <td>{index + 1 + ". "} </td>
                          <td>{item.milestone_Name ? item.milestone_Name : item}</td>
                          <td>
                            <i style={{ color: 'red', cursor: 'pointer' }} className="fa-solid fa-trash"
                              onClick={() => handleMilestoneNameDelete(item)}
                            ></i>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>

              </div>
            </div>

            <div className="col-12 col-lg-12">
              <div className="btn-action">
                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                  onClick={apiFlag === 'Update' ? handleOnCloseClick : handleclearDDL}
                >
                  <X size={18} style={{ marginRight: "3px" }} />{apiFlag === 'Update' ? 'Cancel' : 'Clear'}</button>
                <button
                  type="button"
                  className="btn btn-md btn-primary mx-2 float-right"
                  onClick={() => Add()}
                  disabled={
                    projectNameMilestoneDDL.ID === 0 ||
                    ProjectTypeDDL.ID === 0 ||
                    WorkAndWorkTypeDDL.ID === 0 ||
                    workMilestoneTextField.NoOfMilestone === '' ||
                    MilestoneNames.length === 0
                  }
                >{popUpFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popUpFlag}</button>
              </div>
            </div>
          </div>
        </div>


      </Popup>
    </div>
  )
}