import React from "react";
import { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Department_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import Select from "react-select";
import ZoneDDL from "../../../CommonDDL/ZoneDDL/ZoneDDL";
import { ZoneMasterPostAPI } from "../../../Redux/MasterSlice/ZoneMasterSlice";
import { useDispatch } from "react-redux";

export default function ZoneMasterPop({ flag, open, handleOnCloseClick, deptOfficeTypeIndicatorID, apiFlag, IsPost, setIsPost, editData, handleOnClearClick }) {
    console.log(editData)

    const [zoneName, setZoneName] = useState(apiFlag === 'Update' ? editData.m_ZoneName :'')

    const [designationDDL, setdesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });


    const userDetails = useAuthState();
    const { DepartmentID, UserID, token } = userDetails

    const dispatch = useDispatch();

    const Add = () => {
        const data = {
            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost,
            handleOnClearClick: handleOnClearClick,
            token: token,
            Flag: apiFlag,
            UserID: UserID,
            M_ZoneID: apiFlag === 'Update' ? editData.m_ZoneID : 0,
            M_ZoneName: zoneName,
            DisplayOrder: '',

        }
        dispatch(ZoneMasterPostAPI({ data }))
    }


    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>

                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Zone </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="Budgettype">
                                    Zone <Star/>
                                </label>
                                <input
                                    className="form-control"
                                    id="zoneName"
                                    type="text"
                                    name="Zone"
                                    value={zoneName}
                                    onChange={(e) => { setZoneName(e.target.value) }}

                                />
                            </div>
                        </div>




                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        zoneName === ''
                                    }
                                >{flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}