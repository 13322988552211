import { useEffect, useState } from "react"

export const GroupBTN = (props) => {
    const { activeClass, setActiveClass } = props
    return (
        <>
            <ul className="nav nav-tabs">
                {/* <li onClick={() => setActiveClass("All")}><a data-toggle="tab"
                    className={activeClass === "All" ? "active" : ""}>All </a></li> */}

                <li onClick={() => setActiveClass("Proposed")}><a data-toggle="tab"
                    className={activeClass === "Proposed" ? "active" : ""}>Proposed </a></li>

                <li onClick={() => setActiveClass("OnGoing")}><a data-toggle="tab"
                    className={activeClass === "OnGoing" ? "active" : ""}>On Going </a></li>

                <li onClick={() => setActiveClass("Completed")}><a data-toggle="tab"
                    className={activeClass === "Completed" ? "active" : ""}>Completed  </a></li>
            </ul>
        </>
    )
}