import React from 'react'

const NotFound = () => {
  return (
      <div className='d-flex justify-content-center align-items-center'>
            <img src="../assets/img/something-lost.png" alt="" />
      </div>
  )
}

export default NotFound
