import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const ProjectSanctionDetailsTableDataAPI = createAsyncThunk("ProjectSanctionDetailsTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_ProjectTypeID,
        T_ProjectSanctionDetailsID,
        M_ProjectID,
        M_WorkID,
        M_WorkTypeID,
        M_ContractorID,
        UserID,
        From,
        token,
        To,
        handleProjectSanctionExport
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_T_ProjectSanctionDetails_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : 0}&T_ProjectSanctionDetailsID=${T_ProjectSanctionDetailsID}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_WorkID=${M_WorkID}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                handleProjectSanctionExport && handleProjectSanctionExport()
                return result.data.table
            } else {
                return result
            }
        })
})

const ProjectSanctionDetailsDataSlice = createSlice({
    name: "ProjectSanctionDetailsTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectSanctionDetailsTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectSanctionDetailsTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectSanctionDetailsTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectSanctionDetailsTableDataReducer = ProjectSanctionDetailsDataSlice.reducer

// Export Api

export const ProjectSanctionDetailsExportTableDataAPI = createAsyncThunk("ProjectSanctionDetailsExportTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_ProjectTypeID,
        T_ProjectSanctionDetailsID,
        M_ProjectID,
        M_WorkID,
        M_WorkTypeID,
        M_ContractorID,
        UserID,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_T_ProjectSanctionDetails_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : 0}&T_ProjectSanctionDetailsID=${T_ProjectSanctionDetailsID}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_WorkID=${M_WorkID}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                return result.data.table
            } else {
                return result
            }
        })
})

const ProjectSanctionDetailsExportTableDataSlice = createSlice({
    name: "ProjectSanctionDetailsExportTableData",
    initialState: {
        isExportLoading: false,
        ExporttableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectSanctionDetailsExportTableDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(ProjectSanctionDetailsExportTableDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExporttableData = action.payload;
        });
        builder.addCase(ProjectSanctionDetailsExportTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExporttableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectSanctionDetailsExportTableDataReducer = ProjectSanctionDetailsExportTableDataSlice.reducer


// InsertUpdate Table Data

export const ProjectSanctionDetailsPostAPI = createAsyncThunk("ProjectSanctionDetailsPostData", async ({ addData }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        T_ProjectSanctionDetailsID,
        T_AssignWorkToContractorID,
        M_ProjectID,
        M_WorkID,
        M_ContractorID,
        Budget_Provision,
        Administration_Approval_Cost,
        Administrative_Approval_Date,
        M_Tender_StagesID,
        DateOfTenderFloated,
        M_Tender_Amount_TypeID,
        Tender_Amount_InLakhs,
        Work_Start_Date,
        Work_Completion_Date,
        M_UserID,
        token,
        Flag,
        handlePost,
        handleAddCloseClick,
        M_BudgetID,
        M_ManpowerTypeID,
        NoOfManpowerDeploy,
        Period_OM_Month,
        ApprovalDeputyCollectorDate,
        ResolutionNo,
        M_MunicipalCouncilorID,
        T_ProjectWorkWiseMilestoneID,
        T_ProjectDetailsID,
        M_ProjectRemarkID,
    } = addData

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_FinancialYearID", M_FinancialYearID);
    formdata.append("M_MonthID", M_MonthID);
    formdata.append('M_ProjectRemarkID', M_ProjectRemarkID)
    formdata.append("T_ProjectSanctionDetailsID", T_ProjectSanctionDetailsID);
    formdata.append("T_AssignWorkToContractorID", T_AssignWorkToContractorID);
    formdata.append("M_ProjectID", M_ProjectID);
    formdata.append("T_ProjectDetailsID", T_ProjectDetailsID);
    formdata.append("T_ProjectWorkWiseMilestoneID", T_ProjectWorkWiseMilestoneID);
    formdata.append("M_WorkID", M_WorkID);
    formdata.append("M_ContractorID", M_ContractorID);
    formdata.append("Budget_Provision", Budget_Provision);
    formdata.append("Administration_Approval_Cost", Administration_Approval_Cost);
    formdata.append("Administrative_Approval_Date", Administrative_Approval_Date);
    formdata.append("M_Tender_StagesID", M_Tender_StagesID);
    formdata.append("DateOfTenderFloated", DateOfTenderFloated);
    formdata.append("M_Tender_Amount_TypeID", M_Tender_Amount_TypeID);
    formdata.append("Tender_Amount_InLakhs", Tender_Amount_InLakhs);
    formdata.append("Work_Start_Date", Work_Start_Date);
    formdata.append("Work_Completion_Date", Work_Completion_Date);
    formdata.append("M_BudgetID", M_BudgetID);
    formdata.append("M_ManpowerTypeID", M_ManpowerTypeID);
    formdata.append("NoOfManpowerDeploy", NoOfManpowerDeploy);
    formdata.append("Period_OM_Month", Period_OM_Month);
    formdata.append("ApprovalDeputyCollectorDate", ApprovalDeputyCollectorDate);
    formdata.append("ResolutionNo", ResolutionNo);
    formdata.append("M_MunicipalCouncilorID", M_MunicipalCouncilorID);
    formdata.append("M_UsersID", M_UserID);
    formdata.append("Flag", Flag);


    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectSanctionDetails_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const ProjectSanctionDetailsNameSlice = createSlice({
    name: "ProjectSanctionDetailsPostData",
    initialState: {
        isLoading: false,
        ProjectSanctionData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectSanctionDetailsPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectSanctionDetailsPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectSanctionData = action.payload;
        });
        builder.addCase(ProjectSanctionDetailsPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectSanctionData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectSanctionDetailsPostDataReducer = ProjectSanctionDetailsNameSlice.reducer



// Delete Table Data

export const ProjectSanctionDetailsDeleteAPI = createAsyncThunk("ProjectSanctionDetailsDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_ProjectSanctionDetailsID", rowData?.t_ProjectSanctionDetailsID);
    formdata.append("T_AssignWorkToContractorID", rowData?.t_AssignWorkToContractorID);
    formdata.append("M_UsersID", UserID);


    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectSanctionDetails_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const ProjectSanctionDetailsDeleteSlice = createSlice({
    name: "ProjectSanctionDetailsDelete",
    initialState: {
        isDeleteLoading: false,
        ProjectSanctionDetailstableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectSanctionDetailsDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectSanctionDetailsDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.ProjectSanctionDetailstableData = action.payload;

        });
        builder.addCase(ProjectSanctionDetailsDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.ProjectSanctionDetailstableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectSanctionDetailsDeleteReducer = ProjectSanctionDetailsDeleteSlice.reducer