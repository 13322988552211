
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"



export default function BudgetTypeDDL(props) {
    const { budgetTypeDDL, setBudgetTypeDDL, DDLData, FlagName, editData, apiFlag, mendetary } = props

    useEffect(() => {
        handleBudgetTypeDDL()
    }, [DDLData])

    const handleBudgetTypeDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {

            let list = DDLData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.budgetTypeName,
            }))
            setBudgetTypeDDL({
                DDL: list,
                ID: apiFlag === "Update" ? editData.m_BudgetTypeID : 0,
                Label: apiFlag === "Update" ? editData.budgetTypeName : "Select...",
                // ID: PopUpField ? PopUpField?.apiFlag === "Insert" ? 0 : PopUpField?.rowData?.m_Product_CategoryID : "0",
                // Label: PopUpField ? PopUpField?.apiFlag === "Insert" ? "Select..." : PopUpField?.rowData?.categoryName : "Select...",
            })
        }
        else {
            setBudgetTypeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Budget Type {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: budgetTypeDDL.ID, label: budgetTypeDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setBudgetTypeDDL({ ...budgetTypeDDL, ID: e.value, Label: e.label })
                            :
                            setBudgetTypeDDL({ ...budgetTypeDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={budgetTypeDDL.DDL}

                />
            </div>
        </>
    )
}