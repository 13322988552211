

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import MobileHeader from '../../../../../Components/Header/MobileHeader';
import Header from '../../../../../Components/Header/Header';
import Sidebar from '../../../../../Components/Sidebar/Sidebar';
import { useAuthState } from '../../../../../Helper/Context/context';
import { Pegination } from '../../../../../Components/Pegination/Pegination';
import { CommonBudgetDetailsTableDataAPI, CommonBudgetDetailsTableExportDataAPI } from '../../../../../Redux/DashboardSlice/BudgetDetailsSlice/BudgetDetailsSlice';
import moment from 'moment'
import DepartmentDDL from '../../../../../CommonDDL/DepartmentDDL/DepartmentDDL';
import WardNameDDL from '../../../../../CommonDDL/WardNameDDL/WardNameDDL';
import ProjectTypeDDLData from '../../../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData';
import { Get_DDL_M_Project } from '../../../../../APIs/DDLAPI/DDLAPI';
import Select from "react-select";
import ProjectNameDDL from '../../../../../CommonDDL/ProjectNameDDL/ProjectNameDDL';
import LoaderFile from '../../../../../Helper/Loader/LoaderFile';
import SubDepartmentDDL from '../../../../../CommonDDL/SubDepartmentDDL/SubDepartmentDDL';
import { X } from 'react-feather';
import { WardNameDDLAPI } from '../../../../../Redux/DDLSlice';
import { BudgetDetailsExport } from './Export/BudgetDetailsExportFile';

export default function BudgetDetails({ props }) {
    const today = new Date();
    const year = today.getFullYear();

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    const projectType = searchParams.get('projectType')
    const title = searchParams.get('title')
    const Flag = searchParams.get('Flag')

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [ExcelData, setExcelData] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [isopen, setIsOpen] = useState(true);


    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [projectPriorityDDL, setProjectPriorityDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [ProjectDDL, setProjectDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })
    const [subDepartmentDDL, setSubDepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const { DepartmentDDLData } = useSelector(state => state.DepartmentNameData)

    // const [ProjectTrackerCode, setProjectTrackerCode] = useState('')

    const handleOnClearClick = () => {
        setCurrentPage(0)
        setIsPost(!IsPost)
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        setWardNameDDL({
            ...wardNameDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectTypeDDL({
            ...ProjectTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectDDL({
            ...ProjectDDL,
            ID: 0,
            Label: "---Select---"
        })
        setSubDepartmentDDL({
            ...subDepartmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        // setProjectTrackerCode('')
    }

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_WardID: wardNameDDL.ID,
            zoneDDL: 0
        }
        dispatch(WardNameDDLAPI({ data }))
    }, [])

    const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            M_DepartmentID: departmentDDL.ID,
            M_SubDepartmentID: subDepartmentDDL.ID,
            M_WardID: wardNameDDL.ID,
            Project_Tracker_Code: '',
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_ProjectID: ProjectDDL.ID,
            M_WorkTypeID: 0,
            Flag: Flag,
            M_UsersID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
            handleBudgetDetailsExport: handleBudgetDetailsExport
        }
        dispatch(CommonBudgetDetailsTableDataAPI({ data }))
    }, [To, IsPost, YearID, MonthID, IsClear, departmentDDL.ID, ProjectTypeDDL.ID, ProjectDDL.ID, subDepartmentDDL.ID, wardNameDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.CommonBudgetDetailsTableData)

    const handleBudgetDetailsExport = () => {
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            M_DepartmentID: departmentDDL.ID,
            M_SubDepartmentID: subDepartmentDDL.ID,
            M_WardID: wardNameDDL.ID,
            Project_Tracker_Code: '',
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_ProjectID: ProjectDDL.ID,
            M_WorkTypeID: 0,
            Flag: Flag,
            M_UsersID: UserID,
            FromTop: From,
            ToTop: '99999',
            token: token,
        }
        dispatch(CommonBudgetDetailsTableExportDataAPI({ data }))
    }
    // , [To, IsPost, IsClear, departmentDDL.ID, subDepartmentDDL.ID, ProjectTypeDDL.ID, workTypeDDL.ID, projectPriorityDDL.ID, keyProjectDDL.ID,])

    const { ExporttableData, isExportLoading } = useSelector(state => state.CommonBudgetDetailsTableExportData)

    console.log('ExporttableData', ExporttableData)
    return (
        <>
            {isLoading && <LoaderFile />}

            <div className="wrapper">
                <div className="container-scroller">
                    <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                    <Header setIsOpen={setIsOpen} isopen={isopen} />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar active="dashboard" isopen={isopen} />
                        <div
                            className="main-panel"
                            style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                        >
                            <div className="content-wrapper">
                                {/* <DashboardHeading /> */}

                                <div className="row page-heading justify-content-between">
                                    <div className="col-6 col-lg-7 mb-xl-0 align-self-center align-items-center">
                                        <h4 className="font-weight-bold"> {title}</h4>

                                    </div>
                                    <div className="col-6 col-lg-3 mb-xl-0 py-2">
                                        <h4 className="font-weight-bold float-right px-2 py-1 mr-3 btn-outline-count rounded">
                                            Total Count :- <span className='text-danger px-2'>{tableData && tableData[0]?.totalCount ? tableData[0]?.totalCount : 0}</span>
                                        </h4>
                                    </div>
                                    <div className='col-lg-2'>
                                        {
                                            isExportLoading ?
                                                <button type="button" className="btn btn-success btn-sm float-right" disabled>
                                                    <i className="fa fa-refresh fa-spin" style={{ fontSize: "15px", marginRight: "5px" }}></i>
                                                    Loading..
                                                </button>
                                                :
                                                tableData && tableData.length > 0 &&
                                                <BudgetDetailsExport
                                                    ExportData={ExporttableData}
                                                    name={title}
                                                    projectType={projectType}
                                                />
                                        }
                                    </div>
                                </div>

                                <div className="col-12 shadow table-card">
                                    <div className=" filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <DepartmentDDL
                                                        departmentDDL={departmentDDL}
                                                        setdepartmentDDL={setdepartmentDDL}
                                                        DDLData={DepartmentDDLData}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <SubDepartmentDDL
                                                        subDepartmentDDL={subDepartmentDDL}
                                                        setSubDepartmentDDL={setSubDepartmentDDL}
                                                        departmentDDLID={departmentDDL.ID}
                                                        FlagName=''
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WardNameDDL
                                                        wardNameDDL={wardNameDDL}
                                                        setWardNameDDL={setWardNameDDL}
                                                        DDLData={WardNameDDLData}
                                                        FlagName=''
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-md-6 col-lg-3">
                                                    <label for="" className="d-block"><b>Project Tracker Code</b></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={ProjectTrackerCode}
                                                        onChange={(e) => setProjectTrackerCode(e.target.value)}
                                                    />

                                                </div> */}
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ProjectTypeDDLData
                                                        ProjectTypeDDL={ProjectTypeDDL}
                                                        setProjectTypeDDL={setProjectTypeDDL}
                                                        // PopUpField={PopUpField}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ProjectNameDDL
                                                        ProjectDDL={ProjectDDL}
                                                        setProjectDDL={setProjectDDL}
                                                        ProjectTypeDDL={ProjectTypeDDL}
                                                        IsPost={IsPost}
                                                        // PopUpField={PopUpField}
                                                        DDLFlag='Master'
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-2 pl-lg-3">
                                                    <button type="button"
                                                        className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                    >
                                                        <X size={18} style={{ marginRight: "3px" }} />  Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr style={{ textAlign: "center" }}>
                                                    <th width="5%">Sr.No.</th>

                                                    <th>Department</th>
                                                    <th>Ward Name</th>
                                                    <th>Electroal Ward Number</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Project Tracker Code</th>
                                                    <th>Project Type</th>
                                                    <th>Work Type</th>
                                                    <th style={{ whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em' }}>Project Name</th>
                                                    <th>Budge Head <hr />Budge Code</th>
                                                    <th>Budget Provision (In Lacs)</th>
                                                    <th>Administrative Approval Cost (In Lacs) </th>
                                                    <th>Administrative Approval Date</th>
                                                    {
                                                        projectType != 'ApprovedCost' &&
                                                        <>
                                                            <th>Tender Stages</th>
                                                            <th>Date of Tender Floated</th>
                                                            <th>Tender Amount Type</th>
                                                            <th>Tender Amount (In Lacs)</th>
                                                            {
                                                                projectType == 'ExpenditureCost' &&
                                                                <>
                                                                    <th>Actual Amount (In Lacs)</th>
                                                                </>
                                                            }
                                                            <th>Contractor Name</th>

                                                        </>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableData && tableData.length > 0 ? tableData.map((item, i) => (
                                                        <tr>
                                                            <td className="text_center">{item?.rowNum}</td>
                                                            <td>{item.departmentName ? item.departmentName : '-'}</td>
                                                            <td>{item.wardName ? item.wardName : '-'}</td>
                                                            <td className='text-right'>{item.m_WardWiseNumber ? item.m_WardWiseNumber : '-'}</td>
                                                            <td>{item.project_Tracker_Code ? item.project_Tracker_Code : '-'}</td>
                                                            <td>{item.projectTypeName ? item.projectTypeName : '-'}</td>
                                                            <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                                            <td style={{ width: '30em' }}>{item.projectName ? item.projectName : '-'}</td>
                                                            <td>
                                                                <span>{item.budgetName ? item.budgetName : '-'}</span>
                                                                <hr className="colorBlack" />
                                                                <span>{item.budgetCode ? item.budgetCode : '-'}</span>
                                                            </td>
                                                            <td className='text-right'>{item.budget_Provision ? (item.budget_Provision).toFixed(2) : '-'}</td>
                                                            <td className='text-right'>{item.administrativeApproveCost ? (item.administrativeApproveCost).toFixed(2) : '-'}</td>
                                                            <td>{item.administrativeApproveDate ? moment(item.administrativeApproveDate).format('DD-MM-YYYY') : '-'}</td>
                                                            {
                                                                projectType != 'ApprovedCost' &&
                                                                <>
                                                                    <td>{item.tender_Stages ? item.tender_Stages : '-'}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{item.dateOfTenderFloated ? moment(item.dateOfTenderFloated).format('DD-MM-YYYY') : '-'}</td>
                                                                    <td>{item.tender_Amount_Type ? item.tender_Amount_Type : '-'}</td>
                                                                    <td className='text-right'>{item.tendarCost ? item.tendarCost.toFixed(2) : '-'}</td>
                                                                    {
                                                                        projectType == 'ExpenditureCost' &&
                                                                        <>
                                                                            <td className='text-right'>{item.actualCost ? item.actualCost.toFixed(2) : '-'}</td>
                                                                        </>
                                                                    }
                                                                    <td>{item.contractor_Name ? item.contractor_Name : '-'}</td>
                                                                </>
                                                            }
                                                        </tr>
                                                    )) : <tr style={{ textAlign: "center", whiteSpace: 'nowrap' }}>No data found...</tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {tableData && tableData.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    }
                                </div>


                                <div className="copyright">
                                    Copyright {year}. all rights are reserved.<b>Version 1.0</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
