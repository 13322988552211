import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";





export const ProjectRemarkTableDataAPI = createAsyncThunk("ProjectRemarkTableData", async ({ data }) => {
    const { M_ProjectRemarkID,
        ProjectRemark,
        M_UsersID,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_ProjectRemark_Select?M_ProjectRemarkID=${M_ProjectRemarkID ? M_ProjectRemarkID : '0'}&ProjectRemark=${ProjectRemark ? ProjectRemark : ''}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ProjectRemarkTableDataSlice = createSlice({
    name: "ProjectRemarkTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectRemarkTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectRemarkTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectRemarkTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectRemarkTableDataReducer = ProjectRemarkTableDataSlice.reducer


// InsertUpdate Table Data

export const ProjectRemarkPostAPI = createAsyncThunk("ProjectRemarkPostData", async ({ data }) => {
    const {
        handleOnCloseClick,
        setIsPost,
        IsPost,
        handleOnClearClick,
        M_ProjectRemarkID,
        ProjectRemark,
        M_UsersID,
        token,
        Flag, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectRemarkID", M_ProjectRemarkID);
    formdata.append("ProjectRemark", ProjectRemark);
    formdata.append("M_UsersID", M_UsersID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_ProjectRemark_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            }
            else {
                toastErrorr(result.message)
            }
            setIsPost(!IsPost)
            handleOnCloseClick()
            handleOnClearClick()
        })
        .catch(err => {
            console.log(err)
        })
})

const ProjectRemarkPostSlice = createSlice({
    name: "ManPowerTypePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectRemarkPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectRemarkPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(ProjectRemarkPostAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectRemarkPostDataReducer = ProjectRemarkPostSlice.reducer



// Delete Table Data

export const ProjectRemarkDeleteAPI = createAsyncThunk("DepartmentDelete", async ({ data }) => {
    // const { rowData, apiFlag } = PopUpField
    const {
        M_ProjectRemarkID, 
        handlePost, 
        token, 
        UserID, 
        handleDeleteCloseClick
    } = data
 
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectRemarkID", M_ProjectRemarkID);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_ProjectRemark_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const ProjectRemarkDeleteSlice = createSlice({
    name: "ProjectRemarkDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectRemarkDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectRemarkDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            toastSuccesss(action.payload.message)
        });
        builder.addCase(ProjectRemarkDeleteAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectRemarkDeleteReducer = ProjectRemarkDeleteSlice.reducer