import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Select from 'react-select'
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_M_Department, Get_DDL_M_Designation, Get_DDL_M_Employee, Get_DDL_M_EmployeeDDL, Get_DDL_M_Indicator, Get_DDL_M_Role } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Users_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import RoleDDL from "../../../CommonDDL/RoleDDL/RoleDDL";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import DesignationNameDDL from "../../../CommonDDL/DesignationDDL/DesignationNameDDL";
import EmployeeNameDDL from "../../../CommonDDL/EmployeeNameDDL/EmployeeNameDDL";


export default function UserPopUp({ flag, open, handleOnCloseClick, PopUpFlag, editInfo, setIsPost, IsPost, handleOnClearClick }) {

    const userDetails = useAuthState();
    const { UserID, RoleID, EmployeeID } = userDetails
    const [passwordField, setpasswordField] = useState("password")

    const [userTextField, setuserTextField] = useState(
        {
            tableName: flag === "Update" ? editInfo.tableName : "",
            userName: flag === "Update" ? editInfo.userName : "",
            password: flag === "Update" ? editInfo.password : "",
        }
    )


    const [roleDDL, setRoleDDL] = useState({
        DDL: [],
        ID: flag === "Update" ? editInfo.m_RoleID : 0,
        Label: flag === "Update" ? editInfo.roleName : "---Select---",
    })
    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: flag === "Update" ? editInfo.m_DepartmentID : 0,
        Label: flag === "Update" ? editInfo.departmentName : "---Select---",
    })

    const [employeeNameDDL, setEmployeeNameDDL] = useState({
        DDL: [],
        ID: flag === "Update" ? editInfo.m_EmployeeID : 0,
        Label: flag === "Update" ? editInfo.employeeName : "---Select---"
    })
    const [designationNameDDL, setDesignationNameDDL] = useState({
        DDL: [],
        ID: flag === "Update" ? editInfo.m_DesignationID : 0,
        Label: flag === "Update" ? editInfo.designationName : "---Select---"
    })
    const [userStatusDDL, setuserStatusDDL] = useState({
        DDL: [],
        ID: flag === "Update" ? editInfo.userStatusIndicatorID : 0,
        Label: flag === "Update" ? editInfo.userStatusIndicator : "---Select---"
    })

    const handleInputChange = (e) => {
        setuserTextField({ ...userTextField, [e.target.name]: e.target.value })
    }

    const handleClearField = () => {
        setuserTextField(
            {
                departmentName: "",
                tableName: "",
                userName: "",
                password: "",
            }
        )
        setRoleDDL({
            DDL: [],
            ID: 0,
            Label: ""
        })
        departmentDDL({
            DDL: [],
            ID: 0,
            Label: ""
        })
        employeeNameDDL({
            DDL: [],
            ID: 0,
            Label: ""
        })
        designationNameDDL({
            DDL: [],
            ID: 0,
            Label: ""
        })
        userStatusDDL({
            DDL: [],
            ID: 0,
            Label: ""
        })

    }

    const handleClose = () => {
        if (flag === "Update") {
            handleOnCloseClick(false)
        }
        handleOnCloseClick(false)
        handleClearField()
    }

    const showPassword = () => {
        if (passwordField === "password") {
            setpasswordField("text")
        } else {
            setpasswordField("password")
        }
    }



    const handleSubmitPost = () => {
        Post_M_Users_InsertUpdate(
            {
                UserID: UserID,
                RoleID,
                UsersID: flag === "Insert" ? "0" : editInfo.id,
                // RoleID:flag === "Insert" ? "0" : editInfo.m_RoleID,
                userTextField: userTextField,
                roleDDL: roleDDL,
                // employeeDDL: employeeDDL,
                departmentDDL: departmentDDL,
                designationNameDDL: designationNameDDL,
                userStatusDDL: userStatusDDL,
                Flag: flag,
                setIsPost: setIsPost,
                IsPost: IsPost,
                handelPopUpClose: handleClose,
                handleOnClearClick: handleOnClearClick
            }
        )
    }


    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add User Creation</div>
                <div className="modal-body react_popup">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <RoleDDL
                                roleDDL={roleDDL}
                                setRoleDDL={setRoleDDL}
                                editData={editInfo}
                                FlagName='Update'
                               
                            />

                        </div>

                        <div className="col-12 col-lg-6">
                            <DepartmentDDL
                                departmentDDL={departmentDDL}
                                setdepartmentDDL={setdepartmentDDL}
                                editData={editInfo}
                                FlagName='Update'
                            />
                        </div>

                        <div className="col-12 col-lg-6">
                            <DesignationNameDDL
                                designationNameDDL={designationNameDDL}
                                setDesignationNameDDL={setDesignationNameDDL}
                                editData={editInfo}
                                FlagName='Update'
                                IsPost=''
                                departmentDDL={departmentDDL}
                            />

                        </div>

                        <div className="col-12 col-lg-6">
                            <EmployeeNameDDL
                                designationNameDDL={designationNameDDL}
                                employeeNameDDL={employeeNameDDL}
                                setEmployeeNameDDL={setEmployeeNameDDL}
                                editData={editInfo}
                                FlagName='Update'
                            />

                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="tableName">Table Name</label>
                                <input
                                    className="form-control"
                                    id="tableName"
                                    type="text"
                                    name="tableName"
                                    value={userTextField.tableName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="userName">Username <Star /></label>
                                <input
                                    className="form-control"
                                    id="userName"
                                    type="text"
                                    name="userName"
                                    value={userTextField.userName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="password">Password <Star /></label>
                                <span className="btn-show">
                                    <i onClick={showPassword} className={passwordField === "text" ? "fa fa-eye cursor-pointer" : "fas fa-eye-slash cursor-pointer"}></i>
                                </span>
                                <input
                                    type={passwordField}
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={userTextField.password}
                                    // onChange={(e) => setpassword(e.target.value)}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="Budgettype">User Status <Star /></label>
                                <Select
                                    isClearable
                                    // isRtl={isRtl}
                                    isSearchable
                                    value={{ value: userStatusDDL.ID, label: userStatusDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setuserStatusDDL({ ...userStatusDDL, ID: e.value, Label: e.label })
                                            :
                                            setuserStatusDDL({ ...userStatusDDL, ID: 0, Label: "" })
                                    }}
                                    options={userStatusDDL.DDL}

                                />
                            </div>

                        </div>

                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}>
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    // onClick={() => console.log(userTextField)}
                                    onClick={handleSubmitPost}
                                    disabled={
                                        roleDDL.ID === 0 ||
                                        departmentDDL.ID === 0 ||
                                        designationNameDDL.ID === 0 ||
                                        // employeeDDL.ID === 0 ||
                                        userTextField.userName === "" ||
                                        userTextField.password === "" ||
                                        userStatusDDL.ID === 0

                                    }
                                >
                                    {/* {flag === "Update" ?
                                        <Edit2 size={18}
                                            style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{PopUpFlag}</button> */}
                                    {flag === "Update" ?
                                        <Edit size={18}
                                            style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{PopUpFlag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}