
import Select from "react-select"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { EmployeeWardMappingDDLAPI } from "../Redux/DDLSlice"
import { Star } from "react-feather"
import { useAuthState } from "../Helper/Context/context"



export default function EmployeeWardMappingDDL(props) {
    const { employeeWardMappingDDL, setEmployeeWardMappingDDL, FlagName, PopUpField, mendetary,RoleDDL,departmentDDL} = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        const data = { UserID, token,RoleDDL,departmentDDL }
        dispatch(EmployeeWardMappingDDLAPI({ data }))
      }, [RoleDDL.ID,departmentDDL.ID])
    
      const { EmployeeWardMappingData } = useSelector(state => state.EmployeeWardMappingData)

    useEffect(() => {
        handleEmployeeNameDDL()
    }, [EmployeeWardMappingData])

    const handleEmployeeNameDDL = () => {
        if (EmployeeWardMappingData && EmployeeWardMappingData.table && EmployeeWardMappingData.table.length > 0) {
            
            let list = EmployeeWardMappingData.table.map((item, index) => ({               
                // value: item.departmentCode,
                m_UsersID:item.m_UsersID,
                value: item.m_EmployeeID,
                label: item.employeeName,
            }))

            if(FlagName == ''){
                setEmployeeWardMappingDDL({
                    DDL: list,
                    m_UsersID:0,
                    ID:0,
                    Label:'---Select---'
                   
                })
            }else{
                setEmployeeWardMappingDDL({
                    DDL: list,
                    m_UsersID:PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_UsersID : 0 : 0,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_EmployeeID : 0 : 0,
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.employeeName : "---Select---" : "---Select---",
                   
                })
            }
           
        }
        else {
            setEmployeeWardMappingDDL({
                DDL: [],
                m_UsersID:0,
                ID: 0,
                Label: "---Select---",
            })
        }
    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Employee / User Name {mendetary==='yes' ? <Star/> : ''} </label>
                <Select
                    isClearable
                    isSearchable
                    isDisabled={departmentDDL.ID == 0}
                    maxMenuHeight={140}
                    value={{ value: employeeWardMappingDDL.ID, label: employeeWardMappingDDL.Label, m_UsersID: employeeWardMappingDDL.m_UsersID }}
                    onChange={(e) => {
                        e ?
                        setEmployeeWardMappingDDL({ ...employeeWardMappingDDL, ID: e.value, Label: e.label, m_UsersID: e.m_UsersID })
                            :
                            setEmployeeWardMappingDDL({ ...employeeWardMappingDDL, ID: 0, Label: "---Select---" , m_UsersID:''})
                    }}
                    options={employeeWardMappingDDL.DDL}

                />
            </div>
        </>
    )
}