
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect, useState } from "react"



export default function ElectoralWardNumDDL(props) {
    const { electoralWardNumDDL, setElectoralWardNumDDL, wardNameDDL, DDLData, editData, FlagName, apiFlag, mendetary } = props

    useEffect(() => {
        handleElectoralWardNumDDL()
    }, [DDLData])

    const handleElectoralWardNumDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {

            let list = DDLData.table.map((item, index) => ({

                // value: item.departmentCode,
                value: item.id,
                label: item.wardNumber,
            }))
            if (wardNameDDL && editData && (wardNameDDL.ID != editData.m_WardID)) {
                setElectoralWardNumDDL({
                    DDL: list,
                    ID: 0,
                    Label: "---Select---"
                })
            } else {
                setElectoralWardNumDDL({
                    DDL: list,
                    ID: apiFlag === 'Update' ? editData.m_WardWiseNumberID : 0,
                    Label: apiFlag === 'Update' ? editData.wardNumber : "---Select---"
                })
            }
        }
        else {
            setElectoralWardNumDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Electoral Ward Number {mendetary === 'yes' ? <Star /> : ''} </label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: electoralWardNumDDL.ID, label: electoralWardNumDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setElectoralWardNumDDL({ ...electoralWardNumDDL, ID: e.value, Label: e.label })
                            :
                            setElectoralWardNumDDL({ ...electoralWardNumDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={electoralWardNumDDL.DDL}

                />
            </div>
        </>
    )
}