import React, { useState } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../Helper/Context/context";
import { DepartmentTableDataAPI } from "../../Redux/MasterSlice/DepartmentSlice";
import LoaderFile from "../../Helper/Loader/LoaderFile";
import MobileHeader from "../../Components/Header/MobileHeader";
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Pegination } from "../../Components/Pegination/Pegination";
import Footer from "../../Components/Footer/Footer";
import TrackProjectPopUp from "./TrackProjectPopUp";



export default function TrackProject() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    const [showCreatePopup, setshowCreatePopup] = useState(true)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);
    const [deptOfficeTypeIndicatorID, setdeptOfficeTypeIndicatorID] = useState(1)

    // react pagination states 
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)

    // department Api states 
    const [departmentTable, setdepartmentTable] = useState([])
    const [apiFlag, setapiFlag] = useState()
    const [IsPost, setIsPost] = useState(false)
    const [editData, seteditData] = useState()
    const [deleteData, setDeleteData] = useState()
    const [loading, setloading] = useState()
    const [CurrentPage, setCurrentPage] = useState(0)

    const [departmentTextField, setdepartmentTextField] = useState(
        {
            departmentName: "",
        }
    )

    const [designationDDL, setdesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });
    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [designationTable, setdesignationTable] = useState([]);

    const { departmentName } = departmentTextField

    const handleInputChange = (e) => {
        setdepartmentTextField({ ...departmentTextField, [e.target.name]: e.target.value })
    }


    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
    }
    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        setflag("Insert")
        setapiFlag("Insert")
    }
    const handleOnEditClick = (item) => {
        setshowCreatePopup(true)
        setflag("Update")
        setapiFlag("Update")
        seteditData(item)
    }
    const handleOnDeleteClick = (item) => {
        setshowDeletePopup(true)
        setflag("Delete")
        setDeleteData(item)
    }



    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [departmentDDL.ID])

    useEffect(() => {
        const data = {
            M_DepartmentID: departmentDDL.ID,
            DepartmentName: '',
            M_UsersID: UserID,
            M_DeptOfficeTypeIndicatorID: 0,
            Dept_Code: '',
            From: From,
            token: token,
            To: To,
        }
        dispatch(DepartmentTableDataAPI({ data }))

    }, [From, IsPost, departmentDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.DepartmentTableData)

    const handleOnSearchClick = () => {
        setIsPost(!IsPost)
        setCurrentPage(0)
    }

    const handleOnClearClick = () => {
        setCurrentPage(0)
        setdepartmentTextField(
            {
                departmentName: "",
            }
        )
        setIsPost(!IsPost)
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: 'Select...'
        })
    }

    return (
        <>
            {isLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="trackProject"  isopen={isopen} />
                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Track Project</h4>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className=" filter mb-0">
                                        {/* <div className="card filter mb-4"> */}
                                        <div className="card-body">
                                            <div className="form-row">

                                                <div className="col-12 col-md-6 col-lg-3">
                                                </div>

                                                <div className="col-12 col-lg-2 text-center">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">

                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr style={{ textAlign: "center" }}>
                                                    <th width="5%">Sr.No.</th>
                                                    <th>Work Type</th>
                                                    <th>Project ERP Code</th>
                                                    <th>Project Name</th>
                                                    <th>Ward Name</th>
                                                    <th>Electoral Ward Name</th>
                                                    <th>No of Milestone</th>
                                                    <th>Completed Milestone</th>
                                                    <th>Physical Progress (%)</th>
                                                    <th>Financial Progress (%)</th>
                                                    <th>Department Remark</th>
                                                    <th>Project Remark</th>
                                                    <th style={{ width: "100px" }}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    // tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                                                        <tr >
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {tableData && tableData.table && tableData.table.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData.table[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

        </>
    )
}