import React, { useState, useEffect } from "react";
import { Edit2, Save, Search, Trash, X } from "react-feather";
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../Helper/Context/context";
import { RoleWiseFormPostAPI, RoleWiseFormTableDataAPI } from "../../../Redux/UserMasterSlice/RoleWiseFormSlice";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { RoleDDLData } from "../../../CommonDDL/RoleDDLData";
import RoleWiseFormPopUp from "../../RoleWiseForm/RoleWiseFormPopUp";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import EmployeeNameDDL from "../../../CommonDDL/EmployeeNameDDL/EmployeeNameDDL";
import { DepartmentNameDDLAPI } from "../../../Redux/DDLSlice";
import { UserWiseWardMappingPostAPI, UserWiseWardMappingTableDataAPI } from "../../../Redux/UserMasterSlice/UserWiseWardMappingSlice";
import EmployeeWardMappingDDL from "../../../CommonDDL/EmployeeWardMappingDDL";


export default function UserWiseWardMapping() {

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true)
    const [ISPost, setISPost] = useState(true)
    const [checkedAll, setcheckedAll] = useState(false)
    const [roleFlag, setroleFlag] = useState("")
    const [IsClear, setIsClear] = useState(false)
    const [gridData, setgridData] = useState([])

    const [RoleDDL, setRoleDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })
    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })
    const [employeeWardMappingDDL, setEmployeeWardMappingDDL] = useState({
        DDL: [],
        ID: 0,
        m_UsersID: 0,
        Label: "---Select---"
    })

    const handleOnClearClick = () => {

        setISPost(!ISPost)
    }

    const handlePost = () => {
        setISPost(!ISPost)
    }

    const [roleWiseTable, setroleWiseTable] = useState([])
    const [loading, setloading] = useState()
    const handleOnCloseClick = () => {
        setallState({ ...allState, showCreatePopup: false })
        // setshowDeletePopup(false)
    }

    const [allState, setallState] = useState({
        showCreatePopup: false,
        showDeletePopup: false,
        popUpFlag: "",
        apiFlag: "",
        editData: {},
        deleteData: "",
    })


    const handleAddNewClick = () => {
        setallState({ ...allState, popUpFlag: "Add", apiFlag: "Insert", showCreatePopup: true })
    }

    // const handleChecklist = (event, formManagerID) => {
    //     // setcheckedAll(false)
    //     const { checked } = event.target
    //     const selectedIndex = roleWiseTable.findIndex((id) => id.m_FormManagerID === formManagerID)
    //     roleWiseTable[selectedIndex].isChecked = checked ? 1 : 0
    //     setroleWiseTable([...roleWiseTable])
    // }

    // const [AllChecked, setAllChecked] = useState(false)

    const handleAllSelectChecklist = (event) => {
        // setcheckedAll(true)
        const { checked } = event.target
        gridData && gridData.forEach((item) => {
            return item.isChecked = checked ? 1 : 0
        })
        setgridData([...gridData])
        // setAllChecked(true)
        // if(gridData)
    }

    // const handleAllSelectChecklist = (event) => {
    //     // setcheckedAll(true)
    //     const { checked } = event.target
    //     roleWiseTable.forEach((item) => {
    //         return item.isChecked = checked ? 1 : 0
    //         // for condition rendering
    //         // if(item.rowNum <= 3){
    //         //     return item.isChecked = checked ? 1 : 0
    //         // }else{
    //         //    return item.isChecked = 0
    //         // }
    //     })
    //     setroleWiseTable([...roleWiseTable])
    // }


    // useEffect(() => {
    //     Get_M_RoleWise_FormManager_Select({ UserID, RoleDDL, setroleWiseTable, setloading })
    // }, [ISPost, RoleDDL.ID])

    useEffect(() => {
        const data = {
            LoginM_UsersID: UserID,
            token: token,
            M_UsersID: employeeWardMappingDDL.m_UsersID
        }
        dispatch(UserWiseWardMappingTableDataAPI({ data }))
    }, [employeeWardMappingDDL.m_UsersID, ISPost])

    const { tableData, isLoading } = useSelector(state => state.UserWiseWardMappingTableData)


    useEffect(() => {
        let roleData = tableData && tableData.table && tableData.table.map((item) => ({ ...item }))
        // console.log("roleData",roleData)
        setgridData(roleData)
        // console.log("gridData", gridData)
    }, [tableData])


    const handleOnCheckClick = (item, index, check) => {
        if (gridData) {
            let tempGridData = [...gridData]
            tempGridData[index].isChecked = check
            // console.log(tempGridData)
            setgridData(tempGridData)
            // console.log("gridData", gridData)
        }
        // console.log(item)
    }


    // const handleOnSaveClick = () => {
    //     const finalData = roleWiseTable.filter((item) => {
    //         if (item.isChecked) {
    //             return item.m_FormManagerID
    //         }
    //     }).map((item) => item.m_FormManagerID)

    //     // Post_M_RoleWise_FormManager_InsertUpdate({ RoleDDL, UserID, finalData, setISPost, ISPost })

    //     const data = {
    //         M_RoleID: RoleDDL.ID,
    //         M_FormManagerID_Data: finalData,
    //         Flag: 'Update',
    //         UserID: UserID,
    //         token: token,
    //         handlePost: handlePost,
    //         // handleClear: handleClear,
    //     }

    //     dispatch(RoleWiseFormPostAPI({ data }))

    // }

    const handleOnSaveClick = () => {
        let allCheckData = ""
        gridData.forEach((item) => {
            if (item.isChecked) {
                allCheckData = allCheckData + item.paramID + ","
            }

        })
        console.log(allCheckData)
        const data = {
            M_WardID_Data: allCheckData,
            M_UsersID: employeeWardMappingDDL.m_UsersID,
            LoginM_UsersID: UserID,
            token: token,
            Flag: "Insert",
            handlePost: handlePost,
            handleAddCloseClick: handleOnCloseClick
        }

        dispatch(UserWiseWardMappingPostAPI({ data }))
    }

    const handleClear = () => {
        setRoleDDL({
            ...RoleDDL,
            ID: 0,
            Label: 'Select...'
        })
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: 'Select...'
        })
        setEmployeeWardMappingDDL({
            ...employeeWardMappingDDL,
            ID: 0,
            m_UsersID: 0,
            Label: 'Select...'
        })
    }

    return (
        <>
            {isLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="userWiseWardMapping" isopen={isopen} subMenu='userMaster' />
                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">User Wise Ward Mapping</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <RoleDDLData
                                                        RoleDDL={RoleDDL}
                                                        setRoleDDL={setRoleDDL}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <DepartmentDDL
                                                        departmentDDL={departmentDDL}
                                                        setdepartmentDDL={setdepartmentDDL}
                                                        IsPost={ISPost}
                                                        RoleDDL={RoleDDL}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <EmployeeWardMappingDDL
                                                        employeeWardMappingDDL={employeeWardMappingDDL}
                                                        setEmployeeWardMappingDDL={setEmployeeWardMappingDDL}
                                                        RoleDDL={RoleDDL}
                                                        departmentDDL={departmentDDL}

                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                    <div id='table-scroll'>
                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="5%">
                                                        <input
                                                            className="form-control"
                                                            type="checkbox"
                                                            // checked={checkedAll}
                                                            // checked={roleWiseTable.filter((item) => item?.isChecked !== 1).length < 1}
                                                            disabled={employeeWardMappingDDL.ID === 0}
                                                            onChange={(e) => handleAllSelectChecklist(e)}
                                                        />
                                                    </th>
                                                    <th>Wards Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {

                                                    gridData && gridData && gridData.length > 0 ? gridData.map((item, i) => (

                                                        <tr key={i}>
                                                            {
                                                                employeeWardMappingDDL.ID > 0 ?
                                                                    <>
                                                                        <td className="text_center td_padding">
                                                                            <input
                                                                                className="form-control"
                                                                                type="checkbox"
                                                                                name={item.paramName}
                                                                                value={item.paramID}
                                                                                // checked={item.isChecked === 1 ? true : false}
                                                                                checked={item.isChecked}
                                                                                // onChange={(e) => handleChecklist(e, item.m_FormManagerID)}
                                                                                onChange={(e) => {
                                                                                    handleOnCheckClick(e, i, !item.isChecked)
                                                                                }}
                                                                            />
                                                                        </td>

                                                                        <td>{item.paramName}</td>
                                                                    </>
                                                                    : ''
                                                            }
                                                        </tr>

                                                    )) : ""
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                    <div className="filter">
                                        <div className="col-12 col-lg-12 ">
                                            <button type="button" className="btn btn-clear text-white mr-2 mb-lg-3 float-right waves-effect waves-light"
                                                onClick={handleClear}
                                            >
                                                {/* <Search/> */}
                                                <X size={18} style={{ marginRight: "3px" }} /> Clear
                                            </button>
                                            <button type="button" className="btn btn-primary text-white mr-2 float-right waves-effect waves-light"
                                                onClick={handleOnSaveClick}
                                            >
                                                {/* <Search/> */}
                                                <Save size={18} style={{ marginRight: "3px" }} />
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="copyright">
                                Copyright 2023. all rights are reserved.<b>Version 1.0</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {allState.showCreatePopup ? <RoleWiseFormPopUp
                open={allState.showCreatePopup}
                handleOnCloseClick={handleOnCloseClick}
                allState={allState}
                IsPost={ISPost}
                setIsPost={setISPost}
                handleOnClearClick={handleOnClearClick}
            /> : <></>}


        </>
    )
}