import React, { useState, useEffect } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Select from "react-select";
import { Get_DDL_M_BudgetType } from "../../../APIs/DDLAPI/DDLAPI";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import { Pegination } from "../../../Components/Pegination/Pegination";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { useAuthState } from "../../../Helper/Context/context";
import DeletePopUp from "../../../Helper/DeletePopUp";
import LoaderFile from "../../../Helper/Loader/LoaderFile";

import { Get_T_BudgetAllocation_Select } from "../BudgetManagementAPI/GETAPI";
import { Post_T_BudgetAllocation_Delete } from "../BudgetManagementAPI/POSTAPI";

import AllocateBudgetPopUp from "./AllocateBudgetPopUp";
import Footer from "../../../Components/Footer/Footer";


export default function AllocateBudget() {

    const userDetails = useAuthState();
    const { UserID } = userDetails

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);
    const [AllocateBudgeTable, setAllocateBudgeTable] = useState([])
    const [apiFlag, setapiFlag] = useState()
    const [editData, seteditData] = useState()
    const [IsPost, setIsPost] = useState(false)

    const [budgetTypeDDL, setbudgetTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })

    // react pagination states 
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [loading, setloading] = useState()
    const [DeleteData, setDeleteData] = useState()


    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
        // setallState({ showCreatePopup:false, showDeletePopup:false, popUpFlag:"", apiFlag:"",editData: [], DeleteData:""    })
    }
    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        setflag("Add")
        setapiFlag("Insert")
        // setallState({...allState,showCreatePopup:true,apiFlag:"Insert",popUpFlag:"Add"})
    }
    const handleOnEditClick = (item) => {
        setshowCreatePopup(true)
        setflag("Update")
        setapiFlag("Update")
        seteditData(item)
        // setallState({...allState,showCreatePopup:true, apiFlag:"Update",popUpFlag:"Update",editData: item})
    }
    const handleOnDeleteClick = (item) => {
        setshowDeletePopup(true)
        setflag("Delete")
        setDeleteData(item)
        // setallState({...allState,DeleteData:item,showDeletePopup:true})
    }
    const handleOnSearchClick = () => {
        setIsPost(!IsPost)
        setCurrentPage(0)

    }

    const handleOnClearClick = () => {
        setIsPost(!IsPost)
        setCurrentPage(0)
        setbudgetTypeDDL({
            ...budgetTypeDDL,
            ID: 0,
            Label: "---Select---"
        })

    }

    useEffect(() => {
        Get_DDL_M_BudgetType({ UserID, budgetTypeDDL: budgetTypeDDL, setbudgetTypeDDL: setbudgetTypeDDL })
    }, [])

    useEffect(() => {
        Get_T_BudgetAllocation_Select({ setAllocateBudgeTable, AllocateBudgeTable, UserID, budgetTypeDDL, setloading, setTotalCount: setTotalCount, From: From, To: To, rowNo: rowNo, PerPageCount: PerPageCount })
    }, [To, IsPost])

    const handleDeleteData = () => {
        Post_T_BudgetAllocation_Delete({
            BudgetAllocationID: DeleteData,
            // allState: allState.DeleteData,
            UserID,
            apiFlag,
            handleOnCloseClick,
            IsPost,
            setIsPost,
        })
    }
    
  

    return (
        <>
            {loading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="allocatebudget"  subMenu="subBudget" isopen={isopen} />
                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Allocate Budget</h4>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                title="Add" onClick={() => handleAddNewClick()}>
                                                <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                                                Add
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className=" filter mb-0">
                                        {/* <div className="card filter mb-4"> */}
                                        <div className="card-body">
                                            <div className="form-row">

                                                <div className="col-4 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="Budgettype">Budget Type </label>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            maxMenuHeight={140}
                                                            value={{ value: budgetTypeDDL.ID, label: budgetTypeDDL.Label }}
                                                            onChange={(e) => {
                                                                e ?
                                                                    setbudgetTypeDDL({ ...budgetTypeDDL, ID: e.value, Label: e.label })
                                                                    :
                                                                    setbudgetTypeDDL({
                                                                        ...budgetTypeDDL, ID: 0, Label: "Select"
                                                                    })

                                                            }}
                                                            options={budgetTypeDDL.DDL}

                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-4 col-lg-4 text-center">
                                                    <button type="button" className="btn btn-primary text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnSearchClick}>
                                                        <Search size={18} style={{ marginRight: "3px" }} />  Search
                                                    </button>
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                        disabled={CurrentPage}
                                                    >
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="5%">Sr.No.</th>
                                                    <th>Budget Type</th>
                                                    <th>Budget Head Name</th>
                                                    <th>Budget Code</th>
                                                    <th>Department</th>
                                                    <th>Budget Amount (lacs)</th>
                                                    <th style={{ width: "150px", textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    AllocateBudgeTable && AllocateBudgeTable.length > 0 ? AllocateBudgeTable.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="text_center">{item.rowNum}</td>
                                                            <td>{item.budgetTypeName ? item.budgetTypeName : "-"}</td>
                                                            <td>{item.budgetName ? item.budgetName : "-"}</td>
                                                            <td>{item.budgetCode ? item.budgetCode : "-"}</td>
                                                            <td>{item.departmentName ? item.departmentName : "-"}</td>
                                                            <td>{item.budgetAmount ? item.budgetAmount.toFixed(2) : -""}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Edit" onClick={() => handleOnEditClick(item)}>
                                                                    <Edit size={15} color="#007bff" />
                                                                </span>

                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Delete" onClick={() => handleOnDeleteClick(item.t_BudgetAllocationID)}>
                                                                    <Trash2 size={15} color="red" />
                                                                </span>

                                                            </td>
                                                        </tr>
                                                    )) : ""
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                    {AllocateBudgeTable && AllocateBudgeTable.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={TotalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}

                                        />}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>


            {showCreatePopup ? <AllocateBudgetPopUp
                open={showCreatePopup}
                handleOnCloseClick={handleOnCloseClick}
                flag={flag}
                apiFlag={apiFlag}
                IsPost={IsPost}
                editData={editData}
                setIsPost={setIsPost}
                handleOnClearClick={handleOnClearClick}
            /> : <></>}
            {showDeletePopup ? <DeletePopUp
                open={showDeletePopup}
                handleOnCloseClick={handleOnCloseClick}
                handleDeleteData={handleDeleteData}

            /> : <></>}

        </>
    )
}