import React, { useState } from 'react'
import Header from '../../../Components/Header/Header';
import MobileHeader from '../../../Components/Header/MobileHeader';
import Sidebar from '../../../Components/Sidebar/Sidebar';


import Cost from './Cost';
import Health from './Health';
import MainProgress from './MainProgress';
import ProgressCard from './ProgressCard';
import Task from './Task';

export default function Progress() {
  const [isopen, setIsOpen] = useState(true);

  return (
    <div>
      <div className="wrapper">
        <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen}/>
          <Header setIsOpen={setIsOpen} isopen={isopen}/>
          <div className="container-fluid justify-content-center page-body-wrapper">
            <Sidebar isopen={isopen} />
            <div
              className="main-panel"
              style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
            >
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin">
                    <div className="row page-heading">
                      <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                        <h4 className="font-weight-bold">Progress</h4><p className="p-0">Project Status</p>
                      </div>
                      <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row dashboard pb-3">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <MainProgress
                            title="Name of Project"
                            description="Project Name goes here"
                          />
                          <MainProgress title="Total Cost" description="Rs. 15L" />
                          <MainProgress
                            title="Department"
                            description="Department Name goes here"
                          />
                          <MainProgress title="Area" description="Area123" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <Health />
                  <Task />
                  <Cost />
                  <div className="copyright">
                    Copyright 2021. all rights are reserved.<b>Version 1.0</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
