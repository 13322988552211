import AllRoutes from "./AllRoutes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from "./Helper/Context/context";
import { Provider } from "react-redux";
import store from "./Redux/Store";

function App() {
  return (
    <>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AuthProvider>
          <AllRoutes />
        </AuthProvider>
      </Provider>


      {/* <Dashboard/> */}


    </>
  );
}

export default App;
