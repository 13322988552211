import React, { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Designation_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import { DesignationPostAPI } from "../../../Redux/MasterSlice/DesignationSlice";
import { useDispatch } from "react-redux";


export default function EmployeeDesignationPopUp({ Flag, open, handleOnCloseClick, editData, apiFlag, setIsPost, IsPost, handleClearField }) {

    const userDetails = useAuthState();
    const { UserID, token, DepartmentID } = userDetails
    const dispatch = useDispatch();

    const [designationName, setDesignationName] = useState(Flag === "Update" ? editData.designationName : '')
 

    // ------------------- Validation----------------------
    const [formErrors, setformErrors] = useState({
        designationNameError: "",
    })
    const checkTextOnlyFields = (text, errorField, errorValue) => {
        let regex = new RegExp(/^[a-zA-Z ]*$/)
        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, [errorField]: "" })
        } else {
            setformErrors({ ...formErrors, [errorField]: errorValue })

        }
        // let regex = new RegExp(/^[a-zA-Z ]*$/)
        // // let regex = new RegExp(/^[A-Za-z\s\U+O9OO-U+O97F]+$/)
        // // let regex = new RegExp(/^[A-Za-z\s]*$/)
        // if (regex.test(text) || text === "") {
        //     setformErrors({ ...formErrors, [errorField]: errorValue })
        // } else {
        //     setformErrors({ ...formErrors, [errorField]: "" })

        // }
    }
    const handlePost = () => {
        setIsPost(!IsPost)
        handleOnCloseClick()
    }
    
    const Add = () => {
        dispatch(DesignationPostAPI({
            editData: editData,
            setIsPost: setIsPost,
            IsPost: IsPost,
            M_DesignationID: Flag === "Update" ? editData.m_DesignationID : 0,
            DesignationName:designationName,
            M_UsersID: UserID,
            token: token,
            Flag:Flag,
            handleOnCloseClick:handlePost
        }))
    }

    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Designation </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="designationName">Designation Name <Star /></label>
                                <input
                                    className="form-control"
                                    id="designationName"
                                    type="text"
                                    name="designationName"
                                    value={designationName}
                                    onChange={(e) => { setDesignationName(e.target.value) }}
                                />
                                <span className="text-danger error-danger">{formErrors.designationNameError}</span>
                            </div>
                        </div>

                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button"
                                    className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        designationName === "" ||
                                        formErrors.designationNameError === "Invalid Designation Name"
                                    }
                                >{Flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{Flag === 'Insert' ? 'Add' : 'Update'}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}