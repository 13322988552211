import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const SubWorkTypeTableDataAPI = createAsyncThunk("SubWorkTypeTableData", async ({ data }) => {
    const {
        M_WorkID,
        M_WorkTypeID,
        WorkTypeName,
        UserID,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Work_Select?M_WorkID=${M_WorkID ? M_WorkID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&WorkName=${WorkTypeName ? WorkTypeName : ''}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const SubWorkTypeTableDataSlice = createSlice({
    name: "SubWorkTypeTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SubWorkTypeTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SubWorkTypeTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SubWorkTypeTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SubWorkTypeTableDataReducer = SubWorkTypeTableDataSlice.reducer

// InsertUpdate Table Data

export const SubWorkTypePostAPI = createAsyncThunk("SubWorkTypePostData", async ({ addData }) => {
    const {
        M_WorkID,
        M_WorkTypeID,
        WorkName,  
        M_UserID,
        token,
        Flag,
        handlePost,
        handleAddCloseClick
    } = addData

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WorkID", M_WorkID);
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("WorkName", WorkName);
    formdata.append("UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Work_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const SubWorkTypeNameSlice = createSlice({
    name: "SubWorkTypePostData",
    initialState: {
        isLoading: false,
        SubWorkTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SubWorkTypePostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SubWorkTypePostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.SubWorkTypeData = action.payload;
        });
        builder.addCase(SubWorkTypePostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.SubWorkTypeData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const SubWorkTypePostDataReducer = SubWorkTypeNameSlice.reducer



// Delete Table Data

export const SubWorkTypeDeleteAPI = createAsyncThunk("SubWorkTypeDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WorkID", rowData?.m_WorkTypeID);
    formdata.append("M_UserID", UserID);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/PTMS/Master/Post_M_Work_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const SubWorkTypeDeleteSlice = createSlice({
    name: "SubWorkTypeDelete",
    initialState: {
        isDeleteLoading: false,
        SubWorktableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SubWorkTypeDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(SubWorkTypeDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SubWorktableData = action.payload;

        });
        builder.addCase(SubWorkTypeDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SubWorktableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const SubWorkTypeDeleteReducer = SubWorkTypeDeleteSlice.reducer