import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const ProjectTableDataAPI = createAsyncThunk("ProjectTableData", async ({ data }) => {
    const {
        M_ProjectID,
        M_ProjectTypeID,
        ProjectName,
        ProjectCode,
        UserID,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Project_Select?M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : '0'}&ProjectName=${ProjectName ? ProjectName : ''}&ProjectCode=${ProjectCode ? ProjectCode : ''}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ProjectTableDataSlice = createSlice({
    name: "ProjectTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectTableDataReducer = ProjectTableDataSlice.reducer

// InsertUpdate Table Data

export const ProjectPostAPI = createAsyncThunk("ProjectPostData", async ({ addData }) => {
    const {
        M_ProjectID,
        M_ProjectTypeID,
        ProjectName,
        ProjectCode,
        UserID,
        Flag,
        token,
        handlePost,
        handleAddCloseClick
    } = addData

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectTypeID", M_ProjectTypeID);
    formdata.append("M_ProjectID", M_ProjectID);
    formdata.append("ProjectName", ProjectName);
    formdata.append("ProjectCode", ProjectCode);
    formdata.append("UserID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Project_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const ProjectNameSlice = createSlice({
    name: "ProjectPostData",
    initialState: {
        isLoading: false,
        ProjectData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectData = action.payload;
        });
        builder.addCase(ProjectPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectPostDataReducer = ProjectNameSlice.reducer



// Delete Table Data

export const ProjectDeleteAPI = createAsyncThunk("ProjectDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectID", rowData?.m_ProjectID);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Project_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const ProjectDeleteSlice = createSlice({
    name: "ProjectDelete",
    initialState: {
        isDeleteLoading: false,
        ProjecttableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.ProjecttableData = action.payload;

        });
        builder.addCase(ProjectDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.ProjecttableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectDeleteReducer = ProjectDeleteSlice.reducer