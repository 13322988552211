import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function PendingUtilizationStatementCard({ title, count, cardName, path, Flag }) {
  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate(`${path}?projectType=${cardName}&title=${title}&Flag=${Flag}`);

  };
  return (
    <div>
      <span onClick={handleOnClick} class="con-wrap">
        <p className='spacing_text ml-lg-n2'>{title}</p>
        <span>
          <h2 className='font-weight-bold mb-lg-2'>{count}</h2>
        </span>
      </span>
    </div>
  )
}
