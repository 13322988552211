import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const MainWorkTypeTableDataAPI = createAsyncThunk("MainWorkTypeTableData", async ({ data }) => {
    const {
        M_WorkTypeID,
        UserID,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_WorkType_Select?M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&WorkTypeName=&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const MainWorkTypeTableDataSlice = createSlice({
    name: "MainWorkTypeTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MainWorkTypeTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MainWorkTypeTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(MainWorkTypeTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const MainWorkTypeTableDataReducer = MainWorkTypeTableDataSlice.reducer

// InsertUpdate Table Data

export const MainWorkTypePostAPI = createAsyncThunk("MainWorkTypePostData", async ({ addData }) => {
    const {
        M_WorkTypeID,
        WorkTypeName,
        M_UserID,
        token,
        Flag,
        handlePost,
        handleAddCloseClick
    } = addData

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("WorkTypeName", WorkTypeName);
    formdata.append("UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_WorkType_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const MainWorkTypeNameSlice = createSlice({
    name: "MainWorkTypePostData",
    initialState: {
        isLoading: false,
        MainWorkTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MainWorkTypePostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MainWorkTypePostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.MainWorkTypeData = action.payload;
        });
        builder.addCase(MainWorkTypePostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.MainWorkTypeData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const MainWorkTypePostDataReducer = MainWorkTypeNameSlice.reducer



// Delete Table Data

export const MainWorkTypeDeleteAPI = createAsyncThunk("MainWorkTypeDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WorkTypeID", rowData?.m_WorkTypeID);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_WorkType_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const MainWorkTypeDeleteSlice = createSlice({
    name: "MainWorkTypeDelete",
    initialState: {
        isDeleteLoading: false,
        WorkDatatableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MainWorkTypeDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(MainWorkTypeDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.WorkDatatableData = action.payload;

        });
        builder.addCase(MainWorkTypeDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.WorkDatatableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const MainWorkTypeDeleteReducer = MainWorkTypeDeleteSlice.reducer