import React, { useEffect, useId } from "react";
import { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Department_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import Select from "react-select";
import { Get_DDL_M_Department } from "../../../APIs/DDLAPI/DDLAPI";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import { useDispatch, useSelector } from "react-redux";
import { DepartmentNameDDLAPI } from "../../../Redux/DDLSlice";
import { SubDepartmentPostAPI } from "../../../Redux/MasterSlice/SubDepartmentSlice";

export default function SubDepartmentPopUp({ Flag, open, handleOnCloseClick, deptOfficeTypeIndicatorID, apiFlag, IsPost, setIsPost, editData, handleOnClearClick }) {

    const userDetails = useAuthState();
    const { UserID, token, DepartmentID } = userDetails
    const dispatch = useDispatch();

    // console.log(editData)
    const [designationDDL, setdesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    });
    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: Flag === 'Update' ? editData.m_DepartmentID : 0,
        Label: Flag === 'Update' ? editData.subDepartmentName :"Select...",
    });

    const [designationTable, setdesignationTable] = useState([]);



    const [passwordField, setpasswordField] = useState("password")

    const [subDepartmentName, setSubdepartmentName] = useState(
        Flag === 'Update' ? editData.subDepartmentName: ""
        );




    const handleClearField = () => {
        setSubdepartmentName('')
        setdepartmentDDL({
            ...departmentDDL,
            ID:0,
            Label:'Select...'
        })
    }
    const showPassword = () => {
        if (passwordField === "password") {
            setpasswordField("text")
        } else {
            setpasswordField("password")
        }
    }
    const [formErrors, setformErrors] = useState({
        empNameError: "",
        emailError: "",
        mobileError: "",

    })

    const checkEmailvalidation = (text) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, emailError: "" })
        } else {
            setformErrors({ ...formErrors, emailError: "Enter valid email address" })
        }
    }

    const checkMobileValidation = (text) => {

        // let regex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
        let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, mobileError: "" })
        } else {
            setformErrors({ ...formErrors, mobileError: "Enter valid contact number" })

        }
    }

    // const { departmentName, subDepartmentName } = subDepartmentTextField
    // console.log(editData)
    const Add = () => {
        dispatch(SubDepartmentPostAPI({
            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost,
            handleOnClearClick: handleOnClearClick,
            M_SubDepartmentID: Flag === 'Update' ? editData.m_SubDepartmentID: 0,
            M_DepartmentID: departmentDDL.ID,
            SubDepartmentName: subDepartmentName,
            M_UsersID: UserID,
            Flag: Flag,
        }))
    }

    // useEffect(() => {
    //     Get_DDL_M_Department({ UserID, departmentDDL, setdepartmentDDL })
    // }, [])


    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>

                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Sub Department </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-md-12 col-lg-6">
                            <DepartmentDDL
                                departmentDDL={departmentDDL}
                                setdepartmentDDL={setdepartmentDDL}
                                IsPost=''
                                editData={editData}
                                FlagName={Flag}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="subDepartmentName"> Sub Department Name  <Star/> </label>
                                <input
                                    className="form-control"
                                    id="subDepartmentName"
                                    type="text"
                                    name="subDepartmentName"
                                    value={subDepartmentName}
                                    onChange={(e) => {
                                        setSubdepartmentName(e.target.value)
                                    }}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>



                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={Flag === 'Update' ? handleOnCloseClick : handleClearField}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />{Flag === 'Update' ? 'Cancel' : 'Clear'}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        subDepartmentName === "" ||
                                        departmentDDL.ID === 0
                                    }
                                >{Flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{Flag === 'Update' ? 'Update' : 'Add'}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}