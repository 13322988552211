import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";


export const DashboardCountDataAPI = createAsyncThunk("DashboardCountData", async ({ data }) => {
    const {
        M_UsersID,
        M_FinancialYearID,
        M_MonthID,
        Flag,
        token,
        ShowBy,
        handleGetCount
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_WEB_DB_MainCount_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_UsersID=${M_UsersID}&Flag=${Flag}&ShowBy=${ShowBy}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data) {
                handleGetCount && handleGetCount(result.data.table)
                return result.data
            } else {
                return result
            }
        })
})

const DashboardCountDataSlice = createSlice({
    name: "DashboardCountData",
    initialState: {
        isLoading: false,
        CountData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DashboardCountDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DashboardCountDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CountData = action.payload;
        });
        builder.addCase(DashboardCountDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CountData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DashboardCountDataReducer = DashboardCountDataSlice.reducer

//  Ward Wise Project Details API

export const WardWiseProjectCountDataAPI = createAsyncThunk("WardWiseProjectCountData", async ({ data }) => {
    const {
        M_UsersID,
        M_FinancialYearID,
        M_MonthID,
        Flag,
        token,
        ShowBy,
        // handleGetCount
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_WEB_DB_MainCount_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_UsersID=${M_UsersID}&Flag=${Flag}&ShowBy=${ShowBy}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data) {
                // handleGetCount && handleGetCount(result.data.table)
                return result.data
            } else {
                return result
            }
        })
})

const WardWiseProjectCountDataSlice = createSlice({
    name: "WardWiseProjectCountData",
    initialState: {
        WardWiseProjectisLoading: false,
        WardWiseProjectCountData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardWiseProjectCountDataAPI.pending, (state, action) => {
            state.WardWiseProjectisLoading = true;
        });
        builder.addCase(WardWiseProjectCountDataAPI.fulfilled, (state, action) => {
            state.WardWiseProjectisLoading = false;
            state.WardWiseProjectCountData = action.payload;
        });
        builder.addCase(WardWiseProjectCountDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.WardWiseProjectisLoading = false;
            state.isError = true;
            state.WardWiseProjectCountData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WardWiseProjectCountDataReducer = WardWiseProjectCountDataSlice.reducer


