import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";





export const WardMasterTableDataAPI = createAsyncThunk("WardMasterTableData", async ({ data }) => {
    const { M_WardID,
        M_ZoneID,
        M_UsersID,
        WardName,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Ward_Select?M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&WardName=${WardName ? WardName : ''}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WardMasterTableDataSlice = createSlice({
    name: "DepartmentTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WardMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WardMasterTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WardMasterTableDataReducer = WardMasterTableDataSlice.reducer


// InsertUpdate Table Data

export const WardMasterPostAPI = createAsyncThunk("WardMasterPostData", async ({ data }) => {

    const {
        handleOnCloseClick,
        setIsPost,
        IsPost,
        handleOnClearClick,
        token,
        Flag,
        M_WardID,
        M_ZoneID,
        WardName,
        UserID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WardID", M_WardID);
    formdata.append("M_ZoneID", M_ZoneID);
    formdata.append("WardName", WardName);
    formdata.append("M_UsersID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_Ward_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
})

const WardMasterPostSlice = createSlice({
    name: "WardMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardMasterPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WardMasterPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(WardMasterPostAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WardMasterPostDataReducer = WardMasterPostSlice.reducer



// Delete Table Data

export const WardMasterDeleteAPI = createAsyncThunk("WardMasterDelete", async ({ data }) => {
    const {
        M_WardID,
        UserID,
        handlePost,
        token,
        handleDeleteCloseClick,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WardID", M_WardID);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_Ward_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const WardMasterDeleteSlice = createSlice({
    name: "WardMasterDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(WardMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            console.log(action)
            toastSuccesss(action.payload.message)
        });
        builder.addCase(WardMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WardMasterDeleteReducer = WardMasterDeleteSlice.reducer