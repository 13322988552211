
import Select from "react-select"
import { useEffect } from "react"
import { Star } from "../../Components/CommoComponent/Star"
import { useSearchParams } from "react-router-dom"



export default function WardNameDDL(props) {
    const { wardNameDDL, setWardNameDDL, DDLData, editData, PopUpField, apiFlag, mendetary,WardWiseCard } = props
    const [searchParams] = useSearchParams()
    const Cardtitle = searchParams.get('Cardtitle')
    const CardID = searchParams.get('CardID')
    useEffect(() => {
        handleZoneDDL()
    }, [DDLData])

    const handleZoneDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {

            let list = DDLData.table.map((item, index) => ({

                // value: item.departmentCode,
                value: item.id,
                label: item.wardName,
            }))
            setWardNameDDL({
                DDL: list,
                ID: apiFlag === 'Update' ? editData.m_WardID : 0,
                Label: apiFlag === 'Update' ? editData.wardName : 'Select',

                // ID: CardFlag == 'Card' ? CardID : 0,
                // Label: CardFlag == 'Card' ? Cardtitle : "Select..."
            })
            if (CardID && Cardtitle) {
                setWardNameDDL({
                    DDL: list,
                    ID: CardID,
                    Label: Cardtitle
                })
            }

            // if (PopUpField && PopUpField.popupFlag) {
            //     setWardNameDDL({
            //         DDL: list,
            //         ID: apiFlag === 'Update' ? editData.id : 0,
            //         Label: apiFlag === 'Update' ? editData.wardName : "---Select---",
            //     })
            // } else {
            //     setWardNameDDL({
            //         DDL: list,
            //         ID: apiFlag === 'Update' ? editData.id : 0,
            //         Label: apiFlag === 'Update' ? editData.wardName : "---Select---",
            //     })
            // }
        }
        else {
            setWardNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Ward Name {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isDisabled={WardWiseCard == 'Yes'}
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: wardNameDDL.ID, label: wardNameDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setWardNameDDL({ ...wardNameDDL, ID: e.value, Label: e.label })
                            :
                            setWardNameDDL({ ...wardNameDDL, ID: 0, Label: "Select---" })
                    }}
                    options={wardNameDDL.DDL}

                />
            </div>
        </>
    )
}