import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastInfo, toastSuccesss } from "../../../Helper/ToastMessage";

// ============================== Master designation =================================== 


export function Post_M_Designation_InsertUpdate({ UserID, apiFlag, editData, employeDesignationTextField, handleOnCloseClick, setIsPost, IsPost, handleClearField }) {

    const { designationName } = employeDesignationTextField

    var formdata = new FormData();
    formdata.append("M_DesignationID", apiFlag === "Insert" ? "0" : editData.m_DesignationID);
    formdata.append("DesignationName", designationName ? designationName : "");
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Designation_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleClearField()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ============================== Master Department =================================== 

export function Post_M_Department_InsertUpdate({ apiFlag, UserID, editData, departmentName, contactNumber, emailID, userName, password, handleOnCloseClick, depFlag, deptOfficeTypeIndicatorID, setIsPost, IsPost, agencyName, address, contractPersonName, contractContactNumber, emailAddress, handleOnClearClick }) {

    // const {departmentName,contactNumber,emailID,userName,password} = departmentTextField

    var formdata = new FormData();
    formdata.append("M_DepartmentID", apiFlag === "Insert" ? "0" : editData.m_DepartmentID);
    formdata.append("M_OfficeID", "1");
    formdata.append("M_DeptOfficeTypeIndicatorID", deptOfficeTypeIndicatorID);
    formdata.append("DepartmentName", departmentName ? departmentName : "");
    formdata.append("ContactPerson", contractPersonName);
    formdata.append("MobileNo", contactNumber ? contactNumber : "");
    formdata.append("EmailId", emailID ? emailID : "");
    formdata.append("DepartmentAddress", address);
    formdata.append("UserName", userName ? userName : "");
    formdata.append("Password", password ? password : "");
    formdata.append("Login_UserID", UserID);
    formdata.append("DeptFlag", depFlag);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Department_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ------------------------Employee Post API-----------------------

export function Post_M_Employee_InsertUpdate({
    EmployeeID,
    empolyeeTextField,
    designationDDL,
    departmentDDL,
    UserID,
    Flag,
    setIsPost,
    IsPost,
    setloading,
    handelPopUpClose,
    handleOnClearClick
}) {
    const { employeeName, joiningDate, emailAddress, contactNumber } = empolyeeTextField

    var formdata = new FormData();
    formdata.append("M_EmployeeID", EmployeeID);
    formdata.append("EmployeeName", employeeName ? employeeName : "");
    formdata.append("M_DeptOfficeTypeIndicatorID", "0");
    formdata.append("M_DepartmentID", departmentDDL.ID);
    formdata.append("M_DesignationID", designationDDL.ID);
    formdata.append("JoiningDate", joiningDate && joiningDate !== "Invalid date" ? joiningDate : "");
    formdata.append("EmailID", emailAddress ? emailAddress : "");
    formdata.append("MobileNumber", contactNumber ? contactNumber : "");
    formdata.append("Login_UserID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Employee_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handelPopUpClose()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ----------------------Employee Delete API-------------
export function Post_M_Employee_Delete({ UserID, EmployeeID, handelPopUpClose, setIsPost, IsPost }) {

    var formdata = new FormData();
    formdata.append("M_EmployeeID", EmployeeID);
    formdata.append("UserID", UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Employee_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.message === "You can not Delete this Record") {
                    toastErrorr(result.message)
                } else {
                    toastSuccesss(result.message)
                }
            } else {
                toastErrorr(result.message)
            }
            handelPopUpClose()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// -----------------User-creation Post API---------------

export function Post_M_Users_InsertUpdate({ employeeDDL, userTextField, UserID, roleDDL, departmentDDL, designationDDL, userStatusDDL, Flag, handelPopUpClose, setIsPost, IsPost, handleOnClearClick }) {
    const { tableName, userName, password } = userTextField
    var formdata = new FormData();
    formdata.append("M_UsersID", UserID);
    formdata.append("M_RoleID", roleDDL.ID);
    formdata.append("M_DeptOfficeTypeIndicatorID", "0");
    formdata.append("M_DepartmentID", departmentDDL.ID);
    formdata.append("M_EmployeeID", employeeDDL ? employeeDDL.ID : "0");
    formdata.append("M_DesignationID", designationDDL ? designationDDL.ID : "0");
    formdata.append("TableName", tableName ? tableName : " ");
    formdata.append("UserName", userName ? userName : "");
    formdata.append("Password", password ? password : "");
    formdata.append("UserStatusIndicatorID", userStatusDDL.ID);
    formdata.append("Login_UserID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Users_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
                setIsPost(!IsPost)
            }
            else {
                toastErrorr(result.message)
            }
            handelPopUpClose()
            handleOnClearClick()


        })
        .catch(err => {
            console.log(err)
        })
}

// ------------------ Employee Delete API----------
export function Post_M_Users_Delete({ UserID, deleteI, handelPopUpClose, setloading, setIsPost, IsPost }) {

    var formdata = new FormData();
    formdata.append("M_UsersID", deleteI);
    formdata.append("Login_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Users_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handelPopUpClose()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}


// ------------------------budget Post API-----------------------

export function Post_M_Budget_InserUpdate({ BudgetID, UserID, apiFlag, IsPost, setIsPost, budgetTypeDDL, budgetTextField, handleOnCloseClick, handleOnClearClick }) {

    const { budgetHeadName, budgetCode } = budgetTextField

    var formdata = new FormData();
    formdata.append("M_BudgetID", BudgetID);
    formdata.append("M_BudgetTypeID", budgetTypeDDL ? budgetTypeDDL.ID : "0");
    formdata.append("BudgetName", budgetHeadName ? budgetHeadName : "");
    formdata.append("BudgetCode", budgetCode ? budgetCode : "");
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(` ${BaseUrl}/PTMS/Master/Post_M_Budget_InserUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}



// ------------------------work Post API-----------------------

export function Post_M_Work_InsertUpdate({ WorkID, UserID, workDDL, workTextField, apiFlag, handleOnCloseClick, IsPost, setIsPost, handleOnClearClick }) {

    const { workName } = workTextField

    var formdata = new FormData();
    formdata.append("M_WorkID", WorkID);
    formdata.append("M_WorkTypeID", workDDL ? workDDL.ID : "0");
    formdata.append("WorkName", workName ? workName : "");
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`  ${BaseUrl}/PTMS/Master/Post_M_Work_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.message === "Record Already Exists") {
                    toastInfo(result.message)
                } else {
                    toastSuccesss(result.message)
                }
            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}


// ------------------------Project Type Insert Update-------------------

export function Post_M_ProjectType_InsertUpdate({ ProjectTypeID, projectTypeTextField, apiFlag, UserID, setIsPost, IsPost, handleOnCloseClick, handleOnClearClick }) {

    const { projectTypeName } = projectTypeTextField

    var formdata = new FormData();
    formdata.append("M_ProjectTypeID", ProjectTypeID);
    formdata.append("ProjectTypeName", projectTypeName);
    formdata.append("Login_UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_ProjectType_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ----------------------------------Project Type Delete API------------
export function Post_M_ProjectType_Delete({ ProjectTypeID, UserID, setIsPost, IsPost, handelPopUpClose }) {
    var formdata = new FormData();
    formdata.append("M_ProjectTypeID", ProjectTypeID);
    formdata.append("M_UsersID", UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_ProjectType_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handelPopUpClose()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}


// ------------------------------Project Master Insert Update---------------------

export function Post_M_Project_InsertUpdate({ ProjectID, ProjectTypeID, projectTextField, apiFlag, UserID, setIsPost, IsPost, handleOnCloseClick, handleOnClearClick }) {

    const { projectName, projectCode } = projectTextField

    var formdata = new FormData();
    formdata.append("M_ProjectID", ProjectID);
    formdata.append("M_ProjectTypeID", ProjectTypeID.ID ? ProjectTypeID.ID : "0");
    formdata.append("ProjectName", projectName);
    formdata.append("ProjectCode", projectCode);
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Project_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// --------------------------------- Delete Project Master-------------

export function Post_M_Project_Delete({ ProjectID, UserID, setIsPost, IsPost, handelPopUpClose }) {
    var formdata = new FormData();
    formdata.append("M_ProjectID", ProjectID);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Project_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handelPopUpClose()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}



