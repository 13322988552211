import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";





export const ContractorMasterTableDataAPI = createAsyncThunk("ContractorMasterTableData", async ({ data }) => {
    const {
        M_ContractorID,
        ContractorName,
        UserID,
        From,
        token,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Contractor_Select?M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&ContractorName=${ContractorName ? ContractorName : ''}&M_UsersID=0&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ContractorMasterTableDataSlice = createSlice({
    name: "ContractorMasterTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ContractorMasterTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorMasterTableDataReducer = ContractorMasterTableDataSlice.reducer


// InsertUpdate Table Data

export const ContractorMasterPostAPI = createAsyncThunk("ContractorMasterPostData", async ({ data }) => {
    const {
        handleOnCloseClick,
        setIsPost,
        IsPost,
        handleOnClearClick,

        M_ContractorID,
        ContractorName,
        Address,
        Contact_Person,
        EmailAddress,
        MobileNumber,
        UserID,
        token,
        Flag, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ContractorID", M_ContractorID);
    formdata.append("ContractorName", ContractorName);
    formdata.append("Address", Address);
    formdata.append("Contact_Person", Contact_Person);
    formdata.append("EmailAddress", EmailAddress);
    formdata.append("MobileNumber", MobileNumber);
    formdata.append("UserID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_Contractor_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
})

const ContractorMasterPostSlice = createSlice({
    name: "ManPowerTypePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorMasterPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorMasterPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(ContractorMasterPostAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ContractorMasterPostDataReducer = ContractorMasterPostSlice.reducer



// Delete Table Data

// export const ContractorMasterDeleteAPI = createAsyncThunk("ContractorMasterDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick, M_ContractorID, M_UserID }) => {
//     const { rowData, apiFlag } = PopUpField

//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", "Bearer " + token);

//     var formdata = new FormData();
//     formdata.append("M_ContractorID", M_ContractorID);
//     formdata.append("M_UserID", M_UserID);

//     var requestOptions = {
//         method: 'DELETE',
//         headers: myHeaders,
//         body: formdata,
//         redirect: 'follow'
//     };

//     return fetch(`${BaseUrl}/Master/M_Department_Delete`, requestOptions)
//         .then((res) => res.json())
//         .then((result) => {
//             if (result.status) {
//                 handlePost()
//                 handleDeleteCloseClick()
//             } else {
//                 toastErrorr(result.message)
//                 handleDeleteCloseClick()
//             }
//             return result

//         })
// })

// const DepartmentDeleteSlice = createSlice({
//     name: "DepartmentDelete",
//     initialState: {
//         isDeleteLoading: false,
//         tableData: null,
//         isError: false,
//     },
//     extraReducers: (builder) => {
//         builder.addCase(DepartmentDeleteAPI.pending, (state, action) => {
//             state.isDeleteLoading = true;
//         });
//         builder.addCase(DepartmentDeleteAPI.fulfilled, (state, action) => {
//             state.isDeleteLoading = false;
//             state.tableData = action.payload;
//             toastSuccesss(action.payload.message)
//         });
//         builder.addCase(DepartmentDeleteAPI.rejected, (state, action) => {
//             console.log("Error", action.payload);
//             state.isDeleteLoading = false;
//             state.isError = true;
//             state.tableData = null;
//             toastErrorr('Something went wrong')
//         });
//     },
// })

// export const DepartmentDeleteReducer = DepartmentDeleteSlice.reducer