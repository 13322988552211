import { DepartmentNameReducer, DesignationNameReducer, EmployeeNameReducer } from "./DDLSlice";
import { rootReducer } from "./root-reducer";

const { configureStore } = require("@reduxjs/toolkit");

const store = configureStore({
    reducer: rootReducer,

})

export default store;