import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const DepartmentTableDataAPI = createAsyncThunk("DepartmentTableData", async ({ data }) => {
    const { M_DepartmentID,
        DepartmentName,
        M_UsersID,
        M_DeptOfficeTypeIndicatorID,
        Dept_Code,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Department_Select?M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&DepartmentName=${DepartmentName ? DepartmentName : ''}&M_DeptOfficeTypeIndicatorID=${M_DeptOfficeTypeIndicatorID ? M_DeptOfficeTypeIndicatorID : '0'}&M_UsersID=${M_UsersID}&Dept_Code=${Dept_Code ? Dept_Code : ''}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DepartmentTableDataSlice = createSlice({
    name: "DepartmentTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DepartmentTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DepartmentTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DepartmentTableDataReducer = DepartmentTableDataSlice.reducer

// InsertUpdate Table Data

export const DepartmentPostAPI = createAsyncThunk("DepartmentPostData", async ({ data }) => {

    const {
        handleOnCloseClick,
        setIsPost,
        IsPost,
        handleOnClearClick,
        M_DepartmentID,
        token,
        Flag,
        M_OfficeID,
        M_DeptOfficeTypeIndicatorID,
        DepartmentName,
        ContactPerson,
        MobileNo,
        EmailId,
        DepartmentAddress,
        UserName,
        Password,
        DeptFlag,
        DeptCode,
        UserID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_DepartmentID", M_DepartmentID);
    formdata.append("M_OfficeID", M_OfficeID);
    formdata.append("M_DeptOfficeTypeIndicatorID", M_DeptOfficeTypeIndicatorID);
    formdata.append("DepartmentName", DepartmentName);
    formdata.append("ContactPerson", ContactPerson);
    formdata.append("MobileNo", MobileNo);
    formdata.append("EmailId", EmailId);
    formdata.append("DepartmentAddress", DepartmentAddress);
    formdata.append("UserName", UserName);
    formdata.append("Password", Password);
    formdata.append("Login_UserID", UserID);
    // formdata.append("DeptFlag", DeptFlag);
    formdata.append("DeptCode", DeptCode);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_Department_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
})

const DepartmentNameSlice = createSlice({
    name: "DepartmentPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DepartmentPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(DepartmentPostAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DepartmentPostDataReducer = DepartmentNameSlice.reducer



// Delete Table Data

export const DepartmentDeleteAPI = createAsyncThunk("DepartmentDelete", async ({ data }) => {
    // const { rowData, apiFlag } = PopUpField

    const {
        token,
        UserID,
        handleOnCloseClick,
        setIsPost,
        IsPost,
        M_DepartmentID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_DepartmentID", M_DepartmentID);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Department_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                // console.log(result)
                if (result.status) {
                    console.log(result)
                    // toastSuccesss(result.message)
                } else {
                    // toastErrorr(result.message)
                }
            }
            handleOnCloseClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
})

const DepartmentDeleteSlice = createSlice({
    name: "DepartmentDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(DepartmentDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(DepartmentDeleteAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DepartmentDeleteReducer = DepartmentDeleteSlice.reducer