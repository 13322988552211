import React, { useState } from 'react'
import Header from '../../../Components/Header/Header';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import MobileHeader from '../../../Components/Header/MobileHeader';
import { Search, X } from 'react-feather';



const PhysicalSpillOverTable = () => {



    const [isopen, setIsOpen] = useState(true);

    const navigate = useNavigate()

    const handleOnClick = () => {
        navigate("/PhysicalSpillOverDepartmentPage")
    }
    const options = [
        { value: 'New', label: 'New' },
        { value: 'Ongoing', label: 'Ongoing' },
        { value: 'Completed', label: 'Completed' }
    ]

    const options1 = [
        { value: 'Ward123', label: 'Ward123' },
        { value: 'Ward456', label: 'Ward456' },
        { value: 'Ward789', label: 'Ward789' }
    ]

    const data = [
        {
            Sr: "1",
            NameOfDepartment: "Public Works Department",
            TotalProject: "10",

        },
        {
            Sr: "2",
            NameOfDepartment: "Garden Department",
            TotalProject: "20",
        },
        {
            Sr: "3",
            NameOfDepartment: "Public Works Department",
            TotalProject: "10",
        },
        {
            Sr: "4",
            NameOfDepartment: "Garden Department",
            TotalProject: "20",
        },
        {
            Sr: "5",
            NameOfDepartment: "Public Works Department",
            TotalProject: "10",
        },
        {
            Sr: "6",
            NameOfDepartment: "Garden Department",
            TotalProject: "20",
        },
        {
            Sr: "7",
            NameOfDepartment: "Public Works Department",
            TotalProject: "10",
        },
        {
            Sr: "8",
            NameOfDepartment: "Garden Department",
            TotalProject: "20",
        },
        {
            Sr: "9",
            NameOfDepartment: "Public Works Department",
            TotalProject: "10",
        },
        {
            Sr: "10",
            NameOfDepartment: "Garden Department",
            TotalProject: "20",
        },


    ]

    return (
        <div className="wrapper">
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar isopen={isopen} />
                    <div
                        className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Physical Spill Over</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="Budgettype">Status</label>

                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="WardNo">Ward No.</label>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options1}

                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-4 text-center">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>
                                                    <button type="button" className="btn btn-primary text-white mr-2 mt-4 waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <Search size={18} style={{ marginRight: "3px" }} />  Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered ">
                                            <thead>
                                                <tr>
                                                    <th >Sr.No.</th>
                                                    <th>Name of Department</th>
                                                    <th>Total Projects</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data && data.length > 0 ? data.map((item, index) => (

                                                    <tr key={index}>
                                                        <td style={{ textAlign: 'center' }}>{item.Sr}</td>
                                                        <td>{item.NameOfDepartment ? item.NameOfDepartment : "-"}</td>

                                                        <td>

                                                            <a onClick={handleOnClick} className="btn btn-primary btn-sm px-3 py-2">{item.TotalProject}</a></td>


                                                    </tr>


                                                    // </tr>
                                                )) : <tr >  No Record...</tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default PhysicalSpillOverTable
