import React from 'react'

export default function Health() {
  return (
    <div class="col-12 col-lg-4 pb-3">
    <div class="card h-100 m-0">
        <div class="card-header font-weight-bold">
        PROJECT HEALTH
        </div>
        <div class="card-body">
            <table cellpadding="0" cellspacing="0" border="0" class="table table-task">
                <tr>
                    <th>Time Limit</th>
                    <td className='pt-2 pb-2'>36 Months</td>
                </tr>
                <tr>
                    <th>Time Consumed</th>
                    <td className='pt-2 pb-2'>50% of Time Consumed </td>
                </tr>
                <tr>
                    <th>Total Items in Project</th>
                    <td className='pt-2 pb-2'>10 Items in Project</td>
                </tr>
                <tr>
                    <th>Items Completed</th>
                    <td className='pt-2 pb-2'>5 Items to be Completed</td>
                </tr>
                <tr>
                    <th>Physical Progress</th>
                    <td className='pt-2 pb-2'>50% </td>
                </tr>
                <tr>
                    <th>Financial Progress</th>
                    <td className='pt-2 pb-2'>50% </td>
                </tr>
            </table>
        </div>
    </div>
</div>
  )
}
