import React, { useState, useEffect } from "react";
import Select from "react-select";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Flag, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { Star } from "../../../Components/CommoComponent/Star";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import SubDepartmentDDL from "../../../CommonDDL/SubDepartmentDDL/SubDepartmentDDL";
import ProjectNameDDL from "../../../CommonDDL/ProjectNameDDL/ProjectNameDDL";
import WardNameDDL from "../../../CommonDDL/WardNameDDL/WardNameDDL";
import ProjectPriorityDDL from "../../../CommonDDL/ProjectPriorityDDL/ProjectPriorityDDL";
import KeyProjectDDL from "../../../CommonDDL/KeyProjectDDL/KeyProjectDDL";
import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import { DepartmentNameDDLAPI, ElectoralWardNumberDDLAPI, SubDepartmentDDLAPI, WardNameDDLAPI } from "../../../Redux/DDLSlice";
import { useDispatch, useSelector } from "react-redux";
import { ProjectDetailsPostAPI } from "../../../Redux/ProjectCreationAllocationSlice/ProjectDetailsSlice";
import { ConcateWorkAndWorkTypeDDL } from "../../../CommonDDL/ConcateWorkAndWorkTypeDDL";
import AssignJEDDL from "../../../CommonDDL/AssignJEDDL/AssignJEDDL";
import MultiElectoralNumDDL from "../../../CommonDDL/ElectoralWardNumDDL/MultiElectoralNumDDL";
import moment from 'moment';
import ProjectNameNewDDL from "../../../CommonDDL/ProjectNameMilestoneDDL/ProjectNameNewDDL";

export default function ProjectDetailsPopUp(props) {

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const { open, allState, handleOnCloseClick, IsPost, setIsPost, handleOnClearClick } = props
    const { popUpFlag, apiFlag, editData } = allState

    const YearID = localStorage.getItem("YearID")

    const dispatch = useDispatch()

    // console.log('YearID', YearID);

    const [projectERPCode, setProjectERPCode] = useState(apiFlag === 'Update' ? editData.project_ERP_Code : '')
    const [lfmSFileNo, setlfmSFileNo] = useState(apiFlag === 'Update' ? editData.lfmS_File_No : '')

    // const [M_WardWiseNumberID, setM_WardWiseNumberID] = useState([{ ID: apiFlag === 'Update' ? editData.m_WardWiseNumberID : 0, }]);
    // const [M_WardWiseNumber, setM_WardWiseNumber] = useState({ Label: apiFlag === 'Update' ? editData.wardNumber : "---Select---" });
    // const [selectedOptions, setSelectedOptions] = useState({
    //     ID: apiFlag === 'Update' ? editData.m_WardWiseNumberID : 0,
    //     Label: apiFlag === 'Update' ? editData.wardNumber : "---Select---"
    // });

    const [M_WardWiseNumberID, setM_WardWiseNumberID] = useState([]);
    const [M_WardWiseNumber, setM_WardWiseNumber] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([{ value: 1, label: 10 }]);

    // console.log('selectedOptions', selectedOptions.ID, selectedOptions.Label)
    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.M_DepartmentID : 0,
        Label: apiFlag === 'Update' ? editData.departmentName : "---Select---"
    })

    const [subDepartmentDDL, setSubDepartmentDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.m_SubDepartmentID : 0,
        Label: apiFlag === 'Update' ? editData.subDepartmentName : "---Select---"
    })
    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.m_ProjectTypeID : 0,
        Label: apiFlag === 'Update' ? editData.projectName : "---Select---",

    })
    const [WorkTypeDDL, setWorkTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.m_WorkTypeID : 0,
        Label: apiFlag === 'Update' ? editData.workTypeName : "---Select---"
    })
    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.m_WardID : 0,
        Label: apiFlag === 'Update' ? editData.wardName : "---Select---"
    })
    const [projectPriorityDDL, setProjectPriorityDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.m_ProjectPriorityID : 0,
        Label: apiFlag === 'Update' ? editData.projectPriorityName : "---Select---"
    })
    const [ProjectDDL, setProjectDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.m_ProjectID : 0,
        Label: apiFlag === 'Update' ? editData.projectName : "---Select---",
        projectCode: apiFlag === 'Update' ? editData.project_ERP_Code : "",
        numberOfMileStone: "",
    })
    // console.log(editData)

    const [keyProjectDDL, setKeyProjectDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.key_ProjectM_IndicatorID : 0,
        Label: apiFlag === 'Update' ? editData.key_ProjectIndicatorName : "---Select---"
    })

    const [electoralWardNumDDL, setElectoralWardNumDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? (editData.m_WardWiseNumberID && editData.m_WardWiseNumberID.split(',')) : 0,
        Label: apiFlag === 'Update' ? (editData.wardNumber && editData.wardNumber.split(',')) : "---Select---"
    })

    // console.log("electoralWardNumDDL",typeof(electoralWardNumDDL.ID))
    const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.m_WorkID : 0,
        Label: apiFlag === 'Update' ? editData.workAndWorkType : "---Select---"
    })

    const [assignJEDDL, setAssignJEDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.assignJE_M_UsersID : 0,
        Label: apiFlag === 'Update' ? editData.employeeName : "---Select---"
    })

    const [projectNameMilestoneDDL, setProjectNameMilestoneDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
        projectCode: '',
        numberOfMileStone: ''
    })

    // console.log(editData)

    useEffect(() => {
        const data = { UserID, token }
        dispatch(DepartmentNameDDLAPI({ data }))
    }, [IsPost])

    const { DepartmentDDLData } = useSelector(state => state.DepartmentNameData)

    useEffect(() => {
        const data = {
            UserID,
            token,
            M_WardWiseNumberID: 0,
            M_WardID: wardNameDDL.ID
        }
        dispatch(ElectoralWardNumberDDLAPI({ data }))
    }, [wardNameDDL.ID])

    const { ElectoralWardNumberData } = useSelector(state => state.ElectoralWardNumberDDLData)

    // useEffect(() => {
    //     if(wardNameDDL.ID != 0) {
    //         setElectoralWardNumDDL({
    //             ...electoralWardNumDDL,
    //             ID:0,
    //             Label:'Select...'
    //         })
    //     }

    // }, [wardNameDDL.ID])

    // console.log(editData)

    useEffect(() => {
        const data = { UserID, token, }
        dispatch(WardNameDDLAPI({ data }))
    }, [])

    const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

    const handleClearDDL = () => {
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        setSubDepartmentDDL({
            ...subDepartmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectDDL({
            ...ProjectDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectTypeDDL({
            ...ProjectTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setWorkAndWorkTypeDDL({
            ...WorkAndWorkTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setWardNameDDL({
            ...wardNameDDL,
            ID: 0,
            Label: "---Select---"
        })
        setElectoralWardNumDDL({
            ...electoralWardNumDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectPriorityDDL({
            ...projectPriorityDDL,
            ID: 0,
            Label: "---Select---"
        })
        setKeyProjectDDL({
            ...keyProjectDDL,
            ID: 0,
            Label: "---Select---"
        })
        setlfmSFileNo('')
        setM_WardWiseNumber([])
        setM_WardWiseNumberID([])
        setSelectedOptions()
    }

    useEffect(() => {
        setM_WardWiseNumber([])
        setM_WardWiseNumberID([])
        setSelectedOptions([])
    }, [wardNameDDL.ID])

    // const Add = () => {
    //     const data = {
    //         M_FinancialYearID: moment().format('YYYY'),
    //         M_MonthID: moment().format('MM'),
    //         handleOnCloseClick: handleOnCloseClick,
    //         setIsPost: setIsPost,
    //         IsPost: IsPost,
    //         handleOnClearClick: handleOnClearClick,
    //         T_ProjectDetailsID: apiFlag === 'Update' ? editData.t_ProjectDetailsID : 0,
    //         M_DepartmentID: departmentDDL.ID,
    //         M_SubDepartment: subDepartmentDDL.ID,
    //         M_ProjectTypeID: ProjectTypeDDL.ID,
    //         M_ProjectID: ProjectDDL.ID,
    //         M_WorkID: WorkAndWorkTypeDDL.ID,
    //         M_ProjectPriorityID: projectPriorityDDL.ID,
    //         M_WardID: wardNameDDL.ID,
    //         M_WardWiseNumberID: apiFlag === 'Update' ? editData.m_WardWiseNumberID : M_WardWiseNumberID,
    //         M_WardWiseNumber: apiFlag === 'Update' ? editData.wardNumber : M_WardWiseNumber,
    //         LFMS_File_No: lfmSFileNo,
    //         Project_ERP_Code: projectERPCode,
    //         Key_Project: keyProjectDDL.ID,
    //         UserID: UserID,
    //         token: token,
    //         Flag: apiFlag,
    //         AssignJE_M_UsersID: assignJEDDL.ID
    //     }

    //     dispatch(ProjectDetailsPostAPI({ data }))
    // }

    const Add = () => {
        const data = {
            M_FinancialYearID: moment().format('YYYY'),
            M_MonthID: moment().format('MM'),
            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost,
            handleOnClearClick: handleOnClearClick,
            T_ProjectDetailsID: apiFlag === 'Update' ? editData.t_ProjectDetailsID : 0,
            M_DepartmentID: departmentDDL.ID,
            M_SubDepartment: subDepartmentDDL.ID,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_ProjectID: projectNameMilestoneDDL.ID,
            M_WorkID: WorkAndWorkTypeDDL.ID,
            M_ProjectPriorityID: projectPriorityDDL.ID,
            M_WardID: wardNameDDL.ID,
            M_WardWiseNumberID: M_WardWiseNumberID,
            M_WardWiseNumber: M_WardWiseNumber,
            LFMS_File_No: lfmSFileNo,
            Project_ERP_Code: projectNameMilestoneDDL.projectCode,
            Key_Project: keyProjectDDL.ID,
            UserID: UserID,
            token: token,
            Flag: apiFlag,
            AssignJE_M_UsersID: assignJEDDL.ID
        }

        dispatch(ProjectDetailsPostAPI({ data }))
    }


    console.log('apiFlag', apiFlag)

    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Project Details</div>
                <div className="modal-body" >
                    <div className="row details-row" style={{ height: '400px', 'overflow-y': 'auto' }}>
                        <div className="col-12 col-md-6 col-lg-6">
                            <DepartmentDDL
                                departmentDDL={departmentDDL}
                                setdepartmentDDL={setdepartmentDDL}
                                DDLData={DepartmentDDLData}
                                apiFlag={apiFlag}
                                editData={editData}
                                mendetary='yes'
                                FlagName='Update'
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <SubDepartmentDDL
                                subDepartmentDDL={subDepartmentDDL}
                                setSubDepartmentDDL={setSubDepartmentDDL}
                                apiFlag={apiFlag}
                                departmentDDLID={departmentDDL.ID}
                                editData={editData}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <ProjectTypeDDLData
                                ProjectTypeDDL={ProjectTypeDDL}
                                setProjectTypeDDL={setProjectTypeDDL}
                                apiFlag={apiFlag}
                                editData={editData}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* <ProjectNameDDL
                                ProjectDDL={ProjectDDL}
                                setProjectDDL={setProjectDDL}
                                apiFlag={apiFlag}
                                editData={editData}
                                ProjectTypeDDL={ProjectTypeDDL}
                                mendetary='yes'
                                DDLFlag='All'
                            /> */}

                            <ProjectNameNewDDL
                                projectNameMilestoneDDL={projectNameMilestoneDDL}
                                setProjectNameMilestoneDDL={setProjectNameMilestoneDDL}
                                projectTypeDDL={ProjectTypeDDL}
                                WorkAndWorkTypeDDL='0'
                                // PopUpField={PopUpField}
                                editData={editData}
                                apiFlag={apiFlag}
                                mendetary='yes'
                                FlagName={apiFlag == 'Update' ? 'ProjectDetails_Update' : 'Project'}

                            // setIsClear={setIsClear}
                            // IsClear={IsClear}
                            />
                        </div>

                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="role">Project ERP Code <Star /> </label>
                                <input
                                    className="form-control"
                                    id="role"
                                    type="text"
                                    name="role"
                                    disabled
                                    value={projectNameMilestoneDDL.projectCode}
                                // onChange={(e) => setProjectERPCode(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <ConcateWorkAndWorkTypeDDL
                                WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                                setWorkAndWorkTypeDDL={setWorkAndWorkTypeDDL}
                                // PopUpField={PopUpField}
                                editData={editData}
                                apiFlag={apiFlag}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <WardNameDDL
                                wardNameDDL={wardNameDDL}
                                setWardNameDDL={setWardNameDDL}
                                DDLData={WardNameDDLData}
                                apiFlag={apiFlag}
                                editData={editData}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            {/* <ElectoralWardNumDDL */}
                            <MultiElectoralNumDDL
                                electoralWardNumDDL={electoralWardNumDDL}
                                setElectoralWardNumDDL={setElectoralWardNumDDL}
                                DDLData={ElectoralWardNumberData}
                                wardNameDDL={wardNameDDL}
                                apiFlag={apiFlag}
                                editData={editData}
                                mendetary='yes'
                                M_WardWiseNumberID={M_WardWiseNumberID}
                                setM_WardWiseNumberID={setM_WardWiseNumberID}
                                setM_WardWiseNumber={setM_WardWiseNumber}
                                M_WardWiseNumber={M_WardWiseNumber}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}

                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="role">LFMS File No <Star /> </label>
                                <input
                                    className="form-control"
                                    id="lfmSFileNo"
                                    type="text"
                                    name="lfmSFileNo"
                                    value={lfmSFileNo}
                                    // max={empolyeeTextField.joiningDate}
                                    onChange={(e) => setlfmSFileNo(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <ProjectPriorityDDL
                                projectPriorityDDL={projectPriorityDDL}
                                setProjectPriorityDDL={setProjectPriorityDDL}
                                apiFlag={apiFlag}
                                editData={editData}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <KeyProjectDDL
                                keyProjectDDL={keyProjectDDL}
                                setKeyProjectDDL={setKeyProjectDDL}
                                apiFlag={apiFlag}
                                editData={editData}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <AssignJEDDL
                                assignJEDDL={assignJEDDL}
                                setAssignJEDDL={setAssignJEDDL}
                                departmentDDL={departmentDDL}
                                wardNameDDL={wardNameDDL}
                                apiFlag={apiFlag}
                                editData={editData}
                            />
                        </div>

                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={apiFlag === 'Update' ? handleOnCloseClick : handleClearDDL}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />{apiFlag === 'Update' ? 'Cancel' : 'Clear'}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        departmentDDL.ID == 0 ||
                                        subDepartmentDDL.ID == 0 ||
                                        ProjectTypeDDL.ID == 0 ||
                                        projectNameMilestoneDDL.ID == 0 ||
                                        // ProjectDDL.ID === 0 ||
                                        WorkAndWorkTypeDDL.ID == 0 ||
                                        wardNameDDL.ID == 0 ||
                                        // electoralWardNumDDL.ID == 0 ||
                                        lfmSFileNo == '' ||
                                        M_WardWiseNumberID.length == 0 ||
                                        projectPriorityDDL.ID == 0
                                    }
                                >{popUpFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popUpFlag}</button>
                            </div>
                        </div>

                    </div>
                </div>


            </Popup>
        </div>
    )
}






// import React, { useState, useEffect } from "react";
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
// import { Edit, Edit2, Flag, Plus, Trash, X } from "react-feather";
// import { useAuthState } from "../../../Helper/Context/context";
// import { Star } from "../../../Components/CommoComponent/Star";
// import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
// import SubDepartmentDDL from "../../../CommonDDL/SubDepartmentDDL/SubDepartmentDDL";
// import ProjectNameDDL from "../../../CommonDDL/ProjectNameDDL/ProjectNameDDL";
// import WardNameDDL from "../../../CommonDDL/WardNameDDL/WardNameDDL";
// import ProjectPriorityDDL from "../../../CommonDDL/ProjectPriorityDDL/ProjectPriorityDDL";
// import KeyProjectDDL from "../../../CommonDDL/KeyProjectDDL/KeyProjectDDL";
// import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
// import { DepartmentNameDDLAPI, ElectoralWardNumberDDLAPI, SubDepartmentDDLAPI, WardNameDDLAPI } from "../../../Redux/DDLSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { ProjectDetailsPostAPI } from "../../../Redux/ProjectCreationAllocationSlice/ProjectDetailsSlice";
// import { ConcateWorkAndWorkTypeDDL } from "../../../CommonDDL/ConcateWorkAndWorkTypeDDL";
// import AssignJEDDL from "../../../CommonDDL/AssignJEDDL/AssignJEDDL";
// import MultiElectoralNumDDL from "../../../CommonDDL/ElectoralWardNumDDL/MultiElectoralNumDDL";
// import moment from 'moment';

// export default function ProjectDetailsPopUp(props) {

//     const userDetails = useAuthState();
//     const { UserID, token } = userDetails

//     const { open, allState, handleOnCloseClick, IsPost, setIsPost, handleOnClearClick, PopUpField } = props
//     const { popUpFlag, apiFlag, editData } = allState

//     const dispatch = useDispatch()

//     // console.log(popUpFlag, apiFlag);

//     const [projectERPCode, setProjectERPCode] = useState(apiFlag === 'Update' ? editData.project_ERP_Code : '')
//     const [lfmSFileNo, setlfmSFileNo] = useState(apiFlag === 'Update' ? editData.lfmS_File_No : '')

//     // const [M_WardWiseNumberID, setM_WardWiseNumberID] = useState({ value: apiFlag === 'Update' ? editData.m_WardWiseNumberID : 0 });
//     // const [M_WardWiseNumber, setM_WardWiseNumber] = useState({ label: apiFlag === 'Update' ? editData.wardNumber : "---Select---" });
//     // const [selectedOptions, setSelectedOptions] = useState({
//     //     value: apiFlag === 'Update' ? editData.m_WardWiseNumberID : 0,
//     //     label: apiFlag === 'Update' ? editData.wardNumber : "---Select---"
//     // });

//     const [M_WardWiseNumberID, setM_WardWiseNumberID] = useState();
//     const [M_WardWiseNumber, setM_WardWiseNumber] = useState();
//     // const [selectedOptions, setSelectedOptions] = useState(apiFlag === 'Update' ? editData.m_WardWiseNumberID.map((id, index) => ({
//     //     value: id,
//     //     label: editData.wardNumber[index]
//     // })) : []);

//     const [selectedOptions, setSelectedOptions] = useState(() => {
//         if (apiFlag === 'Update' && Array.isArray(editData.m_WardWiseNumberID)) {
//             return editData.m_WardWiseNumberID.map((id, index) => ({
//                 value: id,
//                 label: editData.wardNumber[index], // Assuming `wardNumber` is an array
//             }));
//         } else if (apiFlag === 'Update') {
//             return [{ value: editData.m_WardWiseNumberID, label: editData.wardNumber }]; // If it's a single value
//         } else {
//             return []; // Default empty for non-update scenarios
//         }
//     });

//     const [departmentDDL, setdepartmentDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.M_DepartmentID : 0,
//         Label: apiFlag === 'Update' ? editData.departmentName : "---Select---"
//     })

//     const [subDepartmentDDL, setSubDepartmentDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.m_SubDepartmentID : 0,
//         Label: apiFlag === 'Update' ? editData.subDepartmentName : "---Select---"
//     })
//     const [ProjectTypeDDL, setProjectTypeDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.m_ProjectTypeID : 0,
//         Label: apiFlag === 'Update' ? editData.projectName : "---Select---",

//     })
//     const [WorkTypeDDL, setWorkTypeDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.m_WorkTypeID : 0,
//         Label: apiFlag === 'Update' ? editData.workTypeName : "---Select---"
//     })
//     const [wardNameDDL, setWardNameDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.m_WardID : 0,
//         Label: apiFlag === 'Update' ? editData.wardName : "---Select---"
//     })
//     const [projectPriorityDDL, setProjectPriorityDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.m_ProjectPriorityID : 0,
//         Label: apiFlag === 'Update' ? editData.projectPriorityName : "---Select---"
//     })
//     const [ProjectDDL, setProjectDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.m_ProjectID : 0,
//         Label: apiFlag === 'Update' ? editData.projectName : "---Select---",
//         projectCode: apiFlag === 'Update' ? editData.project_ERP_Code : "",
//         numberOfMileStone: "",
//     })
//     // console.log(editData)

//     const [keyProjectDDL, setKeyProjectDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.key_ProjectM_IndicatorID : 0,
//         Label: apiFlag === 'Update' ? editData.key_ProjectIndicatorName : "---Select---"
//     })

//     const [electoralWardNumDDL, setElectoralWardNumDDL] = useState({
//         DDL: [],
//         // ID: [1, 4],
//         // Label: [6, 10]
//         ID: apiFlag === 'Update' ? editData.m_WardWiseNumberID : 0,
//         Label: apiFlag === 'Update' ? editData.wardNumber : "---Select---"
//     })
//     console.log('electoralWardNumDDL', electoralWardNumDDL.ID)
//     const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.m_WorkTypeID : 0,
//         Label: apiFlag === 'Update' ? editData.workAndWorkType : "---Select---"
//     })

//     const [assignJEDDL, setAssignJEDDL] = useState({
//         DDL: [],
//         ID: apiFlag === 'Update' ? editData.assignJE_M_UsersID : 0,
//         Label: apiFlag === 'Update' ? editData.employeeName : "---Select---"
//     })

//     // console.log(editData)

//     useEffect(() => {
//         const data = { UserID, token }
//         dispatch(DepartmentNameDDLAPI({ data }))
//     }, [IsPost])

//     const { DepartmentDDLData } = useSelector(state => state.DepartmentNameData)

//     useEffect(() => {
//         const data = {
//             UserID,
//             token,
//             M_WardWiseNumberID: 0,
//             M_WardID: wardNameDDL.ID
//         }
//         dispatch(ElectoralWardNumberDDLAPI({ data }))
//     }, [wardNameDDL.ID])

//     const { ElectoralWardNumberData } = useSelector(state => state.ElectoralWardNumberDDLData)

//     // useEffect(() => {
//     //     if(wardNameDDL.ID != 0) {
//     //         setElectoralWardNumDDL({
//     //             ...electoralWardNumDDL,
//     //             ID:0,
//     //             Label:'Select...'
//     //         })
//     //     }

//     // }, [wardNameDDL.ID])

//     // console.log(editData)

//     useEffect(() => {
//         const data = { UserID, token, }
//         dispatch(WardNameDDLAPI({ data }))
//     }, [])

//     const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

//     const handleClearDDL = () => {
//         setdepartmentDDL({
//             ...departmentDDL,
//             ID: 0,
//             Label: "---Select---"
//         })
//         setSubDepartmentDDL({
//             ...subDepartmentDDL,
//             ID: 0,
//             Label: "---Select---"
//         })
//         setProjectDDL({
//             ...ProjectDDL,
//             ID: 0,
//             Label: "---Select---"
//         })
//         setProjectTypeDDL({
//             ...ProjectTypeDDL,
//             ID: 0,
//             Label: "---Select---"
//         })
//         setWorkAndWorkTypeDDL({
//             ...WorkAndWorkTypeDDL,
//             ID: 0,
//             Label: "---Select---"
//         })
//         setWardNameDDL({
//             ...wardNameDDL,
//             ID: 0,
//             Label: "---Select---"
//         })
//         setElectoralWardNumDDL({
//             ...electoralWardNumDDL,
//             ID: 0,
//             Label: "---Select---"
//         })
//         setProjectPriorityDDL({
//             ...projectPriorityDDL,
//             ID: 0,
//             Label: "---Select---"
//         })
//         setKeyProjectDDL({
//             ...keyProjectDDL,
//             ID: 0,
//             Label: "---Select---"
//         })
//         setlfmSFileNo('')
//         // setM_WardWiseNumber([])
//         // setM_WardWiseNumberID([])
//         // setSelectedOptions()
//     }

//     useEffect(() => {
//         // setM_WardWiseNumber([])
//         // setM_WardWiseNumberID([])
//         // setSelectedOptions([])
//     }, [wardNameDDL.ID])

//     const Add = () => {
//         const data = {
//             M_FinancialYearID: moment().format('YYYY'),
//             M_MonthID: moment().format('MM'),
//             handleOnCloseClick: handleOnCloseClick,
//             setIsPost: setIsPost,
//             IsPost: IsPost,
//             handleOnClearClick: handleOnClearClick,
//             T_ProjectDetailsID: apiFlag === 'Update' ? editData.t_ProjectDetailsID : 0,
//             M_DepartmentID: departmentDDL.ID,
//             M_SubDepartment: subDepartmentDDL.ID,
//             M_ProjectTypeID: ProjectTypeDDL.ID,
//             M_ProjectID: ProjectDDL.ID,
//             M_WorkID: WorkAndWorkTypeDDL.ID,
//             M_ProjectPriorityID: projectPriorityDDL.ID,
//             M_WardID: wardNameDDL.ID,
//             M_WardWiseNumberID: M_WardWiseNumberID,
//             M_WardWiseNumber: M_WardWiseNumber,
//             LFMS_File_No: lfmSFileNo,
//             Project_ERP_Code: projectERPCode,
//             Key_Project: keyProjectDDL.ID,
//             UserID: UserID,
//             token: token,
//             Flag: apiFlag,
//             AssignJE_M_UsersID: assignJEDDL.ID
//         }

//         dispatch(ProjectDetailsPostAPI({ data }))
//     }


//     return (

//         <div>
//             <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
//                 <span className="close" onClick={handleOnCloseClick}>
//                     &times;
//                 </span>
//                 <div className="call"> Project Details</div>
//                 <div className="modal-body" >
//                     <div className="row details-row" style={{ height: '400px', 'overflow-y': 'auto' }}>
//                         <div className="col-12 col-md-6 col-lg-6">
//                             <DepartmentDDL
//                                 departmentDDL={departmentDDL}
//                                 setdepartmentDDL={setdepartmentDDL}
//                                 DDLData={DepartmentDDLData}
//                                 apiFlag={apiFlag}
//                                 editData={editData}
//                                 mendetary='yes'
//                                 FlagName='Update'
//                             />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-6">
//                             <SubDepartmentDDL
//                                 subDepartmentDDL={subDepartmentDDL}
//                                 setSubDepartmentDDL={setSubDepartmentDDL}
//                                 apiFlag={apiFlag}
//                                 departmentDDLID={departmentDDL.ID}
//                                 editData={editData}
//                                 mendetary='yes'
//                             />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-6">
//                             <ProjectTypeDDLData
//                                 ProjectTypeDDL={ProjectTypeDDL}
//                                 setProjectTypeDDL={setProjectTypeDDL}
//                                 apiFlag={apiFlag}
//                                 editData={editData}
//                                 mendetary='yes'
//                             />
//                         </div>

//                         <div className="col-12 col-lg-6">
//                             <ProjectNameDDL
//                                 ProjectDDL={ProjectDDL}
//                                 setProjectDDL={setProjectDDL}
//                                 apiFlag={apiFlag}
//                                 editData={editData}
//                                 ProjectTypeDDL={ProjectTypeDDL}
//                                 mendetary='yes'
//                             />
//                         </div>

//                         <div className="col-12 col-lg-6">
//                             <div className="form-group">
//                                 <label className="d-block" for="role">Project ERP Code <Star /> </label>
//                                 <input
//                                     className="form-control"
//                                     id="role"
//                                     type="text"
//                                     name="role"
//                                     disabled
//                                     value={ProjectDDL.projectCode}
//                                     onChange={(e) => setProjectERPCode(e.target.value)}
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-6">
//                             <ConcateWorkAndWorkTypeDDL
//                                 WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
//                                 setWorkAndWorkTypeDDL={setWorkAndWorkTypeDDL}
//                                 editData={editData}
//                                 apiFlag={apiFlag}
//                                 mendetary='yes'
//                             />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-6">
//                             <WardNameDDL
//                                 wardNameDDL={wardNameDDL}
//                                 setWardNameDDL={setWardNameDDL}
//                                 DDLData={WardNameDDLData}
//                                 apiFlag={apiFlag}
//                                 editData={editData}
//                                 mendetary='yes'
//                             />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-6">
//                             {/* <ElectoralWardNumDDL */}
//                             <MultiElectoralNumDDL
//                                 electoralWardNumDDL={electoralWardNumDDL}
//                                 setElectoralWardNumDDL={setElectoralWardNumDDL}
//                                 DDLData={ElectoralWardNumberData}
//                                 wardNameDDL={wardNameDDL}
//                                 apiFlag={apiFlag}
//                                 PopUpField={PopUpField}
//                                 editData={editData}
//                                 mendetary='yes'
//                                 setM_WardWiseNumberID={setM_WardWiseNumberID}
//                                 setM_WardWiseNumber={setM_WardWiseNumber}
//                                 M_WardWiseNumber={M_WardWiseNumber}
//                                 selectedOptions={selectedOptions}
//                                 setSelectedOptions={setSelectedOptions}
//                             />
//                         </div>
//                         <div className="col-12 col-lg-6">
//                             <div className="form-group">
//                                 <label className="d-block" for="role">LFMS File No <Star /> </label>
//                                 <input
//                                     className="form-control"
//                                     id="role"
//                                     type="text"
//                                     name="role"
//                                     value={lfmSFileNo}
//                                     // max={empolyeeTextField.joiningDate}
//                                     onChange={(e) => setlfmSFileNo(e.target.value)}
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-6">
//                             <ProjectPriorityDDL
//                                 projectPriorityDDL={projectPriorityDDL}
//                                 setProjectPriorityDDL={setProjectPriorityDDL}
//                                 apiFlag={apiFlag}
//                                 editData={editData}
//                                 mendetary='yes'
//                             />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-6">
//                             <KeyProjectDDL
//                                 keyProjectDDL={keyProjectDDL}
//                                 setKeyProjectDDL={setKeyProjectDDL}
//                                 apiFlag={apiFlag}
//                                 editData={editData}
//                             />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-6">
//                             <AssignJEDDL
//                                 assignJEDDL={assignJEDDL}
//                                 setAssignJEDDL={setAssignJEDDL}
//                                 departmentDDL={departmentDDL}
//                                 wardNameDDL={wardNameDDL}
//                                 apiFlag={apiFlag}
//                                 editData={editData}
//                             />
//                         </div>

//                         <div className="col-12 col-lg-12">
//                             <div className="btn-action">
//                                 <button type="button" className="btn btn-md btn-clear mx-2 float-right"
//                                     onClick={apiFlag === 'Update' ? handleOnCloseClick : handleClearDDL}
//                                 >
//                                     <X size={18} style={{ marginRight: "3px" }} />{apiFlag === 'Update' ? 'Cancel' : 'Clear'}</button>
//                                 <button
//                                     type="button"
//                                     className="btn btn-md btn-primary mx-2 float-right"
//                                     onClick={() => Add()}
//                                     disabled={
//                                         departmentDDL.ID == 0 ||
//                                         subDepartmentDDL.ID == 0 ||
//                                         ProjectTypeDDL.ID == 0 ||
//                                         ProjectDDL.ID == 0 ||
//                                         WorkAndWorkTypeDDL.ID == 0 ||
//                                         wardNameDDL.ID == 0 ||
//                                         // electoralWardNumDDL.ID == 0 ||
//                                         // selectedOptions.length == 0 ||
//                                         // M_WardWiseNumber == 0 ||
//                                         lfmSFileNo == '' ||
//                                         projectPriorityDDL.ID == 0
//                                     }
//                                 >{popUpFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popUpFlag}</button>
//                             </div>
//                         </div>

//                     </div>
//                 </div>


//             </Popup>
//         </div>
//     )
// }


