import React, { useState, useEffect } from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_M_ProjectType } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Project_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import { useDispatch } from "react-redux";
import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import { ProjectPostAPI } from "../../../Redux/MasterSlice/ProjectSlice";

export default function ProjectPopUp({ open, handleAddCloseClick, PopUpField, handlePost }) {

    const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()

    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_ProjectTypeID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.projectTypeName
    })

    const [projectTextField, setprojectTextField] = useState(
        {
            projectName: apiFlag === "Insert" ? '' : rowData?.projectName,
            projectCode: apiFlag === "Insert" ? '' : rowData?.projectCode,
        }
    )
    const handleInputChange = (e) => {
        setprojectTextField({ ...projectTextField, [e.target.name]: e.target.value })
    }

    //  ------------------------Validation ----------------

    const [formErrors, setformErrors] = useState({
        projectNameError: "",
        projectCodeError: ""

    })
    const checkTextOnlyFields = (text, errorField, errorValue) => {
        let regex = new RegExp(/^[a-zA-Z ]*$/)
        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, [errorField]: "" })
        } else {
            setformErrors({ ...formErrors, [errorField]: errorValue })

        }
    }

    const checkAlphaNumericFields = (text, errorField, errorValue) => {
        // let regex = new RegExp(/^[0-9a-zA-Z(\-)]+$/)
        let regex = new RegExp(/^[ A-Za-z0-9_@.#&+-]*$/)
        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, [errorField]: "" })
        } else {
            setformErrors({ ...formErrors, [errorField]: errorValue })

        }
    }

    const handleClear = () => {
        setProjectTypeDDL({
            ...ProjectTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setprojectTextField(
            {
                projectName: "",
                projectCode: "",
            }
        )
    }

    const handleAddUpdate = () => {
        const addData = {
            M_ProjectID: apiFlag === 'Insert' ? '0' : rowData?.m_ProjectID,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            ProjectName: projectTextField?.projectName,
            ProjectCode: projectTextField?.projectCode,
            UserID: UserID,
            token: token,
            Flag: apiFlag,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick
        }
        dispatch(ProjectPostAPI({ addData }))
    }

    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>
                <span className="close" onClick={handleAddCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Project Master</div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <ProjectTypeDDLData
                                ProjectTypeDDL={ProjectTypeDDL}
                                setProjectTypeDDL={setProjectTypeDDL}
                                PopUpField={PopUpField}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="projectName">Project Name <Star /></label>
                                <input
                                    className="form-control"
                                    id="projectName"
                                    type="text"
                                    name="projectName"
                                    value={projectTextField.projectName}
                                    onChange={(e) => handleInputChange(e)}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.projectNameError}</span> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="projectCode">Project Code <Star /></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="projectCode"
                                    name="projectCode"
                                    value={projectTextField.projectCode}

                                    onChange={(e) => {
                                        handleInputChange(e)
                                        // checkAlphaNumericFields(e.target.value, "projectCodeError", "Invalid project code")
                                    }}
                                />
                                {/* <span className="text-danger error-danger">{formErrors.projectCodeError}</span> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={() => { popupBtn === 'Clear' ? handleClear() : handleAddCloseClick() }}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />{popupBtn}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    // onClick={() => console.log(projectTextField, ProjectTypeDDL)}
                                    onClick={() => handleAddUpdate()}
                                    disabled={
                                        ProjectTypeDDL.ID === 0 ||
                                        projectTextField.projectName === "" ||
                                        projectTextField.projectCode === "" ||
                                        formErrors.projectNameError === "Invalid Project Name"
                                    }
                                >
                                    {apiFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popupFlag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>
    )
}