import React, { useEffect, useState } from "react";
import { BookOpen, CheckSquare, Circle, Edit, FilePlus, Home, List, PieChart, Users } from "react-feather";
import { Link } from "react-router-dom";
import { useAuthState } from "../../Helper/Context/context";
import { useDispatch, useSelector } from "react-redux";
import Role from "../../Pages/Master/Role/Role";
import { RoleWiseFormTableDataAPI } from "../../Redux/UserMasterSlice/RoleWiseFormSlice";
import LoaderFile from "../../Helper/Loader/LoaderFile";
import { LoadingOverlay } from "react-overlay-loader";

export default function Sidebar(props) {

    const { isopen, active, subMenu, ISPost } = props

    //    console.log(tableData)
    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails

    const dispatch = useDispatch();

    const [tableData, settableData] = useState([])

    // console.log(RoleID)
    useEffect(() => {
        const data = {
            M_RoleID: RoleID,
            UserID: UserID,
            settableData: settableData
        }
        dispatch(RoleWiseFormTableDataAPI({ data }))
    }, [ISPost])

    const { isLoading } = useSelector(state => state.RoleWiseFormTableData)

    return (
        <div>

            <nav className={!isopen ? "sidebar sidebar-offcanvas pt-3 sidebar-hide" : "sidebar sidebar-offcanvas "} style={{ height: isopen ? "calc(100vh - 74px)" : "calc(100vh - 74px)" }} id="sidebar">

                <ul className="nav">

                    <li className={`nav-item ${active === "dashboard" ? "active" : ""}`}>
                        {/* for the curve shave active class  */}
                        <b></b><b></b>
                        <Link to="/dashboard" className="nav-link" ><Home />
                            <span className={!isopen ? "menu-title-none" : "menu-title"}>
                                Dashboard
                            </span>
                        </Link>
                    </li>

                    <li className={`nav-item ${subMenu === "subMaster" ? "active" : ""}`}>
                        <b></b><b></b>
                        <span className="nav-link" data-toggle="collapse" data-target="#Masters" aria-controls="Masters">
                            <List />
                            <span className={!isopen ? "menu-title-none dropdown-toggle" : "menu-title dropdown-toggle"}>
                                Masters
                            </span>
                        </span>
                    </li>
                    {/* =========== master submenu ============= */}
                    {/* {(isLoading)   ?
                        <LoadingOverlay />
                        : */}
                    <div className={`${subMenu === "subMaster" ? "collapse show" : "collapse"}`} id="Masters">
                        {/* style={{ padding: "7px", overflowY: "scroll", height: "292px" }} */}
                        <ul className="nav sub-menu" style={{ display: !isopen ? "none" : "", padding: "7px", overflowY: "scroll", height: "280px" }}>
                            {
                                tableData && tableData.table && tableData.table.length > 0 && tableData.table.map((item, index) => (
                                    // item.pageName !== 'userCreation' || item.pageName !== 'defineRole' ?
                                    // console.log(item.pageName)
                                    item.isChecked == 1 ?
                                        <li className={`nav-item ${active === item.pageName ? "active" : ""}`}>
                                            <Link to={"/" + item.pageName} className="nav-link background-color" >
                                                <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                                <span>{item.formName}</span>
                                            </Link>
                                        </li>
                                        : <></>
                                ))
                            }

                        </ul>
                    </div>

                    <li className={`nav-item ${subMenu === "userMaster" ? "active" : ""}`}>
                        <b></b><b></b>
                        <span className="nav-link" data-toggle="collapse" href="#userMaster" aria-expanded="false" aria-controls="userMaster">
                            <Users />
                            <span className={!isopen ? "menu-title-none dropdown-toggle" : "menu-title dropdown-toggle"}>
                                User Master
                            </span>
                        </span>
                    </li>
                    {/* =========== Project Creation and Allocation ============= */}

                    <div className={`${subMenu === "userMaster" ? "collapse show" : "collapse"}`} id="userMaster">

                        <ul className="nav sub-menu" style={{ display: !isopen ? "none" : "" }}>

                            <li className={`nav-item ${active === "defineRole" ? "active" : ""}`}>
                                <Link to='/defineRole' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>Define Role</span>
                                </Link>
                            </li>

                            <li className={`nav-item ${active === "userCreation" ? "active" : ""}`}>
                                <Link to='/userCreation' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span> User Creation</span>
                                </Link>
                            </li>

                            <li className={`nav-item ${active === "roleWiseForm" ? "active" : ""}`}>
                                <Link to='/roleWiseForm' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>Role Wise Forms</span>
                                </Link>
                            </li>
                            <li className={`nav-item ${active === "userWiseWardMapping" ? "active" : ""}`}>
                                <Link to='/userWiseWardMapping' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>User Wise Ward Mapping</span>
                                </Link>
                            </li>

                        </ul>
                    </div>

                    {/* <li className={`nav-item ${subMenu === "subBudget" ? "active" : ""}`}>
                        <b></b><b></b>
                        <span className="nav-link" data-toggle="collapse" href="#allowcreatebudget" aria-expanded="false" aria-controls="createproject">
                            <BookOpen />
                            <span className={isopen ? "menu-title-none dropdown-toggle" : "menu-title dropdown-toggle"}>
                                Budget <br /> Management
                            </span>
                        </span>
                    </li> */}
                    {/* =========== Budget Management Submenu ============= */}

                    <div className={`${subMenu === "subBudget" ? "collapse show" : "collapse"}`} id="allowcreatebudget">

                        <ul className="nav sub-menu" style={{ display: !isopen ? "none" : "" }}>

                            <li className={`nav-item ${active === "budgetcreation" ? "active" : ""}`}>
                                <Link to='/budgetcreation' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes "><Circle size={7} /></span>
                                    <span>Budget Creation</span>
                                </Link>
                            </li>

                            <li className={`nav-item ${active === "allocatebudget" ? "active" : ""}`}>
                                <Link to='/allocatebudget' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>Allocate Budget</span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <li className={`nav-item ${subMenu === "subProCreation" ? "active" : ""}`}>
                        <b></b><b></b>
                        <span className="nav-link" data-toggle="collapse" href="#createproject" aria-expanded="false" aria-controls="createproject">
                            <FilePlus />
                            <span className={!isopen ? "menu-title-none dropdown-toggle" : "menu-title dropdown-toggle"}>
                                Project Creation <br /> and Allocation
                            </span>
                        </span>
                    </li>
                    {/* =========== Project Creation and Allocation ============= */}

                    <div className={`${subMenu === "subProCreation" ? "collapse show" : "collapse"}`} id="createproject">

                        <ul className="nav sub-menu" style={{ display: !isopen ? "none" : "" }}>

                            <li className={`nav-item ${active === "projectDetails" ? "active" : ""}`}>
                                <Link to='/projectDetails' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>Project Details</span>
                                </Link>
                            </li>

                            {/* <li className={`nav-item ${active === "workdefine" ? "active" : ""}`}>
                                <Link to='/projectwisework' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span> Define Project Wise Work</span>
                                </Link>
                            </li> */}

                            <li className={`nav-item ${active === "workwisemilistone" ? "active" : ""}`}>
                                <Link to='/workwisemilistone' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>Project Wise Milestones</span>
                                </Link>
                            </li>

                            {/* <li className={`nav-item ${active === "createbudget" ? "active" : ""}`}>
                                <Link to='/createbudget' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>Project Wise Budget Creation</span>
                                </Link>
                            </li> */}

                            <li className={`nav-item ${active === "contractorwork" ? "active" : ""}`}>
                                <Link to='/contractorwork' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>Project Sanction Details</span>
                                </Link>
                            </li>

                            <li className={`nav-item ${active === "physicalfinancialworkprogress" ? "active" : ""}`}>
                                <Link to='/physicalfinancialworkprogress' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>Physical Work Progress</span>
                                </Link>
                            </li>

                            <li className={`nav-item ${active === "financialworkprogress" ? "active" : ""}`}>
                                <Link to='/financialworkprogress' className="nav-link background-color" >
                                    <span className="sub_bullet white_bulltes"><Circle size={7} /></span>
                                    <span>Financial Work Progress</span>
                                </Link>
                            </li>

                        </ul>
                    </div>

                    {/* <li className={`nav-item ${active === "roleWiseForm" ? "active" : ""}`}>
                        <b></b><b></b>
                        <Link to="/rolewiseform" className="nav-link" ><Home />
                            <span className={isopen ? "menu-title-none" : "menu-title"}>Role Wise Form</span>
                        </Link>
                    </li> */}


                    {/* <li className={`nav-item ${active === "updateMaster" ? "active" : ""}`}>
                        <b></b><b></b>
                        <Link to="/updateMaster" className="nav-link" ><Edit />
                            <span className={!isopen ? "menu-title-none" : "menu-title"}>Update</span>
                        </Link>
                    </li> */}

                    {/* <li className={`nav-item ${active === "trackProject" ? "active" : ""}`}>
                        <b></b><b></b>
                        <Link to="/trackProject" className="nav-link" ><CheckSquare />
                            <span className={!isopen ? "menu-title-none" : "menu-title"}>Track Project</span>
                        </Link>
                    </li> */}

                    <li className={`nav-item ${active === "ReportScreen" ? "active" : ""}`}>
                        <b></b><b></b>
                        <Link to="/ReportScreen" className="nav-link" >
                            <PieChart />
                            <span className={!isopen ? "menu-title-none" : "menu-title"}>Reports</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

