import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
    useLocation,
    Navigate,
} from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import Budget from "./Pages/Master/Budget/Budget";
import Department from "./Pages/Master/Department/Department";
import Project from "./Pages/Master/Project/Project";
import Contractor from "./Pages/Master/Contractor/Contractor";
import Role from "./Pages/Master/Role/Role";
import EmployeeDesignation from "./Pages/Master/EmployeeDesignation/EmployeeDesignation";
import Employee from "./Pages/Master/Employee/Employee";
import Work from "./Pages/Master/Work/Work";
import User from "./Pages/Master/User/User";

import PhysicalSpillOverTable from "./Pages/DashboardCard/PhysicalSpillOver/PhysicalSpillOverTable";
import Login from "./Pages/Login/Login";
import UserRoles from "./Pages/UserRoles/UserRoles";
import FinancialSpillOverTablesPage from "./Pages/DashboardCard/FinancialPendingSection/FinancialSpillOver/FinancialSpillOverTablesPage";
import PendingUtilizationTablePage from "./Pages/DashboardCard/FinancialPendingSection/PendingUtilization/PendingUtilizationTablePage";
import Progress from "./Pages/DashboardCard/Progress/Progress";
import PhysicalSpillOverDepartmentPage from "./Pages/DashboardCard/PhysicalSpillOver/PhysicalSpillOverDepartmentPage/PhysicalSpillOverDepartmentPage";
import FinancialSpillOverElectricalDeptartment from "./Pages/DashboardCard/FinancialPendingSection/FinancialSpillOver/FinancialSpillOverElectricalDeptartment";
import PendingUtilizationElectricalDepartment from "./Pages/DashboardCard/FinancialPendingSection/PendingUtilization/PendingUtilizationElectricalDepartment";
import AllTotalProjectTable from "./Pages/DashboardCard/Project/TotalProjectTable/AllTotalProjectTable";
import OngoingTotalProject from "./Pages/DashboardCard/Project/TotalProjectTable/OngoingTotalProject";
import CompletedTotalProjectTable from "./Pages/DashboardCard/Project/TotalProjectTable/CompletedTotalProjectTable";
import OngoingTotalProjectDepartment from "./Pages/DashboardCard/Project/TotalProjectTable/OngoingTotalProjectDepartment";
import TotalBudget from "./Pages/Master/Budget/TotalBudget";
import AllTotalProjectDepartment from "./Pages/DashboardCard/Project/TotalProjectTable/AllTotalProjectDepartment";
import CompletedPublicWorkDepartment from "./Pages/DashboardCard/Project/TotalProjectTable/CompletedPublicWorkDepartment";
import TotalProjectTable from "./Pages/DashboardCard/TotalProject/TotalProjectTable";
import ProjectType from "./Pages/Master/ProjectType/ProjectType";
import BudgetType from "./Pages/Master/BudgetType/BudgetType";
import WorkType from "./Pages/Master/WorkType/WorkType";
import ProjectPriority from "./Pages/Master/ProjectPriority/ProjectPriority";
import PhysicalFinancialWorkProgress from "./Pages/CreateProject/PhysicalFinancialWorkProgress/PhysicalFinancialWorkProgress";
import WorkWiseMiliStone from "./Pages/CreateProject/WorkWiseMilestones/WorkWiseMiliStone";
import CreateBudget from "./Pages/CreateProject/CreateBudget/CreateBudget";
import RoleWiseForm from "./Pages/RoleWiseForm/RoleWiseForm";
import AllocateBudget from "./Pages/Budget Management/AllocateBudget/AllocateBudget";
import BudgetCreation from "./Pages/Budget Management/BudgetCreation/BudgetCreation";
import FinancialWorkProgress from "./Pages/CreateProject/FinancialWorkProgress/FinancialWorkProgress";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./Components/ErrorBoundary/ErrorBoundary";
import NotFound from "./Helper/404page/NotFound";
import ContractorWork from "./Pages/CreateProject/ContractorWork/ContractorWork";
import { useAuthState } from "./Helper/Context/context";
import ProjectWiseWork from "./Pages/CreateProject/ProjectWiseWork/ProjectWiseWork";
import DepartmentWiseProject from "./Pages/CreateProject/DepartmentWiseProject/DepartmentWiseProject";
import SubDepartment from "./Pages/Master/SubDepartment/SubDepartment";
import ZoneMaster from './Pages/Master/ZoneMaster/ZoneMaster'
import WardMaster from "./Pages/Master/WardMaster/WardMaster";
import ElectrolWardNumber from "./Pages/Master/ElectrolWardNumber/ElectrolWardNumber";
import ManPowerType from "./Pages/Master/ManPowerType/ManPowerType";
import MuncipleCouncilor from "./Pages/Master/MuncipleCouncilor/MuncipleCouncilor";
import ProjectRemark from "./Pages/Master/ProjectRemark/ProjectRemark";
import DefineRole from "./Pages/UserMaster/DefineRole/DefineRole";
import UserCreation from "./Pages/UserMaster/UserCreation/UserCreation";
import ProjectDetails from "./Pages/CreateProject/ProjectDetails/ProjectDetails";
import UpdateMaster from "./Pages/UpdateMaster/UpdateMaster";
import UserWiseWardMapping from "./Pages/UserMaster/UserWiseWardMapping/UserWiseWardMapping";
import TrackProject from "./Pages/TrackProject/TrackProject";
import TotalDepartments from "./Pages/DashboardCard/DashboardDetails/TotalDepartment/TotalDepartments";
import TotalProjects from "./Pages/DashboardCard/DashboardDetails/TotalProject/TotalProjects";
import DashboardProjectDetails from "./Pages/DashboardCard/Project/ProjectDetails/DashboardProjectDetails";
import WardWiseProjectDetails from "./Pages/DashboardCard/PhysicalSpillOver/WardWiseProjectDetails/WardWiseProjectDetails";
import DelayedWork from "./Pages/DashboardCard/FinancialPendingSection/DelayedWork/DelayedWork";
import BudgetDetails from "./Pages/DashboardCard/FinancialPendingSection/PendingUtilization/BudgetDetails/BudgetDetails";
import ProjectOverviewAll from "./Pages/DashboardCard/ProjectOverview/ProjectOverviewAll/ProjectOverviewAll";
import CommonWardWiseProjectDetailsScreen from "./Pages/DashboardCard/WardWiseProjectDetailsCardScreen/CommonWardWiseProjectDetailsCardScreen";
import ReportScreen from "./Pages/CreateProject/Report/ReportScreen";

function RequireAuth() {
    const userDetails = useAuthState();
    const { RoleID, UserID } = userDetails

    console.log('userDetails', userDetails)

    let auth = RoleID;

    let location = useLocation();
    if (!auth) {
        return <Navigate to="/" state={{ from: location }} />;
    }
    return <Outlet />;
}

export default function AllRoutes() {

    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Router >
                    <Routes>
                        <Route exact path="/" element={<Login />} />
                        <Route exact element={<RequireAuth />}>
                            <Route exact path="/dashboard" element={<Dashboard />} />

                            <Route exact path="/userroles" element={<UserRoles />} />

                            {/* ------------ masters ul list ------------------- */}
                            <Route exact path="/department" element={<Department />} />
                            <Route exact path="/projecttype" element={<ProjectType />} />
                            <Route exact path="/project" element={<Project />} />
                            <Route exact path="/projectpriority" element={<ProjectPriority />} />
                            <Route exact path="/budgettype" element={<BudgetType />} />
                            <Route exact path="/budget" element={<Budget />} />
                            <Route exact path="/totalbudget" element={<TotalBudget />} />
                            <Route exact path="/worktype" element={<WorkType />} />
                            <Route exact path="/work" element={<Work />} />
                            <Route exact path="/contractor" element={<Contractor />} />
                            {/* <Route exact path="/role" element={<Role />} /> */}
                            <Route exact path="/employeedesignation" element={<EmployeeDesignation />} />
                            <Route exact path="/employee" element={<Employee />} />
                            <Route exact path="/user" element={<User />} />

                            <Route exact path="/subdepartment" element={<SubDepartment />} />
                            <Route exact path="/zonemaster" element={<ZoneMaster />} />
                            <Route exact path="/wardmaster" element={<WardMaster />} />

                            <Route exact path="/electrolwardnumber" element={<ElectrolWardNumber />} />
                            <Route exact path="/manpowertype" element={<ManPowerType />} />
                            <Route exact path="/projectremark" element={<ProjectRemark />} />
                            <Route exact path="/munciplecouncilor" element={<MuncipleCouncilor />} />



                            <Route exact path="/rolewiseform" element={<RoleWiseForm />} />
                            <Route exact path="/userWiseWardMapping" element={<UserWiseWardMapping />} />

                            {/* cards dashboard table */}

                            {/* ------------------------Create Project---------- */}
                            <Route exact path="/departmentwiseproject" element={<DepartmentWiseProject />} />
                            <Route exact path="/projectwisework" element={<ProjectWiseWork />} />
                            <Route exact path="/physicalfinancialworkprogress" element={<PhysicalFinancialWorkProgress />} />
                            <Route exact path="/workwisemilistone" element={<WorkWiseMiliStone />} />
                            <Route exact path="/contractorwork" element={<ContractorWork />} />
                            <Route exact path="/createbudget" element={<CreateBudget />} />
                            <Route exact path="/financialworkprogress" element={<FinancialWorkProgress />} />

                            {/* -------------------------- create Budget ---------------- */}
                            <Route exact path="/budgetcreation" element={<BudgetCreation />} />
                            <Route exact path="/allocatebudget" element={<AllocateBudget />} />


                            {/* -------- project-section-tables------ */}
                            <Route exact path="/totalprojecttable" element={<AllTotalProjectTable />} />
                            <Route exact path="/alltotalprojectdepartment" element={<AllTotalProjectDepartment />} />
                            <Route exact path="/totalproject" element={<TotalProjectTable />} />


                            <Route exact path="/ongoingtotalproject" element={<OngoingTotalProject />} />
                            <Route exact path="/ongoingtotalprojectdepartment" element={<OngoingTotalProjectDepartment />} />
                            <Route exact path="/completedtotalprojecttable" element={<CompletedTotalProjectTable />} />
                            <Route exact path="/completedpublicworkdepartment" element={<CompletedPublicWorkDepartment />} />

                            {/* ------------------PHYSICAL SPILL OVER--------------- */}
                            <Route exact path="/physicalspillovertable" element={<PhysicalSpillOverTable />} />
                            <Route exact path="/physicalspillOverdepartmentpage" element={<PhysicalSpillOverDepartmentPage />} />

                            {/* --------------------FINANCIAL SPILL OVER--------------- */}
                            <Route exact path="/financialspillovertablespage" element={< FinancialSpillOverTablesPage />} />
                            <Route exact path="/financialspillOverelectricaldeptartments" element={< FinancialSpillOverElectricalDeptartment />} />

                            {/* ----------------------------PENDING UTILIZATION STATEMENT----------------- */}
                            <Route exact path="/pendingutilizationtablepage" element={< PendingUtilizationTablePage />} />
                            <Route exact path="/pendingutilizationelectricaldepartment" element={< PendingUtilizationElectricalDepartment />} />

                            {/* -------------------PAN CITY PROJECTS------------------------- */}
                            <Route exact path="/viewprogress" element={< Progress />} />


                            {/* ------------------------- User Master ------------------ */}
                            <Route exact path="/defineRole" element={< DefineRole />} />
                            <Route exact path="/userCreation" element={< UserCreation />} />
                            <Route exact path="/projectDetails" element={< ProjectDetails />} />

                            {/* ------------------------ Update ---------------------- */}
                            <Route exact path="/updateMaster" element={< UpdateMaster />} />

                            {/* ------------------------ TrackProject ---------------------- */}
                            <Route exact path="/trackProject" element={< TrackProject />} />


                            {/* --------------- */}

                            <Route exact path="/TotalDepartments" element={< TotalDepartments />} />
                            <Route exact path="/TotalProjects" element={< TotalProjects />} />
                            <Route exact path="/DashboardProjectDetails" element={< DashboardProjectDetails />} />
                            <Route exact path="/WardWiseProjectDetails" element={< WardWiseProjectDetails />} />
                            <Route exact path="/CommonWardWiseProjectDetailsScreen" element={< CommonWardWiseProjectDetailsScreen />} />
                            <Route exact path="/DelayedWork" element={< DelayedWork />} />
                            <Route exact path="/BudgetDetails" element={< BudgetDetails />} />
                            <Route exact path="/ProjectOverviewAll" element={< ProjectOverviewAll />} />


                            {/* Report Screen */}

                            <Route exact path="/ReportScreen" element={< ReportScreen />} />



                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Router>

            </ErrorBoundary>
        </>
    )

}

