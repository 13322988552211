import React from "react";
import { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Department_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import Select from "react-select";
import ZoneDDL from "../../../CommonDDL/ZoneDDL/ZoneDDL";
import { ZoneDDLAPI } from "../../../Redux/DDLSlice";
import { useDispatch } from "react-redux";
import { WardMasterPostAPI } from "../../../Redux/MasterSlice/WardMasterSlice";

export default function WardMasterPop({ flag, open, handleOnCloseClick, deptOfficeTypeIndicatorID, apiFlag, IsPost, setIsPost, editData, handleOnClearClick }) {
    // console.log(editData)
    const [designationDDL, setdesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [designationTable, setdesignationTable] = useState([]);

    const userDetails = useAuthState();
    const { DepartmentID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [wardName, setWardName] = useState(apiFlag === 'Update' ? editData.wardName : '')
    const [passwordField, setpasswordField] = useState("password")
    const [departmentTextField, setdepartmentTextField] = useState(
        {
            departmentName: apiFlag === "Update" ? editData.departmentName : "",
            contactNumber: apiFlag === "Update" ? editData.mobileNo : "",
            emailID: apiFlag === "Update" ? editData.emailId : "",
            userName: apiFlag === "Update" ? editData.userName : "",
            password: apiFlag === "Update" ? editData.password : "",
        }
    )
    const [zoneDDL, setZoneDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });



    const handleInputChange = (e) => {
        setdepartmentTextField({ ...departmentTextField, [e.target.name]: e.target.value })
    }


    const handleClearField = () => {
        setZoneDDL({
           ...zoneDDL,
            ID: 0,
            Label: "---Select---",
        })
        setWardName('')
    }
    const showPassword = () => {
        if (passwordField === "password") {
            setpasswordField("text")
        } else {
            setpasswordField("password")
        }
    }
    const [formErrors, setformErrors] = useState({
        empNameError: "",
        emailError: "",
        mobileError: "",

    })

    const checkEmailvalidation = (text) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, emailError: "" })
        } else {
            setformErrors({ ...formErrors, emailError: "Enter valid email address" })

        }
    }

    const checkMobileValidation = (text) => {
        // let regex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
        let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, mobileError: "" })
        } else {
            setformErrors({ ...formErrors, mobileError: "Enter valid contact number" })

        }
    }
    // console.log(editData)
    const { departmentName, contactNumber, emailID, userName, password } = departmentTextField

    const Add = () => {
        const data = {
            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost,
            handleOnClearClick: handleOnClearClick,
            token: token,
            Flag: apiFlag,
            M_WardID: apiFlag === 'Update' ? editData.m_WardID : 0,
            M_ZoneID: zoneDDL.ID,
            WardName: wardName,
            UserID: UserID
        }
        dispatch(WardMasterPostAPI({ data }))
    }


    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>

                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Ward </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-md-12 col-lg-6">
                            <ZoneDDL
                                zoneDDL={zoneDDL}
                                setZoneDDL={setZoneDDL}
                                FlagName='Master'
                                editData={editData}
                                apiFlag={apiFlag}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="wardName"> Ward Name <Star /></label>
                                <input
                                    className="form-control"
                                    id="wardName"
                                    type="text"
                                    name="wardName"
                                    value={wardName}
                                    onChange={(e) => { setWardName(e.target.value) }}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>



                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={apiFlag === 'Update' ? handleOnCloseClick : handleClearField}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} /> {apiFlag === 'Update' ? 'Cancel' : 'Clear'} </button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        wardName === '' ||
                                        zoneDDL.ID === 0
                                    }
                                >{flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}