import React, { useState } from "react";
import { Edit, Edit2, Flag, Search, Trash, Trash2, X } from "react-feather";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import EmployeeDesignationPopUp from "./EmployeeDesignationPopUp";
import DeletePopUp from "../../../Helper/DeletePopUp";
import MobileHeader from "../../../Components/Header/MobileHeader";
import { useAuthState } from "../../../Helper/Context/context";
import { useEffect } from "react";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Get_M_Designation_Select } from "../MasterApi/GETAPI";
import { Pegination } from "../../../Components/Pegination/Pegination";
import Footer from "../../../Components/Footer/Footer";
import Select from "react-select";
import DesignationNameDDL from "../../../CommonDDL/DesignationDDL/DesignationNameDDL";
import { useDispatch, useSelector } from "react-redux";
import { DepartmentNameDDLAPI, DesignationNameDDLAPI } from "../../../Redux/DDLSlice";
import { DesignationTableDataAPI } from "../../../Redux/MasterSlice/DesignationSlice";
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

export default function EmployeeDesignation() {

  const userDetails = useAuthState();
  const { UserID, token, DepartmentID } = userDetails
  const dispatch = useDispatch();
  // const { DepartmentID, UserID } = userDetails;

  const [showCreatePopup, setshowCreatePopup] = useState(false);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [flag, setflag] = useState(false);
  const [isopen, setIsOpen] = useState(true);

  // employee pagination state
  const [PerPageCount, setPerPageCount] = useState(10);
  const [TotalCount, setTotalCount] = useState(0);
  const [To, setTo] = useState(100);
  const [From, setFrom] = useState(1);
  const [rowNo, setrowNo] = useState(1);

  const [designationTable, setdesignationTable] = useState([]);
  const [apiFlag, setapiFlag] = useState();
  const [IsPost, setIsPost] = useState(false);
  const [editData, seteditData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [loading, setloading] = useState();
  const [CurrentPage, setCurrentPage] = useState(0);

  const [employeDesignationTextField, setemployeDesignationTextField] =
    useState({
      designationName: "",
    });
  const [designationNameDDL, setDesignationNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  });

  const handleInputChange = (e) => {
    setemployeDesignationTextField({
      ...employeDesignationTextField,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnCloseClick = () => {
    setshowCreatePopup(false);
    setshowDeletePopup(false);
  };
  const handleAddNewClick = () => {
    setshowCreatePopup(true);
    setflag("Insert");
    setapiFlag("Insert");
  };
  const handleOnEditClick = (item) => {
    setshowCreatePopup(true);
    setflag("Update");
    setapiFlag("Update");
    seteditData(item);
  };
  const handleOnDeleteClick = (item) => {
    setshowDeletePopup(true);
    setflag("Delete");
    setDeleteData(item);
  };

  useEffect(() => {
    setCurrentPage(0)
    setFrom(1)
    setTo(10)
  }, [designationNameDDL.ID])

  // console.log(editData);

  useEffect(() => {
    const data = {
      M_DesignationID: designationNameDDL.ID,
      M_UsersID: UserID,
      From: From,
      token: token,
      To: To,
    }
    dispatch(DesignationTableDataAPI({ data }))
  }, [From, IsPost, designationNameDDL.ID])

  const { tableData, isLoading } = useSelector(state => state.DesignationTableData)


  const handleOnSearchClick = () => {
    setIsPost(!IsPost);
    setCurrentPage(0);
  };
  const handleClearField = () => {
    setCurrentPage(0);
    setDesignationNameDDL({
      ...designationNameDDL,
      ID: 0,
      Label: 'Select...'

    })
  };

  const handleDeleteData = () => {
    var formdata = new FormData();
    formdata.append("M_DesignationID", deleteData);
    formdata.append("UserID", UserID);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Designation_Delete`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result.code >= 200 && result.code <= 300) {
          toastSuccesss(result.message);
        } else {
          toastErrorr(result.message);
        }
        handleOnCloseClick();
        setIsPost(!IsPost);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  return (
    <>
      {isLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar
            active="employeedesignation"
            subMenu="subMaster"
            isopen={isopen}
          />
          <div
            className="main-panel"
            style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
          >
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">
                        {" "}
                        Employee Designation
                      </h4>
                    </div>
                    <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        title="Add"
                        onClick={() => handleAddNewClick()}
                      >
                        <i
                          className="fa-solid fa-circle-plus pr-2"
                          style={{ fontSize: "15px" }}
                        ></i>
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className="filter mb-0">
                    <div className="card-body">
                      <div className="form-row">
                        <div className="col-12 col-md-6 col-lg-3">
                          <DesignationNameDDL
                            designationNameDDL={designationNameDDL}
                            setDesignationNameDDL={setDesignationNameDDL}
                            IsPost={IsPost}
                            editData={editData}
                            FlagName={Flag}
                            departmentDDL="0"


                          />
                        </div>

                        <div className="col-12 col-lg-2 pl-3">
                          <button
                            type="button"
                            className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleClearField}
                          >
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      className="table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th> Designation Name</th>
                          <th style={{ width: "8%",textAlign: "center" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData && tableData.table && tableData.table.length > 0
                          ? tableData.table.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">{item.rowNum}</td>
                              <td>
                                {item.designationName
                                  ? item.designationName
                                  : "-"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className="mx-2 cursor-pointer"
                                  title="Edit"
                                  onClick={() => handleOnEditClick(item)}
                                >
                                  <Edit size={15} color="#007bff" />
                                </span>

                                <span
                                  className="mx-2 cursor-pointer"
                                  title="Delete"
                                  onClick={() =>
                                    handleOnDeleteClick(item.m_DesignationID)
                                  }
                                >
                                  <Trash2 size={15} color="red" />
                                </span>
                              </td>
                            </tr>
                          ))
                          : <tr style={{ textAlign: "center" }}>No data found...</tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.table && tableData.table.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData.table[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {showCreatePopup ? (
        <EmployeeDesignationPopUp
          open={showCreatePopup}
          handleOnCloseClick={handleOnCloseClick}
          handleClearField={handleClearField}
          apiFlag={apiFlag}
          editData={editData}
          IsPost={IsPost}
          setIsPost={setIsPost}
          Flag={flag}
        />
      ) : (
        <></>
      )}
      {showDeletePopup ? (
        <DeletePopUp
          open={showDeletePopup}
          handleDeleteData={handleDeleteData}
          deleteData={deleteData}
          handleOnCloseClick={handleOnCloseClick}
        />
      ) : (
        <></>
      )}
    </>
  );
}
