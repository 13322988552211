
import Select from "react-select"
import { useEffect } from "react"
import { useAuthState } from "../../Helper/Context/context";
import { useDispatch, useSelector } from "react-redux";
import { DashboardMonthDDLAPI, FinancialDDLAPI } from "../../Redux/DDLSlice";

export const DashBoardMonthDDLData = (props) => {
    const { DashBoardMonthDDL, setDashBoardMonthDDL, Month, setMonth, DDLData, FlagName, editData, mendetary } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token
        }
        dispatch(DashboardMonthDDLAPI({ data }))
    }, [])

    const { DashboardMonthData } = useSelector(state => state.DashboardMonthDDLData)

    useEffect(() => {
        handleDashboardMonthDataDDL()
    }, [DashboardMonthData])


    const handleDashboardMonthDataDDL = () => {
        if (DashboardMonthData && DashboardMonthData.table && DashboardMonthData.table.length > 0) {
            let list = DashboardMonthData.table.map((item, index) => ({
                value: item.id,
                label: item.monthName,
            }))
            setDashBoardMonthDDL({
                DDL: list,
                // ID: FlagName === 'Update' ? editData.genderIndicatorID : 0,
                // Label: FlagName === 'Update' ? editData.genderIndicatorName : "Select..."
                ID: localStorage.getItem('MonthID') ? localStorage.getItem('MonthID') : 0,
                Label: localStorage.getItem('MonthLabel') ? localStorage.getItem('MonthLabel') : 'Month...',
            })
            setMonth({
                ...Month,
                DDL: list,
                ID: 0,
                Label: "Month...",
            })
        }
        else {
            setDashBoardMonthDDL({
                DDL: [],
                ID: 0,
                Label: "Month...",
            })
            setMonth({
                ...Month,
                DDL: [],
                ID: 0,
                Label: "Month...",
            })
        }
    }
    const handleMonthChange = (e) => {
        setDashBoardMonthDDL({ ...DashBoardMonthDDL, ID: e.value, Label: e.label });
        localStorage.setItem("MonthID", JSON.stringify(e.value));
        localStorage.setItem("MonthLabel", e.label);
    };

    const handleMonthClear = () => {
        setDashBoardMonthDDL({ ...DashBoardMonthDDL, ID: 0, Label: "Month..." });
        localStorage.setItem("MonthID", '0');
        localStorage.setItem("MonthLabel", 'Month...');
    };

    return (
        <>
            <div className="form-group d-md-flex d-lg-flex justify-content-end">
                <label className="d-block mt-2 ml-2">Month :</label>
                <Select
                    isClearable
                    isSearchable
                    className="ml-2"
                    maxMenuHeight={140}
                    value={{ value: DashBoardMonthDDL.ID, label: DashBoardMonthDDL.Label }}
                    // onChange={(e) => {
                    //     e ?
                    //         setDashBoardMonthDDL({ ...DashBoardMonthDDL, ID: e.value, Label: e.label })
                    //         :
                    //         setDashBoardMonthDDL({ ...DashBoardMonthDDL, ID: 0, Label: "Month..." })
                    // }}
                    onChange={(e) => {
                        e ? handleMonthChange(e) : handleMonthClear();
                    }}
                    options={DashBoardMonthDDL.DDL}

                />
            </div>
        </>
    )
}