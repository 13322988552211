import { useEffect } from 'react'
import Select from 'react-select'
import { StagesOfWorkDDLAPI, WorkTypeDDLAPI } from '../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../Helper/Context/context'
import { Star } from '../Components/CommoComponent/Star'


export const StagesOfWorkDDL = (props) => {
    const { StagesWorkDDL, setStagesWorkDDL, Flag, PopUpField, apiFlag, editData, mendetary } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = { UserID: UserID, token: token }
        dispatch(StagesOfWorkDDLAPI({ data }))
    }, [])

    const { StagesOfWorkData } = useSelector(state => state.StagesOfWorkDDLData)

    useEffect(() => {
        handleStagesOfWorkDDL();
    }, [StagesOfWorkData])

    const handleStagesOfWorkDDL = () => {
        if (StagesOfWorkData && StagesOfWorkData.table && StagesOfWorkData.table.length > 0) {
            let list = StagesOfWorkData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.m_Tender_StagesID,
                label: item.tender_Stages,
            }))

            if (PopUpField && PopUpField.popupFlag) {

                setStagesWorkDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_Tender_StagesID : 0 : '0',
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.tender_Stages : "--Select--" : "--Select--",
                })
            } else {
                setStagesWorkDDL({
                    DDL: list,
                    ID: apiFlag === 'Update' ? editData.m_Tender_StagesID : 0,
                    Label: apiFlag === 'Update' ? editData.tender_Stages : "---Select---"
                })
            }
        }
        else {
            setStagesWorkDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"> Stages of Work {mendetary==='yes' ? <Star/> : ''} </label>
            <Select
                isClearable
                isSearchable
                maxMenuHeight={150}
                value={{ value: StagesWorkDDL.ID, label: StagesWorkDDL.Label }}
                onChange={(e) => {
                    e ?
                        setStagesWorkDDL({ ...StagesWorkDDL, ID: e.value, Label: e.label })
                        :
                        setStagesWorkDDL({ ...StagesWorkDDL, ID: 0, Label: "--Select--" })

                }}
                options={StagesWorkDDL.DDL}
            // isDisabled
            />
        </div>
    )
}