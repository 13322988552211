
import { useEffect } from 'react'
import Select from 'react-select'

import { useDispatch, useSelector } from 'react-redux';
import { useAuthState } from '../../Helper/Context/context';
import { TenderAmountTypeDDLAPI } from '../../Redux/DDLSlice';
import { Star } from '../../Components/CommoComponent/Star';




export default function TenderAmountTypeDDL(props) {
    const { tenderAmountTypeDDL, setTenderAmountTypeDDL, tenderAmountTypeDDLData, FlagName, Flag, PopUpField, apiFlag, editData, mendetary } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = { UserID: UserID, token: token }
        dispatch(TenderAmountTypeDDLAPI({ data }))
    }, [])

    const { TenderAmountData } = useSelector(state => state.TenderAmountTypeDDLData)

    useEffect(() => {
        handleTenderAmountDDL();
    }, [TenderAmountData])

    const handleTenderAmountDDL = () => {
        if (TenderAmountData && TenderAmountData.table && TenderAmountData.table.length > 0) {
            let list = TenderAmountData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.tender_Amount_Type,
            }))

            if (PopUpField && PopUpField.popupFlag) {

                setTenderAmountTypeDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_Tender_Amount_TypeID : 0 : 0,
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.tender_Amount_Type : "--Select--" : "--Select--",
                })
            } else {
                setTenderAmountTypeDDL({
                    DDL: list,
                    ID: apiFlag === 'Update' ? editData.m_Tender_Amount_TypeID : 0,
                    Label: apiFlag === 'Update' ? editData.tender_Amount_Type : "---Select---"
                })
            }
        }
        else {
            setTenderAmountTypeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Tender Amount Type {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: tenderAmountTypeDDL.ID, label: tenderAmountTypeDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setTenderAmountTypeDDL({ ...tenderAmountTypeDDL, ID: e.value, Label: e.label })
                            :
                            setTenderAmountTypeDDL({ ...tenderAmountTypeDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={tenderAmountTypeDDL.DDL}

                />
            </div>
        </>
    )
}