import React from 'react'
import { useNavigate } from 'react-router-dom';

function PhysicalSpillOverCard(props) {
	const { title, count, path, Cardtitle, cardName, CardID } = props

	const navigate = useNavigate()

	const handleChange = () => {
		navigate(`${path}?projectType=${cardName}&title=${title}&Cardtitle=${Cardtitle}&CardID=${CardID}`);
	};

	return (

		<div className="col-12 col-lg-3 col-sm-6 mb-2" >
			<span className="con-wrap" onClick={handleChange} >
				<p className='spacing_text'>{Cardtitle}</p>
				<span>
					<h2 className='font-weight-bold mb-lg-2'>{count}</h2>
				</span>
			</span>
		</div>
	)
}

export default PhysicalSpillOverCard
