
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"
import { Flag } from "react-feather"
import { DesignationNameDDLAPI } from "../../Redux/DDLSlice"
import { useAuthState } from "../../Helper/Context/context"
import { useDispatch, useSelector } from "react-redux"

export default function DesignationNameDDL(props) {
    const { designationNameDDL, setDesignationNameDDL, editData, FlagName, PopUpField, mendetary, IsPost,departmentDDL } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();


    useEffect(() => {
        const data = { UserID, token, M_DeptOfficeTypeIndicatorID: departmentDDL.ID }
        dispatch(DesignationNameDDLAPI({ data }))
    }, [IsPost])

    const { DesignationDDLData } = useSelector(state => state.DesignationNameData)


    useEffect(() => {
        handleDesignationNameDDL()
    }, [DesignationDDLData])

    const handleDesignationNameDDL = () => {
        if (DesignationDDLData && DesignationDDLData.table && DesignationDDLData.table.length > 0) {

            let list = DesignationDDLData.table.map((item, index) => ({

                // value: item.departmentCode,
                value: item.id,
                label: item.designationName,
            }))
            if (FlagName == 'Update') {
                setDesignationNameDDL({
                    DDL: list,
                    ID: FlagName === 'Update' ? editData.m_DesignationID : 0,
                    Label: FlagName === 'Update' ? editData.designationName : "---Select---"
                })
            } else {
                setDesignationNameDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_DesignationID : 0 : '0',
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.designationName : "---Select---" : "---Select---",
                })
            }

        }
        else {
            setDesignationNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Designation Name {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: designationNameDDL.ID, label: designationNameDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setDesignationNameDDL({ ...designationNameDDL, ID: e.value, Label: e.label })
                            :
                            setDesignationNameDDL({ ...designationNameDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={designationNameDDL.DDL}

                />
            </div>
        </>
    )
}