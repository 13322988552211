
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"



export default function MuncipleCouncilorDDL(props) {
    const { muncipleCouncilorDDL, setMuncipleCouncilorDDL, DDLData, FlagName } = props

    useEffect(() => {
        handleManPowerTypeDDL()
    }, [DDLData])

    const handleManPowerTypeDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {

            let list = DDLData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.municipalCouncilor,
            }))
            setMuncipleCouncilorDDL({
                DDL: list,
                ID: 0,
                Label: "Select...",
                // ID: PopUpField ? PopUpField?.apiFlag === "Insert" ? 0 : PopUpField?.rowData?.m_Product_CategoryID : "0",
                // Label: PopUpField ? PopUpField?.apiFlag === "Insert" ? "Select..." : PopUpField?.rowData?.categoryName : "Select...",
            })
        }
        else {
            setMuncipleCouncilorDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype"> Munciple Councilor</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: muncipleCouncilorDDL.ID, label: muncipleCouncilorDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setMuncipleCouncilorDDL({ ...muncipleCouncilorDDL, ID: e.value, Label: e.label })
                            :
                            setMuncipleCouncilorDDL({ ...muncipleCouncilorDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={muncipleCouncilorDDL.DDL}

                />
            </div>
        </>
    )
}