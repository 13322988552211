import React, { useState, useEffect } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import UserPopUp from "./UserPopUp";
import Select from 'react-select'
import { Get_DDL_M_Department, Get_DDL_M_Role } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";

import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Pegination } from "../../../Components/Pegination/Pegination";
import { Get_M_Users_Select } from "../MasterApi/GETAPI";
import { Post_M_Users_Delete } from "../MasterApi/POSTAPI";
import Footer from "../../../Components/Footer/Footer";
import RoleDDL from "../../../CommonDDL/RoleDDL/RoleDDL";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";

export default function User() {

    const userDetails = useAuthState();
    const { UserID, RoleID } = userDetails

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);
    const [userGridData, setuserGridData] = useState()


    // react pagination states 
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)

    const [loading, setloading] = useState(false)
    const [editInfo, seteditInfo] = useState('')
    const [PopUpFlag, setPopUpFlag] = useState()
    const [deleteI, setdeleteI] = useState()
    const [IsPost, setIsPost] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })
    const [roleDDL, setRoleDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })

    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
    }
    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        setflag("Insert")
        setPopUpFlag("Add")
    }
    const handleOnEditClick = (item) => {
        setshowCreatePopup(true)
        setflag("Update")
        setPopUpFlag("Update")
        seteditInfo(item)
    }
    const handleOnDeleteClick = (item) => {
        setshowDeletePopup(true)
        setflag("Delete")
        setdeleteI(item)
    }

    const handleClose = () => {
        handleOnCloseClick(false)
    }





    const handleOnSearchClick = () => {
        setIsPost(!IsPost)
        setCurrentPage(0)
    }
    const handleOnClearClick = () => {
        setCurrentPage(0)
        console.log(CurrentPage);

        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        setIsPost(!IsPost)
    }


    const handleDeleteData = () => {

        Post_M_Users_Delete(
            {
                UserID,
                deleteI,
                Flag: flag,
                setIsPost: setIsPost,
                IsPost: IsPost,
                setloading: setloading,
                handelPopUpClose: handleClose
            }
        )
    }



    return (
        <>
            {loading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="user" subMenu="subMaster" isopen={isopen} />
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">User Creation</h4>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                            <button type="button" className="btn btn-primary btn-sm pl-3 pr-3 rounded-4"
                                                title="Add" onClick={() => handleAddNewClick()}>
                                                <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <RoleDDL
                                                        roleDDL={roleDDL}
                                                        setRoleDDL={setRoleDDL}
                                                        FlagName=''
                                                    />

                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <DepartmentDDL
                                                        departmentDDL={departmentDDL}
                                                        setdepartmentDDL={setdepartmentDDL}
                                                        FlagName=''
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-4 text-center">
                                                    <button type="button" className="btn btn-primary text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnSearchClick}
                                                    >
                                                        {/* <Search/> */}
                                                        <Search size={18} style={{ marginRight: "5px" }} />Search
                                                    </button>
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                        disabled={CurrentPage}
                                                    >
                                                        {/* <Search/> */}
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="5%">Sr.No.</th>
                                                    <th>Department</th>
                                                    <th>Name of Role</th>
                                                    <th>Designation Name</th>
                                                    <th>Employee Name</th>
                                                    <th>Username</th>
                                                    <th>Password</th>
                                                    <th>User Status</th>
                                                    <th style={{ width: "150px", textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    userGridData && userGridData.length > 0 ? userGridData.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                                            <td>{item.departmentName ? item.departmentName : "-"}</td>
                                                            <td>{item.roleName ? item.roleName : "-"}</td>
                                                            <td>{item.designationName ? item.designationName : "-"}</td>
                                                            <td>{item.employeeName ? item.employeeName : "-"}</td>
                                                            <td className="text-transform-none">{item.userName ? item.userName : "-"}</td>
                                                            <td className="text-transform-none">{item.password ? item.password : "-"}</td>

                                                            <td>{item.userStatusIndicator ? item.userStatusIndicator : "-"}</td>

                                                            <td style={{ textAlign: "center", minWidth: "90px" }}>
                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Edit" onClick={() => handleOnEditClick(item)}>
                                                                    <Edit size={15} color="#007bff" />
                                                                </span>

                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Delete" onClick={() => handleOnDeleteClick(item.id)}>
                                                                    <Trash2 size={15} color="red" />
                                                                </span>

                                                            </td>
                                                        </tr>
                                                    )) : <tr>No data</tr>
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                    {userGridData && userGridData.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={TotalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

            {showCreatePopup ? <UserPopUp
                open={showCreatePopup}
                handleOnCloseClick={handleOnCloseClick}
                flag={flag}
                PopUpFlag={PopUpFlag}
                editInfo={editInfo}
                setIsPost={setIsPost}
                IsPost={IsPost}
                handleOnClearClick={handleOnClearClick}
            /> : <></>}
            {showDeletePopup ? <DeletePopUp open={showDeletePopup}
                handleOnCloseClick={handleOnCloseClick}
                handleDeleteData={handleDeleteData}
                deleteI={deleteI}
            /> : <></>}


        </>
    )
}