
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const WorkWiseMileStoneSidebarExport = (props) => {
    const { ExportData, name, title, projectType } = props
    console.log('ExportData', ExportData)
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportUserCreation"
                className="btn btn-success float-end download-table-xls-button float-end btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-ExportUserCreation" style={{ display: 'none', width: '50%' }}>

                <h5><span>&emsp;</span></h5>

                <tr>
                    <th colSpan={6} style={{ textAlign: 'center', fontSize: 16 }}>{name}</th>
                </tr>
                {/* <tr><td></td></tr> */}

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#254462', color: "#fff" }}>
                        <th style={{ width: "5%", borderRight: '1px solid white' }} >Sr.No.</th>
                        <th>Project Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Main Work Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>No of Milestone</th>
                        <th style={{ borderLeft: "1px solid white" }}>Milestone Name</th>
                    </tr>
                </thead>
                <tbody>
                    {

                        ExportData && ExportData.data_Details && ExportData.data_Details.length > 0 ? ExportData.data_Details.map((item, i) => (
                            <tr style={{ textAlign: "center", border: '1px solid black' }}>
                                <td className="text_center">{i + 1}</td>
                                <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                                <td>{item.projectName ? item.projectName : "-"}</td>
                                <td>{item.workTypeName ? item.workTypeName : "-"}</td>
                                <td style={{ textAlign: 'right' }}>{item.numberOfMileStone ? item.numberOfMileStone : "-"}</td>
                                <td>
                                    <span className="milestoneName">
                                        {
                                            // item.dataList && item.dataList.length > 0 ?
                                            item.dataList.map((each, index) => (
                                                <span className="mr-2">{index + 1 + ". "}{each.milestone_Name ? each.milestone_Name : "-"}</span>
                                            ))
                                            // : "-"
                                        }
                                    </span>
                                </td>
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}