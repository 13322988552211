


import React, { useEffect, useState } from 'react'
import MobileHeader from '../../../../Components/Header/MobileHeader';
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import DepartmentDDL from '../../../../CommonDDL/DepartmentDDL/DepartmentDDL';
import { useDispatch, useSelector } from 'react-redux';
import SubDepartmentDDL from '../../../../CommonDDL/SubDepartmentDDL/SubDepartmentDDL';
import ProjectTypeDDLData from '../../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData';
import WardNameDDL from '../../../../CommonDDL/WardNameDDL/WardNameDDL';
import ProjectPriorityDDL from '../../../../CommonDDL/ProjectPriorityDDL/ProjectPriorityDDL';
import KeyProjectDDL from '../../../../CommonDDL/KeyProjectDDL/KeyProjectDDL';
import WorkTypeDDL from '../../../../CommonDDL/WorkTypeDDL/WorkTypeDDL/WorkTypeDDL';
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from '../../../../Helper/Context/context';
import { CommonProjectDetailsExportTableDataAPI, CommonProjectDetailsTableDataAPI } from '../../../../Redux/DashboardSlice/ProjectDetailsSlice/CommonProjectDetailsSlice';
import moment from 'moment';
import { Pegination } from '../../../../Components/Pegination/Pegination';
import LoaderFile from '../../../../Helper/Loader/LoaderFile';
import { ProjectDetailsExport } from './ProjectDetailsExport';
import { X } from 'react-feather';
import { WorkTypeDDLData } from '../../../../CommonDDL/WorkTypeDDLData';
import { WardNameDDLAPI } from '../../../../Redux/DDLSlice';

export default function DashboardProjectDetails({ props }) {
    const today = new Date();
    const year = today.getFullYear();

    const [searchParams] = useSearchParams()

    const projectType = searchParams.get('projectType')
    const title = searchParams.get('title')
    const Cardtitle = searchParams.get('Cardtitle')
    const Flag = searchParams.get('Flag')

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [ExcelData, setExcelData] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [isopen, setIsOpen] = useState(true);


    const dateOptions = [
        { value: '1', label: '2021-2022' },
        { value: '2', label: '2020-2021' },
        { value: '3', label: '2019-2020' }
    ]

    const [FinancialDDL, setFinancialDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    // ---------
    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [subDepartmentDDL, setSubDepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [WorkTypeDDL, setWorkTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [projectPriorityDDL, setProjectPriorityDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [keyProjectDDL, setKeyProjectDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const { DepartmentDDLData } = useSelector(state => state.DepartmentNameData)

    const handleOnClearClick = () => {
        setCurrentPage(0)
        setIsPost(!IsPost)
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        setSubDepartmentDDL({
            ...subDepartmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectTypeDDL({
            ...ProjectTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setWorkTypeDDL({
            ...WorkTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setWardNameDDL({
            ...wardNameDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectPriorityDDL({
            ...projectPriorityDDL,
            ID: 0,
            Label: "---Select---"
        })
        setKeyProjectDDL({
            ...keyProjectDDL,
            ID: 0,
            Label: "---Select---"
        })

    }

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_WardID: wardNameDDL.ID,
            zoneDDL: 0
        }
        dispatch(WardNameDDLAPI({ data }))
    }, [])

    const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            M_DepartmentID: departmentDDL.ID,
            M_SubDepartmentID: subDepartmentDDL.ID,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_WorkTypeID: WorkTypeDDL.ID,
            M_WardID: wardNameDDL.ID,
            M_ProjectPriorityID: projectPriorityDDL.ID,
            Key_ProjectM_IndicatorID: keyProjectDDL.ID,
            Flag: Flag,
            M_UsersID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
            handleGetExport: handleGetExport
        }
        dispatch(CommonProjectDetailsTableDataAPI({ data }))
    }, [To, IsPost, IsClear, YearID, MonthID, departmentDDL.ID, subDepartmentDDL.ID, ProjectTypeDDL.ID, WorkTypeDDL.ID, projectPriorityDDL.ID, keyProjectDDL.ID, wardNameDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.CommonProjectDetailsTableData)


    const handleGetExport = () => {
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            M_DepartmentID: departmentDDL.ID,
            M_SubDepartmentID: subDepartmentDDL.ID,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_WorkTypeID: WorkTypeDDL.ID,
            M_WardID: wardNameDDL.ID,
            M_ProjectPriorityID: projectPriorityDDL.ID,
            Key_ProjectM_IndicatorID: keyProjectDDL.ID,
            Flag: Flag,
            M_UsersID: UserID,
            FromTop: From,
            ToTop: '99999',
            token: token,
        }
        dispatch(CommonProjectDetailsExportTableDataAPI({ data }))
    }
    // , [To, IsPost, IsClear, departmentDDL.ID, subDepartmentDDL.ID, ProjectTypeDDL.ID, workTypeDDL.ID, projectPriorityDDL.ID, keyProjectDDL.ID,])

    const { ExporttableData, isExportLoading } = useSelector(state => state.CommonProjectDetailsExportTableData)
    return (
        <div className="wrapper">
            {isLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="dashboard" isopen={isopen} />
                    <div
                        className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            <div className="row page-heading justify-content-between">
                                <div className="col-6 col-lg-7 mb-xl-0 py-3">
                                    {/* <h4 className={"font-weight-bold py-3"}>{Cardtitle && Cardtitle ? `${title} - ${Cardtitle}` : title}</h4> */}
                                    <h4 className={"font-weight-bold"}>
                                        {Cardtitle && Cardtitle ? (
                                            <>
                                                {title} - <span style={{ color: "red" }}>{Cardtitle}</span>
                                            </>
                                        ) : (
                                            title
                                        )}
                                    </h4>
                                </div>

                                <div className="col-6 col-lg-3 mb-xl-0 py-2">
                                    <h4 className="font-weight-bold float-right px-2 py-1 mr-3 btn-outline-count rounded">
                                        Total Count :- <span className='text-danger px-2'>{tableData && tableData[0]?.totalCount ? tableData[0]?.totalCount : 0}</span>
                                    </h4>
                                </div>
                                <div className="col-6 col-lg-2 mb-xl-0">
                                    {
                                        isExportLoading ?
                                            <button type="button" className="btn btn-success btn-sm float-right" disabled>
                                                <i className="fa fa-refresh fa-spin" style={{ fontSize: "15px", marginRight: "5px" }}></i>
                                                Loading..
                                            </button>
                                            :
                                            tableData && tableData.length > 0 &&
                                            <ProjectDetailsExport
                                                ExportData={ExporttableData}
                                                name={title}
                                                title={title}
                                                projectType={projectType}
                                            />
                                    }
                                </div>
                            </div>

                            <div className="col-12 shadow table-card">
                                <div className=" filter mb-0">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col-12 col-md-6 col-lg-3">
                                                <DepartmentDDL
                                                    departmentDDL={departmentDDL}
                                                    setdepartmentDDL={setdepartmentDDL}
                                                    DDLData={DepartmentDDLData}
                                                    FlagName=''
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3">
                                                <SubDepartmentDDL
                                                    subDepartmentDDL={subDepartmentDDL}
                                                    setSubDepartmentDDL={setSubDepartmentDDL}
                                                    departmentDDLID={departmentDDL.ID}
                                                    FlagName=''
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3">
                                                <ProjectTypeDDLData
                                                    ProjectTypeDDL={ProjectTypeDDL}
                                                    setProjectTypeDDL={setProjectTypeDDL}
                                                    FlagName=''
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3">
                                                {/* <WorkTypeDDL
                                                    workTypeDDL={workTypeDDL}
                                                    setworkTypeDDL={setworkTypeDDL}
                                                    FlagName=''
                                                /> */}
                                                <WorkTypeDDLData
                                                    WorkTypeDDL={WorkTypeDDL}
                                                    setWorkTypeDDL={setWorkTypeDDL}
                                                    IsPost={IsPost}
                                                    Flag='Master'

                                                // WorkTypeData={WorkTypeData}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3">
                                                <WardNameDDL
                                                    wardNameDDL={wardNameDDL}
                                                    setWardNameDDL={setWardNameDDL}
                                                    DDLData={WardNameDDLData}
                                                    FlagName=''
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3">
                                                <ProjectPriorityDDL
                                                    projectPriorityDDL={projectPriorityDDL}
                                                    setProjectPriorityDDL={setProjectPriorityDDL}
                                                    FlagName=''
                                                />
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3">
                                                <KeyProjectDDL
                                                    keyProjectDDL={keyProjectDDL}
                                                    setKeyProjectDDL={setKeyProjectDDL}
                                                    FlagName=''
                                                />
                                            </div>

                                            <div className="col-12 col-lg-2 pl-lg-3">
                                                <button type="button"
                                                    className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                    onClick={handleOnClearClick}
                                                >
                                                    <X size={18} style={{ marginRight: "3px" }} />Clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 grid-margin">
                                        <div className="table-responsive">

                                            <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                <thead>
                                                    <tr style={{ textAlign: "center" }}>
                                                        <th width="5%">Sr.No.</th>
                                                        <th>Department</th>
                                                        <th>Ward Name</th>
                                                        <th>Electroal Ward Number</th>
                                                        <th style={{ whiteSpace: 'nowrap' }}>Project Tracker Code</th>
                                                        <th>Project Type</th>
                                                        <th>Work Type</th>
                                                        <th style={{ width: '30%', whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em' }}>Project Name</th>
                                                        {
                                                            title != 'Proposed Project' &&
                                                            <th>Tender Amt (lacs.)</th>
                                                        }
                                                        {
                                                            projectType == 'OnGoing' &&
                                                            <>
                                                                <th>Work Start Date</th>
                                                                <th>Contractor Name</th>
                                                            </>
                                                        }
                                                        {
                                                            projectType == 'Completed' &&
                                                            <>
                                                                <th>Work Start Date</th>
                                                                <th>Work Completion Date</th>
                                                            </>
                                                        }
                                                        {
                                                            (projectType == 'OnGoing' || projectType == 'Completed') &&
                                                            <>
                                                                <th>Phy Progress</th>
                                                                <th>Fin Progress</th>
                                                            </>
                                                        }
                                                        <th>Project Priority</th>
                                                        <th>Key Project</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableData && tableData.length > 0 ? tableData.map((item, i) => (
                                                            <tr>
                                                                <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                                                <td>{item.departmentName ? item.departmentName : "-"}</td>
                                                                <td>{item.wardName ? item.wardName : "-"}</td>
                                                                <td className='text-right'>{item.m_WardWiseNumber ? item.m_WardWiseNumber : '0'}</td>
                                                                <td>{item.project_Tracker_Code ? item.project_Tracker_Code : "-"}</td>
                                                                <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                                                                <td>{item.workTypeName ? item.workTypeName : "-"}</td>
                                                                <td>{item.projectName ? item.projectName : "-"}</td>
                                                                {
                                                                    title != 'Proposed Project' &&
                                                                    <td className='text-right'>{item.tendarCost ? item.tendarCost.toFixed(2) : '0'}</td>
                                                                }
                                                                {
                                                                    projectType == 'OnGoing' &&
                                                                    <>
                                                                        <td style={{ whiteSpace: 'nowrap' }}>{item.work_Start_Date ? moment(item.work_Start_Date).format('DD-MM-YYYY') : '-'}</td>
                                                                        <td>{item.contractor_Name ? item.contractor_Name : '-'}</td>
                                                                    </>
                                                                }
                                                                {
                                                                    projectType == 'Completed' &&
                                                                    <>
                                                                        <td style={{ whiteSpace: 'nowrap' }}>{item.work_Start_Date ? moment(item.work_Start_Date).format('DD-MM-YYYY') : '-'}</td>
                                                                        <td>{item.work_Completion_Date ? moment(item.work_Completion_Date).format('DD-MM-YYYY') : '-'}</td>
                                                                    </>
                                                                }
                                                                {
                                                                    (projectType == 'OnGoing' || projectType == 'Completed') &&
                                                                    <>
                                                                        <td className='text-right'>{item.physicalWorkProgress ? `${item.physicalWorkProgress.toFixed(2)} %` : '0 %'}</td>
                                                                        <td className='text-right'>{item.financialWorkProgress ? `${item.financialWorkProgress.toFixed(2)} %` : '0 %'}</td>
                                                                    </>
                                                                }
                                                                <td>{item.projectPriorityName ? item.projectPriorityName : '-'}</td>
                                                                <td>{item.key_ProjectName ? item.key_ProjectName : '-'}</td>

                                                            </tr>
                                                        )) : <tr style={{ textAlign: "center", whiteSpace: 'nowrap' }}>No data found...</tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        {tableData && tableData.length > 0 &&
                                            <Pegination
                                                PerPageCount={PerPageCount}
                                                TotalCount={tableData[0].totalCount}
                                                setFrom={setFrom}
                                                setTo={setTo}
                                                setrowNo={setrowNo}
                                                CurrentPage={CurrentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className="copyright">
                                Copyright {year}. all rights are reserved.<b>Version 1.0</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
