import React, { useState } from 'react'
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import Select from "react-select"
import { Image, PieChart } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import ViewGalleryPopup from '../../PanCityProjectTable/ViewGalleryPopup';
import MobileHeader from '../../../../Components/Header/MobileHeader';




const OngoingTotalProjectDepartment = () => {
    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [isopen, setIsOpen] = useState(true);

    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        // setflag("Add")
    }
    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
    }

    const navigate = useNavigate()
    const handleOnClick = (val) => {
        navigate("/viewprogress")
        console.log(val);
    }




    return (
        <div className="wrapper">
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar isopen={isopen} />
                    <div className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Ongoing Projects - Public Work Department</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">

                                    <div className="table-responsive">
                                        <table cellpadding="0" cellspacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name of the Project</th>
                                                    <th>Name of Bidder</th>
                                                    <th>Area</th>
                                                    <th>Status</th>
                                                    <th>Estimated Cost</th>
                                                    <th>Tendered Cost</th>
                                                    <th>Expenditure Till Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text_center">1</td>
                                                    <td>Project name goes here</td>
                                                    <td>Bidder Name Come here</td>
                                                    <td>Area here</td>
                                                    <td><span className="badge badge-primary px-2 py-1">New</span></td>
                                                    <td>Rs.15.50L</td>
                                                    <td>Rs.16.50L</td>
                                                    <td>Rs.14.00L</td>
                                                    <td className="white-space-nowrap">
                                                    <button onClick={handleAddNewClick} className="mx-1" data-tooltip="tooltip" title="View Gallery"><i><Image color='#3a86ff'/></i></button>
                                                    <button onClick={handleOnClick} className="mx-2" data-tooltip="tooltip" title="View Progress"><i><PieChart color='#b67cd3' /></i></button></td>
                                                </tr>



                                            </tbody >
                                        </table >
                                    </div >
                                    <div className="row"><div className="col-sm-12 col-md-5"><div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 1 to 10 of 25 entries</div></div><div className="col-sm-12 col-md-7"><div className="dataTables_paginate paging_simple_numbers" id="example_paginate"><ul className="pagination"><li className="paginate_button page-item previous disabled" id="example_previous"><a href="#" aria-controls="example" data-dt-idx="0" tabindex="0" className="page-link">Previous</a></li><li className="paginate_button page-item active"><a href="#" aria-controls="example" data-dt-idx="1" tabindex="0" className="page-link">1</a></li><li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="2" tabindex="0" className="page-link">2</a></li><li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="3" tabindex="0" className="page-link">3</a></li><li className="paginate_button page-item next" id="example_next"><a href="#" aria-controls="example" data-dt-idx="4" tabindex="0" className="page-link">Next</a></li></ul></div></div></div>


                                </div >
                            </div >


                        </div >
                    </div >
                </div >
            </div >
            {showCreatePopup ? <ViewGalleryPopup open={showCreatePopup} handleOnCloseClick={handleOnCloseClick} /> : <></>}
        </div >
    )
}

export default OngoingTotalProjectDepartment
