
var url = window.location.origin

// export const BaseUrl =
// url === "http://ptms.cdatinfotech.com" ? "http://PTMSapi.cdatinfotech.com" :
// url === "http://demo.nmmcptms.in" ? "http://demoapi.nmmcptms.in" :
//     "http://PTMSapi.cdatinfotech.com"

// export const BaseUrl = "http://PTMSapi.cdatinfotech.com"
export const BaseUrl =
    url === "http://nmmcptms.cdat.work" ? "http://apinmmcptms.cdat.work" :
        url === "https://nmmcptms.cdat.work" ? "https://apinmmcptms.cdat.work" :
            url === "https://ptms.cdat.work" ? "https://apiptms.cdat.work" :
                url === "http://nmmcptms.in" ? "http://api.nmmcptms.in" :
                    url === "https://nmmcptms.in" ? "https://api.nmmcptms.in" :
                        // "https://apinmmcptms.cdat.work"
                        "https://apiptms.cdat.work"

// "http://demoapi.nmmcptms.in"