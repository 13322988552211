import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const ProjectTypeTableDataAPI = createAsyncThunk("ProjectTypeTableData", async ({ data }) => {
    const {
        M_ProjectTypeID,
        ProjectTypeName,      
        UserID,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_ProjectType_Select?M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID :'0'}&ProjectTypeName=${ProjectTypeName ? ProjectTypeName :''}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ProjectTypeTableDataSlice = createSlice({
    name: "ProjectTypeTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectTypeTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectTypeTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectTypeTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectTypeTableDataReducer =ProjectTypeTableDataSlice.reducer

// InsertUpdate Table Data

export const ProjectTypePostAPI = createAsyncThunk("ProjectTypePostData", async ({ addData }) => {
    const {
        M_ProjectTypeID,
        ProjectTypeName,
        Login_UserID,  
        Flag,
        token,
        handlePost,
        handleAddCloseClick
    } = addData

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectTypeID", M_ProjectTypeID);
    formdata.append("ProjectTypeName", ProjectTypeName);
    formdata.append("Login_UserID",Login_UserID);
    formdata.append("Flag", Flag);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/PTMS/Master/Post_M_ProjectType_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const ProjectTypeNameSlice = createSlice({
    name: "ProjectTypePostData",
    initialState: {
        isLoading: false,
        ProjectTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectTypePostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectTypePostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectTypeData = action.payload;
        });
        builder.addCase(ProjectTypePostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectTypeData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectTypePostDataReducer = ProjectTypeNameSlice.reducer



// Delete Table Data

export const ProjectTypeDeleteAPI = createAsyncThunk("ProjectTypeDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectTypeID", rowData?.id);
    formdata.append("M_UsersID", UserID);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/PTMS/Master/Post_M_ProjectType_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const ProjectTypeDeleteSlice = createSlice({
    name: "ProjectTypeDelete",
    initialState: {
        isDeleteLoading: false,
        ProjecttableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectTypeDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectTypeDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.ProjecttableData = action.payload;

        });
        builder.addCase(ProjectTypeDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.ProjecttableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectTypeDeleteReducer = ProjectTypeDeleteSlice.reducer