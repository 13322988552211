import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function ViewGalleryPopup({ flag, open, handleOnCloseClick }) {

	return (

			<div>
				<Popup open={open} closeOnDocumentClick onClose={handleOnCloseClick}>
					<span className="close" onClick={handleOnCloseClick}>
						&times;
					</span>
					<div className="call"> Gallery </div>
					<div className="modal-body">
					<div className="tbl-sticky">
							<table cellpadding="0" cellspacing="0" border="0" className="table table-bordered">
								<thead>
									<tr>
										<th>Sr.No.</th>
										<th>Milestone</th>
										<th>Before</th>
										<th>In progress</th>
										<th>Completed</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td>Milestones Come here</td>
										<td><button className="btn p-0 mrb50" id="dynamic"><img src="./assets/img/2.jpg" alt="" className="img-thumbnail" /></button></td>
										<td><button className="btn p-0 mrb50" id="dynamic2" lg-uid="lg1"><img src="./assets/img/2.jpg" alt="" className="img-thumbnail" /></button></td>
										<td><button className="btn p-0 mrb50" id="dynamic3" lg-uid="lg2"><img src="./assets/img/3.jpg" alt="" className="img-thumbnail" /></button></td>
									</tr>
									<tr>
										<td>2</td>
										<td>Milestones Come here</td>
										<td><button className="btn p-0 mrb50" id="dynamic4"><img src="./assets/img/2.jpg" alt="" className="img-thumbnail" /></button></td>
										<td><button className="btn p-0 mrb50" id="dynamic5" lg-uid="lg1"><img src="./assets/img/2.jpg" alt="" className="img-thumbnail" /></button></td>
										<td><button className="btn p-0 mrb50" id="dynamic6" lg-uid="lg2"><img src="./assets/img/3.jpg" alt="" className="img-thumbnail" /></button></td>
									</tr>
									<tr>
										<td>3</td>
										<td>Milestones Come here</td>
										<td><button className="btn p-0 mrb50" id="dynamic7"><img src="./assets/img/2.jpg" alt="" className="img-thumbnail" /></button></td>
										<td><button className="btn p-0 mrb50" id="dynamic8" lg-uid="lg1"><img src="./assets/img/2.jpg" alt="" className="img-thumbnail" /></button></td>
										<td><button className="btn p-0 mrb50" id="dynamic9" lg-uid="lg2"><img src="./assets/img/3.jpg" alt="" className="img-thumbnail" /></button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>


				</Popup>
			</div>


	)
}