import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const PhysicalWorkProgressTableDataAPI = createAsyncThunk("PhysicalWorkProgressTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_WorkTypeID,
        M_ProjectID,
        M_WardID,
        M_WardWiseNumberID,
        M_IndicatorStatusID,
        Flag,
        M_UsersID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_Web_T_PhysicalMilestoneWorkProgressMain_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_WardWiseNumberID=${M_WardWiseNumberID ? M_WardWiseNumberID : '0'}&M_IndicatorStatusID=${M_IndicatorStatusID ? M_IndicatorStatusID : '0'}&Flag=${Flag}&M_UsersID=${M_UsersID}&FromTop=${From}&Top=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                // console.log('result',result.data.table)
                return result.data.table
            } else {
                return result
            }
        })
})

const PhysicalWorkProgressDataSlice = createSlice({
    name: "PhysicalWorkProgressTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PhysicalWorkProgressTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PhysicalWorkProgressTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PhysicalWorkProgressTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PhysicalWorkProgressTableDataReducer = PhysicalWorkProgressDataSlice.reducer

// Completed MileStone View POPUP API

export const CompletedMileStoneViewPopupTableDataAPI = createAsyncThunk("CompletedMileStoneViewPopupTableData", async ({ data }) => {
    const {
        M_ProjectID,
        M_WardID,
        T_ProjectSanctionDetailsID,
        Flag,
        M_UsersID,
        token,
        // From,
        Top,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_Web_T_PhysicalMilestoneWorkProgressMainDetails_Select?M_WardID=${M_WardID ? M_WardID : 0}&T_ProjectSanctionDetailsID=${T_ProjectSanctionDetailsID ? T_ProjectSanctionDetailsID : 0}&M_ProjectID=${M_ProjectID ? M_ProjectID : 0}&Flag=${Flag}&M_UsersID=${M_UsersID}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                return result.data.table
            } else {
                return result
            }
        })
})

const CompletedMileStoneViewPopupDataSlice = createSlice({
    name: "CompletedMileStoneViewPopupTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CompletedMileStoneViewPopupTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CompletedMileStoneViewPopupTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CompletedMileStoneViewPopupTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CompletedMileStoneViewPopupTableDataReducer = CompletedMileStoneViewPopupDataSlice.reducer

