import React, { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Post_M_Department_InsertUpdate } from "../MasterApi/POSTAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Star } from "../../../Components/CommoComponent/Star";
import { ContractorMasterPostAPI } from "../../../Redux/MasterSlice/ContractorMasterSlice";
import { useDispatch } from "react-redux";


export default function ContractorPopUp({ flag, open, handleOnCloseClick, apiFlag, deptOfficeTypeIndicatorID, IsPost, setIsPost, editData, handleOnClearClick }) {

    const userDetails = useAuthState();
    const { DepartmentID, UserID, token } = userDetails

    const dispatch = useDispatch();

    console.log(editData)

    const [workTextField, setworkTextField] = useState(
        {
            ContractorName: apiFlag === "Update" ? editData.contractor_Name : "",
            // agencyName: apiFlag === "Update" ? editData.departmentName : "",
            address: apiFlag === "Update" ? editData.address : "",
            contactPersonName: apiFlag === "Update" ? editData.contact_Person : "",
            ContactNumber: apiFlag === "Update" ? editData.mobile_Number : "",
            emailAddress: apiFlag === "Update" ? editData.email_Address : "",
        }
    )
    const handleInputChange = (e) => {
        setworkTextField({ ...workTextField, [e.target.name]: e.target.value })
    }
    // ------------------validation-------------
    const [formErrors, setformErrors] = useState({
        empNameError: "",
        emailError: "",
        contractContactNumberError: "",

    })

    // const checkTextOnlyFields = (text, errorField, errorValue) => {
    // let regex = new RegExp(/^[a-zA-Z ]*$/)
    //     // let regex = new RegExp(/^[A-Za-z\s\U+O9OO-U+O97F]+$/)
    //     // let regex = new RegExp(/^[A-Za-z\s]*$/)
    //     if (regex.test(text) || text === "") {
    //         setformErrors({ ...formErrors, [errorField]: "" })
    //     } else {
    //         setformErrors({ ...formErrors, [errorField]: errorValue })

    //     }
    // }
    const checkEmailvalidation = (text) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, emailError: "" })
        } else {
            setformErrors({ ...formErrors, emailError: "Enter valid email address" })

        }
    }

    const checkMobileValidation = (text, errorField, errorValue) => {
        let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)
        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, contractContactNumberError: "" })
        } else {
            setformErrors({ ...formErrors, contractContactNumberError: "Invalid Contact Number" })

        }
    }

    const handleClearField = () => {
        setworkTextField(
            {
                agencyName: "",
                address: "",
                contractPersonName: "",
                contactNumber: "",
                emailAddress: "",
            }
        )

    }

    const { agencyName, address, contractPersonName, contractContactNumber, emailAddress } = workTextField

    const Add = () => {
        // Post_M_Department_InsertUpdate({
        //     apiFlag: apiFlag,
        //     UserID: UserID,
        //     editData: editData,
        //     M_DeptOfficeTypeIndicatorID:0,
        //     departmentName: agencyName ? agencyName : "",
        //     address: address ? address : "",
        //     contractPersonName: contractPersonName ? contractPersonName : "",
        //     contactNumber: contractContactNumber ? contractContactNumber : "",
        //     emailID: emailAddress ? emailAddress : "",
        //     handleOnCloseClick: handleOnCloseClick,
        //     setIsPost: setIsPost,
        //     IsPost: IsPost,
        //     depFlag: "",
        //     handleOnClearClick: handleOnClearClick
        // })

        const data = {

            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost,
            handleOnClearClick: handleOnClearClick,

            M_ContractorID: apiFlag === 'Update' ? editData.id : 0,
            ContractorName: workTextField.ContractorName,
            Address: workTextField.address,
            Contact_Person: workTextField.contactPersonName,
            EmailAddress: workTextField.emailAddress,
            MobileNumber: workTextField.ContactNumber,
            UserID: UserID,
            token: token,
            Flag: apiFlag,
        }
        dispatch(ContractorMasterPostAPI({ data }))
    }


    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Contractor </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="agencyName">Contractor Name  <Star /></label>
                                <input
                                    className="form-control"
                                    id="agencyName"
                                    type="text"
                                    name="ContractorName"
                                    value={workTextField.ContractorName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="address">Address <Star /></label>
                                <input
                                    className="form-control"
                                    id="address"
                                    type="text"
                                    name="address"
                                    value={workTextField.address}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="contractPersonName">Contact Person Name <Star /> </label>
                                <input
                                    className="form-control"
                                    id="contractPersonName"
                                    type="text"
                                    name="contactPersonName"
                                    value={workTextField.contactPersonName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="contractContactNumber">Contact Number <Star /></label>
                                <input
                                    className="form-control"
                                    id="ContactNumber"
                                    type="text"
                                    name="ContactNumber"
                                    value={workTextField.ContactNumber}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            handleInputChange(e)
                                            checkMobileValidation(e.target.value, "contractContactNumberError", "Invalid Contact Number")
                                        }

                                    }}
                                />
                                <span className="text-danger error-danger">{formErrors.contractContactNumberError}</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="emailAddress">Email Address</label>
                                <input
                                    className="form-control"
                                    id="emailAddress"
                                    type="email"
                                    name="emailAddress"
                                    value={workTextField.emailAddress}

                                    onChange={(e) => {
                                        handleInputChange(e)
                                        checkEmailvalidation(e.target.value)
                                    }}
                                />
                                <span className="text-danger error-danger">{formErrors.emailError}</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}>
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        workTextField.agencyName === "" ||
                                        workTextField.address === "" ||
                                        workTextField.contractPersonName === "" ||
                                        workTextField.contractContactNumber === "" ||
                                        formErrors.contractContactNumberError === "Invalid Contact Number" ||
                                        formErrors.emailError === "Enter valid email address"
                                    }
                                >{flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>
    )
}