import React, { useState, useEffect } from "react";
import { Edit,Trash2, X } from "react-feather";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import moment from 'moment';
import { useAuthState } from "../../../Helper/Context/context";
import WorkAgencyViewPopUp from "./ContractorWorkViewPopUp";
import { Pegination } from "../../../Components/Pegination/Pegination";
import Footer from "../../../Components/Footer/Footer";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import ContractorNameDDL from "../../../CommonDDL/ContractorNameDDL/ContractorNameDDL";
import { ContractorNameDDLAPI } from "../../../Redux/DDLSlice";
import { useDispatch, useSelector } from "react-redux";
import { ProjectSanctionDetailsDeleteAPI, ProjectSanctionDetailsExportTableDataAPI, ProjectSanctionDetailsTableDataAPI } from "../../../Redux/ProjectCreationAllocationSlice/ProjectSanctionDetailsSlice";
import { ConcateWorkAndWorkTypeDDL } from "../../../CommonDDL/ConcateWorkAndWorkTypeDDL";
import ContractorWorkPopUp from "./ContractorWorkPopUp";
import { ProjectSanctionDetailsSidebarExport } from "./ContractorWorkExport";
import ProjectNameNewDDL from "../../../CommonDDL/ProjectNameMilestoneDDL/ProjectNameNewDDL";
import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";

export default function ContractorWork() {
  const userDetails = useAuthState();
  const { UserID, token } = userDetails
  const dispatch = useDispatch();

  // ----------pagination-------------
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)
  const [CurrentPage, setCurrentPage] = useState(0)
  const [IsSearch, setIsSearch] = useState(false)
  const [IsClear, setIsClear] = useState(false)
  const [IsPost, setIsPost] = useState(false)
  const [isopen, setIsOpen] = useState(true);
  const YearID = localStorage.getItem("YearID")

  const [PopUpField, setPopUpField] = React.useState({
    addPopUp: false,
    deletePopUp: false,
    showViewPopup: false,
    popupFlag: '',
    popupBtn: "",
    apiFlag: "",
    rowData: ''
  })
  const [ProjectDDL, setProjectDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })
  const [ProjectTypeDDL, setProjectTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })

  const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "--Select--",
  })
  const [contractorNameDDL, setContractorNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })

  const [projectNameMilestoneDDL, setProjectNameMilestoneDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
    projectCode: '',
    numberOfMileStone: ''
  })

  const handleOnClearClick = () => {
    // setIsClear(!IsClear)
    setCurrentPage(0)
    setProjectDDL({
      ...ProjectDDL,
      ID: 0,
      Label: "---Select---",
    })
    setWorkAndWorkTypeDDL({
      ...WorkAndWorkTypeDDL,
      ID: 0,
      Label: "---Select---"
    })
    setContractorNameDDL({
      ...contractorNameDDL,
      ID: 0,
      Label: "---Select---"
    })
    setProjectNameMilestoneDDL({
      ...projectNameMilestoneDDL,
      ID: 0,
      Label: 'Select...'
    })
    setProjectTypeDDL({
      ...ProjectTypeDDL,
      ID: 0,
      Label: 'Select...'
    })
    setIsPost(!IsPost)

  }

  const handleAddCloseClick = () => {
    setPopUpField({ addPopUp: false, showViewPopup: false })
  }
  const handleOnViewClick = () => {
    setPopUpField({ showViewPopup: true })
  }

  const handleDeleteCloseClick = () => {
    setPopUpField({ deletePopUp: false })
  }
  const addButtonClick = () => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Add", popupBtn: "Clear", apiFlag: 'Insert', rowData: '' })
  }

  const editButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Update", popupBtn: "Cancel", apiFlag: 'Update', rowData: item })

  }

  const deleteButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: false, deletePopUp: true, popupBtn: "", apiFlag: 'Delete', rowData: item })
  }

  const handleDeleteData = () => {
    dispatch(ProjectSanctionDetailsDeleteAPI({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }))
  }

  const { isDeleteLoading } = useSelector(state => state.ProjectSanctionDetailsDeleteData)

  const handlePost = () => {
    setIsPost(!IsPost)
  }


  useEffect(() => {
    const data = {
      M_ContractorID: 0,
      M_ProjectID: projectNameMilestoneDDL.ID,
      Flag: 'ProjectWiseContractor',
      M_UsersID: UserID,
      token: token
    }
    dispatch(ContractorNameDDLAPI({ data }))
  }, [projectNameMilestoneDDL.ID])

  const { ContractorNameDDLData } = useSelector(state => state.ContractorNameDDLData)

  useEffect(() => {
    setCurrentPage(0)
    setFrom(1)
    setTo(10)

  }, [WorkAndWorkTypeDDL.ID, projectNameMilestoneDDL.ID, contractorNameDDL.ID])

  useEffect(() => {
    const data = {
      M_FinancialYearID: YearID,
      M_MonthID: 0,
      T_ProjectSanctionDetailsID: 0,
      M_ProjectID: projectNameMilestoneDDL.ID,
      M_WorkID: 0,
      M_WorkTypeID: WorkAndWorkTypeDDL.ID,
      M_ContractorID: contractorNameDDL.ID,
      M_ProjectTypeID: ProjectTypeDDL.ID,
      UserID: UserID,
      token: token,
      From: From,
      To: To,
      handleProjectSanctionExport: handleProjectSanctionExport
    }
    dispatch(ProjectSanctionDetailsTableDataAPI({ data }))
  }, [From, To, IsPost, IsClear, projectNameMilestoneDDL.ID, WorkAndWorkTypeDDL.ID, contractorNameDDL.ID, ProjectTypeDDL.ID])

  const { tableData, isLoading } = useSelector(state => state.ProjectSanctionDetailsTableData)

  const handleProjectSanctionExport = () => {
    const data = {
      M_FinancialYearID: YearID,
      M_MonthID: 0,
      T_ProjectSanctionDetailsID: 0,
      M_ProjectID: projectNameMilestoneDDL.ID,
      M_WorkID: 0,
      M_WorkTypeID: WorkAndWorkTypeDDL.ID,
      M_ContractorID: contractorNameDDL.ID,
      M_ProjectTypeID: ProjectTypeDDL.ID,
      UserID: UserID,
      token: token,
      From: From,
      To: '99999',
    }
    dispatch(ProjectSanctionDetailsExportTableDataAPI({ data }))
  }
  // , [From, To, IsPost, IsClear, ProjectDDL.ID, WorkAndWorkTypeDDL.ID, contractorNameDDL.ID])

  const { ExporttableData, isExportLoading } = useSelector(state => state.ProjectSanctionDetailsExportTableData)

  return (
    <>
      {isLoading && <LoaderFile />}
      {isDeleteLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="contractorwork" subMenu="subProCreation" isopen={isopen} />
          <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-6 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold"> Project Sanction Details</h4>
                    </div>
                    <div className="col-6 col-lg-3 mb-xl-0 py-2">
                      <h4 className="font-weight-bold float-right px-2 py-1 mr-3 btn-outline-count rounded">
                        Total Count :- <span className='text-danger px-2'>{tableData && tableData[0]?.totalCount ? tableData[0]?.totalCount : 0}</span>
                      </h4>
                    </div>
                    <div className="col-12 col-lg-3 mb-xl-0 align-self-center align-items-end text-right">
                      <button type="button" className="btn btn-primary btn-sm"
                        title="Add" onClick={() => addButtonClick()}>
                        <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                        Add
                      </button>
                      {
                        isExportLoading ?
                          <button type="button" className="btn btn-success btn-sm" disabled>
                            <i className="fa fa-refresh fa-spin" style={{ fontSize: "15px", marginRight: "5px" }}></i>
                            Loading..
                          </button>
                          :
                          tableData && tableData.length > 0 &&
                          <ProjectSanctionDetailsSidebarExport
                            ExportData={ExporttableData}
                            name='Project Sanction Details'
                          />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    {/* <div className="card filter mb-4"> */}
                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-12 col-md-6 col-lg-3">
                          <ProjectTypeDDLData
                            ProjectTypeDDL={ProjectTypeDDL}
                            setProjectTypeDDL={setProjectTypeDDL}
                            PopUpField={PopUpField}
                            mendetary='yes'
                          // IsPost={IsPost}
                          // PopUpField={PopUpField}

                          />
                        </div>
                        <div className=" col-12 col-md-6 col-lg-3">
                          <ConcateWorkAndWorkTypeDDL
                            WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                            setWorkAndWorkTypeDDL={setWorkAndWorkTypeDDL}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <ProjectNameNewDDL
                            projectNameMilestoneDDL={projectNameMilestoneDDL}
                            setProjectNameMilestoneDDL={setProjectNameMilestoneDDL}
                            // PopUpField={PopUpField}
                            WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                            projectTypeDDL={ProjectTypeDDL}
                            mendetary='no'
                            FlagName='ProjectSanctionDetails'
                          // setIsClear={setIsClear}
                          // IsClear={IsClear}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                          <ContractorNameDDL
                            contractorNameDDL={contractorNameDDL}
                            setContractorNameDDL={setContractorNameDDL}
                            DDLData={ContractorNameDDLData}
                            projectNameMilestoneDDL={projectNameMilestoneDDL}
                          // FlagName=''
                          />
                        </div>

                        <div className="col-12 col-lg-12">
                          <div className="float-right">
                            <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 waves-effect waves-light"
                              onClick={handleOnClearClick}>
                              <X size={18} style={{ marginRight: "3px" }} /> Clear
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ whiteSpace: 'nowrap' }}>Sr.No.</th>
                          <th className="th_width">Work Type</th>
                          <th style={{ whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em' }}>Project Name</th>
                          <th className="">Budget Provision (In Lacs)</th>
                          <th>Administrative Approval Cost (In Lacs)</th>
                          <th>Administrative Approval Date</th>
                          <th>Tender Stages</th>
                          <th>Date of Tender Floated</th>
                          <th>Tender Amount Type</th>
                          <th>Tender Amount (In Lacs)</th>
                          <th className="th_width">Contractor Name</th>
                          <th>Remark</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {

                          tableData && tableData.length > 0 ? tableData.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">{item.rowNum}</td>
                              <td>{item.workAndWorkType ? item.workAndWorkType : "-"}</td>
                              <td>{item.projectName ? item.projectName : "-"}</td>
                              <td className='text-right'>{item.budget_Provision ? item.budget_Provision.toFixed(2) : "-"}</td>
                              <td className='text-right'>{item.administrativeApproveCost ? item.administrativeApproveCost.toFixed(2) : "-"}</td>
                              <td>{item.administrativeApproveDate ? moment(item.administrativeApproveDate).format("DD-MM-YYYY") : "-"}</td>
                              <td>{item.tender_Stages ? item.tender_Stages : "-"}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{item.dateOfTenderFloated ? moment(item.dateOfTenderFloated).format("DD-MM-YYYY") : "-"}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{item.tender_Amount_Type ? item.tender_Amount_Type : "-"}</td>
                              <td className='text-right'>{item.tendarCost ? item.tendarCost.toFixed(2) : "-"}</td>
                              <td>{item.contractor_Name ? item.contractor_Name : "-"}</td>
                              <td>{item.projectRemark ? item.projectRemark : "-"}</td>

                              <td style={{ textAlign: "center" }}>

                                <span className=" mx-1 cursor-pointer"
                                  title="Edit" onClick={() => editButtonClick(item)}>
                                  <Edit size={15} color="#007bff" />
                                </span>

                                <span className="  mx-1 cursor-pointer"
                                  title="Delete" onClick={() => deleteButtonClick(item)}>
                                  <Trash2 size={15} color="red" />
                                </span>

                              </td>
                            </tr>
                          )) : <tr style={{ whiteSpace: 'nowrap' }}>No data</tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {PopUpField.addPopUp ? <ContractorWorkPopUp
        open={PopUpField.addPopUp}
        // handleOnClearClick={handleOnClearClick}
        PopUpField={PopUpField}
        handleAddCloseClick={handleAddCloseClick}
        IsPost={IsPost}
        setIsPost={setIsPost}
        handlePost={handlePost}
      /> : <></>}

      {PopUpField.showViewPopup ? <WorkAgencyViewPopUp
        open={PopUpField.showViewPopup}
        // handleOnCloseClick={handleOnCloseClick}
        handleAddCloseClick={handleAddCloseClick}
      /> : <></>}

      {PopUpField.deletePopUp ? <DeletePopUp
        open={PopUpField.deletePopUp}
        handleDeleteData={handleDeleteData}
        // deleteData={deleteData}
        handleOnCloseClick={handleDeleteCloseClick}
      />
        : <></>}

    </>
  )
}