import React, { useState } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import Select from "react-select";
import { useEffect } from "react";
import { useAuthState } from "../../../Helper/Context/context";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Get_M_Department_Select } from "../MasterApi/GETAPI";
import { Pegination } from "../../../Components/Pegination/Pegination";
import Footer from "../../../Components/Footer/Footer";
import WardMasterPop from "./WardMasterPop";
import ZoneDDL from "../../../CommonDDL/ZoneDDL/ZoneDDL";
import WardNameDDL from "../../../CommonDDL/WardNameDDL/WardNameDDL";
import { WardMasterDeleteAPI, WardMasterTableDataAPI } from "../../../Redux/MasterSlice/WardMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { WardNameDDLAPI, ZoneDDLAPI } from "../../../Redux/DDLSlice";

export default function WardMaster() {
  const userDetails = useAuthState();
  const { DepartmentID, UserID, token } = userDetails;
  const dispatch = useDispatch();

  const [showCreatePopup, setshowCreatePopup] = useState(false);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [flag, setflag] = useState(false);
  const [isopen, setIsOpen] = useState(true);
  const [deptOfficeTypeIndicatorID, setdeptOfficeTypeIndicatorID] = useState(1);

  // react pagination states
  const [PerPageCount, setPerPageCount] = useState(10);
  const [TotalCount, setTotalCount] = useState(0);
  const [To, setTo] = useState(10);
  const [From, setFrom] = useState(1);
  const [rowNo, setrowNo] = useState(1);

  // department Api states
  const [departmentTable, setdepartmentTable] = useState([]);
  const [apiFlag, setapiFlag] = useState();
  const [IsPost, setIsPost] = useState(false);
  const [editData, seteditData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [loading, setloading] = useState();
  const [CurrentPage, setCurrentPage] = useState(0);

  const [departmentTextField, setdepartmentTextField] = useState({
    departmentName: "",
  });

  const { departmentName } = departmentTextField;

  const handleInputChange = (e) => {
    setdepartmentTextField({
      ...departmentTextField,
      [e.target.name]: e.target.value,
    });
  };

  const [designationDDL, setdesignationDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });
  const [zoneDDL, setZoneDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });
  const [wardNameDDL, setWardNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });

  // console.log(zoneDDL.ID)

  useEffect(() => {
    const data = { UserID, token }
    dispatch(ZoneDDLAPI({ data }))
  }, [])

  const { ZoneDDLData } = useSelector(state => state.ZoneDDLData)

  useEffect(() => {
    const data = {
      UserID: UserID,
      token: token,
      M_WardID: wardNameDDL.ID,
      zoneDDL: zoneDDL.ID
    }
    dispatch(WardNameDDLAPI({ data }))
  }, [zoneDDL.ID])

  const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

  useEffect(() => {
    setCurrentPage(0)
    setFrom(1)
    setTo(10)
  }, [zoneDDL.ID, wardNameDDL.ID])

  useEffect(() => {
    const data = {
      M_WardID: wardNameDDL.ID,
      M_ZoneID: zoneDDL.ID,
      M_UsersID: UserID,
      WardName: '',
      From: From,
      token: token,
      To: To,
    }
    dispatch(WardMasterTableDataAPI({ data }))
  }, [From,IsPost, zoneDDL.ID, wardNameDDL.ID])


  const { tableData, isLoading } = useSelector(state => state.WardMasterTableData)

  const [designationTable, setdesignationTable] = useState([]);

  const handleOnCloseClick = () => {
    setshowCreatePopup(false);
    setshowDeletePopup(false);
  };
  const handleAddNewClick = () => {
    setshowCreatePopup(true);
    setflag("Add");
    setapiFlag("Insert");
  };
  const handleOnEditClick = (item) => {
    setshowCreatePopup(true);
    setflag("Update");
    setapiFlag("Update");
    seteditData(item);
  };
  const handleOnDeleteClick = (item) => {
    // console.log(item)
    setshowDeletePopup(true);
    setflag("Delete");
    setDeleteData(item);
  };

  const handleOnSearchClick = () => {
    setIsPost(!IsPost);
    setCurrentPage(0);
  };

  const handleOnClearClick = () => {
    setCurrentPage(0);
    setZoneDDL({
      ...zoneDDL,
      ID: 0,
      Label: 'Select...'
    })
    setWardNameDDL({
      ...wardNameDDL,
      ID: 0,
      Label: 'Select...'
    })
  };

  const handlePost = () => {
    setIsPost(!IsPost)
  }

  const handleDeleteData = () => {

    const data = {
      M_WardID: deleteData.m_WardID,
      UserID: UserID,
      handlePost: handlePost,
      token: token,
      handleDeleteCloseClick: handleOnCloseClick,
    }
    dispatch(WardMasterDeleteAPI({ data }))
  };

  return (
    <>
      {isLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="wardmaster" subMenu="subMaster" isopen={isopen} />

          {/* <!-- Main Container --> */}
          <div
            className="main-panel"
            style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
          >
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">Ward Master</h4>
                    </div>
                    <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        title="Add"
                        onClick={() => handleAddNewClick()}
                      >
                        <i
                          className="fa-solid fa-circle-plus pr-2"
                          style={{ fontSize: "15px" }}
                        ></i>
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    {/* <div className="card filter mb-4"> */}
                    <div className="card-body">
                      <div className="form-row">
                        <div className="col-12 col-md-6 col-lg-3">
                          <ZoneDDL
                            zoneDDL={zoneDDL}
                            setZoneDDL={setZoneDDL}
                            DDLData={ZoneDDLData}
                            FlagName=''
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                          <WardNameDDL
                            wardNameDDL={wardNameDDL}
                            setWardNameDDL={setWardNameDDL}
                            DDLData={WardNameDDLData}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-lg-2 pl-lg-3">
                          <button type="button"
                            className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick} >

                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      className="table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th className="col-5" style={{ textAlign: "center" }}>
                            Zone{" "}
                          </th>
                          <th className="col-5" style={{ textAlign: "center" }}>
                            Ward Name
                          </th>
                          <th style={{ width: "8%",textAlign: "center" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                            <tr>
                              <td className="text_center">{item.rowNum}</td>
                              <td>{item.m_ZoneName ? item.m_ZoneName : '-'}</td>
                              <td>{item.wardName ? item.wardName : '-'}</td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className="mx-2 cursor-pointer"
                                  title="Edit"
                                  onClick={() => handleOnEditClick(item)}
                                >
                                  <Edit size={15} color="#007bff" />
                                </span>

                                <span
                                  className="mx-2 cursor-pointer"
                                  title="Delete"
                                  onClick={() =>
                                    // console.log(item)
                                    handleOnDeleteClick(item)
                                  }
                                >
                                  <Trash2 size={15} color="red" />
                                </span>
                              </td>
                            </tr>
                          )) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.table && tableData.table.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData.table[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {showCreatePopup ? (
        <WardMasterPop
          open={showCreatePopup}
          handleOnClearClick={handleOnClearClick}
          handleOnCloseClick={handleOnCloseClick}
          deptOfficeTypeIndicatorID={deptOfficeTypeIndicatorID}
          apiFlag={apiFlag}
          IsPost={IsPost}
          editData={editData}
          setIsPost={setIsPost}
          flag={flag}
        />
      ) : (
        <></>
      )}
      {showDeletePopup ? (
        <DeletePopUp
          open={showDeletePopup}
          handleDeleteData={handleDeleteData}
          deleteData={deleteData}
          handleOnCloseClick={handleOnCloseClick}
        />
      ) : (
        <></>
      )}
    </>
  );
}
