
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const SummaryOfProjectReportExport = (props) => {
    const { ExportData, name, title, projectType } = props

    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportUser"
                className="btn btn-success float-end download-table-xls-button float-end btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-ExportUser" style={{ display: 'none', width: '50%' }}>

                <h5><span>&emsp;</span></h5>

                <tr>
                    <th colSpan={14} style={{ fontSize: 16, textAlign: 'center' }}>Report Name :- {name}</th>
                </tr>
                <tr>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>From Date :- </th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>To Date:-  </th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Department Name :-</th>
                </tr>
                <tr>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Sub Department Name :-</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Project Type :-</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Project Name :- </th>
                </tr>
                <tr>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Ward Name :- </th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Electoral Ward Number :-</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Project Priority :-</th>
                </tr>
                <tr>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Key Project :-</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Tender Amount Type :- </th>
                </tr>
                {/* <tr><td></td></tr> */}

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#254462', color: "#fff" }}>
                        <th style={{ width: "5%", borderRight: '1px solid white' }} >Sr.No.</th>
                        <th>Amount Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Description</th>
                        <th style={{ borderLeft: "1px solid white" }}>Airoli</th>
                        <th style={{ borderLeft: "1px solid white" }}>Belapur</th>
                        <th style={{ borderLeft: "1px solid white" }}>Digha</th>
                        <th style={{ borderLeft: "1px solid white" }}>Ghansoli</th>
                        <th style={{ borderLeft: "1px solid white" }}>Koparkhairane</th>
                        <th style={{ borderLeft: "1px solid white" }}>Nerul</th>
                        <th style={{ borderLeft: "1px solid white" }}>Turbhe</th>
                        <th style={{ borderLeft: "1px solid white" }}>Vashi</th>
                        <th style={{ borderLeft: "1px solid white" }}>Morbe</th>
                        <th style={{ borderLeft: "1px solid white" }}>MIDC</th>
                        <th style={{ borderLeft: "1px solid white" }}>Total</th>

                    </tr>
                </thead>
                <tbody>
                    {/* {
                        ExportData && ExportData.length > 0 ? ExportData.map((item, i) => ( */}
                    <tr style={{ textAlign: "center", border: '1px solid black' }}>
                        <td className="text_center"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>
                    {/* )) : <tr>No data</tr>
                    } */}
                </tbody>
            </table>
        </div>
    )
}