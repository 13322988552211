import React, { useEffect, useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useAuthState } from "../../../Helper/Context/context";
import { useDispatch, useSelector } from "react-redux";
import { CompletedMileStoneViewPopupTableDataAPI } from "../../../Redux/ProjectCreationAllocationSlice/PhysicalWorkProgressSlice";
import LoaderFile from "../../../Helper/Loader/LoaderFile";

export default function PhysicalWorkProgressViewPopUp({ flag, open, handleAddCloseClick, dataList }) {

	const userDetails = useAuthState();
	const { UserID, token } = userDetails
	const dispatch = useDispatch();

	const handleOpenImage = (data) => {
		window.open(data)
	}

	const [To, setTo] = useState(10000)

	useEffect(() => {
		const data = {
			M_WardID: 0,
			T_ProjectSanctionDetailsID: dataList.t_ProjectSanctionDetailsID,
			M_ProjectID: 0,
			Flag: 'CompleteWork',
			M_UsersID: UserID,
			token: token,
			Top: To,
		}
		dispatch(CompletedMileStoneViewPopupTableDataAPI({ data }))
	}, [])

	const { tableData, isLoading } = useSelector(state => state.CompletedMileStoneViewPopupTableData)
	return (

		<div>
			{isLoading && <LoaderFile />}
			<Popup open={open} closeOnDocumentClick onClose={handleAddCloseClick}>
				<span className="close" onClick={handleAddCloseClick}>
					&times;
				</span>
				<div className="call"> Milestone View Gallery </div>
				<div className="modal-body">
					<div className="tbl-sticky">
						<div id='table-scroll'>
							<table cellpadding="0" cellspacing="0" border="0" className="table table-bordered">
								<thead style={{ zIndex: '9999', position: 'relative' }}>
									<tr >
										<th>Sr.No.</th>
										<th>Milestone Name </th>
										<th>Before Photo</th>
										<th>After Photo</th>
									</tr>
								</thead>
								<tbody>
									{
										tableData && tableData.length > 0 ? tableData.map((item, i) => (
											<tr key={i}>
												<td className="text_center">{item?.rowNum}</td>
												<td>{item?.milestone_Name ? item?.milestone_Name : "-"}</td>
												<td>
													<button className="btn p-0 mrb50" id="dynamic" onClick={() => handleOpenImage(item?.beforePhotoPath)}>
														<div class="container" style={{ zIndex: '0' }}>
															<img src={item?.beforePhotoPath ? item?.beforePhotoPath : '-'} alt="" className="img-thumbnail image" style={{ width: "150px", height: '110px' }} />
															<div class="overlay">
																<div class="text">View Image</div>
															</div>
														</div>
													</button>
												</td>
												<td>
													<button className="btn p-0 mrb50" id="dynamic" onClick={() => handleOpenImage(item?.afterPhoto)}>
														<div class="container">
															<img src={item?.afterPhoto ? item?.afterPhoto : '-'} alt="" className="img-thumbnail image" style={{ width: "150px", height: '110px' }} />
															<div class="overlay">
																<div class="text">View Image</div>
															</div>
														</div>
													</button>
												</td>
											</tr>
										)) : <tr>No data</tr>
									}
								</tbody>
							</table>
						</div>
					</div>

				</div>


			</Popup>
		</div>


	)
}