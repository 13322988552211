import React, { useState } from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";

export default function FinancialWorkProgressPopUp({ flag, open, handleOnCloseClick }) {

  const [FinancilTextField, setFinancilTextField] = useState(
    {
      WorkAmount: "",
      administrativeApprovalCost:"",
      spillOver:"",
      administrativeApprovalDate:"",
      WorkDoneDate:"",
      incurredCost:"",     
      workDoneGeo: "",
    }
  )

  const [ProjectNameDDL, setProjectNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [contractorNameDDL, setcontractorNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [workNameDDL, setworkNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const[workAreaNameDDL,setworkAreaNameDDL] =useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [mileStoneDDL, setmileStoneDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })


  const [projectStatusDDL, setprojectStatusDDL] = useState({
    DDL: [
      { value: 'Ongoing', label: 'Ongoing' },
      { value: 'Completed', label: 'Completed' },
    ],
    ID: 0,
    Label: "---Select---"
  })



  const handleInputChange = (e) => {
    setFinancilTextField({ ...FinancilTextField, [e.target.name]: e.target.value })
  }

  const handleClearField = () => {
    setFinancilTextField(
      {
        workCompleteDate: "",
        financialProgress: "",
        workDoneGeo: "",
      }
    )
  }


  return (

    <div>
      <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
        <span className="close" onClick={handleOnCloseClick}>
          &times;
        </span>
        <div className="call"> Add Financial Work Progress</div>
        <div className="modal-body react_popup">
          <div className="row details-row">

          <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" for="NameofDepartment">Project ERP Code </label>
                <input
                  className="form-control"
                  id="administrativeApprovalCost"
                  type="text"
                  name="administrativeApprovalCost"
                  value={FinancilTextField.administrativeApprovalCost}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" for="NameofDepartment">Project Name </label>
                <input
                  className="form-control"
                  id="administrativeApprovalCost"
                  type="text"
                  name="administrativeApprovalCost"
                  value={FinancilTextField.administrativeApprovalCost}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" for="NameofDepartment">Contractor Name  </label>
                <input
                  className="form-control"
                  id="administrativeApprovalCost"
                  type="text"
                  name="administrativeApprovalCost"
                  value={FinancilTextField.administrativeApprovalCost}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" for="NameofDepartment">Tender Amount (Lacs)   </label>
                <input
                  className="form-control"
                  id="administrativeApprovalCost"
                  type="text"
                  name="administrativeApprovalCost"
                  value={FinancilTextField.administrativeApprovalCost}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" for="NameofDepartment">Amount Paid (lacs)</label>
                <input
                  className="form-control"
                  id="administrativeApprovalCost"
                  type="text"
                  name="administrativeApprovalCost"
                  value={FinancilTextField.administrativeApprovalCost}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" for="NameofDepartment">Project Status </label>
                <input
                  className="form-control"
                  id="administrativeApprovalCost"
                  type="text"
                  name="administrativeApprovalCost"
                  value={FinancilTextField.administrativeApprovalCost}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" for="NameofDepartment">Financial Status (%) </label>
                <input
                  className="form-control"
                  id="administrativeApprovalCost"
                  type="text"
                  name="administrativeApprovalCost"
                  value={FinancilTextField.administrativeApprovalCost}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" for="NameofDepartment">Department Remark </label>
                <input
                  className="form-control"
                  id="administrativeApprovalCost"
                  type="text"
                  name="administrativeApprovalCost"
                  value={FinancilTextField.administrativeApprovalCost}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>


            <div className="col-12 col-lg-12">
              <div className="btn-action">
                <button type="button" className="btn btn-md btn-clear mx-2 float-right"><X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                <button
                  type="button"
                  className="btn btn-md btn-primary mx-2 float-right"
                  onClick={() => console.log(FinancilTextField)}
                >{flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
              </div>
            </div>
          </div>
        </div>


      </Popup>
    </div>
  )
}