
import React, { useState } from 'react'
import { TrendingDown, TrendingUp } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import ProjectDetailsCard from './ProjectLeftCard/ProjectDetailsCard'
import ProjectLeftCard from './ProjectLeftCard/ProjectLeftCard'
import Costingcard from './ProjectRightCard/Costingcard'
import ProjectRightCard from './ProjectRightCard/ProjectRightCard'
import ProjectDetailsPie from '../PieChart/ProjectDetailsPie/ProjectDetailsPie'
import { Pie } from 'react-chartjs-2'


const Project = (props) => {
    const { Countdata } = props

    const data = {
        labels: ['Proposed', 'On Going', 'Completed'],
        datasets: [
            {
                data: [
                    Countdata.ProposedProject ? Countdata.ProposedProject : '0',
                    Countdata.OngoingProject ? Countdata.OngoingProject : '0',
                    Countdata.CompletedProject ? Countdata.CompletedProject : '0'
                ],
                backgroundColor: [
                    '#17a2b8',
                    '#024976',
                    '#f79327',
                    // '#ff3547',
                ],
                borderColor: [
                    '#fff',
                    '#fff',
                    '#fff',
                    // '#fff'
                ],
                borderWidth: 2,
            },
        ],
    };

    return (
        <div className="row mt-4">
            <div className="col-12 col-lg-6  ">
                <div className="card h-100 mb-0">

                    <div className="card-header font-weight-bold">
                        Project Details
                    </div>

                    <div className="card-body icon-col">
                        <div className="tab-content w-100 gray-bg" role="tab-container" id="myTabContent">

                            <div className="tab-pane fade show active" id="Capital" role="tabpanel" aria-labelledby="Capital-tab">
                                <div className="form-row">

                                    <div className="col-12 col-lg-6 col-md-6">
                                        <ProjectDetailsCard
                                            path="/DashboardProjectDetails"
                                            title="Proposed Project"
                                            icon={<TrendingUp />}
                                            iconClass="text-success"
                                            textClass="situation text-success pl-2"
                                            count={Countdata.ProposedProject ? Countdata.ProposedProject : '0'}
                                            status="Over Last Month"
                                            percentage="+5.2%"
                                            cardName='Proposed'
                                            Flag='ProposedProject'
                                        />
                                    </div>

                                    <div className="col-12 col-lg-6 col-md-6">
                                        <ProjectDetailsCard

                                            path="/DashboardProjectDetails"
                                            title="OnGoing Project"
                                            icon={<TrendingDown />}
                                            iconClass="text-danger"
                                            textClass="situation text-danger pl-2"
                                            count={Countdata.OngoingProject ? Countdata.OngoingProject : '0'}
                                            status="Over Last Month"
                                            percentage="+5.2%"
                                            cardName='OnGoing'
                                            Flag='OnGoingProject'
                                        />
                                    </div>

                                    <div className="col-12 col-lg-6 col-md-6">
                                        <ProjectDetailsCard
                                            path="/DashboardProjectDetails"
                                            title="Completed Project"
                                            icon={<TrendingUp />}
                                            iconClass="text-success"
                                            textClass="situation text-success pl-2"
                                            count={Countdata.CompletedProject ? Countdata.CompletedProject : '0'}
                                            status="Over Last Month"
                                            percentage="+5.2%"
                                            cardName='Completed'
                                            Flag='CompletedProject'
                                        />
                                    </div>

                                    {/* <div className="col-12 col-lg-6 col-md-6">
                                        <ProjectDetailsCard
                                            path="/DashboardProjectDetails"
                                            title="Cancelled Project"
                                            icon={<TrendingUp />}
                                            iconClass="text-success"
                                            textClass="situation text-success pl-2"
                                            count="10"
                                            status="Over Last Month"
                                            percentage="+5.2%"
                                            cardName='Cancelled'
                                        />
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-12 col-lg-6 ">
                {/* <ProjectDetailsPie /> */}
                <div >
                    <div className="card mb-0">
                        <div className="card-header font-weight-bold">Project Details</div>
                        <div className="card-body">
                            <div className="tab-content w-100 gray-bg p-5" role="tab-container" id="myTabContent">
                                {/* <Pie style={{maxHeight:"250px"}} data={data} /> */}
                                {
                                    Countdata && Countdata.ProposedProject !== 0 || Countdata && Countdata.OngoingProject !== 0
                                        || Countdata && Countdata.CompletedProject !== 0 ?
                                        <Pie data={data} 
                                        height='200px'
                                        // style={{ maxHeight: "250px" }} 
                                        />
                                        :
                                        <h4 className='fw-bold text-danger text-center'>No Data Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Project
