import React, { useState } from 'react'
import { Calendar } from 'react-feather';
import DashboardDetails from '../../Pages/DashboardCard/DashboardDetails/DashboardDetails';
import FinancialPending from '../../Pages/DashboardCard/FinancialPendingSection/FinancialPending';
import PhysicalSpillOver from '../../Pages/DashboardCard/PhysicalSpillOver/PhysicalSpillOver';
import Project from '../../Pages/DashboardCard/Project/Project';
import Sidebar from '../Sidebar/Sidebar';

import Header from '../Header/Header'
import MobileHeader from '../Header/MobileHeader';
import ProjectWiseWorkStatus from '../../Pages/DashboardCard/ProjectWiseWorkStatus/ProjectWiseWorkStatus';
import ProjectOverview from '../../Pages/DashboardCard/ProjectOverview/ProjectOverview';
import { RoleWiseFormTableDataAPI } from '../../Redux/UserMasterSlice/RoleWiseFormSlice';
import { useEffect } from 'react';
import RatingsWorkUtilization from '../../Pages/DashboardCard/RatingsWorkUtilization/RatingsWorkUtilization';
import { FinancialYearDDL } from '../../CommonDDL/FinancialYearDDL/FinancialYearDDL';
import { useAuthState, useAuthStateYearMonth } from '../../Helper/Context/context';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardCountDataAPI, WardWiseProjectCountDataAPI } from '../../Redux/DashboardSlice/DashBoardCountSlice/DashBoardCountSlice';
import { DashBoardMonthDDLData } from '../../CommonDDL/MonthDDL/MonthDDL';
import LoaderFile from '../../Helper/Loader/LoaderFile';


export default function Dashboard({ props }) {
    const today = new Date();

    const [isopen, setIsOpen] = useState(true);

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const YearMonthDetails = useAuthStateYearMonth()
    const { year, setYear, Month, setMonth } = YearMonthDetails

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    const dispatch = useDispatch()

    const dateOptions = [
        { value: '1', label: '2021-2022' },
        { value: '2', label: '2020-2021' },
        { value: '3', label: '2019-2020' }
    ]

    const [FinancialDDL, setFinancialDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [DashBoardMonthDDL, setDashBoardMonthDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Month..."
    })

    const [Countdata, setCountdata] = useState('')
    // const [From, set] = useState('')
    const { FinancialData } = useSelector((state) => state.FinancialDDLData);
    const { DashboardMonthData } = useSelector(state => state.DashboardMonthDDLData)

    useEffect(() => {
        const data = {
            // M_FinancialYearID: FinancialDDL.ID ? FinancialDDL.ID : 0,
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: DashBoardMonthDDL.ID ? DashBoardMonthDDL.ID : 0,
            M_UsersID: UserID,
            Flag: 'WEB',
            ShowBy: '',
            token: token,
            handleGetCount: handleGetCount
        }
        {
            YearID &&
                dispatch(DashboardCountDataAPI({ data }))
        }
    }, [YearID, DashBoardMonthDDL.ID])

    const { isLoading } = useSelector(state => state.DashboardCountData)

    const handleGetCount = (data) => {
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }

    return (
        <div className="wrapper">
            {isLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="dashboard" isopen={isopen} />
                    <div
                        className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            {/* <DashboardHeading/> */}
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">

                                        <div className="col-12 col-lg-5 col-md-12 mb-xl-0">
                                            <h4 className="font-weight-bold"> Dashboard</h4>
                                            <p className="p-0">Welcome to PTMS</p>
                                        </div>
                                        <div className="col-6 col-lg-4 col-md-6 mb-xl-0">
                                            <FinancialYearDDL
                                                setYear={setYear}
                                                Year={year}
                                                FinancialDDL={FinancialDDL}
                                                setFinancialDDL={setFinancialDDL}
                                            />
                                        </div>
                                        <div className="col-6 col-lg-3 col-md-6 mb-xl-0">
                                            <DashBoardMonthDDLData
                                                Month={Month}
                                                setMonth={setMonth}
                                                DashBoardMonthDDL={DashBoardMonthDDL}
                                                setDashBoardMonthDDL={setDashBoardMonthDDL}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row dashboard">
                                <div className="col-12">
                                    <DashboardDetails
                                        Countdata={Countdata}
                                    />
                                    <Project
                                        Countdata={Countdata}
                                    />
                                    {/* <PieChart /> */}
                                    <PhysicalSpillOver
                                        FinancialDDL={FinancialDDL}
                                        DashBoardMonthDDL={DashBoardMonthDDL}
                                    />
                                    {/* <ProjectWiseWorkStatus /> */}
                                    <FinancialPending
                                        Countdata={Countdata}
                                    />
                                    <ProjectOverview
                                        Countdata={Countdata}
                                    />
                                    {/* <PanCityProjectTable />
                                    <DepartmentTable /> */}

                                    {/* <RatingsWorkUtilization /> */}

                                </div>
                            </div>
                            <div className="copyright">
                                Copyright {today.getFullYear()}. all rights are reserved.<b>Version 1.0</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
