
// import Select from "react-select"
// import { Star } from "../../Components/CommoComponent/Star"
// import { useEffect, useState } from "react"

// export default function MultiElectoralNumDDL(props) {
//     const { electoralWardNumDDL, setSelectedOptions, selectedOptions, setM_WardWiseNumber, M_WardWiseNumber, setM_WardWiseNumberID, setElectoralWardNumDDL, wardNameDDL, DDLData, editData, FlagName, apiFlag, mendetary } = props

//     // console.log(editData.m_WardWiseNumberID,editData.wardNumber)

//     useEffect(() => {
//         handleElectoralWardNumDDL()
//     }, [DDLData])

//     const handleElectoralWardNumDDL = () => {
//         if (DDLData && DDLData.table && DDLData.table.length > 0) {
//             let list = DDLData.table.map((item, index) => ({
//                 value: item.id,
//                 label: item.wardNumber,
//             }))
//             if (apiFlag === 'Update') {
//                 setElectoralWardNumDDL({
//                     DDL: list,
//                     ID: apiFlag === 'Update' ? editData.m_WardWiseNumberID : 0,
//                     Label: apiFlag === 'Update' ? parseInt(editData.wardNumber) : "---Select---"
//                 })
//             }
//             if (apiFlag !== 'Update') {
//                 setElectoralWardNumDDL({
//                     DDL: list,
//                     ID: 0,
//                     Label: "---Select---"
//                 })
//             }
//         }
//         else {
//             setElectoralWardNumDDL({
//                 DDL: [],
//                 ID: 0,
//                 Label: "Select...",
//             })
//         }
//     }

//     var ID = []
//     var Label = []

//     const handleSelect = (data) => {
//         console.log(data)
//         setSelectedOptions(data);
//         data.forEach((item) => {
//             Label.push(item.label)
//             ID.push(item.value)
//             return item
//         })
//         setM_WardWiseNumber(Label)
//         setM_WardWiseNumberID(ID)
//     }
//     // console.log('setM_WardWiseNumberID',M_WardWiseNumberID && M_WardWiseNumberID)
//     return (
//         <>
//             <div className="form-group">
//                 <label className="d-block" htmlFor="Budgettype">Electoral Ward Number {mendetary === 'yes' ? <Star /> : ''} </label>
//                 <Select
//                     options={electoralWardNumDDL.DDL}
//                     placeholder="---Select---"

//                     value={selectedOptions}
//                     onChange={handleSelect}
//                     maxMenuHeight={140}
//                     isClearable
//                     isSearchable
//                     isMulti
//                     theme={theme => ({
//                         ...theme,
//                         colors: {
//                             ...theme.colors,
//                             neutral50: '#333333',  // Placeholder color

//                         },
//                     })}
//                 />

//             </div>
//         </>
//     )
// }


import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect, useState } from "react"

export default function MultiElectoralNumDDL(props) {
    const { electoralWardNumDDL, setSelectedOptions, selectedOptions, setM_WardWiseNumber, M_WardWiseNumber, M_WardWiseNumberID, setM_WardWiseNumberID,
        setElectoralWardNumDDL, wardNameDDL, DDLData, editData, FlagName, apiFlag, mendetary } = props

    useEffect(() => {
        handleElectoralWardNumDDL()
    }, [DDLData])

    const handleElectoralWardNumDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {
            let list = DDLData.table.map((item, index) => ({
                value: item.id,
                label: item.wardNumber,
            }))
            if (wardNameDDL && editData && (wardNameDDL.ID != editData.m_WardID)) {
                // if (apiFlag === 'Update') {
                setElectoralWardNumDDL({
                    DDL: list,
                    ID: apiFlag == 'Update' ? (editData.m_WardWiseNumberID && editData.m_WardWiseNumberID.split(',')) : 0,
                    Label: apiFlag == 'Update' ? (editData.wardNumber && editData.wardNumber.split(',')) : "---Select---"
                    // ID: [2, 4],
                    // Label: [7, 10]
                })
            } else {
                setElectoralWardNumDDL({
                    DDL: list,
                    ID: apiFlag == 'Update' ? (editData.m_WardWiseNumberID && editData.m_WardWiseNumberID.split(',')) : 0,
                    Label: apiFlag == 'Update' ? (editData.wardNumber && editData.wardNumber.split(',')) : "---Select---"
                })
            }
            if (apiFlag == 'Update' && editData) {
                const selected = list.filter(option => editData.m_WardWiseNumberID.split(',').includes(option.value.toString()));
                setSelectedOptions(selected);  
                const labels = selected.map(item => item.label);
                const ids = selected.map(item => item.value);
                setM_WardWiseNumber(labels); 
                setM_WardWiseNumberID(ids); 
            }
        }
        else {
            setElectoralWardNumDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }
    // console.log("ElectoralWardNumDDL546", electoralWardNumDDL)
    // console.log("electoralWardNumDDL", electoralWardNumDDL.ID, electoralWardNumDDL.Label)
    // console.log("M_WardWiseNumberID", M_WardWiseNumberID, M_WardWiseNumber)

    var ID = []
    var Label = []

    // const handleSelect = (data) => {
    //     console.log(data)
    //     setSelectedOptions(data);
    //     data.forEach((item) => {
    //         Label.push(item.label)
    //         ID.push(item.value)
    //         return item
    //     })
    //     setM_WardWiseNumber(Label)
    //     setM_WardWiseNumberID(ID)
    // }

    const handleSelect = (data) => {
        setSelectedOptions(data); 
        const labels = data.map(item => item.label);  
        const ids = data.map(item => item.value);   
        setM_WardWiseNumber(labels);  
        setM_WardWiseNumberID(ids);  
    };
    // console.log('selectedOptions', selectedOptions.value, selectedOptions.label)

    // console.log('M_WardWiseNumberID', M_WardWiseNumberID.ID, M_WardWiseNumber.Label)

    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Electoral Ward Number {mendetary === 'yes' ? <Star /> : ''} </label>
                <Select
                    options={electoralWardNumDDL.DDL}
                    placeholder="---Select---"
                    value={selectedOptions}
                    onChange={handleSelect}
                    maxMenuHeight={140}
                    isClearable
                    isSearchable
                    isMulti
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            neutral50: '#333333',  // Placeholder color
                        },
                    })}
                />

            </div>
        </>
    )
}



