import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Select from "react-select"
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_M_BudgetType } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Budget_InserUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import BudgetTypeDDL from "../../../CommonDDL/BudgetType/BudgetTypeDDL";
import { BudgetTypeDDLAPI } from "../../../Redux/DDLSlice";
import { useDispatch, useSelector } from "react-redux";
import { BudgetMasterPostAPI } from "../../../Redux/MasterSlice/BudgetMasterSlice";

export default function BudgetPopUp({ flag, open, handleOnCloseClick, apiFlag, IsPost, setIsPost, editData, handleOnClearClick }) {


    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch();

    const [budgetTextField, setbudgetTextField] = useState(
        {
            budgetHeadName: apiFlag === "Update" ? editData.budgetName : "",
            budgetCode: apiFlag === "Update" ? editData.budgetCode : "",
        }
    )

    const [budgetTypeDDL, setBudgetTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_BudgetTypeID : 0,
        Label: apiFlag === "Update" ? editData.budgetTypeName : "---Select---",
    })

    useEffect(() => {
        const data = { UserID, token }
        dispatch(BudgetTypeDDLAPI({ data }))
    }, [])

    const { BudgetTypeDDLData } = useSelector(state => state.BudgetTypeDDLData)

    const handleInputChange = (e) => {
        setbudgetTextField({ ...budgetTextField, [e.target.name]: e.target.value })
    }

    //  ------------------------Validation ----------------

    const [formErrors, setformErrors] = useState({
        budgetHeadNameError: "",
        budgetCodeError: ""

    })
    const checkTextOnlyFields = (text, errorField, errorValue) => {
        let regex = new RegExp(/^[a-zA-Z ]*$/)
        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, [errorField]: "" })
        } else {
            setformErrors({ ...formErrors, [errorField]: errorValue })

        }
        // let regex = new RegExp(/^[a-zA-Z ]*$/)
        // // let regex = new RegExp(/^[A-Za-z\s\U+O9OO-U+O97F]+$/)
        // // let regex = new RegExp(/^[A-Za-z\s]*$/)
        // if (regex.test(text) || text === "") {
        //     setformErrors({ ...formErrors, [errorField]: errorValue })
        // } else {
        //     setformErrors({ ...formErrors, [errorField]: "" })

        // }
    }

    const checkAlphaNumericFields = (text, errorField, errorValue) => {
        // let regex = new RegExp(/^[0-9a-zA-Z(\-)]+$/)
        let regex = new RegExp(/^[ A-Za-z0-9_@.#&+-]*$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, [errorField]: "" })
        } else {
            setformErrors({ ...formErrors, [errorField]: errorValue })

        }
    }

    const handleClearField = () => {
        setbudgetTextField(
            {
                projectType: "",
                projectCode: "",
            }
        )
        setBudgetTypeDDL({
            DDL: [],
            ID: 0,
            Label: ""
        })
    }





    const Add = () => {
        const data = {
            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost,
            handleOnClearClick: handleOnClearClick,
            M_BudgetID: apiFlag === 'Update' ? editData.id : 0,
            M_BudgetTypeID: budgetTypeDDL.ID,
            BudgetName: budgetTextField.budgetHeadName,
            BudgetCode: budgetTextField.budgetCode,
            UserID: UserID,
            token: token,
            Flag: apiFlag,
        }
        dispatch(BudgetMasterPostAPI({ data }))
    }

    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call font-weight-bold"> Add Budget </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <BudgetTypeDDL
                                budgetTypeDDL={budgetTypeDDL}
                                setBudgetTypeDDL={setBudgetTypeDDL}
                                DDLData={BudgetTypeDDLData}
                                editData={editData}
                                apiFlag={apiFlag}
                                mendetary='yes'
                                FlagName=''
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="budgetHeadName">Name of Budget Head <Star /> </label>
                                <input
                                    className="form-control"
                                    id="budgetHeadName"
                                    type="text"
                                    name="budgetHeadName"
                                    value={budgetTextField.budgetHeadName}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        // checkTextOnlyFields(e.target.value, "budgetHeadNameError", "Invalid Name of Budge Head ")
                                    }}
                                />
                                {/* <span className="text-danger error-danger">{formErrors.budgetHeadNameError}</span> */}
                            </div>
                        </div>

                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="budgetCode">Budget Code <Star /> </label>
                                <input
                                    className="form-control"
                                    id="budgetCode"
                                    type="text"
                                    name="budgetCode"
                                    value={budgetTextField.budgetCode}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        // checkAlphaNumericFields(e.target.value, "budgetCodeError", "Invalid Budget Code")
                                    }}
                                />
                                <span className="text-danger error-danger">{formErrors.budgetCodeError}</span>
                            </div>
                        </div>

                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}>
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        budgetTypeDDL.ID === 0 ||
                                        budgetTextField.budgetHeadName === "" ||
                                        budgetTextField.budgetCode === "" ||
                                        formErrors.budgetHeadNameError === "Invalid Name of Budge Head "


                                    }
                                >
                                    {/* {flag === "Update" ? <Edit2 size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
                               
                                > */}
                                    {flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}