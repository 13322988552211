import React, { useEffect, useState } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import ContractorPopUp from "./ContractorPopUp";
import { Get_M_Department_Select } from "../MasterApi/GETAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Pegination } from "../../../Components/Pegination/Pegination";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import Footer from "../../../Components/Footer/Footer";

import Select from "react-select";
import ContractorNameDDL from "../../../CommonDDL/ContractorNameDDL/ContractorNameDDL";
import { ContractorNameDDLAPI } from "../../../Redux/DDLSlice";
import { useDispatch, useSelector } from "react-redux";
import { ContractorMasterTableDataAPI } from "../../../Redux/MasterSlice/ContractorMasterSlice";

export default function Contractor() {

    const userDetails = useAuthState();
    const { DepartmentID, UserID, token } = userDetails
    const dispatch = useDispatch();

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);

    // react pagination states 
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)

    // department Api states 
    const [departmentTable, setdepartmentTable] = useState([])
    const [apiFlag, setapiFlag] = useState()
    const [IsPost, setIsPost] = useState(false)
    const [editData, seteditData] = useState()
    const [deleteData, setDeleteData] = useState()
    const [loading, setloading] = useState()
    const [CurrentPage, setCurrentPage] = useState(0)
    const [deptOfficeTypeIndicatorID, setdeptOfficeTypeIndicatorID] = useState(2)


    const [workTextField, setworkTextField] = useState(
        {
            agencyName: "",
            contractPersonName: "",
        }
    )
    const handleInputChange = (e) => {
        setworkTextField({ ...workTextField, [e.target.name]: e.target.value })
    }

    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
    }
    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        setflag("Add")
        setapiFlag("Insert")
    }
    const handleOnEditClick = (item) => {
        setshowCreatePopup(true)
        setflag("Update")
        setapiFlag("Update")
        seteditData(item)
    }
    const handleOnDeleteClick = (item) => {
        // console.log("item", item)
        setshowDeletePopup(true)
        setflag("Delete")
        setDeleteData(item)
    }

    const [workDDL, setworkDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })
    const [contractorNameDDL, setContractorNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })

    useEffect(() => {
        const data = {
            M_ContractorID: 0,
            M_ProjectID: 0,
            M_UsersID: UserID,
            token,
            Flag: 'Master'
        }
        dispatch(ContractorNameDDLAPI({ data }))
    }, [IsPost])

    const { ContractorNameDDLData } = useSelector(state => state.ContractorNameDDLData)


    const { agencyName, contractPersonName } = workTextField

    const handleOnSearchClick = () => {
        setCurrentPage(0)
        setIsPost(!IsPost)
    }
    const handleOnClearClick = () => {
        setCurrentPage(0)
        setContractorNameDDL({
            ...contractorNameDDL,
            ID: 0,
            Label: 'Select...'
        })
        setIsPost(!IsPost)
    }

    const handleDeleteData = () => {
        var formdata = new FormData();
        formdata.append("M_ContractorID", deleteData);
        formdata.append("M_UserID", UserID);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${BaseUrl}/PTMS/Master/Post_M_Contractor_Delete`, requestOptions)
            .then((res) => res.json())
            .then(result => {
                if (result.code >= 200 && result.code <= 300) {
                    toastSuccesss(result.message)

                }
                else {
                    toastErrorr(result.message)
                }
                setIsPost(!IsPost)
                handleOnCloseClick()
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [contractorNameDDL.ID])

    useEffect(() => {
        const data = {
            M_ContractorID: contractorNameDDL.ID,
            UserID: UserID,
            From: From,
            token: token,
            To: To,
        }
        dispatch(ContractorMasterTableDataAPI({ data }))

    }, [From, IsPost, contractorNameDDL.ID])


    const { tableData, isLoading } = useSelector(state => state.ContractorMasterTableData)

    // console.log(tableData)
    return (
        <>
            {isLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="contractor" subMenu="subMaster" isopen={isopen} />
                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Contractor Master</h4>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                title="Add" onClick={() => handleAddNewClick()}>
                                                <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">

                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ContractorNameDDL
                                                        contractorNameDDL={contractorNameDDL}
                                                        setContractorNameDDL={setContractorNameDDL}
                                                        DDLData={ContractorNameDDLData}
                                                    // FlagName='Master'
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-2 pl-lg-3">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                        disabled={CurrentPage}
                                                    >
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="5%">Sr.No.</th>
                                                    <th>Contractor Name</th>
                                                    <th>Address</th>
                                                    <th>Contact Person Name</th>
                                                    <th>Contact Number</th>
                                                    <th>Email ID</th>
                                                    <th style={{ width: "8%",textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                                            <td>{item.contractor_Name ? item.contractor_Name : "-"}</td>
                                                            <td>{item.address ? item.address : "-"}</td>
                                                            <td>{item.contact_Person ? item.contact_Person : "-"}</td>
                                                            <td>{item.mobile_Number ? item.mobile_Number : "-"}</td>
                                                            <td>{item.email_Address ? item.email_Address : "-"}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Edit" onClick={() => handleOnEditClick(item)}>
                                                                    <Edit size={15} color="#007bff" />
                                                                </span>

                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Delete" onClick={() => handleOnDeleteClick(item.id)}>
                                                                    <Trash2 size={15} color="red" />
                                                                </span>

                                                            </td>
                                                        </tr>
                                                    )) : ""
                                                }



                                            </tbody>
                                        </table>
                                    </div>
                                    {tableData && tableData.table && tableData.table.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData.table[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>


            {showCreatePopup ? <ContractorPopUp open={showCreatePopup} handleOnCloseClick={handleOnCloseClick} deptOfficeTypeIndicatorID={deptOfficeTypeIndicatorID} handleOnClearClick={handleOnClearClick} apiFlag={apiFlag} IsPost={IsPost} editData={editData} setIsPost={setIsPost} flag={flag} /> : <></>}
            {showDeletePopup ? <DeletePopUp open={showDeletePopup} handleOnCloseClick={handleOnCloseClick} handleDeleteData={handleDeleteData} /> : <></>}



        </>
    )
}