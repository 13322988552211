import { useEffect } from 'react'
import Select from 'react-select'
import { WorkAndWorkTypeDDLAPI } from '../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../Helper/Context/context'
import { Star } from '../Components/CommoComponent/Star'


export const ConcateWorkAndWorkTypeDDL = (props) => {
    const { WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL, isdisabled, Flag, PopUpField, apiFlag, editData, mendetary } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = {
            M_WorkTypeID: 0,
            UserID: UserID,
            token: token
        }
        dispatch(WorkAndWorkTypeDDLAPI({ data }))
    }, [])

    const { WorkAndWorkTypeData } = useSelector(state => state.WorkAndWorkTypeDDLData)

    useEffect(() => {
        handleWorkAndWorkType();
    }, [WorkAndWorkTypeData])

    const handleWorkAndWorkType = () => {
        if (WorkAndWorkTypeData && WorkAndWorkTypeData.table && WorkAndWorkTypeData.table.length > 0) {
            let list = WorkAndWorkTypeData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.workID,
                label: item.workType,
            }))

            if (PopUpField && PopUpField.popupFlag) {
                setWorkAndWorkTypeDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_WorkTypeID : 0 : '0',
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.workAndWorkType : "--Select--" : "--Select--",
                })
            } else {
                setWorkAndWorkTypeDDL({
                    DDL: list,
                    ID: apiFlag === 'Update' ? editData.m_WorkID ? editData.m_WorkID : editData.m_WorkID : 0,
                    Label: apiFlag === 'Update' ? editData.workAndWorkType ? editData.workAndWorkType : editData.workTypeName : "---Select---",
                })
            }
        }
        else {
            setWorkAndWorkTypeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"> Main Work Type {mendetary === 'yes' ? <Star /> : ''} </label>
            <Select
                isClearable={false}
                isSearchable
                isDisabled={isdisabled === 'yes' && apiFlag === 'Update'}
                maxMenuHeight={150}
                value={{ value: WorkAndWorkTypeDDL.ID, label: WorkAndWorkTypeDDL.Label }}
                onChange={(e) => {
                    e ?
                        setWorkAndWorkTypeDDL({ ...WorkAndWorkTypeDDL, ID: e.value, Label: e.label })
                        :
                        setWorkAndWorkTypeDDL({ ...WorkAndWorkTypeDDL, ID: 0, Label: "--Select--" })

                }}
                options={WorkAndWorkTypeDDL.DDL}
            // isDisabled
            />
        </div>
    )
}