import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

// --------------------Department Wise Project------------------

export function Get_T_ProjectDefine_Select({setProjectDefineTable,departmentDDL,projectNameDDL,UserID,setloading,setTotalCount,DepartmentID, From, To, rowNo, PerPageCount, }) {
 var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      setloading(true)
   
    fetch(`${BaseUrl}/PTMS/Transaction/Get_T_ProjectDefine_Select?T_ProjectDefineID=0&M_DepartementID=${DepartmentID === "1" ? "0" : DepartmentID}&M_ProjectID=${projectNameDDL.ID}&UserID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                // setdepartmentTable(result.data.table)
                setProjectDefineTable(result.data.table.map((customdata, index) => ({ ...customdata, rowNo: rowNo == 1 ? (index + 1) : ((rowNo - 1) * PerPageCount) + (index + 1) })))
                setTotalCount(result.data.table[0].totalCount)
                setloading(false)
            } else {
                setProjectDefineTable([])
                
            }
            setloading(false)
        })
        .catch(error => {
            setloading(false)
            toastErrorr("Something went wrong")
            console.log('error', error)
        });
}
   

// ------------------project wise work-------------

export function Get_T_WorkDefine_Select({ setworkdefineTable,UserID,setloading,projectTypeDDL,workTypeDDL,setTotalCount, From, To, rowNo, PerPageCount, }) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    setloading(true)
     fetch(` ${BaseUrl}/PTMS/Transaction/Get_T_WorkDefine_Select?T_WorkDefineID=0&M_ProjectTypeID=${projectTypeDDL.ID}&M_WorkTypeID=${workTypeDDL.ID}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)

        .then((res) => res.json())
        .then(result => {
            // console.log(result)
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                setworkdefineTable(result.data.table.map((customdata, index) => ({ ...customdata, rowNo: rowNo == 1 ? (index + 1) : ((rowNo - 1) * PerPageCount) + (index + 1) })))
                setTotalCount(result.data.table[0].totalCount)
                setloading(false)
            } else {
                setworkdefineTable([])

            }
            setloading(false)
        }
        )
        .catch(err => {
            setloading(false)
            toastErrorr("Something went wrong")
            console.log(err)
        })
}

// ------------------project wise budget creation-------------

export function Get_T_ProjectWorkWiseBudgetCreation_Select({setprojectWiseBudgetTable,UserID,projectTypeDDL,workTypeDDL,setloading,setTotalCount, From, To, rowNo, PerPageCount, }) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    setloading(true)

     fetch(`${BaseUrl}/PTMS/Transaction/Get_T_ProjectWorkWiseBudgetCreation_Select?T_ProjectWorkWiseBudgetCreationID=0&M_ProjectTypeID=${projectTypeDDL.ID}&M_WorkTypeID=${workTypeDDL.ID}&UserID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)

        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                setprojectWiseBudgetTable(result.data.table.map((customdata, index) => ({ ...customdata, rowNo: rowNo == 1 ? (index + 1) : ((rowNo - 1) * PerPageCount) + (index + 1) })))
                setTotalCount(result.data.table[0].totalCount)
                setloading(false)
            } else {
                setprojectWiseBudgetTable([])

            }
            setloading(false)
        }
        )
        .catch(err => {
            setloading(false)
            toastErrorr("Something went wrong")
            console.log(err)
        })
}
// ------------------project work wise milestone -------------

export function Get_T_ProjectWorkWiseMilestone_Select({ setworkMileStoneTable,UserID,setloading,setTotalCount, From, To, rowNo, PerPageCount,workTypeDDL,workNameDDL}) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    setloading(true)   
     fetch(`${BaseUrl}/PTMS/Transaction/Get_T_ProjectWorkWiseMilestone_Select?T_ProjectWorkWiseMilestoneID=0&WorkTypeNameID=${workTypeDDL.ID}&M_WorkID=${workNameDDL.ID}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
    
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                setworkMileStoneTable(result.data.table.map((customdata, index) => ({ ...customdata, rowNo: rowNo == 1 ? (index + 1) : ((rowNo - 1) * PerPageCount) + (index + 1) })))
                setTotalCount(result.data.table[0].totalCount)
                setloading(false)
            } else {
                setworkMileStoneTable([])

            }
            setloading(false)
        }
        )
        .catch(err => {
            setloading(false)
            toastErrorr("Something went wrong")
            console.log(err)
        })
}


// -------------------------- Assign Work to Contractor----------------

export function Get_T_AssignWorkToContractor_Select({setcontractorWorkTable,UserID,projectTypeDDL,workTypeDDL,setloading,setTotalCount, From, To, rowNo, PerPageCount, }) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    setloading(true)

    
     fetch(`${BaseUrl}/PTMS/Transaction/Get_T_AssignWorkToContractor_Select?T_AssignWorkToContractorID=0&M_ProjectTypeID=${projectTypeDDL.ID}&M_WorkTypeID=${workTypeDDL.ID}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                setcontractorWorkTable(result.data.table.map((customdata, index) => ({ ...customdata, rowNo: rowNo == 1 ? (index + 1) : ((rowNo - 1) * PerPageCount) + (index + 1) })))
                setTotalCount(result.data.table[0].totalCount)
                setloading(false)
            } else {
                setcontractorWorkTable([])

            }
            setloading(false)
        }
        )
        .catch(err => {
            setloading(false)
            toastErrorr("Something went wrong")
            console.log(err)
        })
}






