import React, { useState } from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_M_Project, Get_DDL_M_ProjectType, Get_DDL_M_Work, Get_DDL_M_WorkType, Get_DDL_T_ProjectDefine_ProjectName, Get_DDL_T_ProjectDefine_ProjectType } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { useEffect } from "react";
import { Post_T_WorkDefine_InsertUpdate } from "../CreateProjectAPI/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";

export default function ProjectWiseWorkPopUp(props) {

  const userDetails = useAuthState();
  const { UserID } = userDetails

  const { allState ,open, handleOnCloseClick, IsPost, setIsPost,handleOnClearClick } = props
  const {apiFlag,editData,popUpFlag} = allState
  

  const [WorkDefineTextField, setWorkDefineTextField] = useState(
    {
      workArea: apiFlag === "Update" ? editData.workArea : "",
    }
  )

  const [projectTypeDDL, setprojectTypeDDL] = useState({
    DDL: [],
    ID: apiFlag === "Update" ? editData.m_ProjectTypeID : "0",
    Label: apiFlag === "Update" ? editData.projectTypeName : "---Select---"
  })

  const [projectNameDDL, setprojectNameDDL] = useState({
    DDL: [],
    ID: apiFlag === "Update" ? editData.m_ProjectID : "0",
    Label: apiFlag === "Update" ? editData.projectName : "---Select---"
  })

  const [workTypeDDL, setworkTypeDDL] = useState({
    DDL: [],
    ID: apiFlag === "Update" ? editData.m_WorkTypeID : "0",
    Label: apiFlag === "Update" ? editData.workTypeName : "---Select---"
  })
  const [workNameDDL, setworkNameDDL] = useState({
    DDL: [],
    ID: apiFlag === "Update" ? editData.m_WorkID : "0",
    Label: apiFlag === "Update" ? editData.workName : "---Select---"
  })

  const handleInputChange = (e) => {
    setWorkDefineTextField({ ...WorkDefineTextField, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    Get_DDL_T_ProjectDefine_ProjectType({ UserID, projectTypeDDL: projectTypeDDL, setprojectTypeDDL: setprojectTypeDDL })
  }, [])

  useEffect(() => {
    Get_DDL_T_ProjectDefine_ProjectName({ UserID, projectNameDDL: projectNameDDL, setprojectNameDDL: setprojectNameDDL, projectTypeDDL })
  }, [projectTypeDDL.ID])

  useEffect(() => {
    Get_DDL_M_WorkType({ setworkDDL: setworkTypeDDL, workDDL: workTypeDDL, UserID })
  }, [])

  useEffect(() => {
    Get_DDL_M_Work({ UserID, workNameDDL, setworkNameDDL, workTypeDDL })
  }, [workTypeDDL.ID])


  const Add = () => {
    Post_T_WorkDefine_InsertUpdate({
      WorkDefineID: apiFlag === "Insert" ? "0" : editData.id,
      UserID,
      apiFlag,
      IsPost,
      setIsPost,
      WorkDefineTextField,
      projectNameDDL,
      workNameDDL,
      handleOnCloseClick,
      handleOnClearClick
    })
  }

  const handleClear = () => {
    setworkNameDDL({
     ...workNameDDL,
      ID: "0",
      Label: "---Select---"
    })
  }
  const handleProjectClear = () =>{
    setprojectNameDDL({
      ...projectNameDDL,
      ID: "0",
      Label: "---Select---"
    })
  }

  return (

    <div>
      <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
        <span className="close" onClick={handleOnCloseClick}>
          &times;
        </span>
        <div className="call"> Add Project Wise Work</div>
        <div className="modal-body">
          <div className="row details-row">
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Project Type <Star/> </label>
                <Select
                  isClearable
                  // isRtl={isRtl}
                  isSearchable
                  maxMenuHeight={190}
                  value={{ value: projectTypeDDL.ID, label: projectTypeDDL.Label }}
                  onChange={(e) => {
                    e ?
                      setprojectTypeDDL({ ...projectTypeDDL, ID: e.value, Label: e.label })
                      :
                      setprojectTypeDDL({ ...projectTypeDDL, ID: 0, Label: "---Select---" })
                      handleProjectClear()
                  }}
                  options={projectTypeDDL.DDL}


                />
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Project Name <Star/> </label>
                <Select
                  isClearable
                  isSearchable
                  value={{ value: projectNameDDL.ID, label: projectNameDDL.Label }}
                  onChange={(e) => {
                    e ?
                      setprojectNameDDL({ ...projectNameDDL, ID: e.value, Label: e.label })
                      :
                      setprojectNameDDL({ ...projectNameDDL, ID: 0, Label: "---Select---" })
                  }}
                  options={projectNameDDL.DDL}
                  maxMenuHeight={140}
                />
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Work Type <Star/> </label>
                <Select
                  isClearable
                  isSearchable
                  value={{ value: workTypeDDL.ID, label: workTypeDDL.Label }}
                  onChange={(e) => {
                    e ?
                      setworkTypeDDL({ ...workTypeDDL, ID: e.value, Label: e.label })
                      :
                      setworkTypeDDL({ ...workTypeDDL, ID: 0, Label: "---Select---" })
                      
                      handleClear()
                  }}
                  options={workTypeDDL.DDL}

                />
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Work Name <Star/></label>
                <Select
                  isClearable
                  isSearchable
                  value={{ value: workNameDDL.ID, label: workNameDDL.Label }}
                  onChange={(e) => {
                    e ?
                      setworkNameDDL({ ...workNameDDL, ID: e.value, Label: e.label })
                      :
                      setworkNameDDL({ ...workNameDDL, ID: 0, Label: "---Select---" })
                  }}
                  options={workNameDDL.DDL}
                  maxMenuHeight={140}
                />
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="workArea">Work Area <Star/></label>
                <input
                  className="form-control"
                  id="workArea"
                  type="text"
                  name="workArea"
                  value={WorkDefineTextField.workArea}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>

            <div className="col-12 col-lg-12">
              <div className="btn-action">
                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                 onClick={handleOnCloseClick}
                >
                  <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                <button
                  type="button"
                  className="btn btn-md btn-primary mx-2 float-right"
                  onClick={() => Add()}
                  disabled={
                    projectTypeDDL.ID === 0 ||
                    projectNameDDL.ID === 0 ||
                    workTypeDDL.ID === 0 ||
                    workNameDDL.ID === 0 ||
                    WorkDefineTextField.workArea === ""
                }
                >{popUpFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popUpFlag}</button>
              </div>
            </div>
          </div>
        </div>


      </Popup>
    </div>
  )
}