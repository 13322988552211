// --------------------------budget Creation API-------------

import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";

export function Post_T_BudgetCreation_InsertUpdate({ UserID, apiFlag, budgetCreationTextField, BudgetCreationID, BudgetID, setIsPost, IsPost, handleOnCloseClick,handleOnClearClick }) {

    const { budgetProvisioin } = budgetCreationTextField

    var formdata = new FormData();
    formdata.append("T_BudgetCreationID", BudgetCreationID);
    formdata.append("M_BudgetID", BudgetID.ID);
    formdata.append("BudgetProvision", budgetProvisioin);
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_BudgetCreation_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ------------------- Budget-creation Delete API------------------
export function Post_T_BudgetCreation_Delete({ BudgetCreationID,
    UserID,
    IsPost,
    setIsPost, handleOnCloseClick }) {
    var formdata = new FormData();
    formdata.append("T_BudgetCreationID", BudgetCreationID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_BudgetCreation_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// --------------------Allocate Budget----------
export function Post_T_BudgetAllocation_InsertUpdate({ BudgetAllocationID, financialYearDDL, BudgetID, departmentDDL, allowcateBudgetTextField, UserID, apiFlag, setIsPost, IsPost, handleOnCloseClick,handleOnClearClick }) {

    const { budgetAmount } = allowcateBudgetTextField

    var formdata = new FormData();
    formdata.append("T_BudgetAllocationID", BudgetAllocationID);
    formdata.append("FinancialYearID", financialYearDDL.ID);
    formdata.append("M_BudgetID", BudgetID.ID);
    formdata.append("M_DepartementID", departmentDDL.ID);
    formdata.append("BudgetAmount", budgetAmount);
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_BudgetAllocation_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}


// -----------------------Budget Allocation-----------
export function Post_T_BudgetAllocation_Delete({ BudgetAllocationID, UserID, IsPost, setIsPost, handleOnCloseClick }) {
    var formdata = new FormData();
    formdata.append("T_BudgetAllocationID", BudgetAllocationID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_BudgetAllocation_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}