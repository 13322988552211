import React, { useState, useEffect } from "react";
import Select from "react-select";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_M_Department, Get_DDL_M_Project, Get_DDL_M_ProjectPriority, Get_DDL_M_ProjectType } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Star } from "../../../Components/CommoComponent/Star";
import { Post_T_ProjectDefine_InsertUpdate } from "../../CreateProject/CreateProjectAPI/POSTAPI";
import { useDispatch } from "react-redux";
import { DefineRolePostAPI } from "../../../Redux/UserMasterSlice/DefineRoleSlice";

export default function DefineRolePopUp({ open, handleAddCloseClick, PopUpField, handlePost }) {

    const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()

    const [DefineRoleTextField, setDefineRoleTextField] = useState({

        roleName: apiFlag === "Insert" ? '' : rowData?.roleName,

    })

    const handleInputChange = (e) => {
        setDefineRoleTextField({ ...DefineRoleTextField, [e.target.name]: e.target.value })
    }

    const handleAddUpdate = () => {
        const addData = {
            // M_WorkTypeID:apiFlag === 'Insert' ? '0' : rowData?.m_WorkTypeID,
            // WorkTypeName:MainWorkTypeTextField?.WorkTypeName,  
            M_RoleID: apiFlag === 'Insert' ? '0' : rowData?.m_RoleID,
            M_DeptOfficeTypeIndicatorID: 0,
            RoleName: DefineRoleTextField?.roleName,
            DisplayOrder: 0,
            UserID: UserID,
            token: token,
            Flag: apiFlag,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick
        }
        dispatch(DefineRolePostAPI({ addData }))
    }

    const handleClear = () => {
        setDefineRoleTextField({

            roleName: ''
        })
    }
    // const {} = useSelector(state=>state.)


    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>
                <span className="close" onClick={handleAddCloseClick}>
                    &times;
                </span>
                <div className="call"> Define Role</div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="role">Role <Star/></label>
                                <input
                                    className="form-control"
                                    id="roleName"
                                    type="text"
                                    name="roleName"
                                    value={DefineRoleTextField.roleName}
                                    // max={empolyeeTextField.joiningDate}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleAddCloseClick}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />{popupBtn}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => handleAddUpdate()}
                                    disabled={
                                        DefineRoleTextField.roleName === ""
                                    }
                                >{apiFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popupFlag}</button>
                            </div>
                        </div>

                    </div>
                </div>


            </Popup>
        </div>
    )
}