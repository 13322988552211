import React, { useState } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import DepartmentPopUp from "./DepartmentPopUp";
import Select from "react-select"
import { useEffect } from "react";
import { useAuthState } from "../../../Helper/Context/context";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Get_M_Department_Select } from "../MasterApi/GETAPI";
import { Pegination } from "../../../Components/Pegination/Pegination";
import Footer from "../../../Components/Footer/Footer";
import { Get_DDL_M_Department } from "../../../APIs/DDLAPI/DDLAPI";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import { useDispatch, useSelector } from "react-redux";
import { DepartmentDeleteAPI, DepartmentTableDataAPI } from "../../../Redux/MasterSlice/DepartmentSlice";
import { DepartmentNameDDLAPI } from "../../../Redux/DDLSlice";



export default function Department() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);
    const [deptOfficeTypeIndicatorID, setdeptOfficeTypeIndicatorID] = useState(1)

    // react pagination states 
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)

    // department Api states 
    const [departmentTable, setdepartmentTable] = useState([])
    const [apiFlag, setapiFlag] = useState()
    const [IsPost, setIsPost] = useState(false)
    const [editData, seteditData] = useState()
    const [deleteData, setDeleteData] = useState()
    const [loading, setloading] = useState()
    const [CurrentPage, setCurrentPage] = useState(0)

    const [departmentTextField, setdepartmentTextField] = useState(
        {
            departmentName: "",
        }
    )

    const [designationDDL, setdesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });
    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [designationTable, setdesignationTable] = useState([]);

    const { departmentName } = departmentTextField

    const handleInputChange = (e) => {
        setdepartmentTextField({ ...departmentTextField, [e.target.name]: e.target.value })
    }


    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
    }
    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        setflag("Insert")
        setapiFlag("Insert")
    }
    const handleOnEditClick = (item) => {
        setshowCreatePopup(true)
        setflag("Update")
        setapiFlag("Update")
        seteditData(item)
    }
    const handleOnDeleteClick = (item) => {
        setshowDeletePopup(true)
        setflag("Delete")
        setDeleteData(item)
    }


   

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [departmentDDL.ID])

    useEffect(() => {
        const data = {
            M_DepartmentID: departmentDDL.ID,
            DepartmentName: '',
            M_UsersID: UserID,
            M_DeptOfficeTypeIndicatorID: 0,
            Dept_Code: '',
            From: From,
            token: token,
            To: To,
        }
        dispatch(DepartmentTableDataAPI({ data }))

    }, [From,IsPost, departmentDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.DepartmentTableData)

    const handleOnSearchClick = () => {
        setIsPost(!IsPost)
        setCurrentPage(0)
    }

    const handleOnClearClick = () => {
        setCurrentPage(0)
        setdepartmentTextField(
            {
                departmentName: "",
            }
        )
        setIsPost(!IsPost)
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: 'Select...'
        })
    }


    const handleDeleteData = () => {
        var formdata = new FormData();
        formdata.append("M_DepartmentID", deleteData);
        formdata.append("M_UserID", UserID);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };


        fetch(`${BaseUrl}/PTMS/Master/Post_M_Department_Delete`, requestOptions)
            .then((res) => res.json())
            .then(result => {
                if (result.code >= 200 && result.code <= 300) {
                    if (result.message === "You can not delete this") {
                        toastErrorr(result.message)
                    } else {
                        toastSuccesss(result.message)
                    }
                }
                else {
                    toastErrorr(result.message)
                }
                handleOnCloseClick()
                setIsPost(!IsPost)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            {isLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="department" subMenu="subMaster" isopen={isopen} />

                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Department</h4>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                title="Add" onClick={() => handleAddNewClick()}>
                                                <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className=" filter mb-0">
                                        {/* <div className="card filter mb-4"> */}
                                        <div className="card-body">
                                            <div className="form-row">

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <DepartmentDDL
                                                        departmentDDL={departmentDDL}
                                                        setdepartmentDDL={setdepartmentDDL}
                                                        IsPost={IsPost}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-2 pl-lg-3">
                                                    <button type="button"
                                                        className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick} >
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">

                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr style={{ textAlign: "center" }}>
                                                    <th width="5%">Sr.No.</th>
                                                    <th>Name of Department</th>
                                                    <th>Department Code</th>
                                                    <th>Address</th>
                                                    <th>Contact Number</th>
                                                    <th>Email ID</th>
                                                    <th style={{ width: "8%",textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                                                        <tr key={i} >
                                                            <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                                            <td>{item.departmentName ? item.departmentName : "-"}</td>
                                                            <td>{item.dept_Code ? item.dept_Code : "-"}</td>
                                                            <td style={{ width: '18%' }}>{item.departmentAddress ? item.departmentAddress : "-"}</td>
                                                            <td>{item.mobileNo ? item.mobileNo : "-"}</td>
                                                            <td>{item.emailId ? item.emailId : "-"}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <button
                                                                className="mx-2"
                                                                    title="Edit" onClick={() => handleOnEditClick(item)}>
                                                                    <Edit size={15} color="#007bff" />
                                                                </button>

                                                                <button className="mx-2"
                                                                    title="Delete"
                                                                    onClick={() => { handleOnDeleteClick(item.m_DepartmentID) }}
                                                                >
                                                                    <Trash2 size={15} color="red" />
                                                                </button>

                                                            </td>
                                                        </tr>
                                                    )

                                                    ) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {tableData && tableData.table && tableData.table.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData.table[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

            {showCreatePopup ? <DepartmentPopUp
                open={showCreatePopup}
                handleOnClearClick={handleOnClearClick}
                handleOnCloseClick={handleOnCloseClick}
                deptOfficeTypeIndicatorID={deptOfficeTypeIndicatorID}
                apiFlag={apiFlag}
                IsPost={IsPost}
                editData={editData}
                setIsPost={setIsPost}
                flag={flag} /> : <></>
            }

            {showDeletePopup ? <DeletePopUp
                open={showDeletePopup}
                handleDeleteData={handleDeleteData}
                deleteData={deleteData}
                handleOnCloseClick={handleOnCloseClick} />
                : <></>
            }

        </>
    )
}