
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const BudgetDetailsExport = (props) => {
    const { ExportData, name, title, projectType } = props

    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportUserCreation"
                className="btn btn-success float-right download-table-xls-button btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-ExportUserCreation" style={{ display: 'none', width: '50%' }}>

                <h5><span>&emsp;</span></h5>

                <tr>
                    <th colSpan={10} style={{ textAlign: 'center', fontSize: 16 }}>{name}</th>
                </tr>
                {/* <tr><td></td></tr> */}

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#254462', color: "#fff" }}>
                        <th style={{ width: "5%", borderRight: '1px solid white' }} >Sr.No.</th>
                        <th style={{ borderLeft: "1px solid white" }}>Department</th>
                        <th style={{ borderLeft: "1px solid white" }}>Ward Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Electroal Ward Number</th>
                        <th style={{ whiteSpace: 'nowrap', borderLeft: "1px solid white" }}>Project Tracker Code</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Work Type</th>
                        <th style={{ whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em', borderLeft: "1px solid white" }}>Project Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Budge Head <br className="colorBlack" />Budge Code</th>
                        <th style={{ borderLeft: "1px solid white" }}>Budget Provision (In Lacs)</th>
                        <th style={{ borderLeft: "1px solid white" }}>Administrative Approval Cost (In Lacs) </th>
                        <th style={{ borderLeft: "1px solid white" }}>Administrative Approval Date</th>
                        {
                            projectType != 'ApprovedCost' &&
                            <>
                                <th style={{ borderLeft: "1px solid white" }}>Tender Stages</th>
                                <th style={{ borderLeft: "1px solid white" }}>Date of Tender Floated</th>
                                <th style={{ borderLeft: "1px solid white" }}>Tender Amount Type</th>
                                <th style={{ borderLeft: "1px solid white" }}>Tender Amount (In Lacs)</th>
                                {
                                    projectType == 'ExpenditureCost' &&
                                    <>
                                        <th style={{ borderLeft: "1px solid white" }}>Actual Amount (In Lacs)</th>
                                    </>
                                }
                                <th style={{ borderLeft: "1px solid white" }}>Contractor Name</th>

                            </>
                        }
                    </tr>
                </thead>
                <tbody>
                    {

                        ExportData && ExportData.length > 0 ? ExportData.map((item, i) => (
                            <tr style={{ border: '1px solid black' }}>
                                <td className="text_center">{item?.rowNum}</td>
                                <td>{item.departmentName ? item.departmentName : '-'}</td>
                                <td>{item.wardName ? item.wardName : '-'}</td>
                                <td>{item.m_WardWiseNumber ? item.m_WardWiseNumber : '-'}</td>
                                <td>{item.project_Tracker_Code ? item.project_Tracker_Code : '-'}</td>
                                <td>{item.projectTypeName ? item.projectTypeName : '-'}</td>
                                <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                <td style={{ width: '30em' }}>{item.projectName ? item.projectName : '-'}</td>
                                <td>
                                    <span>{item.budgetName ? item.budgetName : '-'}</span>
                                    <br className="colorBlack" />
                                    <span>{item.budgetCode ? item.budgetCode : '-'}</span>
                                </td>
                                <td style={{ textAlign:'right' }}>{item.budget_Provision ? (item.budget_Provision).toFixed(2) : '-'}</td>
                                <td style={{ textAlign:'right' }}>{item.administrativeApproveCost ? (item.administrativeApproveCost).toFixed(2) : '-'}</td>
                                <td>{item.administrativeApproveDate ? moment(item.administrativeApproveDate).format('DD-MM-YYYY') : '-'}</td>
                                {
                                    projectType != 'ApprovedCost' &&
                                    <>
                                        <td>{item.tender_Stages ? item.tender_Stages : '-'}</td>
                                        <td>{item.dateOfTenderFloated ? moment(item.dateOfTenderFloated).format('DD-MM-YYYY') : '-'}</td>
                                        <td>{item.tender_Amount_Type ? item.tender_Amount_Type : '-'}</td>
                                        <td style={{ textAlign:'right' }}>{item.tendarCost ? item.tendarCost.toFixed(2) : '-'}</td>
                                        {
                                            projectType == 'ExpenditureCost' &&
                                            <>
                                                <td style={{ textAlign:'right' }}>{item.actualCost ? item.actualCost.toFixed(2) : '-'}</td>
                                            </>
                                        }
                                        <td>{item.contractor_Name ? item.contractor_Name : '-'}</td>
                                    </>
                                }
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}