
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"
import { EmployeeNameDDLAPI } from "../../Redux/DDLSlice"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../Helper/Context/context"



export default function EmployeeNameDDL(props) {
    const { employeeNameDDL, setEmployeeNameDDL, designationNameDDL, departmentDDL, FlagName, PopUpField, mendetary } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        const data = { UserID, token, designationNameDDL, departmentDDL }
        dispatch(EmployeeNameDDLAPI({ data }))
    }, [designationNameDDL.ID, departmentDDL&&departmentDDL])

    const { EmployeeDDLData } = useSelector(state => state.EmployeeNameData)

    useEffect(() => {
        handleEmployeeNameDDL()
    }, [EmployeeDDLData])

    const handleEmployeeNameDDL = () => {
        if (EmployeeDDLData && EmployeeDDLData.table && EmployeeDDLData.table.length > 0) {

            let list = EmployeeDDLData.table.map((item, index) => ({

                // value: item.departmentCode,
                value: item.id,
                label: item.employeeName,
            }))

            if (FlagName == '') {
                setEmployeeNameDDL({
                    DDL: list,
                    ID: 0,
                    Label: '---Select---'

                })
            } else {
                setEmployeeNameDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_EmployeeID : 0 : 0,
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.employeeName : "---Select---" : "---Select---",

                })
            }

        }
        else {
            setEmployeeNameDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
        }
    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Employee Name {mendetary === 'yes' ? <Star /> : ''} </label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: employeeNameDDL.ID, label: employeeNameDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setEmployeeNameDDL({ ...employeeNameDDL, ID: e.value, Label: e.label })
                            :
                            setEmployeeNameDDL({ ...employeeNameDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={employeeNameDDL.DDL}

                />
            </div>
        </>
    )
}