import React from 'react'
import { useState } from 'react'
import Popup from 'reactjs-popup';

const ContractorWorkViewPopUp = ({ open, handleAddCloseClick, PopUpField, handlePost }) => {
    const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField
    
    // const [contractorWorkTextField, setcontractorWorkTextField] = useState(
    //     {
    //         firstExtentionDate: viewData && viewData.firstExtentionDate?.split("/").reverse().join("-") ,
    //         secondExtentionDate: viewData && viewData.secondExtentionDate?.split("/").reverse().join("-") ,
    //         thirdExtentionDate: viewData && viewData.thirdExtentionDate?.split("/").reverse().join("-"),
    //     })

    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>
                <span className="close" onClick={handleAddCloseClick}>
                    &times;
                </span>
                <div className="call"> Work Extension Date</div>
                <div className="modal-body">
                    <div className="row details-row">
                       <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="firstExtentionDate">1st Extension Date</label>
                                <input
                                    className="form-control"
                                    id="firstExtentionDate"
                                    type="date"
                                    name="firstExtentionDate"
                                    // disabled
                                    // value={contractorWorkTextField.firstExtentionDate}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="secondExtentionDate">2nd Extension Date</label>
                                <input
                                    className="form-control"
                                    id="secondExtentionDate"
                                    type="date"
                                    name="secondExtentionDate"
                                    // disabled
                                    // value={contractorWorkTextField.secondExtentionDate}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" htmlFor="thirdExtentionDate">3rd Extension Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="thirdExtentionDate"
                                    id="thirdExtentionDate"
                                    // disabled
                                    // value={contractorWorkTextField.thirdExtentionDate}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default ContractorWorkViewPopUp
