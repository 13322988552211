import React, { useState } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { useEffect } from "react";
import { useAuthState, useAuthStateYearMonth } from "../../../Helper/Context/context";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Pegination } from "../../../Components/Pegination/Pegination";
import Footer from "../../../Components/Footer/Footer";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import { useDispatch, useSelector } from "react-redux";
import SubDepartmentDDL from "../../../CommonDDL/SubDepartmentDDL/SubDepartmentDDL";
import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import WardNameDDL from "../../../CommonDDL/WardNameDDL/WardNameDDL";
import ElectoralWardNumDDL from "../../../CommonDDL/ElectoralWardNumDDL/ElectoralWardNumDDL";
import ProjectPriorityDDL from "../../../CommonDDL/ProjectPriorityDDL/ProjectPriorityDDL";
import KeyProjectDDL from "../../../CommonDDL/KeyProjectDDL/KeyProjectDDL";
import TenderAmountTypeDDL from "../../../CommonDDL/TenderAmountTypeDDL/TenderAmountTypeDDL";
import { ElectoralWardNumberDDLAPI, WardNameDDLAPI } from "../../../Redux/DDLSlice";
import ProjectNameDDL from "../../../CommonDDL/ProjectNameDDL/ProjectNameDDL";
import { AllColumnExportReport } from "./Excel/AllColumnReportExcel";
import { SummaryOfProjectReportExport } from "./Excel/SummaryOfProjectsReportsExcel";
import { Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectAPI, Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectAPI } from "../../../Redux/ReportSlice/ReportSlice";
import { DailyReportExportReport } from "./Excel/DailyReportExcel";

export default function ReportScreen() {

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch();

    const YearMonthDetails = useAuthStateYearMonth()
    const { year, Month } = YearMonthDetails

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);
    const [deptOfficeTypeIndicatorID, setdeptOfficeTypeIndicatorID] = useState(1)

    const [FromDate, setFromDate] = useState('')
    const [ToDate, setToDate] = useState('')

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [subDepartmentDDL, setSubDepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [ProjectDDL, setProjectDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [electoralWardNumDDL, setElectoralWardNumDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    });

    const [projectPriorityDDL, setProjectPriorityDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [keyProjectDDL, setKeyProjectDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [tenderAmountTypeDDL, setTenderAmountTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    useEffect(() => {
        const data = { UserID, token, M_WardID: wardNameDDL.ID }
        dispatch(ElectoralWardNumberDDLAPI({ data }))
    }, [wardNameDDL.ID])

    const { ElectoralWardNumberData } = useSelector(state => state.ElectoralWardNumberDDLData)

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_WardID: wardNameDDL.ID,
            zoneDDL: 0
        }
        dispatch(WardNameDDLAPI({ data }))
    }, [])

    const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

    // useEffect(() => {
    //     setCurrentPage(0)
    //     setFrom(1)
    //     setTo(10)
    // }, [departmentDDL.ID])

    // const [WorkOrderDelayReport, setWorkOrderDelayReport] = useState([])
    // const [AllReport, setAllReport] = useState([])

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            FromDate: FromDate,
            ToDate: ToDate,
            M_DepartmentID: departmentDDL.ID,
            M_SubDepartmentID: subDepartmentDDL.ID,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_ProjectID: ProjectDDL.ID,
            M_WardID: wardNameDDL.ID,
            M_WardWiseNumberID: electoralWardNumDDL.ID,
            M_ProjectPriorityID: projectPriorityDDL.ID,
            Key_ProjectM_IndicatorID: keyProjectDDL.ID,
            M_Tender_Amount_TypeID: tenderAmountTypeDDL.ID,
            Flag: 'All',
            M_UsersID: UserID,
            FromTop: '1',
            ToTop: '9999',
            token: token,
            // setWorkOrderDelayReport: setWorkOrderDelayReport,
            // setAllReport:setAllReport
        }
        dispatch(Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectAPI({ data }))
    }, [YearID, MonthID, FromDate, ToDate, departmentDDL.ID, subDepartmentDDL.ID, ProjectTypeDDL.ID, ProjectDDL.ID, wardNameDDL.ID,
        electoralWardNumDDL.ID, projectPriorityDDL.ID, keyProjectDDL.ID, tenderAmountTypeDDL.ID,
    ])

    const { tableData, isLoading } = useSelector(state => state.Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectData)

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            FromDate: FromDate,
            ToDate: ToDate,
            M_DepartmentID: departmentDDL.ID,
            M_SubDepartmentID: subDepartmentDDL.ID,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_ProjectID: ProjectDDL.ID,
            M_WardID: wardNameDDL.ID,
            M_WardWiseNumberID: electoralWardNumDDL.ID,
            M_ProjectPriorityID: projectPriorityDDL.ID,
            Key_ProjectM_IndicatorID: keyProjectDDL.ID,
            M_Tender_Amount_TypeID: tenderAmountTypeDDL.ID,
            Flag: 'DelayReport',
            M_UsersID: UserID,
            FromTop: '1',
            ToTop: '9999',
            token: token,
            // setWorkOrderDelayReport: setWorkOrderDelayReport,
            // setAllReport:setAllReport
        }
        dispatch(Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectAPI({ data }))
    }, [YearID, MonthID, FromDate, ToDate, departmentDDL.ID, subDepartmentDDL.ID, ProjectTypeDDL.ID, ProjectDDL.ID, wardNameDDL.ID,
        electoralWardNumDDL.ID, projectPriorityDDL.ID, keyProjectDDL.ID, tenderAmountTypeDDL.ID,
    ])

    const { DelayReportData, isDelayReportLoading } = useSelector(state => state.Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectData)

    console.log('DelayReportData', DelayReportData)

    const handleOnClearClick = () => {
        // setCurrentPage(0)
        // setIsPost(!IsPost)
        setFromDate('')
        setToDate('')
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: 'Select...'
        })
        setSubDepartmentDDL({
            ...subDepartmentDDL,
            ID: 0,
            Label: 'Select...'
        })
        setProjectTypeDDL({
            ...ProjectTypeDDL,
            ID: 0,
            Label: 'Select...'
        })
        setProjectDDL({
            ...ProjectDDL,
            ID: 0,
            Label: 'Select...'
        })
        setWardNameDDL({
            ...wardNameDDL,
            ID: 0,
            Label: 'Select',
        })
        setElectoralWardNumDDL({
            ...electoralWardNumDDL,
            ID: 0,
            Label: 'Select...'
        })
        setProjectPriorityDDL({
            ...projectPriorityDDL,
            ID: 0,
            Label: 'Select...'
        })
        setKeyProjectDDL({
            ...keyProjectDDL,
            ID: 0,
            Label: 'Select...',
        })
        setTenderAmountTypeDDL({
            ...tenderAmountTypeDDL,
            ID: 0,
            Label: 'Select...'
        })
    }

    return (
        <>
            {/* {isLoading && <LoaderFile />} */}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="ReportScreen" subMenu="ReportScreen" isopen={isopen} />

                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Report</h4>
                                        </div>
                                        {/* <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                title="Add" onClick={() => handleAddNewClick()}>
                                                <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                                                Add
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className=" filter mb-0">
                                        {/* <div className="card filter mb-4"> */}
                                        <div className="card-body">
                                            <div className="form-row">

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>From Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => (setFromDate(e.target.value))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>To Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            onChange={(e) => (setToDate(e.target.value))}
                                                        />

                                                    </div>
                                                </div>


                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <DepartmentDDL
                                                        departmentDDL={departmentDDL}
                                                        setdepartmentDDL={setdepartmentDDL}
                                                        // IsPost={IsPost}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <SubDepartmentDDL
                                                        subDepartmentDDL={subDepartmentDDL}
                                                        setSubDepartmentDDL={setSubDepartmentDDL}
                                                        departmentDDLID={departmentDDL.ID}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ProjectTypeDDLData
                                                        ProjectTypeDDL={ProjectTypeDDL}
                                                        setProjectTypeDDL={setProjectTypeDDL}
                                                        // PopUpField={PopUpField}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-12 col-lg-3">
                                                    <ProjectNameDDL
                                                        ProjectDDL={ProjectDDL}
                                                        setProjectDDL={setProjectDDL}
                                                        ProjectTypeDDL={ProjectTypeDDL}
                                                        PopUpField=''
                                                        DDLFlag='All'
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WardNameDDL
                                                        wardNameDDL={wardNameDDL}
                                                        setWardNameDDL={setWardNameDDL}
                                                        DDLData={WardNameDDLData}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ElectoralWardNumDDL
                                                        electoralWardNumDDL={electoralWardNumDDL}
                                                        setElectoralWardNumDDL={setElectoralWardNumDDL}
                                                        DDLData={ElectoralWardNumberData}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ProjectPriorityDDL
                                                        projectPriorityDDL={projectPriorityDDL}
                                                        setProjectPriorityDDL={setProjectPriorityDDL}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <KeyProjectDDL
                                                        keyProjectDDL={keyProjectDDL}
                                                        setKeyProjectDDL={setKeyProjectDDL}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-3">
                                                    <TenderAmountTypeDDL
                                                        tenderAmountTypeDDL={tenderAmountTypeDDL}
                                                        setTenderAmountTypeDDL={setTenderAmountTypeDDL}
                                                    // mendetary='yes'
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-2 pl-lg-3">
                                                    <button type="button"
                                                        className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick} >
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">

                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr style={{ textAlign: "center" }}>
                                                    <th width="5%">Sr.No.</th>
                                                    <th>Report Name</th>
                                                    <th>Download</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {/* {
                                                    tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => ( */}
                                                <tr >
                                                    <td className="text_center">1</td>
                                                    <td>All Column Reports</td>
                                                    <td className="text-center">
                                                        {/* {
                                                        isExportLoading ?
                                                            <button type="button" className="btn btn-success btn-sm" disabled>
                                                                <i className="fa fa-refresh fa-spin pr-2" style={{ fontSize: "15px" }}></i>
                                                                Loading..
                                                            </button>
                                                            :
                                                            tableData && tableData.length > 0 && */}
                                                        {/* <button onClick={() => fetchReportData('All',setAllReport)}> */}
                                                        <AllColumnExportReport
                                                            ExportData={tableData}
                                                            name='All Column Report'
                                                            FromDate={FromDate}
                                                            ToDate={ToDate}
                                                            departmentDDL={departmentDDL && departmentDDL?.Label}
                                                            subDepartmentDDL={subDepartmentDDL && subDepartmentDDL?.Label}
                                                            ProjectTypeDDL={ProjectTypeDDL && ProjectTypeDDL?.Label}
                                                            ProjectDDL={ProjectDDL && ProjectDDL?.Label}
                                                            wardNameDDL={wardNameDDL && wardNameDDL?.Label}
                                                            electoralWardNumDDL={electoralWardNumDDL && electoralWardNumDDL?.Label}
                                                            projectPriorityDDL={projectPriorityDDL && projectPriorityDDL?.Label}
                                                            keyProjectDDL={keyProjectDDL && keyProjectDDL?.Label}
                                                            tenderAmountTypeDDL={tenderAmountTypeDDL && tenderAmountTypeDDL?.Label}
                                                        />
                                                        {/* </button> */}
                                                        {/* } */}
                                                    </td>
                                                </tr>


                                                <tr >
                                                    <td className="text_center">2</td>
                                                    <td>Summary Of Project Reports</td>
                                                    <td className="text-center">
                                                        <SummaryOfProjectReportExport
                                                            // ExportData={ExporttableData}
                                                            name='Summary Of Project Report'
                                                        // title={title}
                                                        // projectType={projectType}
                                                        />
                                                    </td>
                                                </tr>


                                                <tr >
                                                    <td className="text_center">3</td>
                                                    <td>Work Order Delay Report</td>
                                                    <td className="text-center">
                                                        {/* <button onClick={() => fetchReportData('DelayReport', setWorkOrderDelayReport)}> */}
                                                        <DailyReportExportReport
                                                            ExportData={DelayReportData}
                                                            name='Work Order Delay Report'
                                                            FromDate={FromDate}
                                                            ToDate={ToDate}
                                                            departmentDDL={departmentDDL && departmentDDL?.Label}
                                                            subDepartmentDDL={subDepartmentDDL && subDepartmentDDL?.Label}
                                                            ProjectTypeDDL={ProjectTypeDDL && ProjectTypeDDL?.Label}
                                                            ProjectDDL={ProjectDDL && ProjectDDL?.Label}
                                                            wardNameDDL={wardNameDDL && wardNameDDL?.Label}
                                                            electoralWardNumDDL={electoralWardNumDDL && electoralWardNumDDL?.Label}
                                                            projectPriorityDDL={projectPriorityDDL && projectPriorityDDL?.Label}
                                                            keyProjectDDL={keyProjectDDL && keyProjectDDL?.Label}
                                                            tenderAmountTypeDDL={tenderAmountTypeDDL && tenderAmountTypeDDL?.Label}
                                                        // projectType={projectType}
                                                        />
                                                        {/* </button>/ */}
                                                    </td>
                                                </tr>


                                                {/* )

                                                    ) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                                                } */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData.table[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    } */}
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div >
        </>
    )
}