// ---------------Department Wise Project------------------

import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastInfo, toastSuccesss } from "../../../Helper/ToastMessage";

export function Post_T_ProjectDefine_InsertUpdate({ ProjectDefineID, departmentDDL, ProjectTypeDDL, projectNameDDL, projectPriorityDDL, projectDefineTextField, UserID, apiFlag, IsPost, setIsPost, handleOnCloseClick, handleOnClearClick }) {

    const { projectArea } = projectDefineTextField

    var formdata = new FormData();
    formdata.append("T_ProjectDefineID", ProjectDefineID);
    formdata.append("M_DepartementID", departmentDDL.ID);
    formdata.append("M_ProjectID", projectNameDDL.ID);
    formdata.append("M_ProjectPriorityID", projectPriorityDDL.ID);
    formdata.append("ProjectArea", projectArea);
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectDefine_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.message === "This Project Already Assigned to Department") {
                    toastInfo(result.message)
                } else {
                    toastSuccesss(result.message)
                }
            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// --------------------- Delete API Department Wise Project--------------
export function Post_T_ProjectDefine_Delete({ ProjectDefineID, IsPost, setIsPost, handleOnCloseClick }) {
    var formdata = new FormData();
    formdata.append("T_ProjectDefineID", ProjectDefineID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectDefine_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ============================== project wise work ===================================== 

export function Post_T_WorkDefine_InsertUpdate({ WorkDefineID, UserID, apiFlag, IsPost, setIsPost, WorkDefineTextField, projectNameDDL, workNameDDL, handleOnCloseClick, handleOnClearClick }) {

    const { workArea } = WorkDefineTextField

    var formdata = new FormData();
    formdata.append("T_WorkDefineID", WorkDefineID);
    formdata.append("M_ProjectID", projectNameDDL ? projectNameDDL.ID : "0");
    formdata.append("M_WorkID", workNameDDL ? workNameDDL.ID : "0");
    formdata.append("WorkArea", workArea ? workArea : "");
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };


    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_WorkDefine_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.message === "Record Allready Exists") {
                    toastInfo(result.message)
                } else {
                    toastSuccesss(result.message)
                }
            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ===================== work define delete api ===================== 
export function Post_T_WorkDefine_Delete({ UserID, deleteData, handleOnCloseClick, setIsPost, IsPost }) {

    var formdata = new FormData();
    formdata.append("T_WorkDefineID", deleteData);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_WorkDefine_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}


// ============================== Project Work Wise Budget Creation ===================================== 

export function Post_T_ProjectWorkWiseBudgetCreation_InsertUpdate({ UserID, apiFlag, IsPost, setIsPost, handleOnCloseClick, projectNameDDL, workNameDDL, workAreaDDL, createBudgetTextField, ProjectWorkWiseBudgetCreationID, handleOnClearClick }) {

    const { workAmount } = createBudgetTextField

    var formdata = new FormData();
    formdata.append("T_ProjectWorkWiseBudgetCreationID", ProjectWorkWiseBudgetCreationID);
    formdata.append("M_ProjectID", projectNameDDL.ID);
    formdata.append("M_WorkID", workNameDDL.ID);
    formdata.append("WorkAreaID", workAreaDDL.ID);
    formdata.append("WorkAmount", workAmount ? workAmount : "");
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectWorkWiseBudgetCreation_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ===================== Project Work Wise Budget Creation_Delete ===================== 

export function Post_T_ProjectWorkWiseBudgetCreation_Delete({ UserID, deleteData, handleOnCloseClick, IsPost, setIsPost }) {

    var formdata = new FormData();
    formdata.append("T_ProjectWorkWiseBudgetCreationID", deleteData);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(` ${BaseUrl}/PTMS/Transaction/Post_T_ProjectWorkWiseBudgetCreation_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ===================== create Project Work Wise milestone ===================== 

export function Post_T_ProjectWorkWiseMilestone_InsertUpdate({ UserID, apiFlag, ProjectWorkWiseMilestoneID,workAreaDDL, workMilestoneTextField, workNameDDL, handleOnCloseClick, setIsPost, IsPost,handleOnClearClick }) {

    const { addMilestone } = workMilestoneTextField

    var formdata = new FormData();
    formdata.append("T_ProjectWorkWiseMilestoneID", ProjectWorkWiseMilestoneID);
    formdata.append("M_WorkID", workNameDDL.ID);
    formdata.append("T_WorkDefineWorkAreaID", workAreaDDL.ID);
    formdata.append("Milestone", addMilestone ? addMilestone : "");
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };


    fetch(` ${BaseUrl}/PTMS/Transaction/Post_T_ProjectWorkWiseMilestone_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                if (result.message === "Record Already Exists") {
                    toastInfo(result.message)
                } else {
                    toastSuccesss(result.message)
                }
            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ===================== create Project Work Wise milestone delete ===================== 

export function Post_T_ProjectWorkWiseMilestone_Delete({ UserID, allState, handleOnCloseClick, setIsPost, IsPost }) {

    const { deleteData } = allState

    var formdata = new FormData();
    formdata.append("T_ProjectWorkWiseMilestoneID", deleteData);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };


    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectWorkWiseMilestone_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}


// ---------------------  Assign work to Contractor -----------------
export function Post_T_AssignWorkToContractor_InsertUpdate({ AssignWorkToContractorID, projectNameDDL, workNameDDL, contractorNameDDL, contractorWorkTextField, UserID, apiFlag, IsPost, setIsPost, handleOnCloseClick, handleOnClearClick }) {

    const { tenderDate, tenderCost, technicalApproveDate, administrativeApproveCost, administrativeApproveDate, workTimeLimit, workOrderDate, firstExtentionDate, secondExtentionDate, thirdExtentionDate } = contractorWorkTextField

    var formdata = new FormData();
    formdata.append("T_AssignWorkToContractorID", AssignWorkToContractorID);
    formdata.append("M_ProjectID", projectNameDDL.ID);
    formdata.append("M_WorkID", workNameDDL.ID);
    formdata.append("ContractorID", contractorNameDDL.ID);
    formdata.append("TendarDate", tenderDate ? tenderDate : " ");
    formdata.append("TendarCost", tenderCost ? tenderCost : " ");
    formdata.append("TechnicalApproveDate", technicalApproveDate ? technicalApproveDate : "");
    formdata.append("AdministrativeApproveCost", administrativeApproveCost ? administrativeApproveCost : "");
    formdata.append("AdministrativeApproveDate", administrativeApproveDate ? administrativeApproveDate : "");
    formdata.append("WorkTimeLimit", workTimeLimit ? workTimeLimit : "");
    formdata.append("WorkOrderDate", workOrderDate ? workOrderDate : "");
    formdata.append("FirstExtentionDate", firstExtentionDate ? firstExtentionDate : "");
    formdata.append("SecondExtentionDate", secondExtentionDate ? secondExtentionDate : "");
    formdata.append("ThirdExtentionDate", thirdExtentionDate ? thirdExtentionDate : "");
    formdata.append("UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_AssignWorkToContractor_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}

// ---------------------  Assign work to Contractor Delete API -----------------

export function Post_T_AssignWorkToContractor_Delete({ UserID, DeleteData, handleOnCloseClick, setIsPost, IsPost }) {

    var formdata = new FormData();
    formdata.append("T_AssignWorkToContractorID", DeleteData);
    formdata.append("M_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_AssignWorkToContractor_Delete`, requestOptions)
        .then((res) => res.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)
            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
}