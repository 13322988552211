import React, { useEffect, useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Select from "react-select";
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_M_WorkType } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Work_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";

import { WorkTypeDDLData } from "../../../CommonDDL/WorkTypeDDLData";
import { useDispatch } from "react-redux";
import { SubWorkTypePostAPI } from "../../../Redux/MasterSlice/SubWorkTypeSlice";

export default function WorkPopUp({ open, handleAddCloseClick, PopUpField, handlePost }) {
    
    const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()

    const [WorkTypeDDL, setWorkTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_WorkTypeID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.workTypeName,
    })

    

    const [WorkTextField, setWorkTextField] = useState({

        workName: apiFlag === "Insert" ? '' : rowData?.workName,

    })

    const handleInputChange = (e) => {
        setWorkTextField({ ...WorkTextField, [e.target.name]: e.target.value })
    }

    const handleClear = () => {
        setWorkTypeDDL({
            ...WorkTypeDDL,
            ID: 0,
            Label: "---Select---"
          })
          setWorkTextField({
            workName: ''
        })
    }

    const handleAddUpdate = () => {
        const addData = {
            M_WorkID:apiFlag === 'Insert' ? '0' : rowData?.m_WorkID,
            M_WorkTypeID:WorkTypeDDL.ID,
            WorkName:WorkTextField?.workName,  
            M_UserID: UserID,
            token: token,
            Flag: apiFlag,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick
        }
        dispatch(SubWorkTypePostAPI({ addData }))
    }

    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>
                <span className="close" onClick={handleAddCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Sub Work Type </div>
                <div className="modal-body">
                    <div className="row details-row">

                        <div className="col-12 col-lg-6">
                            <WorkTypeDDLData
                                WorkTypeDDL={WorkTypeDDL}
                                setWorkTypeDDL={setWorkTypeDDL}
                                Flag='Master'
                                PopUpField={PopUpField}
                                mendetary = 'yes'
                            // WorkTypeData={WorkTypeData}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="workName">Sub Work Type Name <Star /></label>
                               
                                 <input
                                    className="form-control"
                                    id="workName"
                                    type="text"
                                    name="workName"
                                    value={WorkTextField.workName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                                {/* <span className="text-danger error-danger">{formErrors.workNameError}</span> */}
                            </div>
                        </div>


                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                     onClick={() => { popupBtn === 'Clear' ? handleClear() : handleAddCloseClick() }}>
                                    <X size={18} style={{ marginRight: "3px" }} />{popupBtn}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => handleAddUpdate()}
                                    disabled={
                                        WorkTypeDDL.ID === 0 ||
                                        WorkTextField.workName === ""
                                    }
                                >
                                    {popupFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popupFlag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}