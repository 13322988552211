import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Progressbar from "./Progressbar";

const DashboardDetailsCard = (props) => {
  const { title, count, perentage, status, iconClass, icon, iconColor, path, bgColor, Flag ,cardName} = props;

  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate(`${path}?projectType=${cardName}&title=${title}&Flag=${Flag}`);

  };

  return (
    <div className="col-12 col-lg-3 col-sm-6 mb-md-3">
      <div className="card m-1 h-100 cursor-pointer" style={{ borderBottomRightRadius: "27px" }}>
        <span onClick={handleOnClick} className="card-body">
          <div className="stats">
            <i className={iconClass}></i>
            <p>{title}</p>
            <h2>{count}</h2>
          </div>
          {/* <div className="compare">
            <span className={iconColor}>
              {icon}
              {perentage}%
            </span>
            {status}
          </div> */}
        </span>
        {/* <Progressbar bgcolor="orange" progress={perentage} childBg={bgColor}  height={5} /> */}
      </div>
    </div>


  );
};

export default DashboardDetailsCard;
