
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"



export default function GenderDDL(props) {
    const { genderDDL, setGenderDDL, DDLData, FlagName, editData, mendetary } = props
    // console.log(editData)
    useEffect(() => {
        handleGenderDDL()
    }, [DDLData])

    const handleGenderDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {

            let list = DDLData.table.map((item, index) => ({

                // value: item.departmentCode,
                value: item.indicatorID,
                label: item.indicatorName,
            }))
            setGenderDDL({
                DDL: list,
                ID: FlagName === 'Update' ? editData.genderIndicatorID : 0,
                Label: FlagName === 'Update' ? editData.genderIndicatorName : "Select..."
            })
        }
        else {
            setGenderDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Gender { mendetary === 'yes' ? <Star/> : '' }</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: genderDDL.ID, label: genderDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setGenderDDL({ ...genderDDL, ID: e.value, Label: e.label })
                            :
                            setGenderDDL({ ...genderDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={genderDDL.DDL}

                />
            </div>
        </>
    )
}