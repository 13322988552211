import { BaseUrl } from "../../Helper/BaseUrl";

// -----------------------Department DDl--------------



export function Get_DDL_M_Department({UserID,departmentDDL,setdepartmentDDL,departmentFlag }) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
   
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Department?M_DeptOfficeTypeIndicatorID=${departmentFlag ? departmentFlag:"1"}&M_UsersID=${UserID}`, requestOptions)
 
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.departmentName,
                }))

                setdepartmentDDL({ ...departmentDDL, DDL: list })

            } else {
                setdepartmentDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setdepartmentDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}


// -------------------------Designation DDL----------------

export function Get_DDL_M_Designation({UserID,designationDDL,setdesignationDDL}) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
   
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Designation?M_DeptOfficeTypeIndicatorID=1&M_UsersID=${UserID}`, requestOptions)
 
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.designationName,
                }))

                setdesignationDDL({ ...designationDDL, DDL: list })

            } else {
                setdesignationDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setdesignationDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

    // ----------------------------------------- Get_DDL_M_FinancialYear -------------------------------- 

export function Get_DDL_M_FinancialYear({ setfinancialYearDDL, financialYearDDL, UserID, }) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

    fetch(` ${BaseUrl}/CommonDLLData/Get_DDL_M_FinancialYear?M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.financialYearID,
                    label: item.financialYear,
                }))

                setfinancialYearDDL({ ...financialYearDDL, DDL: list })

            } else {
                setfinancialYearDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setfinancialYearDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// ===================  Get_DDL_M_Indicator ========================= 

export function Get_DDL_M_Indicator({ setuserStatusDDL, userStatusDDL, UserID, }) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

    fetch(` ${BaseUrl}/CommonDLLData/Get_DDL_M_Indicator?GroupID=2&M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.indicatorID,
                    label: item.indicatorName,
                }))

                setuserStatusDDL({ ...userStatusDDL, DDL: list })

            } else {
                setuserStatusDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setuserStatusDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// -------------------------Budget Type DDL----------------------------
export function Get_DDL_M_BudgetType({ UserID,budgetTypeDDL,setbudgetTypeDDL}) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

    
    fetch(` ${BaseUrl}/CommonDLLData/Get_DDL_M_BudgetType?M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.budgetTypeName,
                }))

                setbudgetTypeDDL({ ...budgetTypeDDL, DDL: list })

            } else {
                setbudgetTypeDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setbudgetTypeDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// --------------Role DDL------------

export function Get_DDL_M_Role({ UserID,roleDDL,setroleDDL}) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Role?M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.roleName,
                }))

                setroleDDL({ ...roleDDL, DDL: list })

            } else {
                setroleDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setroleDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// -----------------Employee Name DDL-----------------

export function Get_DDL_M_Employee({UserID,setemployeeDDL, employeeDDL,departmentDDL,designationDDL}) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Employee?M_DepartmentID=${departmentDDL ? departmentDDL.ID : "0"}&M_DesignationID=${designationDDL ? designationDDL.ID : "0"}&M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.employeeName,
                }))

                setemployeeDDL({ ...employeeDDL, DDL: list })

            } else {
                setemployeeDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setemployeeDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// --------------------------Project Type DDL-----------

export function Get_DDL_M_ProjectType({UserID,setProjectTypeDDL,ProjectTypeDDL}) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

   
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectType?M_ProjectTypeID=${ProjectTypeDDL && ProjectTypeDDL.ID ? ProjectTypeDDL.ID : "0"}&M_UsersID=${UserID}`, requestOptions)  
    .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.projectTypeName,
                }))

                setProjectTypeDDL({ ...ProjectTypeDDL, DDL: list })

            } else {
                setProjectTypeDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setProjectTypeDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}


// --------------Budget code DDL------------

export function Get_DDL_M_Budget_Code({budgetCodeDDL,setbudgetCodeDDL, UserID,budgetTypeDDL}) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Budget?M_BudgetTypeID=${budgetTypeDDL.ID}&M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.budgetCode,
                }))

                setbudgetCodeDDL({ ...budgetCodeDDL, DDL: list })

            } else {
                setbudgetCodeDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setbudgetCodeDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// --------------work type DDL------------

export function Get_DDL_M_WorkType({setworkDDL,workDDL,UserID}) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
     
    fetch(` ${BaseUrl}/CommonDLLData/Get_DDL_M_WorkType?M_WorkTypeID=${workDDL.ID}&M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.workTypeName,
                }))

                setworkDDL({ ...workDDL, DDL: list })

            } else {
                setworkDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setworkDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}


// ------------------------Budget Head Name DDL-----------

export function Get_DDL_M_Budget({ UserID,budgetHeadDDL,setbudgetHeadDDL,budgetTypeDDL}) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

    fetch(` ${BaseUrl}/CommonDLLData/Get_DDL_M_Budget?M_BudgetTypeID=${budgetTypeDDL ? budgetTypeDDL.ID : "0"}&M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.budgetName,
                    code :item.budgetCode
                }))

                setbudgetHeadDDL({ ...budgetHeadDDL, DDL: list })

            } else {
                setbudgetHeadDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setbudgetHeadDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// -----------------------Project Name DDL-------------------
export function Get_DDL_M_Project({UserID,projectNameDDL,setprojectNameDDL,projectTypeDDL }) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
   
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Project?M_ProjectTypeID=${projectTypeDDL ? projectTypeDDL.ID : "0"}&M_UsersID=${UserID}`, requestOptions)
 
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.projectName,
                }))

                setprojectNameDDL({ ...projectNameDDL, DDL: list })

            } else {
                setprojectNameDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setprojectNameDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// ---------------------------------Project Priority Name------
export function Get_DDL_M_ProjectPriority({UserID,projectPriorityDDL,setprojectPriorityDDL }) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
   
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectPriority?M_UsersID=${UserID}`, requestOptions)
 
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.projectPriorityName,
                }))

                setprojectPriorityDDL({ ...projectPriorityDDL, DDL: list })

            } else {
                setprojectPriorityDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setprojectPriorityDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// ----------------------- work Name DDL -------------------
export function Get_DDL_M_Work({UserID,workNameDDL, setworkNameDDL, workTypeDDL}) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Work?M_WorkTypeID=${workTypeDDL && workTypeDDL.ID ? workTypeDDL.ID : "0"}&M_UsersID=${UserID}`, requestOptions)
 
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.workName,
                }))

                setworkNameDDL({ ...workNameDDL, DDL: list })

            } else {
                setworkNameDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setworkNameDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}


// --------------Project wise work section Project Type DDL-------------

export function Get_DDL_T_ProjectDefine_ProjectType({UserID,setprojectTypeDDL,projectTypeDDL}) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_T_ProjectDefine_ProjectType?M_UsersID=${UserID}`, requestOptions)  
    .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.projectTypeName,
                }))

                setprojectTypeDDL({ ...projectTypeDDL, DDL: list })

            } else {
                setprojectTypeDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setprojectTypeDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// ---------------------- Project wise work section Project Name DDL-------------
export function Get_DDL_T_ProjectDefine_ProjectName({UserID,projectNameDDL,setprojectNameDDL,projectTypeDDL }) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_T_ProjectDefine_ProjectName?ProjectTypeID=${projectTypeDDL && projectTypeDDL.ID ?projectTypeDDL.ID : "0"}&M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.projectName,
                }))

                setprojectNameDDL({ ...projectNameDDL, DDL: list })

            } else {
                setprojectNameDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setprojectNameDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// ---------------------- Project  work wise  work creation -------------

export function Get_DDL_T_WorkDefine_WorkType({UserID,setworkTypeDDL, workTypeDDL }) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
   
    fetch(` ${BaseUrl}/CommonDLLData/Get_DDL_T_WorkDefine_WorkType?M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.workTypeName,
                }))

                setworkTypeDDL({ ...workTypeDDL, DDL: list })

            } else {
                setworkTypeDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setworkTypeDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}

// ---------------------- Project work wise work creation name ddl -------------

export function Get_DDL_T_WorkDefine_WorkName({UserID,setworkNameDDL, workNameDDL,workTypeDDL}) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    fetch(` ${BaseUrl}/CommonDLLData/Get_DDL_T_WorkDefine_WorkName?M_WorkTypeID=${workTypeDDL.ID}&M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.m_WorkID,
                    label: item.workName,
                }))

                setworkNameDDL({ ...workNameDDL, DDL: list })

            } else {
                setworkNameDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setworkNameDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}


// -------------------------------- Contractor Name DDL-----------------

export function Get_DDL_T_AssignWorkToContractorName({UserID,setcontractorNameDDL,contractorNameDDL}) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_T_AssignWorkToContractorName?M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.contractorID,
                    label: item.contractorName,
                }))

                setcontractorNameDDL({ ...contractorNameDDL, DDL: list })

            } else {
                setcontractorNameDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setcontractorNameDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}


// ---------------------Work Area DDL---------------

export function Get_DDL_WorkArea({UserID,setworkAreaDDL, workAreaDDL,workNameDDL }) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
//    console.log(workNameDDL.ID)
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_WorkArea?T_WorkDefineID=${workNameDDL.ID}&M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.id,
                    label: item.workArea,
                }))

                setworkAreaDDL({ ...workAreaDDL, DDL: list })

            } else {
                setworkAreaDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setworkAreaDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}


// ============================ work area ddl for budget creation and milestne ======================== 

export function Get_DDL_T_WorkDefine_WorkArea({UserID,setworkAreaDDL, workAreaDDL,workNameDDL }) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/CommonDLLData/Get_DDL_T_WorkDefine_WorkArea?M_WorkID=${workNameDDL.ID}&M_UsersID=${UserID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                let list = result.data.table.map((item) => ({
                    value: item.m_WorkID,
                    label: item.workArea,
                }))

                setworkAreaDDL({ ...workAreaDDL, DDL: list })

            } else {
                setworkAreaDDL({
                    DDL: [],
                    ID: 0,
                    Label: "---Select---"
                })
            }
        })
        .catch(error => {
            setworkAreaDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
            console.log('error', error)
        });
}



