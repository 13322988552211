import React, { useState } from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { useDispatch } from "react-redux";

export default function PhysicalFinancialWorkProgressPopUp({ open, handleAddCloseClick, PopUpField, handlePost }) {

  const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField
  const userDetails = useAuthState();
  const { UserID, token } = userDetails
  const dispatch = useDispatch();


  const [physicalFinancilTextField, setphysicalFinancilTextField] = useState(
    {
      workCompleteDate: "",
      financialProgress: "",
      workDoneGeo: "",
      workOrdereDate: "",
      dateOfCompletion: "",
      administrativeApprovalDate: "",
      firstExtensionDate: "",
      secondExtensionDate: "",
      thirdExtensionDate: ""
    }
  )
  const [ProjectNameDDL, setProjectNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [contractorNameDDL, setcontractorNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [workNameDDL, setworkNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [workDateNameDDL, setworkDateNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [mileStoneDDL, setmileStoneDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [WorkTimeLimitDDL, setWorkTimeLimitDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })


  const [projectStatusDDL, setprojectStatusDDL] = useState({
    DDL: [
      { value: 'Ongoing', label: 'Ongoing' },
      { value: 'Completed', label: 'Completed' },
    ],
    ID: 0,
    Label: "---Select---"
  })


  const handleInputChange = (e) => {
    setphysicalFinancilTextField({ ...physicalFinancilTextField, [e.target.name]: e.target.value })
  }

  const handleClearField = () => {
    setphysicalFinancilTextField(
      {
        workCompleteDate: "",
        financialProgress: "",
        workDoneGeo: "",
      }
    )
  }

  const [baseImage, setBaseImage] = useState("")
  const [photoDocuments, setphotoDocuments] = useState("")
  const [baseImageSizeError, setBaseImageSizeError] = useState("")

  const beforeWorkImage = async (e) => {
    const file = e.target.files[0]
    // console.log(file);
    const base64 = await convertBeforImageBase64(file)
    setBaseImage(base64)
  }

  const convertBeforImageBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
        setphotoDocuments(fileReader.result.split(',')[1])
      }

      fileReader.onerror = (error) => {
        reject(error)
      }

      // 16000000 = 2mb 
      if (file.size >= "16000000") {
        setBaseImageSizeError("Please upload a file smaller than 2 MB");
      }

    })
  }
  // console.log(baseImageSizeError);


  const [onGoingImage, setonGoingImage] = useState("")
  const [onGoingDocument, setonGoingDocument] = useState("")
  const [onGoingImageSizeError, setonGoingImageSizeError] = useState("")


  const onGoingWorkImage = async (e) => {
    const file = e.target.files[0]
    // console.log(file);
    const base64 = await convertOnGoingImageBase64(file)
    setonGoingImage(base64)
  }

  const convertOnGoingImageBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
        setonGoingDocument(fileReader.result.split(",")[1])
      }

      fileReader.onerror = (error) => {
        reject(error)
      }

      if (file.size >= "16000000") {
        setonGoingImageSizeError("Please upload a file smaller than 2 MB")
      }

    })
  }
  // console.log(onGoingDocument);

  const [afterImage, setafterImage] = useState("")
  const [afterDocument, setafterDocument] = useState("")
  const [afterImageSizeError, setafterImageSizeError] = useState("")

  const afterWorkImage = async (e) => {
    console.log(e);
    const file = e.target.files[0]
    // console.log(file);
    const base64 = await convertAfterImageBase64(file)
    setafterImage(base64)
  }

  const convertAfterImageBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
        setafterDocument(fileReader.result.split(",")[1])
      }

      fileReader.onerror = (error) => {
        reject(error)
      }

      if (file.size >= "16000000") {
        setafterImageSizeError("Please upload a file smaller than 2 MB")
      }

    })
  }
  console.log(afterDocument);

  const [workVideos, setworkVideos] = useState("")
  const [workDocument, setworkDocument] = useState("")
  const [workVideoError, setworkVideoError] = useState("")

  const workVideo = async (e) => {
    const video = e.target.files[0]
    // console.log(video);
    const base64 = await convertVideoBase64(video)
    setworkVideos(base64)
  }

  const convertVideoBase64 = (video) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader
      fileReader.readAsDataURL(video)

      fileReader.onload = () => {
        resolve(fileReader.result)
        setworkDocument(fileReader.result.split(",")[1])
      }

      fileReader.onerror = (error) => {
        reject(error)
      }

      // 120000000 = 15mb
      if (video.size > "120000000") {
        // console.log(video.size);
        setworkVideoError("Please upload a file smaller than 15 MB")
      }

    })
  }
  console.log(workDocument);

  // const fileChangedHandler = event => {
  //   let file = event.target.files[0];
  //   let reader = new FileReader();
  //   // console.log(file);
  //   reader.onload = function (e) {
  //     setBaseImage(e.target.result);
  //     // split use to remove image path address 
  //     setphotoDocuments(reader.result.split(',')[1])
  //     // console.log(PhotoDocuments);
  //   };
  //   reader.readAsDataURL(event.target.files[0]);

  //   //  if (file != ".png" || file !=".jpg" ) {
  //   //       window.alert("File does not support. You must use .png or .jpg ");
  //   //       return false;
  //   //    }
  //   if (file.size >= 2e+6) {
  //     setBaseImageSizeError("Please upload a file smaller than 2 MB");
  //     // console.log(baseImageSizeError);
  //   }
  // };

  console.log(photoDocuments);

  return (

    <div>
      <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>
        <span className="close" onClick={handleAddCloseClick}>
          &times;
        </span>
        {/* <img src={baseImage} width="100px" alt="" />
        <img src={onGoingImage} width="100px" alt="" />
        <img src={afterImage} width="100px" alt="" /> */}
        <div className="call"> Add Physical Work Progress</div>
        <div className="modal-body react_popup">
          <div className="row details-row">

            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="workTimeLimit">Project ERP Code  </label>
                <input
                  className="form-control"
                  id="workTimeLimit"
                  type="text"
                  name="workTimeLimit"
                  // value={contractorWorkTextField.workTimeLimit}
                  // onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="workTimeLimit">Project Name   </label>
                <input
                  className="form-control"
                  id="workTimeLimit"
                  type="text"
                  name="workTimeLimit"
                  // value={contractorWorkTextField.workTimeLimit}
                  // onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="workTimeLimit">Contractor Name    </label>
                <input
                  className="form-control"
                  id="workTimeLimit"
                  type="text"
                  name="workTimeLimit"
                  // value={contractorWorkTextField.workTimeLimit}
                  // onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="workTimeLimit">No. of Milestones Completed </label>
                <input
                  className="form-control"
                  id="workTimeLimit"
                  type="text"
                  name="workTimeLimit"
                  // value={contractorWorkTextField.workTimeLimit}
                  // onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="workTimeLimit">Project Status </label>
                <input
                  className="form-control"
                  id="workTimeLimit"
                  type="text"
                  name="workTimeLimit"
                  // value={contractorWorkTextField.workTimeLimit}
                  // onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="workTimeLimit">Physical Work Status (%)</label>
                <input
                  className="form-control"
                  id="workTimeLimit"
                  type="text"
                  name="workTimeLimit"
                  // value={contractorWorkTextField.workTimeLimit}
                  // onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="workTimeLimit">Project Remark </label>
                <input
                  className="form-control"
                  id="workTimeLimit"
                  type="text"
                  name="workTimeLimit"
                  // value={contractorWorkTextField.workTimeLimit}
                  // onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="d-block" htmlFor="workTimeLimit">Department Remark </label>
                <input
                  className="form-control"
                  id="workTimeLimit"
                  type="text"
                  name="workTimeLimit"
                  // value={contractorWorkTextField.workTimeLimit}
                  // onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>

            <div className="col-12 col-lg-12">
              <div className="btn-action">
                <button type="button" className="btn btn-md btn-clear mx-2 float-right"><X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                <button
                  type="button"
                  className="btn btn-md btn-primary mx-2 float-right"
                  onClick={() => console.log(physicalFinancilTextField)}
                >{apiFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popupFlag}</button>
              </div>
            </div>
          </div>
        </div>


      </Popup>
    </div>
  )
}