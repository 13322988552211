import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";





export const ElectoralWardNumberTableDataAPI = createAsyncThunk("ElectoralWardNumberTableData", async ({ data }) => {
    const { M_WardWiseNumberID,
        M_WardID,
        UserID,
        WardNumber,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_WardWiseNumber_Select?M_WardWiseNumberID=${M_WardWiseNumberID ? M_WardWiseNumberID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&WardNumber=${WardNumber ? WardNumber : '0'}&M_UsersID=0&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ElectoralWardNumberTableDataSlice = createSlice({
    name: "ElectoralWardNumberTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ElectoralWardNumberTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ElectoralWardNumberTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ElectoralWardNumberTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ElectoralWardNumberTableDataReducer = ElectoralWardNumberTableDataSlice.reducer


// InsertUpdate Table Data

export const ElectoralWardNumberPostAPI = createAsyncThunk("ElectoralWardNumberPostData", async ({ data }) => {

    const {
        handleOnCloseClick,
        setIsPost,
        IsPost,
        handleOnClearClick,
        token,
        Flag,
        M_WardWiseNumberID,
        M_WardID,
        WardNumber,
        UserID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WardWiseNumberID", M_WardWiseNumberID);
    formdata.append("M_WardID", M_WardID);
    formdata.append("WardNumber", WardNumber);
    formdata.append("M_UsersID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_WardWiseNumber_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            handleOnCloseClick()
            handleOnClearClick()
            setIsPost(!IsPost)
        })
        .catch(err => {
            console.log(err)
        })
})

const ElectoralWardNumberPostSlice = createSlice({
    name: "ElectoralWardNumberPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ElectoralWardNumberPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ElectoralWardNumberPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(ElectoralWardNumberPostAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ElectoralWardNumberPostDataReducer = ElectoralWardNumberPostSlice.reducer



// Delete Table Data

export const DepartmentDeleteAPI = createAsyncThunk("DepartmentDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_DepartmentID", rowData?.m_DepartmentID);
    formdata.append("M_UserID", UserID);
    formdata.append("Flag", apiFlag);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/M_Department_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const DepartmentDeleteSlice = createSlice({
    name: "DepartmentDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(DepartmentDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            toastSuccesss(action.payload.message)
        });
        builder.addCase(DepartmentDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DepartmentDeleteReducer = DepartmentDeleteSlice.reducer