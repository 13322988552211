
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const AllColumnExportReport = (props) => {
    const { ExportData, name, title, projectType, FromDate, ToDate, departmentDDL, subDepartmentDDL, ProjectTypeDDL, ProjectDDL, wardNameDDL,
        electoralWardNumDDL, projectPriorityDDL, keyProjectDDL, tenderAmountTypeDDL,
    } = props

    // console.log('ExportData', ExportData)
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table={name == "Work Order Delay Report" ? "table-to-ExportUserCreation" : 'table-to-Export'}
                className="btn btn-success float-end download-table-xls-button float-end btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id={name == "Work Order Delay Report" ? "table-to-ExportUserCreation" : 'table-to-Export'} style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>
                <tr>
                    <th colSpan={15} style={{ fontSize: 16, textAlign: 'center' }}>Report Name :- {name == 'Work Order Delay Report' ? 'Work Order Delay Report' : 'All Column Reports'}</th>
                </tr>
                <tr>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>From Date :- {FromDate}</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>To Date:-  {ToDate}</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Department Name :- {departmentDDL}</th>
                </tr>
                <tr>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Sub Department Name :- {subDepartmentDDL}</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Project Type :- {ProjectTypeDDL}</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Project Name :- {ProjectDDL}</th>
                </tr>
                <tr>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Ward Name :- {wardNameDDL}</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Electoral Ward Number :- {electoralWardNumDDL}</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Project Priority :- {projectPriorityDDL}</th>
                </tr>
                <tr>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Key Project :- {keyProjectDDL}</th>
                    <th colSpan={5} style={{ fontSize: 16, textAlign: 'left' }}>Tender Amount Type :- {tenderAmountTypeDDL}</th>
                </tr>
                {/* <tr><td></td></tr> */}

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#254462', color: "#fff" }}>
                        <th style={{ width: "5%", borderRight: '1px solid white' }} >Sr.No.</th>
                        <th>Department Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Sub Department Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Main Work Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Sub Work Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Ward Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Electroal <br />Ward<br /> Number</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Tracker Code</th>
                        <th style={{ borderLeft: "1px solid white" }}>File No</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project ERP Code</th>
                        <th style={{ borderLeft: "1px solid white" }}>Budget Code</th>
                        <th style={{ borderLeft: "1px solid white" }}>Budget Head</th>
                        <th style={{ borderLeft: "1px solid white" }}>Budget Provision</th>
                        <th style={{ borderLeft: "1px solid white" }}>Administration Approval Date</th>
                        <th style={{ borderLeft: "1px solid white" }}>Admin Approval Cost</th>
                        <th style={{ borderLeft: "1px solid white" }}>Tender Stage</th>
                        <th style={{ borderLeft: "1px solid white" }}>Tender Floated Date</th>
                        <th style={{ borderLeft: "1px solid white" }}>Tender Amount Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Tender Amount</th>
                        <th style={{ borderLeft: "1px solid white" }}>Amount Paid</th>
                        <th style={{ borderLeft: "1px solid white" }}>Municipal Councilor</th>
                        <th style={{ borderLeft: "1px solid white" }}>Agency Or Contractor Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>No Of Milestones</th>
                        <th style={{ borderLeft: "1px solid white" }}>Milestones Completed</th>
                        <th style={{ borderLeft: "1px solid white" }}>Work Order Date</th>
                        <th style={{ borderLeft: "1px solid white" }}>Work Completion Date</th>
                        <th style={{ borderLeft: "1px solid white" }}>First Extension Date</th>
                        <th style={{ borderLeft: "1px solid white" }}>Second Extension Date</th>
                        <th style={{ borderLeft: "1px solid white" }}>Third Extension Date</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Remark</th>
                        <th style={{ borderLeft: "1px solid white" }}>Department Remark</th>
                        <th style={{ borderLeft: "1px solid white" }}>Physical Work Progress</th>
                        <th style={{ borderLeft: "1px solid white" }}>Financial Work Progress</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Priority</th>
                        <th style={{ borderLeft: "1px solid white" }}>Key Project</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ExportData && ExportData.length > 0 ? ExportData.map((i) => (
                            <tr style={{ textAlign: "center", border: '1px solid black' }}>
                                <td className="text_center">{i.rowNum ? i.rowNum : '-'}</td>
                                <td>{i.departmentName ? i.departmentName : '-'}</td>
                                <td>{i.subDepartmentName ? i.subDepartmentName : '-'}</td>
                                <td>{i.projectTypeName ? i.projectTypeName : '-'}</td>
                                <td>{i.workTypeName ? i.workTypeName : '-'}</td>
                                <td>{i.subWorkTypeName ? i.subWorkTypeName : '-'}</td>
                                <td>{i.wardName ? i.wardName : '-'}</td>
                                <td style={{ textAlign: 'right' }}>{i.wardWiseNumber ? i.wardWiseNumber : '-'}</td>
                                <td>{i.projectName ? i.projectName : '-'}</td>
                                <td>{i.project_Tracker_Code ? i.project_Tracker_Code : '-'}</td>
                                <td>{i.lfmS_File_No ? i.lfmS_File_No : '-'}</td>
                                <td>{i.project_ERP_Code ? i.project_ERP_Code : '-'}</td>
                                <td>{i.budgetCode ? i.budgetCode : '-'}</td>
                                <td>{i.budgetName ? i.budgetName : '-'}</td>
                                <td style={{ textAlign: 'right' }}>{i.budget_Provision ? i.budget_Provision : '-'}</td>
                                <td>{i.administrativeApproveDate ? moment(i.administrativeApproveDate).format('DD-MM-YYYY') : '-'}</td>
                                <td style={{ textAlign: 'right' }}>{i.administrativeApproveCost ? i.administrativeApproveCost.toFixed(2) : '0'}</td>
                                <td>{i.tender_Stages ? i.tender_Stages : '-'}</td>
                                <td>{i.dateOfTenderFloated ? moment(i.dateOfTenderFloated).format('DD-MM-YYYY') : '-'}</td>
                                <td>{i.tender_Amount_Type ? i.tender_Amount_Type : '-'}</td>
                                <td style={{ textAlign: 'right' }}>{i.tendarCost ? i.tendarCost.toFixed(2) : 0}</td>
                                <td style={{ textAlign: 'right' }}>{i.actualCost ? i.actualCost.toFixed(2) : 0}</td>
                                <td>{i.municipalCouncilor ? i.municipalCouncilor : '-'}</td>
                                <td>{i.contractor_Name ? i.contractor_Name : '-'}</td>
                                <td style={{ textAlign: 'right' }}>{i.numberOfMileStone ? i.numberOfMileStone : 0}</td>
                                <td style={{ textAlign: 'right' }}>{i.completedMilestone ? i.completedMilestone : 0}</td>
                                <td>{i.work_Start_Date ? moment(i.work_Start_Date).format('DD-MM-YYYY') : '-'}</td>
                                <td>{i.work_Completion_Date ? moment(i.work_Completion_Date).format('DD-MM-YYYY') : '-'}</td>
                                <td>{i.extenstionDate1 ? moment(i.extenstionDate1).format('DD-MM-YYYY') : '-'}</td>
                                <td>{i.extenstionDate2 ? moment(i.extenstionDate2).format('DD-MM-YYYY') : '-'}</td>
                                <td>{i.extenstionDate3 ? moment(i.extenstionDate3).format('DD-MM-YYYY') : '-'}</td>
                                <td>{i.projectRemark ? i.projectRemark : '-'}</td>
                                <td>{i.financialProjectRemark ? i.financialProjectRemark : '-'}</td>
                                <td>{i.physicalProgress ? `${i.physicalProgress.toFixed(2)} %` : '0 %'}</td>
                                <td>{i.financialProgress ? `${i.financialProgress} %` : '0 %'}</td>
                                <td>{i.projectPriorityName ? i.projectPriorityName : '-'}</td>
                                <td>{i.key_Project ? i.key_Project : '-'}</td>
                                <td>{i.workStatus ? i.workStatus : '-'}</td>
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}