
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"
import { ZoneDDLAPI } from "../../Redux/DDLSlice"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../Helper/Context/context"

// const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token}

export default function ZoneDDL(props) {
    const { zoneDDL, setZoneDDL, editData, FlagName, apiFlag, mendetary} = props

    const userDetails = useAuthState();
    const {  UserID, token } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        // const data = { UserID :FlagName && FlagName == 'Master' ? '0' : UserID, token: token }
        const data = { UserID :UserID, token: token }
        dispatch(ZoneDDLAPI({ data }))
      }, [])
    
      const { ZoneDDLData } = useSelector(state => state.ZoneDDLData)

      

    useEffect(() => {
        handleZoneDDL()
    }, [ZoneDDLData])

    const handleZoneDDL = () => {
        if (ZoneDDLData && ZoneDDLData.table && ZoneDDLData.table.length > 0) {

            let list = ZoneDDLData.table.map((item, index) => ({

                // value: item.departmentCode,
                value: item.id,
                label: item.m_ZoneName,
            }))
            setZoneDDL({
                DDL: list,
                ID: apiFlag === 'Update' ? editData.m_ZoneID : 0,
                Label: apiFlag === 'Update' ? editData.m_ZoneName : "Select..."
            })
        }
        else {
            setZoneDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Zone {mendetary === 'yes' ? <Star/> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: zoneDDL.ID, label: zoneDDL.Label }}
                    onChange={(e) => {
                        e ?
                        setZoneDDL({ ...zoneDDL, ID: e.value, Label: e.label })
                            :
                            setZoneDDL({ ...zoneDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={zoneDDL.DDL}

                />
            </div>
        </>
    )
}