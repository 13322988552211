import React, { useReducer, useState } from 'react';
import { initialState, AuthReducer } from './reducer';

const AuthStateContext = React.createContext();
const AuthStateYearMonthContext = React.createContext();
const AuthDispatchContext = React.createContext();

export function useAuthState() {
	const context = React.useContext(AuthStateContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within a AuthProvider');
	}

	return context;
}

export function useAuthStateYearMonth() {
	const context = React.useContext(AuthStateYearMonthContext);
	if (context === undefined) {
		throw new Error('useAuthStateYearMonth must be used within a AuthProvider');
	}

	return context;
}


export function useAuthDispatch() {
	const context = React.useContext(AuthDispatchContext);
	if (context === undefined) {
		throw new Error('useAuthDispatch must be used within a AuthProvider');
	}

	return context;
}

export const AuthProvider = ({ children }) => {
	const [year, setYear] = useState({
		DDL: [],
		ID: 0,
		Label: "Select .....",
	})
	const [Month, setMonth] = useState({
		DDL: [],
		ID: 0,
		Label: "Select .....",
	})

	const [user, dispatch] = useReducer(AuthReducer, initialState);

	return (
		<AuthStateContext.Provider value={user}>
			<AuthStateYearMonthContext.Provider value={{ year, setYear, Month, setMonth}}>
				<AuthDispatchContext.Provider value={dispatch}>
					{children}
				</AuthDispatchContext.Provider>
			</AuthStateYearMonthContext.Provider>
		</AuthStateContext.Provider>
	);
};
