import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";





export const EmployeeTableDataAPI = createAsyncThunk("EmployeeTableData", async ({ data }) => {
    const {
        M_EmployeeID,
        M_DeptOfficeTypeIndicatorID,
        EmployeeName,
        M_DepartmentID,
        M_DesignationID,
        M_UsersID,
        DateOfBirth,
        GenderIndicatorID,
        From,
        token,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Employee_Select?M_EmployeeID=${M_EmployeeID ? M_EmployeeID : '0'}&M_DeptOfficeTypeIndicatorID=${M_DeptOfficeTypeIndicatorID ? M_DeptOfficeTypeIndicatorID : '0'}&EmployeeName=${EmployeeName ? EmployeeName : ''}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&M_UsersID=${M_UsersID ? M_UsersID : '0'}&DateOfBirth=${DateOfBirth ? DateOfBirth : '0'}&GenderIndicatorID=${GenderIndicatorID ? GenderIndicatorID : '0'}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EmployeeTableDataSlice = createSlice({
    name: " EmployeeTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(EmployeeTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EmployeeTableDataReducer = EmployeeTableDataSlice.reducer


// InsertUpdate Table Data

export const EmployeePostAPI = createAsyncThunk("EmployeePostData", async ({ data }) => {

    const {
        M_EmployeeID,
        EmployeeName,
        M_DepartmentID,
        handleOnCloseClick,
        JoiningDate,
        setIsPost,
        IsPost,
        EmailID,
        MobileNumber,
        GenderIndicatorID,
        DateOfBirth,
        handleOnClearClick,
        M_DesignationID,
        M_UsersID,
        token,
        Flag,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_EmployeeID", M_EmployeeID);
    formdata.append("EmployeeName", EmployeeName);
    formdata.append("M_DepartmentID", M_DepartmentID);
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("JoiningDate", JoiningDate);
    formdata.append("EmailID", EmailID);
    formdata.append("MobileNumber", MobileNumber);
    formdata.append("GenderIndicatorID", GenderIndicatorID);
    formdata.append("DateOfBirth", DateOfBirth);
    formdata.append("Login_UserID", M_UsersID);
    formdata.append("Flag", Flag);
    // console.log(IsPost)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_Employee_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                toastSuccesss(result.message)
            }
            else {
                toastErrorr(result.message)
            }
            setIsPost(!IsPost)
            handleOnCloseClick()
            handleOnClearClick()
        })
        .catch(err => {
            console.log(err)
        })
})

const EmployeePostDataSlice = createSlice({
    name: "EmployeePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeePostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeePostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(EmployeePostAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EmployeePostDataReducer = EmployeePostDataSlice.reducer



// Delete Table Data

export const EmployeeDeleteAPI = createAsyncThunk("EmployeeDelete", async ({ data }) => {
    const {
        handlePost,
        token,
        UserID,
        setIsPost,
        IsPost,
        handleOnCloseClick,
        M_EmployeeID,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_EmployeeID", M_EmployeeID);
    formdata.append("UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Post_M_Employee_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setIsPost(!IsPost)
            handlePost()
            handleOnCloseClick()
            return result

        })
})

const EmployeeDeleteSlice = createSlice({
    name: "EmployeeDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(EmployeeDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            toastSuccesss(action.payload.message)
        });
        builder.addCase(EmployeeDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EmployeeDeleteReducer = EmployeeDeleteSlice.reducer