import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function DeletePopUp({ open, handleOnCloseClick, flag, handleDeleteData }) {
    return (
        <div >
            <Popup open={open} closeOnDocumentClick onClose={handleOnCloseClick} className="DeletePopUp DeletePopUp-content">
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call "> Do You Want To Delete ? </div>
                <div className="modal-body">
                    <div className="col-12 col-lg-12">
                        <div className="btn-action d-flex justify-content-center mt-4" >
                            {/* <button type="button" className="btn btn-md btn-danger m-2 float-right" onClick={() => handleDeletePost()}>Yes</button> */}

                            <button
                                type="button"
                                className="btn btn-md btn-danger m-2 float-right"
                                onClick={() => handleDeleteData()}
                            >Yes</button>
                            <button type="button" 
                            className="btn btn-md btn-primary m-2 float-right"
                            onClick={handleOnCloseClick}
                            >No</button>
                        </div>
                    </div>
                </div>

            </Popup>
        </div>


    )
}