import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function UtilizationDetailsPopUp({ flag, open, handleOnCloseClick }) {

    return (

        <div>
            <Popup open={open} closeOnDocumentClick onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call font-weight-bold" style={{color:"#41692d",fontSize:"1.4rem"}}> Utilization Details </div>
                
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="project-block">
                                    <p>Name of Project</p>
                                    <h4>Project Name goes here</h4>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="project-block">
                                    <p>Department</p>
                                    <h4>Department Name goes here</h4>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="project-block">
                                    <p>Area</p>
                                    <h4>Area123</h4>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="project-block">
                                    <p>Total Cost</p>
                                    <h4>Rs. 15L</h4>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="project-block">
                                    <p>Budget Allocated</p>
                                    <h4>Rs. 15 L</h4>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="project-block">
                                    <p>Budget Consumed</p>
                                    <h4>10 L</h4>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="project-block">
                                    <p>Utilization No</p>
                                    <h4>U416123</h4>
                                </div>
                            </div>
                        </div>
                        <div class="tbl-sticky">
                            <table
                                cellpadding="0"
                                cellspacing="0"
                                border="0"
                                class="table table-bordered"
                            >
                                <thead>
                                    <tr>
                                        <th>Sr.No.</th>
                                        <th>Name of Milestones</th>
                                        <th>Date</th>
                                        <th>Budget Utilised</th>
                                        <th>Budget Utilised till Last UC</th>
                                        <th>Total Utilization</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Component here</td>
                                        <td>12/12/2022</td>
                                        <td>15</td>
                                        <td>10</td>
                                        <td>15</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Component here</td>
                                        <td>12/12/2022</td>
                                        <td>15</td>
                                        <td>10</td>
                                        <td>15</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Component here</td>
                                        <td>12/12/2022</td>
                                        <td>15</td>
                                        <td>10</td>
                                        <td>15</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                


            </Popup>
        </div>


    )
}