
import React, { useEffect, useState } from 'react'
import MobileHeader from '../../../../Components/Header/MobileHeader';
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import DepartmentDDL from '../../../../CommonDDL/DepartmentDDL/DepartmentDDL';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthState } from '../../../../Helper/Context/context';
import { DepartmentNameDDLAPI } from '../../../../Redux/DDLSlice';
import { TotalDepartmentTableDataAPI } from '../../../../Redux/DashboardSlice/TotalDepartmentsSlice/TotalDepartMentsSlice';
import { Pegination } from '../../../../Components/Pegination/Pegination';
import LoaderFile from '../../../../Helper/Loader/LoaderFile';
import { useSearchParams } from 'react-router-dom';
import { X } from 'react-feather';


export default function TotalDepartments({ props }) {
    const today = new Date();
    const year = today.getFullYear();


    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    const projectType = searchParams.get('projectType')
    const title = searchParams.get('title')
    const Flag = searchParams.get('Flag')

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [ExcelData, setExcelData] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [isopen, setIsOpen] = useState(true);

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });


    const handleOnClearClick = () => {
        setCurrentPage(0)
        setIsPost(!IsPost)
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: "---Select---"
        })

    }

    const handleAddCloseClick = () => {
        setPopUpField({ addPopUp: false })
    }

    const handleDeleteCloseClick = () => {
        setPopUpField({ deletePopUp: false })
    }
    const addButtonClick = () => {
        setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Add", popupBtn: "Clear", apiFlag: 'Insert', rowData: '' })
    }

    const editButtonClick = (item) => {
        setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Update", popupBtn: "Cancel", apiFlag: 'Update', rowData: item })

    }

    const deleteButtonClick = (item) => {
        setPopUpField({ ...PopUpField, addPopUp: false, deletePopUp: true, popupBtn: "", apiFlag: 'Delete', rowData: item })
    }

    const handleDeleteData = () => {
        // dispatch(UserCreationDeleteAPI({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }))
    }

    const { isDeleteLoading } = useSelector(state => state.UserCreationDeleteData)

    const handlePost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = { UserID, token }
        dispatch(DepartmentNameDDLAPI({ data }))
    }, [IsPost])

    const { DepartmentDDLData } = useSelector(state => state.DepartmentNameData)


    useEffect(() => {
        const data = {
            M_DepartmentID: departmentDDL.ID,
            DepartmentName: '',
            Dept_Code: "",
            M_DeptOfficeTypeIndicatorID: 0,
            M_UsersID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
        }
        dispatch(TotalDepartmentTableDataAPI({ data }))
    }, [To, IsPost, IsClear, departmentDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.TotalDepartmentTableData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [departmentDDL.ID])

    return (
        <>
            {isLoading && <LoaderFile />}
            <div className="wrapper">
                <div className="container-scroller">
                    <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                    <Header setIsOpen={setIsOpen} isopen={isopen} />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar active="dashboard" isopen={isopen} />
                        <div
                            className="main-panel"
                            style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                        >
                            <div className="content-wrapper">
                                {/* <DashboardHeading/> */}

                                <div className="row page-heading justify-content-between">
                                    <div className="col-6 col-lg-6 mb-xl-0 align-self-center align-items-center">
                                        <h4 className="font-weight-bold py-3">{title}</h4>

                                    </div>
                                </div>

                                <div className="col-12 shadow table-card">
                                    <div className=" filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <DepartmentDDL
                                                        departmentDDL={departmentDDL}
                                                        setdepartmentDDL={setdepartmentDDL}
                                                        DDLData={DepartmentDDLData}
                                                    // FlagName=''
                                                    />
                                                </div>



                                                <div className="col-12 col-lg-2 pl-lg-3">
                                                    <button type="button"
                                                        className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                    >
                                                      <X size={18} style={{ marginRight: "3px" }} />
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row px-3">
                                        <div className="col-md-12 grid-margin">
                                            <div className="table-responsive">

                                                <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                    <thead>
                                                        <tr style={{ textAlign: "center" }}>
                                                            <th width="5%">Sr.No.</th>
                                                            <th>Name of Department</th>
                                                            <th>Department Code</th>
                                                            <th>Contact Number</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.length > 0 ? tableData.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                                                    <td>{item.departmentName ? item.departmentName : "-"}</td>
                                                                    <td>{item.dept_Code ? item.dept_Code : "-"}</td>
                                                                    <td>{item.mobileNo ? item.mobileNo : "-"}</td>
                                                                </tr>
                                                            )) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>


                                <div className="copyright">
                                    Copyright {year}. all rights are reserved.<b>Version 1.0</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
