import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../Helper/Context/context";
import { Pegination } from "../../../../Components/Pegination/Pegination";
import { useEffect, useState } from "react";
import { CommonProjectOverViewMileStonePopupTableDataAPI } from "../../../../Redux/DashboardSlice/ProjectOverViewSlice/ProjectOverViewSlice";

const AddPopUpNumberOfMilestone = (props) => {
    const { cancelbtn, heading, GridData } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    // console.log('GridData', GridData)
    useEffect(() => {
        const data = {
            M_FinancialYearID: 0,
            M_MonthID: 0,
            T_PhysicalMilestoneWorkProgressID: GridData.t_ProjectSanctionDetailsID,
            M_UsersID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
        }
        dispatch(CommonProjectOverViewMileStonePopupTableDataAPI({ data }))
    }, [From])

    const { tableData, isLoading } = useSelector(state => state.CommonProjectOverViewMileStonePopupTableData)

    const handleOpenImage = (data) => {
        window.open(data)
    }

    return (<>
        <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090" }}>
            <div className="modal-dialog dialog_width " role="document" >
                <div className="modal-content p-4">
                    <div className="modal-header pt-0">

                        <h5 className="card-title text-dark fw-bold" id="exampleModalLongTitle">{heading}</h5>
                        <button onClick={() => cancelbtn()} type="button" className="close border_cancel_btn" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true" className=" py-0" style={{ color: "red" }}>&times;</span>
                        </button>
                    </div>
                    <hr className="bg-dark" />
                    <div className="modal-body mx-0">

                        <div className="row">
                            <div className="table-responsive">

                                <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                    <thead>
                                        <tr style={{ textAlign: "center" }}>
                                            <th width="5%">Sr.No.</th>
                                            <th>Milestone</th>
                                            <th>Before Photo</th>
                                            <th>After Photo</th>
                                            {/* <th>In progress</th>
                                            <th>Completed</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableData && tableData.length > 0 ? tableData.map((item, i) => (
                                                <tr>
                                                    <td className="text_center">{item?.rowNum}</td>
                                                    <td>{item.milestone_Name ? item.milestone_Name : '-'}</td>
                                                    <td className="text-center">
                                                        {
                                                            item?.beforePhotoPath == null ?
                                                                <>-</>
                                                                :
                                                                <i className="fa-solid fa-eye pl-3 cursor-pointer"
                                                                    onClick={() => handleOpenImage(item?.beforePhotoPath)}
                                                                >
                                                                </i>
                                                        }

                                                    </td>
                                                    <td className="text-center">
                                                        {
                                                            item?.afterPhoto == null ?
                                                                <>-</>
                                                                :
                                                                <i className="fa-solid fa-eye pl-3 cursor-pointer"
                                                                    onClick={() => handleOpenImage(item?.afterPhoto)}
                                                                >
                                                                </i>
                                                        }

                                                    </td>
                                                </tr>
                                            )) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 ml-auto mr-0">
                                {tableData && tableData.length > 0 &&
                                    <Pegination
                                        PerPageCount={PerPageCount}
                                        TotalCount={tableData[0].totalCount}
                                        setFrom={setFrom}
                                        setTo={setTo}
                                        setrowNo={setrowNo}
                                        CurrentPage={CurrentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}

export default AddPopUpNumberOfMilestone;

