import React, { useState, useEffect } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import EmployeePopUp from "./EmployeePopUp";
import Select from "react-select";
import moment from "moment";
import { Get_DDL_M_Designation } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";

import { Pegination } from "../../../Components/Pegination/Pegination";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Get_M_Employee_Select } from "../MasterApi/GETAPI";
import { Post_M_Employee_Delete } from "../MasterApi/POSTAPI";
import Footer from "../../../Components/Footer/Footer";
import EmployeeNameDDL from "../../../CommonDDL/EmployeeNameDDL/EmployeeNameDDL";
import DesignationNameDDL from "../../../CommonDDL/DesignationDDL/DesignationNameDDL";
import { useDispatch, useSelector } from "react-redux";
import { DesignationNameDDLAPI, EmployeeNameDDLAPI } from "../../../Redux/DDLSlice";
import { EmployeeDeleteAPI, EmployeeTableDataAPI } from "../../../Redux/MasterSlice/EmployeeSlice";
import GenderDDL from "../../../CommonDDL/GenderDDL/GenderDDL";

export default function Employee() {

  // const { UserID, DepartmentID, EmployeeID } = userDetails;

  const userDetails = useAuthState();
  const { UserID, token, DepartmentID, EmployeeID } = userDetails
  const dispatch = useDispatch();

  const [showCreatePopup, setshowCreatePopup] = useState(false);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [flag, setflag] = useState(false);
  const [isopen, setIsOpen] = useState(true);

  // react pagination state
  const [PerPageCount, setPerPageCount] = useState(10);
  const [TotalCount, setTotalCount] = useState(0);
  const [To, setTo] = useState(10);
  const [From, setFrom] = useState(1);
  const [rowNo, setrowNo] = useState(1);

  // employee api state
  const [gridData, setgridData] = useState([]);
  const [IsPost, setIsPost] = useState(false);
  const [loading, setloading] = useState(false);
  const [editData, seteditData] = useState("");
  const [PopUpFlag, setPopUpFlag] = useState();
  const [M_EmployeeID, setM_EmployeeID] = useState();
  const [CurrentPage, setCurrentPage] = useState(0);

  const [designationNameDDL, setDesignationNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  });
  const [employeeNameDDL, setEmployeeNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  });

  useEffect(() => {
    const data = { UserID, token }
    dispatch(EmployeeNameDDLAPI({ data }))
  }, [])

  const { EmployeeDDLData } = useSelector(state => state.EmployeeNameData)

  const handleOnCloseClick = () => {
    setshowCreatePopup(false);
    setshowDeletePopup(false);
  };
  const handleAddNewClick = () => {
    setshowCreatePopup(true);
    setflag("Insert");
    seteditData('')
    setPopUpFlag("Add");
  };
  const handleOnEditClick = (item) => {
    setshowCreatePopup(true);
    seteditData(item);
    setflag("Update");
    setPopUpFlag("Update");
  };

  const handleOnDeleteClick = (item) => {
    console.log(item)
    setshowDeletePopup(true);
    setflag("Delete");
    setM_EmployeeID(item.m_EmployeeID);
  };

  const [empolyeeTextField, setempolyeeTextField] = useState({
    employeeName: "",
  });

  const handleInputChange = (e) => {
    setempolyeeTextField({
      ...empolyeeTextField,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    handleOnCloseClick(false);
  };

  const handleOnSearchClick = () => {
    // setIsPost(!IsPost);
    setCurrentPage(0);
  };

  const handleOnClearClick = () => {
    setEmployeeNameDDL({
      ...employeeNameDDL,
      ID: 0,
      Label: 'Select...'
    })
    setDesignationNameDDL({
      ...designationNameDDL,
      ID: 0,
      Label: 'Select...'
    })
    setCurrentPage(0);
    setempolyeeTextField({
      employeeName: "",
    });
    // setIsPost(!IsPost);
  };

  const handlePost = () => {
    setIsPost(!IsPost);
  }

  const handleDeleteData = (item) => {
    const data = {
      handlePost: handlePost,
      setIsPost: setIsPost,
      IsPost: IsPost,
      token: token,
      UserID: UserID,
      handleOnCloseClick: handleOnCloseClick,
      M_EmployeeID: M_EmployeeID
    }
    dispatch(EmployeeDeleteAPI({ data }))
  };

  useEffect(() => {
    setCurrentPage(0)
    setFrom(1)
    setTo(10)
  }, [employeeNameDDL.ID, designationNameDDL.ID])

  useEffect(() => {
    const data = {
      M_EmployeeID: employeeNameDDL.ID,
      M_DeptOfficeTypeIndicatorID: 0,
      EmployeeName: '',
      M_DepartmentID: 0,
      M_DesignationID: designationNameDDL.ID,
      M_UsersID: UserID,
      DateOfBirth: '',
      GenderIndicatorID: 0,
      From: From,
      token: token,
      To: To,
    }
    dispatch(EmployeeTableDataAPI({ data }))
  }, [To, IsPost, employeeNameDDL.ID, designationNameDDL.ID])


  const { tableData, isLoading } = useSelector(state => state.EmployeeTableData)

  return (
    <>
      {isLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="employee" subMenu="subMaster" isopen={isopen} />
          <div
            className="main-panel"
            style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
          >
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">Employee</h4>
                    </div>
                    <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        title="Add"
                        onClick={() => handleAddNewClick()}
                      >
                        <i
                          className="fa-solid fa-circle-plus pr-2"
                          style={{ fontSize: "15px" }}
                        ></i>
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className="filter mb-0">
                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-12 col-md-6 col-lg-3">
                          <DesignationNameDDL
                            designationNameDDL={designationNameDDL}
                            setDesignationNameDDL={setDesignationNameDDL}
                            IsPost={IsPost}
                            FlagName=''
                            departmentDDL="0"
                          />
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <EmployeeNameDDL
                            employeeNameDDL={employeeNameDDL}
                            setEmployeeNameDDL={setEmployeeNameDDL}
                            designationNameDDL={designationNameDDL}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-lg-2 pl-lg-3 ">
                          <button
                            type="button"
                            className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick}
                          >
                            {/* <Search/> */}
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      className="table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th>Name of Employee </th>
                          <th>Department Name</th>
                          <th>Designation </th>
                          <th>Contact No. </th>
                          <th>Email ID</th>
                          <th>Gender</th>
                          <th>Date of Birth</th>
                          <th style={{ width: "8%",textAlign: "center" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData && tableData.table && tableData.table.length > 0
                          ? tableData.table.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">
                                {item.rowNum ? item.rowNum : "-"}
                              </td>
                              <td>
                                {item.employeeName ? item.employeeName : "-"}
                              </td>
                              <td>{item.departmentName ? item.departmentName : "-"}</td>
                              <td>
                                {item.designationName
                                  ? item.designationName
                                  : "-"}
                              </td>
                              <td>
                                {item.mobileNumber ? item.mobileNumber : "-"}
                              </td>
                              <td>{item.emailID ? item.emailID : "-"}</td>
                              <td>{item.genderIndicatorName ? item.genderIndicatorName : "-"}</td>
                              <td>{item.dateOfBirth ? moment(item.dateOfBirth).format("DD-MM-YYYY") : "-"}</td>

                              <td style={{ textAlign: "center" }}>
                                <span
                                  className="mx-2 cursor-pointer"
                                  title="Edit"
                                  onClick={() => handleOnEditClick(item)}
                                >
                                  <Edit size={15} color="#007bff" />
                                </span>

                                <span
                                  className="mx-2 cursor-pointer"
                                  title="Delete"
                                  onClick={() =>
                                    handleOnDeleteClick(item)
                                  }
                                >
                                  <Trash2 size={15} color="red" />
                                </span>
                              </td>
                            </tr>
                          ))
                          :
                          <tr>
                            <td colSpan={9}>No record found</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.table && tableData.table.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData.table[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {showCreatePopup ? (
        <EmployeePopUp
          open={showCreatePopup}
          handleOnCloseClick={handleOnCloseClick}
          UserID={UserID}
          DepartmentID={DepartmentID}
          EmployeeID={EmployeeID}
          editData={editData}
          PopUpFlag={PopUpFlag}
          flag={flag}
          setIsPost={setIsPost}
          IsPost={IsPost}
          handleOnClearClick={handleOnClearClick}
        />
      ) : (
        <></>
      )}
      {showDeletePopup ? (
        <DeletePopUp
          open={showDeletePopup}
          handleOnCloseClick={handleOnCloseClick}
          handleDeleteData={handleDeleteData}
        />
      ) : (
        <></>
      )}
    </>
  );
}
