
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"



export default function ContractorNameDDL(props) {
    const { contractorNameDDL, setContractorNameDDL, DDLData ,editData, FlagName,PopUpField} = props

    useEffect(() => {
        handleContractorNameDDL()
    }, [DDLData])

    const handleContractorNameDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {
            
            let list = DDLData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.contractor_Name,
            }))

            if (PopUpField && PopUpField.popupFlag) {

                setContractorNameDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_ContractorID : 0 : '0',
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.contractor_Name : "--Select--" : "--Select--",
                })
            } else {
                setContractorNameDDL({
                    DDL: list,
                    ID: FlagName === 'Update' ? editData.m_ContractorID : 0,
                    Label: FlagName === 'Update' ? editData.contractor_Name : "Select...",
                  
                })
            }

        }
        else {
            setContractorNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Contractor Name</label>
                <Select
                    isClearable={false}
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: contractorNameDDL.ID, label: contractorNameDDL.Label }}
                    onChange={(e) => {
                        e ?
                        setContractorNameDDL({ ...contractorNameDDL, ID: e.value, Label: e.label })
                            :
                            setContractorNameDDL({ ...contractorNameDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={contractorNameDDL.DDL}

                />
            </div>
        </>
    )
}