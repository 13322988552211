

import React, { useEffect, useState } from 'react'
import MobileHeader from '../../../../Components/Header/MobileHeader';
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import DepartmentDDL from '../../../../CommonDDL/DepartmentDDL/DepartmentDDL';
import { useDispatch, useSelector } from 'react-redux';
import SubDepartmentDDL from '../../../../CommonDDL/SubDepartmentDDL/SubDepartmentDDL';
import ProjectTypeDDLData from '../../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData';
import WardNameDDL from '../../../../CommonDDL/WardNameDDL/WardNameDDL';
import ProjectPriorityDDL from '../../../../CommonDDL/ProjectPriorityDDL/ProjectPriorityDDL';
import KeyProjectDDL from '../../../../CommonDDL/KeyProjectDDL/KeyProjectDDL';
import WorkTypeDDL from '../../../../CommonDDL/WorkTypeDDL/WorkTypeDDL/WorkTypeDDL';
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from '../../../../Helper/Context/context';
import { CommonDelayedWorkTableDataAPI } from '../../../../Redux/DashboardSlice/DelayedWorkSlice/DelayedWorkSlice';
import moment from 'moment'
import { Pegination } from '../../../../Components/Pegination/Pegination';
import LoaderFile from '../../../../Helper/Loader/LoaderFile';
import { X } from 'react-feather';
import { WardNameDDLAPI } from '../../../../Redux/DDLSlice';

export default function DelayedWork({ props }) {
    const today = new Date();
    const year = today.getFullYear();

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    const projectType = searchParams.get('projectType')
    const title = searchParams.get('title')
    const Flag = searchParams.get('Flag')

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [ExcelData, setExcelData] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [isopen, setIsOpen] = useState(true);

    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [projectPriorityDDL, setProjectPriorityDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const handleOnClearClick = () => {
        setCurrentPage(0)
        setIsPost(!IsPost)
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        setWardNameDDL({
            ...wardNameDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectPriorityDDL({
            ...projectPriorityDDL,
            ID: 0,
            Label: "---Select---"
        })
    }

    const { DepartmentDDLData } = useSelector(state => state.DepartmentNameData)

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_WardID: wardNameDDL.ID,
            zoneDDL: 0
        }
        dispatch(WardNameDDLAPI({ data }))
    }, [])

    const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            M_DepartmentID: departmentDDL.ID,
            M_WardID: wardNameDDL.ID,
            M_ProjectPriorityID: projectPriorityDDL.ID,
            Flag: Flag,
            M_UsersID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
            // handleGetExport: handleGetExport
        }
        dispatch(CommonDelayedWorkTableDataAPI({ data }))
    }, [To, IsPost, YearID, MonthID, IsClear, departmentDDL.ID, projectPriorityDDL.ID, wardNameDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.CommonDelayedWorkTableData)

    return (
        <>
            {isLoading && <LoaderFile />}
            <div className="wrapper">
                <div className="container-scroller">
                    <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                    <Header setIsOpen={setIsOpen} isopen={isopen} />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar active="dashboard" isopen={isopen} />
                        <div
                            className="main-panel"
                            style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                        >
                            <div className="content-wrapper">
                                {/* <DashboardHeading /> */}

                                <div className="row page-heading justify-content-between">
                                    <div className="col-6 col-lg-6 mb-xl-0 align-self-center align-items-center">
                                        <h4 className="font-weight-bold"> {title}</h4>

                                    </div>
                                    <div className="col-6 col-lg-6 mb-xl-0 py-2">
                                        <h4 className="font-weight-bold float-right px-2 py-1 mr-3 btn-outline-count rounded">
                                            Total Count :- <span className='text-danger px-2'>{tableData && tableData[0]?.totalCount ? tableData[0]?.totalCount : 0}</span>
                                        </h4>
                                    </div>
                                </div>

                                <div className="col-12 shadow table-card">
                                    <div className=" filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <DepartmentDDL
                                                        departmentDDL={departmentDDL}
                                                        setdepartmentDDL={setdepartmentDDL}
                                                        DDLData={DepartmentDDLData}
                                                        FlagName=''
                                                    />
                                                </div>


                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WardNameDDL
                                                        wardNameDDL={wardNameDDL}
                                                        setWardNameDDL={setWardNameDDL}
                                                        DDLData={WardNameDDLData}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ProjectPriorityDDL
                                                        projectPriorityDDL={projectPriorityDDL}
                                                        setProjectPriorityDDL={setProjectPriorityDDL}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-2 pl-lg-3">
                                                    <button type="button"
                                                        className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                    >
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 grid-margin">
                                            <div className="table-responsive">
                                                <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                    <thead>

                                                        <tr style={{ textAlign: "center" }}>
                                                            <th width="5%">Sr.No.</th>
                                                            <th>Department</th>
                                                            <th>Ward Name </th>
                                                            <th>Electroal Ward Number</th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>Project Tracker Code</th>
                                                            <th>Project Type</th>
                                                            <th>Work Type</th>
                                                            <th style={{ whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em' }}>Project Name</th>
                                                            <th>Tender Amt (lacs.)</th>
                                                            <th>Work Start Date</th>
                                                            <th>Work Completion Date</th>
                                                            <th>Phy Progress</th>
                                                            <th>Fin Progress</th>
                                                            <th>Project Priority</th>
                                                            <th>Key Project</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.length > 0 ? tableData.map((item, i) => (
                                                                <tr>
                                                                    <td className="text_center">{item.rowNum ? item.rowNum : 0}</td>
                                                                    <td>{item.departmentName ? item.departmentName : '-'}</td>
                                                                    <td>{item.wardName ? item.wardName : '-'}</td>
                                                                    <td className='text-right'>{item.m_WardWiseNumber ? item.m_WardWiseNumber : '-'}</td>
                                                                    <td>{item.project_Tracker_Code ? item.project_Tracker_Code : '-'}</td>
                                                                    <td>{item.projectTypeName ? item.projectTypeName : '-'}</td>
                                                                    <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                                                    <td>{item.projectName ? item.projectName : '-'}</td>
                                                                    <td className='text-right'>{item.tendarCost ? item.tendarCost.toFixed(2) : '-'}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{item.work_Start_Date ? moment(item.work_Start_Date).format('DD-MM-YYYY') : '-'}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{item.work_Completion_Date ? moment(item.work_Completion_Date).format('DD-MM-YYYY') : '-'}</td>
                                                                    <td className='text-right'>{item.physicalProgress ? `${item.physicalProgress.toFixed(2)} %` : '0 %'}</td>
                                                                    <td className='text-right'>0 %</td>
                                                                    <td>{item.projectPriorityName ? item.projectPriorityName : '-'}</td>
                                                                    <td>{item.key_ProjectName ? item.key_ProjectName : '-'}</td>

                                                                </tr>
                                                            )) : <tr style={{ textAlign: "center", whiteSpace: 'nowrap' }}>No data found...</tr>
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="copyright">
                                    Copyright {year}. all rights are reserved.<b>Version 1.0</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
