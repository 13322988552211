import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";


export const CommonWardWiseProjectDetailsCardTableDataAPI = createAsyncThunk("CommonWardWiseProjectDetailsCardTableData", async ({ data }) => {
    const {
        M_UsersID,
        M_FinancialYearID,
        M_DepartmentID,
        M_MonthID,
        M_SubDepartmentID,
        M_ProjectTypeID,
        M_WorkTypeID,
        M_WardID,
        M_ProjectPriorityID,
        Key_ProjectM_IndicatorID,
        Flag,
        token,
        FromTop,
        ToTop,
        // handleExportData
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_Web_T_ProjectDetailsM_WardWise_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : 0}&M_SubDepartmentID=${M_SubDepartmentID ? M_SubDepartmentID : 0}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : 0}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&M_WardID=${M_WardID ? M_WardID : 0}&M_ProjectPriorityID=${M_ProjectPriorityID ? M_ProjectPriorityID : 0}&Key_ProjectM_IndicatorID=${Key_ProjectM_IndicatorID ? Key_ProjectM_IndicatorID : 0}&Flag=${Flag}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                // handleExportData()
                return result.data.table
            } else {
                return result
            }
        })
})

const CommonWardWiseProjectDetailsCardTableDataSlice = createSlice({
    name: "CommonWardWiseProjectDetailsCardTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommonWardWiseProjectDetailsCardTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommonWardWiseProjectDetailsCardTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommonWardWiseProjectDetailsCardTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommonWardWiseProjectDetailsCardTableDataReducer = CommonWardWiseProjectDetailsCardTableDataSlice.reducer


