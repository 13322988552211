import React from "react";

export default function MainProgress(props) {
  const { title, description } = props;
  return (
    <div class="col-12 col-lg-6">
      <div class="project-block">
        <p>{title}</p>
        <h4>{description}</h4>
      </div>
    </div>
  );
}
