import React from 'react'
import { AlertTriangle, Bell, Camera, ChevronDown, MessageSquare, Plus, Power, Search, Settings, User } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Helper/Context/actions';
import { useAuthDispatch, useAuthState } from '../../Helper/Context/context';

export default function Header({ setIsOpen, isopen }) {
	const navigation = useNavigate()

  const toggle = () => {
    setIsOpen(!isopen);
  };

  const dispatch = useAuthDispatch();
	const userDetails = useAuthState();

  const {username,RoleID} = userDetails

  const handleLogout = () => {
    logout(dispatch);
    navigation('/')
  };

  return (
    <div className="navbar-section">
      <nav className={!isopen ? "navbar fixed-top  header justify dark-shadow col-lg-12 col-12 p-0" : "navbar fixed-top  header  dark-shadow col-lg-12 col-12 p-0"} >
        <div className="navbar-brand-wrapper d-flex align-items-center justify-content-center " style={{ width: !isopen ? "73px" : "" }} >
          {
            !isopen ?
              <a className="navbar-brand " >
                <img src="assets/img/Ladakh.png"
                  className="small-logo" />
                {/* LOGO */}
              </a>
              :
              <a className="navbar-brand brand-logo" >
                <img
                  // style={{
                  //   width: isopen ? " " : "110px",
                  // }}
                  src="assets/img/Ladakh.png"
                  className="full-logo"
                />
                {/* LOGO */}
              </a>

          }
        </div>

        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between" style={{ width: !isopen ? "calc(100% - 80px)" : "calc(100% - 230px)" }}>
          <div className='d-flex'>

            <button
              className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize"
              onClick={toggle}
            >
              <span className="icon-menu"></span>
            </button>
            <span 
            className="navbar-brand brand-logo header-logo " >
              Project Tracking and Monitoring System
            </span>

          </div>
          <ul className="navbar-nav mr-lg-2">
            {/* <li className="nav-item nav-search d-none d-lg-block">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="navbar-search-input"
                  placeholder="Search now"
                  aria-label="search"
                  aria-describedby="search"
                />
                <div
                  className="input-group-prepend hover-cursor"
                  id="navbar-search-icon"
                >
                  <span className="input-group-text" id="search">
                    <button type="search">
                      <i className="icon-search">
                    <Search/>
                      </i>
                    </button>
                  </span>
                </div>
              </div>
            </li> */}
          </ul>
          <ul className="navbar-nav navbar-nav-right">
            {/* <li className="nav-item dropdown notification mr-0 pr-0">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i>
                  <Bell />
                </i>
                <span className="count"></span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="notificationDropdown"
              >
                <p className="mb-0 font-weight-normal float-left dropdown-header">
                  Notifications
                </p>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="text-white">
                        <AlertTriangle style={{ marginTop: "-8px" }} />
                      </i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      100 Pending Product
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      Just now
                    </p>
                  </div>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="text-white">
                        <Settings style={{ marginTop: "-8px" }} />
                      </i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      Settings
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      Password Change (2 days ago)
                    </p>
                  </div>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="text-white">
                        <User style={{ marginTop: "-8px" }} />
                      </i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      50 New Product Received
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      2 Hrs ago
                    </p>
                  </div>
                </a>
              </div>
            </li> */}
            {/* <li className="nav-item dropdown notification mx-0 px-0">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i>
                  {" "}
                  <MessageSquare />
                </i>
                <span className="count"></span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="notificationDropdown"
              >
                <p className="mb-0 font-weight-normal float-left dropdown-header">
                  Messages
                </p>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="text-white">
                        <AlertTriangle style={{ marginTop: "-8px" }} />
                      </i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      10 Product Out of Stock
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      Just now
                    </p>
                  </div>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="text-white">
                        <Settings style={{ marginTop: "-8px" }} />
                      </i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      Check Received Products
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      2 days ago
                    </p>
                  </div>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="text-white">
                        <Plus style={{ marginTop: "-8px" }} />
                      </i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-normal">
                      2 New Product Added
                    </h6>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      2 Hrs ago
                    </p>
                  </div>
                </a>
              </div>
            </li> */}
            <li className="nav-item nav-profile dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                id="profileDropdown"
              >
                <img src="assets/img/user_img.png" alt="profile" />
                <span className="username">
                  <h6>{username}</h6>
                </span>
                <i className="align-self-center ml-1">
                  <ChevronDown />
                </i>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
                {/* <a className="dropdown-item">
                  <i className="text-success mr-2">
                    <Settings />
                  </i>
                  Settings
                </a> */}
                <span className="dropdown-item" onClick={handleLogout}>
                  <i className="text-success mr-2">
                    <Power />
                  </i>
                  Logout
                </span>
              </div>
            </li>

          </ul>
          {/* <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="icon-menu"></span>
          </button> */}
        </div>
      </nav>
    </div>
  )
}
