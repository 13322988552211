import { useEffect } from 'react'
import Select from 'react-select'
import { WorkTypeDDLAPI } from '../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../Helper/Context/context'
import { Star } from '../Components/CommoComponent/Star'


export const WorkTypeDDLData = (props) => {
    const { WorkTypeDDL, setWorkTypeDDL, Flag, PopUpField, apiFlag, editData, IsPost, mendetary } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = { UserID: UserID, token: token }
        dispatch(WorkTypeDDLAPI({ data }))
    }, [IsPost])

    const { WorkTypeData } = useSelector(state => state.WorkTypeDDLData)

    useEffect(() => {
        handleWorkTypeDDL();
    }, [WorkTypeData])

    const handleWorkTypeDDL = () => {
        if (WorkTypeData && WorkTypeData.table && WorkTypeData.table.length > 0) {
            let list = WorkTypeData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.workTypeName,
            }))

            if (PopUpField && PopUpField.popupFlag) {

                setWorkTypeDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_WorkTypeID : 0 : '0',
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.workTypeName : "--Select--" : "--Select--",
                })
            } else {
                setWorkTypeDDL({
                    DDL: list,
                    ID: apiFlag === 'Update' ? editData.m_WorkTypeID : 0,
                    Label: apiFlag === 'Update' ? editData.workTypeName : "---Select---"
                })
            }
        }
        else {
            setWorkTypeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"> Main Work Type {mendetary === 'yes' ? <Star /> : ''}  </label>
            <Select
                isClearable
                isSearchable
                maxMenuHeight={150}
                value={{ value: WorkTypeDDL.ID, label: WorkTypeDDL.Label }}
                onChange={(e) => {
                    e ?
                        setWorkTypeDDL({ ...WorkTypeDDL, ID: e.value, Label: e.label })
                        :
                        setWorkTypeDDL({ ...WorkTypeDDL, ID: 0, Label: "--Select--" })

                }}
                options={WorkTypeDDL.DDL}
            // isDisabled
            />
        </div>
    )
}