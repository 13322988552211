import React from 'react'
import { Search, X } from 'react-feather'
import Select from "react-select"

const SpillOverDepartSearch = () => {
    const options = [
        { value: 'New', label: 'New' },
        { value: 'Ongoing', label: 'Ongoing' },
        { value: 'Completed', label: 'Completed' }
    ]

    const options1 = [
        { value: 'Ward123', label: 'Ward123' },
        { value: 'Ward456', label: 'Ward456' },
        { value: 'Ward789', label: 'Ward789' }
    ]
    return (
        <div class=" filter mb-0">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label class="d-block" for="Budgettype">Project Status</label>

                            <Select
                                isClearable
                                // isRtl={isRtl}
                                isSearchable
                                // value={{ value: designationDDL.ID, label: designationDDL.Label }}
                                // onChange={(e) => {
                                //   e ?
                                //     setdesignationDDL({ ...designationDDL, ID: e.value, Label: e.label })
                                //     :
                                //     setdesignationDDL({ ...designationDDL, ID: 0, Label: language == "Marathi" ? Marathi.placeHolder.select : English.placeHolder.select })

                                // }}
                                // options={designationDDL.DDL}
                                options={options}

                            />
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label class="d-block" for="WardNo">Ward No</label>
                            <Select
                                isClearable
                                // isRtl={isRtl}
                                isSearchable
                                // value={{ value: designationDDL.ID, label: designationDDL.Label }}
                                // onChange={(e) => {
                                //   e ?
                                //     setdesignationDDL({ ...designationDDL, ID: e.value, Label: e.label })
                                //     :
                                //     setdesignationDDL({ ...designationDDL, ID: 0, Label: language == "Marathi" ? Marathi.placeHolder.select : English.placeHolder.select })

                                // }}
                                // options={designationDDL.DDL}
                                options={options1}

                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <button type="button" className="btn btn-clear text-white mr-2 mt-4 float-right waves-effect waves-light">
                            {/* <Search/> */}
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                        </button>
                        <button type="button" className="btn btn-primary text-white mr-2 mt-4 float-right waves-effect waves-light">
                            {/* <Search/> */}
                            <Search size={18} style={{ marginRight: "3px" }} />  Search
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SpillOverDepartSearch
