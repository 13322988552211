import React, { useState } from 'react'
import { Image, PieChart, Search, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Select from "react-select"
import Header from '../../../../Components/Header/Header';
import MobileHeader from '../../../../Components/Header/MobileHeader';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import ViewGalleryPopup from '../../PanCityProjectTable/ViewGalleryPopup';



const FinancialSpillOverElectricalDeptartment = () => {

    const [isopen, setIsOpen] = useState(true);
    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)


    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        // setflag("Add")
    }
    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
    }

    const navigate = useNavigate()
    const handleOnClick = (val) => {
        navigate("/viewprogress")
        console.log(val);
    }
    const options = [
        { value: 'New', label: 'New' },
        { value: 'Ongoing', label: 'Ongoing' },
        { value: 'Completed', label: 'Completed' }
    ]

    const options1 = [
        { value: 'Area123', label: 'Area123' },
        { value: 'Area456', label: 'Area456' },
        { value: 'Area789', label: 'Area789' }
    ]

    const data = [
        {
            Sr: "1",
            NameoftheProject: "Project name goes here",
            // TotalProject: "Vendor Name Come here",
            NameofBidder: "Bidder Come here",
            Area: "Area123",
            EstimatedCost: "Rs.15.50L",
            TenderedCost: "Rs.16.50L",
            Timelimit: "36 Months",
            Spillover: "Rs. 3L",
            Status: "New",
            ExtensionGranted: "Yes",
            stExtension: "Up to 05/07/2018",
            ndExtension: "Up to 05/05/2020",
            rdExtension: "Up to 05/12/2022"

        },
    ]
    return (
        <div className="wrapper">
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar isopen={isopen} />
                    <div
                        className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Financial Spill Over - projects - Electrical Department</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="Budgettype">Project Status</label>

                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options}

                                                        />



                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="WardNo">Area</label>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options1}

                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-4">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 float-right waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>
                                                    <button type="button" className="btn btn-primary text-white mr-2 mt-4 float-right waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <Search size={18} style={{ marginRight: "3px" }} />  Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table cellpadding="0" cellspacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name of the Project</th>
                                                    <th>Name of Bidder</th>
                                                    <th>Area</th>
                                                    <th>Estimated Cost</th>
                                                    <th>Tendered Cost</th>
                                                    <th>Time limit</th>
                                                    <th>Spill over</th>
                                                    <th>Status</th>
                                                    <th>Extension Granted</th>
                                                    <th>1st Extension</th>
                                                    <th>2nd Extension</th>
                                                    <th>3rd Extension</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Project name goes here</td>
                                                    <td>Bidder Come here</td>
                                                    <td>Area123</td>
                                                    <td>Rs.15.50L</td>
                                                    <td>Rs.16.50L</td>
                                                    <td>36 Months</td>
                                                    <td>Rs. 3L</td>
                                                    <td><span className="badge badge-primary px-2 py-1">New</span></td>
                                                    <td>Yes</td>
                                                    <td>Up to 05/07/2018</td>
                                                    <td>Up to 05/05/2020</td>
                                                    <td>Up to 05/12/2022</td>
                                                    <td className="white-space-nowrap">
                                                    <button onClick={() => handleAddNewClick()} className="mx-2 cursor-pointer" data-tooltip="tooltip" title="View Gallery"><i ><Image color='#3a86ff'/></i></button>
                                                    <button onClick={() => handleOnClick()} className="mx-2 cursor-pointer" data-tooltip="tooltip" title="View Progress"><i ><PieChart color='#b67cd3' /></i></button></td>


                                                </tr>
                                            </tbody >
                                        </table >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            {showCreatePopup ? <ViewGalleryPopup open={showCreatePopup} handleOnCloseClick={handleOnCloseClick} /> : <></>}
        </div >

    )
}

export default FinancialSpillOverElectricalDeptartment
