import React, { useState } from 'react'
import { Image, PieChart } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Select from "react-select"
import Header from '../../../Components/Header/Header';
import MobileHeader from '../../../Components/Header/MobileHeader';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import ViewGalleryPopup from '../PanCityProjectTable/ViewGalleryPopup';
import 'reactjs-popup/dist/index.css';




export default function TotalProjectTable() {

    const navigate = useNavigate()

    const [isopen, setIsOpen] = useState(true);
    const [projectName, setprojectName] = useState("Total Project")
    const [showCreatePopup, setshowCreatePopup] = useState(false)

    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        // setflag("Add")
    }
    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        // setshowDeletePopup(false)
    }


    const handleOnClick = (val) =>{
		navigate("/viewprogress")
		console.log(val);
	}
    const options = [
        { value: 'Active', label: 'Active' },
        { value: 'DeActive', label: 'DeActive' },

    ]
    return (
        <div>
            <div className="wrapper">
                <div className="container-scroller">
                    <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                    <Header setIsOpen={setIsOpen} isopen={isopen} />
                    <div className="container-fluid justify-content-center page-body-wrapper">
                        <Sidebar isopen={isopen} />
                        <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-12 grid-margin">
                                        <div className="row page-heading">
                                            <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                                <h4 className="font-weight-bold">Total Projects - Name of Department</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className=" filter mb-4">
                                            <div className="card-body">
                                                <div className="form-row">
                                                    <div className="col-12 col-md-6 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="d-block font-weight-bold" for="WardNo">Project Status</label>
                                                            <Select
                                                                isClearable
                                                                isSearchable
                                                                options={options}

                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <button type="button"
                                                            className="btn btn-primary submit-btn text-white btn-md m-0 mt-4 float-right waves-effect waves-light"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                                className="feather feather-search text-white">
                                                                <circle cx="11" cy="11" r="8"></circle>
                                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                            </svg>Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="tbl-sticky">
                                                <div className="table-responsive">
                                                    <table cellpadding="0" cellspacing="0" border="0" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr.No.</th>
                                                                <th>Name of the Project</th>
                                                                <th>Name of Vendor</th>
                                                                <th>Ward Number</th>
                                                                <th>Status</th>
                                                                <th>Estimated Cost</th>
                                                                <th>Tendered Cost</th>
                                                                <th>Expenditure Till Date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text_center">1</td>
                                                                <td>Project name goes here</td>
                                                                <td>Vendor Name Come here</td>
                                                                <td>Ward1</td>
                                                                <td><span className="badge badge-primary px-2 py-1">New</span></td>
                                                                <td>Rs.15.50L</td>
                                                                <td>Rs.16.50L</td>
                                                                <td>Rs.14.00L</td>
                                                                <td className="white-space-nowrap">
                                                                    <span  className="btn btn-sm btn-outline-dark out-btn" data-tooltip="tooltip" 
                                                                    title="View Gallery"
                                                                    onClick={() => handleAddNewClick()}
                                                                    >
                                                                        <Image />
                                                                    </span>
                                                                    <span className="btn btn-sm btn-outline-secondary out-btn ml-2" data-tooltip="tooltip"
                                                                     title="View Progress"
                                                                     onClick={() => handleOnClick()}
                                                                     >
                                                                        <PieChart />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text_center">2</td>
                                                                <td>Project name goes here</td>
                                                                <td>Vendor Name Come here</td>
                                                                <td>Ward2</td>
                                                                <td><span className="badge badge-warning px-2 py-1">Ongoing</span></td>
                                                                <td>Rs.15.50L</td>
                                                                <td>Rs.16.50L</td>
                                                                <td>Rs.14.00L</td>
                                                                <td className="white-space-nowrap">
                                                                    <span  className="btn btn-sm btn-outline-dark out-btn" data-tooltip="tooltip" 
                                                                    title="View Gallery"
                                                                    onClick={() => handleAddNewClick()}
                                                                    >
                                                                        <Image />
                                                                    </span>
                                                                    <span className="btn btn-sm btn-outline-secondary out-btn ml-2" data-tooltip="tooltip"
                                                                     title="View Progress"
                                                                     onClick={() => handleOnClick()}
                                                                     >
                                                                        <PieChart />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text_center">3</td>
                                                                <td>Project name goes here</td>
                                                                <td>Vendor Name Come here</td>
                                                                <td>Ward2</td>
                                                                <td><span className="badge badge-warning px-2 py-1">Ongoing</span></td>
                                                                <td>Rs.15.50L</td>
                                                                <td>Rs.16.50L</td>
                                                                <td>Rs.14.00L</td>
                                                                <td className="white-space-nowrap">
                                                                    <span  className="btn btn-sm btn-outline-dark out-btn" data-tooltip="tooltip" 
                                                                    title="View Gallery"
                                                                    onClick={() => handleAddNewClick()}
                                                                    >
                                                                        <Image />
                                                                    </span>
                                                                    <span className="btn btn-sm btn-outline-secondary out-btn ml-2" data-tooltip="tooltip"
                                                                     title="View Progress"
                                                                     onClick={() => handleOnClick()}
                                                                     >
                                                                        <PieChart />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text_center">4</td>
                                                                <td>Project name goes here</td>
                                                                <td>Vendor Name Come here</td>
                                                                <td>Ward2</td>
                                                                <td><span className="badge badge-warning px-2 py-1">Ongoing</span></td>
                                                                <td>Rs.15.50L</td>
                                                                <td>Rs.16.50L</td>
                                                                <td>Rs.14.00L</td>
                                                                <td className="white-space-nowrap">
                                                                    <span  className="btn btn-sm btn-outline-dark out-btn" data-tooltip="tooltip" 
                                                                    title="View Gallery"
                                                                    onClick={() => handleAddNewClick()}
                                                                    >
                                                                        <Image />
                                                                    </span>
                                                                    <span className="btn btn-sm btn-outline-secondary out-btn ml-2" data-tooltip="tooltip"
                                                                     title="View Progress"
                                                                     onClick={() => handleOnClick()}
                                                                     >
                                                                        <PieChart />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text_center">5</td>
                                                                <td>Project name goes here</td>
                                                                <td>Vendor Name Come here</td>
                                                                <td>Ward2</td>
                                                                <td><span className="badge badge-warning px-2 py-1">Ongoing</span></td>
                                                                <td>Rs.15.50L</td>
                                                                <td>Rs.16.50L</td>
                                                                <td>Rs.14.00L</td>
                                                                <td className="white-space-nowrap">
                                                                    <span  className="btn btn-sm btn-outline-dark out-btn" data-tooltip="tooltip" 
                                                                    title="View Gallery"
                                                                    onClick={() => handleAddNewClick()}
                                                                    >
                                                                        <Image />
                                                                    </span>
                                                                    <span className="btn btn-sm btn-outline-secondary out-btn ml-2" data-tooltip="tooltip"
                                                                     title="View Progress"
                                                                     onClick={() => handleOnClick()}
                                                                     >
                                                                        <PieChart />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text_center">6</td>
                                                                <td>Project name goes here</td>
                                                                <td>Vendor Name Come here</td>
                                                                <td>Ward3</td>
                                                                <td><span className="badge badge-success px-2 py-1">Completed</span></td>
                                                                <td>Rs.15.50L</td>
                                                                <td>Rs.16.50L</td>
                                                                <td>Rs.14.00L</td>
                                                                <td className="white-space-nowrap">
                                                                    <span  className="btn btn-sm btn-outline-dark out-btn" data-tooltip="tooltip" 
                                                                    title="View Gallery"
                                                                    onClick={() => handleAddNewClick()}
                                                                    >
                                                                        <Image />
                                                                    </span>
                                                                    <span className="btn btn-sm btn-outline-secondary out-btn ml-2" data-tooltip="tooltip"
                                                                     title="View Progress"
                                                                     onClick={() => handleOnClick()}
                                                                     >
                                                                        <PieChart />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                           
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row"><div className="col-sm-12 col-md-5"><div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 1 to 10 of 25 entries</div></div><div className="col-sm-12 col-md-7"><div className="dataTables_paginate paging_simple_numbers" id="example_paginate"><ul className="pagination"><li className="paginate_button page-item previous disabled" id="example_previous"><a href="#" aria-controls="example" data-dt-idx="0" tabindex="0" className="page-link">Previous</a></li><li className="paginate_button page-item active"><a href="#" aria-controls="example" data-dt-idx="1" tabindex="0" className="page-link">1</a></li><li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="2" tabindex="0" className="page-link">2</a></li><li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="3" tabindex="0" className="page-link">3</a></li><li className="paginate_button page-item next" id="example_next"><a href="#" aria-controls="example" data-dt-idx="4" tabindex="0" className="page-link">Next</a></li></ul></div></div></div>

                                        </div>
                                    </div>

                                </div>
                                <div className="copyright">
                                    Copyright 2021. all rights are reserved.<b>Version 1.0</b>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {showCreatePopup ? <ViewGalleryPopup open={showCreatePopup} handleOnCloseClick={handleOnCloseClick} /> : <></>}
        </div>

    )
}
