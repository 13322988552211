import React, { useState } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import moment from 'moment';
import Select from "react-select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header/Header";
import MobileHeader from "../../Components/Header/MobileHeader";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useAuthState } from "../../Helper/Context/context";
import LoaderFile from "../../Helper/Loader/LoaderFile";
import ZoneDDL from "../../CommonDDL/ZoneDDL/ZoneDDL";
import WardNameDDL from "../../CommonDDL/WardNameDDL/WardNameDDL";
import { WardMasterTableDataAPI } from "../../Redux/MasterSlice/WardMasterSlice";
import { Pegination } from "../../Components/Pegination/Pegination";
import Footer from "../../Components/Footer/Footer";
import WardMasterPop from "../Master/WardMaster/WardMasterPop";
import DeletePopUp from "../../Helper/DeletePopUp";
import UpdateMasterPopUp from "./UpdateMasterPopUp";
import ProjectTypeDDLData from "../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import { ContractorNameDDLAPI } from "../../Redux/DDLSlice";
import { UpdateTableDataAPI } from "../../Redux/UpdateSlice/UpdateSlice";
import ContractorNameDDL from "../../CommonDDL/ContractorNameDDL/ContractorNameDDL";

export default function UpdateMaster() {

  const userDetails = useAuthState();
  const { UserID, token } = userDetails
  const dispatch = useDispatch();

  const today = new Date();
  const year = today.getFullYear();

  // ----------pagination-------------
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)
  const [CurrentPage, setCurrentPage] = useState(0)
  const [IsSearch, setIsSearch] = useState(false)
  const [IsClear, setIsClear] = useState(false)
  const [IsPost, setIsPost] = useState(false)
  const [isopen, setIsOpen] = useState(true);
  const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "--Select--",
  })

  const [ProjectDDL, setProjectDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })
  const [UserStatusDDL, setUserStatusDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });

  const [wardNameDDL, setWardNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })

  const [PopUpField, setPopUpField] = React.useState({
    addPopUp: false,
    deletePopUp: false,
    showViewPopup: false,
    showPopup: false,
    popupFlag: '',
    popupBtn: "",
    apiFlag: "",
    rowData: ''
  })

  const handleAddCloseClick = () => {
    setPopUpField({ addPopUp: false, showViewPopup: false, showPopup: false })
  }
  // const handleOnViewClick = () => {
  //   setPopUpField({ showViewPopup: true })
  // }

  const handleDeleteCloseClick = () => {
    setPopUpField({ deletePopUp: false })
  }
  const addButtonClick = () => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Add", popupBtn: "Clear", apiFlag: 'Insert', rowData: '' })
  }

  const editButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Update", popupBtn: "Cancel", apiFlag: 'Update', rowData: item })

  }

  const ViewButtonClick = (item) => {
    setPopUpField({ ...PopUpField, showPopup: true, deletePopUp: false, popupFlag: "Add", popupBtn: "Cancel", apiFlag: 'Update', rowData: item })
  }

  const deleteButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: false, deletePopUp: true, popupBtn: "", apiFlag: 'Delete', rowData: item })
  }

  const handleDeleteData = () => {
    // dispatch(ProjectSanctionDetailsDeleteAPI({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }))
  }

  const { isDeleteLoading } = useSelector(state => state.ProjectSanctionDetailsDeleteData)

  const handlePost = () => {
    setIsPost(!IsPost)
  }
  const handleOnClearClick = () => {
    setCurrentPage(0)
    setContractorNameDDL({
      ...contractorNameDDL,
      ID: 0,
      Label: "---Select---",
    })
    setProjectTypeDDL({
      ...ProjectTypeDDL,
      ID: 0,
      Label: "---Select---"
    })

    setIsPost(!IsPost)
  }

  useEffect(() => {
    const data = {
      M_WorkTypeID: WorkAndWorkTypeDDL.ID,
      M_ProjectID: ProjectDDL.ID,
      M_WardID: wardNameDDL.ID,
      M_WardWiseNumberID: 0,
      M_IndicatorStatusID: UserStatusDDL.ID,
      Flag: 'CompleteWork',
      M_UsersID: UserID,
      token: token,
      From: From,
      To: To,
    }
    dispatch(UpdateTableDataAPI({ data }))
  }, [From, To, IsPost, IsClear, WorkAndWorkTypeDDL.ID, ProjectDDL.ID, wardNameDDL.ID, UserStatusDDL.ID])

  const { tableData, isLoading } = useSelector(state => state.UpdateTableData)

  const [showCreatePopup, setshowCreatePopup] = useState(false);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [flag, setflag] = useState(false);

  const [deptOfficeTypeIndicatorID, setdeptOfficeTypeIndicatorID] = useState(1);

  const [ProjectTypeDDL, setProjectTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })
  const [contractorNameDDL, setContractorNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })

  useEffect(() => {
    const data = {
      M_ContractorID: 0,
      M_ProjectID: 0,
      M_UsersID: UserID,
      token,
      Flag: 'Master'
    }
    dispatch(ContractorNameDDLAPI({ data }))
  }, [IsPost])

  const { ContractorNameDDLData } = useSelector(state => state.ContractorNameDDLData)

  return (
    <>
      {isLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="updateMaster" subMenu="" isopen={isopen} />

          {/* <!-- Main Container --> */}
          <div
            className="main-panel"
            style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
          >
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">Update</h4>
                    </div>
                    <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    {/* <div className="card filter mb-4"> */}
                    <div className="card-body">
                      <div className="form-row">
                        <div className="col-12 col-md-4 col-lg-3">
                          <ProjectTypeDDLData
                            ProjectTypeDDL={ProjectTypeDDL}
                            setProjectTypeDDL={setProjectTypeDDL}
                            IsPost={IsPost}
                          // PopUpField={PopUpField}

                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                          <ContractorNameDDL
                            contractorNameDDL={contractorNameDDL}
                            setContractorNameDDL={setContractorNameDDL}
                            DDLData={ContractorNameDDLData}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-lg-2 pl-lg-3">
                          <button type="button"
                            className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick} >

                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      className="table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th className="col-3" style={{ textAlign: "center" }}>
                            Work Type
                          </th>
                          <th className="col-4" style={{ textAlign: "center" }}>
                            Project Name
                          </th>
                          <th className="col-3" style={{ textAlign: "center" }}>
                            Contractor Name
                          </th>
                          <th width="15%" className="col-7" style={{ textAlign: "center" }}>
                            Start Date
                          </th>
                          <th className="col-5" style={{ textAlign: "center" }}>
                            Completed Date
                          </th>
                          <th className="col-5" style={{ textAlign: "center" }}>
                            1st Extenstion Date
                          </th>
                          <th className="col-5" style={{ textAlign: "center" }}>
                            2nd Extenstion Date
                          </th>
                          <th className="col-5" style={{ textAlign: "center" }}>
                            3rd Extenstion Date
                          </th>
                          <th className="col-5" style={{ textAlign: "center" }}>
                            Status
                          </th>
                          <th style={{ width: "150px", textAlign: "center" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">{item.rowNum}</td>
                              <td>{item.workTypeName ? item.workTypeName : "-"}</td>
                              <td>{item.projectName ? item.projectName : "-"}</td>
                              <td>{item.contractor_Name ? item.contractor_Name : "-"}</td>
                              <td>{item.work_Start_Date ? moment(item.work_Start_Date).format("DD-MM-YYYY") : "-"}</td>
                              <td>{item.work_Completion_Date ? moment(item.work_Completion_Date).format("DD-MM-YYYY") : "-"}</td>
                              <td>{item.extenstionDate1 ? item.extenstionDate1 : "-"}</td>
                              <td>{item.extenstionDate2 ? item.extenstionDate2 : "-"}</td>
                              <td>{item.extenstionDate3 ? item.extenstionDate3 : "-"}</td>
                              <td>{item.statusName ? item.statusName : "-"}</td>
                              <td style={{ textAlign: "center", padding: "6px 5px" }}>

                                <span className=" mx-1 cursor-pointer"
                                  title="Edit" onClick={() => editButtonClick(item)}>
                                  <Edit size={15} color="#007bff" />
                                </span>

                                <span className="  mx-1 cursor-pointer"
                                  title="Delete" onClick={() => deleteButtonClick(item)}>
                                  <Trash2 size={15} color="red" />
                                </span>

                              </td>
                            </tr>
                          )) : <tr>No data</tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.table && tableData.table.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData.table.length}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {PopUpField.addPopUp ? (
        <UpdateMasterPopUp
          open={PopUpField.addPopUp}
          PopUpField={PopUpField}
          handleAddCloseClick={handleAddCloseClick}
          IsPost={IsPost}
          setIsPost={setIsPost}
          handlePost={handlePost}
        // deptOfficeTypeIndicatorID={deptOfficeTypeIndicatorID}

        />
      ) : (
        <></>
      )}
      {PopUpField.deletePopUp ? <DeletePopUp
        open={PopUpField.deletePopUp}
        handleDeleteData={handleDeleteData}
        // deleteData={deleteData}
        handleOnCloseClick={handleDeleteCloseClick}
      />
        : <></>}
    </>
  );
}
