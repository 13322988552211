import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Select from "react-select";
import { Get_DDL_M_Department, Get_DDL_M_Project } from "../../../APIs/DDLAPI/DDLAPI";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import { useAuthState } from "../../../Helper/Context/context";
import { Pegination } from "../../../Components/Pegination/Pegination";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
// import DepartmentWiseProjectPopUp from "./DepartmentWiseProjectPopUp";
import Footer from "../../../Components/Footer/Footer";
import { Get_T_ProjectDefine_Select } from "../../CreateProject/CreateProjectAPI/GETAPI";
import { Post_T_ProjectDefine_Delete } from "../../CreateProject/CreateProjectAPI/POSTAPI";
import UserCreationPopUp from "./UserCreationPopUp";
import { useDispatch, useSelector } from "react-redux";
import { RoleDDLData } from "../../../CommonDDL/RoleDDLData";
import { DepartmentNameDDLAPI, DesignationNameDDLAPI } from "../../../Redux/DDLSlice";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import DesignationNameDDL from "../../../CommonDDL/DesignationDDL/DesignationNameDDL";
import { UserStatusDDLData } from "../../../CommonDDL/UserStatusDDLData";
import { ExportUserCreationTableDataAPI, UserCreationDeleteAPI, UserCreationTableDataAPI } from "../../../Redux/UserMasterSlice/UserCreationSlice";
import { ExportUserCreation } from "./ExportUserCreation";


export default function UserCreation() {
  const userDetails = useAuthState();
  const { UserID, token } = userDetails
  const dispatch = useDispatch()

  // ----------pagination-------------
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)
  const [CurrentPage, setCurrentPage] = useState(0)
  const [ExcelData, setExcelData] = useState(false)
  const [IsClear, setIsClear] = useState(false)
  const [IsPost, setIsPost] = useState(false)
  const [isopen, setIsOpen] = useState(true);

  const [PopUpField, setPopUpField] = React.useState({
    addPopUp: false,
    deletePopUp: false,
    popupFlag: '',
    popupBtn: "",
    apiFlag: "",
    rowData: ''
  })

  const [RoleDDL, setRoleDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })

  const [departmentDDL, setdepartmentDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });

  const [designationNameDDL, setDesignationNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });

  const [UserStatusDDL, setUserStatusDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });

  const handleOnClearClick = () => {
    setCurrentPage(0)
    setRoleDDL({
      ...RoleDDL,
      ID: 0,
      Label: "---Select---"
    })
    setdepartmentDDL({
      ...departmentDDL,
      ID: 0,
      Label: "---Select---"
    })
    setDesignationNameDDL({
      ...designationNameDDL,
      ID: 0,
      Label: "---Select---"
    })
    setUserStatusDDL({
      ...UserStatusDDL,
      ID: 0,
      Label: "---Select---"
    })

    setIsPost(!IsPost)
  }

  const handleAddCloseClick = () => {
    setPopUpField({ addPopUp: false })
  }

  const handleDeleteCloseClick = () => {
    setPopUpField({ deletePopUp: false })
  }
  const addButtonClick = () => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Add", popupBtn: "Clear", apiFlag: 'Insert', rowData: '' })
  }

  const editButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Update", popupBtn: "Cancel", apiFlag: 'Update', rowData: item })

  }

  const deleteButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: false, deletePopUp: true, popupBtn: "", apiFlag: 'Delete', rowData: item })
  }

  const handleDeleteData = () => {
    dispatch(UserCreationDeleteAPI({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }))
  }

  const { isDeleteLoading } = useSelector(state => state.UserCreationDeleteData)

  const handlePost = () => {
    setIsPost(!IsPost)
  }

  const handleExportData = () => {
    const data = {
      M_UsersID: 0,
      M_RoleID: RoleDDL.ID,
      M_DepartmentID: departmentDDL.ID,
      DepartmentName: '',
      M_DeptOfficeTypeIndicatorID: 0,
      M_DesignationID: designationNameDDL.ID,
      UserStatusIndicatorID: UserStatusDDL.ID,
      Login_M_UsersID: UserID,
      token: token,
      From: 1,
      To: 99999
    }
    dispatch(ExportUserCreationTableDataAPI({ data }))
  }

  const { ExportData, isExportLoading } = useSelector(state => state.ExportUserCreationTableData)


  useMemo(() => {
    const data = {
      M_UsersID: 0,
      M_RoleID: RoleDDL.ID,
      M_DepartmentID: departmentDDL.ID,
      DepartmentName: '',
      M_DeptOfficeTypeIndicatorID: 0,
      M_DesignationID: designationNameDDL.ID,
      UserStatusIndicatorID: UserStatusDDL.ID,
      Login_M_UsersID: UserID,
      token: token,
      From: From,
      To: To,
      handleExportData: handleExportData
    }
    dispatch(UserCreationTableDataAPI({ data }))
  }, [To, IsPost, IsClear, RoleDDL.ID, departmentDDL.ID, designationNameDDL.ID, UserStatusDDL.ID])

  const { tableData, isLoading } = useSelector(state => state.UserCreationTableData)


  return (
    <>
      {isLoading && <LoaderFile />}
      {isDeleteLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="userCreation" subMenu="userMaster" isopen={isopen} />
          <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">User Creation</h4>
                    </div>
                    <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                      <button type="button" className="btn btn-primary btn-sm"
                        title="Add" onClick={() => addButtonClick()}>
                        <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                        Add
                      </button>
                      {
                        isExportLoading || isLoading ?
                          <button type="button" className="btn btn-success btn-sm" disabled>
                            <i className="fa fa-refresh fa-spin" style={{ fontSize: "15px", marginRight: "5px" }}></i>
                            Loading..
                          </button>
                          :
                          <ExportUserCreation
                            ExportData={ExportData}
                            name='User Creation'
                          />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    {/* <div className="card filter mb-4"> */}
                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-4 col-lg-3">
                          <RoleDDLData
                            RoleDDL={RoleDDL}
                            setRoleDDL={setRoleDDL}

                          />

                        </div>
                        <div className="col-4 col-lg-3">
                          <DepartmentDDL
                            departmentDDL={departmentDDL}
                            setdepartmentDDL={setdepartmentDDL}
                            IsPost={IsPost}
                          // FlagName=''
                          />

                        </div>
                        <div className="col-4 col-lg-2">
                          <DesignationNameDDL
                            designationNameDDL={designationNameDDL}
                            setDesignationNameDDL={setDesignationNameDDL}
                            IsPost={IsPost}
                            departmentDDL={departmentDDL}
                          // FlagName=''
                          />
                        </div>
                        <div className="col-4 col-lg-2">
                          <UserStatusDDLData
                            UserStatusDDL={UserStatusDDL}
                            setUserStatusDDL={setUserStatusDDL}
                          />
                        </div>


                        <div className="col-12 col-md-12 col-lg-2 pl-lg-3 ">
                          <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick}>
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th>Role</th>
                          <th>Department</th>
                          <th>Designation</th>
                          <th>Employee Name</th>
                          <th>Table Name</th>
                          <th>Joining Date</th>
                          <th>User Name</th>
                          <th>Password</th>
                          <th>User Status</th>
                          <th style={{ width: "8%", textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                              <td>{item.roleName ? item.roleName : "-"}</td>
                              <td>{item.departmentName ? item.departmentName : "-"}</td>
                              <td>{item.designationName ? item.designationName : "-"}</td>
                              <td>{item.employeeName ? item.employeeName : "-"}</td>
                              <td>{item.tableName ? item.tableName : "-"}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{item.joining_Date ? moment(item.joining_Date).format("DD-MM-YYYY") : "-"}</td>
                              <td>{item.userName ? item.userName : "-"}</td>
                              <td>{item.password ? item.password : "-"}</td>
                              <td>{item.userStatusIndicator ? item.userStatusIndicator : "-"}</td>
                              {/* <td>{item.ClassificationofWork}</td> */}
                              <td style={{ textAlign: "center" }}>
                                <div className="d-flex">
                                  <span className="mx-2 cursor-pointer"
                                    title="Edit" onClick={() => editButtonClick(item)}>
                                    <Edit size={15} color="#007bff" />
                                  </span>

                                  <span className="mx-2 cursor-pointer"
                                    title="Delete" onClick={() => deleteButtonClick(item)}>
                                    <Trash2 size={15} color="red" />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                        }

                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.table && tableData.table.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData.table[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>


      {
        PopUpField.addPopUp ?
          <UserCreationPopUp
            open={PopUpField.addPopUp}
            // handleOnClearClick={handleOnClearClick}
            PopUpField={PopUpField}
            handleAddCloseClick={handleAddCloseClick}
            IsPost={IsPost}
            setIsPost={setIsPost}
            handlePost={handlePost}
          />
          : <></>
      }
      {PopUpField.deletePopUp ? <DeletePopUp
        open={PopUpField.deletePopUp}
        handleDeleteData={handleDeleteData}
        // deleteData={deleteData}
        handleOnCloseClick={handleDeleteCloseClick}
      /> : <></>}

    </>
  )
}