
import React, { useEffect, useState } from 'react'
import MobileHeader from '../../../../Components/Header/MobileHeader';
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import DepartmentDDL from '../../../../CommonDDL/DepartmentDDL/DepartmentDDL';
import { useDispatch, useSelector } from 'react-redux';
import SubDepartmentDDL from '../../../../CommonDDL/SubDepartmentDDL/SubDepartmentDDL';
import ProjectTypeDDLData from '../../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData';
import WardNameDDL from '../../../../CommonDDL/WardNameDDL/WardNameDDL';
import ProjectPriorityDDL from '../../../../CommonDDL/ProjectPriorityDDL/ProjectPriorityDDL';
import KeyProjectDDL from '../../../../CommonDDL/KeyProjectDDL/KeyProjectDDL';
import WorkTypeDDL from '../../../../CommonDDL/WorkTypeDDL/WorkTypeDDL/WorkTypeDDL';
import { useAuthState } from '../../../../Helper/Context/context';
import { DepartmentNameDDLAPI, WardNameDDLAPI } from '../../../../Redux/DDLSlice';
import { TotalProjectTableDataAPI } from '../../../../Redux/DashboardSlice/TotalProjectSlice/TotalProjectSlice';
import LoaderFile from '../../../../Helper/Loader/LoaderFile';
import { Pegination } from '../../../../Components/Pegination/Pegination';
import { WorkTypeDDLData } from '../../../../CommonDDL/WorkTypeDDLData';
import { useSearchParams } from 'react-router-dom';
import { X } from 'react-feather';


export default function TotalProjects({ props }) {

    const today = new Date();
    const year = today.getFullYear();


    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    const projectType = searchParams.get('projectType')
    const title = searchParams.get('title')
    const Flag = searchParams.get('Flag')

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [ExcelData, setExcelData] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [isopen, setIsOpen] = useState(true);

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });
    const [subDepartmentDDL, setSubDepartmentDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [WorkTypeDDL, setWorkTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [workTypeDDL, setworkTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [projectPriorityDDL, setProjectPriorityDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [keyProjectDDL, setKeyProjectDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })

    const [electoralWardNumDDL, setElectoralWardNumDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })


    const handleOnClearClick = () => {
        setCurrentPage(0)
        setIsPost(!IsPost)
        setIsClear(!IsClear)
        setdepartmentDDL({
            ...departmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        setSubDepartmentDDL({
            ...subDepartmentDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectTypeDDL({
            ...ProjectTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setworkTypeDDL({
            ...workTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setWardNameDDL({
            ...wardNameDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectPriorityDDL({
            ...projectPriorityDDL,
            ID: 0,
            Label: "---Select---"
        })
        setKeyProjectDDL({
            ...keyProjectDDL,
            ID: 0,
            Label: "---Select---"
        })

    }

    const handleAddCloseClick = () => {
        setPopUpField({ addPopUp: false })
    }

    const handleDeleteCloseClick = () => {
        setPopUpField({ deletePopUp: false })
    }
    const addButtonClick = () => {
        setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Add", popupBtn: "Clear", apiFlag: 'Insert', rowData: '' })
    }

    const editButtonClick = (item) => {
        setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Update", popupBtn: "Cancel", apiFlag: 'Update', rowData: item })

    }

    const deleteButtonClick = (item) => {
        setPopUpField({ ...PopUpField, addPopUp: false, deletePopUp: true, popupBtn: "", apiFlag: 'Delete', rowData: item })
    }

    const handleDeleteData = () => {
        // dispatch(UserCreationDeleteAPI({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }))
    }

    const { isDeleteLoading } = useSelector(state => state.UserCreationDeleteData)

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_WardID: wardNameDDL.ID,
            zoneDDL: 0
        }
        dispatch(WardNameDDLAPI({ data }))
    }, [])

    const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)

    const handlePost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = {

            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            M_DepartmentID: departmentDDL.ID,
            M_SubDepartmentID: subDepartmentDDL.ID,
            M_ProjectTypeID: ProjectTypeDDL.ID,
            M_WorkTypeID: workTypeDDL.ID,
            M_WardID: wardNameDDL.ID,
            M_ProjectPriorityID: projectPriorityDDL.ID,
            Key_ProjectM_IndicatorID: keyProjectDDL.ID,
            M_UsersID: UserID,
            FromTop: From,
            ToTop: To,
            token: token,
        }
        dispatch(TotalProjectTableDataAPI({ data }))
    }, [To, IsPost, YearID, MonthID, IsClear, ProjectTypeDDL.ID, departmentDDL.ID, subDepartmentDDL.ID, workTypeDDL.ID, wardNameDDL.ID, projectPriorityDDL.ID, keyProjectDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.TotalProjectTableData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [ProjectTypeDDL.ID, departmentDDL.ID, subDepartmentDDL.ID, workTypeDDL.ID, wardNameDDL.ID, projectPriorityDDL.ID, keyProjectDDL.ID])

    // console.log('tableData.table[0].totalCount',tableData[0].totalCount)
    return (
        <>
            {isLoading && <LoaderFile />}
            <div className="wrapper">
                <div className="container-scroller">
                    <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                    <Header setIsOpen={setIsOpen} isopen={isopen} />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar active="dashboard" isopen={isopen} />
                        <div
                            className="main-panel"
                            style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                        >
                            <div className="content-wrapper">
                                {/* <DashboardHeading /> */}

                                <div className="row page-heading justify-content-between">
                                    <div className="col-6 col-lg-6 mb-xl-0">
                                        <h4 className="font-weight-bold py-2">{title}</h4>
                                    </div>
                                    <div className="col-6 col-lg-6 mb-xl-0 py-2">
                                        <h4 className="font-weight-bold float-right px-2 py-1 mr-3 btn-outline-count rounded">
                                            Total Count :- <span className='text-danger px-2'>{tableData && tableData[0]?.totalCount ? tableData[0]?.totalCount : 0}</span>
                                        </h4>
                                    </div>
                                </div>

                                <div className="col-12 shadow table-card">
                                    <div className=" filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <DepartmentDDL
                                                        departmentDDL={departmentDDL}
                                                        setdepartmentDDL={setdepartmentDDL}
                                                        FlagName=''
                                                        IsPost={IsPost}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <SubDepartmentDDL
                                                        subDepartmentDDL={subDepartmentDDL}
                                                        setSubDepartmentDDL={setSubDepartmentDDL}
                                                        departmentDDLID={departmentDDL.ID}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ProjectTypeDDLData
                                                        ProjectTypeDDL={ProjectTypeDDL}
                                                        setProjectTypeDDL={setProjectTypeDDL}
                                                        // PopUpField={PopUpField}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WorkTypeDDLData
                                                        WorkTypeDDL={WorkTypeDDL}
                                                        setWorkTypeDDL={setWorkTypeDDL}
                                                        IsPost={IsPost}
                                                        Flag='Master'

                                                    // WorkTypeData={WorkTypeData}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WardNameDDL
                                                        wardNameDDL={wardNameDDL}
                                                        setWardNameDDL={setWardNameDDL}
                                                        DDLData={WardNameDDLData}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ProjectPriorityDDL
                                                        projectPriorityDDL={projectPriorityDDL}
                                                        setProjectPriorityDDL={setProjectPriorityDDL}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <KeyProjectDDL
                                                        keyProjectDDL={keyProjectDDL}
                                                        setKeyProjectDDL={setKeyProjectDDL}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-2 pl-lg-3">
                                                    <button type="button"
                                                        className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                    >
                                                       <X size={18} style={{ marginRight: "3px" }} />
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row px-3">
                                        <div className="col-md-12 grid-margin">
                                            {/* <div className="row"> */}
                                            <div className="table-responsive">

                                                <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                    <thead>
                                                        <tr style={{ textAlign: "center" }}>
                                                            <th width="5%">Sr.No.</th>
                                                            <th style={{ width: '25%' }}>Project Tracker Code
                                                                <hr />
                                                                LFMS Number
                                                            </th>
                                                            <th>Project Type</th>
                                                            <th>Work Type</th>
                                                            <th style={{ width: '30%' }}>Project Name</th>
                                                            <th>Project ERP Code</th>
                                                            <th>Ward Name</th>
                                                            <th>Electoral Ward Number</th>
                                                            <th>Project Priority</th>
                                                            <th>Key Project</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.length > 0 ? tableData.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                                                    <td>{item.project_Tracker_Code ? item.project_Tracker_Code : "-"}
                                                                        <hr style={{ borderTop: "1px solid #00000075" }} />
                                                                        {item.lfmS_File_No ? item.lfmS_File_No : "-"}
                                                                    </td>
                                                                    <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                                                                    <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                                                    <td>{item.projectName ? item.projectName : "-"}</td>
                                                                    <td>{item.project_ERP_Code ? item.project_ERP_Code : "-"}</td>
                                                                    <td>{item.wardName ? item.wardName : "-"}</td>
                                                                    <td className='text-right'>{item.m_WardWiseNumber ? item.m_WardWiseNumber : "-"}</td>
                                                                    <td>{item.projectPriorityName ? item.projectPriorityName : "-"}</td>
                                                                    <td>{item.key_ProjectName ? item.key_ProjectName : "-"}</td>
                                                                </tr>
                                                            )) : <tr style={{ textAlign: "center", whiteSpace: 'nowrap' }}>No data found...</tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="copyright">
                                    Copyright {year}. all rights are reserved.<b>Version 1.0</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
