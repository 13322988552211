
import Select from "react-select"
import { useEffect } from "react"
import { Star } from "../../Components/CommoComponent/Star"
import { AssignJEDDLAPI } from "../../Redux/DDLSlice"
import { useAuthState } from "../../Helper/Context/context"
import { useDispatch, useSelector } from "react-redux"

export default function AssignJEDDL(props) {
    const { assignJEDDL, setAssignJEDDL, FlagName, editData, apiFlag, PopUpField, mendetary,departmentDDL,wardNameDDL } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            UserID,
            M_DepartmentID:departmentDDL.ID,
            M_RoleID:0,
            M_WardID:wardNameDDL.ID,
            token
        }
        dispatch(AssignJEDDLAPI({ data }))
    }, [departmentDDL.ID,wardNameDDL.ID])

    const { AssignJEData } = useSelector(state => state.AssignJEDDLData)

    // console.log("AssignJEData",AssignJEData)

    useEffect(() => {
        handleAssignJEDDL()
    }, [AssignJEData])

    const handleAssignJEDDL = () => {
        if (AssignJEData && AssignJEData.table && AssignJEData.table.length > 0) {
            let list = AssignJEData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.employeeName,
                // id:item.m_EmployeeID,
                // tableName:item.tableName
            }))

            setAssignJEDDL({
                DDL: list,
                ID: apiFlag === 'Update' ? editData.assignJE_M_UsersID : 0,
                Label: apiFlag === 'Update' ? editData.employeeName : "---Select---",
            })
        }
        else {
            setAssignJEDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
        }

    }

    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Assign JE {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: assignJEDDL.ID, label: assignJEDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setAssignJEDDL({ ...assignJEDDL, ID: e.value, Label: e.label })
                            :
                            setAssignJEDDL({ ...assignJEDDL, ID: 0, Label: "Select..." })
                    }}
                    options={assignJEDDL.DDL}
                />
            </div>
        </>
    )
}