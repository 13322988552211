import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const FinancialWorkProgressTableDataAPI = createAsyncThunk("FinancialWorkProgressTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_WorkTypeID,
        M_ProjectID,
        UserStatusIndicatorID,
        Flag,
        M_UsersID,
        FromTop,
        ToTop,
        token,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_Web_T_PhysicalMilestoneWorkFinancialProgress_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&M_ProjectID=${M_ProjectID ? M_ProjectID : 0}&UserStatusIndicatorID=${UserStatusIndicatorID ? UserStatusIndicatorID : 0}&Flag=${Flag}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                return result.data.table
            } else {
                return result
            }
        })
})

const FinancialWorkProgressTableDataSlice = createSlice({
    name: "FinancialWorkProgressTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(FinancialWorkProgressTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(FinancialWorkProgressTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(FinancialWorkProgressTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const FinancialWorkProgressTableDataReducer = FinancialWorkProgressTableDataSlice.reducer