import React, { useState } from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Plus, X } from "react-feather";
import { useEffect } from "react";
import { Get_DDL_M_Work, Get_DDL_T_AssignWorkToContractorName, Get_DDL_T_ProjectDefine_ProjectName, Get_DDL_T_ProjectDefine_ProjectType, Get_DDL_T_WorkDefine_WorkName, Get_DDL_T_WorkDefine_WorkType, Get_DDL_WorkArea } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_T_AssignWorkToContractor_InsertUpdate } from "../CreateProjectAPI/POSTAPI";
import ContractorNameDDL from "../../../CommonDDL/ContractorNameDDL/ContractorNameDDL";
import ProjectTypeDDL from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import ProjectNameDDL from "../../../CommonDDL/ProjectNameDDL/ProjectNameDDL";
import BudgetCodeDDL from "../../../CommonDDL/BudgetCodeDDL/BudgetCodeDDL";
import BudgetHeadDDL from "../../../CommonDDL/BudgetHeadDDL/BudgetHeadDDL";
import TenderAmountTypeDDL from "../../../CommonDDL/TenderAmountTypeDDL/TenderAmountTypeDDL";
import { WorkTypeDDLData } from "../../../CommonDDL/WorkTypeDDLData";
import { ContractorNameDDLAPI, ManPowerTypeDDLAPI, MunicipalCouncilorDDLAPI } from "../../../Redux/DDLSlice";
import { useDispatch, useSelector } from "react-redux";
import { StagesOfWork, StagesOfWorkDDL } from "../../../CommonDDL/StagesOfWorkDDL";
import { ProjectSanctionDetailsPostAPI } from "../../../Redux/ProjectCreationAllocationSlice/ProjectSanctionDetailsSlice";
import { ConcateWorkAndWorkTypeDDL } from "../../../CommonDDL/ConcateWorkAndWorkTypeDDL";
import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import ManPowerTypeDDL from "../../../CommonDDL/ManPowerTypeDDL/ManPowerTypeDDL";
import { Star } from "../../../Components/CommoComponent/Star";
import MuncipleCouncilorDDL from "../../../CommonDDL/MuncipleCouncilorDDL/MuncipleCouncilorDDL";
import ProjectNameMilestoneDDL from "../../../CommonDDL/ProjectNameMilestoneDDL/ProjectNameMilestoneDDL";
import ProjectRemarkDDL from "../../../CommonDDL/ProjectRemarkDDL/ProjectRemarkDDL";
import moment from 'moment';

export default function ContractorWorkPopUp({ open, handleAddCloseClick, PopUpField, handlePost }) {
    const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField
    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch();
    // console.log(rowData)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsValidText1, setIsValidText1] = useState(false)

    const [IsValidText, setIsValidText] = useState(true);
    const [maxLength, setMaxLength] = useState(10); // Default maxLength value

    const [ProjectSanctionDetailsTextField, setProjectSanctionDetailsTextField] = useState(
        {
            budget_Provision: apiFlag === "Insert" ? '' : rowData?.budget_Provision,
            administrativeApproveCost: apiFlag === "Insert" ? '' : rowData?.administrativeApproveCost,
            administrativeApproveDate: apiFlag === "Insert" ? '' : moment(rowData?.administrativeApproveDate).format("YYYY-MM-DD"),
            dateOfTenderFloated: apiFlag === "Insert" ? '' : moment(rowData?.dateOfTenderFloated).format("YYYY-MM-DD"),
            tendarCost: apiFlag === "Insert" ? '' : rowData?.tendarCost,
            work_Start_Date: apiFlag === "Insert" ? '' : moment(rowData?.work_Start_Date).format("YYYY-MM-DD"),
            work_Completion_Date: apiFlag === "Insert" ? '' : moment(rowData?.work_Completion_Date).format("YYYY-MM-DD"),
            budgetCode: '',
            noOfManpowerDeploy: apiFlag === "Insert" ? '' : (rowData.noOfManpowerDeploy ? rowData.noOfManpowerDeploy : ''),
            periodOM: apiFlag === "Insert" ? '' : (rowData?.period_OM_Month ? rowData?.period_OM_Month : ''),
            approvalDateDeputyCollector: apiFlag === "Update" ? (rowData.approvalDeputyCollectorDate ? moment(rowData?.approvalDeputyCollectorDate).format("YYYY-MM-DD") : '') : '',
            resolutionNo: apiFlag === "Insert" ? '' : (rowData?.resolutionNo ? rowData?.resolutionNo : ''),
        }
    )

    const handleInputChange = (e) => {
        setProjectSanctionDetailsTextField({ ...ProjectSanctionDetailsTextField, [e.target.name]: e.target.value })

        ////////////////////////////

        const { name, value } = e.target;

        setProjectSanctionDetailsTextField({
            ...ProjectSanctionDetailsTextField,
            [name]: value
        });

        if (name === 'budget_Provision') {
            const budgetProvision = parseFloat(value) || 0;
            setMaxLength(value.length);
            const adminApproveCost = parseFloat(ProjectSanctionDetailsTextField.administrativeApproveCost) || 0;
            setIsValidText(adminApproveCost <= budgetProvision);
        }

        if (name === 'administrativeApproveCost') {
            const budgetProvision = parseFloat(ProjectSanctionDetailsTextField.budget_Provision) || 0;
            const adminApproveCost = parseFloat(value) || 0;
            setIsValidText(adminApproveCost <= budgetProvision);
        }
    }


    const [projectNameMilestoneDDL, setProjectNameMilestoneDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
        projectCode: '',
        numberOfMileStone: apiFlag === 'Update' ? rowData.numberOfMileStone : ''
    })

    const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_WorkTypeID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.workAndWorkType,
    })
    const [contractorNameDDL, setContractorNameDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_WorkTypeID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.m_WorkTypeID,
    })
    const [StagesWorkDDL, setStagesWorkDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_Tender_StagesID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.tender_Stages,
    })

    const [tenderAmountTypeDDL, setTenderAmountTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_Tender_Amount_TypeID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.tender_Amount_Type
    })
    const [budgetHeadDDL, setBudgetHeadDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_BudgetID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.budgetName,
        budgetCode: ''
    })
    const [ProjectTypeDDL, setProjectTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_ProjectTypeID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.projectTypeName,
    })
    const [manPowerTypeDDL, setManPowerTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_ManpowerTypeID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.manpowerType,
    });
    const [muncipleCouncilorDDL, setMuncipleCouncilorDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    });

    const [projectRemarkDDL, setProjectRemarkDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_ProjectRemarkID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.projectRemark,
    });


    useEffect(() => {
        const data = { UserID, token }
        dispatch(MunicipalCouncilorDDLAPI({ data }))
    }, [])

    const { MunicipalCouncilorDDLData } = useSelector(state => state.MunicipalCouncilorDDLData)

    useEffect(() => {
        const data = { UserID, token, manPowerTypeDDL }
        dispatch(ManPowerTypeDDLAPI({ data }))
    }, [])


    const { ManPowerTypeDDLData } = useSelector(state => state.ManPowerTypeDDLData)

    const handleOnClearClick = () => {
        setCurrentPage(0)
        setProjectTypeDDL({
            ...ProjectTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setWorkAndWorkTypeDDL({
            ...WorkAndWorkTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setProjectNameMilestoneDDL({
            ...projectNameMilestoneDDL,
            ID: 0,
            Label: "---Select---"
        })
        setBudgetHeadDDL({
            ...budgetHeadDDL,
            ID: 0,
            Label: "---Select---",
            budgetCode: ''
        })
        ProjectSanctionDetailsTextField.budget_Provision = ''
        ProjectSanctionDetailsTextField.administrativeApproveCost = ''
        ProjectSanctionDetailsTextField.administrativeApproveDate = ''
        ProjectSanctionDetailsTextField.dateOfTenderFloated = ''
        ProjectSanctionDetailsTextField.tendarCost = ''
        ProjectSanctionDetailsTextField.work_Start_Date = ''
        ProjectSanctionDetailsTextField.work_Completion_Date = ''
        ProjectSanctionDetailsTextField.budgetCode = ''
        ProjectSanctionDetailsTextField.noOfManpowerDeploy = ''
        ProjectSanctionDetailsTextField.periodOM = ''
        budgetHeadDDL.budgetCode = ''
        setStagesWorkDDL({
            ...StagesWorkDDL,
            ID: 0,
            Label: "---Select---"
        })
        setTenderAmountTypeDDL({
            ...tenderAmountTypeDDL,
            ID: 0,
            Label: "---Select---"
        })
        setContractorNameDDL({
            ...contractorNameDDL,
            ID: 0,
            Label: "---Select---"
        })
    }

    useEffect(() => {
        const data = {
            M_ContractorID: 0,
            M_ProjectID: projectNameMilestoneDDL.ID,
            Flag: 'Master',
            M_UsersID: UserID,
            token: token
        }
        dispatch(ContractorNameDDLAPI({ data }))
    }, [projectNameMilestoneDDL.ID,])

    const { ContractorNameDDLData } = useSelector(state => state.ContractorNameDDLData)

    const handleAddUpdate = () => {
        const addData = {
            M_FinancialYearID: moment().format('YYYY'),
            M_MonthID: moment().format('MM'),
            M_ProjectRemarkID: projectRemarkDDL.ID,
            T_ProjectSanctionDetailsID: apiFlag === 'Insert' ? '0' : rowData?.t_ProjectSanctionDetailsID,
            T_AssignWorkToContractorID: apiFlag === 'Insert' ? '0' : rowData?.t_AssignWorkToContractorID,
            T_ProjectDetailsID: 0,
            T_ProjectWorkWiseMilestoneID: 0,
            M_ProjectID: projectNameMilestoneDDL.ID,
            M_WorkID: WorkAndWorkTypeDDL.ID,
            M_ContractorID: contractorNameDDL.ID,
            Budget_Provision: ProjectSanctionDetailsTextField?.budget_Provision,
            Administration_Approval_Cost: ProjectSanctionDetailsTextField?.administrativeApproveCost,
            Administrative_Approval_Date: ProjectSanctionDetailsTextField?.administrativeApproveDate,
            M_Tender_StagesID: StagesWorkDDL.ID,
            DateOfTenderFloated: ProjectSanctionDetailsTextField?.dateOfTenderFloated,
            M_Tender_Amount_TypeID: tenderAmountTypeDDL.ID,
            Tender_Amount_InLakhs: ProjectSanctionDetailsTextField?.tendarCost,
            Work_Start_Date: ProjectSanctionDetailsTextField?.work_Start_Date,
            Work_Completion_Date: ProjectSanctionDetailsTextField?.work_Completion_Date,
            M_UserID: UserID,
            token: token,
            Flag: apiFlag,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick,
            M_BudgetID: budgetHeadDDL.ID,
            M_ManpowerTypeID: manPowerTypeDDL.ID,
            NoOfManpowerDeploy: ProjectSanctionDetailsTextField.noOfManpowerDeploy,
            Period_OM_Month: ProjectSanctionDetailsTextField.periodOM,
            ApprovalDeputyCollectorDate: ProjectSanctionDetailsTextField.approvalDateDeputyCollector,
            ResolutionNo: ProjectSanctionDetailsTextField.resolutionNo,
            M_MunicipalCouncilorID: muncipleCouncilorDDL.ID,
        }
        dispatch(ProjectSanctionDetailsPostAPI({ addData }))
    }

    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>
                <span className="close" onClick={handleAddCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Project Sanction Details</div>
                <div className="modal-body" style={{ height: '85vh', overflowY: 'auto', width: '100%' }}>
                    <div className="row details-row mt-lg-n3">
                        <div className="col-12 col-lg-4">
                            <ProjectTypeDDLData
                                ProjectTypeDDL={ProjectTypeDDL}
                                setProjectTypeDDL={setProjectTypeDDL}
                                PopUpField={PopUpField}
                                mendetary='yes'
                            // IsPost={IsPost}
                            // PopUpField={PopUpField}

                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <ConcateWorkAndWorkTypeDDL
                                WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                                setWorkAndWorkTypeDDL={setWorkAndWorkTypeDDL}
                                PopUpField={PopUpField}
                                mendetary='yes'
                                FlagName=''
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <ProjectNameMilestoneDDL
                                projectNameMilestoneDDL={projectNameMilestoneDDL}
                                setProjectNameMilestoneDDL={setProjectNameMilestoneDDL}
                                PopUpField={PopUpField}
                                projectTypeDDL={ProjectTypeDDL}
                                WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                                mendetary='yes'
                                FlagName={apiFlag === "Insert" ? "ProjectSanction" : "ProjectSanction_Update"}
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <BudgetHeadDDL
                                budgetHeadDDL={budgetHeadDDL}
                                setBudgetHeadDDL={setBudgetHeadDDL}
                                editData={rowData}
                                apiFlag={apiFlag}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="budgetCode">Budget Code <Star /> </label>
                                <input
                                    className="form-control"
                                    id="budgetCode"
                                    type="text"
                                    name="budgetCode"
                                    disabled
                                    value={budgetHeadDDL.budgetCode}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>

                        {/* <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="tenderDate">Budget Provision <Star /> </label>
                                <input
                                    className="form-control"
                                    id="budget_Provision"
                                    type="text"
                                    name="budget_Provision"
                                    value={ProjectSanctionDetailsTextField.budget_Provision}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div> */}


                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="tenderDate">Budget Provision (In Lacs)<Star /> </label>
                                <input
                                    className="form-control"
                                    id="budget_Provision"
                                    type="text"
                                    name="budget_Provision"
                                    value={ProjectSanctionDetailsTextField.budget_Provision}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>


                        {
                            ProjectTypeDDL.ID === 2 || ProjectTypeDDL.ID === 3 ?

                                <div className="col-12 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="tenderDate">Approval Date Deputy Collector <Star /> </label>
                                        <input
                                            className="form-control"
                                            id="approvalDateDeputyCollector"
                                            type="date"
                                            name="approvalDateDeputyCollector"
                                            value={ProjectSanctionDetailsTextField.approvalDateDeputyCollector}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </div>
                                </div>
                                : <></>
                        }

                        {/* <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="tenderDate">Administrative Approval Cost (In Lacs)  <Star /> </label>
                                <input
                                    className="form-control"
                                    id="administrativeApproveCost"
                                    type="text"
                                    name="administrativeApproveCost"
                                    value={ProjectSanctionDetailsTextField.administrativeApproveCost}
                                    onChange={(e) => {
                                        if (ProjectSanctionDetailsTextField.budget_Provision <= (e.target.value)) {

                                            handleInputChange(e)
                                            setIsValidText(true)

                                        } else {
                                            handleInputChange(e)
                                            setIsValidText()

                                        }
                                    }
                                    }
                                />
                                {
                                    IsValidText && <text style={{ color: 'red' }}>Please enter valid cost</text>
                                }
                            </div>
                        </div> */}

                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="tenderDate">Administrative Approval Cost (In Lacs) <Star /> </label>
                                <input
                                    className="form-control"
                                    id="administrativeApproveCost"
                                    type="text"
                                    name="administrativeApproveCost"
                                    value={ProjectSanctionDetailsTextField.administrativeApproveCost}
                                    maxLength={maxLength}
                                    onChange={(e) => handleInputChange(e)}
                                />
                                {
                                    !IsValidText && <span style={{ color: 'red' }}>Please enter a valid cost that does not exceed the budget provision.</span>
                                }
                            </div>
                        </div>

                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="tenderDate">Administrative Approval Date <Star /> </label>
                                <input
                                    className="form-control"
                                    id="administrativeApproveDate"
                                    type="date"
                                    name="administrativeApproveDate"
                                    value={ProjectSanctionDetailsTextField.administrativeApproveDate}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        {
                            ProjectTypeDDL.ID === 5 ?
                                <>
                                    <div className="col-12 col-lg-4">
                                        <div className="form-group">
                                            <label className="d-block" htmlFor="tenderDate">Resolution No.<Star /> </label>
                                            <input
                                                className="form-control"
                                                id="resolutionNo"
                                                type="text"
                                                name="resolutionNo"
                                                value={ProjectSanctionDetailsTextField.resolutionNo}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-3">
                                        <div className="form-group">
                                            <MuncipleCouncilorDDL
                                                muncipleCouncilorDDL={muncipleCouncilorDDL}
                                                setMuncipleCouncilorDDL={setMuncipleCouncilorDDL}
                                                DDLData={MunicipalCouncilorDDLData}
                                            />
                                        </div>
                                    </div>
                                </>
                                : <></>

                        }
                        <div className="col-12 col-lg-4">
                            <StagesOfWorkDDL
                                StagesWorkDDL={StagesWorkDDL}
                                setStagesWorkDDL={setStagesWorkDDL}
                                PopUpField={PopUpField}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="tenderDate">Date of Tender Floated <Star /> </label>
                                <input
                                    disabled={StagesWorkDDL.ID == 3}
                                    className="form-control"
                                    id="dateOfTenderFloated"
                                    type="date"
                                    name="dateOfTenderFloated"
                                    value={ProjectSanctionDetailsTextField.dateOfTenderFloated}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <TenderAmountTypeDDL
                                tenderAmountTypeDDL={tenderAmountTypeDDL}
                                setTenderAmountTypeDDL={setTenderAmountTypeDDL}
                                PopUpField={PopUpField}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="tenderDate">Tender Amount (In Lacs) <Star />  </label>
                                <input
                                    className="form-control"
                                    id="tendarCost"
                                    type="text"
                                    name="tendarCost"
                                    value={ProjectSanctionDetailsTextField.tendarCost}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        // console.log(tenderAmountTypeDDL)
                                        // if (tenderAmountTypeDDL.ID <= (e.target.value)) {
                                        //     handleInputChange(e)
                                        //     setIsValidText1(e)
                                        //     console.log('if')
                                        // } else {
                                        //     handleInputChange(e)
                                        //     setIsValidText1()
                                        //     console.log('else')
                                        // }

                                    }

                                    }
                                />
                                {
                                    IsValidText1 && <text style={{ color: 'red' }}>Please enter valid cost</text>
                                }
                            </div>
                        </div>
                        {
                            StagesWorkDDL.ID === 3 ?
                                <div className="col-12 col-lg-4">
                                    <ContractorNameDDL
                                        contractorNameDDL={contractorNameDDL}
                                        setContractorNameDDL={setContractorNameDDL}
                                        projectNameMilestoneDDL={projectNameMilestoneDDL}
                                        DDLData={ContractorNameDDLData}
                                        PopUpField={PopUpField}
                                        mendetary='yes'
                                    />
                                </div> : <></>
                        }
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="tenderDate">Number of Milestones <Star /> </label>
                                <input
                                    className="form-control"
                                    id="numberOfMileStone"
                                    type="text"
                                    name="numberOfMileStone"
                                    disabled
                                    value={projectNameMilestoneDDL?.numberOfMileStone}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        {
                            ProjectTypeDDL.ID === 1 ?
                                <>
                                    <div className="col-12 col-lg-4">
                                        <ManPowerTypeDDL
                                            manPowerTypeDDL={manPowerTypeDDL}
                                            setManPowerTypeDDL={setManPowerTypeDDL}
                                            DDLData={ManPowerTypeDDLData}
                                            PopUpField={PopUpField}
                                            mendetary='yes'
                                            FlagName=''
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="form-group">
                                            <label className="d-block" htmlFor="tenderDate">No of Manpower Deploy <Star /> </label>
                                            <input
                                                className="form-control"
                                                id="noOfManpowerDeploy"
                                                type="text"
                                                name="noOfManpowerDeploy"
                                                value={ProjectSanctionDetailsTextField.noOfManpowerDeploy}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="form-group">
                                            <label className="d-block" htmlFor="tenderDate">Period O&M (In Months ) <Star /> </label>
                                            <input
                                                className="form-control"
                                                id="periodOM"
                                                type="text"
                                                name="periodOM"
                                                value={ProjectSanctionDetailsTextField.periodOM}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : <></>
                        }
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="tenderCost">Work Start Date <Star /> </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="work_Start_Date"
                                    id="work_Start_Date"
                                    value={ProjectSanctionDetailsTextField.work_Start_Date}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="d-block" htmlFor="technicalApproveDate">Work Completion Date <Star /> </label>
                                <input
                                    className="form-control"
                                    id="work_Completion_Date"
                                    type="date"
                                    name="work_Completion_Date"
                                    value={ProjectSanctionDetailsTextField.work_Completion_Date}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <ProjectRemarkDDL
                                projectRemarkDDL={projectRemarkDDL}
                                setProjectRemarkDDL={setProjectRemarkDDL}
                                Flag='SanctionalWork'
                                editData={rowData}
                                PopUpField={PopUpField}
                            />
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={() => { popupBtn === 'Clear' ? handleOnClearClick() : handleAddCloseClick() }}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />{popupBtn}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    disabled={
                                        ProjectTypeDDL.ID === 0 ||
                                        WorkAndWorkTypeDDL.ID === 0 ||
                                        projectNameMilestoneDDL.ID === 0 ||
                                        budgetHeadDDL.ID === 0 ||
                                        StagesWorkDDL.ID === 0 ||
                                        tenderAmountTypeDDL.ID === 0 ||
                                        // manPowerTypeDDL.ID === 0 ||

                                        ProjectSanctionDetailsTextField.budget_Provision === '' ||
                                        ProjectSanctionDetailsTextField.administrativeApproveCost === '' ||
                                        ProjectSanctionDetailsTextField.administrativeApproveDate === '' ||

                                        (StagesWorkDDL.ID != 3 ? ProjectSanctionDetailsTextField.dateOfTenderFloated === '' : '') ||

                                        ProjectSanctionDetailsTextField.tendarCost === '' ||
                                        ProjectSanctionDetailsTextField.work_Start_Date === '' ||
                                        ProjectSanctionDetailsTextField.work_Completion_Date === ''
                                        // ProjectSanctionDetailsTextField.budgetCode === '' ||
                                        // ProjectSanctionDetailsTextField.noOfManpowerDeploy === '' ||
                                        // ProjectSanctionDetailsTextField.periodOM === ''
                                    }
                                    onClick={() => handleAddUpdate()}
                                >{apiFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popupFlag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup >
        </div >
    )
}