
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../Helper/Context/context";
import { ProjectPriorityAPI } from "../../Redux/DDLSlice";
import { useEffect } from "react";



export default function ProjectPriorityDDL(props) {
    const { projectPriorityDDL, setProjectPriorityDDL, projectPriorityDDLData, FlagName, apiFlag, editData, mendetary } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()

    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = { UserID: UserID, token: token }
        dispatch(ProjectPriorityAPI({ data }))
    }, [])

    const { ProjectPriorityData } = useSelector(state => state.ProjectPriorityDDLData)

    useEffect(() => {
        handleProjectPriorityDDL()
    }, [ProjectPriorityData])

    const handleProjectPriorityDDL = () => {
        if (ProjectPriorityData && ProjectPriorityData.table && ProjectPriorityData.table.length > 0) {

            let list = ProjectPriorityData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.projectPriorityName,
            }))
            setProjectPriorityDDL({
                DDL: list,
                ID: apiFlag === 'Update' ? editData.m_ProjectPriorityID : 0,
                Label: apiFlag === 'Update' ? editData.projectPriorityName : "---Select---"
                // ID: PopUpField ? PopUpField?.apiFlag === "Insert" ? 0 : PopUpField?.rowData?.m_Product_CategoryID : "0",
                // Label: PopUpField ? PopUpField?.apiFlag === "Insert" ? "Select..." : PopUpField?.rowData?.categoryName : "Select...",
            })
        }
        else {
            setProjectPriorityDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Project Priority {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: projectPriorityDDL.ID, label: projectPriorityDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setProjectPriorityDDL({ ...projectPriorityDDL, ID: e.value, Label: e.label })
                            :
                            setProjectPriorityDDL({ ...projectPriorityDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={projectPriorityDDL.DDL}

                />
            </div>
        </>
    )
}