
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const ProjectSanctionDetailsSidebarExport = (props) => {
    const { ExportData, name, title, projectType } = props

    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportUserCreation"
                className="btn btn-success float-end download-table-xls-button float-end btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-ExportUserCreation" style={{ display: 'none', width: '50%' }}>

                <h5><span>&emsp;</span></h5>

                <tr>
                    <th colSpan={10} style={{ textAlign: 'center', fontSize: 16 }}>{name}</th>
                </tr>
                {/* <tr><td></td></tr> */}

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#254462', color: "#fff" }}>
                        <th style={{ width: "5%", borderRight: '1px solid white' }} >Sr.No.</th>
                        <th>Work Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Budget Provision (In Lacs)</th>
                        <th style={{ borderLeft: "1px solid white" }}>Administrative Approval Cost (In Lacs)</th>
                        <th style={{ borderLeft: "1px solid white" }}>Administrative Approval Date</th>
                        <th style={{ borderLeft: "1px solid white" }}>Tender Stages</th>
                        <th style={{ borderLeft: "1px solid white" }}>Date of Tender Floated</th>
                        <th style={{ borderLeft: "1px solid white" }}>Tender Amount Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Tender Amount (In Lacs)</th>
                        <th style={{ borderLeft: "1px solid white" }}>Contractor Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {

                        ExportData && ExportData.table && ExportData.table.length > 0 ? ExportData.table.map((item, i) => (
                            <tr style={{ textAlign: "center", border: '1px solid black' }}>
                                <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                <td>{item.workAndWorkType ? item.workAndWorkType : "-"}</td>
                                <td>{item.projectName ? item.projectName : "-"}</td>
                                <td style={{ textAlign:'right' }}>{item.budget_Provision ? item.budget_Provision.toFixed(2) : "-"}</td>
                                <td style={{ textAlign:'right' }}>{item.administrativeApproveCost ? item.administrativeApproveCost.toFixed(2) : "-"}</td>
                                <td style={{ textAlign:'right' }}>{item.administrativeApproveDate ? moment(item.administrativeApproveDate).format("DD-MM-YYYY") : "-"}</td>
                                <td>{item.tender_Stages ? item.tender_Stages : "-"}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{item.dateOfTenderFloated ? moment(item.dateOfTenderFloated).format("DD-MM-YYYY") : "-"}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{item.tender_Amount_Type ? item.tender_Amount_Type : "-"}</td>
                                <td style={{ textAlign:'right' }}>{item.tendarCost ? item.tendarCost.toFixed(2) : "-"}</td>
                                <td>{item.contractor_Name ? item.contractor_Name : "-"}</td>
                                <td>{item.projectRemark ? item.projectRemark : "-"}</td>
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}