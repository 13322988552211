import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from '../../Helper/Context/context';
import { loginUser, logout } from '../../Helper/Context/actions';
import LoaderFile from '../../Helper/Loader/LoaderFile';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { toastErrorr } from '../../Helper/ToastMessage';

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useAuthDispatch();
    // const { loading, errorMessage } = useAuthState();
    const userDetails = useAuthState();
    const { loading } = userDetails

    const [passwordField, setpasswordField] = useState("password")
    const [password, setpassword] = useState("")
    const [userName, setuserName] = useState("")
    const [Validationerror, setValidationerror] = useState("")

    useEffect(() => {
        logout(dispatch);
    }, [])

    const handleLogin = async (e) => {
        e.preventDefault();
        if (userName !== '' && password !== '') {
            try {
                let response = await loginUser(dispatch, { userName, password });
                if (!response) return;
                navigate('/dashboard');
            } catch (error) {
                console.log(error);
            }
            setValidationerror('')
        } else {
            setValidationerror('Error')
        }
    };


    const showPassword = () => {
        if (passwordField === "password") {
            setpasswordField("text")
        } else {
            setpasswordField("password")
        }
    }

    const [downloadPercentage, setDownloadPercentage] = useState()
    const [loader, setloader] = useState(false)
    const handleDownload = async () => {
        setloader(true);
        let totalSize = 0;
        let downloadedSize = 0;

        try {
            const response = await fetch('https://files.catbox.moe/6hd6f0.apk');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            totalSize = parseInt(response.headers.get('Content-Length'), 10);
            const reader = response.body.getReader();
            const stream = new ReadableStream({
                async start(controller) {
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            break;
                        }
                        downloadedSize += value.length;
                        const percentage = (downloadedSize / totalSize) * 100;
                        // console.log(`Downloaded: ${percentage.toFixed(2)}%`);
                        setDownloadPercentage(percentage.toFixed(2));
                        controller.enqueue(value);
                    }
                    controller.close();
                }
            });

            const newResponse = new Response(stream);
            const blob = await newResponse.blob();

            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'NMMC-PTMS.apk';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

        } catch (error) {
            // console.error('Error fetching the file:', error);
            toastErrorr('Failed to download the file. Please try again later.');
        } finally {
            setloader(false);
            setTimeout(() => {
                setDownloadPercentage(0);
            }, 3000);
        }
    };

    return (
        <>
            {loading && <LoaderFile />}

            <div className="bjp_background">
                <div className="blur">
                    <div id="loader-wrapper">
                        <div id="loader"></div>
                        <div className="loader-section section-left"></div>
                        <div className="loader-section section-right"></div>
                    </div>
                    <div className="container w-100">
                        <div className="left_form">
                            <div className="limiter">
                                <div className="container-login100 pb-0 pt-0">
                                    <div className=" col-lg-12 col-md-12">
                                        <div className="row" style={{ height: "475px" }}>
                                            <div className="col-lg-6 col-md-6 d_flexImg">
                                                <span className="login100-form-title mt-lg-2" style={{ position: 'fixed' }}>
                                                    <div className='mx-auto'>
                                                        <div className="navbar-brand-wrapper text-center title">
                                                            <img src='./assets/img/LoginLogo.png' className='d-block text-center mb-3' style={{ width: "40%", margin: "auto" }} />
                                                            {/* <img src='./assets/img/unnamed-1.png' className='d-block text-center mb-3' style={{ width : "50%" ,margin: "auto" }} /> */}
                                                            <span className="navbar-brand brand-logo font-weight-bold animate-charcter"
                                                                style={{ fontSize: "14px", textTransform: 'uppercase' }}>
                                                                Navi Mumbai Municipal Corporation
                                                            </span>
                                                        </div>
                                                        {/* </Rotate > */}
                                                        <span className="navbar-brand brand-logo font-weight-bold mt-3"
                                                            style={{ color: "#293990", fontSize: "17px" }}>
                                                            Project Tracking and<br />Monitoring System
                                                        </span>
                                                    </div>
                                                    <div className='mt-5 text-center'>
                                                        <button onClick={handleDownload} disabled={loader}>
                                                            {loader ?
                                                                <p className="text-primary cursor-pointer fw-bold" style={{color: 'blue',fontWeight: 'bold'}}>
                                                                    <i className="fa fa-spinner fa-spin fw-bold" style={{ marginRight: '5px', fontSize: '16px' }}></i>Please Wait While Downloading...</p>

                                                                :
                                                                <a className="text-primary cursor-pointer">
                                                                    <img src='./assets/img/android.png' style={{ marginRight: '5px', width:'20px', color: 'blue' }} />
                                                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>Download Android Mobile App</span></a>
                                                            }
                                                        </button>
                                                        <div className='d-flex mt-2'>
                                                            <h6 className='mt-1' style={{color:'black',fontSize:'12px'}}>Apk Url :- </h6> 
                                                            <a href='https://nmmcptms.in/APK/NMMC-PTMS.apk' style={{color:'blue',fontSize:'12px',textDecoration:'underline'}}> https://nmmcptms.in/APK/NMMC-PTMS.apk</a>
                                                        </div>
                                                        {downloadPercentage > 0 && (
                                                            <div className="fw-bold text-primary" style={{color: 'blue',fontWeight: 'bold'}}>
                                                                <progress value={downloadPercentage} max="100" className="custom-progress" style={{ fontSize: '15px', marginTop: '5px' }} />
                                                                <span style={{ fontSize: '15px' }}> {downloadPercentage}%</span>
                                                            </div>
                                                        )}
                                                    </div>

                                                </span>

                                            </div>
                                            <div className="col-lg-6 col-md-6 right_form">
                                                <div className="wrap-login100">
                                                    <form className="login100-form validate-form">
                                                        <span className="login100-form-title ">
                                                            {/* <Flip > */}
                                                            <div className="navbar-brand-wrapper text-center title">
                                                                {/* <img src='./assets/img/Ladakh.png' className='d-block text-center mb-3' style={{ width: "50%", margin: "auto",background:"white",borderRadius:"20px" }} />  */}
                                                                <img src='./assets/img/profile.png' className='d-block text-center'
                                                                    style={{ width: "60px", margin: "auto" }} />

                                                            </div>
                                                            {/* </Flip > */}
                                                        </span>
                                                        {/* <hr /> */}
                                                        <span className="login100-form-title pt-2 pb-3">
                                                            Welcome
                                                        </span>

                                                        <div className="form-group wrap-input100 validate-input ">
                                                            <input
                                                                type="text"
                                                                className={`form-control ${Validationerror === '' ? 'login-input' : 'login-input-error'}`}
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Username"
                                                                style={{ color: "#000" }}
                                                                value={userName}
                                                                onChange={(e) => { setuserName(e.target.value); setValidationerror('') }}
                                                            />
                                                        </div>
                                                        <div className="form-group wrap-input100 validate-input">
                                                            <span className="btn-show-pass">
                                                                <i onClick={showPassword} className={passwordField === "text" ? "fa fa-eye cursor-pointer" : "fas fa-eye-slash cursor-pointer"}></i>
                                                            </span>
                                                            <input
                                                                type={passwordField}
                                                                className={`form-control ${Validationerror === '' ? 'login-input' : 'login-input-error'}`}
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Password"
                                                                value={password}
                                                                onChange={(e) => { setpassword(e.target.value); setValidationerror('') }}
                                                            />

                                                        </div>
                                                        <div className="row forgot">
                                                            {/* <div className="col-6">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                Remember me
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                            <div className="col-12 text-center">
                                                                <a href="#">forgot Password?</a>
                                                            </div>
                                                        </div>




                                                        <div className="container-login100-form-btn">
                                                            <div className="wrap-login100-form-btn">
                                                                <button type='button' className="up login100-form-btn" onClick={handleLogin}>
                                                                    <span className='ml-4'>Login</span>
                                                                    <span className='loginarrow mt-n1'><ArrowRight size={20} /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id="dropDownSelect1"></div> */}


            </div>

        </>

    )
}
