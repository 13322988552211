import React, { useState } from "react";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import BudgetPopUp from "./BudgetPopUp";
import Select from "react-select"
import MobileHeader from "../../../Components/Header/MobileHeader";

export default function TotalBudget() {

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);

    const toggle = () => {
        setIsOpen(!isopen);
    };


    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
    }
    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        setflag("Add")
    }
    const handleOnEditClick = () => {
        setshowCreatePopup(true)
        setflag("Edit")
    }
    const handleOnDeleteClick = () => {
        setshowDeletePopup(true)
        setflag("Delete")
    }

    const tableData = [
        {
            id: 1,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Capital",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
        {
            id: 2,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Revenue",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
        {
            id: 3,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Revenue",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
        {
            id: 4,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Capital",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
        {
            id: 5,
            BudgetHead: "Budget Head Name",
            BudgetCode: "BUD123456",
            BudgetType: "Revenue",
            BudgetProvision: "Rs. 12.60L",
            TotalProjects: "10"
        },
    ]

    const options = [
        { value: 'Road', label: 'Road' },
        { value: 'Building Work', label: 'Building Work' },
        { value: 'Repairs & Maintenance', label: 'Repairs & Maintenance' }
    ]


    return (
        <>
            <div className="container-scroller">
            <MobileHeader toggle={toggle}/>
                <Header toggle={toggle} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar isopen={isopen} />
                    {/* <!-- Main Container --> */}
                    <div class="main-panel">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-md-12 grid-margin">
                                    <div class="row page-heading">
                                        <div class="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 class="font-weight-bold">Total Budget - Budget Code - Budget Type</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div className="filter mb-4">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-4 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="Budgettype">Budget Type </label>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options}

                                                        />
                                                    </div>

                                                </div>

                                                <div className="col-6 col-lg-6">
                                                    <button type="button" className="btn btn-primary text-white m-0 mt-4 mt-md-0 mt-lg-4 float-right waves-effect waves-light">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <table cellpadding="0" cellspacing="0" border="0" class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name of the Department</th>
                                                    <th>Budget Head</th>
                                                    <th>Budget Code</th>
                                                    <th>Budget Type</th>
                                                    <th>Budget Provision</th>
                                                    <th>Expenditure</th>
                                                    <th>Balance</th>
                                                    <th>Total Projects</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Capital</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Revenue</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Revenue</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Capital</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Capital</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Revenue</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Revenue</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Revenue</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Capital</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>Public Works Department</td>
                                                    <td>Budget Head Name</td>
                                                    <td>BUD123456</td>
                                                    <td><span class="badge badge-dark badge-lg px-3 py-2">Revenue</span></td>
                                                    <td>Rs. 12.60L</td>
                                                    <td>Rs. 10.60L</td>
                                                    <td>Rs. 2.00L</td>
                                                    <td>2</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row"><div class="col-sm-12 col-md-5"><div class="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 1 to 10 of 25 entries</div></div><div class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="example_paginate"><ul class="pagination"><li class="paginate_button page-item previous disabled" id="example_previous"><a href="#" aria-controls="example" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="example" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="2" tabindex="0" class="page-link">2</a></li><li class="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="3" tabindex="0" class="page-link">3</a></li><li class="paginate_button page-item next" id="example_next"><a href="#" aria-controls="example" data-dt-idx="4" tabindex="0" class="page-link">Next</a></li></ul></div></div></div>
                                </div>
                            </div>

                            <div class="copyright">
                                Copyright 2023. all rights are reserved.<b>Version 1.0</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showCreatePopup ? <BudgetPopUp open={showCreatePopup} handleOnCloseClick={handleOnCloseClick} flag={flag} /> : <></>}
        </>
    )
}