
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const ProjectDetailsExport = (props) => {
    const { ExportData, name, title, projectType } = props

    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportUserCreation"
                className="btn btn-success download-table-xls-button btn btn-export btn-md mr-1 mb-1 ml-1 float-right"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-ExportUserCreation" style={{ display: 'none', width: '50%' }}>

                <h5><span>&emsp;</span></h5>

                <tr>
                    <th colSpan={10} style={{ textAlign: 'center', fontSize: 16}}>{name}</th>
                </tr>
                {/* <tr><td></td></tr> */}

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#254462', color: "#fff" }}>
                        <th style={{width:"5%",borderRight:'1px solid white'}} >Sr.No.</th>
                        <th>Department</th>
                        <th style={{ borderLeft: "1px solid white" }}>Ward Name</th>
                        <th style={{ borderLeft: "1px solid white" }}>Electroal <br />Ward<br /> Number</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Tracker Code</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Work Type</th>
                        <th style={{ borderLeft: "1px solid white" }}>Project Name</th>
                        {
                            title != 'Proposed Project' &&
                            <th style={{ borderLeft: "1px solid white" }}>Tender Amt (lacs.)</th>
                        }
                        {
                            projectType == 'OnGoing' &&
                            <>
                                <th style={{ borderLeft: "1px solid white" }}>Work Start Date</th>
                            </>
                        }
                        {
                            projectType == 'Completed' &&
                            <>
                                <th style={{ borderLeft: "1px solid white" }}>Work Start Date</th>
                                <th style={{ borderLeft: "1px solid white" }}>Work Completion Date</th>
                            </>
                        }
                        {
                            (projectType == 'OnGoing' || projectType == 'Completed') &&
                            <>
                                <th style={{ borderLeft: "1px solid white" }}>Phyprogress</th>
                                <th style={{ borderLeft: "1px solid white" }}>Fin Progress</th>
                            </>
                        }
                        <th style={{ borderLeft: "1px solid white" }}>Project <br /> Priority</th>
                        <th style={{ borderLeft: "1px solid white" }}>Key  <br />Project</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        ExportData && ExportData.length > 0 ? ExportData.map((item, i) => (
                            <tr style={{ textAlign: "center", border: '1px solid black' }}>
                                <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                <td>{item.departmentName ? item.departmentName : "-"}</td>
                                <td>{item.wardName ? item.wardName : "-"}</td>
                                <td style={{ textAlign:'right' }}>{item.m_WardWiseNumber ? item.m_WardWiseNumber : '0'}</td>
                                <td>{item.project_Tracker_Code ? item.project_Tracker_Code : "-"}</td>
                                <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                                <td>{item.workTypeName ? item.workTypeName : "-"}</td>
                                <td>{item.projectName ? item.projectName : "-"}</td>
                                {
                                    title != 'Proposed Project' &&
                                    <td style={{ textAlign:'right' }}>{item.tendarCost ? item.tendarCost : '0'}</td>
                                }

                                {/* <td></td>
                                                            <td></td> */}
                                {
                                    projectType == 'OnGoing' &&
                                    <>
                                        <td></td>
                                    </>
                                }
                                {
                                    projectType == 'Completed' &&
                                    <>
                                        <td style={{ whiteSpace: 'nowrap' }}>{item.work_Start_Date ? moment(item.work_Start_Date).format('DD-MM-YYYY') : ''}</td>
                                        <td>{item.work_Completion_Date ? moment(item.work_Completion_Date).format('DD-MM-YYYY') : '-'}</td>
                                    </>
                                }
                                {
                                    (projectType == 'OnGoing' || projectType == 'Completed') &&
                                    <>
                                        <td style={{ textAlign:'right' }}>{item.physicalProgress ? `${item.physicalProgress.toFixed(2)} %` : '0 %'}</td>
                                        <td></td>
                                    </>
                                }
                                <td>{item.projectPriorityName ? item.projectPriorityName : '-'}</td>
                                <td>{item.key_ProjectName ? item.key_ProjectName : '-'}</td>
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}