import React, { useState } from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useEffect } from "react";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_T_BudgetAllocation_InsertUpdate } from "../BudgetManagementAPI/POSTAPI";
import { Get_DDL_M_Budget, Get_DDL_M_BudgetType, Get_DDL_M_Department } from "../../../APIs/DDLAPI/DDLAPI";
import { Star } from "../../../Components/CommoComponent/Star";


export default function AllocateBudgetPopUp({ flag, open, handleOnCloseClick, apiFlag, IsPost, setIsPost, editData, handleOnClearClick }) {

    const userDetails = useAuthState();
    const { UserID } = userDetails


    const [allowcateBudgetTextField, setallowcateBudgetTextField] = useState(
        {
            budgetAmount: apiFlag === "Update" ? editData.budgetAmount : "",
            budgetCode: apiFlag === "Update" ? editData.budgetCode : "",
        }
    )

    const handleInputChange = (e) => {
        setallowcateBudgetTextField({ ...allowcateBudgetTextField, [e.target.name]: e.target.value })
    }

    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_DepartementID : 0,
        Label: apiFlag === "Update" ? editData.departmentName : "---Select---"
    })

    const [financialYearDDL, setfinancialYearDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })

    const [budgetTypeDDL, setbudgetTypeDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_BudgetTypeID : 0,
        Label: apiFlag === "Update" ? editData.budgetTypeName : "---Select---"
    })
    const [budgetHeadDDL, setbudgetHeadDDL] = useState({
        DDL: [],
        ID: apiFlag === "Update" ? editData.m_BudgetID : 0,
        code: apiFlag === "Update" ? editData.budgetCode : "",
        Label: apiFlag === "Update" ? editData.budgetName : "---Select---"
    })

    const budgetHeadClear = () => {
        setbudgetHeadDDL({
          ...budgetHeadDDL,
            ID:  0,
            code:  "",
            Label:  "---Select---"
        })
    }

    const [formErrors, setformErrors] = useState({
        budgetAmountError: "",
    })
    const checkcodeValidation = (text) => {
       
        let regex = new RegExp(/^[0-9]*$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, budgetAmountError: "" })
        } else {
            setformErrors({ ...formErrors, budgetAmountError: "Enter valid number" })

        }
    }

    useEffect(() => {
        Get_DDL_M_Department({ UserID, departmentDDL: departmentDDL, setdepartmentDDL: setdepartmentDDL })
    }, [])
    useEffect(() => {
        Get_DDL_M_BudgetType({ UserID, budgetTypeDDL: budgetTypeDDL, setbudgetTypeDDL: setbudgetTypeDDL })
    }, [])
    useEffect(() => {
        Get_DDL_M_Budget({ UserID, budgetHeadDDL: budgetHeadDDL, setbudgetHeadDDL: setbudgetHeadDDL, budgetTypeDDL })
    }, [budgetTypeDDL.ID])


    const { budgetAmount, budgetCode } = allowcateBudgetTextField
    const Add = () => {
        Post_T_BudgetAllocation_InsertUpdate({
            BudgetAllocationID: apiFlag === "Insert" ? "0" : editData.t_BudgetAllocationID,
            financialYearDDL: financialYearDDL,
            BudgetID: budgetHeadDDL,
            departmentDDL: departmentDDL,
            allowcateBudgetTextField: allowcateBudgetTextField,
            UserID,
            apiFlag,
            handleOnCloseClick,
            IsPost,
            setIsPost,
            handleOnClearClick: handleOnClearClick
        })
    }





    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Allocate Budget</div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="Budgettype">Budget Type <Star/> </label>
                                <Select
                                    isClearable
                                    // isRtl={isRtl}
                                    isSearchable
                                    maxMenuHeight={140}
                                    value={{ value: budgetTypeDDL.ID, label: budgetTypeDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setbudgetTypeDDL({ ...budgetTypeDDL, ID: e.value, Label: e.label })
                                            :
                                            setbudgetTypeDDL({ ...budgetTypeDDL, ID: 0, Label: "---Select---"})
                                            budgetHeadClear()
                                    }}
                                    options={budgetTypeDDL.DDL}

                                />
                            </div>

                        </div>
                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="budgetHeadName">Budget Head Name <Star/> </label>
                                <Select
                                    isClearable
                                    isSearchable
                                    maxMenuHeight={140}
                                    value={{ value: budgetHeadDDL.ID, label: budgetHeadDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setbudgetHeadDDL({ ...budgetHeadDDL, ID: e.value, Label: e.label, code: e.code })
                                            :
                                            setbudgetHeadDDL({
                                                ...budgetHeadDDL, ID: 0, Label: "---Select---"
                                            })

                                    }}
                                    options={budgetHeadDDL.DDL}


                                />
                            </div>
                        </div>

                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="Budgettype">Budget Code <Star/></label>
                                <input
                                    className="form-control"
                                    id="budgetCode"
                                    type="text"
                                    disabled
                                    name="budgetCode"
                                    value={budgetHeadDDL.code}

                                />
                                
                            </div>

                        </div>
                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="Budgettype">Department <Star/></label>
                                <Select
                                    isClearable
                                    isSearchable
                                    maxMenuHeight={140}
                                    value={{ value: departmentDDL.ID, label: departmentDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setdepartmentDDL({ ...departmentDDL, ID: e.value, Label: e.label })
                                            :
                                            setdepartmentDDL({ ...departmentDDL, ID: 0, Label: "---Select---" })
                                    }}
                                    options={departmentDDL.DDL}

                                />
                            </div>

                        </div>

                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="budgetAmount">Budget Amount (lacs ) <Star/></label>
                                <input
                                    className="form-control"
                                    id="budgetAmount"
                                    type="text"
                                    name="budgetAmount"
                                    value={allowcateBudgetTextField.budgetAmount}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        checkcodeValidation(e.target.value)
                                    }}
                                />
                                  <span className="text-danger error-danger">{formErrors.budgetAmountError}</span>
                            </div>
                        </div>

                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}>
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        budgetTypeDDL.ID === 0 ||
                                        budgetHeadDDL.ID === 0 ||
                                        budgetHeadDDL.code === "" ||
                                        departmentDDL.ID === 0 ||
                                        allowcateBudgetTextField.budgetAmount === ""


                                    }
                                >
                                {flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
                        </div>
                    </div>
                </div>
        </div>


            </Popup >
        </div >
    )
}