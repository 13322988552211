import React from 'react'
import { AlertTriangle, Bell, Camera, ChevronDown, MessageSquare, Plus, Power, Search, Settings, User } from 'react-feather';
import { Link } from 'react-router-dom';
import { logout } from '../../Helper/Context/actions';
import { useAuthDispatch, useAuthState } from '../../Helper/Context/context';
import { useNavigate } from 'react-router-dom';

export default function MobileHeader({ setIsOpen, isopen }) {
    const toggle = () => {
        setIsOpen(!isopen);
    };
    const navigation = useNavigate();
    const dispatch = useAuthDispatch();
    const userDetails = useAuthState();

    const { username, RoleID } = userDetails

    const handleLogout = () => {
        logout(dispatch);
        navigation('/')
    };

    return (
        <div className="mobile-navbar-section">
            <nav className="navbar fixed-top header justify-content-between dark-shadow col-lg-12 col-12 p-0" >
                <div className='d-flex'>
                    <div className="navbar-brand-wrapper d-flex align-items-center justify-content-center " style={{ width: "80px" }} >
                        <a className="navbar-brand " >
                            <img src="assets/img/Ladakh.png" style={{height:"50px"}} className="small-logo" />
                            {/* LOGO */}
                        </a>
                    </div>

                    <button
                        onClick={toggle}
                        className="navbar-toggler mobile-header navbar-toggler align-self-center"
                        type="button"
                        data-toggle="minimize"
                    >
                        <span className="Mobile-icon-menu"></span>
                    </button>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end" style={{ width: "calc(100% - 230px)" }}>
                    {/* <span className="navbar-brand brand-logo header-logo" >
                        Project Tracking and Monitoring System
                    </span> */}
                    <ul className="navbar-nav navbar-nav-right">

                        <li className="nav-item nav-profile dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                data-toggle="dropdown"
                                id="profileDropdown"
                            >
                                <img src="assets/img/user_img.png" alt="profile" />
                                <span className="username">
                                    <h6>{username}</h6>
                                </span>
                                <i className="align-self-center ml-1">
                                    <ChevronDown />
                                </i>
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-right navbar-dropdown"
                                aria-labelledby="profileDropdown"
                            >
                                {/* <a className="dropdown-item">
                                    <i className="text-success mr-2">
                                        <Settings />
                                    </i>
                                    Settings
                                </a> */}
                                <span className="dropdown-item" onClick={handleLogout}>
                                    <i className="text-success mr-2">
                                        <Power />
                                    </i>
                                    Logout
                                </span>
                            </div>
                        </li>

                    </ul>
                </div>
            </nav>
        </div>
    )
}
