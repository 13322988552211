import React, { useState, useEffect } from "react";
import Select from "react-select"

import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import BudgetCreationPopUp from "./BudgetCreationPopUp";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { Pegination } from "../../../Components/Pegination/Pegination";
import DeletePopUp from "../../../Helper/DeletePopUp";
import { useAuthState } from "../../../Helper/Context/context";
import { Get_T_BudgetCreation_Select } from "../BudgetManagementAPI/GETAPI";
import { Post_T_BudgetCreation_Delete } from "../BudgetManagementAPI/POSTAPI";
import { Get_DDL_M_Budget, Get_DDL_M_BudgetType } from "../../../APIs/DDLAPI/DDLAPI";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import Footer from "../../../Components/Footer/Footer";

export default function BudgetCreation() {
    const userDetails = useAuthState();
    const { UserID } = userDetails

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);
    const [IsPost, setIsPost] = useState(false)
    const [editData, seteditData] = useState()
    const [apiFlag, setapiFlag] = useState()
    const [BudgetCreationTable, setBudgetCreationTable] = useState([])

    const [budgetHeadDDL, setbudgetHeadDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })
    const [budgetTypeDDL, setbudgetTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })

    // react pagination states 
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [loading, setloading] = useState()
    const [DeleteData, setDeleteData] = useState()

    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
        // setallState({ showCreatePopup: false, showDeletePopup: false, popUpFlag: "", apiFlag: "", editData: [], DeleteData: "" })

    }
    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        setflag("Add")
        setapiFlag("Insert")
        // setallState({...allState,showCreatePopup:true,apiFlag:"Insert",popUpFlag:"Add"})
    }
    const handleOnEditClick = (item) => {
        setshowCreatePopup(true)
        setflag("Update")
        setapiFlag("Update")
        seteditData(item)
        // setallState({...allState,showCreatePopup:true,apiFlag:"Upadte",popUpFlag:"Update",editData: item})
    }
    const handleOnDeleteClick = (item) => {
        setshowDeletePopup(true)
        setflag("Delete")
        setDeleteData(item)
        // setallState({...allState,DeleteData: item, showDeletePopup:true})
    }
    const handleOnSearchClick = () => {
        setIsPost(!IsPost)
        setCurrentPage(0)
    }

    const handleOnClearClick = () => {
        setCurrentPage(0)

        setbudgetHeadDDL({
            ...budgetHeadDDL,
            ID: 0,
            Label: "---Select---"
        })
        setbudgetTypeDDL({
            ...budgetTypeDDL,
            ID: 0,
            Label: "---Select---"
        })

        setIsPost(!IsPost)
    }


    useEffect(() => {
        Get_DDL_M_BudgetType({ UserID, budgetTypeDDL: budgetTypeDDL, setbudgetTypeDDL: setbudgetTypeDDL })
    }, [])

    useEffect(() => {
        Get_DDL_M_Budget({ UserID, budgetHeadDDL: budgetHeadDDL, setbudgetHeadDDL: setbudgetHeadDDL, budgetTypeDDL })
    }, [budgetTypeDDL.ID])


    useEffect(() => {
        Get_T_BudgetCreation_Select({ setBudgetCreationTable, BudgetCreationTable, UserID, budgetHeadDDL, budgetTypeDDL, setloading, setTotalCount: setTotalCount, From: From, To: To, rowNo: rowNo, PerPageCount: PerPageCount })
    }, [To, IsPost])

    const handleDeleteData = (item) => {
        Post_T_BudgetCreation_Delete({
            BudgetCreationID: DeleteData,
            // allState: allState.DeleteData,
            UserID,
            apiFlag,
            handleOnCloseClick,
            IsPost,
            setIsPost,
        })
    }


    return (
        <>
            {loading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="budgetcreation" subMenu="subBudget" isopen={isopen} />
                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Budget Creation</h4>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                title="Add" onClick={() => handleAddNewClick()}>
                                                <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-4 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="Budgettype">Budget Type </label>
                                                        <Select
                                                            isClearable
                                                            // isRtl={isRtl}
                                                            isSearchable
                                                            maxMenuHeight={140}
                                                            value={{ value: budgetTypeDDL.ID, label: budgetTypeDDL.Label }}
                                                            onChange={(e) => {
                                                                e ?
                                                                    setbudgetTypeDDL({ ...budgetTypeDDL, ID: e.value, Label: e.label })
                                                                    :
                                                                    setbudgetTypeDDL({
                                                                        ...budgetTypeDDL, ID: 0, Label: "Select"
                                                                    })

                                                            }}
                                                            options={budgetTypeDDL.DDL}
                                                        // options={options}

                                                        />
                                                    </div>

                                                </div>
                                                <div className="col-4 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment">  Budget Head Name </label>
                                                        <Select
                                                            isClearable
                                                            // isRtl={isRtl}
                                                            isSearchable
                                                            maxMenuHeight={140}
                                                            value={{ value: budgetHeadDDL.ID, label: budgetHeadDDL.Label }}
                                                            onChange={(e) => {
                                                                e ?
                                                                    setbudgetHeadDDL({ ...budgetHeadDDL, ID: e.value, Label: e.label })
                                                                    :
                                                                    setbudgetHeadDDL({
                                                                        ...budgetHeadDDL, ID: 0, Label: "Select"
                                                                    })

                                                            }}
                                                            options={budgetHeadDDL.DDL}
                                                        // options={options}

                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-4 col-lg-4 text-center">
                                                    <button type="button" className="btn btn-primary text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnSearchClick}>
                                                        {/* <Search/> */}
                                                        <Search size={18} style={{ marginRight: "3px" }} />  Search
                                                    </button>
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                        disabled={CurrentPage}
                                                    >
                                                        {/* <Search/> */}
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Budget Type</th>
                                                    <th>Budget Head Name</th>
                                                    <th>Budget Code</th>
                                                    <th>Budget Provision(lacs)</th>


                                                    <th style={{ width: "150px", textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    BudgetCreationTable && BudgetCreationTable.length > 0 ? BudgetCreationTable.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="text_center">{item.rowNum}</td>
                                                            <td>{item.budgetTypeName ? item.budgetTypeName : "-"}</td>
                                                            {/* <span className="badge badge-dark badge-lg px-3 py-2"></span> */}
                                                            <td>{item.budgetName ? item.budgetName : "-"}</td>
                                                            <td>{item.budgetCode ? item.budgetCode : "-"}</td>
                                                            <td>{item.budgetProvision ? item.budgetProvision.toFixed(2) : "-"}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Edit" onClick={() => handleOnEditClick(item)}>
                                                                    <Edit size={15} color="#007bff" />
                                                                </span>

                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Delete" onClick={() => handleOnDeleteClick(item.t_BudgetCreationID)}>
                                                                    <Trash2 size={15} color="red" />
                                                                </span>

                                                            </td>

                                                        </tr>
                                                    )) : ""
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {BudgetCreationTable && BudgetCreationTable.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={TotalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    }
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>

            {/* { showCreatePopup ? <BudgetCreationPopUp /> : <></>} */}

            {showCreatePopup ? <BudgetCreationPopUp
                open={showCreatePopup}
                handleOnCloseClick={handleOnCloseClick}
                flag={flag}
                apiFlag={apiFlag}
                IsPost={IsPost}
                editData={editData}
                setIsPost={setIsPost}
                handleOnClearClick={handleOnClearClick}
                // allState={allState}
            /> : <></>}
            {showDeletePopup ? <DeletePopUp
                open={showDeletePopup}
                handleOnCloseClick={handleOnCloseClick}
                handleDeleteData={handleDeleteData} /> : <></>}

        </>
    )
}