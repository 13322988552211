import React, { useEffect, useState } from "react";
import { Edit, Edit2, Eye, Search, Trash, Trash2, X } from "react-feather";
import Select from "react-select";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import DeletePopUp from "../../../Helper/DeletePopUp";
import FinancialViewWorkProgressPopUp from "./FinancialViewWorkProgressPopUp";
import FinancialWorkProgressPopUp from "./FinancialWorkProgressPopUp.js";
import Footer from "../../../Components/Footer/Footer";
import PhysicalWorkProgressViewPopUp from "../PhysicalFinancialWorkProgress/PhysicalWorkProgressViewPopUp";
import ProjectNameDDL from "../../../CommonDDL/ProjectNameDDL/ProjectNameDDL";
import StatusDDL from "../../../CommonDDL/StatusDDL/StatusDDL";
import { WorkTypeDDLData } from "../../../CommonDDL/WorkTypeDDLData";
import { useAuthState, useAuthStateYearMonth } from "../../../Helper/Context/context";
import { useDispatch, useSelector } from "react-redux";
import { WardNameDDLAPI } from "../../../Redux/DDLSlice";
import { ConcateWorkAndWorkTypeDDL } from "../../../CommonDDL/ConcateWorkAndWorkTypeDDL";
import { UserStatusDDLData } from "../../../CommonDDL/UserStatusDDLData";
import LoaderFile from "../../../Helper/Loader/LoaderFile.js";
import { FinancialWorkProgressTableDataAPI } from "../../../Redux/MasterSlice/FinancialWorkProgressSlice.js";
import { Pegination } from "../../../Components/Pegination/Pegination.js";
import ProjectNameMilestoneDDL from "../../../CommonDDL/ProjectNameMilestoneDDL/ProjectNameMilestoneDDL.js";
import { UserProgressStatusDDL } from "../../../CommonDDL/UserProgressStatusDDL.js";


export default function FinancialWorkProgress() {

  const userDetails = useAuthState();
  const { UserID, token } = userDetails
  const dispatch = useDispatch();

  const YearMonthDetails = useAuthStateYearMonth()
  const { year, Month } = YearMonthDetails


  // ----------pagination-------------
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)
  const [CurrentPage, setCurrentPage] = useState(0)
  const [IsSearch, setIsSearch] = useState(false)
  const [IsClear, setIsClear] = useState(false)
  const [IsPost, setIsPost] = useState(false)
  const [isopen, setIsOpen] = useState(true);

  const YearID = localStorage.getItem("YearID")

  const [WorkAndWorkTypeDDL, setWorkAndWorkTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "--Select--",
  })
  const [projectNameMilestoneDDL, setProjectNameMilestoneDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
    projectCode: '',
    numberOfMileStone: ''
  })

  const [UserProgressID, setUserProgressID] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });

  const [ProjectDDL, setProjectDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  })
  const [UserStatusDDL, setUserStatusDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });

  const [statusDDL, setStatusDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---",
  });

  const [PopUpField, setPopUpField] = React.useState({
    addPopUp: false,
    deletePopUp: false,
    showViewPopup: false,
    showPopup: false,
    popupFlag: '',
    popupBtn: "",
    apiFlag: "",
    rowData: ''
  })

  const handleAddCloseClick = () => {
    setPopUpField({ addPopUp: false, showViewPopup: false, showPopup: false })
  }

  const handlePost = () => {
    setIsPost(!IsPost)
  }
  const handleOnClearClick = () => {
    setCurrentPage(0)
    setWorkAndWorkTypeDDL({
      ...WorkAndWorkTypeDDL,
      ID: 0,
      Label: "---Select---",
    })
    setProjectDDL({
      ...ProjectDDL,
      ID: 0,
      Label: "---Select---"
    })
    setUserStatusDDL({
      ...UserStatusDDL,
      ID: 0,
      Label: "---Select---"
    })
    setProjectNameMilestoneDDL({
      ...projectNameMilestoneDDL,
      ID: 0,
      Label: 'Select...'
    })
    setUserProgressID({
      ...UserProgressID,
      ID: 0,
      Label: 'Select...'
    })
    setIsPost(!IsPost)
  }

  useEffect(() => {
    const data = {
      M_FinancialYearID: YearID ? YearID : 0,
      M_MonthID: Month.ID,
      M_WorkTypeID: WorkAndWorkTypeDDL.ID,
      M_ProjectID: projectNameMilestoneDDL.ID,
      UserStatusIndicatorID: UserProgressID.ID,
      Flag: 'FinancialWorkProgress',
      M_UsersID: UserID,
      FromTop: From,
      ToTop: To,
      token: token,
      // handleGetExport: handleGetExport
    }
    dispatch(FinancialWorkProgressTableDataAPI({ data }))
  }, [To, IsPost, IsClear, WorkAndWorkTypeDDL.ID, YearID, projectNameMilestoneDDL.ID, UserProgressID.ID,])

  const { tableData, isLoading } = useSelector(state => state.FinancialWorkProgressTableData)

  return (
    <>
      {isLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="financialworkprogress" subMenu="subProCreation" isopen={isopen} />
          {/* <!-- Main Container --> */}
          <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">Financial Work Progress</h4>
                    </div>
                    <div className="col-6 col-lg-4 mb-xl-0 py-2">
                      <h4 className="font-weight-bold float-right px-2 py-1 mr-3 btn-outline-count rounded">
                        Total Count :- <span className='text-danger px-2'>{tableData && tableData[0]?.totalCount ? tableData[0]?.totalCount : 0}</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    {/* <div className="card filter mb-4"> */}
                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-12 col-md-6 col-lg-3">
                          <ConcateWorkAndWorkTypeDDL
                            WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                            setWorkAndWorkTypeDDL={setWorkAndWorkTypeDDL}
                            FlagName=''
                          />
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          {/* <ProjectNameDDL
                            ProjectDDL={ProjectDDL}
                            setProjectDDL={setProjectDDL}
                            ProjectTypeDDL='0'
                            FlagName=''
                          /> */}
                          <ProjectNameMilestoneDDL
                            projectNameMilestoneDDL={projectNameMilestoneDDL}
                            setProjectNameMilestoneDDL={setProjectNameMilestoneDDL}
                            WorkAndWorkTypeDDL={WorkAndWorkTypeDDL}
                            // PopUpField={PopUpField}
                            projectTypeDDL="0"
                            mendetary='no'
                            FlagName='FinancialWorkProgress'
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-2">
                          {/* <UserStatusDDLData
                            UserStatusDDL={UserStatusDDL}
                            setUserStatusDDL={setUserStatusDDL}
                          /> */}
                          <UserProgressStatusDDL
                            UserProgressID={UserProgressID}
                            setUserProgressID={setUserProgressID}
                          />
                        </div>


                        <div className="col-12 col-lg-2 pl-lg-3">
                          <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick}>
                            {/* <Search/> */}
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th>Project Tracker Code</th>
                          <th style={{ whiteSpace: 'nowrap', }}>Work Type</th>
                          <th style={{ whiteSpace: 'nowrap', paddingRight: '7em', paddingLeft: '7em' }}>Project Name</th>
                          <th>Tender Cost (In Lacs)</th>
                          <th>Approval cost (In Lacs)</th>
                          <th>Actual cost (In Lacs)</th>
                          <th>Amount (In Lacs)</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tableData && tableData.length > 0 ? tableData.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">{item?.rowNum}</td>
                              <td>{item.project_Tracker_Code ? item.project_Tracker_Code : '-'}</td>
                              <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                              <td>{item.projectName ? item.projectName : '-'}</td>
                              <td className='text-right'>{item.tendarCost ? item.tendarCost.toFixed(2) : 0}</td>
                              <td className='text-right'>{item.administrativeApproveCost ? item.administrativeApproveCost.toFixed(2) : 0}</td>
                              <td className='text-right'>{item.actualCost ? item.actualCost.toFixed(2) : 0}</td>
                              <td className='text-right'>{item.expenditureAmount ? item.expenditureAmount.toFixed(2) : 0}</td>
                              <td>{item.workStatus ? item.workStatus : '-'}</td>
                            </tr>
                          )

                          ) : <tr style={{ textAlign: "center", whiteSpace: 'nowrap' }}>No data found...</tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>


      {PopUpField.addPopUp ? <FinancialWorkProgressPopUp
        open={PopUpField.addPopUp}
        PopUpField={PopUpField}
        handleAddCloseClick={handleAddCloseClick}
        IsPost={IsPost}
        setIsPost={setIsPost}
        handlePost={handlePost}
        handleOnCloseClick={handleAddCloseClick}

      /> : <></>
      }

      {PopUpField.deletePopUp ? <DeletePopUp
        open={PopUpField.deletePopUp}
        handleOnCloseClick={handleAddCloseClick}
      /> : <></>
      }
    </>
  )
}