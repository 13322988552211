import React, { useState, useEffect } from "react";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import Select from "react-select";
import { Get_DDL_M_ProjectType } from "../../../APIs/DDLAPI/DDLAPI";
import Header from "../../../Components/Header/Header";
import MobileHeader from "../../../Components/Header/MobileHeader";
import { Pegination } from "../../../Components/Pegination/Pegination";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { useAuthState } from "../../../Helper/Context/context";
import DeletePopUp from "../../../Helper/DeletePopUp";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Get_M_ProjectType_Select } from "../MasterApi/GETAPI";
import { Post_M_ProjectType_Delete } from "../MasterApi/POSTAPI";
import ProjectTypePopUp from "./ProjectTypePopUp";
import Footer from "../../../Components/Footer/Footer";
import ProjectTypeDDL from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import { useDispatch, useSelector } from "react-redux";
import ProjectTypeDDLData from "../../../CommonDDL/ProjectTypeDDL/ProjectTypeDDLData";
import { ProjectTypeDeleteAPI, ProjectTypeTableDataAPI } from "../../../Redux/MasterSlice/ProjectTypeSlice";

export default function ProjectType() {

  const userDetails = useAuthState();
  const { UserID, token } = userDetails
  const dispatch = useDispatch()

  // ----------pagination-------------
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)
  const [CurrentPage, setCurrentPage] = useState(0)
  const [IsSearch, setIsSearch] = useState(false)
  const [IsClear, setIsClear] = useState(false)
  const [IsPost, setIsPost] = useState(false)
  const [isopen, setIsOpen] = useState(true);

  const [PopUpField, setPopUpField] = React.useState({
    addPopUp: false,
    deletePopUp: false,
    popupFlag: '',
    popupBtn: "",
    apiFlag: "",
    rowData: ''
  })
  const [ProjectTypeDDL, setProjectTypeDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "---Select---"
  })

  const handleOnClearClick = () => {
    setCurrentPage(0)
    setProjectTypeDDL({
      ...ProjectTypeDDL,
      ID: 0,
      Label: "---Select---"
    })

    setIsPost(!IsPost)
  }

  const handleAddCloseClick = () => {
    setPopUpField({ addPopUp: false })
  }

  const handleDeleteCloseClick = () => {
    setPopUpField({ deletePopUp: false })
  }
  const addButtonClick = () => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Add", popupBtn: "Clear", apiFlag: 'Insert', rowData: '' })
  }

  const editButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: true, deletePopUp: false, popupFlag: "Update", popupBtn: "Cancel", apiFlag: 'Update', rowData: item })

  }

  const deleteButtonClick = (item) => {
    setPopUpField({ ...PopUpField, addPopUp: false, deletePopUp: true, popupBtn: "", apiFlag: 'Delete', rowData: item })
  }

  const handleDeleteData = () => {
    dispatch(ProjectTypeDeleteAPI({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }))
  }

  const { isDeleteLoading } = useSelector(state => state.ProjectTypeDeleteData)

  const handlePost = () => {
    setIsPost(!IsPost)
  }

  useEffect(() => {
    setCurrentPage(0)
    setFrom(1)
    setTo(10)

  }, [ ProjectTypeDDL.ID])

  useEffect(() => {
    const data = {
      M_ProjectTypeID: ProjectTypeDDL.ID,
      ProjectTypeName: '',
      UserID: UserID,
      token: token,
      From: From,
      To: To,
    }
    dispatch(ProjectTypeTableDataAPI({ data }))
  }, [IsPost, IsClear, ProjectTypeDDL.ID])

  const { tableData, isLoading } = useSelector(state => state.ProjectTypeTableData)

  return (
    <>
      {isLoading && <LoaderFile />}
      <div className="container-scroller">
        <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
        <Header setIsOpen={setIsOpen} isopen={isopen} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar active="projecttype" subMenu="subMaster" isopen={isopen} />
          {/* <!-- Main Container --> */}
          <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row page-heading">
                    <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                      <h4 className="font-weight-bold">Project Type</h4>
                    </div>
                    <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                      <button type="button" className="btn btn-primary btn-sm"
                        title="Add" onClick={() => addButtonClick()}>
                        <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                        Add
                      </button>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 shadow table-card">
                  <div className=" filter mb-0">
                    <div className="card-body">
                      <div className="form-row">

                        <div className="col-12 col-md-6 col-lg-3">
                          <ProjectTypeDDLData
                            ProjectTypeDDL={ProjectTypeDDL}
                            setProjectTypeDDL={setProjectTypeDDL}
                            IsPost={IsPost}
                          />
                        </div>

                        <div className="col-12 col-lg-2 pl-lg-3">
                          <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                            onClick={handleOnClearClick}
                          >
                            {/* <Search/> */}
                            <X size={18} style={{ marginRight: "3px" }} /> Clear
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="5%">Sr.No.</th>
                          <th>Project Type </th>
                          <th style={{ width: "8%",textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                            <tr key={i}>
                              <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                              <td>{item.projectTypeName ? item.projectTypeName : "-"}</td>
                              <td style={{ textAlign: "center" }}>
                                <span className="mx-2 cursor-pointer"
                                  title="Edit" onClick={() => editButtonClick(item)}>
                                  <Edit size={15} color="#007bff" />
                                </span>

                                <span className="mx-2 cursor-pointer"
                                  title="Delete" onClick={() => deleteButtonClick(item)}>
                                  <Trash2 size={15} color="red" />
                                </span>

                              </td>
                            </tr>
                          )) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                        }


                      </tbody>
                    </table>
                  </div>
                  {tableData && tableData.table && tableData.table.length > 0 &&
                    <Pegination
                      PerPageCount={PerPageCount}
                      TotalCount={tableData.table[0].totalCount}
                      setFrom={setFrom}
                      setTo={setTo}
                      setrowNo={setrowNo}
                      CurrentPage={CurrentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>


      {PopUpField.addPopUp ? <ProjectTypePopUp
        open={PopUpField.addPopUp}
        PopUpField={PopUpField}
        handleAddCloseClick={handleAddCloseClick}
        IsPost={IsPost}
        setIsPost={setIsPost}
        handlePost={handlePost}
      /> : <></>}
      {PopUpField.deletePopUp ?
        <DeletePopUp
          open={PopUpField.deletePopUp}
          handleDeleteData={handleDeleteData}
          handleOnCloseClick={handleDeleteCloseClick}
        /> : <></>}

    </>
  )
}