import React from "react";
import { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Department_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { ProjectRemarkPostAPI } from "../../../Redux/MasterSlice/ProjectRemarkSlice";

export default function ProjectRemarkPopUp({ flag, open, handleOnCloseClick, deptOfficeTypeIndicatorID, apiFlag, IsPost, setIsPost, editData, handleOnClearClick }) {
    // console.log(editData)
    const [designationDDL, setdesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });

    const [designationTable, setdesignationTable] = useState([]);

    const userDetails = useAuthState();
    const { DepartmentID, UserID, token } = userDetails
    const dispatch = useDispatch();


    const [passwordField, setpasswordField] = useState("password")
    const [projectRemark, setProjectRemark] = useState(apiFlag === 'Update' ? editData.projectRemark : '')

    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)



    const handleClearField = () => {
        setProjectRemark('')
    }
    const showPassword = () => {
        if (passwordField === "password") {
            setpasswordField("text")
        } else {
            setpasswordField("password")
        }
    }
    const [formErrors, setformErrors] = useState({
        empNameError: "",
        emailError: "",
        mobileError: "",

    })

    const checkEmailvalidation = (text) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, emailError: "" })
        } else {
            setformErrors({ ...formErrors, emailError: "Enter valid email address" })

        }
    }

    const checkMobileValidation = (text) => {
        // let regex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
        let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, mobileError: "" })
        } else {
            setformErrors({ ...formErrors, mobileError: "Enter valid contact number" })

        }
    }


    const Add = () => {
        const data = {
            M_ProjectRemarkID: apiFlag === 'Update' ? editData.projectRemarkID : 0,
            ProjectRemark: projectRemark,
            M_UsersID: UserID,
            From: From,
            token: token,
            To: To,
            Flag: flag,
            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost
        }
        dispatch(ProjectRemarkPostAPI({ data }))
    }


    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>

                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Project Remark </div>
                <div className="modal-body">
                    <div className="row details-row">

                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="contactNumber"> Project Remark <Star/></label>
                                <input
                                    className="form-control"
                                    id="projectRemark"
                                    type="text"
                                    name="projectRemark"
                                    value={projectRemark}
                                    onChange={(e) => {
                                        setProjectRemark(e.target.value)
                                    }}

                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>



                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={flag === 'Update' ? handleOnCloseClick : handleClearField}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />{flag === 'Update' ? 'Cancel' : 'Clear'}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        projectRemark === ''
                                    }
                                >{flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag === 'Update' ? 'Update' : "Add"}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}