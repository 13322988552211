import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";


export const CommonBudgetDetailsTableDataAPI = createAsyncThunk("CommonBudgetDetailsTableData", async ({ data }) => {
    const {
        M_UsersID,
        M_FinancialYearID,
        M_DepartmentID,
        M_MonthID,
        M_WardID,
        M_SubDepartmentID,
        Project_Tracker_Code,
        M_ProjectTypeID,
        M_ProjectID,
        M_WorkTypeID,
        Flag,
        token,
        FromTop,
        ToTop,
        // setGridData
        handleBudgetDetailsExport
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_Web_T_ProjectDetailsBudgetAllocation_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : 0}&M_SubDepartmentID=${M_SubDepartmentID ? M_SubDepartmentID : 0}&M_WardID=${M_WardID ? M_WardID : 0}&Project_Tracker_Code=${Project_Tracker_Code ? Project_Tracker_Code : ''}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : 0}&M_ProjectID=${M_ProjectID ? M_ProjectID : 0}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&Flag=${Flag}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                handleBudgetDetailsExport && handleBudgetDetailsExport()
                // setGridData && setGridData(result.data.table)
                return result.data.table
            } else {
                return result
            }
        })
})

const CommonBudgetDetailsTableDataSlice = createSlice({
    name: "CommonBudgetDetailsTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommonBudgetDetailsTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommonBudgetDetailsTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommonBudgetDetailsTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommonBudgetDetailsTableDataReducer = CommonBudgetDetailsTableDataSlice.reducer

// Export API

export const CommonBudgetDetailsTableExportDataAPI = createAsyncThunk("CommonBudgetDetailsTableExportData", async ({ data }) => {
    const {
        M_UsersID,
        M_FinancialYearID,
        M_DepartmentID,
        M_MonthID,
        M_WardID,
        M_SubDepartmentID,
        Project_Tracker_Code,
        M_ProjectTypeID,
        M_ProjectID,
        M_WorkTypeID,
        Flag,
        token,
        FromTop,
        ToTop,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_Web_T_ProjectDetailsBudgetAllocation_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : 0}&M_SubDepartmentID=${M_SubDepartmentID ? M_SubDepartmentID : 0}&M_WardID=${M_WardID ? M_WardID : 0}&Project_Tracker_Code=${Project_Tracker_Code ? Project_Tracker_Code : ''}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : 0}&M_ProjectID=${M_ProjectID ? M_ProjectID : 0}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&Flag=${Flag}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                return result.data.table
            } else {
                return result
            }
        })
})

const CommonBudgetDetailsTableExportDataSlice = createSlice({
    name: "CommonBudgetDetailsTableExportData",
    initialState: {
        isExportLoading: false,
        ExporttableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommonBudgetDetailsTableExportDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(CommonBudgetDetailsTableExportDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExporttableData = action.payload;
        });
        builder.addCase(CommonBudgetDetailsTableExportDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExporttableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommonBudgetDetailsTableExportDataReducer = CommonBudgetDetailsTableExportDataSlice.reducer
