import React from 'react'
import { Doughnut } from "react-chartjs-2"



export default function Task() {

	const data = {
		labels: [
			'Not Selected (2)',
			'In Progress (5)',
			'Completed (9)'
		],
		datasets: [{
			label: 'My First Dataset',
			data: [2, 5, 9],
			backgroundColor: [
				'rgb(217, 28, 34)',
				'rgb(250, 222, 37)',
				'rgb(81, 156, 6)',
			],
			hoverOffset: 4
		}]

	};
	const options = {
		legend: {
			display: true,
			position: "top",
			// fontSize:30,
			labels: {
				usePointStyle: true,
				pointStyle: "circle",
				boxWidth: 13,
				fontSize: 15,
				// padding:22
				// padding:{
				// 	top:20,
				// }
				
			}
			
			
		}

	};
	return (
		<div class="col-12 col-lg-4 pb-3">
			<div class="card h-100 m-0">
				<div class="card-header font-weight-bold">
					Task
				</div>
				<div class="card-body">
					<table cellpadding="0" cellspacing="0" border="0" class="table table-task">
						<tr>
							<th className='pt-2 pb-2'>Total task</th>
							<td className='pt-2 pb-2'>16 Tasks</td>
						</tr>
						<tr>
							<th className='pt-2 pb-2'>Completed task</th>
							<td className='pt-2 pb-2'>9 Task to be Completed</td>
						</tr>
					</table>
					<div class="row">
						<div class="col-12 d-flex text-center">
							<div>
								{/* <div id="do_legend"></div> */}
								{/* <Doughnut data={data} style={{maxHeight:"200px" , textAlign:"center"}} /> */}
								{/* Task.destroy(); */}
								<Doughnut options={options} data={data} style={{ maxHeight: "200px", textAlign: "center" }} />
							</div>
						</div>
					</div>



				</div>
			</div>
		</div>
	)
}
