import { CommonBudgetDetailsTableDataReducer, CommonBudgetDetailsTableExportDataReducer } from "./DashboardSlice/BudgetDetailsSlice/BudgetDetailsSlice";
import { DashboardCountDataReducer, WardWiseProjectCountDataReducer } from "./DashboardSlice/DashBoardCountSlice/DashBoardCountSlice";
import { CommonDelayedWorkTableDataReducer } from "./DashboardSlice/DelayedWorkSlice/DelayedWorkSlice";
import { CommonProjectDetailsExportTableDataReducer, CommonProjectDetailsTableDataReducer } from "./DashboardSlice/ProjectDetailsSlice/CommonProjectDetailsSlice";
import { CommonProjectOverViewMileStonePopupTableDataReducer, CommonProjectOverViewTableDataReducer } from "./DashboardSlice/ProjectOverViewSlice/ProjectOverViewSlice";
import { TotalDepartmentTableDataReducer } from "./DashboardSlice/TotalDepartmentsSlice/TotalDepartMentsSlice";
import { TotalProjectTableDataReducer } from "./DashboardSlice/TotalProjectSlice/TotalProjectSlice";
import { CommonWardWiseProjectDetailsCardTableDataReducer } from "./DashboardSlice/WardWiseProjectDetails/WardWiseProjectDetailsCardSlice";
import { AssignJEDDLDataReducer, BudgetHeadDDLDataReducer, BudgetMasterDDLDataReducer, BudgetMasterDDLReducer, BudgetTypeDDLDataReducer, ContractorNameDDLDataReducer, DashboardMonthDDLDataReducer, DepartmentNameReducer, DesignationNameReducer, ElectoralWardNumberDDLDataReducer, EmployeeNameReducer, EmployeeWardMappingReducer, FinancialDDLDataReducer, GenderDDLDataReducer, KeyProjectDDLDataReducer, ManPowerTypeDDLDataReducer, MunicipalCouncilorDDLDataReducer, NewProjectDetailsProjectNameDDLDataReducer, ProjectDDLDataReducer, ProjectNameMilestoneDDLDataReducer, ProjectPriorityDDLDataReducer, ProjectRemarkDDLDataReducer, ProjectTrackerCodeDDLDataReducer, ProjectTypeDDLDataReducer, RoleDDLDataReducer, StagesOfWorkDDLDataReducer, SubDepartmentDDLDataReducer, TenderAmountTypeDDLDataReducer, UserProgressStatusDDLDataReducer, UserStatusDDLDataReducer, WardNameDDLDataReducer, WorkAndWorkTypeDDLDataReducer, WorkDDLDataReducer, WorkTypeDDLDataReducer, ZoneDDLDataReducer } from "./DDLSlice";
import { BudgetMasterDeleteReducer, BudgetMasterPostDataReducer, BudgetMasterTableDataReducer } from "./MasterSlice/BudgetMasterSlice";
// import { BudgetMasterDDLDataReducer, BudgetMasterDDLReducer, BudgetTypeDDLDataReducer, ContractorNameDDLDataReducer, DepartmentNameReducer, DesignationNameReducer, EmployeeNameReducer, GenderDDLDataReducer, ManPowerTypeDDLDataReducer, ProjectDDLDataReducer, ProjectTypeDDLDataReducer, WardNameDDLDataReducer, WorkDDLDataReducer, WorkTypeDDLDataReducer, ZoneDDLDataReducer } from "./DDLSlice";
// import { BudgetMasterDDLDataReducer, BudgetMasterDDLReducer, BudgetTypeDDLDataReducer, ContractorNameDDLDataReducer, DepartmentNameReducer, DesignationNameReducer, ElectoralWardNumberDDLDataReducer, EmployeeNameReducer, GenderDDLDataReducer, ManPowerTypeDDLDataReducer, MunicipalCouncilorDDLDataReducer, ProjectDDLDataReducer, ProjectTypeDDLDataReducer, WardNameDDLDataReducer, WorkDDLDataReducer, WorkTypeDDLDataReducer, ZoneDDLDataReducer } from "./DDLSlice";
// import { BudgetMasterTableDataReducer } from "./MasterSlice/BudgetMasterSlice";
import { ContractorMasterPostDataReducer, ContractorMasterTableDataReducer } from "./MasterSlice/ContractorMasterSlice";
import { DepartmentDeleteReducer, DepartmentPostDataReducer, DepartmentTableDataReducer } from "./MasterSlice/DepartmentSlice";
import { DesignationPostDataReducer, DesignationTableDataReducer } from "./MasterSlice/DesignationSlice";
import { ElectoralWardNumberPostDataReducer, ElectoralWardNumberTableDataReducer } from "./MasterSlice/ElectoralWardNumberSlice";
import { EmployeeDeleteReducer, EmployeePostDataReducer, EmployeeTableDataReducer } from "./MasterSlice/EmployeeSlice";
import { FinancialWorkProgressTableDataReducer } from "./MasterSlice/FinancialWorkProgressSlice";
import { MainWorkTypeDeleteReducer, MainWorkTypePostDataReducer, MainWorkTypeTableDataReducer } from "./MasterSlice/MainWorkTypeSlice";
import { ManPowerTypePostDataReducer, ManPowerTypeTableDataReducer } from "./MasterSlice/ManPowerTypeSlice";
import { MunicipalCouncilorDeleteReducer, MunicipalCouncilorPostDataReducer, MunicipalCouncilorTableDataReducer } from "./MasterSlice/MunicipleCouncilorSlice";
import { ProjectRemarkPostDataReducer, ProjectRemarkTableDataReducer } from "./MasterSlice/ProjectRemarkSlice";
import { ProjectDeleteReducer, ProjectPostDataReducer, ProjectTableDataReducer } from "./MasterSlice/ProjectSlice";
import { ProjectTypeDeleteReducer, ProjectTypePostDataReducer, ProjectTypeTableDataReducer } from "./MasterSlice/ProjectTypeSlice";
import { SubDepartmentTableDataReducer } from "./MasterSlice/SubDepartmentSlice";
import { SubWorkTypeDeleteReducer, SubWorkTypePostDataReducer, SubWorkTypeTableDataReducer } from "./MasterSlice/SubWorkTypeSlice";
// import { WardMasterTableDataReducer } from "./MasterSlice/WardMasterSlice";
import { WardMasterDeleteReducer, WardMasterPostDataReducer, WardMasterTableDataReducer } from "./MasterSlice/WardMasterSlice";
import { ZoneMasterPostDataReducer, ZoneMasterTableDataReducer } from "./MasterSlice/ZoneMasterSlice";
import { CompletedMileStoneViewPopupTableDataReducer, PhysicalWorkProgressTableDataReducer } from "./ProjectCreationAllocationSlice/PhysicalWorkProgressSlice";
import { ProjectDetailsDeleteReducer, ProjectDetailsExportTableDataReducer, ProjectDetailsPostDataReducer, ProjectDetailsTableDataReducer } from "./ProjectCreationAllocationSlice/ProjectDetailsSlice";
import { ProjectSanctionDetailsDeleteReducer, ProjectSanctionDetailsExportTableDataReducer, ProjectSanctionDetailsPostDataReducer, ProjectSanctionDetailsTableDataReducer } from "./ProjectCreationAllocationSlice/ProjectSanctionDetailsSlice";
import { ProjectWiseMilestoneExportTableDataReducer, ProjectWiseMilestonePostDataReducer, ProjectWiseMilestoneTableDataReducer } from "./ProjectCreationAllocationSlice/ProjectWiseMilestoneSlice";
import { Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectReducer, Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectReducer } from "./ReportSlice/ReportSlice";
import { UpdatePostDataReducer, UpdateTableDataReducer } from "./UpdateSlice/UpdateSlice";
import { DefineRoleDeleteReducer, DefineRolePostDataReducer, DefineRoleTableDataReducer } from "./UserMasterSlice/DefineRoleSlice";
import { RoleWiseFormPostDataReducer, RoleWiseFormTableDataReducer } from "./UserMasterSlice/RoleWiseFormSlice";
import { ExportUserCreationTableDataReducer, UserCreationDeleteReducer, UserCreationPostDataReducer, UserCreationTableDataReducer } from "./UserMasterSlice/UserCreationSlice";
import { UserWiseWardMappingPostDataReducer, UserWiseWardMappingTableDataReducer } from "./UserMasterSlice/UserWiseWardMappingSlice";

export const rootReducer = {
    DepartmentNameData: DepartmentNameReducer,
    DesignationNameData: DesignationNameReducer,
    EmployeeNameData: EmployeeNameReducer,
    SubDepartmentTableData: SubDepartmentTableDataReducer,
    DesignationPostData: DesignationPostDataReducer,
    DesignationTableData: DesignationTableDataReducer,
    EmployeeTableData: EmployeeTableDataReducer,
    EmployeePostData: EmployeePostDataReducer,
    ManPowerTypePostData: ManPowerTypePostDataReducer,
    ManPowerTypeTableData: ManPowerTypeTableDataReducer,
    BudgetMasterTableData: BudgetMasterTableDataReducer,
    BudgetTypeDDLData: BudgetTypeDDLDataReducer,
    ProjectRemarkTableData: ProjectRemarkTableDataReducer,
    ProjectRemarkPostData: ProjectRemarkPostDataReducer,
    ManPowerTypeDDLData: ManPowerTypeDDLDataReducer,
    DepartmentTableData: DepartmentTableDataReducer,
    DepartmentPostData: DepartmentPostDataReducer,

    WardMasterTableData: WardMasterTableDataReducer,
    GenderDDLData: GenderDDLDataReducer,
    ContractorNameDDLData: ContractorNameDDLDataReducer,
    ContractorMasterTableData: ContractorMasterTableDataReducer,
    ContractorMasterPostData: ContractorMasterPostDataReducer,
    ZoneDDLData: ZoneDDLDataReducer,
    WardNameDDLData: WardNameDDLDataReducer,
    MunicipalCouncilorTableData: MunicipalCouncilorTableDataReducer,
    ZoneMasterTableData: ZoneMasterTableDataReducer,
    ZoneMasterPostData: ZoneMasterPostDataReducer,
    ElectoralWardNumberTableData: ElectoralWardNumberTableDataReducer,
    ElectoralWardNumberPostData: ElectoralWardNumberPostDataReducer,
    // ElectoralWardNumberPostData: EmployeeDeleteReducer,
    WardMasterPostData: WardMasterPostDataReducer,
    WardMasterDelete: WardMasterDeleteReducer,
    ElectoralWardNumberDDLData: ElectoralWardNumberDDLDataReducer,
    MunicipalCouncilorDDLData: MunicipalCouncilorDDLDataReducer,
    MunicipalCouncilorPostData: MunicipalCouncilorPostDataReducer,
    MunicipalCouncilorDelete: MunicipalCouncilorDeleteReducer,
    BudgetMasterPostData: BudgetMasterPostDataReducer,
    BudgetMasterDelete: BudgetMasterDeleteReducer,
    ProjectRemarkDDLData: ProjectRemarkDDLDataReducer,
    DepartmentDelete: DepartmentDeleteReducer,
    ProjectPriorityDDLData: ProjectPriorityDDLDataReducer,
    KeyProjectDDLData: KeyProjectDDLDataReducer,
    ProjectDetailsTableData: ProjectDetailsTableDataReducer,
    SubDepartmentDDLData: SubDepartmentDDLDataReducer,
    ProjectDetailsPostData: ProjectDetailsPostDataReducer,
    ProjectDetailsDelete: ProjectDetailsDeleteReducer,
    WorkDDLData: WorkDDLDataReducer,
    WorkTypeDDLData: WorkTypeDDLDataReducer,
    MainWorkTypeTableData: MainWorkTypeTableDataReducer,
    MainWorkTypePostData: MainWorkTypePostDataReducer,
    MainWorkTypeDeleteData: MainWorkTypeDeleteReducer,
    SubWorkTypeTableData: SubWorkTypeTableDataReducer,
    SubWorkTypePostData: SubWorkTypePostDataReducer,
    SubWorkTypeDeleteData: SubWorkTypeDeleteReducer,
    ProjectTypeDDLData: ProjectTypeDDLDataReducer,
    ProjectTypeTableData: ProjectTypeTableDataReducer,
    ProjectTypePostData: ProjectTypePostDataReducer,
    ProjectTypeDeleteData: ProjectTypeDeleteReducer,
    ProjectDDLData: ProjectDDLDataReducer,
    ProjectTableData: ProjectTableDataReducer,
    ProjectPostData: ProjectPostDataReducer,
    ProjectDeleteData: ProjectDeleteReducer,
    RoleDDLData: RoleDDLDataReducer,
    UserStatusDDLData: UserStatusDDLDataReducer,
    UserCreationTableData: UserCreationTableDataReducer,
    UserCreationPostData: UserCreationPostDataReducer,
    UserCreationDeleteData: UserCreationDeleteReducer,
    RoleWiseFormTableData: RoleWiseFormTableDataReducer,
    RoleWiseFormPostData: RoleWiseFormPostDataReducer,
    ProjectSanctionDetailsTableData: ProjectSanctionDetailsTableDataReducer,
    StagesOfWorkDDLData: StagesOfWorkDDLDataReducer,
    TenderAmountTypeDDLData: TenderAmountTypeDDLDataReducer,
    ProjectSanctionDetailsPostData: ProjectSanctionDetailsPostDataReducer,
    WorkAndWorkTypeDDLData: WorkAndWorkTypeDDLDataReducer,
    ProjectSanctionDetailsDeleteData: ProjectSanctionDetailsDeleteReducer,
    DefineRoleTableData: DefineRoleTableDataReducer,
    DefineRolePostData: DefineRolePostDataReducer,
    DefineRoleDeleteData: DefineRoleDeleteReducer,
    ProjectWiseMilestoneTableData: ProjectWiseMilestoneTableDataReducer,
    ProjectWiseMilestonePostData: ProjectWiseMilestonePostDataReducer,
    BudgetHeadDDLData: BudgetHeadDDLDataReducer,
    AssignJEDDLData: AssignJEDDLDataReducer,
    ProjectNameMilestoneDDLData: ProjectNameMilestoneDDLDataReducer,
    UserWiseWardMappingTableData: UserWiseWardMappingTableDataReducer,
    UserWiseWardMappingPostData: UserWiseWardMappingPostDataReducer,
    EmployeeWardMappingData: EmployeeWardMappingReducer,
    PhysicalWorkProgressTableData: PhysicalWorkProgressTableDataReducer,
    UpdateTableData: UpdateTableDataReducer,
    UpdatePostData: UpdatePostDataReducer,

    FinancialDDLData: FinancialDDLDataReducer,
    ExportUserCreationTableData: ExportUserCreationTableDataReducer,

    TotalDepartmentTableData: TotalDepartmentTableDataReducer,
    TotalProjectTableData: TotalProjectTableDataReducer,


    CommonProjectDetailsTableData: CommonProjectDetailsTableDataReducer,
    CommonProjectDetailsExportTableData: CommonProjectDetailsExportTableDataReducer,
    CommonProjectOverViewTableData: CommonProjectOverViewTableDataReducer,
    CommonProjectOverViewMileStonePopupTableData: CommonProjectOverViewMileStonePopupTableDataReducer,
    DashboardCountData: DashboardCountDataReducer,
    DashboardMonthDDLData: DashboardMonthDDLDataReducer,


    CommonWardWiseProjectDetailsCardTableData: CommonWardWiseProjectDetailsCardTableDataReducer,

    ProjectDetailsExportTableData: ProjectDetailsExportTableDataReducer,
    ProjectWiseMilestoneExportTableData: ProjectWiseMilestoneExportTableDataReducer,
    ProjectSanctionDetailsExportTableData: ProjectSanctionDetailsExportTableDataReducer,

    CommonDelayedWorkTableData: CommonDelayedWorkTableDataReducer,
    CommonBudgetDetailsTableData: CommonBudgetDetailsTableDataReducer,

    WardWiseProjectCountData: WardWiseProjectCountDataReducer,

    ProjectTrackerCodeDDLData: ProjectTrackerCodeDDLDataReducer,
    UserProgressStatusDDLData: UserProgressStatusDDLDataReducer,
    Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectData: Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectReducer,

    FinancialWorkProgressTableData: FinancialWorkProgressTableDataReducer,

    Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectData: Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectReducer,

    CommonBudgetDetailsTableExportData: CommonBudgetDetailsTableExportDataReducer,

    CompletedMileStoneViewPopupTableData: CompletedMileStoneViewPopupTableDataReducer,

    NewProjectDetailsProjectNameDDLData:NewProjectDetailsProjectNameDDLDataReducer,

}