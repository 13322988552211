import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const ProjectDetailsTableDataAPI = createAsyncThunk("ProjectDetailsTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        T_ProjectDetailsID,
        M_DepartmentID,
        M_SubDepartmentID,
        M_ProjectTypeID,
        M_ProjectID,
        M_WorkTypeID,
        M_ProjectPriorityID,
        M_WardID,
        M_WardWiseNumberID,
        UserID,
        From,
        token,
        To,
        handleGetExport
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_T_ProjectDetails_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&T_ProjectDetailsID=${T_ProjectDetailsID ? T_ProjectDetailsID : '0'}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_SubDepartmentID=${M_SubDepartmentID ? M_SubDepartmentID : '0'}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : '0'}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_ProjectPriorityID=${M_ProjectPriorityID ? M_ProjectPriorityID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_WardWiseNumberID=${M_WardWiseNumberID ? M_WardWiseNumberID : '0'}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                handleGetExport && handleGetExport()
                return result.data.table
            } else {
                return result
            }
        })
})

const ProjectDetailsTableDataSlice = createSlice({
    name: "ProjectDetailsTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectDetailsTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectDetailsTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectDetailsTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectDetailsTableDataReducer = ProjectDetailsTableDataSlice.reducer

// Export Api

export const ProjectDetailsExportTableDataAPI = createAsyncThunk("ProjectDetailsExportTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        T_ProjectDetailsID,
        M_DepartmentID,
        M_SubDepartmentID,
        M_ProjectTypeID,
        M_ProjectID,
        M_WorkTypeID,
        M_ProjectPriorityID,
        M_WardID,
        M_WardWiseNumberID,
        UserID,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_T_ProjectDetails_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&T_ProjectDetailsID=${T_ProjectDetailsID ? T_ProjectDetailsID : '0'}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_SubDepartmentID=${M_SubDepartmentID ? M_SubDepartmentID : '0'}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : '0'}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_ProjectPriorityID=${M_ProjectPriorityID ? M_ProjectPriorityID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_WardWiseNumberID=${M_WardWiseNumberID ? M_WardWiseNumberID : '0'}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ProjectDetailsExportTableDataSlice = createSlice({
    name: "ProjectDetailsTableData",
    initialState: {
        isExportLoading: false,
        ExporttableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectDetailsExportTableDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(ProjectDetailsExportTableDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExporttableData = action.payload;
        });
        builder.addCase(ProjectDetailsExportTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExporttableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectDetailsExportTableDataReducer = ProjectDetailsExportTableDataSlice.reducer


// InsertUpdate Table Data

export const ProjectDetailsPostAPI = createAsyncThunk("ProjectDetailsPostData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        handleOnCloseClick,
        setIsPost,
        IsPost,
        handleOnClearClick,
        T_ProjectDetailsID,
        M_DepartmentID,
        M_SubDepartment,
        M_ProjectTypeID,
        M_ProjectID,
        M_WorkID,
        M_ProjectPriorityID,
        M_WardID,
        M_WardWiseNumberID,
        M_WardWiseNumber,
        LFMS_File_No,
        Project_ERP_Code,
        Key_Project,
        UserID,
        token,
        AssignJE_M_UsersID,
        Flag, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_FinancialYearID", M_FinancialYearID);
    formdata.append("M_MonthID", M_MonthID);
    formdata.append("T_ProjectDetailsID", T_ProjectDetailsID);
    formdata.append("M_DepartmentID", M_DepartmentID);
    formdata.append("M_SubDepartment", M_SubDepartment);
    formdata.append("M_ProjectTypeID", M_ProjectTypeID);
    formdata.append("M_ProjectID", M_ProjectID);
    formdata.append("M_WorkID", M_WorkID);
    formdata.append("M_ProjectPriorityID", M_ProjectPriorityID);
    formdata.append("M_WardID", M_WardID);
    formdata.append("M_WardWiseNumberID", M_WardWiseNumberID);
    formdata.append("M_WardWiseNumber", M_WardWiseNumber);
    formdata.append("LFMS_File_No", LFMS_File_No);
    formdata.append("Project_ERP_Code", Project_ERP_Code);
    formdata.append("Key_Project", Key_Project);
    formdata.append("M_UsersID", UserID);
    formdata.append("Flag", Flag);
    formdata.append("AssignJE_M_UsersID", AssignJE_M_UsersID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectDetails_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                toastSuccesss(result.message)

            }
            else {
                toastErrorr(result.message)
            }
            setIsPost(!IsPost)
            handleOnCloseClick()
            handleOnClearClick()
        })
        .catch(err => {
            console.log(err)
        })
})

const ProjectDetailsPostSlice = createSlice({
    name: "ProjectDetailsPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectDetailsPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectDetailsPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(ProjectDetailsPostAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectDetailsPostDataReducer = ProjectDetailsPostSlice.reducer



// Delete Table Data

export const ProjectDetailsDeleteAPI = createAsyncThunk("ProjectDetailsDelete", async ({ data }) => {
    // const { rowData, apiFlag } = PopUpField
    const {
        handlePost,
        token,
        UserID,
        handleDeleteCloseClick,
        T_ProjectDetailsID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_ProjectDetailsID", T_ProjectDetailsID);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectDetails_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            handlePost()
            handleDeleteCloseClick()
            return result

        })
})

const ProjectDetailsDeleteSlice = createSlice({
    name: "ProjectDetailsDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectDetailsDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectDetailsDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(ProjectDetailsDeleteAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectDetailsDeleteReducer = ProjectDetailsDeleteSlice.reducer