import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";


export const TotalDepartmentTableDataAPI = createAsyncThunk("TotalDepartmentTableData", async ({ data }) => {
    const {
        M_UsersID,
        M_RoleID,
        M_DepartmentID,
        DepartmentName,
        Dept_Code,
        M_DeptOfficeTypeIndicatorID,
        M_DesignationID,
        UserStatusIndicatorID,
        Login_M_UsersID,
        token,
        FromTop,
        ToTop,
        handleExportData
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Department_Select?M_DepartmentID=${M_DepartmentID}&DepartmentName=${DepartmentName}&Dept_Code=${Dept_Code}&M_DeptOfficeTypeIndicatorID=${M_DeptOfficeTypeIndicatorID}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                // handleExportData()
                return result.data.table
            } else {
                return result
            }
        })
})

const TotalDepartmentTableDataSlice = createSlice({
    name: "TotalDepartmentTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(TotalDepartmentTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(TotalDepartmentTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(TotalDepartmentTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const TotalDepartmentTableDataReducer = TotalDepartmentTableDataSlice.reducer


