import { toast } from "react-toastify";

export function toastSuccesss (text){
    // console.log(text);
    toast.success(text, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        // backgroundColor: "blue",
        zIndex : 1000,
        });
}
export function toastErrorr (text){
    toast.error(text, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        zIndex : 1000,
        });
}

export function toastInfo(text){
    toast.info(text, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        zIndex : 1000,
        }); 
}

export function toastWarning(text){
    toast.warning(text, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        zIndex : 1000,
        }); 
}



// import { message } from 'antd';

// export function successMessage (text){ 
//     message.open({
//         type: 'success',
//         content: {text},
//       });
// }