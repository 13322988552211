import React, { useState } from 'react'
import { Eye, PieChart, Search, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Select from "react-select"
import Header from '../../../../Components/Header/Header';
import MobileHeader from '../../../../Components/Header/MobileHeader';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import ViewGalleryPopup from '../../PanCityProjectTable/ViewGalleryPopup';
import UtilizationDetailsPopUp from './UtilizationDetailsPopUp';



const PendingUtilizationElectricalDepartment = () => {

    const [isopen, setIsOpen] = useState(true);

    const [showPopup, setshowPopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [showCreatePopup, setshowCreatePopup] = useState(false)


    const handleAddClick = () => {
        setshowPopup(true)
        // setflag("Add")
    }
    const handleOnCloseClick = () => {
        setshowPopup(false)
        setshowDeletePopup(false)
    }

    const navigate = useNavigate()
    const handleOnClick = (val) => {
        navigate("/viewprogress")
        console.log(val);
    }
    const options = [
        { value: 'New', label: 'New' },
        { value: 'Ongoing', label: 'Ongoing' },
        { value: 'Completed', label: 'Completed' }
    ]

    const options1 = [
        { value: 'Public Works Department', label: 'Public Works Department' },
        { value: 'WATER Work Department', label: 'WATER Work Department' },
        { value: 'Health Department', label: 'Health Department' },
        { value: 'Solid Waste Management', label: 'Solid Waste Management' },
        { value: 'Electrical Department', label: 'Electrical Department' },
        { value: 'Sewerage Department', label: 'Sewerage Department' }
    ]
    const TableData = [{
        SrNo: "1",
        NameofProject: "-",
        UtilizationNo: "-",
        BudgetAllocated: "-",
        BudgetConsumed: "-",
        BudgetBalance: "-",
        Action: "-"
    }]
    return (
        <div className="wrapper">
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar isopen={isopen} />
                    <div
                        className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Pending Utilization - Since 15 Days - Electrical Department</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="Budgettype">Status</label>

                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options}

                                                        />



                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="form-group">
                                                        <label className="d-block" for="WardNo">Department</label>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options1}

                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-4 text-center">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>
                                                    <button type="button" className="btn btn-primary text-white mr-2 mt-4 waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <Search size={18} style={{ marginRight: "3px" }} />  Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table
                                            cellpadding="0"
                                            cellspacing="0"
                                            border="0"
                                            className="table table-bordered"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name of Project</th>
                                                    <th>Utilization No</th>
                                                    <th>Budget Allocated</th>
                                                    <th>Budget Consumed</th>
                                                    <th>Budget Balance</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {TableData && TableData.length > 0 ? TableData.map((item, index) => (

                                                    <tr key={index}>
                                                        <td style={{ textAlign: 'center' }}>{item.SrNo}</td>
                                                        <td>{item.NameofProject ? "-" : "-"}</td>
                                                        <td>{item.UtilizationNo ? "-" : "-"}</td>
                                                        <td>{item.BudgetAllocated ? "-" : "-"}</td>
                                                        <td>{item.BudgetConsumed ? "-" : "-"}</td>
                                                        <td>{item.BudgetBalance ? "-" : "-"}</td>

                                                        <td><button
                                                            href="#details"
                                                            className="mx-2 cursor-pointer"
                                                            data-toggle="modal"
                                                            data-target="#details"
                                                            data-tooltip="tooltip"
                                                            title="View Details"
                                                            onClick={handleAddClick}><i><Eye color='#4788f4' /></i></button>
                                                        <button
                                                            href="progress.html"
                                                            className="mx-2 cursor-pointer"
                                                            data-tooltip="tooltip"
                                                            title="View Progress"
                                                            onClick={handleOnClick}
                                                        ><i><PieChart color='#b67cd3' /></i></button></td>


                                                    </tr>


                                                )) : <tr >  No Record...</tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div
                                                className="dataTables_info"
                                                id="example_info"
                                                role="status"
                                                aria-live="polite"
                                            >
                                                Showing 1 to 10 of 25 entries
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div
                                                className="dataTables_paginate paging_simple_numbers"
                                                id="example_paginate"
                                            >
                                                <ul className="pagination">
                                                    <li
                                                        className="paginate_button page-item previous disabled"
                                                        id="example_previous"
                                                    >
                                                        <a
                                                            href="#"
                                                            aria-controls="example"
                                                            data-dt-idx="0"
                                                            tabindex="0"
                                                            className="page-link"
                                                        >Previous</a
                                                        >
                                                    </li>
                                                    <li className="paginate_button page-item active">
                                                        <a
                                                            href="#"
                                                            aria-controls="example"
                                                            data-dt-idx="1"
                                                            tabindex="0"
                                                            className="page-link"
                                                        >1</a
                                                        >
                                                    </li>
                                                    <li className="paginate_button page-item">
                                                        <a
                                                            href="#"
                                                            aria-controls="example"
                                                            data-dt-idx="2"
                                                            tabindex="0"
                                                            className="page-link"
                                                        >2</a
                                                        >
                                                    </li>
                                                    <li className="paginate_button page-item">
                                                        <a
                                                            href="#"
                                                            aria-controls="example"
                                                            data-dt-idx="3"
                                                            tabindex="0"
                                                            className="page-link"
                                                        >3</a
                                                        >
                                                    </li>
                                                    <li
                                                        className="paginate_button page-item next"
                                                        id="example_next"
                                                    >
                                                        <a
                                                            href="#"
                                                            aria-controls="example"
                                                            data-dt-idx="4"
                                                            tabindex="0"
                                                            className="page-link"
                                                        >Next</a
                                                        >
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup ? <UtilizationDetailsPopUp open={showPopup} handleOnCloseClick={handleOnCloseClick} /> : <></>}
            {showCreatePopup ? <ViewGalleryPopup open={showCreatePopup} handleOnCloseClick={handleOnCloseClick} /> : <></>}
        </div>
    )
}

export default PendingUtilizationElectricalDepartment
