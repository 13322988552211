import React from "react";
import { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { useAuthState } from "../../../Helper/Context/context";
import { Post_M_Department_InsertUpdate } from "../MasterApi/POSTAPI";
import { Star } from "../../../Components/CommoComponent/Star";
import Select from "react-select";
import WardNameDDL from "../../../CommonDDL/WardNameDDL/WardNameDDL";
import { WardNameDDLAPI } from "../../../Redux/DDLSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ElectoralWardNumberPostAPI } from "../../../Redux/MasterSlice/ElectoralWardNumberSlice";

export default function ElectrolWardNumberPopUp({ flag, open, handleOnCloseClick, deptOfficeTypeIndicatorID, apiFlag, IsPost, setIsPost, editData, handleOnClearClick }) {
    console.log(editData)
    const [designationDDL, setdesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---",
    });


    const [electoralWardNumber, setElectoralWardName] = useState(apiFlag === 'Update' ? editData.wardNumber : '')

    const userDetails = useAuthState();
    const { DepartmentID, UserID, token } = userDetails

    const dispatch = useDispatch();


    const [wardNameDDL, setWardNameDDL] = useState({
        DDL: [],
        ID: apiFlag === 'Update' ? editData.m_WardID : 0,
        Label: apiFlag === 'Update' ? editData.wardName : "Select...",
    });

    useEffect(() => {
        const data = { UserID: '0', token }
        dispatch(WardNameDDLAPI({ data }))
    }, [])

    const { WardNameDDLData } = useSelector(state => state.WardNameDDLData)


    const Add = () => {
        const data = {
            handleOnCloseClick: handleOnCloseClick,
            setIsPost: setIsPost,
            IsPost: IsPost,
            handleOnClearClick: handleOnClearClick,
            token: token,
            Flag: apiFlag,
            M_WardWiseNumberID: apiFlag === 'Update' ? editData.m_WardWiseNumberID : 0,
            M_WardID: wardNameDDL.ID,
            WardNumber: electoralWardNumber,
            UserID: UserID
        }
        dispatch(ElectoralWardNumberPostAPI({ data }))
    }

    const handleClearField = () => {
        setWardNameDDL({
            ...wardNameDDL,
            ID:0,
            Label:'Select...'
        })
        setElectoralWardName('')
    }


    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>

                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Electoral Ward Number  </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-md-12 col-lg-6">
                            <WardNameDDL
                                wardNameDDL={wardNameDDL}
                                setWardNameDDL={setWardNameDDL}
                                DDLData={WardNameDDLData}
                                apiFlag={apiFlag}
                                editData={editData}
                                FlagName=''
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="electoralWardNumber"> Electoral Ward Number  <Star/></label>
                                <input
                                    className="form-control"
                                    id="electoralWardNumber"
                                    type="text"
                                    name="electoralWardNumber"
                                    value={electoralWardNumber}
                                    onChange={(e) => { setElectoralWardName(e.target.value) }}
                                />
                                {/* <span className="text-danger error-danger">{formErrors.mobileError}</span> */}
                            </div>
                        </div>



                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={apiFlag === 'Update' ? handleOnCloseClick : handleClearField}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />{apiFlag === 'Update' ? 'Cancel' : 'Clear'}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => Add()}
                                    disabled={
                                        wardNameDDL.ID === 0 ||
                                        electoralWardNumber === ''
                                    }
                                >{flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>

    )
}