import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const RoleWiseFormTableDataAPI = createAsyncThunk("RoleWiseFormTableData", async ({ data }) => {
    const {
        M_WorkID,
        M_WorkTypeID,
        M_RoleID,
        UserID,
        From,
        token,
        settableData,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_RoleWise_FormManager_Select?M_RoleID=${M_RoleID ? M_RoleID : '0'}&UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                settableData && settableData(result.data)
                return result.data
            } else {
                return result
            }
        })
})

const RoleWiseFormTableDataSlice = createSlice({
    name: "RoleWiseFormTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(RoleWiseFormTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(RoleWiseFormTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(RoleWiseFormTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const RoleWiseFormTableDataReducer = RoleWiseFormTableDataSlice.reducer

// InsertUpdate Table Data

export const RoleWiseFormPostAPI = createAsyncThunk("RoleWiseFormPostData", async ({ data }) => {
    const {
        M_RoleID,
        M_FormManagerID_Data,
        Flag,
        UserID,
        handlePost,
        token

    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_RoleID", M_RoleID);
    formdata.append("M_FormManagerID_Data", M_FormManagerID_Data);
    formdata.append("UserID", UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_RoleWise_FormManager_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                // handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const RoleWiseFormNameSlice = createSlice({
    name: "RoleWiseFormPostData",
    initialState: {
        isLoading: false,
        RoleWiseFormData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(RoleWiseFormPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(RoleWiseFormPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.RoleWiseFormData = action.payload;
        });
        builder.addCase(RoleWiseFormPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.RoleWiseFormData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const RoleWiseFormPostDataReducer = RoleWiseFormNameSlice.reducer



// Delete Table Data

export const UserCreationDeleteAPI = createAsyncThunk("UserCreationDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_UsersID", rowData?.id);
    formdata.append("Login_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Users_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const UserCreationDeleteSlice = createSlice({
    name: "UserCreationDelete",
    initialState: {
        isDeleteLoading: false,
        UserCreationableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(UserCreationDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.UserCreationableData = action.payload;

        });
        builder.addCase(UserCreationDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.UserCreationableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const UserCreationDeleteReducer = UserCreationDeleteSlice.reducer