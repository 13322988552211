import React, { useState, useEffect } from "react";
import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import BudgetPopUp from "./BudgetPopUp";
import Select from "react-select"
import { useNavigate } from "react-router-dom";
import MobileHeader from "../../../Components/Header/MobileHeader";
import DeletePopUp from "../../../Helper/DeletePopUp";
import { Edit, Edit2, Search, Trash, Trash2, X } from "react-feather";
import { Get_DDL_M_BudgetType } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Get_M_Budget_Select } from "../MasterApi/GETAPI";
import LoaderFile from "../../../Helper/Loader/LoaderFile";
import { Pegination } from "../../../Components/Pegination/Pegination";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseUrl";
import Footer from "../../../Components/Footer/Footer";
import BudgetTypeDDL from "../../../CommonDDL/BudgetType/BudgetTypeDDL";
import { useDispatch, useSelector } from "react-redux";
import { BudgetMasterDeleteAPI, BudgetMasterTableDataAPI } from "../../../Redux/MasterSlice/BudgetMasterSlice";
import { BudgetMasterDDLAPI, BudgetTypeDDLAPI } from "../../../Redux/DDLSlice";

export default function Budget() {

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch();

    const navigate = useNavigate()
    const onNavigate = () => {
        navigate("/totalbudget")
    }

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true);


    // react pagination states 
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)

    // budget master api state
    const [budgetMasterTable, setbudgetMasterTable] = useState([])
    const [apiFlag, setapiFlag] = useState()
    const [IsPost, setIsPost] = useState(false)
    const [editData, seteditData] = useState()
    const [deleteData, setDeleteData] = useState()
    const [loading, setloading] = useState()
    const [CurrentPage, setCurrentPage] = useState(0)


    const [budgetTypeDDL, setBudgetTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })


    const handleOnCloseClick = () => {
        setshowCreatePopup(false)
        setshowDeletePopup(false)
    }
    const handleAddNewClick = () => {
        setshowCreatePopup(true)
        setflag("Add")
        setapiFlag("Insert")
    }
    const handleOnEditClick = (item) => {
        setshowCreatePopup(true)
        setflag("Update")
        setapiFlag("Update")
        seteditData(item)
    }
    const handleOnDeleteClick = (item) => {
        setshowDeletePopup(true)
        setflag("Delete")
        setDeleteData(item)
    }




    const handleOnSearchClick = () => {
        setCurrentPage(0)
        setIsPost(!IsPost)
    }

    const handleOnClearClick = () => {
        setCurrentPage(0)
        setBudgetTypeDDL({
            ...budgetTypeDDL,
            ID: 0,
            Label: "Select..."
        })
    }

    const handlePost = () => {
        setIsPost(!IsPost)
    }

    const handleDeleteData = () => {
        const data = {
            handlePost: handlePost,
            token: token,
            UserID: UserID,
            handleDeleteCloseClick: handleOnCloseClick,
            M_BudgetID: deleteData
        }
        dispatch(BudgetMasterDeleteAPI({ data }))
    }


    useEffect(() => {
        const data = { UserID, token }
        dispatch(BudgetTypeDDLAPI({ data }))
    }, [])

    const { BudgetTypeDDLData } = useSelector(state => state.BudgetTypeDDLData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [budgetTypeDDL.ID])

    useEffect(() => {
        const data = {
            BudgetTypeID: budgetTypeDDL.ID,
            UserID: UserID,
            From: From,
            token: token,
            To: To,
        }
        dispatch(BudgetMasterTableDataAPI({ data }))
    }, [From, IsPost, budgetTypeDDL.ID])


    const { tableData, isLoading } = useSelector(state => state.BudgetMasterTableData)


    return (
        <>
            {isLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="budget" subMenu="subMaster" isopen={isopen} />
                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Budget Master</h4>
                                        </div>
                                        <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-end text-right">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                title="Add" onClick={() => handleAddNewClick()}>
                                                <i className="fa-solid fa-circle-plus pr-2" style={{ fontSize: "15px" }}></i>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <BudgetTypeDDL
                                                        budgetTypeDDL={budgetTypeDDL}
                                                        setBudgetTypeDDL={setBudgetTypeDDL}
                                                        DDLData={BudgetTypeDDLData}
                                                        FlagName=''
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-2 pl-lg-3">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light"
                                                        onClick={handleOnClearClick}
                                                    >
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "5em", textAlign: "center" }}>Sr.No.</th>
                                                    <th>Budget Type</th>
                                                    <th>Name of Budget Head</th>
                                                    <th>Budget Code</th>
                                                    <th style={{ width: "8%",textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                                                        <tr key={item.id}>
                                                            <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                                            <td><span>{item.budgetTypeName ? item.budgetTypeName : "-"}</span></td>
                                                            <td>{item.budgetName ? item.budgetName : "-"}</td>
                                                            <td>{item.budgetCode ? item.budgetCode : "-"}</td>

                                                            <td style={{ textAlign: "center" }}>
                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Edit" onClick={() => handleOnEditClick(item)}>
                                                                    <Edit size={15} color="#007bff" />
                                                                </span>

                                                                <span className="mx-2 cursor-pointer"
                                                                    title="Delete" onClick={() =>
                                                                        handleOnDeleteClick(item.id)
                                                                    }>
                                                                    <Trash2 size={15} color="red" />
                                                                </span>

                                                            </td>
                                                        </tr>
                                                    )) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {tableData && tableData.table && tableData.table.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData.table[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

            {showCreatePopup ? <BudgetPopUp open={showCreatePopup} handleOnCloseClick={handleOnCloseClick} handleOnClearClick={handleOnClearClick} apiFlag={apiFlag} IsPost={IsPost} editData={editData} setIsPost={setIsPost} flag={flag} /> : <></>}
            {showDeletePopup ? <DeletePopUp open={showDeletePopup} handleOnCloseClick={handleOnCloseClick} handleDeleteData={handleDeleteData} /> : <></>}

        </>
    )
}
