
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { SubDepartmentDDLAPI } from "../../Redux/DDLSlice"
import { useAuthState } from "../../Helper/Context/context";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";



export default function SubDepartmentDDL(props) {
    const { subDepartmentDDL, setSubDepartmentDDL, departmentDDLID, subDepartmentDDLData, FlagName, editData, apiFlag, mendetary } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    // console.log(departmentDDLID)

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_SubDepartmentID: 0,
            M_DepartmentID: departmentDDLID
        }
        dispatch(SubDepartmentDDLAPI({ data }))
    }, [departmentDDLID])

    const { SubDepartmentData } = useSelector(state => state.SubDepartmentDDLData)

    useEffect(() => {
        handleSubDepartmentNameDDL()
    }, [SubDepartmentData])

    const handleSubDepartmentNameDDL = () => {
        if (SubDepartmentData && SubDepartmentData.table && SubDepartmentData.table.length > 0) {

            let list = SubDepartmentData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.subDepartmentName,
            }))
            setSubDepartmentDDL({
                DDL: list,
                ID: apiFlag === 'Update' ? editData.m_SubDepartmentID : 0,
                Label: apiFlag === 'Update' ? editData.subDepartmentName : "---Select---"
                // ID: PopUpField ? PopUpField?.apiFlag === "Insert" ? 0 : PopUpField?.rowData?.m_Product_CategoryID : "0",
                // Label: PopUpField ? PopUpField?.apiFlag === "Insert" ? "Select..." : PopUpField?.rowData?.categoryName : "Select...",
            })
        }
        else {
            setSubDepartmentDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Sub Department {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: subDepartmentDDL.ID, label: subDepartmentDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setSubDepartmentDDL({ ...subDepartmentDDL, ID: e.value, Label: e.label })
                            :
                            setSubDepartmentDDL({ ...subDepartmentDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={subDepartmentDDL.DDL}

                />
            </div>
        </>
    )
}