
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useEffect } from "react"
import { ProjectRemarkDDLAPI } from "../../Redux/DDLSlice"
import { useAuthState } from "../../Helper/Context/context"
import { useDispatch, useSelector } from "react-redux"



export default function ProjectRemarkDDL(props) {
    const { projectRemarkDDL, setProjectRemarkDDL, Flag, PopUpField, apiFlag, editData, IsPost } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_ProjectRemarkID: 0,
            Flag: Flag
        }
        dispatch(ProjectRemarkDDLAPI({ data }))
    }, [IsPost])


    const { ProjectRemarkDDLData } = useSelector(state => state.ProjectRemarkDDLData)

    useEffect(() => {
        handleProjectRemarkDDL()
    }, [ProjectRemarkDDLData])

    const handleProjectRemarkDDL = () => {
        if (ProjectRemarkDDLData && ProjectRemarkDDLData.table && ProjectRemarkDDLData.table.length > 0) {

            let list = ProjectRemarkDDLData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.m_ProjectRemarkID,
                label: item.projectRemark,
            }))
            if (PopUpField && PopUpField.popupFlag) {
                setProjectRemarkDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_ProjectRemarkID : 0 : '0',
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.projectRemark : "--Select--" : "--Select--",
                })
            }
            else {
                setProjectRemarkDDL({
                    DDL: list,
                    ID: apiFlag === 'Update' ? editData.m_ProjectRemarkID : 0,
                    Label: apiFlag === 'Update' ? editData.projectRemark : "---Select---",
                })
            }
        }
        else {
            setProjectRemarkDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype"> Project Remark</label>
                <Select
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: projectRemarkDDL.ID, label: projectRemarkDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setProjectRemarkDDL({ ...projectRemarkDDL, ID: e.value, Label: e.label })
                            :
                            setProjectRemarkDDL({ ...projectRemarkDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={projectRemarkDDL.DDL}

                />
            </div>
        </>
    )
}