import React, { useState } from "react";
import Select from 'react-select'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Post_M_ProjectType_InsertUpdate } from "../MasterApi/POSTAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Star } from "../../../Components/CommoComponent/Star";
import { useDispatch } from "react-redux";
import { ProjectTypePostAPI } from "../../../Redux/MasterSlice/ProjectTypeSlice";

export default function ProjectTypePopUp({ open, handleAddCloseClick, PopUpField, handlePost }) {

    const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()

    const [projectTypeTextField, setprojectTypeTextField] = useState(
        {
            projectTypeName:  apiFlag === "Insert" ? '' : rowData?.projectTypeName ,
        }
    )
    const handleInputChange = (e) => {
        setprojectTypeTextField({ ...projectTypeTextField, [e.target.name]: e.target.value })
    }

    const handleClear = () => {
        setprojectTypeTextField(
            {
                projectTypeName: "",
            }
        )
    }

    const handleAddUpdate = () => {
        const addData = {
            M_ProjectTypeID:apiFlag === 'Insert' ? '0' : rowData?.id,
            ProjectTypeName:projectTypeTextField?.projectTypeName     ,       
            Login_UserID: UserID,
            token: token,
            Flag: apiFlag,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick
        }
        dispatch(ProjectTypePostAPI({ addData }))
    }

    return (

        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>
                <span className="close" onClick={handleAddCloseClick}>
                    &times;
                </span>
                <div className="call font-weight-bold"> Add Project Type</div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="projectTypeName">Project Type <Star/></label>
                                <input
                                    className="form-control"
                                    id="projectTypeName"
                                    type="text"
                                    name="projectTypeName"
                                    value={projectTypeTextField.projectTypeName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                                
                                {/* <span className="text-danger error-danger">{formErrors.projectTypeNameError}</span> */}
                            </div>
                        </div>
                        {/* <div className="col-6 col-lg-6">
                                <div className="form-group">
                                    <label className="d-block" for="NameofDepartment">Project Code</label>
                                    <input type="text" className="form-control" name="NameofDepartment" id="NameofDepartment" />
                                </div>
                            </div> */}

                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                 onClick={() => { popupBtn === 'Clear' ? handleClear() : handleAddCloseClick() }}
                                    >
                                    <X size={18} style={{ marginRight: "3px" }} />{popupBtn}</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    // onClick={() => console.log(projectTypeTextField)}
                                    onClick={() => handleAddUpdate()}
                                    disabled={
                                        projectTypeTextField.projectTypeName === ""  
                                        // formErrors.projectTypeNameError === "Invalid project Type Name"
                                       
                                    }
                                >{apiFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popupFlag}</button>

                            </div>
                        </div>
                    </div>
                </div>


            </Popup >
        </div >
    )
}