import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Post_T_BudgetCreation_InsertUpdate } from "../BudgetManagementAPI/POSTAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Get_DDL_M_Budget, Get_DDL_M_BudgetType } from "../../../APIs/DDLAPI/DDLAPI";
import { Star } from "../../../Components/CommoComponent/Star";

export default function BudgetCreationPopUp({ flag, open, handleOnCloseClick, apiFlag, IsPost, setIsPost, editData,handleOnClearClick }) {

    const userDetails = useAuthState();
    const { UserID } = userDetails
   

    const [budgetTypeDDL, setbudgetTypeDDL] = useState({
        DDL: [],
        ID: apiFlag==="Update" ? editData.m_BudgetTypeID: 0,
        Label: apiFlag==="Update" ? editData.budgetTypeName: "---Select---"
    })
    const [budgetHeadDDL, setbudgetHeadDDL] = useState({
        DDL: [],
        ID: apiFlag==="Update" ? editData.m_BudgetID : 0,
        code: apiFlag==="Update" ? editData.budgetCode : "",
        Label: apiFlag==="Update" ? editData.budgetName: "---Select---"
    })
   

    const budgetHeadClear = () => {
        setbudgetHeadDDL({
            ...budgetHeadDDL,
            ID: 0,
            code: "",
            Label:"---Select---"
        })
    }

    const [budgetCreationTextField, setbudgetCreationTextField] = useState(
        {
            budgetCode: apiFlag==="Update" ? editData.budgetCode: "",
            budgetProvisioin: apiFlag==="Update" ? editData.budgetProvision: "",
        }
    )
    const handleInputChange = (e) => {
        setbudgetCreationTextField({ ...budgetCreationTextField, [e.target.name]: e.target.value })
    }

    const [formErrors, setformErrors] = useState({
        budgetProvisioinError: "",
    })
    const checkcodeValidation = (text) => {
       
        let regex = new RegExp(/^[0-9]*$/)

        if (regex.test(text) || text === "") {
            setformErrors({ ...formErrors, budgetProvisioinError: "" })
        } else {
            setformErrors({ ...formErrors, budgetProvisioinError: "Enter valid number" })

        }
    }

    useEffect(() => {
        Get_DDL_M_BudgetType({ UserID, budgetTypeDDL: budgetTypeDDL, setbudgetTypeDDL: setbudgetTypeDDL })
    }, [])

    useEffect(() => {
        Get_DDL_M_Budget({ UserID, budgetHeadDDL: budgetHeadDDL, setbudgetHeadDDL: setbudgetHeadDDL, budgetTypeDDL })
    }, [budgetTypeDDL.ID])

    const {budgetCode,budgetProvisioin } = budgetCreationTextField

    const Add = () => {
        Post_T_BudgetCreation_InsertUpdate({
            BudgetCreationID: apiFlag === "Insert" ? "0" : editData.t_BudgetCreationID,
            BudgetID:budgetHeadDDL,
            UserID,
            budgetCreationTextField:budgetCreationTextField,
            apiFlag,
            handleOnCloseClick,
            IsPost,
            setIsPost,
            handleOnClearClick:handleOnClearClick
        })
    }

    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
                <span className="close" onClick={handleOnCloseClick}>
                    &times;
                </span>
                <div className="call"> Add Budget </div>
                <div className="modal-body">
                    <div className="row details-row">

                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="budgetType">Budget Type <Star/></label>
                                <Select
                                    isClearable
                                    // isRtl={isRtl}
                                    isSearchable
                                    maxMenuHeight={140}
                                    value={{ value: budgetTypeDDL.ID, label: budgetTypeDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setbudgetTypeDDL({ ...budgetTypeDDL, ID: e.value, Label: e.label })
                                            :
                                            setbudgetTypeDDL({ ...budgetTypeDDL, ID: 0, Label: "---Select---"})
                                            budgetHeadClear()

                                    }}
                                    options={budgetTypeDDL.DDL}
                                // options={options}

                                />
                            </div>
                        </div>



                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="budgetHeadName">Budget Head Name <Star/> </label>
                                <Select
                                    isClearable
                                    // isRtl={isRtl}
                                    isSearchable
                                    maxMenuHeight={140}
                                    value={{ value: budgetHeadDDL.ID, label: budgetHeadDDL.Label }}
                                    onChange={(e) => {
                                        e ?
                                            setbudgetHeadDDL({ ...budgetHeadDDL, ID: e.value, Label: e.label, code: e.code })
                                            :
                                            setbudgetHeadDDL({
                                                ...budgetHeadDDL, ID: 0, Label: "---Select---"
                                            })

                                    }}
                                    options={budgetHeadDDL.DDL}


                                />
                            </div>
                        </div>

                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="budgetCode">Budget Code <Star/></label>
                                <input
                                    className="form-control"
                                    id="budgetCode"
                                    type="text"
                                    disabled
                                    name="budgetCode"
                                    value={budgetHeadDDL.code}
                                />
                            </div>
                        </div>

                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="budgetProvisioin">Budget Provision(Lacs) <Star/></label>
                                <input
                                    className="form-control"
                                    id="budgetProvisioin"
                                    type="text"
                                    name="budgetProvisioin"
                                    value={budgetCreationTextField.budgetProvisioin}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        checkcodeValidation(e.target.value)
                                    }
                                        
                                    }
                                />
                                 <span className="text-danger error-danger">{formErrors.budgetProvisioinError}</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="submit" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleOnCloseClick}>
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="submit"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    // onClick={() => console.log(budgetCreationTextField)}
                                    onClick={() => Add()}
                                    disabled = {
                                        budgetTypeDDL.ID === 0 ||
                                        budgetHeadDDL.ID === 0 ||
                                        budgetHeadDDL.code === "" ||
                                        budgetCreationTextField.budgetProvisioin === ""


                                    }
                                >
                        
                                {flag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{flag}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Popup>
        </div>

    )
}