

import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useDispatch, useSelector } from "react-redux";
import { ProjectDDLAPI, ProjectNameMilestoneDDLAPI } from "../../Redux/DDLSlice";
import { useEffect } from "react";
import { useAuthState } from "../../Helper/Context/context";

export default function ProjectNameNewDDL(props) {
    const { projectNameMilestoneDDL, setProjectNameMilestoneDDL, FlagName, PopUpField, isdisabled, projectTypeDDL, WorkAndWorkTypeDDL, apiFlag, editData, IsPost, mendetary,ERPCodeFlag } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const YearID = localStorage.getItem("YearID")
    const MonthID = localStorage.getItem("MonthID")

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = {
            M_FinancialYearID: YearID ? YearID : 0,
            M_MonthID: MonthID ? MonthID : 0,
            UserID: UserID,
            token: token,
            M_ProjectTypeID: projectTypeDDL.ID,
            M_WorkTypeID: WorkAndWorkTypeDDL.ID,
            Flag: FlagName,
        }
        { projectTypeDDL.ID != 0 && WorkAndWorkTypeDDL.ID != 0 && dispatch(ProjectNameMilestoneDDLAPI({ data })) }
    }, [IsPost, WorkAndWorkTypeDDL.ID, YearID, MonthID,projectTypeDDL.ID])

    const { ProjectNameMilestoneData } = useSelector(state => state.ProjectNameMilestoneDDLData)

    useEffect(() => {
        handleProjectDDL();
    }, [ProjectNameMilestoneData])


    const handleProjectDDL = () => {
        if (ProjectNameMilestoneData && ProjectNameMilestoneData.table && ProjectNameMilestoneData.table.length > 0) {
            let list = ProjectNameMilestoneData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.m_ProjectID,
                label: item.projectName,
                projectCode: item.projectCode,
                numberOfMileStone: item.numberOfMileStone ? item.numberOfMileStone : ''
            }))

            PopUpField && PopUpField?.popupFlag === "Update" ?
                setProjectNameMilestoneDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_ProjectID : 0 : 0,
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.projectName : "---Select---" : "---Select---",
                    projectCode: '',
                    numberOfMileStone: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.numberOfMileStone : "" : "",
                }) :
                setProjectNameMilestoneDDL({
                    DDL: list,
                    ID: apiFlag === 'Update' ? editData.m_ProjectID : 0,
                    Label: apiFlag === 'Update' ? editData.projectName : "---Select---",
                    projectCode: apiFlag === 'Update' ? editData.project_ERP_Code : '',
                    numberOfMileStone: apiFlag === 'Update' ? editData.numberOfMileStone : '',
                })
        }
        else {
            setProjectNameMilestoneDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
                projectCode: '',
            })
        }
    }


    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Project Name {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    isClearable
                    isSearchable
                    isDisabled={isdisabled === 'yes' && apiFlag === 'Update' || WorkAndWorkTypeDDL.ID == 0 || projectTypeDDL.ID == 0}
                    maxMenuHeight={140}
                    value={{ value: projectNameMilestoneDDL.ID, label: projectNameMilestoneDDL.Label, numberOfMileStone: projectNameMilestoneDDL.numberOfMileStone }}
                    onChange={(e) => {
                        e ?
                            setProjectNameMilestoneDDL({ ...projectNameMilestoneDDL, ID: e.value, Label: e.label, numberOfMileStone: e.numberOfMileStone, projectCode: e.projectCode })
                            :
                            setProjectNameMilestoneDDL({ ...projectNameMilestoneDDL, ID: 0, Label: "---Select---", numberOfMileStone: '', projectCode: '' })
                    }}
                    options={projectNameMilestoneDDL.DDL}

                />
            </div>
        </>
    )
}