
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr } from "../../Helper/ToastMessage";

export const Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectAPI = createAsyncThunk("Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_Select", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        FromDate,
        ToDate,
        M_DepartmentID,
        M_SubDepartmentID,
        M_ProjectTypeID,
        M_ProjectID,
        M_WardID,
        M_WardWiseNumberID,
        M_ProjectPriorityID,
        Key_ProjectM_IndicatorID,
        M_Tender_Amount_TypeID,
        Flag,
        M_UsersID,
        FromTop,
        ToTop,
        token,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&FromDate=${FromDate}&ToDate=${ToDate}&M_DepartmentID=${M_DepartmentID}&M_SubDepartmentID=${M_SubDepartmentID}&M_ProjectTypeID=${M_ProjectTypeID}&M_ProjectID=${M_ProjectID}&M_WardID=${M_WardID}&M_WardWiseNumberID=${M_WardWiseNumberID}&M_ProjectPriorityID=${M_ProjectPriorityID}&Key_ProjectM_IndicatorID=${Key_ProjectM_IndicatorID}&M_Tender_Amount_TypeID=${M_Tender_Amount_TypeID}&Flag=${Flag}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                return result.data.table
            } else {
                return result
            }
        })
})

const Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectSlice = createSlice({
    name: "Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_Select",
    initialState: {
        isLoading: false,
        isDelayReportDataLoading: false,
        tableData: null,
        DelayReportData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectAPI.pending, (state, action) => {
            state.isLoading = true;
            state.isDelayReportDataLoading = true;
        });
        builder.addCase(Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
            state.isDelayReportDataLoading = false;
            state.DelayReportData = action.payload;
        });
        builder.addCase(Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isDelayReportDataLoading = false;
            state.isError = true;
            state.tableData = null;
            state.DelayReportData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectReducer = Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_SelectSlice.reducer

// Daily Report Api

export const Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectAPI = createAsyncThunk("Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelect", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        FromDate,
        ToDate,
        M_DepartmentID,
        M_SubDepartmentID,
        M_ProjectTypeID,
        M_ProjectID,
        M_WardID,
        M_WardWiseNumberID,
        M_ProjectPriorityID,
        Key_ProjectM_IndicatorID,
        M_Tender_Amount_TypeID,
        Flag,
        M_UsersID,
        FromTop,
        ToTop,
        token,
        // setWorkOrderDelayReport,
        // setAllReport
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&FromDate=${FromDate}&ToDate=${ToDate}&M_DepartmentID=${M_DepartmentID}&M_SubDepartmentID=${M_SubDepartmentID}&M_ProjectTypeID=${M_ProjectTypeID}&M_ProjectID=${M_ProjectID}&M_WardID=${M_WardID}&M_WardWiseNumberID=${M_WardWiseNumberID}&M_ProjectPriorityID=${M_ProjectPriorityID}&Key_ProjectM_IndicatorID=${Key_ProjectM_IndicatorID}&M_Tender_Amount_TypeID=${M_Tender_Amount_TypeID}&Flag=${Flag}&M_UsersID=${M_UsersID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                // {
                //     Flag == 'DelayReport' &&
                //         setWorkOrderDelayReport && setWorkOrderDelayReport(result.data.table)
                // }
                return result.data.table
            } else {
                return result
            }
        })
})

const Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectSlice = createSlice({
    name: "Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelect",
    initialState: {
        isDelayReportDataLoading: false,
        DelayReportData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectAPI.pending, (state, action) => {
            state.isDelayReportDataLoading = true;
        });
        builder.addCase(Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectAPI.fulfilled, (state, action) => {
            state.isDelayReportDataLoading = false;
            state.DelayReportData = action.payload;
        });
        builder.addCase(Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isDelayReportDataLoading = false;
            state.isError = true;
            state.DelayReportData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectReducer = Get_RM_Web_DB_T_PhysicalMilestoneWorkProgress_DailyReportSelectSlice.reducer
