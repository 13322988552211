import { useEffect } from 'react'
import Select from 'react-select'
import { WorkDDLAPI } from '../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../Helper/Context/context'


export const WorkDDLData = (props) => {
    const { SubWorkTypeDDL, setSubWorkTypeDDL, Flag,WorkTypeDDL,IsPost } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = { UserID: UserID, token: token, WorkTypeDDL:WorkTypeDDL.ID }
        dispatch(WorkDDLAPI({ data }))
    }, [IsPost,WorkTypeDDL.ID ])

    const { WorkDDLData } = useSelector(state => state.WorkDDLData)

    useEffect(() => {
        handleSubWorkTypeDDL();
    }, [WorkDDLData])

    const handleSubWorkTypeDDL = () => {
        if (WorkDDLData && WorkDDLData.table && WorkDDLData.table.length > 0) {
            let list = WorkDDLData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.workName,
            }))

            setSubWorkTypeDDL({
                DDL: list,
                ID: 0,
                Label: "---Select---",
            })
        }
        else {
            setSubWorkTypeDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"> Sub Work Type Name </label>
            <Select
                isClearable
                isSearchable
                maxMenuHeight={150}
                value={{ value: SubWorkTypeDDL.ID, label: SubWorkTypeDDL.Label }}
                onChange={(e) => {
                    e ?
                        setSubWorkTypeDDL({ ...SubWorkTypeDDL, ID: e.value, Label: e.label })
                        :
                        setSubWorkTypeDDL({ ...SubWorkTypeDDL, ID: 0, Label: "---Select---" })

                }}
                options={SubWorkTypeDDL.DDL}
            // isDisabled
            />
        </div>
    )
}