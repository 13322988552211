import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

// ---------------------------Budget Creation-------------------
export function Get_T_BudgetCreation_Select({ setBudgetCreationTable, UserID, budgetHeadDDL, budgetTypeDDL, setloading, setTotalCount, From, To, rowNo, PerPageCount}) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      setloading(true)
    fetch(`${BaseUrl}/PTMS/Transaction/Get_T_BudgetCreation_Select?T_BudgetCreationID=0&M_BudgetTypeID=${budgetTypeDDL.ID}&M_BudgetID=${budgetHeadDDL.ID}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                // setdepartmentTable(result.data.table)
                setBudgetCreationTable(result.data.table.map((customdata, index) => ({ ...customdata, rowNo: rowNo == 1 ? (index + 1) : ((rowNo - 1) * PerPageCount) + (index + 1) })))
                setTotalCount(result.data.table[0].totalCount)
                setloading(false)
            } else {
                setBudgetCreationTable([])
                
            }
            setloading(false)
        })
        .catch(error => {
            setloading(false)
            toastErrorr("Something went wrong")
            console.log('error', error)
        });
}


// -----------------------------Allocate Budget----------------

export function Get_T_BudgetAllocation_Select({ setAllocateBudgeTable, UserID, budgetTypeDDL, setloading, setTotalCount, From, To, rowNo, PerPageCount}) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      setloading(true)
    fetch(`${BaseUrl}/PTMS/Transaction/Get_T_BudgetAllocation_Select?T_BudgetAllocationID=0&M_BudgetTypeID=${budgetTypeDDL.ID}&M_UsersID=${UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                // setdepartmentTable(result.data.table)
                setAllocateBudgeTable(result.data.table.map((customdata, index) => ({ ...customdata, rowNo: rowNo == 1 ? (index + 1) : ((rowNo - 1) * PerPageCount) + (index + 1) })))
                setTotalCount(result.data.table[0].totalCount)
                setloading(false)

            } else {
                setAllocateBudgeTable([])
                
                
            }
            setloading(false)
        })
        .catch(error => {
            setloading(false)
            toastErrorr("Something went wrong")
            console.log('error', error)
        });
}