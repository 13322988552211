
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star"
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../Helper/Context/context";
import { useEffect } from "react";
import { ProjectTypeDDLAPI } from "../../Redux/DDLSlice";



export default function ProjectTypeDDLData(props) {

    const { ProjectTypeDDL, setProjectTypeDDL, FlagName, PopUpField, isdisabled, apiFlag, editData, IsPost, mendetary } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()
    useEffect(() => {
        // const data = { UserID :Flag && Flag == 'Master' ? '0' : UserID , token : token} 
        const data = { UserID: UserID, token: token }
        dispatch(ProjectTypeDDLAPI({ data }))
    }, [IsPost])

    const { ProjectTypeData } = useSelector(state => state.ProjectTypeDDLData)

    useEffect(() => {
        handleProjectTypeDDL();
    }, [ProjectTypeData])

    const handleProjectTypeDDL = () => {
        if (ProjectTypeData && ProjectTypeData.table && ProjectTypeData.table.length > 0) {
            let list = ProjectTypeData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.id,
                label: item.projectTypeName,
            }))

            if (PopUpField && PopUpField.popupFlag) {
                setProjectTypeDDL({
                    DDL: list,
                    ID: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.m_ProjectTypeID : 0 : 0,
                    Label: PopUpField ? PopUpField?.popupFlag === "Update" ? PopUpField?.rowData?.projectTypeName : "---Select---" : "---Select---",
                })
            }
            else {
                setProjectTypeDDL({
                    DDL: list,
                    ID: apiFlag === 'Update' ? editData.m_ProjectTypeID : 0,
                    Label: apiFlag === 'Update' ? editData.projectTypeName : "---Select---",
                })
            }
        }
        else {
            setProjectTypeDDL({
                DDL: [],
                ID: 0,
                Label: "---Select---",
            })
        }
    }

    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Project Type {mendetary === 'yes' ? <Star/> : ''} </label>
                <Select
                    isClearable
                    isSearchable
                    isDisabled={isdisabled === 'yes' && apiFlag === 'Update'}
                    maxMenuHeight={140}
                    value={{ value: ProjectTypeDDL.ID, label: ProjectTypeDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setProjectTypeDDL({ ...ProjectTypeDDL, ID: e.value, Label: e.label })
                            :
                            setProjectTypeDDL({ ...ProjectTypeDDL, ID: 0, Label: "---Select---" })
                    }}
                    options={ProjectTypeDDL.DDL}

                />
            </div>
        </>
    )
}