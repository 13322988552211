import React from 'react'

const ErrorFallback = ({ resetErrorBoundary }) => {
    return (
        <div role="alert" className="col-12 col-lg-12 text-center">
            <img src="./assets/img/went3.png" width="450px" height="auto" alt="eroor" />
            <br />
            {/* <p>Something went wrong:</p> */}
            <button className='boundary_btn' onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}

export default ErrorFallback
