import React, { useState, useEffect } from "react";
import moment from "moment";
import Select from "react-select";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Edit, Edit2, Plus, Trash, X } from "react-feather";
import { Get_DDL_M_Department, Get_DDL_M_Project, Get_DDL_M_ProjectPriority, Get_DDL_M_ProjectType } from "../../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../../Helper/Context/context";
import { Star } from "../../../Components/CommoComponent/Star";
import { Post_T_ProjectDefine_InsertUpdate } from "../../CreateProject/CreateProjectAPI/POSTAPI";
import { useDispatch, useSelector } from "react-redux";
import { DepartmentNameDDLAPI, DesignationNameDDLAPI } from "../../../Redux/DDLSlice";
import { UserStatusDDLData } from "../../../CommonDDL/UserStatusDDLData";
import DesignationNameDDL from "../../../CommonDDL/DesignationDDL/DesignationNameDDL";
import DepartmentDDL from "../../../CommonDDL/DepartmentDDL/DepartmentDDL";
import { RoleDDLData } from "../../../CommonDDL/RoleDDLData";
import EmployeeNameDDL from "../../../CommonDDL/EmployeeNameDDL/EmployeeNameDDL";
import { UserCreationPostAPI } from "../../../Redux/UserMasterSlice/UserCreationSlice";

export default function UserCreationPopUp({ open, handleAddCloseClick, PopUpField, handlePost, IsPost }) {

    const { popupFlag, popupBtn, apiFlag, rowData } = PopUpField

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const dispatch = useDispatch()

    const [RoleDDL, setRoleDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_RoleID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.roleName,
    })

    const [departmentDDL, setdepartmentDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_DepartmentID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.departmentName,
    });

    const [designationNameDDL, setDesignationNameDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_DesignationID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.designationName,
    });
    const [employeeNameDDL, setEmployeeNameDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.m_EmployeeID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.employeeName,
    });
    const [UserStatusDDL, setUserStatusDDL] = useState({
        DDL: [],
        ID: apiFlag === "Insert" ? 0 : rowData?.userStatusIndicatorID,
        Label: apiFlag === "Insert" ? "---Select---" : rowData?.userStatusIndicator,
    });


    const [UserCreationTextField, setUserCreationTextField] = useState({

        tableName: apiFlag === "Insert" ? '' : rowData?.tableName,
        userName: apiFlag === "Insert" ? '' : rowData?.userName,
        password: apiFlag === "Insert" ? '' : rowData?.password,
        joining_Date: apiFlag === "Insert" ? '' : moment(rowData?.joining_Date).format("YYYY-MM-DD"),

    })

    const handleInputChange = (e) => {
        setUserCreationTextField({ ...UserCreationTextField, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        const data = {
            UserID,
            token,
            M_DeptOfficeTypeIndicatorID: departmentDDL.ID
        }
        dispatch(DesignationNameDDLAPI({ data }))
    }, [departmentDDL.ID])

    const { DesignationDDLData } = useSelector(state => state.DesignationNameData)

    // const handleClear = () => {
    //     setCurrentPage(0)
    //     setRoleDDL({
    //       ...RoleDDL,
    //       ID: 0,
    //       Label: "---Select---"
    //     })
    //     setdepartmentDDL({
    //       ...departmentDDL,
    //       ID: 0,
    //       Label: "---Select---"
    //     })
    //     setDesignationNameDDL({
    //       ...designationNameDDL,
    //       ID: 0,
    //       Label: "---Select---"
    //     })
    //     setUserStatusDDL({
    //       ...UserStatusDDL,
    //       ID: 0,
    //       Label: "---Select---"
    //     })

    //     setIsPost(!IsPost)
    // }

    const handleAddUpdate = () => {
        const addData = {
            M_UsersID: apiFlag === 'Insert' ? '0' : rowData?.id,
            M_RoleID: RoleDDL.ID,
            M_DepartmentID: departmentDDL.ID,
            M_EmployeeID: employeeNameDDL.ID,
            M_DesignationID: designationNameDDL.ID,
            TableName: UserCreationTextField?.tableName,
            UserName: UserCreationTextField?.userName,
            Password: UserCreationTextField?.password,
            Joining_Date: UserCreationTextField?.joining_Date,
            UserStatusIndicatorID: UserStatusDDL.ID,
            Login_UserID: UserID,
            token: token,
            Flag: apiFlag,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick
        }
        dispatch(UserCreationPostAPI({ addData }))
    }

    return (
        <div>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleAddCloseClick}>
                <span className="close" onClick={handleAddCloseClick}>
                    &times;
                </span>
                <div className="call"> User Creation</div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="col-6 col-lg-6">
                            <RoleDDLData
                                RoleDDL={RoleDDL}
                                setRoleDDL={setRoleDDL}
                                PopUpField={PopUpField}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-6 col-lg-6">
                            <DepartmentDDL
                                departmentDDL={departmentDDL}
                                setdepartmentDDL={setdepartmentDDL}
                                IsPost=''
                                PopUpField={PopUpField}
                                mendetary='yes'
                            // FlagName=''
                            />

                        </div>
                        <div className="col-6 col-lg-6">
                            <DesignationNameDDL
                                designationNameDDL={designationNameDDL}
                                setDesignationNameDDL={setDesignationNameDDL}
                                DDLData={DesignationDDLData}
                                PopUpField={PopUpField}
                                mendetary='yes'
                                departmentDDL={departmentDDL}
                            // FlagName=''
                            />
                        </div>

                        <div className="col-6 col-lg-6">
                            <EmployeeNameDDL
                            departmentDDL={departmentDDL.ID}
                                employeeNameDDL={employeeNameDDL}
                                designationNameDDL={designationNameDDL}
                                setEmployeeNameDDL={setEmployeeNameDDL}
                                PopUpField={PopUpField}
                                mendetary='yes'
                            // EmployeeDDLData={EmployeeDDLData}
                            // FlagName=''
                            />
                        </div>

                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="role">Table Name <Star /> </label>
                                <input
                                    className="form-control"
                                    id="tableName"
                                    type="text"
                                    name="tableName"
                                    value={UserCreationTextField.tableName}
                                    // max={empolyeeTextField.joiningDate}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="joiningDate">Joining Date <Star /> </label>
                                <input
                                    className="form-control"
                                    id="joining_Date"
                                    type="Date"
                                    name="joining_Date"
                                    value={UserCreationTextField.joining_Date}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="role">User Name <Star /> </label>
                                <input
                                    className="form-control"
                                    id="userName"
                                    type="text"
                                    name="userName"
                                    value={UserCreationTextField.userName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <div className="form-group">
                                <label className="d-block" for="role">Password <Star /> </label>
                                <input
                                    className="form-control"
                                    id="password"
                                    type="text"
                                    name="password"
                                    value={UserCreationTextField.password}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-6 col-lg-6">
                            <UserStatusDDLData
                                UserStatusDDL={UserStatusDDL}
                                setUserStatusDDL={setUserStatusDDL}
                                PopUpField={PopUpField}
                                mendetary='yes'
                            />
                        </div>
                        <div className="col-12 col-lg-12">
                            <div className="btn-action">
                                <button type="button" className="btn btn-md btn-clear mx-2 float-right"
                                    onClick={handleAddCloseClick}
                                >
                                    <X size={18} style={{ marginRight: "3px" }} />Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-md btn-primary mx-2 float-right"
                                    onClick={() => handleAddUpdate()}
                                    disabled={
                                        RoleDDL.ID === 0 ||
                                        departmentDDL.ID === 0 ||
                                        designationNameDDL.ID === 0 ||
                                        employeeNameDDL.ID === 0 ||
                                        UserCreationTextField.tableName === '' ||
                                        UserCreationTextField.joining_Date === '' ||
                                        UserCreationTextField.userName === '' ||
                                        UserCreationTextField.password === '' ||
                                        UserStatusDDL.ID === 0
                                    }
                                >{apiFlag === "Update" ? <Edit size={18} style={{ marginRight: "3px" }} /> : <Plus size={18} style={{ marginRight: "3px" }} />}{popupFlag}</button>
                            </div>
                        </div>

                    </div>
                </div>


            </Popup>
        </div>
    )
}