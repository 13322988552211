import React, { useState } from 'react'
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import Select from "react-select"

import SpillOverDepartSearch from './SpillOverDepartSearch';
import SpillOverDepartTable from './SpillOverDepartTable';
import MobileHeader from '../../../../Components/Header/MobileHeader';
import { Search, X } from 'react-feather'




const PhysicalSpillOverDepartmentPage = () => {

    const [isopen, setIsOpen] = useState(true);
    const options = [
        { value: 'New', label: 'New' },
        { value: 'Ongoing', label: 'Ongoing' },
        { value: 'Completed', label: 'Completed' }
    ]

    const options1 = [
        { value: 'Ward123', label: 'Ward123' },
        { value: 'Ward456', label: 'Ward456' },
        { value: 'Ward789', label: 'Ward789' }
    ]
    const data = [
        {
            Sr: "1",
            NameoftheProject: "Project name goes here",
            // TotalProject: "Vendor Name Come here",
            NameofVendor: "Vendor Name Come here",
            WardNumber: "Ward123",
            Status: "New",
            EstimatedCost: "Rs.15.50L",
            TenderedCost: "Rs.16.50L",
            Timelimit: "36 Months",
            Spillover: "20 Months",
            ExtensionGranted: "Yes",
            stExtension: "Up to 05/07/2018",
            ndExtension: "Up to 05/05/2020",
            rdExtension: "Up to 05/12/2022"

        },


        {
            Sr: "2",
            NameoftheProject: "Project name goes here",
            // TotalProject: "Vendor Name Come here",
            NameofVendor: "Vendor Name Come here",
            WardNumber: "Ward123",
            Status: "New",
            EstimatedCost: "Rs.15.50L",
            TenderedCost: "Rs.16.50L",
            Timelimit: "36 Months",
            Spillover: "20 Months",
            ExtensionGranted: "Yes",
            stExtension: "Up to 05/07/2018",
            ndExtension: "Up to 05/05/2020",
            rdExtension: "Up to 05/12/2022"
        },
        {
            Sr: "3",
            NameoftheProject: "Project name goes here",
            // TotalProject: "Vendor Name Come here",
            NameofVendor: "Vendor Name Come here",
            WardNumber: "Ward123",
            Status: "New",
            EstimatedCost: "Rs.15.50L",
            TenderedCost: "Rs.16.50L",
            Timelimit: "36 Months",
            Spillover: "20 Months",
            ExtensionGranted: "Yes",
            stExtension: "Up to 05/07/2018",
            ndExtension: "Up to 05/05/2020",
            rdExtension: "Up to 05/12/2022"
        },
        {
            Sr: "6",
            NameoftheProject: "Project name goes here",
            // TotalProject: "Vendor Name Come here",
            NameofVendor: "Vendor Name Come here",
            WardNumber: "Ward123",
            Status: "New",
            EstimatedCost: "Rs.15.50L",
            TenderedCost: "Rs.16.50L",
            Timelimit: "36 Months",
            Spillover: "20 Months",
            ExtensionGranted: "Yes",
            stExtension: "Up to 05/07/2018",
            ndExtension: "Up to 05/05/2020",
            rdExtension: "Up to 05/12/2022"
        },
        {
            Sr: "7",
            NameoftheProject: "Project name goes here",
            // TotalProject: "Vendor Name Come here",
            NameofVendor: "Vendor Name Come here",
            WardNumber: "Ward123",
            Status: "New",
            EstimatedCost: "Rs.15.50L",
            TenderedCost: "Rs.16.50L",
            Timelimit: "36 Months",
            Spillover: "20 Months",
            ExtensionGranted: "Yes",
            stExtension: "Up to 05/07/2018",
            ndExtension: "Up to 05/05/2020",
            rdExtension: "Up to 05/12/2022"
        },
        {
            Sr: "8",
            NameoftheProject: "Project name goes here",
            // TotalProject: "Vendor Name Come here",
            NameofVendor: "Vendor Name Come here",
            WardNumber: "Ward123",
            Status: "New",
            EstimatedCost: "Rs.15.50L",
            TenderedCost: "Rs.16.50L",
            Timelimit: "36 Months",
            Spillover: "20 Months",
            ExtensionGranted: "Yes",
            stExtension: "Up to 05/07/2018",
            ndExtension: "Up to 05/05/2020",
            rdExtension: "Up to 05/12/2022"
        },
        
    ]

    return (
        <div className="wrapper">
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar isopen={isopen} />
                    <div className="main-panel"
                        style={{ width: isopen ? "" : "calc(100%  - 80px )" }}
                    >
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Physical Spill Over - Name of Department</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 table-card">
                                    {/* <SpillOverDepartSearch /> */}
                                    <div class=" filter mb-0">
                                        <div class="card-body">
                                            <div class="form-row">
                                                <div class="col-12 col-lg-4">
                                                    <div class="form-group">
                                                        <label class="d-block" for="Budgettype">Project Status</label>

                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options}

                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-4">
                                                    <div class="form-group">
                                                        <label class="d-block" for="WardNo">Ward No</label>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            options={options1}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <button type="button" className="btn btn-clear text-white mr-2 mt-4 float-right waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <X size={18} style={{ marginRight: "3px" }} /> Clear
                                                    </button>
                                                    <button type="button" className="btn btn-primary text-white mr-2 mt-4 float-right waves-effect waves-light">
                                                        {/* <Search/> */}
                                                        <Search size={18} style={{ marginRight: "3px" }} />  Search
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* <SpillOverDepartTable /> */}
                                    <div class="table-responsive">
                                        <table cellpadding="0" cellspacing="0" border="0" class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name of the Project</th>
                                                    <th>Name of Vendor</th>
                                                    <th>Ward Number</th>
                                                    <th>Status</th>
                                                    <th>Estimated Cost</th>
                                                    <th>Tendered Cost</th>
                                                    <th>Time limit</th>
                                                    <th>Spill over</th>
                                                    <th>Extension Granted</th>
                                                    <th>1st Extension</th>
                                                    <th>2nd Extension</th>
                                                    <th>3rd Extension</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Project name goes here</td>
                                                    <td>Vendor Name Come here</td>
                                                    <td>Ward123</td>
                                                    <td><span class="badge badge-primary px-2 py-1">New</span></td>
                                                    <td>Rs.15.50L</td>
                                                    <td>Rs.16.50L</td>
                                                    <td>36 Months</td>
                                                    <td>20 Months</td>
                                                    <td>Yes</td>
                                                    <td>Up to 05/07/2018</td>
                                                    <td>Up to 05/05/2020</td>
                                                    <td>Up to 05/12/2022</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Project name goes here</td>
                                                    <td>Vendor Name Come here</td>
                                                    <td>Ward2</td>
                                                    <td><span class="badge badge-warning px-2 py-1">Ongoing</span></td>
                                                    <td>Rs.15.50L</td>
                                                    <td>Rs.16.50L</td>
                                                    <td>36 Months</td>
                                                    <td>20 Months</td>
                                                    <td>Yes</td>
                                                    <td>Up to 05/07/2020</td>
                                                    <td>Up to 05/05/2022</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Project name goes here</td>
                                                    <td>Vendor Name Come here</td>
                                                    <td>Ward2</td>
                                                    <td><span class="badge badge-warning px-2 py-1">Ongoing</span></td>
                                                    <td>Rs.15.50L</td>
                                                    <td>Rs.16.50L</td>
                                                    <td>36 Months</td>
                                                    <td>20 Months</td>
                                                    <td>Yes</td>
                                                    <td>Up to 05/07/2022</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Project name goes here</td>
                                                    <td>Vendor Name Come here</td>
                                                    <td>Ward3</td>
                                                    <td><span class="badge badge-success px-2 py-1">Completed</span></td>
                                                    <td>Rs.15.50L</td>
                                                    <td>Rs.16.50L</td>
                                                    <td>36 Months</td>
                                                    <td>20 Months</td>
                                                    <td>No</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>Project name goes here</td>
                                                    <td>Vendor Name Come here</td>
                                                    <td>Ward3</td>
                                                    <td><span class="badge badge-success px-2 py-1">Completed</span></td>
                                                    <td>Rs.15.50L</td>
                                                    <td>Rs.16.50L</td>
                                                    <td>36 Months</td>
                                                    <td>20 Months</td>
                                                    <td>No</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 1 to 10 of 25
                                                entries</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                                <ul className="pagination">
                                                    <li className="paginate_button page-item previous disabled" id="example_previous"><a href="#"
                                                        aria-controls="example" data-dt-idx="0" tabindex="0" className="page-link">Previous</a></li>
                                                    <li className="paginate_button page-item active"><a href="#" aria-controls="example" data-dt-idx="1"
                                                        tabindex="0" className="page-link">1</a></li>
                                                    <li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="2"
                                                        tabindex="0" className="page-link">2</a></li>
                                                    <li className="paginate_button page-item "><a href="#" aria-controls="example" data-dt-idx="3"
                                                        tabindex="0" className="page-link">3</a></li>
                                                    <li className="paginate_button page-item next" id="example_next"><a href="#" aria-controls="example"
                                                        data-dt-idx="4" tabindex="0" className="page-link">Next</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhysicalSpillOverDepartmentPage
