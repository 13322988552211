import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const UpdateTableDataAPI = createAsyncThunk("UpdateTableData", async ({ data }) => {
    const {
        T_ProjectSanctionDetailsID,
        M_ProjectID,
        M_WorkID,
        M_WorkTypeID,
        M_ContractorID,
        UserID,
        From,
        token,
        To, } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Transaction/Get_Web_T_PhysicalMilestoneWorkProgress_Select?M_WorkTypeID=0&M_ProjectTypeID=0&M_WardID=0&M_ContractorID=0&M_IndicatorStatusID=0&Flag=All&M_UsersID=1&FromTop=1&ToTop=10`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const UpdateDataSlice = createSlice({
    name: "UpdateTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UpdateTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UpdateTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(UpdateTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const UpdateTableDataReducer = UpdateDataSlice.reducer

// InsertUpdate Table Data

export const UpdatePostAPI = createAsyncThunk("UpdatePostData", async ({ data }) => {
    const {
        M_ProjectID,
        ExtenstionDate1,
        ExtenstionDate2,
        ExtenstionDate3,
        M_UsersID,
        Flag,     
        M_UserID,
        token,     
        handlePost,
        handleAddCloseClick,     
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectID", M_ProjectID);
    formdata.append("ExtenstionDate1", ExtenstionDate1);
    formdata.append("ExtenstionDate2", ExtenstionDate2);
    formdata.append("ExtenstionDate3", ExtenstionDate3);
    formdata.append("M_UsersID", M_UsersID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectDetails_ExtenstionDateUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const UpdateNameSlice = createSlice({
    name: "UpdatePostData",
    initialState: {
        isLoading: false,
        UpdateData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UpdatePostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UpdatePostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.UpdateData = action.payload;
        });
        builder.addCase(UpdatePostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.UpdateData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const UpdatePostDataReducer = UpdateNameSlice.reducer



// Delete Table Data

export const ProjectSanctionDetailsDeleteAPI = createAsyncThunk("ProjectSanctionDetailsDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_ProjectSanctionDetailsID", rowData?.t_ProjectSanctionDetailsID);
    formdata.append("T_AssignWorkToContractorID", rowData?.t_AssignWorkToContractorID);
    formdata.append("M_UsersID", UserID);


    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Transaction/Post_T_ProjectSanctionDetails_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const ProjectSanctionDetailsDeleteSlice = createSlice({
    name: "ProjectSanctionDetailsDelete",
    initialState: {
        isDeleteLoading: false,
        ProjectSanctionDetailstableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectSanctionDetailsDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectSanctionDetailsDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.ProjectSanctionDetailstableData = action.payload;

        });
        builder.addCase(ProjectSanctionDetailsDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.ProjectSanctionDetailstableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectSanctionDetailsDeleteReducer = ProjectSanctionDetailsDeleteSlice.reducer