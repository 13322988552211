
import Select from "react-select"
import { useEffect } from "react"
import { Star } from "../../Components/CommoComponent/Star"
import { useAuthState } from "../../Helper/Context/context";
import { useDispatch, useSelector } from "react-redux";
import { ProjectTrackerCodeDDLAPI } from "../../Redux/DDLSlice";



export default function ProjectTrackerCodeDDL(props) {
    const { projectTrackerCodeDDL, setProjectTrackerCodeDDL, DDLData, editData, FlagName, apiFlag, mendetary, ProjectDDL } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails;
    const dispatch = useDispatch();

    useEffect(() => {
        const data = {
            M_ProjectID: ProjectDDL.ID,
            Flag: apiFlag,
            UserID: UserID,
            token: token
        };
       {ProjectDDL.ID != 0 && dispatch(ProjectTrackerCodeDDLAPI({ data }))}
    }, [ProjectDDL.ID]);

    const { ProjectTrackerCodeData } = useSelector((state) => state.ProjectTrackerCodeDDLData);


    useEffect(() => {
        handleProjectTrackerCodeDDL()
    }, [ProjectTrackerCodeData])

    const handleProjectTrackerCodeDDL = () => {
        if (ProjectTrackerCodeData && ProjectTrackerCodeData.table && ProjectTrackerCodeData.table.length > 0) {

            let list = ProjectTrackerCodeData.table.map((item, index) => ({
                value: item.m_ProjectID,
                label: item.project_Tracker_Code,
            }))
            setProjectTrackerCodeDDL({
                DDL: list,
                ID: apiFlag === 'Update' ? editData.m_ProjectID : 0,
                Label: apiFlag === 'Update' ? editData.projectCode : "---Select---"
            })
        }
        else {
            setProjectTrackerCodeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }
    return (
        <>
            <div className="form-group">
                <label className="d-block" htmlFor="Budgettype">Project Tracker Code {mendetary === 'yes' ? <Star /> : ''}</label>
                <Select
                    // isLoading
                    isClearable
                    isSearchable
                    maxMenuHeight={140}
                    value={{ value: projectTrackerCodeDDL.ID, label: projectTrackerCodeDDL.Label }}
                    onChange={(e) => {
                        e ?
                            setProjectTrackerCodeDDL({ ...projectTrackerCodeDDL, ID: e.value, Label: e.label })
                            :
                            setProjectTrackerCodeDDL({ ...projectTrackerCodeDDL, ID: 0, Label: "Select---" })
                    }}
                    options={projectTrackerCodeDDL.DDL}

                />
            </div>
        </>
    )
}