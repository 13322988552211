import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const UserCreationTableDataAPI = createAsyncThunk("UserCreationTableData", async ({ data }) => {
    const {
        M_UsersID,
        M_RoleID,
        M_DepartmentID,
        DepartmentName,
        M_DeptOfficeTypeIndicatorID,
        M_DesignationID,
        UserStatusIndicatorID,
        Login_M_UsersID,
        token,
        From,
        To,
        handleExportData
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Users_Select?M_UsersID=${M_UsersID ? M_UsersID : '0'}&M_RoleID=${M_RoleID ? M_RoleID : '0'}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&DepartmentName=${DepartmentName}&M_DeptOfficeTypeIndicatorID=${M_DeptOfficeTypeIndicatorID}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&UserStatusIndicatorID=${UserStatusIndicatorID ? UserStatusIndicatorID :'0'}&Login_M_UsersID=${Login_M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleExportData()
                return result.data
            } else {
                return result
            }
        })
})

const UserCreationTableDataSlice = createSlice({
    name: "UserCreationTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserCreationTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(UserCreationTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const UserCreationTableDataReducer = UserCreationTableDataSlice.reducer


// Export Data

export const ExportUserCreationTableDataAPI = createAsyncThunk("ExportUserCreationTableData", async ({ data }) => {
    const {
        M_UsersID,
        M_RoleID,
        M_DepartmentID,
        DepartmentName,
        M_DeptOfficeTypeIndicatorID,
        M_DesignationID,
        UserStatusIndicatorID,
        Login_M_UsersID,
        token,
        From,
        To
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/PTMS/Master/Get_M_Users_Select?M_UsersID=${M_UsersID ? M_UsersID : '0'}&M_RoleID=${M_RoleID ? M_RoleID : '0'}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&DepartmentName=${DepartmentName}&M_DeptOfficeTypeIndicatorID=${M_DeptOfficeTypeIndicatorID}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&UserStatusIndicatorID=${UserStatusIndicatorID ? UserStatusIndicatorID :'0'}&Login_M_UsersID=${Login_M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ExportUserCreationTableDataSlice = createSlice({
    name: "ExportUserCreationTableData",
    initialState: {
        isExportLoading: false,
        ExportData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ExportUserCreationTableDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(ExportUserCreationTableDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportData = action.payload;
        });
        builder.addCase(ExportUserCreationTableDataAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ExportUserCreationTableDataReducer = ExportUserCreationTableDataSlice.reducer

// InsertUpdate Table Data

export const UserCreationPostAPI = createAsyncThunk("UserCreationPostData", async ({ addData }) => {
    const {
        M_UsersID,
        M_RoleID,
        M_DepartmentID,
        M_EmployeeID,
        M_DesignationID,
        TableName,
        UserName,
        Joining_Date,
        Password,
        UserStatusIndicatorID,
        Login_UserID,
        token,
        Flag,
        handlePost,
        handleAddCloseClick
    } = addData

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_UsersID", M_UsersID);
    formdata.append("M_RoleID", M_RoleID);
    formdata.append("M_DepartmentID", M_DepartmentID);
    formdata.append("M_EmployeeID", M_EmployeeID);
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("TableName", TableName);
    formdata.append("UserName", UserName);
    formdata.append("Joining_Date", Joining_Date);
    formdata.append("Password", Password);
    formdata.append("UserStatusIndicatorID", UserStatusIndicatorID);
    formdata.append("Login_UserID", Login_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Users_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const UserCreationNameSlice = createSlice({
    name: "UserCreationPostData",
    initialState: {
        isLoading: false,
        UserCreationData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserCreationPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.UserCreationData = action.payload;
        });
        builder.addCase(UserCreationPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.UserCreationData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const UserCreationPostDataReducer = UserCreationNameSlice.reducer



// Delete Table Data

export const UserCreationDeleteAPI = createAsyncThunk("UserCreationDelete", async ({ PopUpField, handlePost, token, UserID, handleDeleteCloseClick }) => {
    const { rowData, apiFlag } = PopUpField

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_UsersID", rowData?.id);
    formdata.append("Login_UserID", UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/PTMS/Master/Post_M_Users_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const UserCreationDeleteSlice = createSlice({
    name: "UserCreationDelete",
    initialState: {
        isDeleteLoading: false,
        UserCreationableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(UserCreationDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.UserCreationableData = action.payload;

        });
        builder.addCase(UserCreationDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.UserCreationableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const UserCreationDeleteReducer = UserCreationDeleteSlice.reducer