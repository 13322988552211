
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const ExportUserCreation = (props) => {
    const { ExportData, name } = props

    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportUserCreation"
                className="btn btn-success float-end download-table-xls-button float-end btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-ExportUserCreation" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>

                <tr>
                    <th colSpan={10} style={{ textAlign: 'center', fontSize: 16, border: '1px solid black' }}>{name}</th>
                </tr>
                {/* <tr><td></td></tr> */}

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#254462', color: "#fff" }}>

                        <th>Sr.No.</th>
                        <th>Role</th>
                        <th>Department</th>
                        <th>Designation</th>
                        <th>Employee Name</th>
                        <th>Table Name</th>
                        <th>Joining Date</th>
                        <th>User Name</th>
                        <th>Password</th>
                        <th>User Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ExportData && ExportData.table && ExportData.table.length > 0 ? ExportData.table.map((item, i) => (
                            <tr style={{ textAlign: "center", border: '1px solid black' }}>
                                <td className="text_center">{item.rowNum ? item.rowNum : "-"}</td>
                                <td>{item.roleName ? item.roleName : "-"}</td>
                                <td>{item.departmentName ? item.departmentName : "-"}</td>
                                <td>{item.designationName ? item.designationName : "-"}</td>
                                <td>{item.employeeName ? item.employeeName : "-"}</td>
                                <td>{item.tableName ? item.tableName : "-"}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{item.joining_Date ? moment(item.joining_Date).format("DD-MM-YYYY") : "-"}</td>
                                <td>{item.userName ? item.userName : "-"}</td>
                                <td>{item.password ? item.password : "-"}</td>
                                <td>{item.userStatusIndicator ? item.userStatusIndicator : "-"}</td>
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}