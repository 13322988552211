import React, { useState, useEffect } from "react";
import { Edit2, Save, Search, Trash, X } from "react-feather";
import MobileHeader from "../../Components/Header/MobileHeader";
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Select from "react-select"
import { Star } from "../../Components/CommoComponent/Star";
import { Get_M_RoleWise_FormManager_Select } from "./RoleWiseApi/GETAPI";
import LoaderFile from "../../Helper/Loader/LoaderFile";
import { Post_M_RoleWise_FormManager_InsertUpdate } from "./RoleWiseApi/POSTAPI";
import RoleWiseFormPopUp from "./RoleWiseFormPopUp";
import { Get_DDL_M_Role } from "../../APIs/DDLAPI/DDLAPI";
import { useAuthState } from "../../Helper/Context/context";
import { RoleDDLData } from "../../CommonDDL/RoleDDLData";
import { RoleWiseFormPostAPI, RoleWiseFormTableDataAPI } from "../../Redux/UserMasterSlice/RoleWiseFormSlice";
import { useDispatch, useSelector } from "react-redux";


export default function RoleWiseForm() {

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [showCreatePopup, setshowCreatePopup] = useState(false)
    const [showDeletePopup, setshowDeletePopup] = useState(false)
    const [flag, setflag] = useState(false)
    const [isopen, setIsOpen] = useState(true)
    const [ISPost, setISPost] = useState(true)
    const [checkedAll, setcheckedAll] = useState(false)
    const [roleFlag, setroleFlag] = useState("")
    const [IsClear, setIsClear] = useState(false)
    const [gridData, setgridData] = useState([])

    const [RoleDDL, setRoleDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "---Select---"
    })

    const handleOnClearClick = () => {

        setISPost(!ISPost)
    }

    const handlePost = () => {
        setISPost(!ISPost)
    }


    const [roleWiseTable, setroleWiseTable] = useState([])
    const [loading, setloading] = useState()
    const handleOnCloseClick = () => {
        setallState({ ...allState, showCreatePopup: false })
        // setshowDeletePopup(false)
    }

    const [allState, setallState] = useState({
        showCreatePopup: false,
        showDeletePopup: false,
        popUpFlag: "",
        apiFlag: "",
        editData: {},
        deleteData: "",
    })


    const handleAddNewClick = () => {
        setallState({ ...allState, popUpFlag: "Add", apiFlag: "Insert", showCreatePopup: true })
    }

    // const handleChecklist = (event, formManagerID) => {
    //     // setcheckedAll(false)
    //     const { checked } = event.target
    //     const selectedIndex = roleWiseTable.findIndex((id) => id.m_FormManagerID === formManagerID)
    //     roleWiseTable[selectedIndex].isChecked = checked ? 1 : 0
    //     setroleWiseTable([...roleWiseTable])
    // }


    const handleAllSelectChecklist = (event) => {
        // setcheckedAll(true)
        const { checked } = event.target
        gridData.forEach((item) => {
            return item.isChecked = checked ? 1 : 0
        })
        setgridData([...gridData])
    }

    // const handleAllSelectChecklist = (event) => {
    //     // setcheckedAll(true)
    //     const { checked } = event.target
    //     roleWiseTable.forEach((item) => {
    //         return item.isChecked = checked ? 1 : 0
    //         // for condition rendering
    //         // if(item.rowNum <= 3){
    //         //     return item.isChecked = checked ? 1 : 0
    //         // }else{
    //         //    return item.isChecked = 0
    //         // }
    //     })
    //     setroleWiseTable([...roleWiseTable])
    // }


    // useEffect(() => {
    //     Get_M_RoleWise_FormManager_Select({ UserID, RoleDDL, setroleWiseTable, setloading })
    // }, [ISPost, RoleDDL.ID])

    useEffect(() => {
        const data = {
            M_RoleID: RoleDDL.ID,
            UserID: UserID,
            //   From: From,
            //   To: To,
        }
        dispatch(RoleWiseFormTableDataAPI({ data }))
    }, [ISPost, IsClear, RoleDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.RoleWiseFormTableData)


    useEffect(() => {
        let roleData = tableData?.table.map((item) => ({ ...item }))
        setgridData(roleData)
    }, [tableData])


    const handleOnCheckClick = (item, index, check) => {
        if (gridData) {
            let tempGridData = [...gridData]
            tempGridData[index].isChecked = check
            // console.log(tempGridData)
            setgridData(tempGridData)
        }
    }

    // const handleOnSaveClick = () => {
    //     const finalData = roleWiseTable.filter((item) => {
    //         if (item.isChecked) {
    //             return item.m_FormManagerID
    //         }
    //     }).map((item) => item.m_FormManagerID)

    //     // Post_M_RoleWise_FormManager_InsertUpdate({ RoleDDL, UserID, finalData, setISPost, ISPost })

    //     const data = {
    //         M_RoleID: RoleDDL.ID,
    //         M_FormManagerID_Data: finalData,
    //         Flag: 'Update',
    //         UserID: UserID,
    //         token: token,
    //         handlePost: handlePost,
    //         // handleClear: handleClear,
    //     }

    //     dispatch(RoleWiseFormPostAPI({ data }))

    // }

    const handleOnSaveClick = () => {
        let allCheckData = ""
        gridData.forEach((item) => {
            if (item.isChecked) {
                allCheckData = allCheckData + item.m_FormManagerID + ","
            }

        })
        // console.log(allCheckData)
        const data = {
            M_RoleID: RoleDDL.ID,
            M_FormManagerID_Data: allCheckData,
            Flag: 'Update',
            UserID: UserID,
            token: token,
            handlePost: handlePost,
            // handleClear: handleClear,
        }

        dispatch(RoleWiseFormPostAPI({ data }))
    }

    return (
        <>
            {isLoading && <LoaderFile />}
            <div className="container-scroller">
                <MobileHeader setIsOpen={setIsOpen} isopen={isopen} />
                <Header setIsOpen={setIsOpen} isopen={isopen} />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar active="roleWiseForm" isopen={isopen} subMenu='userMaster' ISPost={ISPost} />
                    {/* <!-- Main Container --> */}
                    <div className="main-panel" style={{ width: isopen ? "" : "calc(100%  - 80px )" }}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-md-12 grid-margin">
                                    <div className="row page-heading">
                                        <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                            <h4 className="font-weight-bold">Role Wise Form</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shadow table-card">
                                    <div className="filter mb-0">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <RoleDDLData
                                                        RoleDDL={RoleDDL}
                                                        setRoleDDL={setRoleDDL}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <div id='table-scroll'>
                                            <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th width="5%">
                                                            <div className="text-center">
                                                                <input
                                                                    className="form-control"
                                                                    type="checkbox"
                                                                    // checked={checkedAll}
                                                                    // checked={roleWiseTable.filter((item) => item?.isChecked !== 1).length < 1}
                                                                    disabled={RoleDDL.ID === 0}
                                                                    onChange={(e) => handleAllSelectChecklist(e)}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>Forms name</th>

                                                        {/* <th style={{ width: "150px", textAlign: "center" }}>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        gridData && gridData && gridData.length > 0 ? gridData.map((item, i) => (
                                                            <tr key={i}>
                                                                {
                                                                    RoleDDL.ID > 0 ?
                                                                        <>
                                                                            <td className="text_center td_padding">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="checkbox"
                                                                                    name={item.formName}
                                                                                    value={item.m_FormManagerID}
                                                                                    // checked={item.isChecked === 1 ? true : false}
                                                                                    checked={item.isChecked}
                                                                                    // onChange={(e) => handleChecklist(e, item.m_FormManagerID)}
                                                                                    onChange={(e) => {
                                                                                        handleOnCheckClick(e, i, !item.isChecked)
                                                                                    }}
                                                                                />
                                                                            </td>

                                                                            <td>{item.formName}</td>
                                                                        </> : <></>

                                                                }
                                                            </tr>

                                                        )) : ""
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <div className="col-12 col-lg-12">
                                            <button type="button" className="btn btn-primary text-white mr-2 float-right waves-effect waves-light"
                                                onClick={handleOnSaveClick}
                                            >
                                                <Save size={18} style={{ marginRight: "3px" }} />
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="copyright">
                                Copyright 2023. all rights are reserved.<b>Version 1.0</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {allState.showCreatePopup ? <RoleWiseFormPopUp
                open={allState.showCreatePopup}
                handleOnCloseClick={handleOnCloseClick}
                allState={allState}
                IsPost={ISPost}
                setIsPost={setISPost}
                handleOnClearClick={handleOnClearClick}
            /> : <></>}


        </>
    )
}