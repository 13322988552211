import React from "react";
import {Bar} from "react-chartjs-2"

export default function Cost() {
  const data = {
    labels: ["Estimates Cost", "Tendered Cost", "Actual Costk"],
    datasets: [
      {
        backgroundColor: "rgb(34, 115, 32)",
        borderWidth: 1,
        // borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
        borderRadius: 15,
        data: [100, 63, 87],
      },
    ],
  };

  return (
    <div class="col-12 col-lg-4 pb-3">
      <div class="card h-100 m-0">
        <div class="card-header font-weight-bold">Cost</div>

        {/* <Bar data={data} style={{ maxHeight: "200px", textAlign: "center" }} /> */}
        <Bar data = {data} style={{ maxHeight: "200px", textAlign: "center"}}/>
        
      </div>
    </div>
  );
}
