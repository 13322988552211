import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../Helper/BaseUrl";
import { toastErrorr } from "../Helper/ToastMessage";

export const DepartmentNameDDLAPI = createAsyncThunk("DepartmentNameDDL", async ({ data }) => {
    const {
        UserID,
        token,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Department?M_DeptOfficeTypeIndicatorID=0&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })


})

const DepartmentNameDDLSlice = createSlice({
    name: "DepartmentNameDDL",
    initialState: {
        isLoading: false,
        DepartDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DepartmentNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DepartDDLData = action.payload;
        });
        builder.addCase(DepartmentNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DepartDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DepartmentNameReducer = DepartmentNameDDLSlice.reducer


export const DesignationNameDDLAPI = createAsyncThunk("DesignationNameDDL", async ({ data, Flag }) => {
    const {
        UserID,
        M_DeptOfficeTypeIndicatorID,
        token
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Designation?M_DeptOfficeTypeIndicatorID=${M_DeptOfficeTypeIndicatorID ? M_DeptOfficeTypeIndicatorID : '0'}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const DesignationNameDDLSlice = createSlice({
    name: "DesignationNameDDL",
    initialState: {
        isLoading: false,
        DesignationDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DesignationNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DesignationDDLData = action.payload;
        });
        builder.addCase(DesignationNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DesignationDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DesignationNameReducer = DesignationNameDDLSlice.reducer


export const EmployeeNameDDLAPI = createAsyncThunk("EmployeeNameDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
        designationNameDDL,
        departmentDDL
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Employee?M_DepartmentID=${departmentDDL ? departmentDDL : "0"}&M_DesignationID=${designationNameDDL.ID}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const EmployeeNameDDLSlice = createSlice({
    name: "EmployeeNameDDL",
    initialState: {
        isLoading: false,
        EmployeeDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.EmployeeDDLData = action.payload;
        });
        builder.addCase(EmployeeNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.EmployeeDDLData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const EmployeeNameReducer = EmployeeNameDDLSlice.reducer

export const BudgetTypeDDLAPI = createAsyncThunk(" BudgetTypeDDL", async ({ data, Flag }) => {
    const {
        UserID,
        M_BudgetTypeID,
        token,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_BudgetType?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const BudgetTypeDDLSlice = createSlice({
    name: "BudgetTypeDDL",
    initialState: {
        isLoading: false,
        BudgetTypeDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(BudgetTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(BudgetTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.BudgetTypeDDLData = action.payload;
        });
        builder.addCase(BudgetTypeDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.BudgetTypeDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const BudgetTypeDDLDataReducer = BudgetTypeDDLSlice.reducer

export const ManPowerTypeDDLAPI = createAsyncThunk(" ManPowerTypeDDL", async ({ data, Flag }) => {
    const {
        UserID,
        manPowerTypeDDL,
        token,
    } = data

    console.log(manPowerTypeDDL.ID)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ManPowerType?M_ManPowerTypeID=${manPowerTypeDDL.ID ? manPowerTypeDDL.ID : '0'}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const ManPowerTypeDDLSlice = createSlice({
    name: "ManPowerTypeDDL",
    initialState: {
        isLoading: false,
        ManPowerTypeDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ManPowerTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ManPowerTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ManPowerTypeDDLData = action.payload;
        });
        builder.addCase(ManPowerTypeDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ManPowerTypeDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ManPowerTypeDDLDataReducer = ManPowerTypeDDLSlice.reducer

export const GenderDDLAPI = createAsyncThunk("GenderDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data

    // console.log(manPowerTypeDDL.ID)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_Gender?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const GenderDDLSlice = createSlice({
    name: "GenderDDL",
    initialState: {
        isLoading: false,
        GenderDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GenderDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GenderDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.GenderDDLData = action.payload;
        });
        builder.addCase(GenderDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.GenderDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GenderDDLDataReducer = GenderDDLSlice.reducer


export const ContractorNameDDLAPI = createAsyncThunk("ContractorNameDDL", async ({ data }) => {
    const {
        M_ContractorID,
        M_ProjectID,
        Flag,
        M_UsersID,
        token,
    } = data

    // console.log(manPowerTypeDDL.ID)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Contractor?M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_ProjectID=${M_ProjectID ? M_ProjectID : 0}&Flag=${Flag}&M_UsersID=${M_UsersID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const ContractorNameDDLSlice = createSlice({
    name: "ContractorNameDDL",
    initialState: {
        isLoading: false,
        ContractorNameDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ContractorNameDDLData = action.payload;
        });
        builder.addCase(ContractorNameDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ContractorNameDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorNameDDLDataReducer = ContractorNameDDLSlice.reducer


export const ZoneDDLAPI = createAsyncThunk("ZoneDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data

    // console.log(manPowerTypeDDL.ID)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Zone?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const ZoneDDLSlice = createSlice({
    name: "ZoneDDL",
    initialState: {
        isLoading: false,
        ZoneDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ZoneDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ZoneDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ZoneDDLData = action.payload;
        });
        builder.addCase(ZoneDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ZoneDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ZoneDDLDataReducer = ZoneDDLSlice.reducer

export const WardNameDDLAPI = createAsyncThunk("WardNameDDL", async ({ data, Flag }) => {
    const {
        M_WardID,
        UserID,
        zoneDDL,
        token,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Ward?M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${zoneDDL ? zoneDDL : '0'}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const WardNameDDLSlice = createSlice({
    name: "WardNameDDL",
    initialState: {
        isLoading: false,
        WardNameDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WardNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WardNameDDLData = action.payload;
        });
        builder.addCase(WardNameDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WardNameDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WardNameDDLDataReducer = WardNameDDLSlice.reducer

// --------------Work DDL ----------------

export const WorkDDLAPI = createAsyncThunk("WorkDDL", async ({ data, Flag }) => {
    const {
        WorkTypeDDL,
        UserID,
        token,
    } = data

    // console.log(manPowerTypeDDL.ID)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Work?M_WorkTypeID=${WorkTypeDDL ? WorkTypeDDL : '0'}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const WorkDDLSlice = createSlice({
    name: "WorkDDL",
    initialState: {
        isLoading: false,
        WorkDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WorkDDLData = action.payload;
        });
        builder.addCase(WorkDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WorkDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkDDLDataReducer = WorkDDLSlice.reducer

// --------------Work Type DDL ----------------

export const WorkTypeDDLAPI = createAsyncThunk("WorkTypeDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data

    // console.log(manPowerTypeDDL.ID)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_WorkType?M_WorkTypeID=0&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const WorkTypeDDLSlice = createSlice({
    name: "WorkTypeDDL",
    initialState: {
        isLoading: false,
        WorkTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WorkTypeData = action.payload;
        });
        builder.addCase(WorkTypeDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WorkTypeData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkTypeDDLDataReducer = WorkTypeDDLSlice.reducer

// --------------------------ProjectType DDL--------------------

export const ProjectTypeDDLAPI = createAsyncThunk("ProjectTypeDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectType?M_ProjectTypeID=0&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const ProjectTypeDDLSlice = createSlice({
    name: "ProjectTypeDDL",
    initialState: {
        isLoading: false,
        ProjectTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectTypeData = action.payload;
        });
        builder.addCase(ProjectTypeDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectTypeData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectTypeDDLDataReducer = ProjectTypeDDLSlice.reducer

// --------------------------Project DDL--------------------

export const ProjectDDLAPI = createAsyncThunk("ProjectDDL", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_ProjectTypeID,
        UserID,
        token,
        M_ProjectID,
        Flag
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Project?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : '0'}&M_ProjectID=${M_ProjectID ? M_ProjectID : 0}&M_UsersID=${UserID}&Flag=${Flag}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const ProjectDDLSlice = createSlice({
    name: "ProjectDDL",
    initialState: {
        isLoading: false,
        ProjectData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectData = action.payload;
        });
        builder.addCase(ProjectDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectDDLDataReducer = ProjectDDLSlice.reducer

export const ElectoralWardNumberDDLAPI = createAsyncThunk("ElectoralWardNumberDDL", async ({ data, Flag }) => {
    const {
        UserID,
        M_WardWiseNumberID,
        M_WardID,
        token,
    } = data

    // console.log(manPowerTypeDDL.ID)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_WardWiseNumber?M_WardWiseNumberID=${M_WardWiseNumberID ? M_WardWiseNumberID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const ElectoralWardNumberDDLSlice = createSlice({
    name: "ElectoralWardNumberDDL",
    initialState: {
        isLoading: false,
        ElectoralWardNumberData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ElectoralWardNumberDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ElectoralWardNumberDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ElectoralWardNumberData = action.payload;
        });
        builder.addCase(ElectoralWardNumberDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ElectoralWardNumberData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ElectoralWardNumberDDLDataReducer = ElectoralWardNumberDDLSlice.reducer

export const MunicipalCouncilorDDLAPI = createAsyncThunk("MunicipalCouncilorDDL", async ({ data, Flag }) => {
    const {
        UserID,
        M_WardWiseNumberID,
        M_MunicipalCouncilorID,
        token,
    } = data

    // console.log(manPowerTypeDDL.ID)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_MunicipalCouncilor?M_WardWiseNumberID=${M_WardWiseNumberID ? M_WardWiseNumberID : '0'}&M_MunicipalCouncilorID=${M_MunicipalCouncilorID ? M_MunicipalCouncilorID : '0'}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const MunicipalCouncilorDDLSlice = createSlice({
    name: "MunicipalCouncilorDDL",
    initialState: {
        isLoading: false,
        MunicipalCouncilorDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MunicipalCouncilorDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MunicipalCouncilorDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.MunicipalCouncilorDDLData = action.payload;
        });
        builder.addCase(MunicipalCouncilorDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.MunicipalCouncilorDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const MunicipalCouncilorDDLDataReducer = MunicipalCouncilorDDLSlice.reducer



export const ProjectRemarkDDLAPI = createAsyncThunk("ProjectRemarkDDL", async ({ data }) => {
    const {
        UserID,
        M_ProjectRemarkID,
        token,
        Flag
    } = data

    // console.log(manPowerTypeDDL.ID)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectRemark?M_ProjectRemarkID=${M_ProjectRemarkID ? M_ProjectRemarkID : '0'}&M_UsersID=${UserID}&Flag=${Flag}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const ProjectRemarkDDLSlice = createSlice({
    name: "ProjectRemarkDDL",
    initialState: {
        isLoading: false,
        ProjectRemarkDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectRemarkDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectRemarkDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectRemarkDDLData = action.payload;
        });
        builder.addCase(ProjectRemarkDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectRemarkDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectRemarkDDLDataReducer = ProjectRemarkDDLSlice.reducer

// ----------------------------Role DDL---------------

export const RoleDDLAPI = createAsyncThunk("RoleDDL", async ({ data, Flag }) => {
    const {
        UserID,
        M_ProjectRemarkID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Role?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const RoleDDLSlice = createSlice({
    name: "RoleDDL",
    initialState: {
        isLoading: false,
        RoleData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(RoleDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(RoleDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.RoleData = action.payload;
        });
        builder.addCase(RoleDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.RoleData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const RoleDDLDataReducer = RoleDDLSlice.reducer


// ----------------------------USer Status DDL---------------

export const UserStatusDDLAPI = createAsyncThunk("UserStatusDDL", async ({ data, Flag }) => {
    const {
        UserID,
        M_ProjectRemarkID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_Status?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const UserStatusDDLSlice = createSlice({
    name: "UserStatusDDL",
    initialState: {
        isLoading: false,
        UserStatusData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserStatusDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserStatusDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.UserStatusData = action.payload;
        });
        builder.addCase(UserStatusDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.UserStatusData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const UserStatusDDLDataReducer = UserStatusDDLSlice.reducer


export const UserProgressStatusDDLAPI = createAsyncThunk("UserProgressStatusDDL", async ({ data, Flag }) => {
    const {
        GroupID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Indicator?GroupID=${GroupID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const UserProgressStatusDDLSlice = createSlice({
    name: "UserProgressStatusDDL",
    initialState: {
        isLoading: false,
        UserStatusData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserProgressStatusDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserProgressStatusDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.UserStatusData = action.payload;
        });
        builder.addCase(UserProgressStatusDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.UserStatusData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const UserProgressStatusDDLDataReducer = UserProgressStatusDDLSlice.reducer

export const ProjectPriorityAPI = createAsyncThunk("ProjectPriorityDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectPriority?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const ProjectPriorityDDLSlice = createSlice({
    name: "ProjectPriorityDDL",
    initialState: {
        isLoading: false,
        ProjectPriorityData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectPriorityAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectPriorityAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectPriorityData = action.payload;
        });
        builder.addCase(ProjectPriorityAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectPriorityData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectPriorityDDLDataReducer = ProjectPriorityDDLSlice.reducer


export const KeyProjectDDLAPI = createAsyncThunk("KeyProjectDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_Key_Project?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const KeyProjectDDLSlice = createSlice({
    name: "KeyProjectDDL",
    initialState: {
        isLoading: false,
        KeyProjectData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(KeyProjectDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(KeyProjectDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.KeyProjectData = action.payload;
        });
        builder.addCase(KeyProjectDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.KeyProjectData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const KeyProjectDDLDataReducer = KeyProjectDDLSlice.reducer


export const SubDepartmentDDLAPI = createAsyncThunk("SubDepartmentDDL", async ({ data, Flag }) => {
    const {
        UserID,
        M_SubDepartmentID,
        M_DepartmentID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_SubDepartment?M_SubDepartmentID=${M_SubDepartmentID ? M_SubDepartmentID : '0'}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const SubDepartmentDDLSlice = createSlice({
    name: "SubDepartmentDDL",
    initialState: {
        isLoading: false,
        SubDepartmentData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SubDepartmentDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SubDepartmentDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.SubDepartmentData = action.payload;
        });
        builder.addCase(SubDepartmentDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.SubDepartmentData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SubDepartmentDDLDataReducer = SubDepartmentDDLSlice.reducer


// ----------------------------Stages of Work DDL---------------

export const StagesOfWorkDDLAPI = createAsyncThunk("StagesOfWorkDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_TenderStages?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const StagesOfWorkDDLSlice = createSlice({
    name: "StagesOfWorkDDL",
    initialState: {
        isLoading: false,
        StagesOfWorkData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(StagesOfWorkDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(StagesOfWorkDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.StagesOfWorkData = action.payload;
        });
        builder.addCase(StagesOfWorkDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.StagesOfWorkData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const StagesOfWorkDDLDataReducer = StagesOfWorkDDLSlice.reducer

// ----------------------------Tender Amount Type DDL---------------

export const TenderAmountTypeDDLAPI = createAsyncThunk("TenderAmountTypeDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_TenderAmountType?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const TenderAmountTypeDDLSlice = createSlice({
    name: "TenderAmountTypeDDL",
    initialState: {
        isLoading: false,
        TenderAmountData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(TenderAmountTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(TenderAmountTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.TenderAmountData = action.payload;
        });
        builder.addCase(TenderAmountTypeDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.TenderAmountData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const TenderAmountTypeDDLDataReducer = TenderAmountTypeDDLSlice.reducer

// ----------------------------concate Work And WorkType DDL---------------

export const WorkAndWorkTypeDDLAPI = createAsyncThunk("WorkAndWorkTypeDDL", async ({ data, Flag }) => {
    const {
        M_WorkTypeID,
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_WorkAndWorkType?M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const WorkAndWorkTypeDDLSlice = createSlice({
    name: "WorkAndWorkTypeDDL",
    initialState: {
        isLoading: false,
        WorkAndWorkTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkAndWorkTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkAndWorkTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WorkAndWorkTypeData = action.payload;
        });
        builder.addCase(WorkAndWorkTypeDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WorkAndWorkTypeData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkAndWorkTypeDDLDataReducer = WorkAndWorkTypeDDLSlice.reducer

export const BudgetHeadDDLAPI = createAsyncThunk("BudgetHeadDDL", async ({ data, Flag }) => {
    const {
        UserID,
        M_BudgetTypeID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Budget?M_BudgetTypeID=${M_BudgetTypeID ? M_BudgetTypeID : '0'}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const BudgetHeadDDLSlice = createSlice({
    name: "BudgetHeadDDL",
    initialState: {
        isLoading: false,
        BudgetHeadData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(BudgetHeadDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(BudgetHeadDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.BudgetHeadData = action.payload;
        });
        builder.addCase(BudgetHeadDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.BudgetHeadData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const BudgetHeadDDLDataReducer = BudgetHeadDDLSlice.reducer

export const AssignJEDDLAPI = createAsyncThunk("AssignJEDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
        M_DepartmentID,
        M_RoleID,
        M_WardID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    // Get_DDL_M_UsersM_EmployeeWardMapping_Select
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_UsersEmployeeAssignJE_Select?M_DepartmentID=${M_DepartmentID ? M_DepartmentID : 0}&M_RoleID=${M_RoleID ? M_RoleID : 0}&M_WardID=${M_WardID ? M_WardID : 0}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const AssignJEDDLSlice = createSlice({
    name: "AssignJEDDL",
    initialState: {
        isLoading: false,
        AssignJEData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AssignJEDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(AssignJEDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.AssignJEData = action.payload;
        });
        builder.addCase(AssignJEDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.AssignJEData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const AssignJEDDLDataReducer = AssignJEDDLSlice.reducer

export const ProjectNameMilestoneDDLAPI = createAsyncThunk("ProjectNameMilestoneDDL", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        UserID,
        M_ProjectTypeID,
        M_WorkTypeID,
        Flag,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_T_ProjectWorkWiseMilestoneWise_M_ProjectSelect?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_UsersID=${UserID}&Flag=${Flag}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const ProjectNameMilestoneDDLSlice = createSlice({
    name: "ProjectNameMilestoneDDL",
    initialState: {
        isLoading: false,
        ProjectNameMilestoneData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectNameMilestoneDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectNameMilestoneDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectNameMilestoneData = action.payload;
        });
        builder.addCase(ProjectNameMilestoneDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectNameMilestoneData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectNameMilestoneDDLDataReducer = ProjectNameMilestoneDDLSlice.reducer

// --------------------------------------EmployeeWardMapping DDL -------------
export const EmployeeWardMappingDDLAPI = createAsyncThunk("EmployeeWardMappingDDL", async ({ data, Flag }) => {
    const {
        RoleDDL,
        departmentDDL,
        UserID,
        token,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_UsersM_EmployeeWardMapping_Select?M_DepartmentID=${departmentDDL ? departmentDDL.ID : '0'}&M_RoleID=${RoleDDL ? RoleDDL.ID : '0'}&M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const EmployeeWardMappingDDLSlice = createSlice({
    name: "EmployeeWardMappingDDL",
    initialState: {
        isLoading: false,
        EmployeeWardMappingData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeWardMappingDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeWardMappingDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.EmployeeWardMappingData = action.payload;
        });
        builder.addCase(EmployeeWardMappingDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.EmployeeWardMappingData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EmployeeWardMappingReducer = EmployeeWardMappingDDLSlice.reducer

// Financial Year DDL

export const FinancialDDLAPI = createAsyncThunk("FinancialDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_FinancialYear?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)     
            } else {
                return result
            }
        })
})

const FinancialDDLSlice = createSlice({
    name: "FinancialDDL",
    initialState: {
        isLoading: false,
        FinancialData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(FinancialDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(FinancialDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.FinancialData = action.payload;
        });
        builder.addCase(FinancialDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.FinancialData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const FinancialDDLDataReducer = FinancialDDLSlice.reducer

// Month DDL

export const DashboardMonthDDLAPI = createAsyncThunk("DashboardMonthDDL", async ({ data, Flag }) => {
    const {
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Month?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)
            } else {
                return result
            }
        })
})

const DashboardMonthDDLSlice = createSlice({
    name: "DashboardMonthDDL",
    initialState: {
        isLoading: false,
        DashboardMonthData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DashboardMonthDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DashboardMonthDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DashboardMonthData = action.payload;
        });
        builder.addCase(DashboardMonthDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DashboardMonthData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DashboardMonthDDLDataReducer = DashboardMonthDDLSlice.reducer

// Project Tracker Code DDL

export const ProjectTrackerCodeDDLAPI = createAsyncThunk("ProjectTrackerCodeDDL", async ({ data }) => {
    const {
        M_ProjectID,
        Flag,
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_ProjectCode?M_ProjectID=${M_ProjectID ? M_ProjectID : 0}&M_UsersID=${UserID}&Flag=${Flag}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)
            } else {
                return result
            }
        })
})

const ProjectTrackerCodeDDLSlice = createSlice({
    name: "ProjectTrackerCodeDDL",
    initialState: {
        isLoading: false,
        ProjectTrackerCodeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectTrackerCodeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectTrackerCodeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectTrackerCodeData = action.payload;
        });
        builder.addCase(ProjectTrackerCodeDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectTrackerCodeData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectTrackerCodeDDLDataReducer = ProjectTrackerCodeDDLSlice.reducer

// Project Name New DDL For Project Details Screen

export const NewProjectDetailsProjectNameDDLAPI = createAsyncThunk("NewProjectDetailsProjectNameDDL", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_DepartmentID,
        M_SubDepartmentID,
        Flag,
        M_WardID,
        M_ProjectTypeID,
        M_WorkTypeID,
        M_UsersID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectWiseGrid_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : 0}&M_MonthID=${M_MonthID ? M_MonthID : 0}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : 0}&M_SubDepartmentID=${M_SubDepartmentID ? M_SubDepartmentID : 0}&M_WardID=${M_WardID ? M_WardID : 0}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : 0}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&M_UsersID=${M_UsersID}&Flag=${Flag}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log(result.data)
            } else {
                return result
            }
        })
})

const NewProjectDetailsProjectNameDDLSlice = createSlice({
    name: "NewProjectDetailsProjectNameDDL",
    initialState: {
        isLoading: false,
        NewProjectDetailsProjectNameData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewProjectDetailsProjectNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewProjectDetailsProjectNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.NewProjectDetailsProjectNameData = action.payload;
        });
        builder.addCase(NewProjectDetailsProjectNameDDLAPI.rejected, (state, action) => {
            // console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.NewProjectDetailsProjectNameData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const NewProjectDetailsProjectNameDDLDataReducer = NewProjectDetailsProjectNameDDLSlice.reducer